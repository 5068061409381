import {useParams} from "react-router-dom";
import {useTransfersApi, useTransfersQuery} from "../../api/transfers";
import RecipientAvatar from "../../components/domain/recipients/RecipientAvatar";
import {TransferTitle} from "../../components/domain/transfers/TransferTitle";
import {Button} from "../../components/ui/Buttons";
import {WithSkeleton} from "../../components/ui/Skeleton";
import {useAlertStack} from "../../providers/alert-stack";
import TransferView from "./TransferView";
import Limiter from "../../components/ui/Limiter";
import {Transfer} from "../../models/transfers/Transfer";
import {TitleSubtitleLeftScreen} from "../../components/ui/screen/TitleSubtitleScreen";
import ButtonBar from "../../components/ui/ButtonBar";
import {useStrings} from "../../strings";
import NavBar from "../../components/navigation/NavBar";
import {AlertView} from "../../components/ui/Errors";
import FormattedMoney from "../../components/formatters/money/FormattedMoney";

const ViewTransferPage = () => {
  const strings = useStrings()
  const alertStack = useAlertStack()

  const transferApi = useTransfersApi()
  const transfersQuery = useTransfersQuery()

  const parameters = useParams<{ id: string }>();
  const transferId = parameters.id

  const {data: timeline, ...timelineQuery} = transfersQuery.timeline.useQuery(transferId)
  const transfer = timeline?.transfer
  const cancel = transfersQuery.cancel.useMutation()
  const refund = transfersQuery.refund.request.useMutation({onError: alertStack.showError})

  if (parameters.id === undefined) {
    throw new Error("Transfer ID is required")
  }

  const onCancel = async () => {
    if (transfer === undefined) return

    alertStack.showPopUp(ctx => (
      <AlertView
        title={strings["pay_transfer.bank_transfer.cancel.alert.title"]}
        content={strings["pay_transfer.bank_transfer.cancel.alert.body"]}
        primaryButton={props =>
          <Button
            {...props}
            type={"button"}
            title={strings["general.no"]}
            onClickAsync={ctx.onDismiss}
          />
        }
        secondaryButton={props =>
          <Button
            {...props}
            type={"button"}
            title={strings["general.yes"]}
            onClickAsync={async () => {
              try {
                await cancel.mutateAsync(transfer.transferId)
                await timelineQuery.refetch()
                await ctx.onDismiss()
              } catch (e) {
                alertStack.showError(e)
              }
            }}
          />
        }
        onDismiss={ctx.onDismiss}
      />
    ))

  }

  const onRefund = async () => {
    if (transfer === undefined) return

    const refundInfo = await transferApi.refundInfo(transfer.transferId).catch(err => alertStack.showError(err))

    if (!refundInfo) return

    alertStack.showPopUp(ctx => (
      <AlertView
        title={strings["pay_transfer.bank_transfer.refund.alert.title"]}
        content={strings["pay_transfer.bank_transfer.refund.alert.body"](
          <FormattedMoney fractionDigitsNotRequired={true} value={refundInfo.refundAmount}/>,
          refundInfo.refundBankAccountLast4
        )}
        primaryButton={props =>
          <Button
            {...props}
            type={"button"}
            title={strings["general.no"]}
            onClickAsync={ctx.onDismiss}
          />
        }
        secondaryButton={props =>
          <Button
            {...props}
            type={"button"}
            title={strings["general.yes"]}
            onClickAsync={async () => {
              try {
                await refund.mutateAsync(transfer.transferId)
                await timelineQuery.refetch()
                await ctx.onDismiss()
              } catch (e) {
                alertStack.showError(e)
              }
            }}
          />
        }
        onDismiss={ctx.onDismiss}
      />
    ))
    try {
    } catch (e) {
      alertStack.showError(e)
    }
  }

  // TODO: Test refund
  const buttonBar = (transfer?.isCancellable || transfer?.isUserRefundable) && (
    <ButtonBar align={"center"} sticky={"bottom"}>
      <Button type={"button"}
              size={"big"}
              color={"secondary"}
              title={
                <span className={"text-error-100"}>{
                  transfer?.isUserRefundable
                    ? strings["transfer.cancel_and_refund"]
                    : strings["transfer.cancel"]
                }</span>
              }
              onClickAsync={transfer?.isUserRefundable ? onRefund : onCancel}
      />
    </ButtonBar>
  )

  return (
    <Limiter>
      <NavBar/>
      <TitleSubtitleLeftScreen
        title={
          <WithSkeleton value={transfer} fallback={Transfer.fallback}>
            {transfer => <TransferTitle transfer={transfer}/>}
          </WithSkeleton>
        }
        avatar={<RecipientAvatar recipient={transfer?.recipient} size={"big"}/>}
      >
        <TransferView
          transferDisplaying={transfer?.displaying()}
          displayRecipient={true}
          recipient={transfer?.recipient}
        />
        {buttonBar}
      </TitleSubtitleLeftScreen>
    </Limiter>
  )
};

export default ViewTransferPage;