import {tx} from '@transifex/native';
import {useT} from "@transifex/react";

export type UseT = typeof useT;

export class Transifex {
  static init(): void {
    const defaultLang = navigator.language.split('-')[0];
    const supportedLangs = ["en", "es", "fr", "de", "it"];
    tx.init({
      token: '1/5e3747c23b34499b4989842c9e64394cf24e4964',
      // Use this for finding missing translations
      // missingPolicy: new PseudoTranslationPolicy(),
    });

    // console.log('defaultLang', defaultLang);

    const selectedLang = supportedLangs.indexOf(defaultLang) >= 0 ? defaultLang : 'en';
    // console.log('selectedLang', selectedLang);
    void tx.setCurrentLocale(selectedLang)
  }
}