import {v4 as uuidv4} from 'uuid';
import {BuildConfig} from "build-config";

export class DynamicConfig {
    static DEFAULT_URL = BuildConfig.baseUrl

    static getApiUrl(): string {
        return localStorage.getItem("config.api.url") || this.DEFAULT_URL;
    }

    static getDeviceID(): string {
        const persisted = localStorage.getItem("config.device.id")
        if (persisted) {
            return persisted
        }

        const generated = this.generateRandomDeviceID()
        localStorage.setItem("config.device.id", generated)
        return generated
    }

    static generateRandomDeviceID(): string {
        return uuidv4();
    }
}