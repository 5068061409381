import FormattedColloquialDate from "../../formatters/date/FormattedColloquialDate";
import React from "react";
import {useStrings} from "../../../strings";
import {DeliveryOption} from "../../../models/transfers/Estimate";

type Props = {
  deliveryOption: DeliveryOption

  standardPast?: (date: JSX.Element) => string,
  standardToday: string,
  standardTomorrow: string,
  standardOther: (date: JSX.Element) => string,
  expressPast?: (date: JSX.Element) => string,
  expressInstant?: string,
  expressToday: string,
  expressTomorrow: string,
  expressOther: (date: JSX.Element) => string
}

export const FormattedDeliveryOption = (
  {
    deliveryOption,

    standardPast,
    standardToday,
    standardTomorrow,
    standardOther,
    expressPast,
    expressInstant,
    expressToday,
    expressTomorrow,
    expressOther
  }: Props) => {
  const deliveryDate = deliveryOption.deliveryDate
  if (deliveryOption.isInstant) {
    return <>{expressInstant ?? ""}</>
  } else if (deliveryOption.kind === "standard") {
    return (
      <FormattedColloquialDate
        value={deliveryDate}
        today={standardToday}
        tomorrow={standardTomorrow}
        other={standardOther}
        past={standardPast}
      />
    )
  } else if (deliveryOption.kind === "expedited") {
    return (
      <FormattedColloquialDate
        value={deliveryDate}
        today={expressToday}
        tomorrow={expressTomorrow}
        other={expressOther}
        past={expressPast}
      />
    )
  }
  return null
}
type SimpleProps = {
  deliveryOption: DeliveryOption

  past?: (date: JSX.Element) => string,
  instant?: string,
  today: string,
  tomorrow: string,
  other: (date: JSX.Element) => string
}
export const FormattedDeliveryOptionSimple = (
  {
    deliveryOption,

    past,
    instant,
    today,
    tomorrow,
    other
  }: SimpleProps) => {
  return (
    <FormattedDeliveryOption
      deliveryOption={deliveryOption}
      standardPast={past}
      standardToday={today}
      standardTomorrow={tomorrow}
      standardOther={other}
      expressInstant={instant}
      expressPast={past}
      expressToday={today}
      expressTomorrow={tomorrow}
      expressOther={other}
    />
  )
}

export const DeliveryOptionTitle = ({deliveryOption}: { deliveryOption: DeliveryOption }) => {
  const strings = useStrings()
  return (
    <FormattedDeliveryOption
      deliveryOption={deliveryOption}
      standardPast={strings["transfer.delivery_option.standard.title"]}
      standardToday={strings["transfer.delivery_option.standard.title.today"]}
      standardTomorrow={strings["transfer.delivery_option.standard.title.tomorrow"]}
      standardOther={strings["transfer.delivery_option.standard.title"]}
      expressInstant={strings["transfer.delivery_option.instant.title"]}
      expressPast={strings["transfer.delivery_option.expedited.title"]}
      expressToday={strings["transfer.delivery_option.expedited.title.today"]}
      expressTomorrow={strings["transfer.delivery_option.expedited.title.tomorrow"]}
      expressOther={strings["transfer.delivery_option.expedited.title"]}
    />
  )
}