import {FormattedNumber, useIntl} from "react-intl";
import {Money} from "../../../helpers/money";
import {useStrings} from "../../../strings";

type Props = {
  value: Money
  withFree?: boolean
  fractionDigitsNotRequired?: boolean
}

const FormattedMoney = ({value, ...props}: Props) => {
  const strings = useStrings()
  const withFree = props.withFree ?? false
  if (value.amount.isZero() && withFree) {
    return strings["general.free"]
  }

  // eslint-disable-next-line react/style-prop-object
  return <FormattedNumber
    value={value.amount.toNumber()}
    style={"currency"}
    currency={value.currency.code}
    maximumFractionDigits={value.currency.maximumFractionDigits()}
    minimumFractionDigits={props.fractionDigitsNotRequired ? 0 : value.currency.maximumFractionDigits()}
  />
}

export const useMoneyFormatter = () => {
  const intl = useIntl()

  const formatWithoutSymbol = (value: Money) => {
    return intl.formatNumber(value.amount.toNumber(), {
      style: "currency",
      currencyDisplay: "code",
      currency: value.currency.code,
      maximumFractionDigits: value.currency.maximumFractionDigits(),
      minimumFractionDigits: value.currency.maximumFractionDigits(),
    }).replace(value.currency.code, "").trim()
  }

  return {
    formatWithoutSymbol
  }
}

export default FormattedMoney;
