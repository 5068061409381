import React, {useEffect} from "react";
import Limiter from "../../../components/ui/Limiter";
import {NavStackRoot} from "../../../components/navigation/NavStack";
import NavBar from "../../../components/navigation/NavBar";
import {TransferEstimateStep, TransferEstimateStepProps} from "./TransferEstimateStep";
import {useTransfersApi} from "../../../api/transfers";
import {useTransferContextOps} from "../../../models/transfers/TransferContext";
import {useUserDefaultsState} from "../../../atoms/user-defaults";
import {CurrencyAvailability} from "../../../models/transfers/Estimate";
import {useEstimateInputState} from "../../../atoms/estimate-input";

type Props = {}

const RootTransferPage = (_: Props) => {
  const transferApi = useTransfersApi()
  const transferContextOps = useTransferContextOps()
  const [_1, setUserDefaults] = useUserDefaultsState()
  const currencyAvailabilityOps = CurrencyAvailability.useOperations()

  const [_2, setEstimateInput] = useEstimateInputState()

  useEffect(() => void async function () {
    const currencyAvailability = await transferApi.availableCurrencies()
    const ops = currencyAvailabilityOps(currencyAvailability)

    setEstimateInput(prev => {
      if (prev !== undefined) return prev

      const sourceCurrency = ops.preferredSourceCurrency
      const destinationCurrency = ops.preferredDestinationCurrency
      const preferredEntryMode = ops.preferredEntryMode(sourceCurrency, destinationCurrency)
      const amount = "empty"

      switch (preferredEntryMode) {
        case "source":
          return {
            enteredMoney: {amount, currency: sourceCurrency},
            entryMode: "source",
            calculatedCurrency: destinationCurrency,
          }
        case "destination":
          return {
            enteredMoney: {amount, currency: destinationCurrency},
            entryMode: "destination",
            calculatedCurrency: sourceCurrency,
          }
      }
    })
  }(), [currencyAvailabilityOps, setEstimateInput, transferApi, transferContextOps])

  return (
    <NavStackRoot<{}, TransferEstimateStepProps>
      root={TransferEstimateStep}
      args={{
        onChangeEntryMode: (entryMode) => {
          setUserDefaults(prev => ({
            ...prev, lastViewedEntryMode: entryMode
          }))
        },
        onChangeCurrency: (entryMode, currency) => {
          setUserDefaults(prev => ({
            ...prev,
            ...(entryMode === "source"
                ? {lastViewedSourceCurrency: currency}
                : {lastViewedDestinationCurrency: currency}
            ),
          }))
        }
      }}
      ctx={{}}
      wrapper={(navStack, children) => {
        if (navStack.top.component.name === TransferEstimateStep.name) {
          return (
            <div className="container flex flex-grow flex-col items-center mx-auto w-4/5 relative max-w-[780px]">
              {children}
            </div>
          )
        }
        return (
          <Limiter>
            <NavBar className={"px-0"}/>
            {children}
          </Limiter>
        )
      }}/>
  )
}

export default RootTransferPage;