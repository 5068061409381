import {Section} from "../../components/ui/collections/Section";
import {WithLearnMoreSuffix} from "../../components/ui/LearnMore";
import {Links} from "../../helpers/Links";
import {Tier} from "../../models/tiers/Tier";
import Icon from "../../components/ui/icons/Icon";
import {TierList} from "../../models/tiers/TierList";
import moment from "moment";
import {useStrings} from "../../strings";
import React from "react";
import _ from "lodash";
import {WithSkeleton} from "../../components/ui/Skeleton";
import {LimitProgressRow} from "./LimitProgessRow";

type Props = {
  tier?: Tier
  tierList?: TierList
  actionButton?: React.ReactNode
}

const LimitSection = ({tier, tierList, ...props}: Props) => {
  const strings = useStrings()

  const title = (
    <WithSkeleton value={tier} fallback={Tier.fallback()}>{tier =>
      <div className={"flex flex-row items-center"}>
        {tier.name}
        {tier.tierId === tierList?.currentTierId ?
          <Icon className={"ml-2"} name={"check-circle-solid"}/> : undefined}
      </div>
    }</WithSkeleton>
  )

  return (
    <Section
      className={"mt-8"}
      title={title}
      isLoading={tier === undefined || tierList === undefined}
      subtitle={function () {
        if (tier !== undefined && tier?.tierId === tierList?.currentTierId) return undefined
        return tier?.description && <WithLearnMoreSuffix url={Links.helpCenter.articles.tiers}>{tier?.description}</WithLearnMoreSuffix>
      }()
      }
      actionButton={props.actionButton}
    >
      <WithSkeleton
        value={tier && tierList ? {tier, tierList} : undefined}
        fallback={{tier: Tier.fallback(), tierList: TierList.fallback()}}>{({tier, tierList}) => {
        const customLimit = tierList.customLimit
        if (customLimit) {
          const daysLeft = moment(customLimit.expiryDate).diff(moment(), "days")

          return (
            <LimitProgressRow
              className={"mt-6"}
              caption={strings["tiers.custom.days_left"](daysLeft)}
              consumed={customLimit.consumedMoney}
              limit={customLimit.limit}
            />
          )
        }

        return _.sortBy(tier?.limits, limit => limit.limit.amount.toNumber()).map(limit => {
          const consumedLimit = tierList.consumedMoney.find(consumed =>
            consumed.durationInDays === limit.durationDays && consumed.money.currency.eq(limit.limit.currency)
          )
          if (!consumedLimit) return undefined
          return (
            <LimitProgressRow
              key={limit.durationDays}
              className={"mt-6"}
              caption={strings["tier.day_limit"](limit.durationDays)}
              consumed={consumedLimit.money}
              limit={limit.limit}
            />
          )
        })
      }}</WithSkeleton>
    </Section>
  )
}

export default LimitSection