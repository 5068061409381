export function publicUrl(path: string): string {
    return `${process.env.PUBLIC_URL}/${path}`
}

type SpriteName = "flags" | "icons"
export function svgSprite(spriteName: SpriteName, elName: string, modifier?: string): string {
    return publicUrl(`sprite/${spriteName}.svg#${elName}${modifier ? `--${modifier}` : ""}`)
}

export function img(path: string): string {
    return publicUrl(`img/${path}`)
}
