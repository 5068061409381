import {InterfaceTemplates, InterfaceTemplateValues} from "../domain";
import {CommonInterfaceTemplateProps} from "./common";
import {Picker, PickerOption} from "../../../ui/Picker";
import Collapsable from "../../../ui/Collapsable";
import {InterfaceTemplateTextInput} from "./InterfaceTemplateTextInput";
import SingleSelectOption = InterfaceTemplateValues.SingleSelectOption;

export const InterfaceTemplateSingleSelectInput = (
  {template, value, ...props}:
    CommonInterfaceTemplateProps<InterfaceTemplates.SingleSelect, InterfaceTemplateValues.SingleSelect>
) => {

  const options: PickerOption[] = template.options.map((option) => ({
    value: option.id,
    label: option.title,
  }))

  return (
    <>
      <Picker
        placeholder={template.title ?? ""}
        value={options.find((option) => option.value === value.singleSelectValue.option.id) ?? null}
        options={options}
        errored={props.error}
        onChange={
          (newValue) => {
            props.onChange({
              kind: "singleSelect",
              singleSelectValue: {
                option:
                  template.options.find((option) => option.id === newValue?.value) ??
                  new SingleSelectOption({
                    id: newValue?.value ?? "",
                    title: newValue?.value ?? "",
                  }),
              }
            })
          }
        }
      />
      {template.otherTextEntry && (
        <Collapsable expanded={value.singleSelectValue.option.isOther}>
          <div className={"pt-[24px]"}>
            <InterfaceTemplateTextInput
              capitalization={"sentences"}
              template={template.otherTextEntry}
              value={{kind: "text", textValue: value.singleSelectValue.otherText ?? ""}}
              error={undefined}
              onChange={(newValue) => {
                props.onChange({
                  kind: "singleSelect",
                  singleSelectValue: {
                    option: new SingleSelectOption({
                      id: "other",
                      title: "Other",
                    }),
                    otherText: newValue.textValue ?? "",
                  }
                })
              }}
            />
          </div>
        </Collapsable>
      )
      }
    </>
  )
}
