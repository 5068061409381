import EstimatePage from "../estimate/EstimatePage";
import {useTransferCreateController} from "./transfer-create-controller";
import {EntryMode} from "../../../models/transfers/Estimate";
import {Currency} from "../../../helpers/money";


export type TransferEstimateStepProps = {
  onChangeCurrency: (input: EntryMode, currency: Currency) => void
  onChangeEntryMode: (entryMode: EntryMode) => void
}

export const TransferEstimateStep = (props: TransferEstimateStepProps) => {
  const transferCreateController = useTransferCreateController()
  return (
    <EstimatePage
      onChangeCurrency={props.onChangeCurrency}
      onChangeEntryMode={props.onChangeEntryMode}
      onComplete={transferCreateController.onEstimateComplete}
    />
  )
}