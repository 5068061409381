import QRCodeStyling, {DownloadOptions} from "qr-code-styling";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {img} from "./public";
import {useEffectOnce} from "../hooks/effect";

type Options = {
  size: number
  url?: string
}

type QrCodeContext = {
  isLoading: boolean
  canvasContainerRef: React.RefObject<HTMLDivElement>
  download: (opts: Partial<DownloadOptions>) => Promise<void>
}

export const useQrCode = (options: Options): QrCodeContext => {
  const canvasContainerRef = useRef<HTMLDivElement>(null)
  const [isLoading, setIsLoading] = useState(true)
  const qrCode = useMemo(() => new QRCodeStyling({
    type: "svg",
    width: options.size,
    height: options.size,
    data: options.url,
    image: img("app-icon-dark.svg"),
    "margin": 0,
    "qrOptions": {
      "typeNumber": 0,
      "mode": "Byte",
      "errorCorrectionLevel": "Q"
    },
    "imageOptions": {
      "hideBackgroundDots": true,
      "imageSize": 0.5,
      "margin": 5
    },
    "dotsOptions": {
      "type": "dots",
      "color": "#242424"
    },
    "backgroundOptions": {
      "color": 'transparent'
    },
    "cornersSquareOptions": {
      "type": "extra-rounded",
      "color": "#242423"
    },
    "cornersDotOptions": {
      "type": "square",
      "color": "#242423"
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [])

  useEffectOnce(() => {
    if (canvasContainerRef === null) return
    qrCode.append(canvasContainerRef.current || undefined)
    qrCode._getElement("svg").then(() => {
      setTimeout(() => setIsLoading(false))
    })
  })

  useEffect(() => {
    qrCode.update({
      data: options.url,
      width: options.size,
      height: options.size,
    });
  }, [options.url, options.size, qrCode]);

  return {
    isLoading: options.url === undefined || isLoading,
    canvasContainerRef: canvasContainerRef,
    download: function (opts) {
      return qrCode.download(opts)
    }
  }
}