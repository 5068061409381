import {TitleSubtitleLeftScreen} from "../../components/ui/screen/TitleSubtitleScreen";
import {useStrings} from "../../strings";
import {useTransfersApi, useTransfersQuery} from "../../api/transfers";
import {WithLearnMoreSuffix} from "../../components/ui/LearnMore";
import {Links} from "../../helpers/Links";
import React from "react";
import {InlineButton} from "../../components/ui/Buttons";
import {useVerificationController} from "../../components/verification/verification-controller";
import {useNavStack} from "../../atoms/nav-stack";
import LimitsStepUpgradeSuccess from "./LimitsStepUpgradeSuccess";
import LimitsStepUpgradeInReview from "./LimitsStepUpgradeInReview";
import {VerificationsExt} from "../../components/domain/verifications/domain";
import LimitSection from "./LimitSection";
import {Tier} from "../../models/tiers/Tier";


const LimitsStepMain = () => {
  const strings = useStrings()
  const transferApi = useTransfersApi()
  const transfersQuery = useTransfersQuery()

  const verificationController = useVerificationController()
  const navStack = useNavStack()

  const {data: tierList, refetch: refetchTierList} = transfersQuery.tier.list.useQuery({refetchInterval: 1000})

  const sections = (
    tierList?.availableTiers ?? new Array<Tier | undefined>(2).fill(undefined)
  ).map((tier, idx) => {
    return (
      <LimitSection
        key={idx}
        tier={tier}
        tierList={tierList}
        actionButton={function () {
          if (!tier || !tierList) return undefined
          const pushUpgradeInReview = () => {
            navStack.push(LimitsStepUpgradeInReview, {
              requestedTier: tier,
              onComplete: async () => {
                navStack.popUntil({component: LimitsStepMain, inclusive: false})
              }
            }, {
              popUntil: {
                component: LimitsStepMain,
                inclusive: false
              }
            })
          }

          const pushUpgradeSuccess = () => {
            navStack.push(LimitsStepUpgradeSuccess, {
              requestedTier: tier,
              onComplete: async () => {
                navStack.popUntil({component: LimitsStepMain, inclusive: false})
              }
            }, {
              popUntil: {
                component: LimitsStepMain,
                inclusive: false
              }
            })
          }

          const processVerifications = async () => {
            await verificationController(tier.requiredVerifications, {
              titleAlign: "left",
              sourceStackEntry: LimitsStepMain,
              onFinished: () => {
                return transferApi.pollTierList({
                  maxDurationMs: 5 * 1000,
                  intervalMs: 500,
                  pollCondition: (tierData) => {
                    const currentTierHighestLimit = tierData.currentTier()?.highestLimit()
                    const tierHighestLimit = tier.highestLimit()

                    if (!currentTierHighestLimit || !tierHighestLimit) return false

                    return currentTierHighestLimit.gte(tierHighestLimit)
                  }
                }).pollThen({
                  onExpire: () => {
                    pushUpgradeInReview()
                  },
                  onSatisfied: () => {
                    pushUpgradeSuccess()
                  }
                })
              }
            })
            await refetchTierList()
          }

          const inReviewButton = (
            <InlineButton
              type={"button"}
              title={strings["tier.upgrade.in_review"]}
              onClick={() => {
                navStack.push(LimitsStepUpgradeInReview, {
                  requestedTier: tier,
                  onComplete: async () => {
                    navStack.popUntil({component: LimitsStepMain, inclusive: false})
                  }
                })
              }}
            />
          )

          if (!tier.isUpgradable()) return undefined

          const isTierCurrentTier = tier.tierId === tierList?.currentTierId
          const isUserInputRequired = VerificationsExt.isUserInputRequired(tier.requiredVerifications)
          if (isTierCurrentTier && !isUserInputRequired) {
            return tier.isUnlimited() ? inReviewButton : undefined
          } else if (isTierCurrentTier && isUserInputRequired) {
            return (
              <InlineButton
                type={"button"}
                title={strings["limits.custom_limit.re_request"]}
                onClickAsync={processVerifications}
              />
            )
          } else if (!isTierCurrentTier && isUserInputRequired) {
            return (
              <InlineButton
                type={"button"}
                title={strings["tiers.upgrade"]}
                onClickAsync={processVerifications}
              />
            )
          }

          return inReviewButton
        }()}
      />
    )
  })

  return (
    <TitleSubtitleLeftScreen
      title={strings["tiers.title"]}
      subtitle={
        <WithLearnMoreSuffix
          url={Links.helpCenter.articles.tiers}
          children={strings["tiers.subtitle"]}
        />
      }>
      {sections}
    </TitleSubtitleLeftScreen>
  )
}

export default LimitsStepMain