import {Portal} from "../../components/domain/recipients/domain";
import {useMemo, useState} from "react";
import _ from "lodash";
import {useIntl} from "react-intl";
import SegmentedControl from "../../components/ui/input/SegmentedControl";
import CurrencyName from "../../components/ui/CurrencyName";
import {Button, InlineButton} from "../../components/ui/Buttons";
import {useStrings} from "../../strings";
import Caption from "../../components/ui/Caption";
import ChevronDown from "../../components/ui/icons/ChevronDown";
import Crossfade from "../../components/ui/Crossfade";
import Collapsable from "../../components/ui/Collapsable";
import Spinner from "../../components/ui/icons/Spinner";
import PayInDetailsSectionRows from "../../components/domain/common/PayInDetailsRows";
import {PortalDeliveryInfo} from "../../models/PortalDeliveryInfo";
import InfoRow from "../../components/ui/collections/rows/InfoRow";
import PortalDeliveryOptionSpeed from "../../components/domain/recipients/PortalDeliveryOptionSpeed";
import DeliveryOptionKindView from "../../components/transfers/DeliveryOptionKindView";
import FormattedNumber from "../../components/formatters/FormattedNumber";
import {useAlertStack} from "../../providers/alert-stack";
import {AlertPopUpContext} from "../../components/ui/Errors";
import ComplexRow from "../../components/ui/collections/rows/ComplexRow";
import Icon from "../../components/ui/icons/Icon";
import PortalDeliveryOptionBasicDescription
  from "../../components/domain/recipients/PortalDeliveryOptionBasicDecription";
import {WithLearnMoreSuffix} from "../../components/ui/LearnMore";
import {Links} from "../../helpers/Links";
import ButtonBar from "../../components/ui/ButtonBar";
import {DeliveryOptionKind} from "../../models/transfers/Estimate";

type Props = {
  deliveryInfo: PortalDeliveryInfo | undefined
  portals: Portal[]
  onChangePortalOption: (optionKind: DeliveryOptionKind) => Promise<any>
}

const Footer = (props: { expanded: boolean, onClickExpand: () => void }) => {
  const strings = useStrings()
  return (
    <div className="py-4 border-t border-primary-20">
      <InlineButton className={"flex items-center"} type={"button"} onClick={props.onClickExpand}>
        <Caption size={"3-title"} text={strings[props.expanded ? "portals.show_less" : "portals.show_more"]}/>
        {" "}
        <ChevronDown className={"w-5 h-5"} invertedY={props.expanded}/>
      </InlineButton>
    </div>
  )
}

const DeliveryOptionPopUp = (props: AlertPopUpContext & {
  deliveryInfo: PortalDeliveryInfo
  onSave: (selectedOption: DeliveryOptionKind) => Promise<void>
}) => {
  const strings = useStrings()

  const [selectedOption, setSelectedOption] = useState(props.deliveryInfo.selectedDeliveryType)

  return (
    <div className={"w-full max-w-[548px]"}>
      {props.deliveryInfo.availableDeliveryOptions.map(option => (
        <ComplexRow
          bordered={true}
          title={
            <span className={"inline-flex items-center"}>
            {strings["portals.delivery_option.title"](
              <DeliveryOptionKindView kind={option.type}/>,
              <PortalDeliveryOptionSpeed option={option}/>
            )}
              <Icon className={"ml-1"} name={"check-circle-solid"} hidden={selectedOption !== option.type}/>
          </span>
          }
          subtitle={function () {
            switch (option.type) {
              case "standard":
                return <PortalDeliveryOptionBasicDescription option={option}/>
              case "expedited":
                return strings["portals.delivery_option.description.express"]
            }
          }()}
          secondary={<FormattedNumber style={"percent"} withFree={true} value={option.rate.toNumber()}/>}
          onClick={() => setSelectedOption(option.type)}
        />
      ))}
      <div className={"text-center py-4"}>
        <WithLearnMoreSuffix url={Links.helpCenter.articles.portalsDeliveryOptions}>
          <Caption size={"3"} text={strings["portals.delivery_option_selection_description"]}/>
        </WithLearnMoreSuffix>
      </div>
      <ButtonBar>
        <Button
          type={"button"}
          size={"big"}
          color={"primary-black"}
          title={strings["general.save"]}
          onClickAsync={async () => {
            await props.onSave(selectedOption)
            await props.onDismiss()
          }}
        />

        <Button
          type={"button"}
          size={"big"}
          color={"secondary"}
          onClickAsync={props.onDismiss}
          title={strings["general.cancel"]}
        />
      </ButtonBar>
    </div>
  )
}

const DeliveryOptionRow = ({info, onEdit}: {
  info: PortalDeliveryInfo
  onEdit: () => void
}) => {
  const strings = useStrings()

  const selectedDeliveryOption = info.availableDeliveryOptions.find(o => o.type === info.selectedDeliveryType)

  if (!selectedDeliveryOption) return null

  const hasOnlyOption = info.availableDeliveryOptions.length === 1

  const content = function () {
    if (hasOnlyOption) return <PortalDeliveryOptionSpeed option={selectedDeliveryOption}/>

    return strings["portal.delivery_option.description"](
      <DeliveryOptionKindView kind={selectedDeliveryOption.type}/>,
      <PortalDeliveryOptionSpeed option={selectedDeliveryOption}/>,
      <FormattedNumber style={"percent"} withFree={true} value={selectedDeliveryOption.rate.toNumber()}/>
    )
  }()

  const actionButton = function () {
    if (hasOnlyOption) return null

    return (
      <InlineButton type={"button"} onClick={onEdit}>
        {strings["general.edit"]}
      </InlineButton>
    )
  }()

  return (
    <InfoRow
      caption={strings["transfer.review.transfer.delivery"]}
      content={content}
      actionButton={actionButton}
    />
  )
}

const LoadingView = () => {
  const strings = useStrings()
  return (
    <div className={"flex flex-col justify-center items-center h-[200px]"}>
      <Spinner className={"w-6 h-6"} color={"dark"}/>
      <div className={"mt-2"}>
        {strings["portal.loading"]}
      </div>
    </div>
  )
}

const PortalsView = (props: Props) => {
  const intl = useIntl()
  const alertStack = useAlertStack()

  const portals = useMemo(() => _.sortBy(
    props.portals,
    p => p.payInDetails.bankTransfer.bankAccount.currency.name(intl)
  ), [intl, props.portals])

  const [selectedCurrency, setSelectedCurrency] = useState<string | undefined>(portals[0]?.payInDetails.bankTransfer.bankAccount.currency.code)
  const [expanded, setExpanded] = useState<boolean>(false)

  const selectedPortal = portals.find(p => p.payInDetails.bankTransfer.bankAccount.currency.code === selectedCurrency)

  if (!selectedPortal) throw new Error("Selected portal not found")

  return (
    <div className={"px-4 pt-3 border border-primary-10 rounded-[16px]"}>
      <SegmentedControl
        name={"portal-control"}
        segments={portals.map(p => ({
          label: <CurrencyName currency={p.payInDetails.bankTransfer.bankAccount.currency}/>,
          value: p.payInDetails.bankTransfer.bankAccount.currency.code
        }))}
        value={selectedCurrency ?? selectedPortal.payInDetails.bankTransfer.bankAccount.currency.code}
        onChange={setSelectedCurrency}
      />
      <Crossfade key={selectedPortal.payInDetails.bankTransfer.bankAccount.currency.code}>
        {function () {
          if (!selectedPortal.isReady()) return <LoadingView key={"loading"}/>
          return (
            <div className={"pb-2"}>
              <PayInDetailsSectionRows
                key={"bankAccount"}
                isLoading={true}
                sendingAmount={undefined}
                payInDetails={selectedPortal.payInDetails}
                onlyImportant={!expanded}
              />
            </div>
          )
        }()}
      </Crossfade>
      <Collapsable expanded={props.deliveryInfo !== undefined && expanded}>
        <div className={"border-t border-primary-20 py-2 "}>

          <DeliveryOptionRow
            info={props.deliveryInfo ?? PortalDeliveryInfo.fallback}
            onEdit={() => {
              const deliveryInfo = props.deliveryInfo
              if (!deliveryInfo) return
              alertStack.showPopUp(ctx =>
                <DeliveryOptionPopUp
                  {...ctx}
                  deliveryInfo={deliveryInfo}
                  onSave={props.onChangePortalOption}
                />
              )
            }}
          />
        </div>
      </Collapsable>
      <Collapsable expanded={selectedPortal.isReady()}>
        <Footer expanded={expanded} onClickExpand={() => setExpanded(prev => !prev)}/>
      </Collapsable>
    </div>
  )
}

export default PortalsView;