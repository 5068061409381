import {InlineButton} from "../Buttons";
import {useStrings} from "../../../strings";
import {useCopyToClipboard} from "../../../helpers/hooks";

const InlineButtonCopy = ({ copyString }: { copyString: string }) => {
  const strings = useStrings()
  const [_, copyToClipboard] = useCopyToClipboard()

  return (
  <InlineButton
    type={"button"}
    title={strings["general.copy"]}
    onClick={() => {
      void copyToClipboard(copyString)
    }}
  />)
}

export default InlineButtonCopy