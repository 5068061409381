import React from "react";
import {svgSprite} from "../../../helpers/public";
import {currencyToCountry} from "../../../helpers/countries";
import {PropsClassName} from "../../../helpers/props";

type Props = {
  currencyCode: string;
  size: "big" | "medium" | "small"
} & PropsClassName

const CurrencyIcon: React.FC<Props> = (props) => {
  const name = function () {
    const separateIcon = ["EUR"]
    if (separateIcon.indexOf(props.currencyCode) >= 0) {
      return props.currencyCode
    }

    return currencyToCountry(props.currencyCode)
  }()

  const size = function (){
    switch (props.size) {
      case "big":
        return 32
      case "medium":
        return 24
      case "small":
        return 18
    }
  }()

  return (
    <svg className={props.className} width={size} height={size}>
      <use href={`${svgSprite("flags", "flag-unknown")}`}/>
      {name && <use href={`${svgSprite("flags", name)}`}/>}
    </svg>
  )
}

export default CurrencyIcon;