import {Button} from "../../ui/Buttons";
import {Checkbox, Form} from "../../ui/input/Inputs";
import ButtonBar from "../../ui/ButtonBar";
import AddressInputGroup, {
  AddressInputValue,
  fromApiAddress,
  toApiAddressModel
} from "../../ui/input/AddressInputGroup";
import {useEffect, useMemo, useState} from "react";
import {useBusinessOnboardingQuery} from "../../../api/onboarding";
import {useAlertStack} from "../../../providers/alert-stack";
import Collapsable from "../../ui/Collapsable";
import {useAsyncLoading} from "../../../helpers/hooks";
import {TitleSubtitleCenterScreen} from "../../ui/screen/TitleSubtitleScreen";
import {OnboardingCtx, useOnboardingNavStack} from "./BusinessOnboardingScreen";
import {BusinessApplicantContactForm} from "./BusinessContactForm";
import {BusinessSignUpKeyPeople} from "./BusinessKeyPeople";
import {useSideIllustration} from "../../../atoms/side-illustration";
import {useStrings} from "../../../strings";

type Props = OnboardingCtx

type State = {
  inputs?: {
    registeredAddress: AddressInputValue
    tradingAddress: AddressInputValue
    addressesMatch: boolean
  }
  errors: {
    registeredAddress?: any
    tradingAddress?: any
  }
}

const BusinessOnboardingAddress = ({application, ...props}: Props) => {
  const strings = useStrings()
  const navStack = useOnboardingNavStack()
  const [state, setState] = useState<State>({
    inputs: undefined,
    errors: {
      registeredAddress: undefined,
      tradingAddress: undefined
    }
  })
  const errorStack = useAlertStack()
  const {isLoading, asyncWithLoading} = useAsyncLoading(false)
  useSideIllustration("briefcase")

  useEffect(() => {
    if (application === undefined) {
      return
    }

    setState((prev) => ({
      ...prev,
      inputs: prev.inputs ? prev.inputs : {
        registeredAddress: fromApiAddress(application.payload.info.registeredAddress),
        tradingAddress: fromApiAddress(application.payload.info.tradingAddress),
        addressesMatch: true,
      }
    }))

  }, [application]);

  const businessOnboardingQuery = useBusinessOnboardingQuery()
  const updateRegisteredAddress = businessOnboardingQuery.application.updateRegisteredAddress.useMutation(props.user.userId)
  const updateTradingAddress = businessOnboardingQuery.application.updateTradingAddress.useMutation(props.user.userId)


  const setError = (label: keyof State["errors"]) => (err: any) => {
    setState(prev => ({...prev, errors: {...prev.errors, [label]: err}}))
  }
  const unsetError = (label: keyof State["errors"]) => {
    setError(label)(undefined)
  }

  const onUpdateRegisteredAddress = () => {
    return asyncWithLoading(() => updateRegisteredAddress.mutateAsync({address: toApiAddressModel(state.inputs?.registeredAddress)}, {
      onSuccess: () => unsetError("registeredAddress"),
      onError: setError("registeredAddress")
    }))
  }

  const onUpdateTradingAddress = () => {
    const addr = state.inputs?.addressesMatch === true ? state.inputs.registeredAddress : state.inputs?.tradingAddress
    return asyncWithLoading(() => updateTradingAddress.mutateAsync({address: toApiAddressModel(addr)}, {
      onSuccess: () => unsetError("tradingAddress"),
      onError: setError("tradingAddress")
    }))
  }

  const onFormSubmit = async () => {
    await onUpdateRegisteredAddress()
    await onUpdateTradingAddress()

    const isApplicantCreated = application?.payload.contacts.some((v) => v.roles.includes("applicant"))
    if (!isApplicantCreated) {
      navStack.push(BusinessApplicantContactForm, {
        onComplete: async () => {
          navStack.push(BusinessSignUpKeyPeople, {}, {replace: true})
        }
      })
    } else {
      navStack.push(BusinessSignUpKeyPeople, {})
    }
  }

  const {data: acceptableCountryList} = businessOnboardingQuery.acceptableCountriesList.useQuery({onError: err => errorStack.showError(err)})

  const onChangeAddress = (label: "registeredAddress" | "tradingAddress") => (newValue: AddressInputValue) => {
    setState((prev) => ({
      errors: {
        [label]: undefined,
      },
      inputs: prev.inputs && {
        ...prev.inputs,
        [label]: newValue
      }
    }))
  }

  const countryList = useMemo(() =>
      acceptableCountryList?.payload?.countries?.map((c) => c.countryCode),
    [acceptableCountryList])

  return (
    <TitleSubtitleCenterScreen title={strings["business_signup.address.title"]}>
      <Form onSubmit={onFormSubmit}>
        <AddressInputGroup
          name={strings["business.registered_address.title"]}
          classNames={{inputGroup: "mb-[16px]"}}
          availableCountries={countryList}
          suggestedCountryCode={props.user.countryCode}
          disabled={isLoading}
          value={state.inputs?.registeredAddress}
          error={state.errors?.registeredAddress}
          onChange={onChangeAddress("registeredAddress")}
        />
        <Checkbox
          className={"mb-[24px]"}
          label={strings["business_signup.trading_address_same.confirm.title"]}
          isChecked={state.inputs?.addressesMatch ?? false}
          onChange={newValue => setState(prev => ({
            ...prev, inputs: prev.inputs && {
              ...prev.inputs, addressesMatch: newValue
            }
          }))}/>

        <Collapsable expanded={state.inputs ? !state.inputs.addressesMatch : false}>
          <AddressInputGroup
            name={strings["business.trading_address.title"]}
            availableCountries={countryList}
            suggestedCountryCode={props.user.countryCode}
            disabled={isLoading}
            value={state.inputs?.tradingAddress}
            error={state.errors.tradingAddress}
            onChange={onChangeAddress("tradingAddress")}/>
        </Collapsable>

        <ButtonBar align={"center"} className={"mt-[32px]"}>
          <Button type={"submit"} title={strings["general.continue"]} size={"max"} color={"primary-black"} loading={isLoading}/>
        </ButtonBar>
      </Form>
    </TitleSubtitleCenterScreen>
  )
};

export default BusinessOnboardingAddress;