import {useBusinessOnboardingQuery} from "../../../api/onboarding";
import BusinessOnboardingCompanyInformation from "./BusinessOnboardingCompanyInformation";
import {useAuthorizedAuthValue, User} from "../../../atoms/auth";
import {BusinessApplicationResponse} from "../../../api/whales/Onboarding";
import SideIllustrationLayout from "../../ui/layout/unauthorized/SideIllustrationLayout";
import {NavStackRoot} from "../../navigation/NavStack";
import OnboardingScreen from "../OnboardingScreen";
import BusinessOnboardingStatus from "./BusinessOnboardingStatus";
import BusinessApplicationApproved from "./BusinessApplicationApproved";
import {useNavStack} from "../../../atoms/nav-stack";
import {Links} from "../../../helpers/Links";
import Navigate from "../../../routes/router-dom-wrapper/Navigate";
import Limiter from "../../ui/Limiter";


export type OnboardingCtx = {
  user: User,
  application: BusinessApplicationResponse
}

export const useOnboardingNavStack = useNavStack<OnboardingCtx, {}>

const BusinessOnboardingScreen = () => {
  const authState = useAuthorizedAuthValue()
  const businessOnboardingQuery = useBusinessOnboardingQuery()
  const {data: application} = businessOnboardingQuery.application.get.useQuery(authState.user.userId)

  if (application === undefined) {
    return null
  }

  const commonProps = {
    user: authState.user,
    application: application
  }

  switch (authState.user.userType) {
    case "individual":
      return <Navigate to={Links.atlantic.onboarding.individual} replace={true} isBackAllowed={false}/>
    case "business":
      return (
        <SideIllustrationLayout>
          <OnboardingScreen>
            <NavStackRoot<OnboardingCtx, {}>
              wrapper={(navStack, children) => (
                <Limiter className={"flex-1"}>
                  {children}
                </Limiter>
              )}
              ctx={commonProps} root={function () {
              switch (application.payload.info.status) {
                default:
                  return BusinessOnboardingCompanyInformation
                case "checks-pending":
                  return BusinessOnboardingStatus
                case "active":
                  return BusinessApplicationApproved
              }
            }()} args={{}}/>
          </OnboardingScreen>
        </SideIllustrationLayout>
      )
  }

};

export default BusinessOnboardingScreen;