import React from "react";
import {Button} from "../../../ui/Buttons";
import {PropsClassName, PropsOnComplete} from "../../../../helpers/props";
import {InterfaceTemplates, InterfaceTemplateValues} from "../domain";
import {InterfaceTemplateTextInput} from "../inputs/InterfaceTemplateTextInput";
import {TitleSubtitleScreen} from "../../../ui/screen/TitleSubtitleScreen";
import ButtonBar from "../../../ui/ButtonBar";
import Form from "../../../ui/input/Inputs";
import {useAsyncLoading} from "../../../../helpers/hooks";
import {useStrings} from "../../../../strings";

type Props = {
  titleAlign: "left" | "center"
  value: InterfaceTemplateValues.Text
  setValue: (value: InterfaceTemplateValues.Text) => void
  template: InterfaceTemplates.Text,
} & PropsClassName & PropsOnComplete<InterfaceTemplateValues.Text>

const InterfaceTemplateTextScreen = ({value, setValue, className, template, onComplete, ...props}: Props) => {
  const strings = useStrings()
  const asyncWithLoading = useAsyncLoading(false)

  return (
    <TitleSubtitleScreen
      className={className}
      title={template.title}
      subtitle={template.subtitle}
      titleAlign={props.titleAlign}
    >
      <Form onSubmit={() => asyncWithLoading.asyncWithLoading(() => onComplete(value))}>
        <InterfaceTemplateTextInput
          template={template}
          value={value}
          disabled={asyncWithLoading.isLoading}
          onChange={setValue}
          error={undefined}
        />
        <ButtonBar sticky={"bottom"} pushDown={false}>
          <Button
            loading={asyncWithLoading.isLoading}
            title={strings["general.continue"]}
            type={"submit"}
            size={"big"}
            color={"primary-black"}
          />
        </ButtonBar>
      </Form>
    </TitleSubtitleScreen>
  )
}

export default InterfaceTemplateTextScreen