import {isAndroid, isIOS} from 'react-device-detect';
import {img} from "../../helpers/public";
import {Button} from "../ui/Buttons";
import SideIllustrationLayout from "../ui/layout/unauthorized/SideIllustrationLayout";
import Limiter from "../ui/Limiter";
import {Link} from "../ui/Link";
import {TitleSubtitleCenterScreen} from "../ui/screen/TitleSubtitleScreen";
import OnboardingScreen from './OnboardingScreen';
import {useSideIllustration} from "../../atoms/side-illustration";
import {useStrings} from "../../strings";
import AtlanticQrCode from "../ui/AtlanticQrCode";

const DownloadScreen = () => {
  const strings = useStrings()
  const appStoreLink = "https://apps.apple.com/app/id1600301653"
  const googlePlayLink = "https://play.google.com/store/apps/details?id=money.atlantic"
  const getAppLink = "https://atlantic.money/get-app"

  const isMobile = isAndroid || isIOS

  useSideIllustration("briefcase")

  return <SideIllustrationLayout sideIllustration={"app-showcase"}>
    <OnboardingScreen>
      <TitleSubtitleCenterScreen
        title={strings["download_app.title"]}
        subtitle={isMobile ? strings["download_app.subtitle.mobile"] : strings["download_app.subtitle.desktop"]}>
        <Limiter contentMaxWidth={"380px"}>
          {!isMobile &&
              <div className={"mb-[70px] w-full"}>
                  <div className={"w-full max-w-[260px] max-h-[260px] block mx-auto mb-[16px]"}>
                      <AtlanticQrCode size={"big"} url={getAppLink}/>
                  </div>
                  <div className={"flex justify-center gap-x-[8px]"}>
                      <Link className={"flex-grow-0 max-w-[134px]"}
                            href={appStoreLink}
                            newTab={true}>
                          <img className={"block h-[36px]"} src={img("download-app-store.svg")}
                               alt={"Download on the AppStore"}/>
                      </Link>
                      <Link className={"flex-grow-0 max-w-[134px]"}
                            href={googlePlayLink}
                            newTab={true}>
                          <img className={"block h-[36px]"} src={img("download-gplay.svg")}
                               alt={"Get it on Google Play"}/>
                      </Link>
                  </div>
              </div>
          }

          {isMobile &&
              <Button type={"link"} title={strings["general.get_app"]} size={"max"} color={"primary-black"}
                      href={isIOS ? appStoreLink : googlePlayLink}
                      newTab/>
          }
        </Limiter>
      </TitleSubtitleCenterScreen>
    </OnboardingScreen>
  </SideIllustrationLayout>
}

export default DownloadScreen;