import DefaultRow from "../ui/collections/rows/DefaultRow";
import {Transfer} from "../../models/transfers/Transfer";
import React from "react";
import FormattedDate from "../formatters/date/FormattedDate";
import {useStrings} from "../../strings";
import TransferStatusView from "../domain/transfers/TransferStatusView";
import FormattedMoney from "../formatters/money/FormattedMoney";
import Icon from "../ui/icons/Icon";
import {RowClickableProps} from "../ui/collections/rows/AbstractRow";

type Props = {
  transfer: Transfer
  active?: boolean
  trailingArrow?: boolean
} & RowClickableProps

const TransferRow = React.forwardRef<HTMLDivElement, Props>(
  ({transfer, ...props}, ref) => {
    const strings = useStrings()
    return <DefaultRow
      {...props}
      ref={ref}
      title={strings["transfer_row.title"](transfer.recipient.displayName)}
      active={props.active}
      subtitle={
        <span>
          <FormattedDate value={transfer.createdAt} style={"MMMMd"}/>
          {" • "}
          <TransferStatusView status={transfer.status}/>
        </span>
      }
      secondary={<FormattedMoney value={transfer.sourceMoney}/>}
      subSecondary={<FormattedMoney value={transfer.destinationMoney}/>}
      trailingIcon={function () {
        if (props.trailingArrow) {
          return <Icon name={"chevron-right"}/>
        }
      }()}
    />
  })

export default TransferRow