import {VerificationProps} from "./verification-controller";
import {InterfaceTemplates} from "../domain/interface-template/domain";
import {useVerificationApi} from "../../api/verification";
import InterfaceTemplatePickerScreen from "../domain/interface-template/screens/InterfaceTemplatePickerScreen";

type Props = VerificationProps & {
  template: InterfaceTemplates.Picker
}

const VerificationPickerStepSubmit = (props: Props) => {
  const verificationApi = useVerificationApi()
  return (
    <InterfaceTemplatePickerScreen
      titleAlign={props.titleAlign}
      template={props.template}
      onComplete={async (value) => {
        await verificationApi.submit({
          verificationId: props.verification.id,
          value: {
            kind: "picker",
            pickerValue: {
              option: {
                id: value.pickerValue.id,
                title: value.pickerValue.title
              },
            }
          }
        })
        await props.onComplete()
      }}
    />
  )
}

export default VerificationPickerStepSubmit