import React, {useMemo} from "react";
import './EstimateDeliveryOption.scss';
import {Estimate} from "../../../models/transfers/Estimate";
import {useStrings} from "../../../strings";
import FormattedMoney from "../../../components/formatters/money/FormattedMoney";
import FormattedNumber from "../../../components/formatters/FormattedNumber";
import {LearnMoreLink} from "../../../components/ui/LearnMore";
import {TransferDisplaying} from "../../../models/transfers/TransferDisplaying";
import {Links} from "../../../helpers/Links";
import Icon from "../../../components/ui/icons/Icon";
import ComplexRow from "../../../components/ui/collections/rows/ComplexRow";
import {DeliveryOptionTitle} from "../../../components/domain/transfers/FormattedDeliveryOption";

type Props = {
  estimate: Estimate;
  isLastOption: boolean
  onEstimateSelect: () => Promise<void>;
}

const EstimateDeliveryOption = ({estimate, ...props}: Props) => {
  const strings = useStrings()
  const displaying = useMemo(() => TransferDisplaying.fromEstimate(estimate), [estimate])

  return (
    <ComplexRow
      bordered={!props.isLastOption}
      disabled={estimate.deliveryOption.disabled}
      title={<DeliveryOptionTitle deliveryOption={estimate.deliveryOption}/>}
      subtitle={function () {
        if (estimate.disabled) {
          if (estimate.deliveryOption.kind === "standard") {
            return strings["delivery_option.unavailable.body.standard"]
          } else {
            return strings["delivery_option.unavailable.body.expedited"]
          }
        }
        return displaying.deliverySubtitle(strings)
      }()}
      secondary={<div className={"flex flex-row"}>
        {function () {
          if (estimate.deliveryOption.fee.amount.gt(0) || estimate.deliveryOption.isFree) {
            return <FormattedMoney value={estimate.deliveryOption.fee} withFree={true}/>
          }

          return <FormattedNumber style={"percent"} value={estimate.deliveryOption.rate.toNumber()}/>

        }()}
      </div>}
      trailingIcon={<Icon name={"chevron-right"}/>}
      auxiliaryButton={<LearnMoreLink url={Links.helpCenter.articles.deliveryOptions}/>}
      onClickAsync={props.onEstimateSelect}
    />
  )
};

export default EstimateDeliveryOption;