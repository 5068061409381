import {PortalDeliveryOption} from "../../../models/PortalDeliveryInfo"
import {useStrings} from "../../../strings";

type Props = {
  option: PortalDeliveryOption
}

const PortalDeliveryOptionBasicDescription = ({option}: Props) => {
  const strings = useStrings()
  if (option.isInstant) {
    return strings["portals.delivery_option.basic_description.instant"]
  }

  if (option.isSameDay()) {
    return strings["portals.delivery_option.basic_description.same_day"]
  }

  return strings["portals.delivery_option.basic_description.future"](option.deliverySpeed)
}

export default PortalDeliveryOptionBasicDescription;