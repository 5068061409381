import {TitleSubtitleLeftScreen} from "../../../components/ui/screen/TitleSubtitleScreen";
import {useStrings} from "../../../strings";
import {LearnMoreLink, WithLearnMoreSuffix} from "../../../components/ui/LearnMore";
import {Links} from "../../../helpers/Links";
import _ from "lodash";
import FormattedQuoteRate from "../../../components/formatters/FormattedQuoteRate";
import FormattedMoney from "../../../components/formatters/money/FormattedMoney";
import {useAsyncLoading} from "../../../helpers/hooks";
import {PropsTransferContext, useTransferCreateController} from "./transfer-create-controller";
import {useAlertStack} from "../../../providers/alert-stack";
import {AlertPopUpContext, AlertView} from "../../../components/ui/Errors";
import {Button} from "../../../components/ui/Buttons";
import {Estimate} from "../../../models/transfers/Estimate";
import {LimiterOverflow} from "../../../components/ui/Limiter";
import Icon from "../../../components/ui/icons/Icon";
import {useUserDefaultsState} from "../../../atoms/user-defaults";
import ComplexRow from "../../../components/ui/collections/rows/ComplexRow";

type Props = {} & PropsTransferContext

const TransferQuoteOptionStep = ({transferContext}: Props) => {
  const strings = useStrings()
  const transferCreateController = useTransferCreateController()

  const alertStack = useAlertStack()
  const {isLoading, asyncWithLoading} = useAsyncLoading(false)

  const [_1, setUserDefaults] = useUserDefaultsState()

  const confirmPopUp = (candidate: Estimate, confirm: () => Promise<void>) => (ctx: AlertPopUpContext) => {
    return (
      <AlertView
        title={strings["quote.approximate_warning.title"]}
        content={strings["quote.approximate_warning.subtitle"](
          <FormattedMoney value={candidate.destinationMoney}/>
        )}
        primaryButton={(props) =>
          <Button {...props}
                  type={"button"}
                  title={strings["general.continue"]}
                  onClickAsync={async () => {
                    await ctx.onDismiss()
                    return confirm()
                  }}
          />
        }
        secondaryButton={(props) =>
          <Button {...props}
                  type={"button"}
                  title={strings["general.back"]}
                  onClickAsync={ctx.onDismiss}
          />
        }
        {...ctx}
      />
    )
  }

  const rows = _.sortBy(
    transferContext.quoteCandidateTransferEstimates,
    e => e.quoteOption.fee.amount
  ).map((candidate, index) => {
    const onClickRow = candidate.disabled ? undefined : () => asyncWithLoading(async () => {
      const confirm = () => {
        setUserDefaults(prev => ({
          ...prev, lastSelectedQuoteOptionKind: candidate.quoteOption.kind
        }))
        return transferCreateController.onQuoteOptionComplete(transferContext, candidate.quoteOption)
      }
      if (candidate.quoteOption.kind === "exact" || candidate.entryMode !== "destination") {
        return confirm()
      }
      alertStack.showPopUp(confirmPopUp(candidate, confirm))
    })

    return (
      <ComplexRow
        key={index}
        disabled={candidate.disabled || isLoading}
        title={function () {
          switch (candidate.quoteOption.kind) {
            case "approximate":
              return strings["transfer.quote_option.approximate.title"](
                <FormattedQuoteRate value={candidate.quote.rate}/>
              )
            case "exact":
              return strings["transfer.quote_option.exact.title"](
                <FormattedQuoteRate value={candidate.quote.rate}/>
              )
          }
        }()}
        subtitle={function () {
          if (candidate.disabled) {
            return candidate.quoteOption.disabledExplanation ??
              strings["transfer.quote_option.disabled"]
          }

          switch (candidate.quoteOption.kind) {
            case "approximate":
              return strings["transfer.quote_option.approximate.subtitle"](
                <FormattedQuoteRate value={candidate.quote.rate}/>
              )
            case "exact":
              return strings["transfer.quote_option.exact.subtitle"]
          }
        }()}
        secondary={<FormattedMoney withFree={true} value={candidate.quoteOption.fee}/>}
        trailingIcon={<Icon name={"chevron-right"}/>}
        auxiliaryButton={<LearnMoreLink url={Links.helpCenter.articles.quoteOptions}/>}
        onClickAsync={onClickRow}
      />
    )
  })

  return (
    <TitleSubtitleLeftScreen
      title={strings["transfer.quote_option.title"]}
      subtitle={
        <WithLearnMoreSuffix url={Links.helpCenter.articles.quoteOptions}>
          {strings["transfer.quote_option.subtitle"]}
        </WithLearnMoreSuffix>
      }>
      <LimiterOverflow overflow={"16px"}>
        {rows}
      </LimiterOverflow>
    </TitleSubtitleLeftScreen>
  )
}

export default TransferQuoteOptionStep