import cx from "classnames";
import {SVGProps} from "react";
import {ReactComponent as SpinnerSVG} from "./svg/spinner.svg"
import {ReactComponent as SpinnerIosSvg} from "./svg/ios-spinner.svg"
import "./Spinner.scss"
import {PropsClassName} from "../../../helpers/props";

export const SpinnerIOS = (props: {size: number} & PropsClassName) => {
  return (
    <SpinnerIosSvg className={props.className} width={props.size} height={props.size}/>
  )
}

const Spinner = (props: SVGProps<any> & {
  color?: "light" | "dark"
  hidden?: boolean
}) => {
  return <SpinnerSVG {...props}
                     className={cx("spinner", {
                       "spinner--animated": !props.hidden,
                       "spinner--dark": props.color === "dark",
                       "spinner--hidden": props.hidden
                     }, props.className)}/>
}

export default Spinner