export class BranchStorage {
    static key: string = "branch_data"
    static setData(branchData: BranchData): void {
        localStorage.setItem(this.key, JSON.stringify(branchData));
    }

    static getData(): BranchData | null {
        const data = localStorage.getItem(this.key);
        if (data) {
            return JSON.parse(data);
        }
        return null;
    }
}