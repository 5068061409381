import ButtonBar from "../../components/ui/ButtonBar";
import {Button} from "../../components/ui/Buttons";
import RecipientList from "./RecipientList";
import {Recipient} from "../../components/domain/recipients/domain";
import {PropsOnComplete} from "../../helpers/props";
import {NavStackRoot} from "../../components/navigation/NavStack";
import {useRecipientCreateController} from "./create/recipient-create-controller";
import {useStrings} from "../../strings";
import React, {useMemo} from "react";
import {TitleSubtitleLeftScreen} from "../../components/ui/screen/TitleSubtitleScreen";
import Limiter from "../../components/ui/Limiter";
import NavBar from "../../components/navigation/NavBar";
import {Currency} from "../../helpers/money";
import {useNavigate} from "../../routes/router-dom-wrapper/hooks";
import {Links} from "../../helpers/Links";
import {useRecipientsQuery} from "../../api/recipients";

export const RootRecipientsPage = () => {
  const strings = useStrings()
  const navigate = useNavigate()
  return (
    <NavStackRoot
      root={RecipientListStep}
      ctx={{}}
      wrapper={(navStack, children) => (
        <Limiter className={"flex-1"}>
          <NavBar className={"px-0"}/>
          {children}
        </Limiter>
      )}
      args={{
        title: strings["root.recipient.title"],
        selectable: false,
        onComplete: async (recipient: Recipient) => {
          navigate(Links.atlantic.recipient.details(recipient.recipientId))
        }
      }}
    />
  )
}

type Props = {
  currency?: Currency,
  recipients?: Recipient[]
  title?: React.ReactNode
} & PropsOnComplete<Recipient>;

export const RecipientListStep = (props: Props) => {
  const recipientCreateController = useRecipientCreateController();
  const strings = useStrings()
  const recipientsQuery = useRecipientsQuery();

  const recipientsListResponse = recipientsQuery.recipient.list.useQuery()

  const recipients = props.recipients ?? recipientsListResponse.data

  const filteredRecipients = useMemo(() => {
    if (props.currency === undefined) return recipients
    return recipients?.filter((recipient) => {
      return recipient.bankAccount.currency.code === props.currency?.code
    })
  }, [recipients, props.currency])

  return (
    <TitleSubtitleLeftScreen
      title={function (){
        if (props.title !== undefined) return props.title
        if (filteredRecipients === undefined || filteredRecipients.length > 0) {
          return strings["transfer.recipient.select_a_recipient"]
        }
        return strings["add_recipient.add_recipient.title"]
      }()}
      subtitle={
        <ButtonBar className={""} align={"start"}>
          <Button
            type={"button"}
            size={"fit"}
            title={strings["add_recipient.add_recipient.title"]}
            color={"primary-10"}
            onClickAsync={() => recipientCreateController({
              isCurrencyEditable: props.currency === undefined,
              currency: props.currency,
              onComplete: props.onComplete
            })}
            iconLeft={"plus"}
          />
        </ButtonBar>
      }>

      <RecipientList
        recipientCurrency={props.currency}
        onComplete={(recipient) => props.onComplete(recipient)}
      />
    </TitleSubtitleLeftScreen>
  )
};