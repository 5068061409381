import {InterfaceTemplates, InterfaceTemplateValues} from "../domain";
import {CommonInterfaceTemplateProps} from "./common";
import {Picker, PickerOption} from "../../../ui/Picker";

export const InterfaceTemplatePickerInput = (
  {template, value, ...props}:
    CommonInterfaceTemplateProps<InterfaceTemplates.Picker, InterfaceTemplateValues.Picker>
) => {

  const options: PickerOption[] = template.options.map((option) => ({
    value: option.id,
    label: option.title,
  }))

  return (
    <Picker
      placeholder={template.title ?? ""}
      errored={props.error}
      value={options.find((option) => option.value === value.pickerValue.id) ?? null}
      options={options}
      onChange={
        (newValue) => {
          props.onChange({
            kind: "picker",
            pickerValue: template.options.find((option) => option.id === newValue?.value) ?? {
              id: newValue?.value ?? "",
              title: newValue?.value ?? "",
            }
          })
        }
      }
    />
  )
}
