import Limiter, {LimiterOverflow} from "../../components/ui/Limiter";
import {TitleSubtitleLeftScreen} from "../../components/ui/screen/TitleSubtitleScreen";
import {Recipient} from "../../components/domain/recipients/domain";
import RecipientAvatar from "../../components/domain/recipients/RecipientAvatar";
import {useRecipientsQuery} from "../../api/recipients";
import {withParametricRouter} from "../../routes/parameteric-router";
import RecipientView from "../../components/domain/recipients/RecipientView";
import NavBar from "../../components/navigation/NavBar";
import {WithSkeleton} from "../../components/ui/Skeleton";
import {Section} from "../../components/ui/collections/Section";
import {useStrings} from "../../strings";
import {useTransfersQuery} from "../../api/transfers";
import {useAlertStack} from "../../providers/alert-stack";
import TransferRow from "../../components/transfers/TransferRow";
import {Links} from "../../helpers/Links";
import CurrencyName from "../../components/ui/CurrencyName";
import {WithLearnMoreSuffix} from "../../components/ui/LearnMore";
import PortalsView from "./PortalsView";


type RecipientDetailsPageParams = {
  id: string
}

export const RecipientDetailsPage =
  withParametricRouter<{}, "id">({requiredPathKeys: ["id"]})(({id: recipientId}: RecipientDetailsPageParams) => {
    const recipientsQuery = useRecipientsQuery()
    const {data: recipient} = recipientsQuery.recipient.get.useQuery(recipientId)

    return (
      <Limiter>
        <NavBar/>
        <RecipientDetailsScreen recipient={recipient}/>
      </Limiter>
    )
  })

export const TransfersSection = (props: { recipientId: string }) => {
  const strings = useStrings()
  const alertStack = useAlertStack()

  const transferQuery = useTransfersQuery()
  const {data: transferList} = transferQuery.list.byRecipient.useQuery(props.recipientId, {
    refetchInterval: undefined,
    onError: err => alertStack.showError(err)
  })

  if (!transferList || transferList.results.length === 0) {
    return null
  }

  return (
    <Section title={strings["transfers.list.title"]}>
      <LimiterOverflow overflow={"16px"}>
        {transferList.results.map(transfer => (
          <TransferRow
            key={transfer.transferId}
            transfer={transfer}
            trailingArrow={true}
            href={Links.atlantic.transfer.view(transfer.transferId)}
          />
        ))}
      </LimiterOverflow>
    </Section>
  )
}

const PortalsSection = (props: { recipient: Recipient }) => {
  const strings = useStrings()
  const alertStack = useAlertStack()

  const transferQuery = useTransfersQuery()
  const {data: portalDeliveryInfo, ...portalDeliveryInfoQuery} = transferQuery.portals.deliveryOption.info.useQuery(
    props.recipient?.bankAccount.currency.code, {
      refetchInterval: undefined,
      onError: err => alertStack.showError(err)
    })
  const chooseDeliveryOption = transferQuery.portals.deliveryOption.choose.useMutation({
    onError: err => alertStack.showError(err)
  })

  const recipientHasPortals = props.recipient.portals.length > 0

  if (!recipientHasPortals) return null

  return (
    <Section
      title={strings["portals.title"]}
      subtitle={
        <WithLearnMoreSuffix url={Links.helpCenter.articles.portals}>
          {strings["portals.subtitle"](
            <CurrencyName currency={props.recipient.bankAccount.currency}/>,
            props.recipient.displayName
          )}
        </WithLearnMoreSuffix>
      }
    >
      <PortalsView
        portals={props.recipient.portals}
        deliveryInfo={portalDeliveryInfo}
        onChangePortalOption={async (optionKind) => {
          await chooseDeliveryOption.mutateAsync(optionKind)
          await portalDeliveryInfoQuery.refetch()
        }}
      />
    </Section>
  )
}

export const RecipientDetailsScreen = ({recipient}: { recipient?: Recipient }) => {
  return (
    <TitleSubtitleLeftScreen
      title={
        <WithSkeleton
          value={recipient}
          fallback={Recipient.fallback}
          children={recipient => recipient.displayName ?? "Unknown"}/>
      }
      avatar={<RecipientAvatar size={"big"} recipient={recipient}/>}
    >
      <RecipientView recipient={recipient}/>
      {recipient && <PortalsSection recipient={recipient}/>}
      {recipient && <TransfersSection recipientId={recipient.recipientId}/>}
    </TitleSubtitleLeftScreen>
  )
}