import SideIllustrationLayout from "../ui/layout/unauthorized/SideIllustrationLayout";
import {
  InterfaceTemplateSingleSelectForm,
} from "../domain/interface-template/screens/InterfaceTemplateSingleSelectScreen";
import {InlineButton} from "../ui/Buttons";
import Limiter, {LimiterOverflow} from "../ui/Limiter";
import {useClientApi} from "../../api/client";
import Caption from "../ui/Caption";
import {TitleSubtitleCenterScreen} from "../ui/screen/TitleSubtitleScreen";
import OnboardingScreen from "./OnboardingScreen";
import {useStrings} from "../../strings";
import {Links} from "../../helpers/Links";
import {InterfaceTemplates, InterfaceTemplateValues} from "../domain/interface-template/domain";
import {useState} from "react";
import {useNavigate} from "../../routes/router-dom-wrapper/hooks";
import {useConfig} from "../../hooks/config";

const QuestionerScreen = () => {
  const strings = useStrings()
  const config = useConfig()
  const clientApi = useClientApi()
  const navigate = useNavigate()
  const template: InterfaceTemplates.SingleSelect = new InterfaceTemplates.SingleSelect({
    title: "",
    subtitle: "",
    options: [
      {id: "friend", title: strings["signup.hear_about_us.friend"]},
      {id: "google", title: strings["signup.hear_about_us.google"]},
      {id: "news", title: strings["signup.hear_about_us.news"]},
      {id: "price-comparison-website", title: strings["signup.hear_about_us.price_comparison"]},
      {id: "social-media", title: strings["signup.hear_about_us.social_media"]},
      {id: "other", title: strings["general.other"]}
    ].map((option) => new InterfaceTemplateValues.SingleSelectOption({...option})),
    otherTextEntry: new InterfaceTemplates.Text({
      title: "",
      subtitle: "",
      placeholder: strings["signup.questioner.other_placeholder"]
    }),
  })

  const [otherValue, setOtherValue] = useState<InterfaceTemplateValues.Text>({kind: "text", textValue: ""})

  const onNavigateNext = () => navigate(
    config.enableFullWebAppExperience
      ? Links.atlantic.root.send
      : Links.atlantic.onboarding.download,
    {replace: true, isBackAllowed: false}
  )

  return (
    <SideIllustrationLayout sideIllustration={"document"}>
      <OnboardingScreen>
        <TitleSubtitleCenterScreen title={strings["signup.hear_about_us.title"]}>
          <Limiter contentMaxWidth={"460px"}>
            <LimiterOverflow overflow={"8px"}>
              <InterfaceTemplateSingleSelectForm
                titleAlign={"center"}
                template={template}
                otherValue={otherValue}
                onChangeOtherValue={setOtherValue}
                onComplete={async (ssv) => {
                  await clientApi.questionnaireAnswer("post-onboarding", {
                    value: {
                      option: {...ssv.singleSelectValue.option},
                      otherText: ssv.singleSelectValue.otherText ? {
                        text: ssv.singleSelectValue.otherText
                      } : undefined
                    }
                  }).finally(() => {
                    onNavigateNext()
                  })
                }}
              />
            </LimiterOverflow>
            <InlineButton
              className={"block mx-auto mt-[24px]"}
              type={"button"}
              title={<Caption size={"3-title"} text={strings["general.skip"]}/>}
              onClick={() => {
                onNavigateNext()
              }}
            />
          </Limiter>
        </TitleSubtitleCenterScreen>
      </OnboardingScreen>
    </SideIllustrationLayout>
  )
}

export default QuestionerScreen;