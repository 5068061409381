import ReactToggle from 'react-toggle'
import "react-toggle/style.css"
import "./Toggle.scss"
import cx from "classnames";
import {WithSkeletonFlag} from "./Skeleton";
import React, { useState } from 'react';

type Props = {
  disabled?: boolean
  checked?: boolean
  onChange?: (checked: boolean) => void
  hidden?: boolean
  isLoading?: boolean
}

const Toggle = (props: Props) => {
  const [instantAnim, setInstantAnim] = useState(props.checked === undefined || props.isLoading)

  return (
    <WithSkeletonFlag isLoading={props.isLoading ?? false}>
      <ReactToggle
        className={cx({
          "atlantic-toggle": true,
          "atlantic-toggle--hidden": props.hidden,
          "atlantic-toggle--instant-anim": instantAnim,
        })}
        icons={false}
        onChange={(e) => {
          setInstantAnim(false)
          props.onChange?.(e.target.checked)
        }}
        checked={props.checked ?? false}
        disabled={props.disabled ?? props.checked === undefined ?? false}
      />
    </WithSkeletonFlag>
  )
}

export default Toggle;