import {PortalDeliveryOption} from "../../../models/PortalDeliveryInfo"
import {useStrings} from "../../../strings";

type Props = {
  option: PortalDeliveryOption
}

const PortalDeliveryOptionSpeed = ({option}: Props) => {
  const strings = useStrings()
  if (option.isInstant) {
    return strings["delivery.instant"]
  }

  if (option.deliverySpeed === 0) {
    return strings["delivery.same_day"]
  }

  return strings["delivery.business_days"](option.deliverySpeed)
}

export default PortalDeliveryOptionSpeed;