import {CommonInputProps, Input, InputComponentProps} from "./Inputs";
import ReactPhoneInput from 'react-phone-number-input/input'
import {suggestPhone} from "../../../helpers/phone";
import { useState } from "react";

type PhoneInputProps = CommonInputProps & {
  suggestCountryCode?: string
}

const PhoneInputInner = (props: InputComponentProps) => {
  return <ReactPhoneInput
    {...props}
    ref={props.innerRef}
    onChange={(phoneNum) => {
      props.onChange && props.onChange(phoneNum as string || "")
    }}/>
}

const PhoneInput = ({...props}: PhoneInputProps) => {
  const [userTouched, setUserTouched] = useState<boolean>(false)
  const value = function() {
    const suggestedPhone = props.suggestCountryCode && suggestPhone(props.suggestCountryCode)
    if (userTouched || (props.value && props.value.length > 0)) return props.value
    if (props.suggestCountryCode && props.suggestCountryCode !== "") {return `+${suggestedPhone}`}
    return ""
  }()
  return (
    <Input {...props}
           type={"tel"}
           autoComplete={"tel"}
           inputMode={"tel"}
           capitalization={"none"}
           components={{Input: PhoneInputInner}}
           value={value}
           onChange={(value) => {
              if (!userTouched) setUserTouched(true)
              props.onChange && props.onChange(value)
           }}
    />
  )
}

export default PhoneInput
