import {atom, useRecoilState} from "recoil";
import {useCallback} from "react";

const loadingBlocker = atom<number>({
  key: "loadingBlocker",
  default: 1,
})

export const useLoadingBlockerState = () => {
  const [loadingCnt, setLoadingCnt] = useRecoilState(loadingBlocker)

  return {
    cnt: loadingCnt,
    inc: useCallback(() => {
      setLoadingCnt(cnt => cnt + 1)
    }, [setLoadingCnt]),
    dec: useCallback(() => {
      setLoadingCnt(cnt => cnt - 1)
    }, [setLoadingCnt]),
  }
}
