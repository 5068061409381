import {useStrings} from "../../../../strings";
import {useFileState} from "../../../../helpers/file-state";
import {useCopyToClipboard} from "../../../../helpers/hooks";
import {BodyCode, BodyTitle} from "../../../ui/Labels";
import Icon from "../../../ui/icons/Icon";
import Caption from "../../../ui/Caption";
import DropzoneFileInput from "../../../ui/input/DropzoneFileInput";
import Collapsable from "../../../ui/Collapsable";
import {FileListPreview} from "../../../ui/input/FilePreview";
import ButtonBar from "../../../ui/ButtonBar";
import {Button} from "../../../ui/Buttons";
import {TitleSubtitleScreen} from "../../../ui/screen/TitleSubtitleScreen";
import {InterfaceTemplates} from "../domain";
import {PropsOnComplete} from "../../../../helpers/props";

type Props = {
  titleAlign: "left" | "center"
  template: InterfaceTemplates.Document
} & PropsOnComplete<File[]>

export const InterfaceTemplateDocumentScreen = ({template, ...props}: Props) => {
  const strings = useStrings()

  const fileState = useFileState()
  const [_, setCopiedText] = useCopyToClipboard()

  const detailsBlock = (
    <ul className={"flex flex-col gap-4"}>
      <BodyTitle text={strings["transfer_source_of_funds.details_title"]}/>
      {template.details.map((detail, index) => {
        return <li key={index} className={"flex items-center"}>
          <Icon name={"dot"} className={"self-start w-[5px]"}/>
          <Caption className={"ml-[8px]"} size={"2"} text={detail}/>
        </li>
      })}
    </ul>
  )

  const submitFilesBlock = (
    <div className={"mt-8"}>
      <BodyTitle text={strings["web.transfer_source_of_funds.upload_title"]}/>
      <DropzoneFileInput
        className={"mt-4 mb-4"}
        title={strings["document.dropzone.title"]}
        subtitle={strings["document.dropzone.subtitle"]}
        rejectedMessage={strings["document.dropzone.rejectedMessage"]}
        allowedFileTypes={["image", "pdf"]}
        onAddFiles={fileState.addFiles}
      />
      {<Collapsable expanded={fileState.files.length > 0}>
        <Caption className={"mb-4"} size={"3"} text={strings["document.dropzone.uploadedFiles"]}/>
        <FileListPreview files={fileState.files} onRemove={fileState.removeFile}/>
        <ButtonBar className={"mt-8"} align={"center"}>
          <Button type={"button"} title={strings["general.submit"]} size={"max"} color={"primary-black"}
                  onClickAsync={() => props.onComplete(fileState.files)}/>
        </ButtonBar>
      </Collapsable>
      }
    </div>
  )

  const emailDocumentBlock = (
    <div className={"mt-8"}>
      <BodyTitle text={strings["web.transfer_source_of_funds.email_title"]}/>
      <Caption
        className={"mt-4 mb-8"}
        size={"3"}
        text={strings["transfer_source_of_funds.email_subtitle"]}
      />
      <div className={"flex gap-4 items-center flex-wrap justify-center"}>
        <BodyCode className={"min-w-[max-content]"} text={template.emailForSubmission}/>
        <Button className={"min-w-[max-content]"}
                type={"button"}
                title={strings["transfer_source_of_funds.copy_email"]} size={"fit"}
                color={"primary-10"}
                onClick={() => setCopiedText(template.emailForSubmission)}
        />
      </div>
      <ButtonBar className={"mt-8 pb-[40px]"} align={"center"}>
        <Button type={"button"}
                title={strings["transfer_source_of_funds.emailed_all_docs"]}
                size={"big"}
                color={"primary-black"}
                onClickAsync={() => props.onComplete([])}
        />
      </ButtonBar>
    </div>
  )

  return (
    <TitleSubtitleScreen titleAlign={props.titleAlign} title={template.title}>
      {detailsBlock}
      {submitFilesBlock}
      {emailDocumentBlock}
    </TitleSubtitleScreen>
  )
}

export default InterfaceTemplateDocumentScreen
