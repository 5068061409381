import CurrencyIcon from "../../../components/ui/icons/CurrencyIcon";
import {Currency} from "../../../helpers/money";
import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  offset,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole,
  useTransitionStyles,
  useTypeahead
} from "@floating-ui/react";
import {useEffect, useRef, useState} from "react";
import {BodyTitle, Heading1} from "../../../components/ui/Labels";
import "./EstimateCurrencyInput.scss";
import ChevronDown from "../../../components/ui/icons/ChevronDown";
import {InlineButton} from "../../../components/ui/Buttons";
import cx from "classnames";
import Icon from "../../../components/ui/icons/Icon";
import {useIntl} from "react-intl";
import AbstractRow from "../../../components/ui/collections/rows/AbstractRow";

type Props = {
  value: Currency,
  currencies: Currency[],
  onChange: (currency: Currency) => void,
  styles?: {
    currencyLabel?: React.CSSProperties
  }
}

const CurrencyRow = (props: { currency: Currency, active: boolean, selected: boolean }) => {
  const intl = useIntl();
  return (
    <AbstractRow
      className={"py-[16px] px-[13px] flex flex-row items-center"}
      active={props.active}
      clickable={true}
    >
      <CurrencyIcon className={"mr-[18px]"} currencyCode={props.currency.code} size={"big"}/>
      <BodyTitle className={"flex-1"}>{props.currency.name(intl)}</BodyTitle>
      <Icon className={"ml-[18px]"} name={"check-circle-solid"}
            hidden={!props.selected}/>
    </AbstractRow>
  )
}

const EstimateCurrencyInput = (props: Props) => {
  const intl = useIntl();
  const stringOptions = props.currencies.map((currency) => currency.name(intl));

  const [isOpen, setIsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const selectedIndex = props.currencies.findIndex((option) => option.eq(props.value));

  const {refs, floatingStyles, context} = useFloating({
    placement: "bottom-end",
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(20),
      flip({padding: 10}),
      size({
        apply({rects, elements, availableHeight}) {
          Object.assign(elements.floating.style, {
            maxHeight: `calc(min(${availableHeight}px, 356px))`,
            maxWidth: "402px",
            width: `402px`,
            minWidth: `${rects.reference.width}px`
          });
        },
        padding: 10
      })
    ]
  });

  const dismiss = useDismiss(context);
  const {isMounted, styles: transitionStyles} = useTransitionStyles(context, {
    duration: 200,
    close: {
      opacity: 0,
    },
    open: {
      opacity: 1,
    }
  });

  const listRef = useRef<Array<HTMLElement | null>>([]);
  const listContentRef = useRef(stringOptions);
  const isTypingRef = useRef(false);
  useEffect(() => {
    listContentRef.current = stringOptions;
  }, [stringOptions])

  const click = useClick(context, {event: "mousedown"});
  const role = useRole(context, {role: "listbox"});
  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    selectedIndex,
    onNavigate: setActiveIndex,
    // This is a large list, allow looping.
    loop: true,
  });

  const typeahead = useTypeahead(context, {
    listRef: listContentRef,
    activeIndex,
    selectedIndex,
    onMatch: isOpen ? setActiveIndex : (idx) => props.onChange(props.currencies[idx]),
    // MARK: we can implement custom search here (for example fully matching)
    // findMatch: (list, typedString) => {
    //   console.log(typedString)
    //   return list[0]
    // },
    onTypingChange(isTyping) {
      isTypingRef.current = isTyping;
    },
  });

  const {getReferenceProps, getFloatingProps, getItemProps} = useInteractions(
    [dismiss, role, listNav, typeahead, click]
  );

  const onSelect = (index: number) => {
    props.onChange(props.currencies[index]);
    setIsOpen(false);
  };

  const {onClick: onRefClick, ...refProps} = getReferenceProps()

  return (
    <div className={"estimate-currency-input"}>
      <InlineButton className={cx("estimate-currency-input__btn", {"estimate-currency-input__btn--open": isOpen})}
                    type={"button"}
                    indications={false}
                    ref={refs.setReference}
                    onClickDOM={onRefClick as any}
                    {...refProps}
      >
        <div className={"flex flex-row items-center"}>
          <Heading1 className={"flex justify-start"} mobileStyles={false} style={props.styles?.currencyLabel}
                    text={props.value.code}/>
          <ChevronDown invertedY={isOpen}/>
        </div>
      </InlineButton>
      {isMounted && (
        <FloatingFocusManager context={context} modal={false} returnFocus={true}>
          <div ref={refs.setFloating}
               className={"estimate-currency-input__menu"}
               style={{...floatingStyles, ...transitionStyles}}
               {...getFloatingProps()}
          >
            <div className={"estimate-currency-input__menu-list"}>
              {props.currencies.map((currency, i) => (
                <div
                  key={currency.code}
                  className={cx("estimate-currency-input__menu-item")}
                  ref={(node) => {
                    listRef.current[i] = node;
                  }}
                  role="option"
                  tabIndex={i === activeIndex ? 0 : -1}
                  aria-selected={i === selectedIndex && i === activeIndex}
                  {...getItemProps({
                    // Handle pointer select.
                    onClick() {
                      onSelect(i);
                    },
                    // Handle keyboard select.
                    onKeyDown(event) {
                      if (event.key === "Enter") {
                        event.preventDefault();
                        onSelect(i);
                      }

                      if (event.key === " " && !isTypingRef.current) {
                        event.preventDefault();
                        onSelect(i);
                      }
                    },
                  })}>
                  <CurrencyRow currency={currency} active={i === activeIndex} selected={selectedIndex === i}/>
                </div>
              ))}
            </div>
          </div>
        </FloatingFocusManager>
      )}
    </div>
  )
};

export default EstimateCurrencyInput;