import {VerboseError} from "../api/axios";
import {useStrings} from "../strings";
import {useCallback} from "react";

const ERR = Symbol('ERR')
export type Err = {
  [ERR]: true
  error: unknown
}

export function isErr(x: unknown): x is Err {
  return typeof x === 'object' && x != null && ERR in x
}

export function NewErr(message: string): Err {
  return {[ERR]: true, error: message}
}

export const useDisplayErrorMessage = () => {
  const stings = useStrings()

  return useCallback((err: any): string => {
    if (typeof err === "string") {
      return err
    }

    return VerboseError.fromError(err)?.errorMessage ?? stings["generic.error.description"]
  }, [stings])
}