import {useStrings} from "../../../strings";
import {Button} from "../../ui/Buttons";
import AddressInputGroup, {
  AddressInputValue,
  fromApiAddress,
  toApiAddressModel
} from "../../ui/input/AddressInputGroup";
import {useBusinessOnboardingQuery} from "../../../api/onboarding";
import {Form, InputGroup, TextInput} from "../../ui/input/Inputs";
import ButtonBar from "../../ui/ButtonBar";
import {useEffect, useMemo, useState} from "react";
import {useAsyncLoading} from "../../../helpers/hooks";
import {useAlertStack} from "../../../providers/alert-stack";
import {TitleSubtitleCenterScreen} from "../../ui/screen/TitleSubtitleScreen";
import {useAuthorizedAuthValue} from "../../../atoms/auth";

type State = {
  inputs?: {
    legalName: string
    identifier: string
    registeredAddress: AddressInputValue
    tradingAddress: AddressInputValue
  }
}

type Props = {
  onComplete: () => Promise<void>
}

const BusinessInfoReview = (props: Props) => {
  const strings = useStrings()
  const authState = useAuthorizedAuthValue()
  const alertStack = useAlertStack()
  const [state, setState] = useState<State>({
    inputs: undefined,
  })

  const {isLoading, asyncWithLoading} = useAsyncLoading(false)

  const businessOnboardingQuery = useBusinessOnboardingQuery()
  const {data: application} = businessOnboardingQuery.application.get.useQuery(authState.user.userId)
  const updateLegalName = businessOnboardingQuery.application.updateLegalName.useMutation(authState.user.userId)
  const updateIdentifier = businessOnboardingQuery.application.updateIdentifier.useMutation(authState.user.userId)
  const updateRegisteredAddress = businessOnboardingQuery.application.updateRegisteredAddress.useMutation(authState.user.userId)
  const updateTradingAddress = businessOnboardingQuery.application.updateTradingAddress.useMutation(authState.user.userId)
  const checksConfirm = businessOnboardingQuery.application.checksConfirm.useMutation(authState.user.userId)

  useEffect(() => {
    if (application === undefined) {
      return
    }

    setState((prev) => ({
      ...prev,
      inputs: prev.inputs ? prev.inputs : {
        legalName: application.payload.info.legalName ?? "",
        identifier: application.payload.info.identifier ?? "",
        registeredAddress: fromApiAddress(application.payload.info.registeredAddress),
        tradingAddress: fromApiAddress(application.payload.info.tradingAddress),
      }
    }))

  }, [application]);

  const onChange = (fieldName: "legalName" | "identifier") => {
    return (value: string) => {
      setState((prev) => ({
        ...prev,
        inputs: prev.inputs !== undefined ? {
          ...prev.inputs,
          [fieldName]: value
        } : undefined,
      }))
    }
  }

  const onChangeAddress = (label: "registeredAddress" | "tradingAddress") => (newValue: AddressInputValue) => {
    setState((prev) => ({
      errors: {
        [label]: undefined,
      },
      inputs: prev.inputs && {
        ...prev.inputs,
        [label]: newValue
      }
    }))
  }


  const {data: acceptableCountryList} = businessOnboardingQuery.acceptableCountriesList.useQuery({onError: err => alertStack.showError(err)})
  const countryList = useMemo(() =>
      acceptableCountryList?.payload?.countries?.map((c) => c.countryCode),
    [acceptableCountryList])

  const onUpdateLegalName = () => {
    return asyncWithLoading(() => updateLegalName.mutateAsync({legalName: state.inputs?.legalName ?? ""}))
  }
  const onUpdateIdentifier = () => {
    return asyncWithLoading(() => updateIdentifier.mutateAsync({identifier: state.inputs?.identifier ?? ""}))
  }
  const onUpdateRegisteredAddress = () => {
    return asyncWithLoading(() => updateRegisteredAddress.mutateAsync({address: toApiAddressModel(state.inputs?.registeredAddress)}))
  }
  const onUpdateTradingAddress = () => {
    return asyncWithLoading(() => updateTradingAddress.mutateAsync({address: toApiAddressModel(state.inputs?.tradingAddress)}))
  }

  const onFormSubmit = () => asyncWithLoading(async () => {
    await Promise.all([
      onUpdateLegalName(),
      onUpdateIdentifier(),
      onUpdateRegisteredAddress(),
      onUpdateTradingAddress()
    ]).catch((_e) => {
    })
    await checksConfirm.mutateAsync({checks: ["business_info"]}).catch((e) => alertStack.showError(e))
    await props.onComplete()
  })

  return <TitleSubtitleCenterScreen
    title={strings["account.business_info"]}
    subtitle={strings["add_recipient.review.subtitle"]}>
    <Form onSubmit={onFormSubmit}>
      <InputGroup name={strings["business_signup.legal_name.title"]}>
        <TextInput capitalization={"words"}
                   onChange={onChange("legalName")}
                   error={updateLegalName.error}
                   placeholder={strings["business_signup.legal_name.placeholder"]}
                   disabled={isLoading}
                   value={state.inputs?.legalName}
        />
      </InputGroup>

      <InputGroup name={strings["business_signup.identifier.title"]}>
        <TextInput capitalization={"words"}
                   onChange={onChange("identifier")}
                   error={updateIdentifier.error}
                   placeholder={strings["business_signup.identifier.placeholder"]}
                   disabled={isLoading}
                   value={state.inputs?.identifier}
        />
      </InputGroup>

      <AddressInputGroup
        name={strings["business_signup.registered_address.title"]}
        availableCountries={countryList}
        disabled={isLoading}
        value={state.inputs?.registeredAddress}
        error={updateRegisteredAddress.error}
        onChange={onChangeAddress("registeredAddress")}
      />
      <AddressInputGroup
        name={strings["business_signup.trading_address.title"]}
        availableCountries={countryList}
        disabled={isLoading}
        value={state.inputs?.tradingAddress}
        error={updateTradingAddress.error}
        onChange={onChangeAddress("tradingAddress")}
      />

      <ButtonBar align={"center"}>
        <Button type={"submit"} title={strings["general.continue"]} size={"max"} color={"primary-black"} loading={isLoading}/>
      </ButtonBar>
    </Form>
  </TitleSubtitleCenterScreen>
}

export default BusinessInfoReview