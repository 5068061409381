import {TitleSubtitleCenterScreenV2} from "../../../components/ui/screen/TitleSubtitleScreen";
import {useStrings} from "../../../strings";
import {Button} from "../../../components/ui/Buttons";
import Form, {EmailInput} from "../../../components/ui/input/Inputs";
import {useAsyncLoading} from "../../../helpers/hooks";
import {useAuthApi} from "../../../api/auth";
import {useState} from "react";
import {useNavStack} from "../../../atoms/nav-stack";
import PasswordResetStepEmailSent from "./PasswordResetStepEmailSent";

const PasswordResetStepForm = () => {
  const strings = useStrings()
  const authApi = useAuthApi()
  const navStack = useNavStack()

  const [email, setEmail] = useState("")
  const [error, setError] = useState<any>()

  const asyncLoading = useAsyncLoading(false)

  const onSubmit = () => asyncLoading.asyncWithLoading(async () => {
    try {
      await authApi.emailValidate({email: email})
    } catch (e) {
      setError(e)
      throw e
    }

    await authApi.passwordReset({
      email: email,
    })
    navStack.push(PasswordResetStepEmailSent, {})
  })

  return (
    <TitleSubtitleCenterScreenV2
      title={strings["reset_password.title"]}
      subtitle={strings["reset_password.subtitle"]}
      primaryButton={btnProps =>
        <Button {...btnProps} type={"submit"} title={strings["general.continue"]}/>
      }
    >{ ({buttonBar}) =>
      <Form onSubmit={onSubmit}>
        <EmailInput
          placeholder={strings["signup.email.placeholder"]}
          value={email}
          onChange={(newValue) => {
            setEmail(newValue)
            setError(undefined)
          }}
          onValidate={email => authApi.emailValidate({email})}
          error={error}
        />
        {buttonBar}
      </Form>
    }
    </TitleSubtitleCenterScreenV2>
  )
}

export default PasswordResetStepForm;