import {Button} from "../../ui/Buttons";
import {EmailInput, Form, InputGroup, InputGroupRow, TextInput} from "../../ui/input/Inputs";
import ButtonBar from "../../ui/ButtonBar";
import {useMemo, useState} from "react";
import {useBusinessOnboardingQuery} from "../../../api/onboarding";
import {useAsyncLoading} from "../../../helpers/hooks";
import AddressInputGroup, {AddressInputValue, toApiAddressModel} from "../../ui/input/AddressInputGroup";
import {useAlertStack} from "../../../providers/alert-stack";
import {BusinessContactRole} from "../../../api/whales/Common";
import {TitleSubtitleCenterScreen} from "../../ui/screen/TitleSubtitleScreen";
import {OnboardingCtx} from "./BusinessOnboardingScreen";
import {PropsOnComplete} from "../../../helpers/props";
import {useSideIllustration} from "../../../atoms/side-illustration";
import {useStrings} from "../../../strings";
import PhoneInput from "../../ui/input/PhoneInput";
import DateInputGroup, {DateInputValue} from "../../ui/input/DateInputGroup";

type Props = OnboardingCtx & {
  title: string
  needPhone: boolean
  needEmail: boolean
  contactRoles: BusinessContactRole[]
} & PropsOnComplete

type State = {
  inputs: {
    firstName: string
    lastName: string
    birthdate: DateInputValue
    address: AddressInputValue
    email?: string
    phone?: string
  },
}

const AbstractBusinessContactForm = (props: Props) => {
  const strings = useStrings()
  const errorStack = useAlertStack()
  const businessOnboardingQuery = useBusinessOnboardingQuery()

  const [state, setState] = useState<State>({
    inputs: {
      firstName: "",
      lastName: "",
      birthdate: DateInputValue.empty(),
      address: {
        addressLine1: "",
        addressLine2: "",
        city: "",
        postcode: "",
        stateOrProvince: "",
        countryCode: null
      },
      email: props.needEmail ? "" : undefined,
      phone: props.needPhone ? "" : undefined
    },
  })

  const {isLoading, asyncWithLoading} = useAsyncLoading(false)

  const createContact = businessOnboardingQuery.application.contact.create.useMutation()
  const onCreateContact = () => {
    return createContact.mutateAsync({
      firstName: state.inputs.firstName,
      lastName: state.inputs.lastName,
      birthdate: state.inputs.birthdate.toApiString() ?? "",
      email: state.inputs.email,
      phone: state.inputs.phone,
      address: toApiAddressModel(state.inputs.address),
      roles: props.contactRoles
    }).then(() => {
      // staleTime = 0 to force refetch
      return businessOnboardingQuery.application.get.preFetch(props.user.userId, undefined, 0)
    })
  }

  const onFormSubmit = () => {
    asyncWithLoading(
      () => onCreateContact().then(() => {
        return props.onComplete()
      }, (e) => errorStack.showError(e))
    )
  }

  const onChangeString = (fieldName: "firstName" | "lastName" | "birthdate" | "email" | "phone") => {
    return (value: string) => {
      setState((prev) => ({
        ...prev,
        inputs: {
          ...prev.inputs,
          [fieldName]: value
        },
      }))
    }
  }

  const onChangeBirthdate = (newValue: DateInputValue) => {
    setState((prev) => {
      if (!prev.inputs) {
        return prev
      }
      return {
        ...prev, inputs: {...prev.inputs, birthdate: newValue}
      }
    })
  }

  const {data: acceptableCountryList} = businessOnboardingQuery.acceptableCountriesList.useQuery({onError: err => errorStack.showError(err)})
  const countryList = useMemo(() =>
      acceptableCountryList?.payload?.countries?.map((c) => c.countryCode),
    [acceptableCountryList])

  return (
    <TitleSubtitleCenterScreen title={props.title}>
      <Form onSubmit={onFormSubmit}>
        <InputGroup name={strings["personal_info.name.title"]}>
          <InputGroupRow>
            <TextInput className="flex-1"
                       placeholder={strings["signup.name.first_name.placeholder"]}
                       disabled={isLoading}
                       autoComplete={"given-name"}
                       capitalization={"words"}
                       value={state.inputs?.firstName}
                       error={undefined}
                       onChange={onChangeString("firstName")}
            />
            <TextInput className={"flex-1"}
                       placeholder={strings["signup.name.last_name.placeholder"]}
                       disabled={isLoading}
                       autoComplete={"family-name"}
                       capitalization={"words"}
                       value={state.inputs?.lastName}
                       error={undefined}
                       onChange={onChangeString("lastName")}
            />
          </InputGroupRow>
        </InputGroup>

        <DateInputGroup
          name={strings["signup.dob.title"]}
          disabled={isLoading}
          value={state.inputs?.birthdate}
          error={undefined}
          onChange={onChangeBirthdate}
        />

        <AddressInputGroup
          name={strings["signup.address.title"]}
          disabled={isLoading}
          availableCountries={countryList}
          suggestedCountryCode={props.application.payload.info.registeredAddress?.countryCode}
          value={state.inputs.address}
          onChange={(newValue) => {
            setState((prev) => ({
              ...prev,
              inputs: {...prev.inputs, address: newValue,},
            }))
          }}
        />

        {props.needPhone && (
          <InputGroup name={strings["signup.phone_number.title"]}>
            <PhoneInput
              ariaLabel={strings["signup.phone_number.title"]}
              disabled={isLoading}
              suggestCountryCode={state.inputs.address.countryCode || props.application.payload.info.registeredAddress?.countryCode}
              value={state.inputs.phone}
              error={undefined}
              onChange={onChangeString("phone")}
            />
          </InputGroup>
        )}

        {props.needEmail && (
          <InputGroup name={strings["signup.email.placeholder"]}>
            <EmailInput
              placeholder={strings["signup.email.placeholder"]}
              disabled={isLoading}
              value={state.inputs.email}
              error={undefined}
              onChange={onChangeString("email")}
            />
          </InputGroup>
        )}

        <ButtonBar align={"center"}>
          <Button type={"submit"} title={strings["general.continue"]} size={"max"} color={"primary-black"} loading={isLoading}/>
        </ButtonBar>
      </Form>
    </TitleSubtitleCenterScreen>
  )
};

export const BusinessApplicantContactForm = (props: OnboardingCtx & PropsOnComplete) => {
  const strings = useStrings()
  useSideIllustration("profile-silhouette")

  return <AbstractBusinessContactForm
    {...props}
    title={strings["business_signup.applicant_info_form.title"]}
    needPhone={true}
    needEmail={false}
    contactRoles={["applicant"]}
  />
}

export const AnyBusinessContactForm = (props: OnboardingCtx & PropsOnComplete & {
  role: BusinessContactRole
}) => {
  const strings = useStrings()
  useSideIllustration("profile-2-silhouettes")

  return <AbstractBusinessContactForm
    {...props}
    title={strings["business_signup.key_people_form.title"]}
    needPhone={false}
    needEmail={true}
    contactRoles={[props.role]}
  />
}