import {VerificationProps} from "./verification-controller";
import {InterfaceTemplates, InterfaceTemplateValues} from "../domain/interface-template/domain";
import InterfaceTemplateTextScreen from "../domain/interface-template/screens/InterfaceTemplateTextScreen";
import {useVerificationApi} from "../../api/verification";
import { useState } from "react";

type Props = VerificationProps & {
  template: InterfaceTemplates.Text
}

const VerificationTextStepSubmit = (props: Props) => {
  const verificationApi = useVerificationApi()
  const [value, setValue] = useState<InterfaceTemplateValues.Text>({kind: "text", textValue: ""})
  return (
    <InterfaceTemplateTextScreen
      template={props.template}
      titleAlign={props.titleAlign}
      value={value}
      setValue={setValue}
      onComplete={async (value) => {
        await verificationApi.submit({
          verificationId: props.verification.id,
          value: {
            kind: "text",
            textValue: {
              text: value.textValue
            }
          }
        })
        await props.onComplete()
      }}
    />
  )
}

export default VerificationTextStepSubmit