import {TitleSubtitleLeftScreen} from "../../../components/ui/screen/TitleSubtitleScreen";
import {useStrings} from "../../../strings";
import {PropsTransferContext, useTransferCreateController} from "./transfer-create-controller";
import TransferView from "../TransferView";
import {Button, InlineButton} from "../../../components/ui/Buttons";
import React, {useState} from "react";
import ButtonBar from "../../../components/ui/ButtonBar";
import {useUserDefaultsValue} from "../../../atoms/user-defaults";
import {useNavStack} from "../../../atoms/nav-stack";
import DisclosureScreen from "../../../components/ui/screen/DisclosureScreen";
import {PropsOf} from "@emotion/react";
import {Check} from "../../../components/domain/common/check";


type State = {
  reference?: string
}

type Props = {} & PropsTransferContext
const TransferReviewStep = ({transferContext}: Props) => {
  const strings = useStrings()
  const navStack = useNavStack()
  const transferCreateController = useTransferCreateController()

  const [state, setState] = useState<State>({reference: undefined})
  const userDefaults = useUserDefaultsValue()
  const displaying = transferContext?.displaying(userDefaults)

  const buttonCompanion = function () {
    for (const check of transferContext.transferEstimate(userDefaults).checks ?? []) {
      switch (check.type) {
        case "transfer_disclosure":
          return (
            <InlineButton type={"button"} onClick={() => {
              if (check.meta?.kind === undefined) throw new Error("disclosure kind is undefined")
              navStack.push<PropsOf<typeof DisclosureScreen>, {}>(DisclosureScreen, {disclosureKind: check.meta.kind})
            }}>
              {strings["transfer.review.agree_to_terms.text"](
                <span className={"underline"}>{strings["transfer.review.agree_to_terms.underlined"]}</span>
              )}
            </InlineButton>
          )
      }
    }

    return undefined
  }()

  const acceptedChecks = function () {
    const originalChecks = transferContext.transferEstimate(userDefaults).checks ?? []
    let acceptedChecks: Check[] = []

    for (const check of originalChecks) {
      const checkCopy = Object.assign({}, check)
      switch (check.type) {
        case "transfer_disclosure":
          checkCopy.status = "accepted"
          break
        default:
          continue
      }
      acceptedChecks.push(new Check(checkCopy))
    }

    return acceptedChecks
  }()

  return (
    <TitleSubtitleLeftScreen
      title={strings["transfer.review.title"]}
      subtitle={displaying.deliverySubtitle(strings)}
    >
      <TransferView
        transferDisplaying={displaying}
        displayRecipient={true}
        recipient={transferContext?.recipient}
        reference={state.reference}
        setReference={reference =>
          setState((prev) => ({...prev, reference}))
        }
      />
      <ButtonBar
        sticky={"bottom"}
        buttonCompanion={buttonCompanion}>
        <Button
          type={"button"}
          title={strings["transfer.confirm.confirm_transfer"]}
          size={"big"}
          color={"primary-black"}
          onClickAsync={() => {

            return transferCreateController.onConfirm(transferContext, acceptedChecks, state.reference)
          }}
        />
      </ButtonBar>
    </TitleSubtitleLeftScreen>
  )
}

export default TransferReviewStep