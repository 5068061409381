import {CountryCode} from "../../helpers/countries";
import {Address} from "../../models/address";
import FixedFormatter from "./FixedFormatter";
import Formatter from "./Formatter";


class GbPostcodeFormatter extends Formatter {
  asYouTypePlaceholder() {
    return undefined
  }

  iMaskOptions() {
    const patterns = ["@@#@ #@@", "@#@ #@@", "@# #@@", "@## #@@", "@@# #@@", "@@## #@@"]
    const formatters = patterns.map((pattern) => FixedFormatter.pattern(pattern).uppercase())
    return {
      mask: formatters.map((formatter) => formatter.iMaskOptions()),
    }
  }

  isNumericOnly(): boolean {
    return false;
  }
}

const stateOrProvinceFormatters: {
  [k: string]: FixedFormatter
} = {
  [CountryCode.Australia]: FixedFormatter.uppercaseLetters(3),
  [CountryCode.Canada]: FixedFormatter.uppercaseLetters(3),
  [CountryCode.Italy]: FixedFormatter.uppercaseLetters(2),
  [CountryCode.UnitedStates]: FixedFormatter.uppercaseLetters(2)
}

const postcodeFormatters: {
  [k: string]: Formatter
} = {
  [CountryCode.Australia]: FixedFormatter.digits(4),
  [CountryCode.Belgium]: FixedFormatter.digits(4),
  [CountryCode.Spain]: FixedFormatter.digits(5),
  [CountryCode.Canada]: FixedFormatter.pattern("*** ***").uppercase(),
  [CountryCode.CzechRepublic]: FixedFormatter.pattern("### ##"),
  [CountryCode.Germany]: FixedFormatter.digits(5),
  [CountryCode.Denmark]: FixedFormatter.digits(4),
  [CountryCode.France]: FixedFormatter.digits(5),
  [CountryCode.UnitedKingdom]: new GbPostcodeFormatter(),
  [CountryCode.Italy]: FixedFormatter.digits(5),
  [CountryCode.Norway]: FixedFormatter.digits(4),
  [CountryCode.Poland]: {format: "##-###"},
  [CountryCode.Poland]: FixedFormatter.pattern("##-###"),
  [CountryCode.Sweden]: FixedFormatter.pattern("### ##"),
  [CountryCode.UnitedStates]: FixedFormatter.digits(5)
}

export const useAddressPartsFormatter = () => (countryCode: string): {
  postcode: Formatter | null
  stateOrProvince: Formatter | null
} => {
  return {
    postcode: postcodeFormatters[countryCode] ?? null,
    stateOrProvince: stateOrProvinceFormatters[countryCode] ?? null
  }
}

type RemainderElement = "comma" | "city" | "state-or-province" | "postcode"

const useAddressRemainder = () => {
  const addressPartsFormatter = useAddressPartsFormatter()
  return (address: Address): string => {
    const genericOrder: RemainderElement[] = ["postcode", "city", "state-or-province"]
    const customRemainderElement: Record<string, RemainderElement[]> = {
      [CountryCode.Australia]: ["city", "state-or-province", "postcode"],
      [CountryCode.Belgium]: ["postcode", "city"],
      [CountryCode.Canada]: ["city", "state-or-province", "postcode"],
      [CountryCode.CzechRepublic]: ["postcode", "city"],
      [CountryCode.Germany]: ["postcode", "city"],
      [CountryCode.Denmark]: ["postcode", "city"],
      [CountryCode.Spain]: ["postcode", "city"],
      [CountryCode.France]: ["postcode", "city"],
      [CountryCode.UnitedKingdom]: ["city", "postcode"],
      [CountryCode.Italy]: ["postcode", "city", "state-or-province"],
      [CountryCode.Norway]: ["postcode", "city"],
      [CountryCode.Poland]: ["postcode", "city"],
      [CountryCode.Sweden]: ["postcode", "city"],
      [CountryCode.UnitedStates]: ["city", "comma", "state-or-province", "postcode"],
    }

    const formatList = customRemainderElement[address.countryCode] ?? genericOrder
    return formatList.map((element): string => {
      switch (element) {
        default:
          return ""
        case "comma":
          return ","
        case "city":
          return address.city
        case "state-or-province":
          return address.stateOrProvince
            ? (addressPartsFormatter(address.countryCode).stateOrProvince?.format(address.stateOrProvince) ?? address.stateOrProvince)
            : ""
        case "postcode":
          return addressPartsFormatter(address.countryCode).postcode?.format?.(address.postcode) ?? address.postcode
      }
    }).join(" ").replaceAll(" ,", ",")
  }
}


export const useAddressFormatter = () => {
  const addressRemainder = useAddressRemainder()
  const formatToString = (address: Address, format: "one-line"): string => {
    switch (format) {
      case "one-line":
        let result = address.line1
        if (address.line2) {
          result += `, ${address.line2}`
        }
        result += `, ${addressRemainder(address)}`
        return result
    }
  }
  return {
    formatToString
  }
}


type AddressFormatStyle = "one-line"

type Props = {
  value: Address
  style: AddressFormatStyle
};

// WEB-368: Add more formats
const FormattedAddress = ({value, style}: Props) => {
  const addressFormatter = useAddressFormatter()
  switch (style) {
    case "one-line":
      return <>{addressFormatter.formatToString(value, style)}</>
  }
}

export default FormattedAddress;