import cx from "classnames";
import React from "react";
import {useDelayUnmount} from "../../helpers/hooks";
import {defaultAnimDurationMillis} from "../../variables";
import './Caption.scss'
import Skeleton from "./Skeleton";

export type CaptionSize = "2" | "2-title" | "3" | "3-title" | "3-medium"

type Props = {
  className?: string
  text?: React.ReactNode;
  size?: CaptionSize
  kind?: "text" | "button" | "link"
  color?: "red" | "green" | "primary" | "primary-50",
} & React.PropsWithChildren & React.HtmlHTMLAttributes<any>

const Caption = (props: Props) => {
  const {className, size, kind, color, text, children, ...rest} = props

  let sizeClassName = "caption"
  if (size) {
    sizeClassName += `--${size}`
  }

  const elementClassName = cx("block", "caption", sizeClassName, color && `caption--${color}`, kind && `caption-${kind}`, className)

  const isLoading = text === undefined && children === undefined
  const skeletonMount = useDelayUnmount(isLoading, defaultAnimDurationMillis)

  return (
    <span {...rest} className={elementClassName}>
      {skeletonMount.shouldRender && <>&#8203;<Skeleton hidden={!skeletonMount.shouldShow}/></>}
      {props.text}
      {props.children}
    </span>
  )
};

export default Caption;