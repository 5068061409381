import {DiscountedFixedFee, DiscountedTotalFee} from "../domain/transfers/DiscountedFee";
import FormattedMoney from "../formatters/money/FormattedMoney";
import {TooltipView} from "../ui/Tooltip";
import {LimiterOverflow} from "../ui/Limiter";
import {useStrings} from "../../strings";
import React from "react";
import {TransferDisplaying} from "../../models/transfers/TransferDisplaying";
import DefaultRow from "../ui/collections/rows/DefaultRow";

type Props = {
  displaying: TransferDisplaying
}

const TooltipViewFeeBreakdown = ({displaying}: Props) => {
  const strings = useStrings()
  const rowContent: {
    title: React.ReactNode
    feeText: React.ReactNode
  }[] = [{
    title: strings["transfer.fee_breakdown.fixed_fee"],
    feeText: <DiscountedFixedFee displaying={displaying}/>
  }, displaying.deliveryOption.fee.amount.gt(0) ? [{
    title: strings[`transfer.fee_breakdown.delivery.${displaying.deliveryOption.kind}`],
    feeText: <FormattedMoney value={displaying.deliveryOption.fee}/>
  }] : [], displaying.quoteOption.fee.amount.gt(0) ? [{
    title: strings[`transfer.fee_breakdown.delivery.${displaying.quoteOption.kind}`],
    feeText: <FormattedMoney value={displaying.quoteOption.fee}/>
  }] : []
  ].flatMap(x => x)

  return (
    <TooltipView title={strings["transfer.fee_breakdown.transfer_fees"]}>
      <div className={"min-w-[300px] mb-[-8px]"}>
        <LimiterOverflow overflow={"16px"}>
          {rowContent.map((row, index) => {
            return <DefaultRow key={index} title={row.title} secondary={row.feeText}
                               bordered={index === rowContent.length - 1}/>
          })}
          <DefaultRow
            bordered={false}
            title={strings["transfer.review.transfer.total_fees"]}
            secondary={<DiscountedTotalFee displaying={displaying}/>}
          />
        </LimiterOverflow>
      </div>
    </TooltipView>
  )
}

export default TooltipViewFeeBreakdown