import {TierConsumedMoney} from "./TierConsumedMoney";
import {Tier} from "./Tier";
import {TierCustomLimit} from "./TierCustomLimit";
import * as TransferCommonModels from "../../api/whales/TransferCommon";
import {Money} from "../../helpers/money";

export class TierList {
  currentTierId?: string
  consumedMoney: TierConsumedMoney[]
  availableTiers: Tier[]
  customLimit?: TierCustomLimit

  constructor(other: {
    currentTierId?: string,
    consumedMoney: TierConsumedMoney[],
    availableTiers: Tier[],
    customLimit?: TierCustomLimit
  }) {
    this.currentTierId = other.currentTierId
    this.consumedMoney = other.consumedMoney
    this.availableTiers = other.availableTiers
    this.customLimit = other.customLimit
  }

  currentTier(): Tier | undefined {
    if (this.currentTierId === undefined) return undefined
    return this.availableTiers.find(tier => tier.tierId === this.currentTierId)
  }

  static fromApi(api: TransferCommonModels.ListTierResponse): TierList {
    const payload = api.payload
    return new TierList({
      currentTierId: payload.currentTierId,
      consumedMoney: payload.consumedMoney.map(TierConsumedMoney.fromApi),
      availableTiers: payload.availableTiers.map(Tier.fromApi),
      customLimit: payload.customLimit ? TierCustomLimit.fromApi(payload.customLimit) : undefined
    })
  }

  static fallback(): TierList {
    return new TierList({
      consumedMoney: [
        new TierConsumedMoney({
          money: Money.gbp(0),
          durationInDays: 30
        }),
        new TierConsumedMoney({
          money: Money.gbp(0),
          durationInDays: 180
        })
      ],
      availableTiers: []
    })
  }
}