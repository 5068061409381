import {PickerOption} from "../ui/Picker";
import CurrencyIcon from "../ui/icons/CurrencyIcon";
import {useIMaskOptionsForMoney} from "../formatters/money/imask-money-formatter";
import {CommonInputPropsGeneric, MaskedInput} from "../ui/input/Inputs";
import {Currency, MoneyInputValue} from "../../helpers/money";
import {useIntl} from "react-intl";
import Decimal from "decimal.js";

type MoneyInputProps =
  CommonInputPropsGeneric<MoneyInputValue>
  & {
  currencyOptions?: Currency[]
}

export const CompareMoneyInput = ({value, onChange, ...props}: MoneyInputProps) => {
  const intl = useIntl()
  const currencyOptions: PickerOption[] | undefined = props.currencyOptions?.map(currency => ({
    value: currency.code,
    label: currency.name(intl),
    Icon: (props: {
      className?: string
    }) => {
      return <CurrencyIcon className={props.className} currencyCode={currency.code} size={"small"}/>
    },
  }))

  const iMaskOptions = useIMaskOptionsForMoney(value?.currency)

  return <MaskedInput
    {...props}
    iMaskOptions={iMaskOptions}
    type={"text"}
    capitalization={"none"}
    inputMode={"decimal"}
    value={value?.amount === "empty" ? "" : value?.amount.toFixed()}
    onChangeMasked={(newValue) => {
      const newAmount = newValue.unmasked === "" ? "empty" : new Decimal(newValue.unmasked);
      onChange && value && onChange({...value, amount: newAmount})
    }}
    gadget={{
      kind: "select",
      options: currencyOptions,
      disabled: false,
      value: currencyOptions?.find((opt) => opt.value === value?.currency?.code) || null,
      onChange: (newValue: PickerOption | null) => {
        onChange && value && newValue && onChange({...value, currency: Currency.default(newValue.value)})
      }
    }}
  />
}
