import React, {ReactNode} from "react";

type Props = {
  separator: React.ReactNode
  elements: ReactNode[]
  keys?: string[]
}

const Join = (props: Props) => {
  return <>
    {props.elements.map((item, index) => {
      return <React.Fragment key={(props.keys && props.keys[index]) ?? index}>
        <>
          {index > 0 && props.separator}
          {item}
        </>
      </React.Fragment>
    })}
  </>
}

export default Join