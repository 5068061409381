import {FormattedDeliveryOptionSimple} from "./FormattedDeliveryOption";
import {useStrings} from "../../../strings";
import {DeliveryOption} from "../../../models/transfers/Estimate";

const DeliveryDateArrivesOn = (props: {deliveryOption: DeliveryOption}) => {
  const strings = useStrings()
  return (
    <FormattedDeliveryOptionSimple
      deliveryOption={props.deliveryOption}
      past={strings["transfer.delivery_arrives_on.past.2"]}
      instant={strings["transfer.delivery_arrives_on.instant"]}
      today={strings["transfer.delivery_arrives_on.today.2"]}
      tomorrow={strings["transfer.delivery_arrives_on.tomorrow.2"]}
      other={strings["transfer.delivery_arrives_on.future_date.2"]}
    />
  )
}

export default DeliveryDateArrivesOn;