import './BottomSheet.scss'
import React, {PropsWithChildren, useMemo} from "react";
import {BodyTitle} from "./Labels";
import Caption from "./Caption";
import ButtonBar from "./ButtonBar";
import {Drawer} from "vaul";

type BottomSheetCtx = {
  open: boolean
  setOpen: (open: boolean) => void
  dismiss(): void
}

type WithBottomSheetProps = {
  children: (ctx: BottomSheetCtx) => React.ReactNode
  bottomSheetView: (ctx: BottomSheetCtx) => React.ReactNode
}

export const WithBottomSheet = (props: WithBottomSheetProps) => {
  const [open, setOpen] = React.useState(false)
  const ctx = useMemo(() => ({
    open,
    setOpen,
    dismiss: () => setOpen(false)
  }), [open, setOpen])
  return (
    <Drawer.Root open={open} onOpenChange={setOpen} modal={true}>
      {props.children(ctx)}
      <Drawer.Portal>
        <Drawer.Overlay className="fixed inset-0 bg-[rgba(0,0,0,.5)] z-[var(--z-modal)]"/>
        <Drawer.Content
          className="bg-secondary flex flex-col rounded-t-[10px] mt-24 fixed bottom-0 left-0 right-0"
          style={{
            zIndex: "calc(var(--z-modal) + 1)"
          }}
          onOpenAutoFocus={(e) => e.preventDefault()}
        >
          <div className="p-4 bg-white rounded-t-[10px] flex-1">
            {props.bottomSheetView(ctx)}
          </div>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  )
}

export const TitleSubtitleBottomSheetLayout = (props: {
  title: string
  subtitle?: string
  buttons?: React.ReactNode
} & PropsWithChildren) => {
  return (
    <>
      <BodyTitle text={props.title}/>
      {props.subtitle && <Caption className={"mt-[4px]"} size={"3"} text={props.subtitle}/>}
      <div className={"py-[24px]"}>
        {props.children}
      </div>
      <ButtonBar align={"center"}>
        {props.buttons}
      </ButtonBar>
    </>
  )
}