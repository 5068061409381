import {useNavStack} from "../../../atoms/nav-stack";
import {Currency} from "../../../helpers/money";
import {RecipientType} from "../../../api/whales/Recipients";
import {useTransfersApi} from "../../../api/transfers";
import {RecipientCreateTypeStep} from "./RecipientCreateTypeStep";
import {RecipientCreateCurrencyStep} from "./RecipientCreateCurrencyStep";
import {RecipientCreateTemplateStep} from "./RecipientCreateTemplateStep";
import {useRecipientsApi, useRecipientsQuery} from "../../../api/recipients";
import {RecipientCreateReviewStep} from "./RecipientCreateReviewStep";
import {PropsOnComplete} from "../../../helpers/props";
import {BankAccountElement} from "../../../components/domain/recipients/domain/BankAccountElement";
import {InterfaceTemplateValue} from "../../../components/domain/interface-template/domain";
import {useClientApi} from "../../../api/client";
import {ClientInfo} from "../../../models/client/client-info";
import {useAddRecipientElementsState} from "../../../atoms/recipient/add-recipient-elements";
import {Recipient} from "../../../components/domain/recipients/domain";
import {BankAccount, BankAccountTemplate} from "../../../models/common/BankAccount";


type RecipientCreateOptions = {
  isKindEditable?: boolean
  kind?: RecipientType

  isCurrencyEditable?: boolean
  currency?: Currency
} & PropsOnComplete<Recipient>

export type RecipientElements = BankAccountElement[]

export class RecipientCreateContext {
  currency?: Currency
  kind?: RecipientType
  elements?: RecipientElements
  template?: BankAccountTemplate

  clientInfo: ClientInfo

  constructor(other: {
    currency?: Currency
    kind?: RecipientType
    elements?: RecipientElements
    template?: BankAccountTemplate
    clientInfo: ClientInfo
  }) {
    this.currency = other.currency
    this.kind = other.kind
    this.elements = other.elements
    this.template = other.template
    this.clientInfo = other.clientInfo
  }

  with(updates: {
    currency?: Currency
    kind?: RecipientType
    elements?: RecipientElements
    template?: BankAccountTemplate
  }): RecipientCreateContext {
    return new RecipientCreateContext({
      currency: updates.currency ?? this.currency,
      kind: updates.kind ?? this.kind,
      elements: updates.elements ?? this.elements,
      template: updates.template ?? this.template,
      clientInfo: this.clientInfo,
    })
  }

  bankAccount(): BankAccount | undefined {
    if (
      this.currency === undefined || this.elements === undefined || this.template === undefined
    ) return undefined
    return new BankAccount({
      currency: this.currency,
      elements: this.elements,
      template: this.template
    })
  }
}

export const useRecipientCreateController = () => {
  const [,setAddRecipientElementsState] = useAddRecipientElementsState()
  const navStack = useNavStack<{}, {}>()
  const recipientsApi = useRecipientsApi();
  const recipientsQuery = useRecipientsQuery();
  const transferApi = useTransfersApi();
  const clientApi = useClientApi();

  const recipientCreate = recipientsQuery.recipient.create.useMutation()

  return async (
    {
      isKindEditable = true,
      isCurrencyEditable = true,
      ...options
    }: RecipientCreateOptions): Promise<void> => {
    const nextScreen = async (index: number, context: RecipientCreateContext): Promise<void> => {
      if (index === 0) {
        if (!isKindEditable) {
          if (context.kind === undefined) throw new Error("isKindEditable: false, kind is required")
          return nextScreen(index + 1, context)
        }

        navStack.push(RecipientCreateTypeStep, {
          onComplete: (kind: RecipientType) => {
            return nextScreen(index + 1, context.with({kind}))
          }
        })
      } else if (index === 1) {
        if (!isCurrencyEditable) {
          if (context.currency === undefined) throw new Error("isCurrencyEditable: false, currency is required")
          return nextScreen(index + 1, context)
        }

        const data = await transferApi.availableCurrencies()

        navStack.push(RecipientCreateCurrencyStep, {
          context,
          availableCurrencies: data.destinationCurrencies,
          onComplete: (currency: Currency) => {
            return nextScreen(index + 1, context.with({currency}))
          }
        })
      } else if (index === 2) {
        const template = await recipientsApi.bankAccountTemplateFind(context.currency!.code)
        const withTemplate = context.with({template})

        navStack.push(RecipientCreateTemplateStep, {
          context: withTemplate,
          template: template,
          onComplete: (elements: RecipientElements) => {
            return nextScreen(index + 1, withTemplate.with({elements}))
          }
        })
      } else if (index === 3) {
        navStack.push(RecipientCreateReviewStep, {
          context,
          onComplete: async () => {
            const recipient = await recipientCreate.mutateAsync({
              type: context.kind!,
              bankAccount: {
                currencyCode: context.currency!.code,
                elements: context.elements!.map((element) => {
                  return {
                    elementId: element.identifier,
                    value: InterfaceTemplateValue.toApi(element.value),
                  }
                })
              }
            })
            navStack.popUntil({
              component: function () {
                if (isKindEditable) {
                  return RecipientCreateTypeStep
                }
                if (isCurrencyEditable) {
                  return RecipientCreateCurrencyStep
                }
                return RecipientCreateTemplateStep
              }(),
              inclusive: true
            })
            setAddRecipientElementsState(undefined)
            await options.onComplete(recipient)
          }
        })
      }
    }

    return nextScreen(0, new RecipientCreateContext({
      currency: options.currency,
      kind: options.kind,
      clientInfo: await clientApi.clientInfo()
    }))
  }
}