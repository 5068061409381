import {useStrings} from "../../../strings";
import {BusinessContactRole} from "../../../api/whales/Common";

type Props = {
  role: BusinessContactRole
}
const BusinessContactRoleValue = (props: Props) => {
  const strings = useStrings()
  return strings[`business_contact.${props.role}`]
}
export default BusinessContactRoleValue;