import {DropEvent, FileRejection, useDropzone} from "react-dropzone";
import _ from "lodash";
import {BodyTitle} from "../Labels";
import Caption from "../Caption";
import "./DropzoneFileInput.scss"
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useAlertStack} from "../../../providers/alert-stack";
import {PropsClassName} from "../../../helpers/props";
import cx from "classnames";
import {useWindowDimensions} from "../../../helpers/hooks";

type AllowedFileTypes = "image" | "pdf" | "csv"

type Props = {
  title: React.ReactNode
  subtitle: React.ReactNode
  disabled?: boolean
  rejectedMessage: string
  allowFullscreenDrop?: boolean
  allowedFileTypes: AllowedFileTypes[]
  onAddFiles: (files: File[]) => void
} & PropsClassName

const DropzoneFileInput = ({allowedFileTypes, onAddFiles, ...props}: Props) => {
  const measureRef = useRef<HTMLDivElement>(null)
  const alertStack = useAlertStack()
  const accept = useMemo(() => _.merge({},
    ...allowedFileTypes.map((type): Record<string, string[]> => {
      switch (type) {
        case "image":
          return {"image/jpeg": [], "image/png": []}
        case "pdf":
          return {"application/pdf": [".pdf"]}
        case "csv":
          return {"text/csv": [".csv"]}
        default:
          return {}
      }
    })
  ), [allowedFileTypes])

  const windowDimensions = useWindowDimensions()
  const updateFullscreenStyles = (rect: DOMRect | null, windowDims: { width: number, height: number }) => {
    const rectBonds = rect
    if (rectBonds === null) return

    const inset = 24
    const styles = {
      left: `-${(rectBonds.x - inset)}px`,
      top: `-${(rectBonds.y - inset)}px`,
      width: `${(windowDims.width - inset * 2)}px`,
      minHeight: `${windowDims.height - inset * 2}px`
    }
    setFullscreenStyles(styles)
  }

  const disabled = props.disabled ?? false
  const {isDragging} = useIsDragging({
    onDragEnter: () => {
      if (isDragging) return
      updateFullscreenStyles(measureElement(measureRef) ?? null, windowDimensions)
    }
  })

  const isFullscreen = isDragging && !disabled && (props.allowFullscreenDrop ?? true)
  // const isFullscreen = true
  const [fullscreenStyles, setFullscreenStyles] = useState<React.CSSProperties>({});

  useEffect(() => {
    if (!isFullscreen) return
    updateFullscreenStyles(measureElement(measureRef) ?? null, windowDimensions)
  }, [isFullscreen, windowDimensions])

  useEffect(() => {
    if (isFullscreen) {
      disableScroll()
    } else {
      enableScroll()
    }
  }, [isFullscreen]);


  // const windowDimensions = useWindowDimensions()
  const onDrop = useCallback((acceptedFiles: File[]) => {
    onAddFiles(acceptedFiles)
  }, [onAddFiles])

  const onDropRejected = useCallback((rejectedFiles: FileRejection[], _event: DropEvent) => {
    rejectedFiles.forEach((_rejectedFile) => {
      alertStack.showErrorMessage(props.rejectedMessage)
    })
  }, [alertStack, props.rejectedMessage])

  const dropzone = useDropzone({
    accept, onDrop, onDropRejected, disabled
  })

  return (
    <div {...dropzone.getRootProps({
      style: isFullscreen ? fullscreenStyles : {}
    })}
         ref={measureRef}
         className={cx(
           "dropzone-file-input",
           {
             "dropzone-file-input--disabled": disabled,
             "dropzone-file-input--fullscreen": isFullscreen
           },
           props.className)
         }>
      <div className={"flex flex-col items-center max-w-[348px]"}>
        <BodyTitle text={props.title} className={"dropzone-file-input__title"}/>
        <Caption className={"dropzone-file-input__subtitle"} size={"2"} color={"primary-50"} text={props.subtitle}/>
      </div>
      <input {...dropzone.getInputProps()}/>
    </div>
  )
}

const useIsDragging = ({onDragEnter}: { onDragEnter: () => void }) => {
  const [isDragging, setIsDragging] = React.useState(false);
  const dragCounter = React.useRef(0);

  const handleDrag = React.useCallback((event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);
  const handleDragIn = React.useCallback((event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    dragCounter.current++;
    if (event.dataTransfer !== null &&
      (event.dataTransfer.types.includes('Files') ||
        (event.dataTransfer.items && event.dataTransfer.items.length > 0))) {
      onDragEnter()
      setIsDragging(true);
    }
  }, [onDragEnter]);
  const handleDragOut = React.useCallback((event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    dragCounter.current--;
    if (dragCounter.current > 0) return;
    setIsDragging(false);
  }, []);
  const handleDrop = React.useCallback((event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
    if (event.dataTransfer !== null && (
      event.dataTransfer.types.includes('Files') ||
      (event.dataTransfer.files && event.dataTransfer.files.length > 0))
    ) {
      dragCounter.current = 0;
      event.dataTransfer.clearData();
    }
  }, []);

  React.useEffect(() => {
    window.addEventListener("dragenter", handleDragIn);
    window.addEventListener("dragleave", handleDragOut);
    window.addEventListener("dragover", handleDrag);
    window.addEventListener("drop", handleDrop);
    return function cleanUp() {
      window.removeEventListener("dragenter", handleDragIn);
      window.removeEventListener("dragleave", handleDragOut);
      window.removeEventListener("dragover", handleDrag);
      window.removeEventListener("drop", handleDrop);
    };
  });

  return {isDragging}
}

const measureElement = (ref: React.RefObject<HTMLDivElement>) => {
  return ref.current?.getBoundingClientRect()
}

function disableScroll() {
  // Get the current page scroll position
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft

  // if any scroll is attempted, set this to the previous value
  window.onscroll = function () {
    window.scrollTo(scrollLeft, scrollTop);
  };
}

function enableScroll() {
  window.onscroll = function () {
  };
}

export default DropzoneFileInput