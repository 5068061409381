import {Button} from "../../ui/Buttons";
import {useStrings} from "../../../strings";
import {PropsOnComplete} from "../../../helpers/props";
import {img} from "../../../helpers/public";
import InformationScreen from "../../ui/screen/InformationScreen";

type Props = PropsOnComplete

const VerificationDocumentStepSuccess = ((props: Props) => {
  const strings = useStrings()
  return (
    <InformationScreen
      title={strings["documents.submitted.title"]}
      subtitle={strings["documents.submitted.subtitle"]}
      media={<img className={"max-w-[375px]"} src={img("documents-in-review.png")} alt={"Illustration"}/>}
      primaryButton={(common) => {
        return <Button {...common} type={"button"} onClickAsync={props.onComplete} title={strings["general.continue"]}/>
      }}
    />
  )
})

export default VerificationDocumentStepSuccess