import {useStrings} from "../../../strings";
import {TransferStatus} from "../../../models/transfers/TransferStatus";

const TransferStatusView = ({status}: {status: TransferStatus}) => {
  const strings = useStrings()
  const visibleStatuses = [
    "created", "checks_sent", "checks_pending", "checks_approved", "checks_failed", "payin_waiting", "payin_received",
    "payin_initiated", "payin_checks_sent", "payin_checks_pending", "payin_checks_approved", "payin_checks_failed",
    "fee_debited", "trade_pending", "trade_placed", "trade_settled", "payout_pending", "payout_completed",
    "payout_failed", "refund_pending", "refund_completed", "refund_failed", "canceled"
  ] as const
  const found = visibleStatuses.find((item) => item === status.string)
  if (found) {
    return strings[`transfer.status.${found}`]
  }

  console.warn(`Unknown status: ${status}`)

  return "Unknown satus"
}

export default TransferStatusView;