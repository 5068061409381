import {Link} from "./Link";
import React from "react";
import cx from "classnames";
import {useQrCode} from "../../helpers/qrcode";
import {WithSkeletonFlag} from "./Skeleton";


type Props = {
  className?: string
  url?: string
  size: "small" | "big"
}

const AtlanticQrCode = (props: Props) => {
  const size = function () {
    switch (props.size) {
      case "small":
        return 100
      case "big":
        return 260
    }
  }()
  const ctx = useQrCode({
    url: props.url,
    size: size, // add size enum to props if different sizes are needed
  })

  return (
    <WithSkeletonFlag width={`${size}px`} height={`${size}px`} isLoading={props.url === undefined || ctx.isLoading}>
      <Link className={cx(props.className, "block")}
            href={props.url || "#"}
            newTab={true}
      >
        <div ref={ctx.canvasContainerRef} className={"flex justify-center items-center"}/>
      </Link>
    </WithSkeletonFlag>
  )
}

export default AtlanticQrCode