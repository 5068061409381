import {useStrings} from "../../../strings";
import {useVerificationNavStack, VerificationProps} from "../verification-controller";
import {useVerificationApi} from "../../../api/verification";
import {useAlertStack} from "../../../providers/alert-stack";
import InterfaceTemplateDocumentScreen from "../../domain/interface-template/screens/InterfaceTemplateDocumentScreen";
import {InterfaceTemplates} from "../../domain/interface-template/domain";
import VerificationDocumentStepSuccess from "./VerificationDocumentStepSuccess";
import {Verification} from "../../domain/verifications/domain";
import {AlertView} from "../../ui/Errors";


export const VerificationDocumentStepUploadGeneric = (props: VerificationProps & {
  template: InterfaceTemplates.Document
  onUpload: (documents: File[]) => Promise<Verification>
}) => {
  const strings = useStrings()
  const alertStack = useAlertStack();
  const navStack = useVerificationNavStack()
  const verificationApi = useVerificationApi()

  return <InterfaceTemplateDocumentScreen
    titleAlign={props.titleAlign}
    template={props.template}
    onComplete={async (files) => {
      await props.onUpload(files)
      await verificationApi.pollVerification(props.verification, {
        maxDurationMs: 60 * 1000,
        intervalMs: 1000,
        pollCondition: (v) => !v.isUserInputRequired(),
        onError: (e) => alertStack.showError(e)
      }).pollThen({
        onSatisfied: () => {
          navStack.push(VerificationDocumentStepSuccess, {onComplete: props.onComplete}, {replace: true})
        },
        onExpire: () => {
          alertStack.showPopUp((ctx) => (
            <AlertView
              {...ctx}
              title={strings["source_of_funds.verification_polling_expired.title"]}
              content={strings["source_of_funds.verification_polling_expired.subtitle"]}
              primaryButton={undefined}
            />
          ))
        }
      })
    }}
  />
}

export const VerificationDocumentStepUpload = (props: VerificationProps & {
  template: InterfaceTemplates.Document
}) => {
  const verificationApi = useVerificationApi()
  return <VerificationDocumentStepUploadGeneric
    {...props}
    onUpload={(files) => verificationApi.submitDocument(props.verification.id, files)}
  />
}

