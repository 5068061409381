import cx from "classnames";
import React, {RefCallback, useState} from "react";
import {
  ClassNamesConfig,
  DropdownIndicatorProps,
  GroupBase,
  NoticeProps,
  SingleValueProps,
  StylesConfig
} from "react-select";
import Select from "react-select/base";
import {OptionProps} from "react-select/dist/declarations/src/components/Option";
import {useDelayUnmount} from "../../helpers/hooks";
import {defaultAnimDurationMillis} from "../../variables";
import Caption from "./Caption";
import CurrencyIcon from "./icons/CurrencyIcon";
import Icon from "./icons/Icon";
import "./Picker.scss"
import Skeleton from "./Skeleton";
import {FilterOptionOption} from "react-select/dist/declarations/src/filters";

export type PickerOption = {
  value: string;
  label: React.ReactNode;
  Icon?: React.FC<{
    className?: string
  }>
}

type PickerProps<T extends PickerOption> = {
  className?: string;
  placeholder: React.ReactNode;
  options?: T[];
  disabled?: boolean;
  value: T | null;
  searchDisabled?: boolean;
  noSearchIcon?: boolean;
  onChange: (value: T | null) => void;
  errored?: boolean
  classNames?: ClassNamesConfig<InnerPickerT<T>, false, GroupBase<InnerPickerT<T>>>
  stylesConfig?: StylesConfig<InnerPickerT<T>, false, GroupBase<InnerPickerT<T>>>
  filterOptions?: (option: FilterOptionOption<InnerPickerT<T>>, inputValue: string) => boolean
  hasIcon?: boolean
}

type PickerState = {
  inputValue: string;
  menuIsOpen: boolean
}

type InnerPickerT<T extends PickerOption> =
  (T & {
    skeleton: false
    hasIcon: boolean
  })
  | {
  skeleton: true,
  value: "",
  label: "",
  hasIcon: boolean
}

const skeletonOption = <T extends PickerOption, >(hasIcon: boolean): InnerPickerT<T> => ({
  skeleton: true,
  value: "",
  label: "",
  hasIcon: hasIcon
})

type CustomOptionProps<T extends PickerOption> = {
  data: InnerPickerT<T>
  optionsLength: number
  innerRef?: RefCallback<HTMLDivElement>
  innerProps?: JSX.IntrinsicElements['div'];
  isFocused: boolean;
  isSelected: boolean;
}
const CustomOption = <T extends PickerOption, >(props: CustomOptionProps<T>) => {
  const data = props.data
  const {
    shouldRender: skeletonRender,
    shouldShow: skeletonShow
  } = useDelayUnmount(props.data.skeleton, defaultAnimDurationMillis)

  const hasOverflow = props.optionsLength > 8

  return (
    <div
      ref={props.innerRef}
      className={cx(
        "flex flex-row items-center bg-primary h-[44px] px-[8px] rounded-[8px]",
        {
          "mr-[8px]": hasOverflow,
          "bg-primary-10": !skeletonRender && props.isFocused,
          "active:bg-primary-20 cursor-pointer": !skeletonRender,
        },
        "transition-all default-anim",
      )}
      {...props.innerProps}
    >
      {data.hasIcon && <div className={"flex-grow-0 flex-shrink-0 w-[18px] h-[18px] mr-[11px] relative"}>
        {skeletonRender && <Skeleton hidden={!skeletonShow} circle={true}/>}
        {!data.skeleton && data.Icon !== undefined && <data.Icon className={"w-[18px] h-[18px]"}/>}
      </div>
      }
      <div className={"flex-1 relative h-[22px]"}>
        {skeletonRender && <Skeleton hidden={!skeletonShow}/>}
        {!data.skeleton && <Caption kind={"text"} className={"text-left"} size={"2"} text={data?.label || ""}/>}
      </div>
      <div className={"ml-1"}>
        <Icon hidden={!props.isSelected} name={"row-check"}/>
      </div>
    </div>
  )
}

const CustomOptionAdapter = <T extends PickerOption, >(props: OptionProps<InnerPickerT<T>, false, GroupBase<InnerPickerT<T>>>) => {
  return <CustomOption {...props} optionsLength={props.options.length}/>
}

type CustomNoticePros<T extends PickerOption> =
  NoticeProps<InnerPickerT<T>, false, GroupBase<InnerPickerT<T>>>
const NoOptionsMessage = <T extends PickerOption, >(props: CustomNoticePros<T>) => {
  const isLoading = !!(props.options[0] as InnerPickerT<T> | undefined)?.skeleton
  const {shouldRender} = useDelayUnmount(isLoading, defaultAnimDurationMillis)
  return (
    <div className={"absolute w-full h-full flex justify-center items-center"} {...props.innerProps} >
      {shouldRender && <div
          className={cx("absolute w-full h-full opacity-[0] transition-opacity default-anim", {"opacity-[1]": isLoading})}>
          <CustomOption data={skeletonOption(true)} optionsLength={props.options.length} isFocused={false}
                        isSelected={false}/>
      </div>
      }
      {props.children}
    </div>
  )
}

const DropdownIndicator = <T extends PickerOption>(props: DropdownIndicatorProps<InnerPickerT<T>, false, GroupBase<InnerPickerT<T>>>) => {
  return <Icon className={cx({"hidden": !props.hasValue})} name={"chevron-down"}/>
}

export const Picker = <T extends PickerOption>(props: PickerProps<T>) => {
  const hasIcon = props.hasIcon ?? true

  const [state, setState] = useState<PickerState>({
    inputValue: "",
    menuIsOpen: false
  })

  const options: InnerPickerT<T>[] = props.options ?
    props.options.map((opt) => ({...opt, skeleton: false, hasIcon: hasIcon})) :
    Array<InnerPickerT<T>>(8).fill(skeletonOption(hasIcon))

  return <Select<InnerPickerT<T>>
    className={cx("picker", {
      "picker--disabled": props.disabled,
      "picker--with-search-icon": !props.noSearchIcon,
      "picker--errored":  props.errored,
    }, props.className)}
    placeholder={
      <Caption className={"whitespace-nowrap text-ellipsis overflow-hidden"} kind={"text"} size={"2"}
               text={props.placeholder}/>
    }
    isDisabled={props.disabled}
    inputValue={state.inputValue}
    openMenuOnFocus={true}
    isSearchable={!props.searchDisabled}
    value={props.value && {...props.value, skeleton: false, hasIcon: hasIcon}}
    options={options}
    filterOption={props.filterOptions}
    onChange={(newValue) => {
      if (!newValue || newValue.skeleton) {
        return
      }
      setState((prev) => ({...prev, inputValue: ""}))
      props.onChange({...newValue})
    }}
    onInputChange={(newValue) => {
      setState((prev) => ({...prev, inputValue: newValue}))
    }}
    menuIsOpen={state.menuIsOpen}
    onMenuOpen={() => {
      setState((prev) => ({...prev, menuIsOpen: true}))
    }}
    onMenuClose={() => {
      setState((prev) => ({...prev, menuIsOpen: false}))
    }}
    components={{
      Option: CustomOptionAdapter,
      DropdownIndicator: DropdownIndicator,
      NoOptionsMessage: NoOptionsMessage
    }}
    classNames={props.classNames ? props.classNames : {
      placeholder: () => cx("absolute", {
        "left-[32px] w-[calc(100%-32px)]": !props.noSearchIcon,
      }),
      container: () => "picker__container",
      control: () => "picker__control",
      valueContainer: (state) => {
        return cx("picker__value-container", {"picker__value-container--empty": !state.hasValue})
      },
      indicatorSeparator: () => "hidden",
      singleValue: () => "caption2 absolute left-0",
      input: () => "caption2",
      // menu:
      menuList: () => "scrollbar-thin scrollbar-w-[6px] scrollbar-thumb-rounded scrollbar-thumb-primary-20 scrollbar-thumb-rounded-full scrollbar-track-rounded-full"
    }}
    styles={props.stylesConfig ? props.stylesConfig : {
      singleValue: (baseStyles) => ({
        ...baseStyles,
        padding: "0",
        margin: 0,
        textAlign: "left"
      }),
      valueContainer: (baseStyles) => ({
        ...baseStyles,
        padding: "0",
        display: undefined
      }),
      placeholder: (baseStyles) => ({
        ...baseStyles,
        color: "var(--clr-primary-40)",
        margin: "0"
      }),
      control: (baseStyles) => ({
        ...baseStyles,
        outline: "none"
      }),
      input: (baseStyles) => ({
        ...baseStyles,
        flex: "1 1 auto",
        width: "0",
        overflow: "hidden",
        paddingRight: "16px",
        margin: "0"
      }),
      indicatorsContainer: (base) => ({
        ...base,
        transform: state.menuIsOpen ? "scaleY(-1)" : "scaleY(1)",
        opacity: props.value === null ? 0 : (props.disabled ? 0.4 : 1),
        transition: "transform var(--default-transition-duration) var(--default-transition-timing-function), " +
          "opacity var(--default-transition-duration) var(--default-transition-duration) var(--default-transition-timing-function)"
      }),
      menu: (baseStyles) => ({
        ...baseStyles,
        width: "calc(100% + 2px)",
        background: "var(--clr-secondary)",
        border: "1px solid var(--clr-primary-20)",
        borderRadius: "8px",
        maxHeight: "368px",
        padding: "8px",
        left: "-1px",
      }),
      menuList: (baseStyles) => ({
        ...baseStyles,
        maxHeight: "352px", // 8 elements
        minHeight: "44px", // 1 element
        padding: "0",
      })
    }}
  />
}

type InlinePickerProps<T extends PickerOption> = {
  className?: string
  options?: T[]
  disabled?: boolean
  value: T | null
  onChange: (value: T | null) => void
  classNames?: ClassNamesConfig<T, false, GroupBase<T>>
  stylesConfig?: StylesConfig<T, false, GroupBase<T>>
}

type InlinePickerState = {
  menuIsOpen: boolean;
}
const CustomInlineValue = <T extends PickerOption, >(props: SingleValueProps<InnerPickerT<T>, false, GroupBase<InnerPickerT<T>>>) => {
  return <CurrencyIcon className={"flex-auto flex-grow-0 flex-shrink-0"} currencyCode={props.data.value}
                       size={"medium"}/>
}

export const InlinePicker = <T extends PickerOption>(props: InlinePickerProps<T>) => {
  const [state, setState] = useState<InlinePickerState>({
    menuIsOpen: false
  })

  const options: InnerPickerT<T>[] = props.options ?
    props.options.map((opt) => ({...opt, skeleton: false, hasIcon: true})) :
    Array<InnerPickerT<T>>(8).fill(skeletonOption(true))

  return <Select<InnerPickerT<T>>
    className={cx("inline-picker", {"picker--disabled": props.disabled}, props.className)}
    placeholder={
      <Caption kind={"text"} className="" size={"2"} text={""}/>
    }
    isDisabled={props.disabled}
    inputValue={""}
    isSearchable={false}
    value={props.value && {...props.value, skeleton: false, hasIcon: true}}
    options={options}
    onChange={(newValue) => {
      if (!newValue || newValue.skeleton) {
        return
      }
      setState((prev) => ({...prev, inputValue: ""}))
      props.onChange({...newValue})
    }}
    onInputChange={() => {
    }}
    menuIsOpen={state.menuIsOpen}
    onMenuOpen={() => {
      setState((prev) => ({...prev, menuIsOpen: true}))
    }}
    onMenuClose={() => {
      setState((prev) => ({...prev, menuIsOpen: false}))
    }}
    components={{
      Option: CustomOptionAdapter,
      DropdownIndicator: DropdownIndicator,
      NoOptionsMessage: NoOptionsMessage,
      SingleValue: CustomInlineValue
    }}
    classNames={{
      // placeholder: () => "absolute left-[32px]",
      // container: () => "picker__container",
      control: () => "inline-picker__control",
      // valueContainer: () => "picker__value-container",
      indicatorSeparator: () => "hidden",
      // singleValue: () => "caption2 absolute left-[32px]",
      // input: () => "caption2",
      // menu:
      menuList: () => "scrollbar-thin scrollbar-w-[6px] scrollbar-thumb-rounded scrollbar-thumb-primary-20 scrollbar-thumb-rounded-full scrollbar-track-rounded-full"
    }}
    styles={{
      container: () => ({
        // height: "100%"
      }),
      singleValue: (baseStyles) => ({
        ...baseStyles,
        padding: "0",
        margin: 0,
        textAlign: "left"
      }),
      valueContainer: (baseStyles) => ({
        ...baseStyles,
        height: "100%",
        flex: "0 0 auto",
        padding: "0 4px 0 0",
        display: "flex",
        justifyContent: "start"
      }),
      placeholder: (baseStyles) => ({
        ...baseStyles,
        color: "var(--clr-primary-40)",
        margin: "0"
      }),
      control: (baseStyles) => ({
        ...baseStyles,
        position: "relative",
        justifyContent: "start",
        height: "100%",
        outline: "none"
      }),
      input: (baseStyles) => ({
        ...baseStyles,
        flex: "1 1 auto",
        width: "0",
        overflow: "hidden",
        paddingRight: "16px",
        margin: "0"
      }),
      indicatorsContainer: (base) => ({
        ...base,
        height: "100%",
        transform: state.menuIsOpen ? "scaleY(-1)" : "scaleY(1)",
        opacity: props.value === null ? 0 : (props.disabled ? 0.4 : 1),
        transition: "transform var(--default-transition-duration) var(--default-transition-timing-function), " +
          "opacity var(--default-transition-duration) var(--default-transition-duration) var(--default-transition-timing-function)"
      }),
      menu: (baseStyles) => ({
        ...baseStyles,
        background: "var(--clr-secondary)",
        width: "calc(100% + 2px)",
        border: "1px solid var(--clr-primary-20)",
        borderRadius: "8px",
        maxHeight: "368px",
        padding: "8px",
        left: "-1px",
      }),
      menuList: (baseStyles) => ({
        ...baseStyles,
        maxHeight: "352px", // 8 elements
        minHeight: "44px", // 1 element
        padding: "0",
      })
    }}
  />
}