import {AdStorage} from "../storage/ad-storage";
import {Referral} from "../api/whales/Auth";

export class AdService {

    static init() {
        const urlParams = new URLSearchParams(window.location.search);
        const gclid = urlParams.get("gclid")
        if (gclid) {
            AdStorage.setData({gclid: gclid || undefined})
        }
    }

    static referralData(): Referral | null {
        const adData = AdStorage.getData()
        if (adData == null) {
            return null
        }
        return {
            code: "googleads",
            metadata: {
                gclid: adData.gclid
            }
        }
    }

}
