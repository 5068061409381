import {CommonInputProps, Input} from "./Inputs";
import cx from "classnames";
import "./RowInput.scss"

type RowInputProps = {} & CommonInputProps

const RowInput = ({className, ...props}: RowInputProps) => {
  return (
    <Input
      type={"text"}
      capitalization={"sentences"}
      {...props}
      className={cx(className, "input--row")}
      readOnly={props.readOnly || props.disabled}
    />
  )
}

export default RowInput