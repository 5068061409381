import {T} from "@transifex/react";
import RecipientAvatar from "../../../components/domain/recipients/RecipientAvatar";
import Caption from "../../../components/ui/Caption";
import {Link} from "../../../components/ui/Link";
import './Timeline.scss';
import TimelineElementView from "./TimelineElementView";
import {Links} from "../../../helpers/Links";
import {TransferTitle} from "../../../components/domain/transfers/TransferTitle";
import {useTransferTimelineController} from "./transfer-timeline-controller";
import _ from "lodash";
import {TitleSubtitleLeftScreen} from "../../../components/ui/screen/TitleSubtitleScreen";
import ButtonBar from "../../../components/ui/ButtonBar";
import {WithSkeleton} from "../../../components/ui/Skeleton";
import {Transfer} from "../../../models/transfers/Transfer";
import React from "react";
import {Timeline, TimelineElement} from "../../../models/transfers/Timeline";

const TimelineStepMain = ({transferId, onTimelineUpdated}: {
  transferId?: string
  onTimelineUpdated?: (timeline: Timeline) => void
}) => {
  const controller = useTransferTimelineController({
    transferId,
    onSuccess: (timeline) => {
      onTimelineUpdated?.(timeline)
    }
  })
  const timeline = controller.timeline

  const sortedElements = timeline && _.sortBy(timeline.elements, (element) => element.order)
  const activeElement = timeline && _.findLast(sortedElements, ((element) => element.state === "currentOrPast"))

  const elements = function () {
    if (!!timeline && !!sortedElements) {
      return sortedElements.map((element: TimelineElement) => {
        return <TimelineElementView
          key={element.order}
          element={element}
          transfer={timeline.transfer}
          state={activeElement?.identifier === element.identifier ? "open" : "closed"}
        />
      })
    } else {
      const skeletonElements = 4
      return Array.from<React.ReactNode>({length: skeletonElements}).map((_, index) => {
        return (
          <TimelineElementView
            key={index}
            state={"skeleton"}
            isFirst={index === 0}
            isLast={index === skeletonElements - 1}
          />
        )
      })
    }
  }()

  if (transferId === undefined) {
    return null
  }

  return (
    <TitleSubtitleLeftScreen
      title={
        <WithSkeleton value={timeline?.transfer} fallback={Transfer.fallback}>
          {transfer => <TransferTitle transfer={transfer}/>}
        </WithSkeleton>
      }
      avatar={<RecipientAvatar recipient={timeline?.transfer?.recipient} size={"big"}/>}
    >
      <ol className="timeline__list">
        {elements}
      </ol>
      <ButtonBar align={"center"} sticky={"bottom"} pushDown={false}>
        <WithSkeleton value={transferId} fallback={""}>{transferId =>
          <Link href={Links.atlantic.transfer.view(transferId)} newTab={false}>
            <Caption text={<T _str={"View transfer details"}/>} size={"2-title"}/>
          </Link>
        }
        </WithSkeleton>
      </ButtonBar>
    </TitleSubtitleLeftScreen>
  )
};

export default TimelineStepMain;