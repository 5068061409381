import React, {useRef} from "react";

import './Sidebar.scss';
import Icon from "../ui/icons/Icon";
import {useStrings} from "../../strings";
import cx from "classnames";
import {InlineButton} from "../ui/Buttons";
import {useAuthActions} from "../../atoms/auth";
import {Links} from "../../helpers/Links";
import {PropsClassName} from "../../helpers/props";
import {PropsOf} from "@emotion/react";
import SidebarMenuItem from "./SidebarMenuItem";
import {isAndroid, isIOS} from "react-device-detect";
import {useConfig} from "../../hooks/config";
import {Variables} from "../../variables";

type SidebarMenuItemProps = PropsOf<typeof SidebarMenuItem>

const useMenuConfiguration = ({isAuthorized}: { isAuthorized: boolean }) => {
    const authActions = useAuthActions()
    const strings = useStrings()

    const topSection: SidebarMenuItemProps[] = !isAuthorized ? [] : [
      {
        activeIcon: "send-filled",
        inactiveIcon: "send-transparent",
        title: strings["sidebar.items.send"],
        url: Links.atlantic.root.send,
      },
      {
        activeIcon: "user-filled",
        inactiveIcon: "user-transparent",
        title: strings["sidebar.items.recipients"],
        url: Links.atlantic.root.recipients,
      },
      {
        activeIcon: "archive-filled",
        inactiveIcon: "archive-transparent",
        title: strings["sidebar.items.history"],
        url: Links.atlantic.root.history(),
      },
      {
        activeIcon: "speedometer-filled",
        inactiveIcon: "speedometer-transparent",
        title: 'Limits',
        url: Links.atlantic.root.limits,
      },
    ]

    const bottomSection: SidebarMenuItemProps[] = !isAuthorized ? [] : [
      // {
      //   activeIcon: "gift-solid",
      //   inactiveIcon: "gift-transparent",
      //   title: strings["sidebar.items.referral"],
      //   url: Links.atlantic.root.referral,
      // },
      {
        inactiveIcon: "help-transparent",
        activeIcon: "help-solid",
        title: strings["sidebar.items.help"],
        url: Links.atlantic.root.contactUs,
        newTab: true,
      },
      {
        activeIcon: "settings-filled",
        inactiveIcon: "settings-transparent",
        title: strings["sidebar.items.settings"],
        url: Links.atlantic.root.settings,
      },
    ]

    const bottomBottomSection: SidebarMenuItemProps[] = [
      function (): SidebarMenuItemProps {
        if (!isAuthorized) {
          return {
            inactiveIcon: "log-in",
            title: strings["sidebar.items.log_in"],
            url: Links.atlantic.auth.signIn,
          }
        }

        return {
          activeIcon: "log-out",
          inactiveIcon: "log-out",
          title: strings["sidebar.items.log_out"],
          onClick: () => {
            authActions.logout();
          }
        }
      }()
    ]

    return {topSection, bottomSection, bottomBottomSection}
  }
;

type Props = {
  isAuthorized: boolean,
} & PropsClassName;

type State = {
  isOpened: boolean;
  mouseIn: boolean;
}

const Sidebar = (props: Props) => {
  const config = useConfig()
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)
  const [state, setState] = React.useState<State>({
    isOpened: false,
    mouseIn: false
  });

  const menuConfiguration = useMenuConfiguration({isAuthorized: props.isAuthorized})

  if (isIOS || isAndroid || !config.enableFullWebAppExperience) {
    return null
  }

  return (
    <div className={cx("sidebar", props.className,
      {
        "sidebar--opened": state.isOpened,
      }
    )}>
      <div className={cx("sidebar__overlay")}/>
      <nav
        className={cx("sidebar__nav")}
        onMouseEnter={() => {
          setState(prev => ({...prev, mouseIn: true}))
          timeoutRef.current && clearTimeout(timeoutRef.current)
          timeoutRef.current = setTimeout(() => {
            setState(prev => ({...prev, isOpened: prev.mouseIn}))
          }, Variables.ui.animDuration.sidebarOpenDelay)
        }}
        onMouseLeave={() => {
          setState(prev => ({...prev, isOpened: false, mouseIn: false}))
        }}
        onClick={(event) => {
          const el = event.target as HTMLElement
          if (!!el.closest(".sidebar__menu-item")) {
            timeoutRef.current && clearTimeout(timeoutRef.current)
            timeoutRef.current = setTimeout(() => {
              setState(prev => ({...prev, isOpened: prev.mouseIn}))
            }, Variables.ui.animDuration.sidebarOpenDelayAfterClick)
            setState(prev => ({...prev, isOpened: false}))
          }
        }}
      >
        <div className={"sidebar__content"}>
          <InlineButton className={"flex gap-[4px] px-[6px]"} type={"link"} href={"/"}>
            <Icon name={"logo-small"}/>
            <Icon className={"sidebar__menu-item-title"} name={"logo-text"}/>
          </InlineButton>

          <div className="sidebar__menu pt-[42px]">
            {menuConfiguration.topSection.map((item, index) => (
              <SidebarMenuItem key={index} {...item}/>
            ))}
          </div>

          <div className="sidebar__menu mt-auto">
            {menuConfiguration.bottomSection.map((item, index) => (
              <SidebarMenuItem key={index} {...item}/>
            ))}
          </div>

          <div className="sidebar__menu pt-[32px]">
            {menuConfiguration.bottomBottomSection.map((item, index) => (
              <SidebarMenuItem key={index} {...item}/>
            ))}
          </div>
        </div>
      </nav>
    </div>
  )
};


export default Sidebar;