import _ from "lodash";
import * as InputInterfaceAPI from "../../../api/whales/Interface-template";
import {Address, Address as AddressModel} from "../../../models/address";

export namespace InterfaceTemplateValues {
  export type Text = {
    kind: "text"
    textValue: string
  }

  export type PickerOption = {
    id: string
    title: string
  }

  export type Picker = {
    kind: "picker"
    pickerValue: PickerOption
  }

  export type Address = {
    kind: "address"
    addressValue: AddressModel
  }

  export class SingleSelectOption {
    readonly title: string
    readonly id: string

    constructor(other: {
      title: string
      id: string
    }) {
      this.title = other.title
      this.id = other.id
    }

    get isOther(): boolean {
      return this.id === "other"
    }

    get isEmployed(): boolean {
      return this.id === "employed"
    }

    get isSelfEmployed(): boolean {
      return this.id === "selfEmployed"
    }

    toApi(): InputInterfaceAPI.SelectOption {
      return {
        title: this.title,
        id: this.id
      }
    }
  }


  export type SingleSelect = {
    kind: "singleSelect"
    singleSelectValue: {
      option: SingleSelectOption
      otherText?: string
    }
  }

  export type MultiSelect = {
    kind: "multiSelect"
    multiSelectValue: {
      options: SingleSelectOption[]
      otherText?: string
    }
  }

  export type Employment = {
    kind: "employment"
    employmentValue: {
      status: SingleSelect
      occupation: string | undefined
      employer: string | undefined
    }
  }

  export type Value = Text | Picker | Address | SingleSelect | MultiSelect | Employment
}

export class InterfaceTemplateValue {
  static fromApi(api: InputInterfaceAPI.InputInterfaceValue): InterfaceTemplateValues.Value {
    if (api.textValue !== undefined) {
      return {kind: "text", textValue: api.textValue.text}
    }

    if (api.pickerValue !== undefined) {
      return {kind: "picker", pickerValue: {id: api.pickerValue.option.id, title: api.pickerValue.option.title}}
    }

    if (api.addressValue !== undefined) {
      return {
        kind: "address", addressValue: Address.fromApi(api.addressValue.address)
      }
    }

    if (api.singleSelectValue !== undefined) {
      return {
        kind: "singleSelect", singleSelectValue: {
          option: new InterfaceTemplateValues.SingleSelectOption(api.singleSelectValue.option),
          otherText: api.singleSelectValue.otherText?.text ?? undefined
        }
      }
    }

    if (api.multiSelectValue !== undefined) {
      return {
        kind: "multiSelect", multiSelectValue: {
          options: api.multiSelectValue.options.map(o =>
            new InterfaceTemplateValues.SingleSelectOption(o)
          ),
          otherText: api.multiSelectValue.otherText?.text ?? undefined
        }
      }
    }

    throw new Error(`Unknown interface template value kind: ${JSON.stringify(api)}`)
  }

  static defaultForTemplate(template: InterfaceTemplate): InterfaceTemplateValues.Value {
    const typed = template.typed
    const kind = typed.kind
    switch (kind) {
      case "text":
        return {kind, textValue: ""}
      case "picker":
        return {kind, pickerValue: {id: "", title: ""}}
      case "address":
        return {
          kind, addressValue: Address.empty()
        }
      case "singleSelect":
        return {kind, singleSelectValue: {option: new InterfaceTemplateValues.SingleSelectOption({id: "", title: ""})}}
      case "multiSelect":
        return {kind, multiSelectValue: {options: []}}
    }

    throw new Error(`Unknown interface template kind: ${kind}`)
  }


  static fallbackForTemplate(template: InterfaceTemplate): InterfaceTemplateValues.Value {
    const typed = template.typed
    const kind = typed.kind
    switch (kind) {
      case "text":
        return {kind, textValue: "Fallback"}
      case "picker":
        return {kind, pickerValue: {id: "", title: ""}}
      case "address":
        return {
          kind, addressValue: new Address({
            line1: "Line 1",
            line2: "Line 2",
            city: "New-York",
            stateOrProvince: "NY",
            postcode: "10001",
            countryCode: "US"
          })
        }
      case "singleSelect":
        return {kind, singleSelectValue: {option: new InterfaceTemplateValues.SingleSelectOption({id: "", title: ""})}}
      case "multiSelect":
        return {kind, multiSelectValue: {options: []}}
    }

    throw new Error(`Unknown interface template kind: ${kind}`)
  }

  static toApi(value: InterfaceTemplateValues.Value): InputInterfaceAPI.InputInterfaceValue {
    const kind = value.kind
    switch (kind) {
      case "text":
        return {kind, textValue: {text: value.textValue}}
      case "picker":
        return {
          kind, pickerValue: {
            option: {id: value.pickerValue.id, title: value.pickerValue.title}
          }
        }
      case "address":
        return {
          kind, addressValue: {
            address: value.addressValue.toApi()
          }
        }
      case "singleSelect":
        return {
          kind, singleSelectValue: {
            option: {id: value.singleSelectValue.option.id, title: value.singleSelectValue.option.title},
            otherText: value.singleSelectValue.otherText ? {
              text: value.singleSelectValue.otherText
            } : undefined
          }
        }
      case "multiSelect":
        return {
          kind, multiSelectValue: {
            options: value.multiSelectValue.options.map(o => ({
              id: o.id,
              title: o.title
            })),
          }
        }
      case "employment":
        return {
          kind, employmentValue: {
            statusValue: InterfaceTemplateValue.toApi(value.employmentValue.status).singleSelectValue!,
            occupationValue: value.employmentValue.occupation ? {
              text: value.employmentValue.occupation
            } : undefined,
            employerValue: value.employmentValue.employer ? {
              text: value.employmentValue.employer
            } : undefined
          }
        }
    }
  }
}

export namespace InterfaceTemplates {
  export class Text {
    title: string
    subtitle?: string
    placeholder?: string
    format?: string

    constructor(other: {
      title: string
      subtitle?: string
      placeholder?: string
      format?: string
    }) {
      this.title = other.title
      this.subtitle = other.subtitle
      this.placeholder = other.placeholder
      this.format = other.format
    }

    with(other: {
      title?: string
      subtitle?: string
      placeholder?: string
      format?: string
    }): Text {
      return new Text({
        title: other.title ?? this.title,
        subtitle: other.subtitle ?? this.subtitle,
        placeholder: other.placeholder ?? this.placeholder,
        format: other.format ?? this.format
      })
    }
  }

  export class Picker {
    title: string
    subtitle?: string
    options: InterfaceTemplateValues.PickerOption[]

    constructor(other: {
      title: string
      subtitle?: string
      options: InterfaceTemplateValues.PickerOption[]
    }) {
      this.title = other.title
      this.subtitle = other.subtitle
      this.options = other.options
    }
  }

  export class Address {
    title: string
    subtitle?: string
    countryCodes: string[]

    constructor(other: {
      title: string
      subtitle?: string
      countryCodes: string[]
    }) {
      this.title = other.title
      this.subtitle = other.subtitle
      this.countryCodes = other.countryCodes
    }
  }

  export class SingleSelect {
    title: string
    subtitle?: string
    options: InterfaceTemplateValues.SingleSelectOption[]
    otherTextEntry?: Text;

    constructor(other: {
      title: string
      subtitle?: string
      options: InterfaceTemplateValues.SingleSelectOption[]
      otherTextEntry?: Text;
    }) {
      this.title = other.title
      this.subtitle = other.subtitle
      this.options = other.options.map(o => o)
      this.otherTextEntry = other.otherTextEntry
    }

    static fromApi(api: NonNullable<InputInterfaceAPI.InputInterfaceTemplate["singleSelectTemplate"]>): SingleSelect {
      return new SingleSelect({
        title: api.title,
        subtitle: api.subtitle,
        options: api.options.map(o => new InterfaceTemplateValues.SingleSelectOption(o)),
        otherTextEntry: api.otherTextEntry ? new Text(api.otherTextEntry) : undefined
      })
    }
  }

  export class MultiSelect extends SingleSelect {
  }

  export class SelfieId {
    readonly acceptableDocuments: InputInterfaceAPI.SelfieIdDocument[]

    constructor(other: {
      acceptableDocuments: InputInterfaceAPI.SelfieIdDocument[]
    }) {
      this.acceptableDocuments = other.acceptableDocuments
    }
  }

  export class Employment {
    readonly statusTemplate: SingleSelect
    readonly occupationTemplate: Text
    readonly employerTemplate: Text

    constructor(other: {
      statusTemplate: SingleSelect
      occupationTemplate: Text
      employerTemplate: Text
    }) {
      this.statusTemplate = other.statusTemplate
      this.occupationTemplate = other.occupationTemplate
      this.employerTemplate = other.employerTemplate
    }

    static fromApi(api: NonNullable<InputInterfaceAPI.InputInterfaceTemplate["employmentTemplate"]>): Employment {
      return new Employment({
        statusTemplate: SingleSelect.fromApi(api.statusTemplate),
        occupationTemplate: new Text(api.occupationTemplate),
        employerTemplate: new Text(api.employerTemplate)
      })
    }
  }

  export class Document {
    readonly documentLimit: number = 10
    readonly title: string
    readonly subtitle?: string
    readonly details: string[]
    readonly emailForSubmission: string

    constructor(other: {
      title: string
      subtitle?: string
      details: string[]
      emailForSubmission: string
    }) {
      this.title = other.title
      this.subtitle = other.subtitle
      this.details = other.details
      this.emailForSubmission = other.emailForSubmission
    }

    static fromApi(api: NonNullable<InputInterfaceAPI.InputInterfaceTemplate["documentTemplate"]>): Document {
      return new Document({
        title: api.title,
        details: api.details,
        emailForSubmission: api.emailForSubmission
      })
    }
  }

  export class TransferSourceOfFunds {
    readonly sourceOfFundsSelect: SingleSelect
    readonly emailForSubmission: string
    readonly documentsTemplates?: Record<string, Document>

    constructor(other: {
      sourceOfFundsSelect: SingleSelect
      emailForSubmission: string
      documentsTemplates?: Record<string, Document>
    }) {
      this.sourceOfFundsSelect = other.sourceOfFundsSelect
      this.emailForSubmission = other.emailForSubmission
      this.documentsTemplates = other.documentsTemplates
    }

    static fromApi(api: NonNullable<InputInterfaceAPI.InputInterfaceTemplate["transferSourceOfFundsTemplate"]>): TransferSourceOfFunds {
      return new TransferSourceOfFunds({
        sourceOfFundsSelect: SingleSelect.fromApi(api.sourceOfFundsSelect),
        emailForSubmission: api.emailForSubmission,
        documentsTemplates: _.fromPairs(api.documentsTemplates?.map(d =>
          [d.optionId, Document.fromApi({...d, emailForSubmission: api.emailForSubmission})]
        ))
      })
    }
  }

  export class BusinessInfo {
    readonly document?: Document

    constructor(other: {
      document?: Document
    }) {
      this.document = other.document
    }

    static fromApi(api: NonNullable<InputInterfaceAPI.InputInterfaceTemplate["businessInfoTemplate"]>): BusinessInfo {
      return new BusinessInfo({
        document: api.document ? Document.fromApi(api.document) : undefined
      })
    }
  }

  export class BusinessKeyPeople {
    readonly document?: Document

    constructor(other: {
      document?: Document
    }) {
      this.document = other.document
    }

    static fromApi(api: NonNullable<InputInterfaceAPI.InputInterfaceTemplate["businessKeyPeopleTemplate"]>): BusinessKeyPeople {
      return new BusinessKeyPeople({
        document: api.document ? Document.fromApi(api.document) : undefined
      })
    }
  }

  export type Kind = {
    kind: "text"
    text: Text
  } | {
    kind: "picker"
    picker: Picker
  } | {
    kind: "address"
    address: Address
  } | {
    kind: "singleSelect"
    singleSelect: SingleSelect
  } | {
    kind: "multiSelect"
    multiSelect: MultiSelect
  } | {
    kind: "selfieId"
    selfieId: SelfieId
  } | {
    kind: "employment"
    employment: Employment
  } | {
    kind: "transferSourceOfFunds"
    transferSourceOfFunds: TransferSourceOfFunds
  } | {
    kind: "document"
    document: Document
  } | {
    kind: "businessInfo"
    businessInfo: BusinessInfo
  } | {
    kind: "businessKeyPeople"
    businessKeyPeople: BusinessKeyPeople
  }
}


export class InterfaceTemplate {
  readonly rawKind: InputInterfaceAPI.InputInterfaceTemplate["kind"]
  readonly textTemplate?: InterfaceTemplates.Text
  readonly pickerTemplate?: InterfaceTemplates.Picker
  readonly addressTemplate?: InterfaceTemplates.Address
  readonly singleSelectTemplate?: InterfaceTemplates.SingleSelect
  readonly multiSelectTemplate?: InterfaceTemplates.MultiSelect
  readonly employmentTemplate?: InterfaceTemplates.Employment
  readonly selfieIdTemplate?: InterfaceTemplates.SelfieId
  readonly transferSourceOfFundsTemplate?: InterfaceTemplates.TransferSourceOfFunds
  readonly documentTemplate?: InterfaceTemplates.Document
  readonly businessInfoTemplate?: InterfaceTemplates.BusinessInfo
  readonly businessKeyPeopleTemplate?: InterfaceTemplates.BusinessKeyPeople

  readonly typed: InterfaceTemplates.Kind

  constructor(other: InputInterfaceAPI.InputInterfaceTemplate) {
    this.rawKind = other.kind

    if (other.textTemplate !== undefined) {
      const template = new InterfaceTemplates.Text(other.textTemplate)
      this.typed = {kind: "text", text: template}
      this.textTemplate = template
      return
    }

    if (other.pickerTemplate !== undefined) {
      const template = new InterfaceTemplates.Picker(other.pickerTemplate)
      this.typed = {kind: "picker", picker: template}
      this.pickerTemplate = template
      return
    }

    if (other.addressTemplate !== undefined) {
      const template = new InterfaceTemplates.Address(other.addressTemplate)
      this.typed = {kind: "address", address: template}
      this.addressTemplate = template
      return
    }


    if (other.singleSelectTemplate !== undefined) {
      const template = InterfaceTemplates.SingleSelect.fromApi(other.singleSelectTemplate)
      this.typed = {kind: "singleSelect", singleSelect: template}
      this.singleSelectTemplate = template
      return
    }

    if (other.multiSelectTemplate !== undefined) {
      const template = InterfaceTemplates.SingleSelect.fromApi(other.multiSelectTemplate)
      this.typed = {kind: "multiSelect", multiSelect: template}
      this.multiSelectTemplate = template
      return
    }

    if (other.employmentTemplate !== undefined) {
      const template = InterfaceTemplates.Employment.fromApi(other.employmentTemplate)
      this.typed = {kind: "employment", employment: template}
      this.employmentTemplate = template
      return
    }

    if (other.selfieIdTemplate !== undefined) {
      const template = new InterfaceTemplates.SelfieId(other.selfieIdTemplate)
      this.typed = {kind: "selfieId", selfieId: template}
      this.selfieIdTemplate = template
      return
    }

    if (other.transferSourceOfFundsTemplate !== undefined) {
      const template = InterfaceTemplates.TransferSourceOfFunds.fromApi(other.transferSourceOfFundsTemplate)
      this.typed = {kind: "transferSourceOfFunds", transferSourceOfFunds: template}
      this.transferSourceOfFundsTemplate = template
      return
    }

    if (other.documentTemplate !== undefined) {
      const template = InterfaceTemplates.Document.fromApi(other.documentTemplate)
      this.typed = {kind: "document", document: template}
      this.documentTemplate = template
      return
    }

    if (other.businessInfoTemplate !== undefined) {
      const template = InterfaceTemplates.BusinessInfo.fromApi(other.businessInfoTemplate)
      this.typed = {kind: "businessInfo", businessInfo: template}
      this.businessInfoTemplate = template
      return
    }

    if (other.businessKeyPeopleTemplate !== undefined) {
      const template = InterfaceTemplates.BusinessKeyPeople.fromApi(other.businessKeyPeopleTemplate)
      this.typed = {kind: "businessKeyPeople", businessKeyPeople: template}
      this.businessKeyPeopleTemplate = template
      return
    }

    throw new Error(`Unknown interface template kind: ${other.kind}`)
  }

  static fallbackTextTemplate(): InterfaceTemplate {
    return new InterfaceTemplate({
      kind: "text",
      textTemplate: {
        title: "Fallback",
        subtitle: "Fallback",
        placeholder: "",
        errorElementName: "",
        keyboardType: "systemDefault",
        format: ""
      }
    })
  }
}

