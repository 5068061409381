import Limiter from "../../components/ui/Limiter";
import InformationScreen from "../../components/ui/screen/InformationScreen";
import {useStrings} from "../../strings";
import {Button} from "../../components/ui/Buttons";
import {img} from "../../helpers/public";
import {Links} from "../../helpers/Links";

const NotFoundPage = () => {
  const strings = useStrings()
  return (
    <Limiter>
      <InformationScreen
        title={strings["error.404.title"]}
        subtitle={strings["error.404.subtitle"]}
        media={<img src={img("image-error-404.png")} alt={strings["error.404.title"]}/>}
        primaryButton={(innerProps) =>
          <Button
            {...innerProps}
            type={"link"}
            title={strings["general.go_home"]}
            href={Links.atlantic.home}
          />
      }
      />
    </Limiter>
  )
}

export default NotFoundPage;