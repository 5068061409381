import parsePhoneNumber from 'libphonenumber-js'

type Props = {
  value: string
}

const FormattedPhone = (props: Props) => {
  const formatted = parsePhoneNumber(props.value)

  if (!formatted) {
    return <>{props.value}</>
  }

  return <>{formatted.formatInternational()}</>
}

export default FormattedPhone;