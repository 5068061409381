import {InterfaceTemplate, InterfaceTemplateValues} from "./domain";
import {InterfaceTemplateTextInput} from "./inputs/InterfaceTemplateTextInput";
import {InterfaceTemplateAddressInput} from "./inputs/InterfaceTemplateAddressInput";
import {InterfaceTemplatePickerInput} from "./inputs/InterfaceTemplatePickerInput";
import {InterfaceTemplateSingleSelectInput} from "./inputs/InterfaceTemplateSingleSelectInput";
import {InterfaceTemplateMultiSelectInput} from "./inputs/InterfaceTemplateMultiSelectInput";
import {CommonInterfaceTemplateProps} from "./inputs/common";


type Props = CommonInterfaceTemplateProps<InterfaceTemplate, InterfaceTemplateValues.Value> & {
  suggestedCountryCode: string | undefined
}

export const InterfaceTemplateInput = (props: Props) => {
  const template = props.template.typed
  const value = props.value
  switch (template.kind) {
    // WEB-374: Add support for other interface template kinds
    case "text":
      if (value.kind !== "text") {
        throw new Error(`Expected value of kind "text", got ${value.kind}`)
      }
      return <InterfaceTemplateTextInput {...props} template={template.text} value={value}/>

    case "picker":
      if (value.kind !== "picker") {
        throw new Error(`Expected value of kind "picker", got ${value.kind}`)
      }
      return <InterfaceTemplatePickerInput {...props} template={template.picker} value={value}/>

    case "singleSelect":
      if (value.kind !== "singleSelect") {
        throw new Error(`Expected value of kind "singleSelect", got ${value.kind}`)
      }
      return <InterfaceTemplateSingleSelectInput {...props} template={template.singleSelect} value={value}/>

    case "multiSelect":
      if (value.kind !== "multiSelect") {
        throw new Error(`Expected value of kind "multiSelect", got ${value.kind}`)
      }

      return <InterfaceTemplateMultiSelectInput {...props} template={template.multiSelect} value={value}/>

    case "address":
      if (value.kind !== "address") {
        throw new Error(`Expected value of kind "address", got ${value.kind}`)
      }

      return <InterfaceTemplateAddressInput {...props} template={template.address} value={value}/>

    default:
      throw new Error(`Unknown interface template kind: ${template}`)
  }
}
