import {TitleSubtitleLeftScreen} from "../../components/ui/screen/TitleSubtitleScreen";
import Limiter, {LimiterOverflow} from "../../components/ui/Limiter";
import NavBar from "../../components/navigation/NavBar";
import {useStrings} from "../../strings";
import {Section} from "../../components/ui/collections/Section";
import ComplexRow from "../../components/ui/collections/rows/ComplexRow";
import Icon, {IconName} from "../../components/ui/icons/Icon";
import React from "react";
import {Links} from "../../helpers/Links";
import {isAndroid, isIOS, isMacOs} from "react-device-detect";
import {useSupportApi} from "../../api/support";
import {useAuthValue} from "../../atoms/auth";
import {useClientQuery} from "../../api/client";
import {useLocation} from "../../routes/router-dom-wrapper/hooks";

const ContactUsRow = (props: {
  title: string,
  topIcon: IconName,
  secondary?: React.ReactNode,
  hasTrailingArrow?: boolean
  qrCodeUrl?: string
  href?: string
  authorizeSupport?: boolean
}) => {
  const authValue = useAuthValue()
  const supportApi = useSupportApi()
  return (
    <ComplexRow
      title={props.title}
      topImage={<Icon className={"rounded-full"} name={props.topIcon}/>}
      bordered={true}
      secondary={props.secondary}
      trailingIcon={!props.hasTrailingArrow ? undefined : <Icon name={"chevron-right"}/>}
      qrCodeUrl={props.qrCodeUrl}
      onClickAsync={function () {
        if (props.href === undefined) return undefined
        return async () => {
          if (authValue.authenticated && props.authorizeSupport) {
            await supportApi.authorize({platform: "web"})
          }
          if (props.href) window.location.href = props.href
        }
      }()
      }
    />
  )
}

const ContactUsPage = () => {
  const strings = useStrings()
  const authValue = useAuthValue()
  const clientQuery = useClientQuery()
  const {data: clientInfo} = clientQuery.v2.info.useQuery()
  const clientInfoTyped = clientInfo?.typed
  const location = useLocation()

  const initialMessage: string | undefined = function () {
    const prefix: string | undefined = function () {
      if (authValue.authenticated) {
        if (!clientInfoTyped) return undefined
        switch (clientInfoTyped.kind) {
          case "individual":
            return strings["support.chat_with_us.initial_message"](
              clientInfoTyped.individual.firstName,
              clientInfoTyped.individual.email
            ) as unknown as string
          case "business":
            return strings["support.auth.initial_message.business"](
              clientInfoTyped.business.applicant()?.firstName ?? "",
              clientInfoTyped.business.info.legalName,
              clientInfoTyped.business.applicant()?.email ?? ""
            ) as unknown as string
        }
      } else {
        return strings["support.chat_with_us.initial_message.unauthed"] as unknown as string
      }
    }()
    const appendix =  location.state?.previousLocation?.pathname ? strings["support.chat_with_us.initial_message_detail_appendage"](
      location.state?.previousLocation?.pathname
    ) as unknown as string: undefined
    console.log({prefix, appendix})
    return [prefix, appendix].filter(str => str !== undefined).join(" ")
  }()

  const whatsAppLink = function () {
    const url = new URL(Links.helpCenter.contactUs.whatsApp)
    if (initialMessage) {
      url.searchParams.append("text", initialMessage)
    }
    return url.toString()
  }()

  const iMessageLink = function () {
    const url = new URL(Links.helpCenter.contactUs.iMessage)
    if (initialMessage) {
      url.searchParams.set("body", initialMessage)
    }

    return url.toString().replaceAll("+", "%20")
  }()

  return (
    <Limiter>
      <NavBar/>
      <TitleSubtitleLeftScreen title={strings["contact_us.title"]}>
        <Section>
          <LimiterOverflow overflow={"16px"}>
            <ContactUsRow
              title={strings["contact_us.options.help_center"]}
              topIcon={"circle-help-center"}
              href={Links.helpCenter.home}
              secondary={strings["general.continue"]}
              hasTrailingArrow={true}
            />
            {(!isIOS && !isAndroid) &&
                <ContactUsRow
                    title={strings["contact_us.options.in_app"]}
                    topIcon={"circle-atlantic-logo"}
                    qrCodeUrl={Links.atlantic.app.contactUs}
                />
            }
            <ContactUsRow
              title={strings["help_center.chat.whatsapp"]}
              topIcon={"circle-whatsapp-logo"}
              secondary={strings["contact_us.chat_now"]}
              href={whatsAppLink}
              hasTrailingArrow={true}
              authorizeSupport={true}
            />
            {(isIOS || isMacOs) &&
                <ContactUsRow
                    title={strings["help_center.chat.imessage"]}
                    topIcon={"circle-imessage-logo"}
                    secondary={strings["contact_us.chat_now"]}
                    href={iMessageLink}
                    hasTrailingArrow={true}
                    authorizeSupport={true}
                />
            }
          </LimiterOverflow>
        </Section>
      </TitleSubtitleLeftScreen>
    </Limiter>
  )
}

export default ContactUsPage;