import * as ReactIntl from "react-intl";

type FormatStyle = "Mdyyyy" | "MMMMd" | "EEEEMMMMd" | "EEEE" | "jmm" | "MMMMdyyyyjmm"

const formatStyles: Record<FormatStyle, Intl.DateTimeFormatOptions> = {
  Mdyyyy: {
    month: "2-digit",
    day: "numeric",
    year: "numeric",
  },
  MMMMd: {
    month: "long",
    day: "numeric",
  },
  EEEEMMMMd: {
    weekday: "long",
    month: "long",
    day: "numeric",
  },
  EEEE: {
    weekday: "long",
  },
  jmm: {
    hour: "numeric",
    minute: "numeric",
  },
  MMMMdyyyyjmm: {
    month: "long",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  },
}



type Props = {
  value: Date
  style: FormatStyle,
}

const FormattedDate = (props: Props) => {
  const style = formatStyles[props.style]
  return (
    <ReactIntl.FormattedDate
      {...style}
      value={props.value}
    />
  );
}

export default FormattedDate;