import Skeleton from "../Skeleton";

const SkeletonScreen = () => {
  return (
    <div className={"flex flex-col gap-4"}>
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </div>
  )
}

export default SkeletonScreen