import {atom, useRecoilState, useResetRecoilState} from "recoil";
import {useMemo} from "react";
import {RecipientElements} from "../../pages/recipients/create/recipient-create-controller";

type State = {
  currencyCode: string
  elements: RecipientElements
}

const addRecipientElementsAtom = atom<State | undefined>({
  key: "recipient.addRecipientElements",
  default: undefined,
})


export const useAddRecipientElementsState = () => {
  const [state, setState] = useRecoilState(addRecipientElementsAtom)
  const reset = useResetRecoilState(addRecipientElementsAtom)
  return useMemo(() => [state, setState, reset] as const, [state, setState, reset])
}