import Caption from "./Caption";
import {Button} from "./Buttons";
import "./CookieModal.scss"
import {useEffect, useState} from "react";
import {OptInStorage} from "../../storage/opt-in";
import {useDelayUnmount} from "../../helpers/hooks";
import {defaultAnimDurationMillis} from "../../variables";
import cx from "classnames";
import {Link} from "./Link";
import {useStrings} from "../../strings";
import {TrackingServiceManager} from "../../services/tracking/TrackingService";

export const CookieModal = () => {
  const strings = useStrings()
  const [optInFlag, setOptInFlag] = useState<boolean | null>(() => OptInStorage.getGeneralOptIn()?.optIn || null)

  const {shouldRender, shouldShow} = useDelayUnmount(optInFlag === null, defaultAnimDurationMillis)

  useEffect(() => {
    if (optInFlag) TrackingServiceManager.instance.grantConsent()
  }, [optInFlag])

  const onClick = (optIn: boolean) => () => {
    setOptInFlag(optIn)
    OptInStorage.setGeneralOptIn(optIn)
  }

  return (
    <>
      {shouldRender && <div className={cx("cookie-modal", {"cookie-modal--hidden": !shouldShow})}>
          <div className={"cookie-modal__text"}>
              <Caption className={"inline"} size={"3"} text={strings["cookie.body.2"](
                <Link href={"https://atlantic.money/l/cookie-policy"} newTab>
                  <Caption
                    kind={"link"}
                    className={"inline cookie-modal__link"}
                    size={"3"}
                    text={strings["cookie.link"]}
                  />
                </Link>
              )}/>
          </div>
          <div className={"cookie-modal__buttons"}>
              <Button type={"button"}
                      title={strings["cookie.accept"]}
                      size={"small"}
                      color={"primary-10"}
                      onClick={onClick(true)}/>
              <Button type={"button"}
                      className={"ml-2"}
                      title={strings["cookie.decline"]}
                      size={"small"} color={"primary-10"}
                      onClick={onClick(false)}/>
          </div>
      </div>
      }
    </>
  )
}