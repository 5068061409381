export type PollOptions<T> = {
  maxDurationMs: number
  intervalMs: number
  pollCondition: (value: T) => boolean
  onError?: (err: unknown) => void
}

export type PollResult<T> = { tag: "expired", value?: T } | { tag: "satisfied", value: T }

export class PollResultPromise<T> extends Promise<PollResult<T>> {
  pollThen<TResult1 = T, TResult2 = T>(opts: {
    onExpire?: ((value: T | undefined) => TResult1 | PromiseLike<TResult1>) | undefined | null,
    onSatisfied?: ((value: T) => TResult2 | PromiseLike<TResult2>) | undefined | null
  }): Promise<TResult1 | TResult2 | undefined> {
    return this.then((result):
      TResult1 | PromiseLike<TResult1> |
      TResult2 | PromiseLike<TResult2> | undefined => {
      switch (result.tag) {
        case "expired":
          return opts.onExpire?.(result.value)
        case "satisfied":
          return opts.onSatisfied?.(result.value)
      }
    })
  }
}

export function poll<T>(fn: () => Promise<T>, opts: PollOptions<T>): PollResultPromise<T> {
  return new PollResultPromise<T>((resolve, _reject) => {
    const startTime = Date.now()
    let lastValue: T | undefined = undefined
    let lastError: undefined | unknown = undefined

    const run = () => {
      if (Date.now() - startTime > opts.maxDurationMs) {
        if (opts.onError) {
          opts.onError(lastError)
        }
        resolve({tag: "expired", value: lastValue})
        return
      }

      fn().then((value) => {
        lastValue = value
      }, (err) => {
        lastError = err
      })

      if (lastValue && opts.pollCondition(lastValue)) {
        resolve({tag: "satisfied", value: lastValue})
      } else {
        setTimeout(run, opts.intervalMs)
      }
    }

    run()
  })
}