import React, {ComponentType, FunctionComponent} from 'react';
import {useParams, useSearchParams} from 'react-router-dom';

export type NoRequiredPathParams = never
export type NoRequiredQueryParams = never

export const withParametricRouter = <
  Props,
  RequiredPathKeys extends string = never,
  OptionalPathKeys extends string = never,
  RequiredQueryKeys extends string = never,
  OptionalQueryKeys extends string = never,
  FinalProps extends (
    Record<RequiredPathKeys, string>
    & Record<RequiredQueryKeys, string>
    & Partial<Record<OptionalQueryKeys, string>>
    & Props)
    = (
    Record<RequiredPathKeys, string>
    & Partial<Record<OptionalPathKeys, string>>
    & Record<RequiredQueryKeys, string>
    & Partial<Record<OptionalQueryKeys, string>>
    & Props
    )
>(
  keys: {
    requiredPathKeys?: RequiredPathKeys[],
    optionalPathKeys?: OptionalPathKeys[],
    requiredQueryKeys?: RequiredQueryKeys[],
    optionalQueryKeys?: OptionalQueryKeys[]
  }) => (
  WrappedComponent: ComponentType<FinalProps>
): FunctionComponent<Props> => {
  return (props: Props) => {
    const routerParams = useParams<Record<string, string>>();

    const requiredPathParams: Record<string, string> = {}
    for (const key of keys.requiredPathKeys ?? []) {
      const param = routerParams[key]
      if (param === undefined) {
        throw new Error(`Path parameter ${key} is required`)
      }
      requiredPathParams[key] = param
    }

    const optionalPathParams: Record<string, string> = {}
    for (const key of keys.optionalPathKeys ?? []) {
      const param = routerParams[key]
      if (param !== undefined) {
        optionalPathParams[key] = param
      }
    }

    const [queryParams, _] = useSearchParams()

    const requiredQueryParams: Record<string, string> = {}
    for (const key of keys.requiredQueryKeys ?? []) {
      const param = queryParams.get(key)
      if (param === null) {
        throw new Error(`Query parameter ${key} is required`)
      }
      requiredQueryParams[key] = param
    }

    const optionalQueryParams: Record<string, string | undefined> = {}
    for (const key of keys.optionalQueryKeys ?? []) {
      const param = queryParams.get(key)
      if (param !== null) {
        optionalQueryParams[key] = param
      }
    }

    const finalProps = {
      ...props,
      ...requiredPathParams,
      ...optionalPathParams,
      ...requiredQueryParams,
      ...optionalQueryParams
    } as FinalProps

    return <WrappedComponent {...finalProps}/>;
  };
};

type PropsParam = {
  propsParam: string
}

type PathKeys = "id"
type RequiredQueryKeys = "name"
type OptionalQueryKeys = "age"

// MARK: - Example usage
const _WithParametricRouterDemo = withParametricRouter<
  PropsParam, PathKeys, never, RequiredQueryKeys, OptionalQueryKeys
>({
  requiredPathKeys: ["id"],
  requiredQueryKeys: ["name"],
  optionalQueryKeys: ["age"]
})((props: {id: string, name: string, age?: string}) => {
  return (
    <div>
      <h1>id: {props.id}</h1>
      <h1>name: {props.name}</h1>
      <h1>age: {props.age ?? "undefined"}</h1>
    </div>
  )
})