import {Money} from "../../helpers/money";
import * as TransferModels from "../../api/whales/TransferCommon";

export class TierConsumedMoney {
  money: Money
  durationInDays: number

  constructor(other: {
    money: Money,
    durationInDays: number
  }) {
    this.money = other.money
    this.durationInDays = other.durationInDays
  }

  static fromApi(api: TransferModels.ConsumedMoney): TierConsumedMoney {
    return new TierConsumedMoney({
      money: Money.fromApi(api.money),
      durationInDays: api.duration
    })
  }
}