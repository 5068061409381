import EstimateDeliveryOption from "./EstimateDeliveryOption";
import {Estimate} from "../../../models/transfers/Estimate";
import {TransferContext} from "../../../models/transfers/TransferContext";
import {useUserDefaultsState} from "../../../atoms/user-defaults";

type Props = {
  transferContext?: TransferContext
  onEstimateSelect: (estimate: Estimate) => void;
};

export const DeliveryOptionRows = ({transferContext, onEstimateSelect}: Props) => {
  const deliveryCandidateEstimates = transferContext
      ?.deliveryCandidateTransferEstimates
      ?.sort((a, b) => a.deliveryOption.fee.amount.minus(b.deliveryOption.fee.amount).toNumber())
    || [];
  const [_, setUserDefaults] = useUserDefaultsState()

  const options = deliveryCandidateEstimates.map((estimate, index) => {
    return <EstimateDeliveryOption
      estimate={estimate}
      key={index}
      isLastOption={index === deliveryCandidateEstimates.length - 1}
      onEstimateSelect={async () => {
        setUserDefaults(prev => ({
          ...prev, lastSelectedDeliveryOptionKind: estimate.deliveryOption.kind
        }))
        onEstimateSelect(estimate)
      }}
    />
  })

  return <>{options}</>
}

const EstimateDeliveryOptionList = (props: Props) => {
  return (
    <div className="rounded-[16px] px-[24px] py-[16px] border border-primary-10 mx-auto">
      <DeliveryOptionRows {...props}/>
    </div>
  );
};

export default EstimateDeliveryOptionList;