import {atom, useRecoilState, useResetRecoilState} from "recoil";
import {useMemo} from "react";

type State = {
  selectOther: string
}

const tsofVerificationAtom = atom<State>({
  key: "verification.tsof",
  default: {
    selectOther: "",
  },
})


export const useTSOFVerificationState = () => {
  const [state, setState] = useRecoilState(tsofVerificationAtom)
  const reset = useResetRecoilState(tsofVerificationAtom)
  return useMemo(() => [state, setState, reset] as const, [state, setState, reset])
}