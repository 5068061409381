import * as CommonModels from "../api/whales/Common";

export class Address {
  line1: string
  line2: string
  city: string

  stateOrProvince?: string
  postcode: string
  countryCode: string

  constructor(other: {
    line1: string
    line2: string
    city: string
    stateOrProvince?: string
    postcode: string
    countryCode: string
  }) {
    this.line1 = other.line1
    this.line2 = other.line2
    this.city = other.city
    this.stateOrProvince = other.stateOrProvince
    this.postcode = other.postcode
    this.countryCode = other.countryCode
  }

  static fromApi(address: CommonModels.Address): Address {
    return new Address({
      line1: address.addressLine1,
      line2: address.addressLine2 ?? "",
      city: address.city,
      stateOrProvince: address.stateOrProvince,
      postcode: address.postcode,
      countryCode: address.countryCode
    })
  }

  toApi(): CommonModels.Address {
    return {
      addressLine1: this.line1,
      addressLine2: this.line2 === "" ? null : this.line2,
      city: this.city,
      stateOrProvince: this.stateOrProvince,
      postcode: this.postcode,
      countryCode: this.countryCode
    }
  }

  static empty(): Address {
    return new Address({
      line1: "",
      line2: "",
      city: "",
      postcode: "",
      countryCode: "",
    })
  }

  static fallback(): Address {
    return new Address({
      line1: "123 Main St",
      line2: "Door B",
      city: "London",
      postcode: "SW1W0NY",
      countryCode: "GB"
    })
  }

}