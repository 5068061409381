import {BankAccountElementId} from "../../api/whales/Common";
import {InterfaceTemplate} from "../../components/domain/interface-template/domain";
import * as RecipientsAPI from "../../api/whales/Recipients";
import {Currency} from "../../helpers/money";
import {BankAccountElement, BankAccountElementText} from "../../components/domain/recipients/domain/BankAccountElement";

export class BankAccountElementTemplate {
  readonly identifier: BankAccountElementId
  readonly elementName: string
  readonly interfaceTemplate: InterfaceTemplate
  readonly isImportant: boolean

  constructor(other: {
    identifier: BankAccountElementId
    elementName: string
    interfaceTemplate: InterfaceTemplate
    isImportant: boolean
  }) {
    this.identifier = other.identifier
    this.elementName = other.elementName
    this.interfaceTemplate = other.interfaceTemplate
    this.isImportant = other.isImportant
  }

  static fromApi(template: NonNullable<RecipientsAPI.Recipient["bankAccount"]["template"]>["templateElements"][0]): BankAccountElementTemplate {
    return new BankAccountElementTemplate({
      identifier: template.elementId,
      elementName: template.elementName,
      interfaceTemplate: new InterfaceTemplate(template.interfaceTemplate),
      isImportant: template.isImportant,
    })
  }
}

export class BankAccountTemplate {
  readonly currencyCode: string
  readonly elements: BankAccountElementTemplate[]

  constructor(other: {
    currencyCode: string
    elements: BankAccountElementTemplate[]
  }) {
    this.currencyCode = other.currencyCode
    this.elements = other.elements
  }

  static fromApi(template: NonNullable<RecipientsAPI.Recipient["bankAccount"]["template"]>): BankAccountTemplate {
    return new BankAccountTemplate({
      currencyCode: template.currencyCode,
      elements: template.templateElements.map(elem => BankAccountElementTemplate.fromApi(elem))
    })
  }
}


export interface IBankAccount {
  readonly currency: Currency
  readonly bankName?: string
  readonly template: BankAccountTemplate
  readonly elements: BankAccountElement[]
}

export class BankAccount {
  readonly currency: Currency
  readonly bankName?: string
  readonly template: BankAccountTemplate
  readonly elements: BankAccountElement[]

  constructor(other: IBankAccount) {
    this.currency = other.currency
    this.bankName = other.bankName
    this.template = other.template
    this.elements = other.elements
  }

  get holderName(): string | undefined {
    const el = this.elements
      .find(element => element.identifier === "holderName")


    if (el instanceof BankAccountElementText) {
      return el.value.textValue
    }
    return undefined
  }

  displayElements(
    options?: {
      removeElements: BankAccountElementId[]
    }): BankAccountElement[] {
    const removeElements = options?.removeElements ?? []

    return this.template.elements
      .filter((element) => !removeElements.includes(element.identifier))
      .flatMap((templateElement) => {
        const element = this.elements.find((element) => {
          return element.identifier === templateElement.identifier
        })

        return element ? [element] : []
      })
  }


  static fromApi(bankAccount: RecipientsAPI.Recipient["bankAccount"]): BankAccount {
    const template = BankAccountTemplate.fromApi(bankAccount.template!);
    return new BankAccount({
      currency: Currency.default(bankAccount.currencyCode),
      bankName: bankAccount.bankName || undefined,
      template: template,
      elements: bankAccount.elements.map((el) => BankAccountElement.fromApi(el, template)),
    })
  }
}
