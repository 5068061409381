import React, {useState} from "react";
import {useAuthApi} from "../../../api/auth";
import {useApi} from "../../../api/axios";
import {useBusinessOnboardingQuery, useOnboardingQuery} from "../../../api/onboarding";
import {
  AccessToken,
  AuthorizeRequest,
  OTPConfirmationRequest,
  TokenResponse,
  UserInfoResponse
} from "../../../api/whales/Auth";
import {useAuthActions, User} from "../../../atoms/auth";
import ButtonBar from "../../../components/ui/ButtonBar";
import {Button, InlineButton} from "../../../components/ui/Buttons";
import Caption from "../../../components/ui/Caption";
import Collapsable from "../../../components/ui/Collapsable";
import {
  EmailInput,
  Form,
  InputGroup,
  OtpInput,
  PasswordInput,
  useOtpFocuser,
} from "../../../components/ui/input/Inputs";
import {useAsyncLoading} from "../../../helpers/hooks";
import {useAlertStack} from "../../../providers/alert-stack";
import {useStrings} from "../../../strings";
import {Links} from "../../../helpers/Links";
import {useTransfersApi} from "../../../api/transfers";
import _ from "lodash";
import {useUserDefaultsState} from "../../../atoms/user-defaults";

type SignInFormState = {
  email: string;
  password: string;
  otp?: {
    destinationMask: string
    ticket: string;
    channel: "phone" | "email"
    code: string;
    error?: any
  },
};

const SignInForm = () => {
    const strings = useStrings()
    const api = useApi()
    const authApi = useAuthApi()
    const onboardingQuery = useOnboardingQuery()
    const transfersApi = useTransfersApi()
    const businessOnboardingQuery = useBusinessOnboardingQuery()
    const authActions = useAuthActions()
    const errorStack = useAlertStack()
    const [_userDefaults, setUserDefaults] = useUserDefaultsState()
    const [state, setState] = useState<SignInFormState>({
      email: "",
      password: "",
    })

    const onAuthorized = async (token: AccessToken) => {
      const response = await api.get<UserInfoResponse>("/auth/v1/user/info/get", {}, {
        "Authorization": `Bearer ${token.accessToken}`
      })
      if (response.payload.userType === "business") {
        await businessOnboardingQuery.application.get.preFetch(response.payload.userId, token.accessToken).catch(() => {
        })
      } else {
        await onboardingQuery.application.get.preFetch(response.payload.userId, token.accessToken).catch(() => {
        })
      }
      const lastTransfer = await transfersApi.history({limit: 1, accessToken: token})
          .then(list => _.head(list.results))
      if (lastTransfer) {
        setUserDefaults(prev => ({
          ...prev,
          lastViewedSourceCurrency: lastTransfer.sourceMoney.currency,
          lastViewedDestinationCurrency: lastTransfer.destinationMoney.currency
        }))
      }

      authActions.login(token, new User(response.payload.userId, response.payload.email, response.payload.region, response.payload.countryCode, response.payload.userType))
      return token
    }

    const otpFocuser = useOtpFocuser()

    const authorize = async (): Promise<AccessToken | undefined> => {
      return api.post<TokenResponse, AuthorizeRequest>("/auth/v1/authorize", {
        email: state.email,
        password: state.password
      }).then(async response => {
        if (response.payload.tokens != null) {
          return onAuthorized(response.payload.tokens)
        }

        if (response.payload.confirmation != null) {
          setState((prev) => ({
            ...prev,
            otp: {
              channel: response.payload.confirmation?.channel || "phone",
              destinationMask: response.payload.confirmation?.channelDestinationMask || "",
              ticket: response.payload.confirmation?.ticket || "",
              code: ""
            }
          }))
        }
      }).catch(async (e) => {
        errorStack.showError(e)
        return undefined
      })
    }

    const confirmOtpCode = async (): Promise<AccessToken | undefined> => {
      return api.post<TokenResponse, OTPConfirmationRequest>("/auth/v1/authorize/otp/confirm", {
        code: state.otp?.code || "",
        ticket: state.otp?.ticket || ""
      }).then(response => {
        if (response.payload.tokens) {
          return onAuthorized(response.payload.tokens)
        } else {
          return Promise.reject(Error("no tokens in confirm code response"))
        }
      }).catch(async err => {
        setState(prev => ({
          ...prev,
          otp: prev.otp && {...prev.otp, code: "", error: err}
        }))
        return Promise.reject(err)
      })
    }

    const {isLoading, asyncWithLoading} = useAsyncLoading(false)

    const onResendOtpCode = () => asyncWithLoading(async () => {
      await authApi.resendOtpCode({ticket: state.otp?.ticket || ""}).catch(errorStack.showError)
      otpFocuser.focus()
    })

    const onSubmit = () => asyncWithLoading(async () => {
        try {
          !state.otp ? await authorize() : await confirmOtpCode()
        } catch (e) {
          errorStack.showError(e)
        }
      }
    )

    return <Form className={"w-full"} onSubmit={onSubmit}>
      <InputGroup>
        <EmailInput
          disabled={isLoading}
          placeholder={strings["signup.email.placeholder"]}
          value={state.email}
          error={undefined}
          onChange={(newValue) => {
            setState((prev) => ({...prev, email: newValue}))
          }}
        />
        <PasswordInput
          disabled={isLoading}
          passwordKind="current"
          placeholder={strings["signup.password.placeholder"]}
          value={state.password}
          error={undefined}
          onChange={(newValue) => {
            setState((prev) => ({...prev, password: newValue}))
          }}
        />
        <Collapsable expanded={!!state.otp} onExpanded={() => otpFocuser.focus()}>
          <div className="flex mb-[16px] mt-[20px]">
            <Caption size={"2-title"} text={strings["login.confirm_otp.title"]}/>
            <InlineButton className={"ml-auto"} type={"button"}
                          title={<Caption size={"3-title"} text={strings["signup.verify_phone_number.resend_title"]}/>}
                          onClick={onResendOtpCode}/>
          </div>
          <OtpInput
            focuser={otpFocuser}
            disabled={isLoading}
            value={state.otp?.code || ""}
            error={state.otp?.error}
            onChange={(newValue =>
              setState((prev) => ({...prev, otp: prev.otp && {...prev.otp, code: newValue}})))
            }
            onComplete={onSubmit}
          />
          <Caption className={"block text-left mt-[8px]"}
                   color={"primary-50"}
                   text={strings[`login.confirm_otp.subtitle.${state.otp?.channel || "phone"}`](state.otp?.destinationMask || "")}
          />
        </Collapsable>
      </InputGroup>

      <ButtonBar className={"gap-5"} align={"center"}>
        <Button
          type="submit" size={"max"} color={"primary-black"}
          title={strings["launch.log_in"]}
          loading={isLoading}
        />
        <InlineButton
          type={"link"}
          href={Links.atlantic.auth.signUp.individual}
          title={<Caption size={"3-title"} text={strings["web.launch.sign_up"]}/>}
        />
        <InlineButton
          type={"link"}
          href={Links.atlantic.auth.forgotPassword}
          title={<Caption size={"3-title"} text={strings["log_in.forgot_password"]}/>}
        />
      </ButtonBar>
    </Form>
  }
;

export default SignInForm;