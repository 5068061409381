import FormattedMoney from "../../formatters/money/FormattedMoney";
import {Transfer} from "../../../models/transfers/Transfer";
import {useStrings} from "../../../strings";

export const TransferTitle = ({transfer}: {transfer: Transfer}) => {
  const strings = useStrings()
  const exactAmounts = transfer.displaying().exactAmounts;
  const money = transfer.entryMode === "source" ? transfer.sourceMoney : transfer.destinationMoney;
  const formattedMoney = <FormattedMoney value={money} />
  const recipientName = transfer.recipient.displayName ?? ""

  if (exactAmounts.includes(transfer.entryMode)) {
    return strings["transfer_detail.title.exact"](formattedMoney, recipientName)
  } else {
    return strings["transfer_detail.title.approximate"](formattedMoney, recipientName)
  }
};