import {PropsClassName} from "../../helpers/props";
import {Money} from "../../helpers/money";
import {useStrings} from "../../strings";
import ProgressRow from "../../components/ui/collections/rows/ProgressRow";
import FormattedMoney from "../../components/formatters/money/FormattedMoney";
import React from "react";

export const LimitProgressRow = (props: PropsClassName & {
  caption: React.ReactNode,
  consumed: Money,
  limit: Money,
}) => {
  const strings = useStrings()
  const progressFraction = props.consumed.amount.div(props.limit.amount).toNumber()
  const remainingAmount = props.limit.updated({
    amount: props.limit.amount.sub(props.consumed.amount)
  })

  return (
    <ProgressRow
      className={props.className}
      caption={props.caption}
      content={
        <span>
          <FormattedMoney value={props.consumed}/>
          {" / "}
          <FormattedMoney value={props.limit}/>
        </span>
      }
      progressFraction={progressFraction}
      secondary={strings["tier.row.remaining_amount"](<FormattedMoney value={remainingAmount}/>)}
    />
  )
}
