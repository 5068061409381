import React from "react";
import {useVerificationApi} from "../../api/verification";
import {useAlertStack} from "../../providers/alert-stack";
import BusinessInfoReview from "../onboarding/business/BusinessInfoReview";
import {BusinessUpdateKeyPeople} from "../onboarding/business/BusinessKeyPeople";
import {PropsOnComplete} from "../../helpers/props";
import {NavStackPush, useNavStack} from "../../atoms/nav-stack";
import {Verification} from "../domain/verifications/domain";
import {Check} from "../domain/common/check";
import {VerificationDocumentStepUpload} from "./document/VerificationDocumentStepUpload";
import VerificationTextStepSubmit from "./VerificationTextStepSubmit";
import VerificationSingleSelectStepSubmit from "./VerificationSingleSelectStepSubmit";
import VerificationMultiSelectStepSubmit from "./VerificationMultiSelectStepSubmit";
import VerificationPickerStepSubmit from "./VerificationPickerStepSubmit";
import VerificationAddressStepSubmit from "./VerificationAddressStepSubmit";
import _ from "lodash";
import {useTransferSourceOfFundsController} from "./tsof/controller";
import {useSelfieIdController} from "./selfie-id/controller";
import VerificationEmploymentStepSubmit from "./VerificationEmploymentStepSubmit";

export const useVerificationNavStack = useNavStack<{}, {}>

export type VerificationProps = {
  titleAlign: "center" | "left",
  verification: Verification,
} & PropsOnComplete

type UseVerificationOptions = {
  titleAlign: "center" | "left",
  sourceStackEntry: React.FC<any> | undefined,
  onFinished: () => Promise<void>
}

export const useVerificationController = () => {
  const navStack = useVerificationNavStack()
  const verificationApi = useVerificationApi()
  const alertStack = useAlertStack()

  const transferSourceOfFundsController = useTransferSourceOfFundsController()
  const selfieIdController = useSelfieIdController()

  const nextScreen = async (idx: number, verifications: Verification[], options: UseVerificationOptions): Promise<void> => {
    for (let i = idx; i < verifications.length; i++) {
      if (verifications[i].status === "accepted") continue
      const v = await verificationApi.getPublicOrAuthorized(verifications[i].id, verifications[i].ticket)
      const it = v.template.interfaceTemplate
      if (it === undefined || !v.isUserInputRequired()) {
        continue
      }

      const commonProps: VerificationProps = {
        titleAlign: options.titleAlign,
        verification: v,
        onComplete: () => {
          return nextScreen(i + 1, verifications, options)
        }
      }
      const pushFirst: NavStackPush<{}, {}> = (c, args, opts) =>
        navStack.push(c, args, {
          ...opts,
          popUntil: options.sourceStackEntry && {component: options.sourceStackEntry, inclusive: false},
          popAll: options.sourceStackEntry === undefined
        })


      const typedTemplate = it.typed

      switch (typedTemplate.kind) {
        case "text":
          pushFirst(VerificationTextStepSubmit, {...commonProps, template: typedTemplate.text})
          return
        case "singleSelect":
          pushFirst(VerificationSingleSelectStepSubmit, {...commonProps, template: typedTemplate.singleSelect})
          return
        case "multiSelect":
          pushFirst(VerificationMultiSelectStepSubmit, {...commonProps, template: typedTemplate.multiSelect})
          return
        case "picker":
          pushFirst(VerificationPickerStepSubmit, {...commonProps, template: typedTemplate.picker})
          return
        case "address":
          pushFirst(VerificationAddressStepSubmit, {...commonProps, template: typedTemplate.address})
          return
        case "employment":
          pushFirst(VerificationEmploymentStepSubmit, {...commonProps, template: typedTemplate.employment})
          return
        case "selfieId":
          return selfieIdController.routeIntro({
            titleAlign: options.titleAlign,
            verification: v,
            onComplete: () => commonProps.onComplete(),
            pushFirst
          })
        case "transferSourceOfFunds":
          return transferSourceOfFundsController.routeFirst({
            verification: commonProps.verification,
            titleAlign: options.titleAlign,
            template: typedTemplate.transferSourceOfFunds,
            onComplete: () => commonProps.onComplete(),
            pushFirst
          })
        case "document":
          pushFirst(VerificationDocumentStepUpload, {...commonProps, template: typedTemplate.document})
          return
        case "businessInfo":
        case "businessKeyPeople":
          const onFinishEditing = () => {
            return verificationApi.pollVerification(v, {
              maxDurationMs: 10_000,
              intervalMs: 1000,
              pollCondition: (v) => v.status === "accepted" || v.documentTemplateIfBusiness() !== undefined,
              onError: (e) => alertStack.showError(e)
            }).pollThen({
              onSatisfied: (value) => {
                const template = value.documentTemplateIfBusiness()
                if (template === undefined) {
                  return options.onFinished()
                }

                pushFirst(VerificationDocumentStepUpload, {...commonProps, template})
              },
              onExpire: () => {
                return options.onFinished()
              }
            })
          }
          switch (typedTemplate.kind) {
            case "businessInfo":
              pushFirst(BusinessInfoReview, {onComplete: onFinishEditing})
              break;
            case "businessKeyPeople":
              pushFirst(BusinessUpdateKeyPeople, {onComplete: onFinishEditing})
              break;
          }
          return
        default:
          throw new Error(`Unknown interface template ${it}`,)
      }
    }

    return options.onFinished()
  }

  return (verifications: Verification[], options: UseVerificationOptions) => nextScreen(0, _.uniqBy(verifications, v => v.id), options)
}

type UseChecksOptions = {
  titleAlign: "center" | "left",
  sourceStackEntry: React.FC<any>,
  onFinished: () => Promise<void>
}

export const useChecksController = () => {
  const verificationController = useVerificationController()

  const processChecks = async (idx: number, checks: Check[], options: UseChecksOptions) => {
    if (idx >= checks.length) {
      return options.onFinished()
    }
    const check = checks[idx]
    switch (check.type) {
      case "business_info":
      case "business_key_people":
      default:
        await verificationController(check.verifications || [], {
          titleAlign: options.titleAlign,
          sourceStackEntry: options.sourceStackEntry,
          onFinished: () => processChecks(idx + 1, checks, options)
        })
        return
    }
  }

  return (checks: Check[], options: UseChecksOptions) => processChecks(0, checks, options)
}