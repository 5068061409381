import {BusinessApplicationResponse, BusinessContact} from "../../../api/whales/Onboarding";
import {Check, EntityChecksExt} from "../common/check";

export class BusinessApplicationExt {
  static isUserInputRequired(application: BusinessApplicationResponse): boolean {
    const allChecks = application.payload.info.checks.concat(
      application.payload.contacts.flatMap(contact => contact.checks)
    )

    return allChecks.some(check => Check.fromApiCommon(check).isUserInputRequired())
  }

  static contactsWithRoles(application: BusinessApplicationResponse): BusinessContact[] {
    return application.payload.contacts.filter((c) => c.roles.length > 0)
  }
}

type BusinessContactStatusIOS = "input-pending" | "in-review" | "rejected" | "approved" | "deleted"

export class BusinessContactExt {
  static fullName(contact: BusinessContact) {
    return [contact.firstName, contact.lastName].join(" ")
  }

  static getIOSStatus(contact: BusinessContact): BusinessContactStatusIOS {
    switch (contact.status) {
      case "new":
        return "in-review"
      case "checks-pending":
        if (EntityChecksExt.isUserInputRequired(contact.checks.map(Check.fromApiCommon))) {
          return "input-pending"
        } else {
          return "in-review"
        }
      case "checks-approved":
        return "approved"
      case "deleted":
        return "deleted"
      default:
        return "in-review"
    }
  }

  static verificationShareUrl(contact: BusinessContact): string | undefined {
    return contact.checks.flatMap((c) => c.verifications)
      .map((v) => v?.shareUrl)
      .find((u) => u !== undefined)
  }
}

export class BusinessContactsExt {
  static areApproved(contacts: BusinessContact[]): boolean {
    return contacts.every((c) => c.status === "checks-approved")
  }
}

