import {Button} from "../../ui/Buttons";
import {Form, InputGroup, TextInput} from "../../ui/input/Inputs";
import ButtonBar from "../../ui/ButtonBar";
import {useEffect, useState} from "react";
import {useBusinessOnboardingQuery, useOnboardingQuery} from "../../../api/onboarding";
import {useAsyncLoading} from "../../../helpers/hooks";
import {OnboardingCtx, useOnboardingNavStack} from "./BusinessOnboardingScreen";
import {TitleSubtitleCenterScreen} from "../../ui/screen/TitleSubtitleScreen";
import BusinessOnboardingAddress from "./BusinessOnboardingAddress";
import {useSideIllustration} from "../../../atoms/side-illustration";
import {useStrings} from "../../../strings";
import {WithSkeletonFlag} from "../../ui/Skeleton";
import {InterfaceTemplateTitle} from "../../domain/interface-template/InterfaceTemplateTitle";
import {InterfaceTemplateTextInput} from "../../domain/interface-template/inputs/InterfaceTemplateTextInput";
import {InterfaceTemplate} from "../../domain/interface-template/domain";

type Props = OnboardingCtx

type State = {
  inputs?: {
    legalName: string
    identifier: string
  },
  errors: {
    legalName?: any,
    identifier?: any,
  }
}

const BusinessOnboardingCompanyInformation = ({application, ...props}: Props) => {
  const strings = useStrings()
  const [state, setState] = useState<State>({
    inputs: undefined,
    errors: {
      legalName: undefined,
      identifier: undefined
    }
  })

  useSideIllustration("briefcase")

  useEffect(() => {
    if (application === undefined) {
      return
    }

    setState((prev) => ({
      ...prev,
      inputs: prev.inputs ? prev.inputs : {
        legalName: application.payload.info.legalName ?? "",
        identifier: application.payload.info.identifier ?? "",
      }
    }))

  }, [application]);

  const onboardingQuery = useOnboardingQuery()
  const businessOnboardingQuery = useBusinessOnboardingQuery()
  const updateLegalName = businessOnboardingQuery.application.updateLegalName.useMutation(props.user.userId)
  const updateIdentifier = businessOnboardingQuery.application.updateIdentifier.useMutation(props.user.userId)
  const applicationTemplate = onboardingQuery.application.template.useQuery()

  const {isLoading: asyncIsLoading, asyncWithLoading} = useAsyncLoading(false)
  const isLoading = asyncIsLoading || applicationTemplate.isLoading

  const setError = (label: keyof State["errors"]) => (err: any) => {
    setState(prev => ({...prev, errors: {...prev.errors, [label]: err}}))
  }
  const unsetError = (label: keyof State["errors"]) => {
    setError(label)(undefined)
  }

  const onUpdateLegalName = () => {
    return asyncWithLoading(() => updateLegalName.mutateAsync({legalName: state.inputs?.legalName ?? ""}, {
      onSuccess: () => unsetError("legalName"),
      onError: setError("legalName")
    }))
  }

  const onUpdateIdentifier = () => {
    return asyncWithLoading(() => updateIdentifier.mutateAsync({identifier: state.inputs?.identifier ?? ""}, {
      onSuccess: () => unsetError("identifier"),
      onError: setError("identifier")
    }))
  }

  const onFormSubmit = async () => {
    await onUpdateLegalName()
    await onUpdateIdentifier()
    navStack.push(BusinessOnboardingAddress, {})
  }

  const onChange = (fieldName: "legalName" | "identifier") => {
    return (value: string) => {
      setState((prev) => ({
        ...prev,
        inputs: prev.inputs !== undefined ? {
          ...prev.inputs,
          [fieldName]: value
        } : undefined,
        errors: {
          legalName: undefined,
          identifier: undefined
        }
      }))
    }
  }

  const navStack = useOnboardingNavStack()

  return (
    <TitleSubtitleCenterScreen title={strings["business_signup.company_information.title"]}>
      <Form onSubmit={onFormSubmit}>
        <InputGroup name={strings["business_signup.legal_name.title"]}>
          <TextInput capitalization={"words"}
                     onChange={onChange("legalName")}
                     error={state.errors.legalName}
                     placeholder={strings["business_signup.legal_name.placeholder"]}
                     disabled={isLoading}
                     value={state.inputs?.legalName}
          />
        </InputGroup>
        {function () {
          const template = applicationTemplate.data?.interfaceTemplateFor("businessIdentifier")

          return (
            <InputGroup
              name={function () {
                return (
                  <WithSkeletonFlag isLoading={applicationTemplate.isLoading}>
                    {template
                      ? <InterfaceTemplateTitle template={template}/>
                      : strings["business_signup.identifier.title"]
                    }
                  </WithSkeletonFlag>
                )
              }()}
            >
              <InterfaceTemplateTextInput
                capitalization={"characters"}
                template={
                  (template?.textTemplate
                    ?? InterfaceTemplate.fallbackTextTemplate().textTemplate!)
                    .with({placeholder: strings["business_signup.identifier.placeholder"]})
                }
                value={{kind: "text", textValue: state.inputs?.identifier ?? ""}}
                isLoading={applicationTemplate.isLoading}
                disabled={isLoading}
                error={state.errors.identifier}
                onChange={value => onChange("identifier")(value.textValue)}
              />
            </InputGroup>
          )
        }()
        }

        <ButtonBar align={"center"}>
          <Button type={"submit"} title={strings["general.continue"]} size={"max"} color={"primary-black"}
                  loading={isLoading}/>
        </ButtonBar>
      </Form>
    </TitleSubtitleCenterScreen>
  )
};

export default BusinessOnboardingCompanyInformation;