import {withParametricRouter} from "../../routes/parameteric-router";
import {useAuthValue} from "../../atoms/auth";
import {NavStackDefaultWrapper, NavStackRoot} from "../../components/navigation/NavStack";
import SkeletonScreen from "../../components/ui/screen/SkeletonScreen";
import {useVerificationController} from "../../components/verification/verification-controller";
import {useNavigate} from "../../routes/router-dom-wrapper/hooks";
import {Links} from "../../helpers/Links";
import {useVerificationApi} from "../../api/verification";
import Navigate from "../../routes/router-dom-wrapper/Navigate";
import {useLoadingBlockerState} from "../../atoms/loading-blocker";
import {useEffectOnce} from "../../hooks/effect";

const VerificationsPage = withParametricRouter({
  requiredPathKeys: ["id"],
  optionalQueryKeys: ["ticket", "public"]
})((props) => {
  const navigate = useNavigate()
  const loadingBlockerState = useLoadingBlockerState()
  const authValue = useAuthValue()
  const verificationApi = useVerificationApi()
  const verificationController = useVerificationController()

  if (!authValue.authenticated && props.ticket === undefined) {
    return <Navigate to={Links.atlantic.auth.signIn} replace={true} isBackAllowed={false} openLocationAfterAuth={true}/>
  }

  useEffectOnce(() => void (async () => {
    loadingBlockerState.inc()
    const v = await verificationApi.getPublicOrAuthorized(props.id, props.ticket)
    return verificationController([
      function () {
        if (props.ticket) {
          return v.withTicket(props.ticket)
        }
        return v
      }()
    ], {
      titleAlign: "left",
      sourceStackEntry: undefined,
      onFinished: async () => navigate(Links.atlantic.home, {replace: true})
    }).finally(() => loadingBlockerState.dec())
  })())

  return <NavStackRoot wrapper={NavStackDefaultWrapper} ctx={{}} root={SkeletonScreen} args={{}}/>
})

export default VerificationsPage