import {InfiniteData, useInfiniteQuery, useMutation, useQuery, useQueryClient} from "react-query";
import {Api, useApi} from "./axios";
import {EmptySuccessfulResponse} from "./whales/Common";
import * as CommonModels from "./whales/Common";
import * as TransferCommonModels from "./whales/TransferCommon";
import * as TransferV3Models from "./whales/Transfer.v3";
import * as TransferV1Models from "./whales/Transfer";
import {Money} from "../helpers/money";
import {CurrencyAvailability, DeliveryOptionKind, Estimate} from "../models/transfers/Estimate";
import {Transfer} from "../models/transfers/Transfer";
import {poll, PollOptions, PollResultPromise} from "../helpers/poller";
import {Timeline} from "../models/transfers/Timeline";
import {TierList} from "../models/tiers/TierList";
import {AccessToken} from "./whales/Auth";
import {SingleRefundInfo} from "../models/transfers/SingleRefundInfo";
import {DefaultMutationOptions, DefaultQueryOptions} from "./common";
import {PortalDeliveryInfo} from "../models/PortalDeliveryInfo";
import * as TransferModelsV3 from "./whales/Transfer.v3";

type PublicEstimateReq = {
  amount: string
  entryMode: "source" | "destination"
  sourceCurrencyCode: string
  destinationCurrencyCode: string
}

type EstimateBySourceReq = {
  sourceAmount: string,
  sourceCurrencyCode: string,
  destinationCurrencyCode: string
}

type EstimateByDestinationReq = {
  destinationAmount: string,
  destinationCurrencyCode: string,
  sourceCurrencyCode: string
}

type ListTransfersResponse = {
  results: Transfer[]
  paginationMeta: CommonModels.PaginationMeta
}

type HistoryParams = {
  cursor?: string
  limit: number
  accessToken?: AccessToken
  filter?: "in_progress" | "is_blocking"
}

export class TransfersApi {
  constructor(private api: Api) {
  }

  publicEstimateBootstrapInfo(): Promise<TransferV1Models.MarketingSiteBootstrapInfoResponse> {
    return this.api.get<TransferV1Models.MarketingSiteBootstrapInfoResponse>("/transfer/public/v1/marketing-site/bootstrap-info")
  }

  publicEstimate(req: PublicEstimateReq): Promise<TransferV1Models.PublicEstimateResponse> {
    return this.api.get<TransferV1Models.PublicEstimateResponse>("/transfer/public/v1/estimate", req)
  }

  estimateBySource(params: { srcMoney: Money, dstCurrencyCode: string }): Promise<Estimate[]> {
    const req: EstimateBySourceReq = {
      sourceAmount: params.srcMoney.amount.toString(),
      sourceCurrencyCode: params.srcMoney.currency.code,
      destinationCurrencyCode: params.dstCurrencyCode
    }
    return this.api.get<TransferV3Models.SingleTransferEstimateResponse>("/transfer/v3/single/estimate/entered-source/get", req)
      .then((res) => res.payload?.results?.map((e) => Estimate.fromApi(e)) || [])
  }

  estimateByDestination(params: { dstMoney: Money, srcCurrencyCode: string }): Promise<Estimate[]> {
    const req: EstimateByDestinationReq = {
      destinationAmount: params.dstMoney.amount.toString(),
      destinationCurrencyCode: params.dstMoney.currency.code,
      sourceCurrencyCode: params.srcCurrencyCode
    }
    return this.api.get<TransferV3Models.SingleTransferEstimateResponse>("/transfer/v3/single/estimate/entered-destination/get", req)
      .then((res) => res.payload?.results?.map((e) => Estimate.fromApi(e)) || [])
  }

  estimateValidate(params: Estimate): Promise<void> {
    return this.api.post<EmptySuccessfulResponse, TransferV3Models.SingleTransferEstimate>("/transfer/v3/single/estimate/validate", params.toApi(), undefined).then(() => {
    })
  }

  availableCurrencies(): Promise<CurrencyAvailability> {
    return this.api.get<TransferCommonModels.AvailableCurrenciesResponse>("/transfer/v3/available-currency/list").then(res => CurrencyAvailability.fromAPI(res.payload))
  }

  referenceValidate(reference: string): Promise<void> {
    return this.api.post<EmptySuccessfulResponse, undefined>("/transfer/v3/reference/validate", undefined, undefined, {reference}).then(() => {
    })
  }

  create(req: TransferV3Models.CreateSingleTransferRequest): Promise<TransferV3Models.CreateSingleTransferResponse> {
    return this.api.post<TransferV3Models.CreateSingleTransferResponse, TransferV3Models.CreateSingleTransferRequest>("/transfer/v3/single/create", req);
  }

  timeline(transferId: string): Promise<Timeline> {
    return this.api.get<TransferV3Models.SingleTransferTimelineResponse>("/transfer/v3/single/timeline", {singleTransferId: transferId}).then((resp) => {
      return Timeline.fromApi(resp)
    })
  }

  pollTimeline(transferId: string, options: PollOptions<Timeline>): PollResultPromise<Timeline> {
    return poll(() => this.timeline(transferId), options)
  }

  get(transferId: string): Promise<Transfer> {
    return this.api.get<TransferV3Models.GetSingleResponse>('/transfer/v3/single/get', {singleTransferId: transferId}).then((resp) => {
      return Transfer.fromApi(resp.payload!)
    })
  }

  getBlocking(): Promise<Transfer | null> {
    return this.history({limit: 1, filter: "is_blocking"}).then((resp) => {
      return resp.results.length > 0 ? resp.results[0] : null
    })
  }

  pollTransfer(id: string, options: PollOptions<Transfer>): PollResultPromise<Transfer> {
    return poll(() => this.get(id), options)
  }

  history(params: HistoryParams): Promise<ListTransfersResponse> {
    const {accessToken, ...withoutAccessToken} = {...params}
    return this.api.get<TransferV3Models.HistoryResponse>(
      '/transfer/v3/history',
      withoutAccessToken, accessToken && {"Authorization": `Bearer ${accessToken.accessToken}`})
      .then((resp) => {
        return {
          results: resp.payload?.results?.flatMap((t) => {
            return t.singleTransfer ? [Transfer.fromApiSummary(t.singleTransfer)] : []
          }) || [],
          paginationMeta: resp.payload?.paginationMeta || {cursor: undefined, hasMore: false}
        }
      })
  }

  listByRecipient(recipientId: string, limit: number): Promise<ListTransfersResponse> {
    return this.api.get<TransferV3Models.ListSingleTransferResponse>('/transfer/v3/single/by-recipient/list', {
      recipientId,
      limit
    })
      .then((resp) => {
        return {
          results: resp.payload.results.map(t => Transfer.fromApiSummary(t)),
          paginationMeta: resp.payload.paginationMeta
        }
      })
  }


  confirmQuote(transferId: string): Promise<TransferV3Models.SingleConfirmQuoteResponse> {
    return this.api.post<TransferV3Models.SingleConfirmQuoteResponse, undefined>(
      '/transfer/v3/single/confirm-quote',
      undefined,
      {},
      {singleTransferId: transferId}
    )
  }

  markPayInInitiated(transferId: string): Promise<void> {
    return this.api.post<EmptySuccessfulResponse, undefined>(
      '/transfer/v3/single/mark-payin-initiated',
      undefined,
      {},
      {singleTransferId: transferId}
    ).then(() => {
    })
  }

  markPayInWaiting(transferId: string): Promise<void> {
    return this.api.post<EmptySuccessfulResponse, undefined>(
      '/transfer/v3/single/mark-payin-waiting',
      undefined,
      {},
      {singleTransferId: transferId}
    ).then(() => {
    })
  }

  tierList(): Promise<TierList> {
    return this.api.get<TransferCommonModels.ListTierResponse>('/transfer/v3/tier/list').then(
      (resp) => TierList.fromApi(resp)
    )
  }

  pollTierList(options: PollOptions<TierList>): PollResultPromise<TierList> {
    return poll(() => this.tierList(), options)
  }

  cancel(transferId: string): Promise<void> {
    return this.api.post<EmptySuccessfulResponse, undefined>(
      '/transfer/v3/single/cancel',
      undefined,
      {},
      {singleTransferId: transferId}
    ).then(() => {
    })
  }

  refundRequest(transferId: string): Promise<void> {
    return this.api.post<EmptySuccessfulResponse, undefined>(
      '/transfer/v3/single/refund/request',
      undefined,
      {},
      {singleTransferId: transferId}
    ).then(() => {
    })
  }

  refundInfo(transferId: string): Promise<SingleRefundInfo> {
    return this.api.get<TransferV3Models.SingleRefundInfoResponse>(
      "/transfer/v3/single/refund/info",
      {singleTransferId: transferId}
    ).then((resp) => SingleRefundInfo.fromApi(resp.payload)
    )
  }

  portalsDeliveryInfo(destinationCurrencyCode: string) {
    return this.api.get<TransferModelsV3.PortalDeliveryOptionInfoResponse>(
      "/transfer/v3/portals/delivery-option/info",
      {destinationCurrencyCode}
    ).then(resp => PortalDeliveryInfo.fromApi(resp.payload))
  }

  portalsDeliveryOptionChoose(deliveryOptionType: DeliveryOptionKind) {
    return this.api.post<EmptySuccessfulResponse, TransferV3Models.PortalsDeliveryOptionChooseRequest>(
      "/transfer/v3/portals/delivery-option/choose",
      {type: deliveryOptionType},
    )
  }

  // bulkCreate(file: File): Promise<TransferV3Models.BulkTransferResponse> {
  //   const formData = new FormData()
  //   formData.append("file", file)
  //   return this.api.post<TransferModels.BulkTransferResponse, FormData>('/transfer/v1/bulk/create', formData, {
  //     'Content-Type': 'multipart/form-data'
  //   })
  // }
  //
  // bulkStart(bulkId: string): Promise<void> {
  //   return this.api.post<EmptySuccessfulResponse, undefined>(
  //     '/transfer/v1/bulk/start',
  //     undefined,
  //     {},
  //     {bulkId: bulkId}
  //   ).then(() => {
  //   })
  // }
  //
  // bulkGet(bulkId: string, transfersOnly: boolean | undefined = undefined): Promise<TransferModels.BulkTransferResponse> {
  //   let params: {
  //     bulkId: string
  //     transfersOnly?: boolean
  //   } = {bulkId: bulkId}
  //   if (transfersOnly !== undefined) {
  //     params['transfersOnly'] = transfersOnly
  //   }
  //
  //   return this.api.get<TransferModels.BulkTransferResponse>('/transfer/v1/bulk/get', params)
  // }
  //
  // bulkConfirmQuote(bulkId: string): Promise<void> {
  //   return this.api.post<EmptySuccessfulResponse, undefined>(
  //     '/transfer/v1/bulk/confirm-quote',
  //     undefined,
  //     {},
  //     {bulkId: bulkId}
  //   ).then(() => {
  //   })
  // }
  //
  // bulkMarkPayInInitiated(bulkId: string): Promise<void> {
  //   return this.api.post<EmptySuccessfulResponse, undefined>(
  //     '/transfer/v1/bulk/mark-payin-initiated',
  //     undefined,
  //     {},
  //     {bulkId: bulkId}
  //   ).then(() => {
  //   })
  // }
  //
  // bulkMarkPayInWaiting(bulkId: string): Promise<void> {
  //   return this.api.post<EmptySuccessfulResponse, undefined>(
  //     '/transfer/v1/bulk/mark-payin-waiting',
  //     undefined,
  //     {},
  //     {bulkId: bulkId}
  //   ).then(() => {
  //   })
  // }
  //
  // bulkVerificationsList(bulkId: string): Promise<TransferModels.BulkTransferVerificationListResponse> {
  //   return this.api.get<TransferModels.BulkTransferVerificationListResponse>('/transfer/v1/bulk/verifications/list', {bulkId: bulkId})
  // }
}

/** @deprecated move all mutations to useTransfersQuery and don't forget to add invalidations for mutations */
export function useTransfersApi() {
  return new TransfersApi(useApi())
}

const transfersQueryKeys = {
  public: {
    estimateBootstrapInfo: "transfers.public.estimateBootstrapInfo"
  },
  availableCurrencies: "transfers.availableCurrencies",
  timeline: (transferId: string) => ["transfers.timeline", transferId],
  get: (transferId: string) => ["transfers.get", transferId],
  firstBlocking: {
    get: () => ["transfers.firstBlocking.get"]
  },
  create: () => "transfers.create",
  cancel: () => "transfers.cancel",
  refund: {
    request: () => "transfers.refund.request",
    info: (transferId: string) => ["transfers.refund.info", transferId]
  },
  confirmQuote: () => "transfers.confirmQuote",
  markPayInInitiated: () => "transfers.markPayInInitiated",
  markPayInWaiting: () => "transfers.markPayInWaiting",
  list: () => ['transfers.list'],
  listByRecipient: (recipientId: string) => ['transfers.list.byRecipient', recipientId],
  portals: {
    deliveryOption: {
      info: (destinationCurrencyCode: string) => ['transfers.portals.info', destinationCurrencyCode],
      choose: () => ['transfers.portals.choose']
    },
  },
  tier: {
    list: "transfers.tier.list"
  },
  bulk: {
    get: (bulkId: string, transfersOnly: boolean) => ['transfers.bulk.get', bulkId, transfersOnly],
    verifications: (bulkId: string) => ['transfers.bulk.verifications', bulkId]
  }
}

export const useTransfersQuery = () => {
  const transfersApi = useTransfersApi()
  const queryClient = useQueryClient()

  const invalidateByTransferId = (transferId: string | undefined) => {
    let keys = [transfersQueryKeys.list(), transfersQueryKeys.firstBlocking.get()]
    if (transferId) {
      keys.push(transfersQueryKeys.timeline(transferId))
      keys.push(transfersQueryKeys.get(transferId))
    }
    keys.forEach(key => queryClient.invalidateQueries(key))
  }

  return {
    public: {
      estimateBootstrapInfo: {
        useQuery: () => useQuery(transfersQueryKeys.public.estimateBootstrapInfo, () => transfersApi.publicEstimateBootstrapInfo())
      }
    },
    availableCurrencies: {
      useQuery: () => useQuery(transfersQueryKeys.availableCurrencies, () => transfersApi.availableCurrencies(), {
        retry: false,
      }),
      preFetch: () => queryClient.prefetchQuery(transfersQueryKeys.availableCurrencies, () => transfersApi.availableCurrencies()),
    },
    timeline: {
      useQuery: (transferId: string | undefined, options?: {
        interval: number | undefined
        onSuccess?: (data: Timeline) => void
        onError?: (error: any) => void
      }) => useQuery(
        transfersQueryKeys.timeline(transferId || "empty"),
        () => transfersApi.timeline(transferId || "empty"),
        {
          refetchInterval: options?.interval,
          enabled: transferId !== undefined,
          onSuccess: options?.onSuccess,
          onError: options?.onError
        }
      ),
    },
    get: {
      useQuery: (transferId: string, interval?: number) => useQuery(
        transfersQueryKeys.get(transferId),
        () => transfersApi.get(transferId),
        {
          refetchInterval: interval,
        }
      ),
    },
    create: {
      useMutation: () => useMutation(
        transfersQueryKeys.create(),
        (req: TransferV3Models.CreateSingleTransferRequest) => transfersApi.create(req),
        {
          onSuccess: () => invalidateByTransferId(undefined)
        }
      ),
    },
    cancel: {
      useMutation: () => useMutation(
        transfersQueryKeys.cancel(),
        (transferId: string) => transfersApi.cancel(transferId),
        {
          onSuccess: (_data, variables) => invalidateByTransferId(variables)
        }
      )
    },
    refund: {
      request: {
        useMutation: (options: DefaultMutationOptions<any>) => useMutation(
          transfersQueryKeys.refund.request(),
          (transferId: string) => transfersApi.refundRequest(transferId),
          {
            ...options,
            onSuccess: (_data, variables) => {
              invalidateByTransferId(variables)
              return options.onSuccess?.(_data)
            }
          }
        )
      },
    },
    confirmQuote: {
      useMutation: () => useMutation(
        transfersQueryKeys.confirmQuote(),
        (transferId: string) => transfersApi.confirmQuote(transferId),
        {
          onSuccess: (_data, variables) => invalidateByTransferId(variables)
        }
      )
    },
    markPayInInitiated: {
      useMutation: () => useMutation(
        transfersQueryKeys.markPayInInitiated(),
        (transferId: string) => transfersApi.markPayInInitiated(transferId),
        {
          onSuccess: (_data, variables) => invalidateByTransferId(variables)
        }
      )
    },
    markPayInWaiting: {
      useMutation: () => useMutation(
        transfersQueryKeys.markPayInWaiting(),
        (transferId: string) => transfersApi.markPayInWaiting(transferId),
        {
          onSuccess: (_data, variables) => invalidateByTransferId(variables)
        }
      )
    },
    firstBlocking: {
      get: {
        useQuery: (options?: {
          onBeforeRequest?: () => void,
          onAfterRequest?: () => void,
        }) => useQuery(transfersQueryKeys.firstBlocking.get(), () => {
          options?.onBeforeRequest?.()
          return transfersApi.getBlocking().finally(() => options?.onAfterRequest?.())
        })
      }
    },
    list: {
      useInfiniteQuery: (pageSize: number, options?: {
        refetchInterval?: number
        onSuccess?: (d: InfiniteData<ListTransfersResponse>) => void
      }) => useInfiniteQuery(
        transfersQueryKeys.list(),
        ({pageParam}) => {
          return transfersApi.history({limit: pageSize, cursor: pageParam})
        }, {
          onSuccess: options?.onSuccess,
          refetchInterval: options?.refetchInterval,
          getPreviousPageParam: () => undefined,
          getNextPageParam: (lastPage) => {
            return lastPage.paginationMeta.hasMore ? lastPage.paginationMeta.cursor : undefined
          },
        }),
      byRecipient: {
        useQuery: (recipientId: string, options: DefaultQueryOptions<ListTransfersResponse>) => useQuery(
          transfersQueryKeys.listByRecipient(recipientId),
          () => transfersApi.listByRecipient(recipientId, 0),
          options
        )
      }
    },
    portals: {
      deliveryOption: {
        info: {
          useQuery: (destinationCurrencyCode: string, options: DefaultQueryOptions<any>) => useQuery(
            transfersQueryKeys.portals.deliveryOption.info(destinationCurrencyCode),
            () => transfersApi.portalsDeliveryInfo(destinationCurrencyCode),
            options,
          )
        },
        choose: {
          useMutation: (options: DefaultMutationOptions<any>) => useMutation(
            transfersQueryKeys.portals.deliveryOption.choose(),
            (deliveryOptionType: DeliveryOptionKind) => transfersApi.portalsDeliveryOptionChoose(deliveryOptionType),
            options,
          )
        }
      }
    },
    tier: {
      list: {
        useQuery: (options?: {
          refetchInterval?: number
        }) => useQuery(transfersQueryKeys.tier.list, () => transfersApi.tierList(), options),
      },
    },
    bulk: {
      // get: {
      //   useQuery: (bulkId: string, transfersOnly: boolean, interval?: number) => useQuery(
      //     transfersQueryKeys.bulk.get(bulkId, transfersOnly),
      //     () => transfersApi.bulkGet(bulkId, transfersOnly),
      //     {
      //       refetchInterval: interval,
      //     }
      //   ),
      // },
      // verifications: {
      //   useQuery: (bulkId: string, interval?: number) => useQuery(
      //     transfersQueryKeys.bulk.verifications(bulkId),
      //     () => transfersApi.bulkVerificationsList(bulkId),
      //     {
      //       refetchInterval: interval,
      //     }
      //   ),
      // }
    }
  }
}