import {RefObject, useCallback, useEffect, useMemo, useState} from "react";
import {useAlertStack} from "../providers/alert-stack";
import {useStrings} from "../strings";

export const useAsyncLoading = (initialState: boolean) => {
    const [loadingCnt, setLoadingCnt] = useState(initialState ? 1 : 0)

    const asyncWithLoading = useCallback(<T>(fn: () => Promise<T>): Promise<T> => {
        setLoadingCnt((prev) => prev + 1)
        const res = fn()
        res.finally(() => {
            setLoadingCnt((prev) => prev - 1)
        })
        return res
    }, [])

    return useMemo(() => ({
        isLoading: loadingCnt > 0,
        asyncWithLoading: asyncWithLoading,
    }), [asyncWithLoading, loadingCnt])
}

// Taken from https://stackoverflow.com/questions/40064249/react-animate-mount-and-unmount-of-a-single-component#54114180
export function useDelayUnmount(isMounted: boolean, delayTime: number) {
    const [shouldRender, setShouldRender] = useState(isMounted);
    const [shouldShow, setShouldShow] = useState(isMounted);

    useEffect(() => {
        let timeoutId: ReturnType<typeof setTimeout>;
        if (isMounted && shouldRender && !shouldShow) {
            setShouldShow(true)
        }
        if (isMounted && !shouldRender) {
            setShouldRender(true);
        } else if (!isMounted && shouldRender) {
            setShouldShow(false)
            timeoutId = setTimeout(
                () => {
                    setShouldRender(false)
                },
                delayTime + 50 // + 50 ms to be sure animation is finished
            );
        }
        return () => clearTimeout(timeoutId);
    }, [isMounted, delayTime, shouldRender, shouldShow]);
    return {shouldRender, shouldShow};
}

export const useIntersectionObserver = (
    cb: (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => void,
    options?: { root: RefObject<HTMLElement> }) => {

    const observer = useMemo(() => new IntersectionObserver(
        (entries, observer) => {
            cb(entries, observer)
        }, {root: options?.root.current}
    ), [cb, options?.root])


    useEffect(() => {
        return () => observer.disconnect()
    }, [observer])

    return {
        observe: useCallback((el: Element) => {
            observer.observe(el)
            return () => observer.unobserve(el)
        }, [observer])
    }
}

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

// https://stackoverflow.com/questions/36862334/get-viewport-window-height-in-reactjs
export const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

type CopiedValue = string | null
type CopyFn = (text: string) => Promise<boolean> // Return success

export function useCopyToClipboard(): [CopiedValue, CopyFn] {
    const strings = useStrings()
    const alertStack = useAlertStack()
    const [copiedText, setCopiedText] = useState<CopiedValue>(null)

    const copy: CopyFn = async text => {
        if (!navigator?.clipboard) {
            console.warn('Clipboard not supported')
            alertStack.showErrorMessage("No clipboard support")
            return false
        }

        // Try to save to clipboard then save it in the state if worked
        try {
            await navigator.clipboard.writeText(text)
            alertStack.showNotification(strings["general.copied"], "")
            setCopiedText(text)
            return true
        } catch (error) {
            console.warn('Copy failed', error)
            alertStack.showError(error)
            setCopiedText(null)
            return false
        }
    }

    return [copiedText, copy]
}