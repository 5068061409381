import Limiter from "../../components/ui/Limiter";
import InformationScreen from "../../components/ui/screen/InformationScreen";
import {useStrings} from "../../strings";
import {Button} from "../../components/ui/Buttons";
import {img} from "../../helpers/public";

const GenericErrorPage = () => {
  const strings = useStrings()
  return (
    <Limiter>
      <InformationScreen
        title={strings["error.generic_screen.title"]}
        subtitle={strings["error.generic_screen.subtitle"]}
        media={<img src={img("image-warning.png")} alt={strings["error.generic_screen.title"]}/>}
        primaryButton={(innerProps) =>
          <Button
            {...innerProps}
            type={"button"}
            title={strings["generic.try_again"]}
            onClick={() => {
              window.location.reload()
            }}
          />
      }
      />
    </Limiter>
  )
}

export default GenericErrorPage;