import {RelativeColloquialType, RelativeColloquialTypeClass} from "../../../helpers/relative-colloquial";
import {useStrings} from "../../../strings";
import FormattedDate from "./FormattedDate";

const FormattedDueDate = ({value}: { value: Date }) => {
  const strings = useStrings()
  switch (RelativeColloquialTypeClass.from(value)) {
    case RelativeColloquialType.TODAY:
      return strings["date_format.due_date.today"](<FormattedDate style={"jmm"} value={value}/>)
    case RelativeColloquialType.TOMORROW:
      return strings["date_format.due_date.tomorrow"](<FormattedDate style={"jmm"} value={value}/>)
    default:
      return strings["date_format.due_date.later"](
        <FormattedDate style={"jmm"} value={value}/>,
        <FormattedDate style={"EEEE"} value={value}/>
      )
  }
};

export default FormattedDueDate;