import Decimal from "decimal.js";
import React, {useCallback, useEffect, useId, useMemo, useRef, useState} from "react";
import FormattedQuoteRate from "../../../components/formatters/FormattedQuoteRate";
import {Button, InlineButton} from "../../../components/ui/Buttons";
import {Currency, Money, MoneyInputValue} from "../../../helpers/money";
import {useAlertStack} from "../../../providers/alert-stack";
import EstimateAmountInput from "./EstimateAmountInput";
import EstimateCurrencyInput from "./EstimateCurrencyInput";
import {Estimate} from "../../../models/transfers/Estimate";
import {useStrings} from "../../../strings";
import ChevronDown from "../../../components/ui/icons/ChevronDown";
import {Body, BodyTitle, Heading1} from "../../../components/ui/Labels";
import EstimateDeliveryOptionList from "./EstimateDeliveryOptionList";
import {
  autoUpdate,
  flip,
  offset,
  size,
  useDismiss,
  useFloating,
  useInteractions,
  useTransitionStyles
} from "@floating-ui/react";
import ButtonBar from "../../../components/ui/ButtonBar";
import Caption from "../../../components/ui/Caption";
import {PropsClassName, PropsOnComplete} from "../../../helpers/props";
import {WithSkeleton} from "../../../components/ui/Skeleton";
import {EntryMode} from "../../../api/whales/TransferCommon";
import cx from "classnames";
import {BasicForm} from "../../../components/ui/input/Inputs";
import {usePromiseValue} from "../../../hooks/promise";
import {withMeasure} from "../../../components/ui/MeasureLayer";
import Tooltip, {TooltipView} from "../../../components/ui/Tooltip";
import {useIntl} from "react-intl";
import {TransferContext, useTransferContextOps,} from "../../../models/transfers/TransferContext";
import {AlertView} from "../../../components/ui/Errors";
import {LearnMoreButton, WithLearnMoreSuffix} from "../../../components/ui/LearnMore";
import CancelablePromise, {cancelable} from "cancelable-promise";
import TooltipViewFeeBreakdown from "../../../components/transfers/TooltipViewFeeBreakdown";
import {DiscountedTotalFee} from "../../../components/domain/transfers/DiscountedFee";
import {TransferDisplaying} from "../../../models/transfers/TransferDisplaying";
import {Links} from "../../../helpers/Links";
import {useAsyncLoading} from "../../../helpers/hooks";
import {useUserDefaultsValue} from "../../../atoms/user-defaults";
import {useEstimateInputState} from "../../../atoms/estimate-input";
import {
  FormattedDeliveryOption,
  FormattedDeliveryOptionSimple
} from "../../../components/domain/transfers/FormattedDeliveryOption";
import {useResizeObserver} from "usehooks-ts";
import Icon from "../../../components/ui/icons/Icon";

const otherInputMode = (input: "source" | "destination"): "source" | "destination" => {
  return input === "source" ? "destination" : "source"
}

const toMoney = (value: MoneyInputValue) => Money.make(value.amount === "empty" ? 0 : value.amount, value.currency)

export type EstimateInputState = {
  entryMode: EntryMode
  enteredMoney: MoneyInputValue
  calculatedCurrency: Currency
}

type Props = {
  onChangeCurrency: (input: EntryMode, currency: Currency) => void
  onChangeEntryMode: (entryMode: EntryMode) => void
} & PropsOnComplete<TransferContext>

const EstimatePage = withMeasure((props: Props, measure) => {
  const strings = useStrings()
  const intl = useIntl()
  const transferContextOps = useTransferContextOps()
  const alertStack = useAlertStack()

  const [transferContext, setTransferContext] = useState<TransferContext>()
  const [state, setState] = useEstimateInputState()
  const [isDeliveryOptionsVisible, setIsDeliveryOptionsVisible] = useState(false)
  const userDefaults = useUserDefaultsValue()
  const estimateRef = useRef<HTMLDivElement>(null)
  const estimateMeasure = useResizeObserver({ref: estimateRef,})

  const displaying = useMemo(() => transferContext?.displaying(userDefaults), [transferContext, userDefaults])
  const transferEstimate = useMemo(() => transferContext?.transferEstimate(userDefaults), [transferContext, userDefaults])

  const updateLoading = useAsyncLoading(false)
  const submitLoading = useAsyncLoading(false)
  const updateOperationRef = useRef<CancelablePromise<TransferContext>>()
  const runOperation = useCallback((operation: () => Promise<TransferContext>): Promise<TransferContext> => {
    updateOperationRef.current?.cancel()
    const result = cancelable(
      updateLoading.asyncWithLoading(() => operation())
    ).then((updated) => {
      setTransferContext(_ => updated)
      return updated
    })
    updateOperationRef.current = result
    return result
  }, [updateLoading, setTransferContext])

  const update = useCallback(async (inputState: EstimateInputState) => {
    return runOperation(() => {
      const params = {
        entryMode: inputState.entryMode,
        enteredMoney: toMoney(inputState.enteredMoney),
        calculatedCurrency: inputState.calculatedCurrency,
      }

      if (transferContext === undefined) return transferContextOps.make(params)

      return transferContextOps.update(transferContext, params)
    })
  }, [runOperation, transferContext, transferContextOps])


  useEffect(() => {
    if (transferContext !== undefined || state === undefined || updateOperationRef.current !== undefined) return
    void update(state)
  }, [state, transferContext, update])

  const {refs, floatingStyles, context} = useFloating({
    placement: "bottom",
    open: isDeliveryOptionsVisible,
    onOpenChange: setIsDeliveryOptionsVisible,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset({mainAxis: 8}),
      flip({padding: 0}),
      {
        ...size({
          apply({rects: _rects, elements}) {
            Object.assign(elements.floating.style, {
              width: `${(estimateMeasure.width ?? 0) * 0.85}px`
            });
          },
        }),
        options: [estimateMeasure.width]
      },
    ]
  });

  const dismiss = useDismiss(context);
  const {isMounted, styles: transitionStyles} = useTransitionStyles(context, {
    duration: 200,
    close: {
      opacity: 0,
    },
    open: {
      opacity: 1,
    }
  });
  const {getReferenceProps, getFloatingProps} = useInteractions([
    dismiss,
  ]);

  const toggleDeliveryOptions = useCallback(() => {
    setIsDeliveryOptionsVisible((prev) => !prev);
  }, [])

  const onEstimateSelect = (estimate: Estimate) => {
    setIsDeliveryOptionsVisible(false);

    setTransferContext(prev =>
      prev?.with({
        selectedDeliveryOption: estimate.deliveryOption
      })
    )
  }

  const deliveryEstimateText = function (displaying: TransferDisplaying): {
    text: React.ReactNode,
    choiceAvailable: boolean
  } {
    if (!transferContext?.isDeliveryChoiceAvailable) {
      const text = (
        <FormattedDeliveryOptionSimple
          deliveryOption={displaying.deliveryOption}
          past={undefined}
          instant={strings["transfer.amount_entry.generic.instant"]}
          today={strings["transfer.amount_entry.generic.today"]}
          tomorrow={strings["transfer.amount_entry.generic.tomorrow"]}
          other={strings["transfer.amount_entry.generic.future_date"]}
        />
      )
      return {text, choiceAvailable: false}
    }

    const text = (
      <FormattedDeliveryOption
        deliveryOption={displaying.deliveryOption}
        standardPast={undefined}
        standardToday={strings["transfer.amount_entry.standard.today"]}
        standardTomorrow={strings["transfer.amount_entry.standard.tomorrow"]}
        standardOther={strings["transfer.amount_entry.standard.future_date"]}
        expressPast={undefined}
        expressInstant={strings["transfer.amount_entry.express.instant"]}
        expressToday={strings["transfer.amount_entry.express.today"]}
        expressTomorrow={strings["transfer.amount_entry.express.tomorrow"]}
        expressOther={strings["transfer.amount_entry.express.future_date"]}
      />
    )

    return {text, choiceAvailable: true}
  }

  const currencyLabelWidth = usePromiseValue(useCallback(async () => {
    const allCurrencies = transferContext?.currencyAvailability?.allCurrencies() || []

    const promises = allCurrencies
      .map((currency) => measure((ref) => <Heading1 ref={ref} mobileStyles={false} className={"block"}
                                                    text={currency.code}/>, currency.code))

    const sizes = await Promise.all(promises)
    const widths = sizes.map(size => size.width)
    const max = Math.max(...widths)
    return max >= 0 ? max : undefined
  }, [measure, transferContext?.currencyAvailability]))


  const promoTooltip = function () {
    return <TooltipView
      title={strings["transfer_promo.explanation.title"]}
      subtitle={transferEstimate?.promo?.fixedFeeExplanation(strings)}
    />
  }

  const feeTooltip = function (displaying: TransferDisplaying) {
    if (displaying.isFixedFeeDiscounted) {
      return promoTooltip()
    }
    return <TooltipViewFeeBreakdown displaying={displaying}/>
  }


  const onChangeAmount = (newEntryMode: EntryMode) => (newAmount: MoneyInputValue["amount"]) => {
    setState((prev) => {
      if (!prev) return prev

      const newInputState = {
        ...prev,
        entryMode: newEntryMode,
        enteredMoney: {
          amount: newAmount,
          currency: prev.enteredMoney.currency
        },
      }

      void update(newInputState)

      return newInputState
    })
  }

  const onChangeCurrency = (inputEntryMode: EntryMode) => (newCurrency: Currency) => {
    props.onChangeCurrency(inputEntryMode, newCurrency)
    setState((prev) => {
      if (!prev) return prev

      const newInputState = {
        ...prev,
        enteredMoney: {
          ...prev.enteredMoney,
          currency: inputEntryMode === prev.entryMode ? newCurrency : prev.enteredMoney.currency
        },
        calculatedCurrency: inputEntryMode === prev.entryMode ? prev.calculatedCurrency : newCurrency,
      }

      void update(newInputState)

      return newInputState
    })
  }


  const onFocus = (newEntryMode: EntryMode) => () => {
    if (newEntryMode === state?.entryMode) return

    props.onChangeEntryMode(newEntryMode)

    setState((prev) => {
      if (!prev || !displaying || prev.entryMode === newEntryMode) return prev

      const money = displaying[`${newEntryMode}Money`]

      const newInputState: EstimateInputState = {
        ...prev,
        entryMode: newEntryMode,
        enteredMoney: money.amount.eq(0) ? {amount: "empty", currency: money.currency} : money,
        calculatedCurrency: prev.enteredMoney.currency,
      }

      void update(newInputState)

      return newInputState
    })
  }

  const onContinue = () => submitLoading.asyncWithLoading(async () => {
    if (!transferContext || !state) return

    const updatedTransferContext = await update(state)

    return updatedTransferContext && props.onComplete(updatedTransferContext)
  }).catch((e) => alertStack.showError(e))

  return (
    <BasicForm className={"flex-1 flex flex-col isolate w-full"} onSubmit={onContinue}>
      <div ref={estimateRef} className={"flex-1 flex flex-col items-start justify-center"}>
        <div className={"w-full relative"}>
          <Icon name={"estimate-triangle"} className={cx("absolute left-[-20px] z-20 default-anim transition-[top,opacity]", {
            "top-[25px]": state?.entryMode === "source",
            "top-[calc(100%-40px-12px)]": state?.entryMode === "destination",
            "opacity-0": transferContext === undefined
          })}/>
          <EstimateRow
            inputEntryMode={"source"}
            label={undefined}
            transferContext={transferContext}
            inputState={state}
            onChangeAmount={onChangeAmount("source")}
            onChangeCurrency={onChangeCurrency("source")}
            onFocusInput={onFocus("source")}
            currencyLabelWidth={currencyLabelWidth}
          />

          <div className="mt-[32px] flex flex-col items-start gap-0">
            <WithSkeleton value={displaying} fallback={TransferDisplaying.fallback}>
              {(transferDisplaying) => (<>
                <BodyTitle>
                  {"- "}
                  <DiscountedTotalFee displaying={transferDisplaying}/>
                  {" "}
                </BodyTitle>
                <Body>
                  {transferDisplaying.isFixedFeeOnly() ? strings["transfer.fixed_fee"] : strings["transfer.fee"]}
                  {" "}
                  <Tooltip children={displaying && feeTooltip(displaying)}/>
                </Body>
              </>)
              }
            </WithSkeleton>

            <WithSkeleton className={"mt-[4px]"} value={displaying?.quote.rate}
                          fallback={new Decimal(1.111111111111)}>
              {(rate) => (<>
                <BodyTitle>
                  {"× "}
                  <FormattedQuoteRate value={rate}/>
                </BodyTitle>
                <Body>
                  {" "}
                  {strings["transfer.live_rate"]}
                  {displaying?.destinationMoney.currency.eq(Currency.inr) && (
                    <>
                      {" "}
                      <Tooltip
                        children={
                          <TooltipView
                            title={strings["currency_exchange_rate"](displaying?.destinationMoney.currency.name(intl))}
                            subtitle={
                              <WithLearnMoreSuffix url={Links.helpCenter.articles.inr}>
                                {strings["inr.exchange_rate_alert.subtitle.3"]}
                              </WithLearnMoreSuffix>
                            }/>
                        }
                      />
                    </>
                  )}
                </Body>
              </>)}
            </WithSkeleton>
          </div>

          <EstimateRow
            className={"mt-[32px]"}
            inputEntryMode={"destination"}
            label={undefined}
            transferContext={transferContext}
            inputState={state}
            onChangeAmount={onChangeAmount("destination")}
            onChangeCurrency={onChangeCurrency("destination")}
            onFocusInput={onFocus("destination")}
            currencyLabelWidth={currencyLabelWidth}
          />
        </div>

        <div className="mt-6 self-center" ref={refs.setReference} {...getReferenceProps()}>
          <WithSkeleton value={displaying}
                        fallback={TransferDisplaying.fallback}>{(displaying) => <>
            {function () {
              const {text, choiceAvailable} = deliveryEstimateText(displaying)
              return <InlineButton type={"button"}
                                   disabled={!choiceAvailable}
                                   indications={choiceAvailable}
                                   onClick={toggleDeliveryOptions}
                                   className={"text-2xl w-full flex justify-center items-center gap-[4px]"}>
                {text}
                <ChevronDown hidden={!choiceAvailable} invertedY={isDeliveryOptionsVisible}/>
              </InlineButton>
            }()}
          </>}</WithSkeleton>
          {isMounted && (
            <div className={"bg-secondary z-20 w-full"} ref={refs.setFloating}
                 style={{...floatingStyles, ...transitionStyles}} {...getFloatingProps()}>

              <EstimateDeliveryOptionList transferContext={transferContext} onEstimateSelect={onEstimateSelect}/>
            </div>
          )}
        </div>
      </div>
      <div className={"z-10"}>
        <ButtonBar align={"center"} sticky={"bottom"}>
          <Button
            type={"submit"}
            title={strings["general.continue"]}
            size="big"
            loading={submitLoading.isLoading}
            disabled={updateLoading.isLoading}
            color="primary-black"
          />
        </ButtonBar>
      </div>
    </BasicForm>
  )
})

type EstimateRowProps = {
  inputEntryMode: "source" | "destination"
  label: React.ReactNode | undefined
  transferContext?: TransferContext
  inputState?: EstimateInputState
  onChangeAmount: (amount: MoneyInputValue["amount"]) => void
  onChangeCurrency: (currency: Currency) => void
  onFocusInput?: () => void
  currencyLabelWidth?: number
} & PropsClassName

const EstimateRow = ({inputState, transferContext, ...props}: EstimateRowProps) => {
  const id = useId()
  const alertStack = useAlertStack()
  const strings = useStrings()
  const inputRef = useRef<HTMLInputElement>(null)
  const userDefaults = useUserDefaultsValue()
  const displaying = transferContext?.displaying(userDefaults)

  const entryModeAvailableCurrencies = transferContext?.currencyAvailability[`${props.inputEntryMode}EntryCurrencies`]
  const availableCurrencies = transferContext?.currencyAvailability[`${props.inputEntryMode}Currencies`]

  const [amountValue, currencyValue] = function (): [MoneyInputValue | undefined, Currency | undefined] {
    if (inputState?.entryMode === props.inputEntryMode) return [inputState.enteredMoney, inputState.enteredMoney.currency]

    if (transferContext && inputState) {
      return [displaying && displaying[`${props.inputEntryMode}Money`], inputState.calculatedCurrency]
    }

    return [undefined, undefined]
  }()
  const checkInputDisabled = () => {
    const inputDisabled = currencyValue && entryModeAvailableCurrencies?.findIndex(c => c.eq(currencyValue)) === -1

    if (inputDisabled) {
      inputRef.current?.blur()
      const other = otherInputMode(props.inputEntryMode)
      alertStack.showPopUp((ctx) =>
        <AlertView
          title={strings[`transfer.entry_mode_availability.${other}_only.title`]}
          content={strings[`transfer.entry_mode_availability.${other}_only.body`]}
          primaryButton={(props) =>
            <LearnMoreButton href={Links.helpCenter.articles.inr} {...props}/>
          }
          {...ctx}
        />
      )
    }

    return inputDisabled
  }

  const onChangeAmount = (newAmount: MoneyInputValue["amount"]) => {
    if (checkInputDisabled()) return
    props.onChangeAmount(newAmount)
  }

  const onFocus = () => {
    if (checkInputDisabled()) return
    props.onFocusInput?.()
  }


  return (
    <div className={cx("w-full", props.className)}>
      {props.label && (
        <label className={"mb-3"} htmlFor={id}>
          <Caption size={"3-medium"} color={"primary-50"} text={props.label}/>
        </label>
      )
      }
      <div className="flex flex-row gap-4">
        <div className={"flex-grow"}>
          <EstimateAmountInput
            id={id}
            isLoading={transferContext === undefined}
            innerRef={inputRef}
            className={"w-full"}
            autoFocus={transferContext?.entryMode === props.inputEntryMode}
            value={amountValue}
            onChange={onChangeAmount}
            onFocus={onFocus}
          />
        </div>

        <div className={"flex-grow-0 flex-shrink-0"}>
          <WithSkeleton
            adjust={{left: 2, right: 2, bottom: 2.5}}
            value={availableCurrencies && currencyValue && {
              selected: currencyValue,
              all: availableCurrencies
            }}
            // NOK is the longest string of all currency codes we currently support
            fallback={{selected: Currency.nok, all: []}}>
            {data => (
              <EstimateCurrencyInput
                value={data.selected}
                styles={{currencyLabel: {width: props.currencyLabelWidth && `${props.currencyLabelWidth}px`}}}
                onChange={props.onChangeCurrency}
                currencies={data.all}/>)
            }
          </WithSkeleton>
        </div>
      </div>
    </div>
  )
}

export default EstimatePage;