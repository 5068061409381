import {NavStackPush, useNavStack} from "../../../atoms/nav-stack";
import {Verification} from "../../domain/verifications/domain";
import {PropsOnComplete} from "../../../helpers/props";
import SelfieIdQrCodeStep from "./SelfieIdQrCodeStep";
import {PropsOf} from "@emotion/react";
import SelfieIdIntroStep from "./SelfieIdIntroStep";
import {useVerificationApi} from "../../../api/verification";
import {useAlertStack} from "../../../providers/alert-stack";
import {useStrings} from "../../../strings";
import {AlertView} from "../../ui/Errors";

export const useSelfieIdController = () => {
  const strings = useStrings()
  const verificationApi = useVerificationApi()
  const navStack = useNavStack()
  const alertStack = useAlertStack()

  const routeIntro = async (opts: {
    titleAlign: "center" | "left",
    verification: Verification,
    pushFirst?: NavStackPush<{}, {}>
  } & PropsOnComplete) => {
    const push = opts.pushFirst || navStack.push
    push<PropsOf<typeof SelfieIdIntroStep>, {}>(
      SelfieIdIntroStep, {
        titleAlign: opts.titleAlign,
        verification: opts.verification,
        onComplete: opts.onComplete,
        onContinue: () => routeFirst({verification: opts.verification, onComplete: opts.onComplete})
      })
  }

  const routeFirst = async (opts: {
    verification: Verification,
  } & PropsOnComplete) => {
    navStack.push<PropsOf<typeof SelfieIdQrCodeStep>, {}>(
      SelfieIdQrCodeStep, {
        titleAlign: "left",
        verification: opts.verification,
        onComplete: async () => {
          await verificationApi.pollVerification(opts.verification, {
            maxDurationMs: 5 * 1000,
            intervalMs: 1000,
            pollCondition: (v) => !v.isUserInputRequired()
          }).pollThen({
            onSatisfied: async () => {
              await opts.onComplete()
            },
            onExpire: async () => {
              alertStack.showPopUp((ctx) => (
                <AlertView
                  {...ctx}
                  title={strings["selfie_id.verification_polling_expired.title"]}
                  content={strings["selfie_id.verification_polling_expired.subtitle"]}
                  primaryButton={undefined}
                />
              ))
            }
          })
        }
      })
  }


  return {routeIntro}
}
