import ReactSkeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import "./Skeleton.scss"
import cx from "classnames";
import React, {PropsWithChildren} from "react";
import {useDelayUnmount} from "../../helpers/hooks";
import {defaultAnimDurationMillis} from "../../variables";
import {PropsClassName} from "../../helpers/props";

export type SkeletonAdjust = {
  top: number
  right: number
  bottom: number
  left: number
}

type Props = {
  className?: string
  circle?: boolean
  height?: string,
  width?: string
  adjust?: Partial<SkeletonAdjust>
  borderRadius?: string
  hidden?: boolean
}

const Skeleton = (props: Props) => {
  const adjust: SkeletonAdjust = {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    ...props.adjust
  }
  return <ReactSkeleton
    containerClassName={cx("skeleton", {
      "skeleton--hidden": props.hidden
    }, props.className)}
    className={"skeleton__inner"}
    baseColor={"var(--clr-primary-5)"}
    highlightColor={"var(--clr-primary-10)"}
    circle={props.circle ?? false}
    style={{
      position: "absolute",
      borderRadius: props.borderRadius,
      top: `${-adjust.top}px`,
      left: `${-adjust.left}px`,
      width: `calc(100% + ${adjust.left}px + ${adjust.right}px)`,
      height: `calc(100% + ${adjust.top}px + ${adjust.bottom}px)`,
    }}
  />
};

type WithSkeletonProps<T> = {
  value: T | undefined
  fallback: T
  children: (value: T) => React.ReactNode | undefined
  adjust?: Partial<SkeletonAdjust>
  wrap?: boolean
  circle?: boolean
} & PropsClassName
export const WithSkeleton = <T, >(props: WithSkeletonProps<T>) => {
  return (
    <WithSkeletonFlag
      {...props}
      isLoading={props.value === undefined}
      children={props.children(props.value ?? props.fallback)}
    />
  )

}

type WithSkeletonFlagProps = {
  isLoading: boolean
  adjust?: Partial<SkeletonAdjust>
  wrap?: boolean
  circle?: boolean
  minWidth?: string
  width?: string
  height?: string
} & PropsClassName & PropsWithChildren
export const WithSkeletonFlag = ({isLoading, ...props}: WithSkeletonFlagProps) => {
  const wrap = props.wrap ?? true
  const skeletonMount = useDelayUnmount(isLoading, defaultAnimDurationMillis)

  const inner = (
    <>
      {props.children || "Loading"}
      {skeletonMount.shouldRender &&
          <Skeleton circle={props.circle}
                    adjust={props.adjust}
                    width={props.width}
                    height={props.height}
                    hidden={!skeletonMount.shouldShow}
          />}
    </>
  )

  if (wrap) {
    return (
      <div className={cx("relative", props.className, {
        [`min-w-[${props.minWidth}]`]: props.minWidth && isLoading,
      })}>{inner}</div>
    )
  }

  return inner
}

export default Skeleton;