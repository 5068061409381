import {useEffect, useRef} from "react";
import {useAuthValue} from "../atoms/auth";
import {useAlertStack} from "../providers/alert-stack";
import {useBusinessOnboardingQuery, useOnboardingQuery} from "../api/onboarding";
import {useTransfersQuery} from "../api/transfers";
import {useLocation, useNavigate} from "./router-dom-wrapper/hooks";
import {Links} from "../helpers/Links";
import {useLoadingBlockerState} from "../atoms/loading-blocker";
import {useConfig} from "../hooks/config";

export const useLoadingBlockerRemover = () => {
  const {cnt, dec} = useLoadingBlockerState()

  useEffect(() => {
    const timeout = setTimeout(() => {
      dec()
    }, 50)

    return () => clearTimeout(timeout)
  }, [dec]);

  useEffect(() => {
    if (cnt > 0) return
    document.querySelectorAll('.loading-blocker-overlay').forEach((el) => {
      setTimeout(() => {
        el.classList.add('loading-blocker-overlay--hidden')
        setTimeout(() => el.remove(), 250)
      }, 50)
    })
  }, [cnt]);
}

export type SessionRouterData = {
  isLoading?: boolean
  userType?: "individual" | "business"
  isOnboarded?: boolean
  error: any | undefined
}

export const useSessionRouter = (): SessionRouterData => {
  const authValue = useAuthValue()
  const errorStack = useAlertStack()
  const loadingBlockerState = useLoadingBlockerState()
  const navigate = useNavigate()
  const location = useLocation()
  const config = useConfig()

  const businessOnboardingQuery = useBusinessOnboardingQuery()
  const transferQuery = useTransfersQuery()
  const onboardingQuery = useOnboardingQuery()

  const {
    data: individual,
    ...individualQuery
  } = onboardingQuery.application.get.useQuery(authValue.authenticated ? authValue.user.userId : "", {
    enabled: authValue.authenticated && authValue.user.userType === "individual",
    onBeforeRequest: () => loadingBlockerState.inc(),
    onAfterRequest: () => loadingBlockerState.dec(),
    onError: errorStack.showError
  })

  const {data: business, ...businessQuery} =
    businessOnboardingQuery.application.get.useQuery(
      authValue.authenticated ? authValue.user.userId : "", {
        enabled: authValue.authenticated && authValue.user.userType === "business",
        onBeforeRequest: () => loadingBlockerState.inc(),
        onAfterRequest: () => loadingBlockerState.dec(),
        onError: errorStack.showError,
      })

  const {data: blockingTransfer} = transferQuery.firstBlocking.get.useQuery({
    onBeforeRequest: () => loadingBlockerState.inc(),
    onAfterRequest: () => loadingBlockerState.dec(),
  })

  const userType = authValue.authenticated ? authValue.user.userType : undefined

  const isOnboarded = function () {
    switch (userType) {
      case undefined:
        return undefined
      case "individual":
        if (individual === undefined) {
          return undefined
        }

        return individual.payload.status === "accepted"
      case "business":
        if (business === undefined) {
          return undefined
        }

        return business.payload.info.status === "active"
    }
  }()

  const openedTransferRef = useRef(false)
  const blockingTransferId = blockingTransfer?.transferId
  const forwardToLink = blockingTransferId !== undefined
    ? Links.atlantic.transfer.timeline(blockingTransferId)
    : undefined

  useEffect(() => {
    if (location.pathname === forwardToLink) {
      openedTransferRef.current = true
    }
  }, [forwardToLink, location.pathname]);

  useEffect(() => {
      // console.log("useEffect", {
      //   blockingTransferId: blockingTransferId,
      //   isOnboarded,
      //   openedTransfer: openedTransferRef.current
      // })
      if (config.enableFullWebAppExperience && forwardToLink && isOnboarded && !openedTransferRef.current) {
        navigate(forwardToLink)
        openedTransferRef.current = true
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [blockingTransferId, isOnboarded]
  );

  return {
    isOnboarded,
    userType,
    isLoading: individualQuery.isLoading || businessQuery.isLoading,
    error: individualQuery.error || businessQuery.error
  }
}
