import {atom, useRecoilState} from "recoil";
import {EstimateInputState} from "../pages/transfer/estimate/EstimatePage";

type State = EstimateInputState | undefined

const estimateInputAtom = atom<State>({
  key: "estimateInput",
  default: undefined,
})


export const useEstimateInputState = () => {
  return useRecoilState(estimateInputAtom)
}