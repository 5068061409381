import {Section} from "../../../components/ui/collections/Section";
import {PropsOnComplete} from "../../../helpers/props";
import {RecipientCreateContext} from "./recipient-create-controller";
import {useStrings} from "../../../strings";
import {TitleSubtitleLeftScreen} from "../../../components/ui/screen/TitleSubtitleScreen";
import {Button} from "../../../components/ui/Buttons";
import ButtonBar from "../../../components/ui/ButtonBar";
import {BankAccountSectionRows} from "../../../components/domain/common/BankAccount";
import {useAlertStack} from "../../../providers/alert-stack";

type Props = {
  context: RecipientCreateContext
} & PropsOnComplete;

export const RecipientCreateReviewStep = ({context, ...props}: Props) => {
  const strings = useStrings()
  const alertStack = useAlertStack()

  return (
    <TitleSubtitleLeftScreen
      title={strings["add_recipient.review.title"]}
      subtitle={strings["add_recipient.review.subtitle"]}
    >
      <Section>
        <BankAccountSectionRows bankAccount={context.bankAccount()} includeCurrency={false}/>
      </Section>

      <ButtonBar sticky={"bottom"}>
        <Button
          type={"button"}
          title={strings["add_recipient.review.button_title"]}
          size={"big"}
          color={"primary-black"}
          onClickAsync={() => props.onComplete().catch(e => alertStack.showError(e))}
        />
      </ButtonBar>
    </TitleSubtitleLeftScreen>
  );
}