import React, {PropsWithChildren, useCallback} from "react";
import {Heading2} from "../Labels";
import Caption from "../Caption";
import Limiter from "../Limiter";
import cx from "classnames";
import {PropsAs, PropsButtonStack, PropsClassName} from "../../../helpers/props";
import ButtonBar from "../ButtonBar";

export const TitleSubtitle = (props: {
  textAlign: "left" | "center"
  title: React.ReactNode
  subtitle?: React.ReactNode
  avatar?: React.ReactNode
} & PropsClassName) => {
  return (
    <div className={cx(props.className, {
      "text-left": props.textAlign === "left",
      "text-center": props.textAlign === "center",
    })}>
      <div className={"flex flex-row items-center"}>
        <Heading2 className={"flex-grow"} text={props.title}/>
        {props.avatar && (
          <div className={"flex-grow-0 flex-shrink-0 ml-[16px]"}>
            {props.avatar}
          </div>
        )}
      </div>
      {props.subtitle && <Caption className={"block mt-4"} size={"2"} text={props.subtitle}/>}
    </div>
  )
}

export const TitleSubtitleCenter = (props: {
  className?: string
  title: React.ReactNode
  subtitle?: React.ReactNode
}) => {
  return (
    <TitleSubtitle
      className={props.className}
      textAlign={"center"}
      title={props.title}
      subtitle={props.subtitle}
    />
  )
}
export const TitleSubtitleCenterScreen = (props: {
  title: React.ReactNode
  subtitle?: React.ReactNode
} & React.PropsWithChildren & PropsClassName) => {
  return (
    <Limiter className={cx("pb-[40px] mx-auto", props.className)} contentMaxWidth={"580px"}>
      <TitleSubtitleCenter className={"mb-[32px]"} title={props.title} subtitle={props.subtitle}/>
      {props.children}
    </Limiter>
  )
}

export const TitleSubtitleCenterScreenV2 = (
  props: {
    title: React.ReactNode
    subtitle?: React.ReactNode
    children?: React.ReactNode | ((items: {
      buttonBar?: React.ReactNode
    }) => React.ReactNode)
  } & PropsClassName
    & Partial<PropsButtonStack>
    & PropsAs
) => {

  const defaultWrapper = useCallback(({children, className}: PropsWithChildren & PropsClassName) =>
      <Limiter className={cx("pb-[40px] mx-auto", className)}
               contentMaxWidth={"580px"}
               children={children}
      />
    , [])

  const Wrapper = props.wrapper ?? defaultWrapper

  const buttonBar = (
    props.primaryButton && (
      <ButtonBar align={"center"} sticky={"bottom"}>
        {props.primaryButton?.({size: "max", color: "primary-black"})}
        {props.secondaryButton?.({size: "max", color: "secondary"})}
      </ButtonBar>
    )
  )

  const defaultLayout = (
    <>
      {props.children}
      {buttonBar}
    </>
  )

  return (
    <Wrapper>
      <TitleSubtitleCenter className={"mb-[32px]"} title={props.title} subtitle={props.subtitle}/>
      {typeof props.children === "function" ? props.children({buttonBar: buttonBar}) : defaultLayout}
    </Wrapper>
  )
}


export const TitleSubtitleScreen = (props: {
  title: React.ReactNode
  subtitle?: React.ReactNode
  titleAlign: "left" | "center"
  avatar?: React.ReactNode
} & React.PropsWithChildren & PropsClassName) => {
  return (
    <div className={cx(props.className, "flex-1 flex flex-col")}>
      <TitleSubtitle
        className={"mb-[24px]"}
        textAlign={props.titleAlign}
        title={props.title}
        subtitle={props.subtitle}
        avatar={props.avatar}
      />
      {props.children}
    </div>
  )
}

export const TitleSubtitleLeftScreen = (props: {
  title: React.ReactNode
  subtitle?: React.ReactNode
  avatar?: React.ReactNode
} & React.PropsWithChildren & PropsClassName) => {
  return <TitleSubtitleScreen titleAlign={"left"}{...props}/>
}
