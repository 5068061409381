import Skeleton from "./Skeleton";
import Caption from "./Caption";
import {useDelayUnmount} from "../../helpers/hooks";
import {Variables} from "../../variables";
import cx from "classnames";

type Props = {
  text: string | undefined
}

const ScrollTextView = (props: Props) => {
  const disclaimerTextMount = useDelayUnmount(props.text === undefined, Variables.ui.animDuration.default)
  return (
    <div
      className={"flex flex-col flex-auto h-0 pl-[24px] pr-[11px] py-[24px] rounded-[16px] border-[1px] relative border-primary-20"}>
      {disclaimerTextMount.shouldRender &&
        // WEB-363: change to multiline skeleton when it is fixed
          <Skeleton className={"inset-0 rounded-[16px]"}
                    hidden={!disclaimerTextMount.shouldShow}
                    adjust={{left: 1, right: 1, top: 1, bottom: 1}}/>}
      <div
        className={cx(
          "text-left pr-[16px] h-[100%] overflow-y-auto overflow-x-hidden",
          "scrollbar-thin scrollbar-w-[6px] scrollbar-thumb-rounded scrollbar-thumb-primary-20 scrollbar-thumb-rounded-full scrollbar-track-rounded-full")}>
        {props.text && <Caption className={"whitespace-pre-wrap"} text={props.text} size={"3"}/>}
      </div>
    </div>
  )
}

export default ScrollTextView;