import Limiter, {LimiterOverflow} from "../../ui/Limiter";
import {BusinessApplicationResponse, BusinessContact} from "../../../api/whales/Onboarding";
import {useBusinessOnboardingQuery} from "../../../api/onboarding";
import {useAlertStack} from "../../../providers/alert-stack";
import {Section} from "../../ui/collections/Section";
import React, {useId, useState} from "react";
import Icon, {IconName} from "../../ui/icons/Icon";
import {BusinessApplicationExt, BusinessContactExt, BusinessContactsExt} from "../../domain/onboarding/business";
import BusinessContactRoleValue from "../../domain/onboarding/BusinessContactRoleView";
import Join from "../../ui/collections/Join";
import {useStrings} from "../../../strings";
import {PropsClassName} from "../../../helpers/props";
import {useAuthApi} from "../../../api/auth";
import {TitleSubtitleCenterScreen} from "../../ui/screen/TitleSubtitleScreen";
import {OnboardingCtx, useOnboardingNavStack} from "./BusinessOnboardingScreen";
import BusinessApplicationApproved from "./BusinessApplicationApproved";
import {useChecksController} from "../../verification/verification-controller";
import Caption from "../../ui/Caption";
import {BodyCode} from "../../ui/Labels";
import {Button} from "../../ui/Buttons";

import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingPortal,
  offset,
  useDismiss,
  useFloating,
  useInteractions,
  useTransitionStyles
} from "@floating-ui/react";
import {useCopyToClipboard} from "../../../helpers/hooks";
import {useSideIllustration} from "../../../atoms/side-illustration";
import {platformHasAtlanticApp} from "../../../helpers/device";
import {openInNewTab} from "../../../helpers/url";
import {useScreen} from "usehooks-ts";
import {TitleSubtitleBottomSheetLayout, WithBottomSheet} from "../../ui/BottomSheet";
import {Check} from "../../domain/common/check";
import DefaultRow from "../../ui/collections/rows/DefaultRow";

type Props = OnboardingCtx

const pollInterval = 5 * 1000

const ShareVerificationLink = (props: {
  shareUrl: string
}) => {
  const strings = useStrings()
  const trimmedUrl = props.shareUrl.replace(/^https:\/\//, "")
  const [_, setCopiedValue] = useCopyToClipboard()
  return (
    <div className={""}>
      <div className={"border-b border-primary-20 text-center pb-[16px]"}>
        <Caption size={"2-title"} text={strings["business.id.share_link"]}/>
      </div>
      <div className={"pt-[16px]"}>
        <Caption className={"max-w-[400px]"} size={"3"}
                 text={strings["business.id.share_copy"]}/>
        <div className={"flex flex-row gap-4 mt-[24px] "}>
          <BodyCode className={"min-w-[max-content]"} text={trimmedUrl}/>
          <Button className={"flex-1"}
                  type={"button"}
                  title={strings["general.copy"]}
                  size={"fit"} color={"primary-10"}
                  onClick={() => setCopiedValue(props.shareUrl)}/>
        </div>
      </div>
    </div>
  )
}

type State = {
  verificationUrl: string
}

const BusinessOnboardingStatus = (props: Props) => {
  const strings = useStrings()
  const alertStack = useAlertStack()
  const authApi = useAuthApi()
  const {push, ...navStack} = useOnboardingNavStack()
  const checkController = useChecksController()
  const businessOnboardingQuery = useBusinessOnboardingQuery()
  const {data} = businessOnboardingQuery.application.get.useQuery(
    props.user.userId, {
      refetchInterval: pollInterval,
      onSuccess: (data) => {
        if (data.payload?.info?.status === "active") {
          push(BusinessApplicationApproved, {}, {popAll: true})
        }
      },
    }
  )
  const application = data ?? props.application
  useSideIllustration("document")
  const [_copiedText, copyToClipboard] = useCopyToClipboard()

  const [state, setState] = useState<State>({verificationUrl: ""})

  const {title, subtitle} = function () {
    if (BusinessApplicationExt.isUserInputRequired(application)) {
      return {
        title: strings["business.application_status.input_required.title"],
        subtitle: strings["business.application_status.input_required.subtitle"]
      }
    } else {
      return {
        title: strings["business.application_status.in_review.title"],
        subtitle: strings["business.application_status.in_review.subtitle.2"]
      }
    }
  }()

  const onProcessChecks = (checks: Check[]) => {
    return checkController(checks, {
      titleAlign: "center",
      sourceStackEntry: BusinessOnboardingStatus,
      onFinished: async () => {
        await businessOnboardingQuery.application.get.preFetch(props.user.userId, undefined, 0)
        navStack.popUntil({
          component: BusinessOnboardingStatus,
          inclusive: false
        })
      }
    })
  }

  return (
    <WithBottomSheet bottomSheetView={ctx =>
      <TitleSubtitleBottomSheetLayout
        title={strings["business.id.share_link"]}
        subtitle={strings["business.id.share_copy"]}
        children={
          <div className={"flex flex-col items-center"}>
            <BodyCode text={state.verificationUrl.replace(/^https:\/\//, "")}/>
          </div>
        }
        buttons={<>
          <Button type={"button"} title={strings["general.copy"]} size={"fit"} color={"primary-10"}
                  onClick={() => {
                    copyToClipboard(state.verificationUrl).then(_ => {
                    })
                    ctx.dismiss()
                  }}/>
          <Button className={"mt-[12px]"} type={"button"} title={strings["general.dismiss"]} size={"max"}
                  color={"secondary"}
                  onClick={() => ctx.dismiss()}/>
        </>}
      />
    }>{ctx => (
      <TitleSubtitleCenterScreen title={title} subtitle={subtitle}>
        <Limiter contentMaxWidth={"460px"}>
          <BusinessInfoSection application={application} onProcessChecks={onProcessChecks}/>
          <BusinessKeyPeopleSection
            className={"mt-[32px]"}
            application={application}
            onProcessChecks={onProcessChecks}
            onOpenBottomSheet={(verificationUrl) => {
              setState(prev => ({...prev, verificationUrl}))
              ctx.setOpen(true)
            }}
          />
          <ProofOfAuthoritySection className={"mt-[32px]"} application={application} onProcessChecks={onProcessChecks}/>
          <EmailConfirmationSection
            className={"mt-[32px]"}
            application={application}
            onProcessChecks={onProcessChecks}
            onResend={(applicantEmail) => {
              return authApi.resendEmailConfirmation().then(() => {
                alertStack.showNotification(strings["verify_email.resent.title"], strings["verify_email.resent.body"](applicantEmail))
              }).catch((err) => alertStack.showError(err))
            }}/>
        </Limiter>
      </TitleSubtitleCenterScreen>
    )}
    </WithBottomSheet>
  )
}

export default BusinessOnboardingStatus;

const BusinessInfoSection = (props: PropsClassName & {
  application: BusinessApplicationResponse
  onProcessChecks: (checks: Check[]) => Promise<void>
}) => {
  const strings = useStrings()
  const apiCheck = props.application.payload.info.checks.find(check => check.type === "business_info")
  const check = apiCheck && Check.fromApiCommon(apiCheck)
  if (check === undefined) return null
  return (
    <Section
      className={props.className}
      title={strings["business.info.title"]}
      subtitle={function (): string {
        if (check.isUserInputRequired()) {
          return strings["business.info.verification_pending"]
        } else if (check.status !== "accepted") {
          return strings["business.info.in_review"]
        } else {
          return strings["business.info.approved"]
        }
      }()}>
      <LimiterOverflow overflow={"16px"}>
        {function () {
          if (check.isUserInputRequired()) {
            return <DefaultRow
              title={strings["business.info.row.update"]}
              trailingIcon={<Icon name={"chevron-right"}/>}
              onClickAsync={() => {
                return props.onProcessChecks([check])
              }}/>
          } else if (check.status !== "accepted") {
            return <DefaultRow title={strings["business.info.row.verification_pending"]}/>
          } else {
            return <DefaultRow title={strings["business.info.row.verified"]}
                               trailingIcon={<Icon name={"row-check"}/>}/>
          }
        }()}
      </LimiterOverflow>
    </Section>
  )
}

const BusinessContactRow = ({contact, ...props}: {
  contact: BusinessContact
  onOpenBottomSheet: (verificationUrl: string) => void
  onProcessChecks: (checks: Check[]) => Promise<void>
}) => {
  const strings = useStrings()

  const [shareOpen, setShareOpen] = useState(false)

  const {refs, floatingStyles, context} = useFloating({
    placement: "bottom-start",
    open: shareOpen,
    onOpenChange: setShareOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(({rects}) => {
        return ({
          mainAxis: 8,
          crossAxis: -(rects.floating.width - rects.reference.width) / 2
        })
      }),
      flip(),
    ]
  });

  const id = useId()
  const dismiss = useDismiss(context, {
    outsidePress: (e) => {
      // do not dismiss on clicks on row
      // @ts-ignore
      return e.target.closest(`#${CSS.escape(id)}`) === null
    }
  });
  const {isMounted, styles: transitionStyles} = useTransitionStyles(context, {
    duration: 200,
    close: {
      opacity: 0,
    },
    open: {
      opacity: 1,
    }
  });
  const {getReferenceProps, getFloatingProps} = useInteractions([
    dismiss,
  ]);

  const iosContactStatus = BusinessContactExt.getIOSStatus(contact)
  const verificationUrl = BusinessContactExt.verificationShareUrl(contact)
  const screenSize = useScreen()

  const {secondary, iconName} = function (): { secondary: React.ReactNode, iconName?: IconName } {
    switch (iosContactStatus) {
      case "in-review":
        return {secondary: strings["tier.upgrade.in_review"]}
      case "input-pending":
        const text = function () {
          if (contact.roles.includes("applicant")) {
            if (platformHasAtlanticApp()) {
              return strings["business.id.verify_in_app"]
            } else {
              return strings["business.id.complete_now"]
            }
          } else {
            return strings["business.id.share_link"]
          }
        }()

        const inputPendingSecondary = (
          <div ref={refs.setReference}  {...getReferenceProps()}>
            {text}
            {isMounted &&
                <FloatingPortal>
                    <FloatingFocusManager context={context} modal={false}>
                        <div className={"bg-secondary p-[16px] border border-primary-20 rounded-[8px]"}
                             ref={refs.setFloating}
                             style={{...floatingStyles, ...transitionStyles}}
                             {...getFloatingProps()}
                        >
                            <ShareVerificationLink shareUrl={verificationUrl || ""}/>
                        </div>
                    </FloatingFocusManager>
                </FloatingPortal>}
          </div>
        )
        return {secondary: inputPendingSecondary, iconName: "chevron-right"}
      case "approved":
        return {secondary: strings["business.check.approved"], iconName: "row-check"}
      case "rejected":
        return {secondary: strings["business.check.not_approved"]}
      case "deleted":
        return {secondary: strings["business_contact.deleted"]}
    }
  }()


  return <DefaultRow
    id={id}
    title={BusinessContactExt.fullName(contact)}
    subtitle={
      <Join
        elements={contact.roles.map((role) =>
          (<BusinessContactRoleValue role={role}/>))}
        separator={", "}
      />}
    secondary={secondary}
    trailingIcon={iconName && <Icon name={iconName}/>}
    onClickAsync={function () {
      if (iosContactStatus !== "input-pending") return undefined
      if (iosContactStatus === "input-pending") {
        return async () => {
          if (contact.roles.includes("applicant")) {
            if (platformHasAtlanticApp()) {
              openInNewTab(verificationUrl || "")
            } else {
              await props.onProcessChecks(contact.checks.map(check => Check.fromApiCommon(check)))
            }
          } else {
            const usePopUp = (screenSize && screenSize.width >= 800) ?? false
            if (usePopUp) {
              setShareOpen(true)
            } else {
              props.onOpenBottomSheet(verificationUrl || "")
            }
          }
        }
      }
    }()}
  />
}
const BusinessKeyPeopleSection = (props: PropsClassName & {
  application: BusinessApplicationResponse
  onOpenBottomSheet: (verificationUrl: string) => void
  onProcessChecks: (checks: Check[]) => Promise<void>
}) => {
  const strings = useStrings()

  const apiCheck = props.application.payload.info.checks.find(check => check.type === "business_key_people")
  const check = apiCheck && Check.fromApiCommon(apiCheck)
  if (check === undefined) return null

  return (
    <Section
      className={props.className}
      title={strings["business.key_people.title"]}
      subtitle={function (): React.ReactNode {
        if (check.isUserInputRequired()) {
          return strings["business.key_people.verification_pending"]
        } else if (!BusinessContactsExt.areApproved(props.application.payload.contacts)) {
          if (check.status === "accepted") {
            return strings["business.key_people.list_verified_identity_pending"]
          } else {
            return strings["business.key_people.verify_identity"]
          }
        } else {
          return strings["business.key_people.approved"]
        }
      }()}>
      <LimiterOverflow overflow={"16px"}>
        {function () {
          if (check.isUserInputRequired()) {
            return <DefaultRow
              title={strings["business.info.row.update"]}
              trailingIcon={<Icon name={"chevron-right"}/>}
              onClickAsync={() => {
                return props.onProcessChecks([check])
              }}/>
          } else if (check.isInManualReview()) {
            return <DefaultRow title={strings["business.key_people.docs_in_review"]}/>
          } else {
            return null
          }
        }()}
        {BusinessApplicationExt.contactsWithRoles(props.application).map((contact) => {
          return <BusinessContactRow
            key={contact.contactId}
            contact={contact}
            onProcessChecks={props.onProcessChecks}
            onOpenBottomSheet={props.onOpenBottomSheet}
          />
        })}
      </LimiterOverflow>
    </Section>
  )
}

const ProofOfAuthoritySection = (props: PropsClassName & {
  application: BusinessApplicationResponse
  onProcessChecks: (checks: Check[]) => Promise<void>
}) => {
  const strings = useStrings()
  const apiCheck = props.application.payload.info.checks.find(check => check.type === "business_applicant_relationship")
  const check = apiCheck && Check.fromApiCommon(apiCheck)
  if (check === undefined) return null

  const {secondary, iconName} = function (): { secondary: React.ReactNode, iconName?: IconName } {
    switch (check.status) {
      case "pending":
        if (check.isUserInputRequired()) {
          return {secondary: strings["business.id.complete_now"], iconName: "chevron-right"}
        } else {
          return {secondary: strings["tier.upgrade.in_review"]}
        }
      case "accepted":
        return {secondary: strings["business.check.approved"], iconName: "row-check"}
      case "rejected":
        return {secondary: strings["business.check.not_approved"]}
    }

  }()

  return (
    <Section
      className={props.className}
      title={strings["business.proof_of_authority.title"]}
      subtitle={function () {
        const companyName = props.application.payload.info.legalName || ""
        switch (check.status) {
          case "pending":
          case "rejected":
            return strings["business.proof_of_authority.input_required"](companyName)
          case "accepted":
            return strings["business.proof_of_authority.accepted"](companyName)
        }
      }()}>
      <LimiterOverflow overflow={"16px"}>
        <DefaultRow
          title={strings["business.proof_of_authority.row.title"]}
          secondary={secondary}
          trailingIcon={iconName && <Icon name={iconName}/>}
          onClickAsync={() => props.onProcessChecks([check])}
        />
      </LimiterOverflow>
    </Section>
  )
}

const EmailConfirmationSection = (props: PropsClassName & {
  application: BusinessApplicationResponse
  onProcessChecks: (checks: Check[]) => Promise<void>
  onResend: (applicantEmail: string) => Promise<void>
}) => {
  const strings = useStrings()
  const check = props.application.payload.info.checks.find(check => check.type === "email_confirmed")
  if (check === undefined) return null

  const applicant = props.application.payload.contacts.find(contact => contact.roles.includes("applicant"))

  const {secondary, iconName} = function (): { secondary: React.ReactNode, iconName?: IconName } {
    switch (check.status) {
      case "accepted":
        return {secondary: strings["business.email_confirmed.confirmed"], iconName: "row-check"}
      default:
      case "pending":
        return {secondary: strings["business.email_confirmed.input_pending"], iconName: "chevron-right"}
    }
  }()

  return (
    <Section
      className={props.className}
      title={strings["business.email_confirmed.title"]}
      subtitle={function () {
        if (check.status === "accepted") return undefined

        return strings["business.email_confirmed.pending.subtitle"]
      }()}
    >
      <LimiterOverflow overflow={"16px"}>
        <DefaultRow
          title={(applicant && BusinessContactExt.fullName(applicant)) ?? ""}
          subtitle={(applicant && applicant.email) ?? ""}
          secondary={secondary}
          trailingIcon={iconName && <Icon name={iconName}/>}
          onClickAsync={function () {
            if (check.status === "accepted") return undefined

            return () => props.onResend(applicant?.email || "")
          }()}
        />
      </LimiterOverflow>
    </Section>
  )
}

