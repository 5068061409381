import {Money} from "../../helpers/money";
import {Verification} from "../../components/domain/verifications/domain";
import * as TransferModels from "../../api/whales/TransferCommon";
import Decimal from "decimal.js";
import _ from "lodash";

class Limit {
  limit: Money
  durationDays: number

  constructor(other: { limit: Money, durationDays: number }) {
    this.limit = other.limit
    this.durationDays = other.durationDays
  }
}

export class Tier {
  tierId: string
  name: string
  description?: string

  limits: Limit[]
  requiredVerifications: Verification[]

  constructor(other: {
    tierId: string
    name: string
    description?: string
    limits: Limit[]
    requiredVerifications: Verification[]
  }) {
    this.tierId = other.tierId
    this.name = other.name
    this.description = other.description
    this.limits = other.limits
    this.requiredVerifications = other.requiredVerifications
  }

  highestLimit(): Decimal | undefined {
    return _.max(this.limits.map((l) => l.limit.amount))
  }

  isUpgradable(): boolean {
    return this.requiredVerifications.length > 0
  }

  isUnlimited(): boolean {
    return this.highestLimit() === undefined
  }

  static fromApi(apiModel: TransferModels.Tier): Tier {
    return new Tier({
      tierId: apiModel.tierId,
      name: apiModel.name,
      description: apiModel.description,
      limits: apiModel.limits.map((l) =>
        new Limit({limit: Money.fromApi(l.limit), durationDays: l.duration})
      ),
      requiredVerifications: apiModel.requiredVerifications?.map((v) => Verification.fromApi(v)) ?? []
    })
  }

  static fallback(): Tier {
    return new Tier({
      tierId: "fallback",
      name: "This tier is loading",
      limits: [
        new Limit({limit: Money.gbp(5000), durationDays: 30}),
        new Limit({limit: Money.gbp(35000), durationDays: 180}),
      ],
      requiredVerifications: []
    })
  }
}