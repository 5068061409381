import {atom, useRecoilState, useRecoilValue} from "recoil";
import {DeliveryOption, EntryMode, QuoteOption} from "../models/transfers/Estimate";
import {recoilPersist} from "recoil-persist";
import * as CommonModels from "../api/whales/Common";
import {Currency} from "../helpers/money";


type UserDefaultsRaw = {
  lastViewedSourceCurrency?: CommonModels.Currency
  lastViewedDestinationCurrency?: CommonModels.Currency
  lastViewedEntryMode?: EntryMode

  lastSelectedDeliveryOptionKind?: DeliveryOption["kind"]
  lastSelectedQuoteOptionKind?: QuoteOption["kind"]
}

export type UserDefaults = {
  readonly lastViewedSourceCurrency?: Currency
  readonly lastViewedDestinationCurrency?: Currency
  readonly lastViewedEntryMode: EntryMode

  readonly lastSelectedDeliveryOptionKind?: DeliveryOption["kind"]
  readonly lastSelectedQuoteOptionKind?: QuoteOption["kind"]
}

type Stringify = {
  userDefaults: UserDefaults
}

const {persistAtom} = recoilPersist({
  key: "atlantic-user-defaults",
  converter: {
    stringify: (arg: Stringify): string => {
      const userDefaults = arg.userDefaults
      const raw: UserDefaultsRaw = {
        lastViewedSourceCurrency: userDefaults.lastViewedSourceCurrency?.toApi(),
        lastViewedDestinationCurrency: userDefaults.lastViewedDestinationCurrency?.toApi(),
        lastViewedEntryMode: userDefaults.lastViewedEntryMode,
        lastSelectedDeliveryOptionKind: userDefaults.lastSelectedDeliveryOptionKind,
        lastSelectedQuoteOptionKind: userDefaults.lastSelectedQuoteOptionKind,
      }
      return JSON.stringify({
        userDefaults: raw
      })
    },
    parse: (str: string): {userDefaults: UserDefaults} => {
      const obj: {userDefaults?: UserDefaultsRaw} = JSON.parse(str)
      const raw = obj.userDefaults ?? {}
      const unmarhsalled: UserDefaults = {
        lastViewedSourceCurrency: raw.lastViewedSourceCurrency && Currency.fromApi(raw.lastViewedSourceCurrency),
        lastViewedDestinationCurrency: raw.lastViewedDestinationCurrency && Currency.fromApi(raw.lastViewedDestinationCurrency),
        lastViewedEntryMode: raw.lastViewedEntryMode ?? "source",
        lastSelectedDeliveryOptionKind: raw.lastSelectedDeliveryOptionKind,
        lastSelectedQuoteOptionKind: raw.lastSelectedQuoteOptionKind,
      }

      return {userDefaults: unmarhsalled}
    }
  }
})

export const userDefaultsAtom = atom<UserDefaults>({
  key: "userDefaults",
  default: {
    lastViewedEntryMode: "source",
  },
  effects: [persistAtom]
})

export const useUserDefaultsState = () => {
  return useRecoilState(userDefaultsAtom)
}

export const useUserDefaultsValue = () => {
  return useRecoilValue(userDefaultsAtom)
}
