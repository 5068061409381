import React from "react";
import {PropsOnComplete} from "../../../../helpers/props";
import {InterfaceTemplates, InterfaceTemplateValues} from "../domain";
import InterfaceTemplateSingleSelectScreen from "./InterfaceTemplateSingleSelectScreen";

type Props = {
  titleAlign: "left" | "center"
  template: InterfaceTemplates.Picker,
} & PropsOnComplete<InterfaceTemplateValues.Picker>

const InterfaceTemplatePickerScreen = ({template, onComplete, ...props}: Props) => {
  return (
    <InterfaceTemplateSingleSelectScreen
      titleAlign={props.titleAlign}
      template={new InterfaceTemplates.SingleSelect({
        ...template,
        options: template.options.map(o =>
          new InterfaceTemplateValues.SingleSelectOption(o)
        ),
      })
      }
      otherValue={({kind: "text", textValue: ""})}
      onChangeOtherValue={() => {}}
      onComplete={(ssv) => {
        return onComplete({
          kind: "picker",
          pickerValue: ssv.singleSelectValue.option
        })
      }}
    />
  )
}

export default InterfaceTemplatePickerScreen