import Collapsable from "./Collapsable";
import Caption from "./Caption";
import "./Errors.scss"
import {BlockButtonProps, Button, CloseButton} from "./Buttons";
import {useBasicAlertStack} from "../../providers/alert-stack";
import React, {useId} from "react";
import cx from "classnames";
import {useDelayUnmount} from "../../helpers/hooks";
import IconInfoCircle from "./icons/IconInfoCircle";
import {
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
  useTransitionStyles
} from "@floating-ui/react";
import {BodyTitle} from "./Labels";
import ButtonBar from "./ButtonBar";
import {useStrings} from "../../strings";
import {useDisplayErrorMessage} from "../../helpers/errors";
import {useResizeObserver} from "usehooks-ts";

type InlineErrorProps = {
  message?: string
  error?: any | null
}

export const InlineError = (props: InlineErrorProps) => {
  const displayErrorMessage = useDisplayErrorMessage()
  const message = props.message ?
    props.message :
    props.error ? displayErrorMessage(props.error) : undefined
  return (
    <Collapsable expanded={!!message}>
      <div className={"pt-[10px]"}>
        <ErrorMessage message={message}/>
      </div>
    </Collapsable>
  )
}

export const ErrorMessage = (props: { message: React.ReactNode }) => {
  return (
    <div className={"inline-error"}>
      <IconInfoCircle className={"inline-error__icon"} size={"medium"} color={"red"}/>
      <Caption text={props.message} size={"3"} color={"red"}/>
    </div>
  )
}

type ErrorBoxProps = {
  title: string
  message: string
  onClose: () => void
}

export const ErrorBox = (props: ErrorBoxProps) => {
  return (
    <div className={"error-box"}>
      <div className={"error-box__content"}>
        <Caption text={props.title} size={"3-title"}/>
        {props.message && <Caption className={"mt-[4px]"} text={props.message} size={"3"}/>}
      </div>
      <div className={"error-box__close"}>
        <CloseButton onClick={props.onClose}/>
      </div>
    </div>
  )
}


type ErrorStackWrapperProps = {
  index: number
  totalErrors: number
  errorId: string
  shown: boolean
  errorNode: React.ReactNode
}

const ErrorStackWrapper = (props: ErrorStackWrapperProps) => {
  const itemRef = React.useRef<HTMLDivElement>(null)
  const {height: itemHeight} = useResizeObserver({ref: itemRef})

  const moveOutAnimationMillis = 400000
  const {shouldRender, shouldShow} = useDelayUnmount(props.shown, moveOutAnimationMillis + 50)

  return (
    <>
      {shouldRender &&
          <div className={"error-stack__container"}
               style={{
                 height: shouldShow ? itemHeight : 0,
                 marginTop: props.index > 0 && shouldShow ? "8px" : "0"
               }}
          >
              <div ref={itemRef} className={cx("error-stack__item", {"error-stack__item--show": shouldShow})}>
                {props.errorNode}
              </div>
          </div>
      }
    </>
  )
}

type ErrorStackViewProps = { errorBoxes: { errorId: string, shown: boolean, errorNode: React.ReactNode }[] }
const ErrorStackView = (props: ErrorStackViewProps) => {
  return (
    <div className={"error-stack"}>
      {props.errorBoxes.map((errorBox, index) => (
        <React.Fragment key={errorBox.errorId}>
          <ErrorStackWrapper index={index}
                             totalErrors={props.errorBoxes.length}
                             {...errorBox}
          />
        </React.Fragment>
      ))}
    </div>
  )
}


type AlertBtn = (props: Pick<BlockButtonProps, "size" | "color">) => React.ReactNode

type AlertProps = {
  title: React.ReactNode | undefined
  content: React.ReactNode
  primaryButton: AlertBtn | undefined
  secondaryButton?: AlertBtn
}

export const AlertView = (props: AlertProps & AlertPopUpContext) => {
  const strings = useStrings()

  const secondaryButton: AlertBtn = props.secondaryButton ?? (innerProps =>
    <Button type={"button"} onClickAsync={props.onDismiss} title={strings["general.dismiss"]} {...innerProps}/>
  )

  return (
    <div className={"flex flex-col max-w-[428px]"}>
      <BodyTitle id={props.headingId} className={"self-center text-center"} text={props.title}/>
      <Caption id={props.descriptionId} className={"mt-[4px] self-center text-center"} size={"2"} text={props.content}/>
      <ButtonBar className={"mt-[16px]"}>
        {props.primaryButton && props.primaryButton({size: "max", color: "primary-black"})}
        {secondaryButton({size: "max", color: "secondary"})}
      </ButtonBar>
    </div>
  )
}

export type AlertPopUpContext = {
  headingId?: string
  descriptionId?: string

  onDismiss: () => Promise<void>
}


type AlertPopUpProps = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  children?: (alertProps: AlertPopUpContext) => React.ReactNode
}

const AlertPopUp = (props: AlertPopUpProps) => {
  const {refs, context} = useFloating({
    open: props.isOpen,
    onOpenChange: props.setIsOpen,
  })

  const click = useClick(context);
  const role = useRole(context);
  const dismiss = useDismiss(context, {outsidePress: true});

  const {getReferenceProps: _, getFloatingProps} = useInteractions([
    click,
    role,
    dismiss
  ]);

  const {isMounted, styles: transitionStyles} = useTransitionStyles(context, {
    duration: 200,
    close: {
      opacity: 0,
    },
    open: {
      opacity: 1,
    }
  });

  const headingId = useId();
  const descriptionId = useId();

  return (
    <FloatingPortal>
      {isMounted &&
          <FloatingOverlay
              className={"grid place-items-center bg-[rgba(var(--clr-primary-black-dec),0.2)] z-[var(--z-modal)]"}
              lockScroll={true}
              style={{...transitionStyles}}
          >
              <FloatingFocusManager context={context} modal={true} initialFocus={-1}>
                  <div ref={refs.setFloating}
                       className={"px-[32px] py-[24px] bg-secondary rounded-[16px] border border-primary-20 flex flex-col mx-[16px] my-[12px]"}
                       aria-labelledby={headingId}
                       aria-describedby={descriptionId}
                       {...getFloatingProps()}>
                    {props.children?.({headingId, descriptionId, onDismiss: async () => props.setIsOpen(false)})}
                  </div>
              </FloatingFocusManager>
          </FloatingOverlay>
      }
    </FloatingPortal>
  )
}

export const ErrorStack = () => {
  const errorStack = useBasicAlertStack()
  return (
    <>
      <ErrorStackView
        errorBoxes={
          errorStack.state.errorOrder.map((errorId) => {
            return {
              ...errorStack.state.errors[errorId],
              errorId: errorId
            }
          }).map((errorDetails) => {
              // MARK: copy errorId, so it won't change during next iterations
              const errorId = errorDetails.errorId
              return {
                errorId: errorDetails.errorId,
                shown: !errorDetails.closed,
                errorNode: (
                  <ErrorBox title={errorDetails.title}
                            message={errorDetails.message}
                            onClose={() => {
                              errorStack.closeError(errorId)
                            }}/>
                )
              }
            }
          )
        }
      />
      <AlertPopUp
        isOpen={errorStack.state.popUpAlert.open}
        setIsOpen={(isOpen) => {
          if (!isOpen) errorStack.closePopUp()
        }}
        children={errorStack.state.popUpAlert.content}
      />
    </>
  )
}