import {PropsClassName} from "../../helpers/props";
import React, {PropsWithChildren, useRef, useState} from "react";
import {
  Alignment,
  arrow,
  autoUpdate,
  flip,
  FloatingArrowProps,
  FloatingFocusManager,
  FloatingPortal,
  offset,
  platform,
  safePolygon,
  shift,
  Side,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
  useTransitionStyles
} from "@floating-ui/react";
import Caption from "./Caption";
import {BodyTitle} from "./Labels";
import {InlineButton} from "./Buttons";
import IconInfoCircle from "./icons/IconInfoCircle";
import cx from "classnames";

type TooltipViewProps = {
  title: React.ReactNode
  subtitle?: React.ReactNode
} & PropsClassName & PropsWithChildren

export const TooltipView = (props: TooltipViewProps) => {
  return (
    <div className={cx("py-[16px] px-[24px]",
      "max-w-[432px]",
      props.className
    )}>
      <BodyTitle text={props.title}/>
      {props.subtitle && <Caption size={"3"} className={"mt-[8px]"} text={props.subtitle}/>}
      {props.children}
    </div>
  )
}

type Props = {
  open?: boolean
} & PropsClassName & PropsWithChildren

const Tooltip = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const arrowRef = useRef(null);

  const {refs, floatingStyles, context} = useFloating({
    open: props.open ?? isOpen,
    onOpenChange: setIsOpen,
    placement: "right",
    // Make sure the tooltip stays on the screen
    whileElementsMounted: autoUpdate,
    middleware: [
      arrow({
        element: arrowRef,
        padding: 8,
      }),
      offset(4 + 11),
      flip({}),
      shift(),
    ],
  });

  // Event listeners to change the open state
  const hover = useHover(context, {
    move: false,
    handleClose: safePolygon({
      buffer: 5,
      requireIntent: false
    }),
  });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  // Role props for screen readers
  const role = useRole(context, {role: "tooltip"});

  // Merge all the interactions into prop getters
  const {getReferenceProps, getFloatingProps} = useInteractions([
    hover,
    focus,
    dismiss,
    role,
  ]);
  const {isMounted, styles: transitionStyles} = useTransitionStyles(context, {
    duration: 200,
    close: {
      opacity: 0,
    },
    open: {
      opacity: 1,
    }
  });

  return (
    <>
    <span ref={refs.setReference} {...getReferenceProps()} className={props.className}>
      <InlineButton type={"button"} onClick={() => setIsOpen(prev => !prev)}>
        <IconInfoCircle className={"inline align-baseline"} size={"small"}/>
      </InlineButton>
    </span>
      {isMounted && (
        <FloatingPortal>
          <FloatingFocusManager context={context} modal={false} initialFocus={-1} returnFocus={false}>
            <div ref={refs.setFloating}
                 style={{...floatingStyles, ...transitionStyles}}
                 {...getFloatingProps({
                   className: "border border-primary-20 rounded-[8px] bg-secondary"
                 })}
            >
              <CustomFloatingArrow context={context} ref={arrowRef}/>
              {props.children}
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </>
  )
}

// Inspiration https://github.com/floating-ui/floating-ui/blob/master/packages/react/src/components/FloatingArrow.tsx
const CustomFloatingArrow = React.forwardRef(function FloatingArrow(
  {
    context: {
      placement,
      elements: {floating},
      middlewareData: {arrow},
    },
    width = 28,
    staticOffset,
    style: {transform, ...restStyle} = {},
    ..._rest
  }: FloatingArrowProps,
  ref: React.Ref<SVGSVGElement>,
): JSX.Element | null {
  if (!floating) {
    return null;
  }

  // Strokes must be double the border width, this ensures the stroke's width
  // works as you'd expect.
  const [side, alignment] = placement.split('-') as [Side, Alignment];
  const isRTL = platform.isRTL(floating);
  const isCustomShape = false;
  const isVerticalSide = side === 'top' || side === 'bottom';

  const yOffsetProp = staticOffset && alignment === 'end' ? 'bottom' : 'top';
  let xOffsetProp = staticOffset && alignment === 'end' ? 'right' : 'left';
  if (staticOffset && isRTL) {
    xOffsetProp = alignment === 'end' ? 'left' : 'right';
  }

  const arrowX = arrow?.x != null ? arrow.x : '';
  const arrowY = arrow?.y != null ? arrow.y : ''

  const rotation = {
    top: isCustomShape ? 'rotate(180deg)' : '',
    left: isCustomShape ? 'rotate(90deg)' : 'rotate(-90deg)',
    bottom: isCustomShape ? '' : 'rotate(180deg)',
    right: isCustomShape ? 'rotate(-90deg)' : 'rotate(90deg)',
  }[side];

  return (
    <svg width={width} height={width} viewBox="0 0 28 16" fill="none"
         aria-hidden
         ref={ref}
         style={{
           position: 'absolute',
           pointerEvents: 'none',
           [xOffsetProp]: arrowX,
           [yOffsetProp]: arrowY,
           [side]:
             isVerticalSide || isCustomShape
               ? '100%'
               : `calc(100% - ${2}px)`,
           transform: `${rotation}${transform ?? ''}`,
           ...restStyle,
         }}
    >
      <g id="Arrow 1" transform="rotate(-90) translate(-7 0)">
        <path id="Vector"
              d="M7.65686 6.34314L1.41421 12.5858C0.6332 13.3668 0.6332 14.6332 1.41421 15.4142L7.65685 21.6569C9.15715 23.1571 10 25.192 10 27.3137L10 28L11 28L11 14L11 0L10 -4.37114e-08L10 0.686291C10 2.80802 9.15715 4.84285 7.65686 6.34314Z"
              fill="#FFF5ED"/>
        <path id="Vector_2"
              d="M8.36396 7.05025L2.12132 13.2929C1.73079 13.6834 1.7308 14.3166 2.12132 14.7071L8.36396 20.9497C10.0518 22.6376 11 24.9268 11 27.3137L11 28L10 28L10 27.3137C10 25.192 9.15715 23.1571 7.65685 21.6569L1.41421 15.4142C0.6332 14.6332 0.6332 13.3668 1.41421 12.5858L7.65686 6.34314C9.15715 4.84285 10 2.80802 10 0.686292L10 -4.37114e-08L11 0L11 0.686292C11 3.07324 10.0518 5.36242 8.36396 7.05025Z"
              fill="#D3CBC5"/>
        <rect id="Rectangle" x="11" y="0" width="10" height="28" fill="#FFF5ED"/>
      </g>
    </svg>
  )
});

export default Tooltip