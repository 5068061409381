import {InterfaceTemplates, InterfaceTemplateValues} from "../domain";
import {FormattedInput, TextInput} from "../../../ui/input/Inputs";
import {CommonInterfaceTemplateProps, compose} from "./common";
import React from "react";
import FixedFormatter from "../../../formatters/FixedFormatter";

export const InterfaceTemplateTextInput =
  React.forwardRef<HTMLInputElement, CommonInterfaceTemplateProps<InterfaceTemplates.Text, InterfaceTemplateValues.Text>>((
    {template, value, ...props}, ref) => {
    const transform = (value: string): InterfaceTemplateValues.Text => {
      return {
        kind: "text",
        textValue: value
      }
    }

    const commonProps = {
      ...props,
      innerRef: ref,
      type: "text",
      capitalization: "words",
      value: value.textValue,
      placeholder: template.placeholder,
      onChange: compose(props.onChange, transform),
      onValidate: props.onValidate && compose(props.onValidate, transform),
    } as const


    if (template.format) {
      return (
        <FormattedInput
          {...commonProps}
          placeholder={!template.format ? template.placeholder : undefined}
          formatter={FixedFormatter.pattern(template.format)}
        />
      )
    }

    return (
      <TextInput {...commonProps} />
    )
  })
