import {PropsClassName} from "../../../helpers/props";
import Icon from "./Icon";
import cx from "classnames";
import "./ChevronDown.scss";

type Props = {
  invertedY?: boolean
  hidden?: boolean
} & PropsClassName

export const ChevronDown = (props: Props) => {
  const invertedY = props.invertedY ?? false;
  const hidden = props.hidden ?? false;
  return <Icon className={cx(props.className, "chevron-down",
    {"chevron-down--inverted-y": invertedY, "chevron-down--hidden": hidden}
  )} name={"chevron-down"}/>
}

export default ChevronDown;