import axios, {AxiosError} from "axios";
import {QueryClient} from "react-query";
import {DynamicConfig} from "../storage/dynamic-сonfig";
import {AccessToken, TokenResponse} from "./whales/Auth";
import {AuthState, useAuthActions, useAuthValue} from "../atoms/auth";
import {ErrorResponse} from "./domain";

export class ApiError extends Error {
  axiosError: AxiosError | undefined

  constructor(other: {
    axiosError: AxiosError
  }) {
    super("API error")
    this.axiosError = other.axiosError
  }

  static fromError(err: any): ApiError | undefined {
    if (axios.isAxiosError(err)) {
      return new ApiError({axiosError: err})
    }
    return undefined
  }
}

export class VerboseError extends ApiError {
  status: string
  traceId: string
  error: string
  errorMessage: string
  errorDetails: Record<string, string>

  constructor(other: {
    axiosError: AxiosError,
    status: string,
    traceId: string,
    error: string,
    errorMessage: string,
    errorDetails: Record<string, string>
  }) {
    super(other);
    this.status = other.status
    this.traceId = other.traceId
    this.error = other.error
    this.errorMessage = other.errorMessage
    this.errorDetails = other.errorDetails
  }

  static fromError(err: any): VerboseError | undefined {
    if (err instanceof VerboseError) {
      return err
    }
    try {
      if (axios.isAxiosError(err)) {
        const data = err.response?.data as ErrorResponse | undefined
        if (data) {
          return new VerboseError({
            axiosError: err,
            status: data.status,
            traceId: data.traceId,
            error: data.payload.error,
            errorMessage: data.payload.errorMessage,
            errorDetails: data.payload.errorDetails
          })
        }
      }
    } catch (e) {
      return undefined
    }
    return undefined
  }
}

export class Api {
  readonly api = axios.create({
    headers: {
      "Content-Type": "application/json",
    },
  });
  private accessToken: string | undefined

  constructor(private authValue: AuthState, private onRefreshTokens: (token: AccessToken) => void, private onLogout: () => void) {
    const axiosInstance = this.api
    const self = this
    this.accessToken = authValue.authenticated ? authValue.token.accessToken : undefined

    axiosInstance.interceptors.request.use(
      (config) => {
        config.baseURL = DynamicConfig.getApiUrl()
        // @ts-expect-error
        config.headers = {
          ...this.defaultHeaders(),
          ...config.headers,
        }

        if (authValue.authenticated) {
          // @ts-expect-error
          config.headers["Authorization"] = `Bearer ${this.accessToken}`;
        }

        return config
      },
      (error) => {
        return Promise.reject(error)
      }
    )

    axiosInstance.interceptors.response.use((response) => {
      return response
    }, async function (error) {
      const axiosError = ApiError.fromError(error)
      if (!axiosError) {
        return Promise.reject(error)
      }

      const originalRequest = error.config;
      if (axiosError.axiosError?.response?.status === 401 && !originalRequest._retry) {
        if (!authValue.authenticated) {
          const verboseError = VerboseError.fromError(error)
          return Promise.reject(verboseError ?? error)
        }
        originalRequest._retry = true;
        await self.refreshToken(authValue.token);
        return axiosInstance(originalRequest);
      }
      return Promise.reject(error);
    });
  }

  post<Res, Req>(url: string, data: Req, headers: any = {}, params: any = {}): Promise<Res> {
    return this.api.post(url, data, {
      headers,
      params: params,
    }).then((response) => response.data)
  }

  postFiles<Res>(url: string, fd: FormData, files: File[], headers: any = {}, params: any = {}): Promise<Res> {
    files.forEach((file, idx) => {
      fd.append(`file${idx + 1}`, file)
    })
    return this.api.post(url, fd, {
      headers: {...headers, "Content-Type": "multipart/form-data"},
      params,
    }).then((response) => response.data)
  }

  get<Res>(url: string, params: any = {}, headers: any = {}) {
    return this.api.get<Res>(url, {params, headers}).then((response) => response.data)
  }

  getOrNull<Res>(url: string, params: any = {}, headers: any = {}): Promise<Res | null> {
    return this.api.get<Res>(url, {params, headers}).then((response) => response.data, (err) => {
      if (err.response?.status === 404) {
        return Promise.resolve(null)
      } else {
        return Promise.reject(err)
      }
    })
  }

  private async refreshToken(token: AccessToken) {
    await axios
      .create({
        baseURL: DynamicConfig.getApiUrl(),
        headers: this.defaultHeaders(),
      })
      .post<TokenResponse>("/auth/v1/token/refresh", {
        refreshToken: token.refreshToken,
      })
      .then((response) => {
        const tokens = response.data.payload.tokens
        if (!tokens) {
          return Promise.reject("No tokens")
        }

        this.accessToken = tokens.accessToken
        this.onRefreshTokens(tokens)

        return tokens.accessToken
      })
      .catch((error) => {
        this.onLogout()
        return Promise.reject(error)
      })
  }

  private userTz = Intl.DateTimeFormat().resolvedOptions().timeZone ?? "Europe/London"

  private defaultHeaders(): object {
    return {
      "Content-Type": "application/json",
      "X-Device-Id": DynamicConfig.getDeviceID(),
      "X-App-Platform": "web",
      "X-App-Version": __GIT_BRANCH_NAME__,
      "X-App-Timezone": this.userTz,
    }
  }
}

export const useApi = () => {
  const authValue = useAuthValue()
  const authActions = useAuthActions()

  return new Api(authValue,
    (token) => {
      authActions.updateTokens(token)
    }, () => {
      authActions.logout()
    })
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      retry: false,
      staleTime: 5 * 60 * 1000,
    }
  }
})