import {Picker, PickerOption} from "./Picker";
import CountryIcon from "./icons/CountryIcon";
import {useMemo} from "react";
import {useIntl} from "react-intl";

type Props = {
  className?: string;
  placeholder: React.ReactNode;
  countryCodes?: string[]
  suggestCountryCode?: string
  disabled?: boolean;
  value: string | null;
  onChange: (value: string | null) => void;
  error: any | undefined;
}

const CountryPicker = ({countryCodes, value, onChange, ...props}: Props) => {
  const intl = useIntl()

  const countryOptions: PickerOption[] | undefined = useMemo(() => {
    return countryCodes?.map(countryCode => {
      return {
        value: countryCode,
        label: intl.formatDisplayName(countryCode, {type: "region"}) || "",
        Icon: (props: {
          className?: string
        }) => {
          return <CountryIcon className={props.className} countryCode={countryCode}/>
        },
      }
    })?.sort((a, b) => {
        if (props.suggestCountryCode === a.value) {
          return -1
        }
        if (props.suggestCountryCode === b.value) {
          return 1
        }
        return a.label.localeCompare(b.label)
      }
    )
  }, [countryCodes, intl, props.suggestCountryCode])

  const selectedOption = useMemo(() => {
    if (value === null) {
      return null
    }
    return countryOptions?.find(option => option.value === value) || null
  }, [countryOptions, value])

  return (
    <Picker<PickerOption>
      options={countryOptions}
      value={selectedOption}
      errored={!!props.error}
      onChange={(newValue: PickerOption | null) => {
        onChange(newValue?.value || null)
      }}
      {...props}
    />
  )
}

export default CountryPicker;