import FormattedDueDate from "../../../components/formatters/date/FormattedDueDate";
import FormattedQuoteRate from "../../../components/formatters/FormattedQuoteRate";
import {Button} from "../../../components/ui/Buttons";
import ButtonBar from "../../../components/ui/ButtonBar";
import {Timeline} from "../../../models/transfers/Timeline";
import {TitleSubtitleLeftScreen} from "../../../components/ui/screen/TitleSubtitleScreen";
import {useTransferTimelineController} from "./transfer-timeline-controller";
import {useStrings} from "../../../strings";
import FormattedMoney, {useMoneyFormatter} from "../../../components/formatters/money/FormattedMoney";
import {Quote} from "../../../models/transfers/Estimate";
import {useNavStack} from "../../../atoms/nav-stack";
import TimelineStepMain from "./TimelineStepMain";
import {WithSkeleton} from "../../../components/ui/Skeleton";
import {Transfer} from "../../../models/transfers/Transfer";
import {BankAccountSectionRows} from "../../../components/domain/common/BankAccount";
import {Section} from "../../../components/ui/collections/Section";
import React from "react";
import {LearnMoreLink, WithLearnMoreSuffix} from "../../../components/ui/LearnMore";
import {Links} from "../../../helpers/Links";
import IconBodyRow from "../../../components/ui/collections/rows/IconBodyRow";
import InfoRow from "../../../components/ui/collections/rows/InfoRow";
import InlineButtonCopy from "../../../components/ui/button/InlineButtonCopy";

const TimelineStepSendPayment = ({transferId}: { transferId: string, }) => {
  const strings = useStrings()
  const navStack = useNavStack()

  const moneyFormatter = useMoneyFormatter()

  const {timeline, ...timelineController} = useTransferTimelineController({
    transferId: transferId,
    onSuccess: (t: Timeline) => {
      if (t.transfer.status.string === "payin_waiting") return
      navStack.popUntil({
        component: TimelineStepMain,
        inclusive: false,
      })
    }
  })

  const transferFallback = Transfer.fallbackWithStatus("payin_waiting")

  const withTransferSkeleton = (children: (transfer: Transfer) => React.ReactNode) => {
    return <WithSkeleton value={timeline?.transfer} fallback={transferFallback} children={children}/>
  }

  return (
    <TitleSubtitleLeftScreen
      title={strings["open_banking.manual_transfer.title"]}
      subtitle={withTransferSkeleton(transfer =>
        <>
          {function () {
            const isQuoteFrozen = transfer.displaying().isQuoteFrozen
            if (isQuoteFrozen.kind === "frozen-with-expiry") {
              return <>
                {strings["transfer.timeline.element.title.3.due_by_subtitle"](
                  <FormattedMoney value={transfer.sourceMoney}/>,
                  <FormattedDueDate value={(isQuoteFrozen as Quote.Frozen).expiry}/>,
                  <FormattedQuoteRate value={transfer.quote.rate}/>
                )}
                {" "}
              </>
            } else {
              return null
            }
          }()}
          {strings["open_banking.manual_transfer.subtitle_addition"]}
        </>
      )}
    >
      <Section className={"-mt-4"}>
        <BankAccountSectionRows
          includeCurrency={false}
          withCopy={true}
          bankAccount={timeline?.transfer.payInDetails?.bankTransfer?.bankAccount}
        />
        {withTransferSkeleton(transfer => (
          <InfoRow
            caption={strings["transfer.review.transfer.sending_amount"]}
            content={<FormattedMoney value={transfer.sourceMoney}/>}
            actionButton={<InlineButtonCopy copyString={moneyFormatter.formatWithoutSymbol(transfer.sourceMoney)}/>}
          />
        ))}
        {withTransferSkeleton(_transfer => (
          <InfoRow
            caption={strings["transfer.review.reference"]}
            content={
              <span className={"text-primary-50"}>
                {strings["transfer.timeline.reference_not_needed"]}
              </span>
            }
            actionButton={<LearnMoreLink url={Links.helpCenter.articles.payinReferenceNotRequired}/>}
          />
        ))}
      </Section>

      <Section title={strings["transfer.pay.things_to_remember.title"]}>
        {withTransferSkeleton(transfer => (
          <IconBodyRow iconName={"transfer-local"}>
            <WithLearnMoreSuffix url={Links.helpCenter.articles.localBankTransferOnly}>
              {function () {
                if (transfer.sourceMoney.currency.code === "EUR") {
                  return strings["transfer.pay.things_to_remember.transfer_type_eur"]
                } else {
                  return strings["transfer.pay.things_to_remember.transfer_type"]
                }
              }()}
            </WithLearnMoreSuffix>
          </IconBodyRow>
        ))}

        {withTransferSkeleton(_ => (
          <IconBodyRow iconName={"transfer-one-transfer"}>
            <WithLearnMoreSuffix url={Links.helpCenter.articles.oneBankTransferPayment}>
              {strings["transfer.pay.things_to_remember.one_transfer"]}
            </WithLearnMoreSuffix>
          </IconBodyRow>
        ))}

      </Section>

      <ButtonBar align={"center"} sticky={"bottom"}>
        <Button type={"button"}
                size={"big"}
                color={"primary-black"}
                title={strings["open_banking.manual_transfer.action"]}
                onClickAsync={timelineController.onPaymentConfirmation}
        />
      </ButtonBar>
    </TitleSubtitleLeftScreen>
  )
};
export default TimelineStepSendPayment;