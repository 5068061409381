export enum RelativeColloquialType {
  PAST,
  TODAY,
  TOMORROW,
  WITHIN_A_WEEK,
  FUTURE
}

export class RelativeColloquialTypeClass {
  static from(dt: Date): RelativeColloquialType {
    const now = new Date(); // your current date time
    const beginningOfToday = new Date(now);
    beginningOfToday.setHours(0, 0, 0, 0);
    const endOfToday = new Date(now);
    endOfToday.setHours(23, 59, 59, 999);

    const endOfTomorrow = new Date(endOfToday);
    endOfTomorrow.setDate(endOfToday.getDate() + 1);

    const endOfWeek = new Date(endOfToday);
    endOfWeek.setDate(endOfToday.getDate() + 6);

    const timeIntervalToDeliver = dt.getTime() - now.getTime();

    if (dt >= beginningOfToday && dt <= endOfToday) {
      return RelativeColloquialType.TODAY;
    }
    if (dt > endOfToday && dt < endOfTomorrow) {
      return RelativeColloquialType.TOMORROW;
    }
    if (dt > endOfToday && dt < endOfWeek) {
      return RelativeColloquialType.WITHIN_A_WEEK;
    }
    if (timeIntervalToDeliver < 0) {
      return RelativeColloquialType.PAST;
    }
    return RelativeColloquialType.FUTURE;
  }
}
