import {useAuthActions, useAuthValue} from "../../atoms/auth";
import Icon from "../ui/icons/Icon";
import {InlineButton} from "../ui/Buttons";
import Caption from "../ui/Caption";
import {PropsClassName} from "../../helpers/props";
import cx from "classnames";
import {useStrings} from "../../strings";
import {useNavStack} from "../../atoms/nav-stack";
import {Links} from "../../helpers/Links";
import {useRouterNavigateBack} from "../../routes/router-dom-wrapper/hooks";

type NavBarFeatures = "logout" | "help"

type Props = {
  backDisabled?: boolean;
  onBack?: () => void;
  features?: NavBarFeatures[];
} & PropsClassName
const NavBar = (props: Props) => {
  const strings = useStrings()

  const authActions = useAuthActions()
  const authValue = useAuthValue()
  const navStack = useNavStack()
  const routerNavigateBack = useRouterNavigateBack()

  const onBack = props.onBack ?? navStack.pop ?? routerNavigateBack

  return (
    <div className={cx(props.className, "flex justify-end items-center bg-secondary pb-[16px]")}>
      <InlineButton
        className={cx(
          "flex-auto flex-grow-0 pr-[24px] mr-auto transition-opacity default-anim",
          {"opacity-0 invisible": props.backDisabled || onBack === undefined}
        )}
        type={"button"}
        onClick={onBack}
        title={<Icon name={"arrow-narrow-right"}/>}
      />
      {props.features?.includes("help") &&
          <InlineButton type={"link"}
                        className={"block ml-[32px] flex-1 flex-grow-0"}
                        href={Links.atlantic.root.contactUs}
                        title={<Caption size={"3-title"} text={strings["account.help"]}/>}
                        newTab={true}
          />}
      {props.features?.includes("logout") && authValue.authenticated && (
        <InlineButton type={"button"}
                      className={"block ml-[32px] flex-1 flex-grow-0"}
                      title={<Caption size={"3-title"} text={strings["general.logout"]}/>}
                      onClick={() => {
                        authActions.logout()
                      }}
        />
      )}
    </div>
  )
}

export const NavBarSpacer = () => {
  return (
    <div className={"h-10"} />
  )
}

export default NavBar;