import React from "react";
import SignInForm from "./SignInForm";
import SideIllustrationLayout from "../../../components/ui/layout/unauthorized/SideIllustrationLayout";
import {TitleSubtitleCenterScreen} from "../../../components/ui/screen/TitleSubtitleScreen";
import OnboardingScreen from "../../../components/onboarding/OnboardingScreen";
import {useStrings} from "../../../strings";

const SignInPage = () => {
  const strings = useStrings()
  return (
    <SideIllustrationLayout sideIllustration={"app-showcase"}>
      <OnboardingScreen>
        <TitleSubtitleCenterScreen title={strings["log_in.welcome_back"]}>
          <SignInForm/>
        </TitleSubtitleCenterScreen>
      </OnboardingScreen>
    </SideIllustrationLayout>
  )
}

export default SignInPage;