import {useStrings} from "../../strings";
import {Link} from "./Link";
import {PropsWithChildren} from "react";
import {BlockButtonProps, Button, LinkTypeProps} from "./Buttons";

export const LearnMoreLink = ({url}: { url: string}) => {
  const strings = useStrings()
  return <Link href={url} newTab={true} className={"font-bold"}>{strings["general.learn_more"]}</Link>
}

export const WithLearnMoreSuffix = ({url, children}: { url: string } & PropsWithChildren) => {
  return <>
    {children}
    {" "}
    <LearnMoreLink url={url}/>
  </>
}

export const LearnMoreButton = (props: Omit<LinkTypeProps, "type"> & BlockButtonProps) => {
  const strings = useStrings()
  return (
    <Button {...props} type={"link"} title={strings["general.learn_more"]} newTab={true}/>
  )
}
