import {TitleSubtitleCenterScreenV2} from "../../../components/ui/screen/TitleSubtitleScreen";
import {useStrings} from "../../../strings";

const PasswordResetStepEmailSent = () => {
  const strings = useStrings()
  return (
    <TitleSubtitleCenterScreenV2
      title={strings["reset_password.success.title"]}
      subtitle={strings["reset_password.success.subtitle"]}
    />
  )
}

export default PasswordResetStepEmailSent;