import React from 'react';
import './App.css';
import {useWindowDimensions} from "./helpers/hooks";
import {BrowserRouter} from "react-router-dom";
import AppRouter from "./routes/AppRouter";
import {useLoadingBlockerRemover} from "./routes/hooks";

const App = () => {
  const {height: viewportHeight} = useWindowDimensions()
  useLoadingBlockerRemover() // add loading blocker to the app, otherwise might encounter infinite loading

  return (
    <div
      style={{
        "--100vh": `${viewportHeight}px`,
      }}>
      <BrowserRouter>
        <AppRouter/>
      </BrowserRouter>
    </div>
  );
}

export default App;
