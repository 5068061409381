import {BuildConfig} from "build-config";
import {useLocalStorage} from "usehooks-ts";
import {BuildConfigSchema} from "../configs/schema";


export const useConfig = () => {
  const [localConfig, setLocalConfig] = useLocalStorage<Partial<BuildConfigSchema> | undefined>('atl-config', undefined);

  (window as any).atlantic = {
    // @ts-expect-error
    ...window.atlantc,
    setLocalConfig
  }

  return {...BuildConfig, ...localConfig}
}