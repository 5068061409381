import {InterfaceTemplates, InterfaceTemplateValues} from "../domain";
import {AddressInputs} from "../../../ui/input/AddressInputGroup";
import {CommonInterfaceTemplateProps} from "./common";
import {Address} from "../../../../models/address";

export const InterfaceTemplateAddressInput = (
  {template, value, ...props}:
    CommonInterfaceTemplateProps<InterfaceTemplates.Address, InterfaceTemplateValues.Address> & {
    suggestedCountryCode: string | undefined
  }
) => {
  return (
    <AddressInputs
      suggestedCountryCode={props.suggestedCountryCode}
      availableCountries={template.countryCodes}
      value={{
        addressLine1: value.addressValue.line1,
        addressLine2: value.addressValue.line2,
        city: value.addressValue.city,
        stateOrProvince: value.addressValue.stateOrProvince || "",
        postcode: value.addressValue.postcode,
        countryCode: value.addressValue.countryCode,
      }}
      error={props.error}
      disabled={props.disabled ?? false}
      onChange={(newValue) => {
        props.onChange({
          kind: "address",
          addressValue: new Address({
            line1: newValue.addressLine1,
            line2: newValue.addressLine2,
            city: newValue.city,
            stateOrProvince: newValue.stateOrProvince,
            postcode: newValue.postcode,
            countryCode: newValue.countryCode || "",
          })
        })
      }}/>
  )
}
