import {useMutation, useQuery} from "react-query";
import {Api, queryClient, useApi} from "./axios";
import {
  BankAccountElementValidateResponse,
  CreateRecipientRequest,
  CreateRecipientResponse,
  GetRecipientResponse,
  GetRecipientsResponse,
  TemplateResponse,
  ValidateBankAccountElementRequest
} from "./whales/Recipients";
import {Recipient} from "../components/domain/recipients/domain";
import {BankAccountTemplate} from "../models/common/BankAccount";

export class RecipientsApi {
  constructor(private api: Api) {
  }

  get(recipientId: string): Promise<Recipient> {
    return this.api.get<GetRecipientResponse>(`/recipients/v1/with-template/get`, {recipientId})
      .then((res) => {
          return Recipient.fromApi(res.payload)
        }
      )
  }

  bankAccountTemplateFind(currency: string): Promise<BankAccountTemplate> {
    return this.api.get<TemplateResponse>(`/recipients/v1/bank-account/template/find`, {currencyCode: currency})
      .then((res) => BankAccountTemplate.fromApi(res.payload))
  }

  bankAccountElementValidate(req: ValidateBankAccountElementRequest): Promise<BankAccountElementValidateResponse> {
    return this.api.post<BankAccountElementValidateResponse, ValidateBankAccountElementRequest>(`/recipients/v1/bank-account/element/validate`, req)
  }

  recipientCreate(req: CreateRecipientRequest): Promise<Recipient> {
    return this.api.post<CreateRecipientResponse, CreateRecipientRequest>(`/recipients/v1/create`, req)
      .then((res) => Recipient.fromApi(res.payload))
  }

  recipientList(): Promise<Recipient[]> {
    return this.api.get<GetRecipientsResponse>(`/recipients/v1/list`)
      .then((res) => res.payload?.results?.map((e) => Recipient.fromApi(e)) || [])
  }
}

export function useRecipientsApi() {
  return new RecipientsApi(useApi())
}

const queryKeys = {
  bankAccount: {
    template: {
      find: (currency: string) => ["recipients.bank-account.template.find", currency],
    },
    element: {
      validate: (req: ValidateBankAccountElementRequest) => ["recipients.bank-account.element.validate", req],
    }
  },
  recipient: {
    get: (recipientId: string) => ["recipients.find", recipientId],
    create: "recipients.create",
    list: "recipients.list",
  },
  bulk: {
    get: (jobId: string) => ["recipients.bulk.get", jobId],
  }
}

export function useRecipientsQuery() {
  const api = useRecipientsApi();
  return {
    bankAccount: {
      template: {
        find: {
          useQuery: (currency: string) => useQuery(queryKeys.bankAccount.template.find(currency), () => api.bankAccountTemplateFind(currency)),
          preFetch: (currency: string) => queryClient.prefetchQuery(queryKeys.bankAccount.template.find(currency), () => api.bankAccountTemplateFind(currency)),
        },
      },
      element: {
        validate: {
          useQuery: (req: ValidateBankAccountElementRequest, options?: {
            keepPreviousData?: boolean;
            onSettled?: (data: BankAccountElementValidateResponse | undefined, error: any | null) => void;
          }) =>
            useQuery(queryKeys.bankAccount.element.validate(req), () => api.bankAccountElementValidate(req), options),
        }
      }
    },
    recipient: {
      get: {
        useQuery: (recipientId: string) => useQuery(queryKeys.recipient.get(recipientId), () => api.get(recipientId)),
      },
      list: {
        useQuery: () => useQuery(queryKeys.recipient.list, () => api.recipientList()),
      },
      create: {
        useMutation: () => {
          return useMutation(queryKeys.recipient.create, (req: CreateRecipientRequest) => api.recipientCreate(req), {
            onSuccess: async (data) => {
              await queryClient.invalidateQueries(queryKeys.recipient.list)
              queryClient.setQueryData(
                queryKeys.recipient.get(data.recipientId),
                data
              )
            }
          })
        }
      }
    },
  }
}