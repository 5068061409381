import {useState} from "react";
import {useOnboardingQuery} from "../../api/onboarding";
import {useAsyncLoading} from "../../helpers/hooks";
import {useAlertStack} from "../../providers/alert-stack";
import ButtonBar from "../ui/ButtonBar";
import {Button} from "../ui/Buttons";
import {Checkbox, Form} from "../ui/input/Inputs";
import {UserType} from "../../api/whales/Common";
import {useStrings} from "../../strings";
import ScrollTextView from "../ui/ScrollTextView";

type Props = {
  countryCode: string
  userType: UserType
  onContinue: () => Promise<void>
}
type State = {
  accepted: boolean
}

const Disclaimer = (props: Props) => {
  const strings = useStrings()
  const {showError} = useAlertStack()
  const [state, setState] = useState<State>({
    accepted: false,
  })
  const onboardingQuery = useOnboardingQuery()
  const {isLoading, asyncWithLoading} = useAsyncLoading(false)

  const {data: disclosure} = onboardingQuery.disclosure.useQuery(props.countryCode, props.userType, "signup", {onError: showError})

  const onSubmit = () => asyncWithLoading(async (): Promise<any> => {
    try {
      if (state.accepted) {
        await props.onContinue()
      } else {
        showError(strings["onboarding.disclaimer.agree_request"])
        return Promise.resolve()
      }
    } catch (e) {
      showError(e)
    }
  })


  return (
    <div className={"flex flex-col"} style={{
      height: "calc(var(--100vh) - 250px)"
    }}>
      <ScrollTextView text={disclosure?.payload.text}/>
      <Form className={"mt-[12px]"} onSubmit={onSubmit} mw={"580px"}>
        <Checkbox isChecked={state.accepted}
                  label={strings["onboarding.disclaimer.agree_checkbox_label"]}
                  onChange={(isChecked) => {
                    setState(prev => ({...prev, accepted: isChecked}))
                  }}/>
        <ButtonBar align={"center"}>
          <Button className={"mt-[32px] max-w-[460px]"}
                  type={"submit"}
                  title={strings["general.continue"]}
                  size={"max"}
                  color={"primary-black"}
                  loading={disclosure === undefined || isLoading || undefined}/>
        </ButtonBar>
      </Form>
    </div>
  )
}

export default Disclaimer