import Avatar from "../../ui/Avatar";
import {Recipient} from "./domain";

interface Props {
  recipient?: Recipient;
  size: "regular" | "big"
  className?: string;
}

const RecipientAvatar = (props: Props) => {
  return <Avatar title={props.recipient?.avatarLabel} size={props.size} className={props.className}
                 colors={props.recipient?.gradientColors}/>
};

export default RecipientAvatar;