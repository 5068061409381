import {Api, useApi} from "./axios";
import {Subscription, SubscriptionListResponse} from "./whales/Notifications";
import {useMutation, useQuery, useQueryClient} from "react-query";

export class NotificationsApi {
  constructor(private api: Api) {
  }

  subscriptionsList(): Promise<Subscription[]> {
    return this.api.get<SubscriptionListResponse>("/notifications/v1/subscriptions/list").then(r => r.payload.results)
  }

  subscriptionsMarketingSubscribe(): Promise<{}> {
    return this.api.post("/notifications/v1/subscriptions/marketing/subscribe", {}).then(_ => ({}))
  }

  subscriptionsMarketingUnsubscribe(): Promise<{}> {
    return this.api.post("/notifications/v1/subscriptions/marketing/unsubscribe", {}).then(_ => ({}))
  }
}

export function useNotificationsApi() {
  return new NotificationsApi(useApi())
}

const queryKeys = {
  subscriptions: {
    list: "subscriptions.list",
    marketing: {
      update: "subscriptions.marketing.update",
    }
  }
};

export function useNotificationsQuery() {
  const api = useNotificationsApi();
  const queryClient = useQueryClient()
  return {
    subscriptions: {
      list: {
        useQuery: () => useQuery(queryKeys.subscriptions.list, () => api.subscriptionsList()),
      },
      marketing: {
        update: {
          useMutation: () => useMutation(queryKeys.subscriptions.marketing.update, (subscribed: boolean) => {
            return subscribed
              ? api.subscriptionsMarketingSubscribe()
              : api.subscriptionsMarketingUnsubscribe()
          }, {
            onSettled: async () => {
              return await queryClient.invalidateQueries({queryKey: queryKeys.subscriptions.list})
            },
          })
        }
      }
    }
  }
}