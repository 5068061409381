import {CommonInputProps, CommonInputPropsGeneric, InputGroup, InputGroupRow, MaskedInput} from "./Inputs";
import {useIntl} from "react-intl";
import {useMemo} from "react";
import {IMask} from "react-imask";
import {Picker} from "../Picker";
import _ from "lodash";
import {InlineError} from "../Errors";

export class DateInputValue {
  day: string
  month: number | null // 0 - January
  year: string

  constructor(other: { day: string, month: number | null, year: string }) {
    this.day = other.day
    this.month = other.month
    this.year = other.year
  }

  isComplete(): boolean {
    return this.day !== "" && this.month != null && this.year !== ""
  }

  toApiString(): string | null {
    try {
      if (!this.isComplete() || this.month == null) return null
      return `${this.year}-${(this.month + 1).toString().padStart(2, "0")}-${this.day.padStart(2, "0")}`
    } catch (e) {
      console.error("Failed to convert DateInputValue to API string", e)
      return null
    }
  }

  static empty(): DateInputValue {
    return new DateInputValue({day: "", month: null, year: ""})
  }

  static fromDate(date: Date): DateInputValue {
    return new DateInputValue({
      day: date.getDate().toFixed(0),
      month: date.getMonth(),
      year: date.getFullYear().toFixed(0)
    })
  }

}

type Props = CommonInputPropsGeneric<DateInputValue> & {
  name?: string
}

const DateInputGroup = (props: Props) => {
  return (
    <InputGroup className={props.className} name={props.name}>
      <InputGroupRow>
        <DateDayInput
          className={"flex-1 basis-[25%]"}
          value={props.value?.day}
          error={props.error}
          errorMessageHidden={true}
          onBlur={props.onBlur}
          onChange={newValue => {
            if (!props.value) return
            props.onChange?.(new DateInputValue({
              ...props.value,
              day: newValue
            }))
          }}
        />
        <DateMonthInput
          className={"flex-1 basis-[50%]"}
          value={props.value ? (props.value.month?.toFixed(0) ?? "") : undefined}
          error={props.error}
          errorMessageHidden={true}
          onChange={newValue => {
            if (!props.value) return
            props.onChange?.(new DateInputValue({
              ...props.value,
              month: function () {
                try {
                  return Number(newValue)
                } catch (e) {
                  return null
                }
              }()
            }))
          }}
        />
        <DateYearInput
          className={"flex-1 basis-[25%]"}
          value={props.value?.year}
          error={props.error}
          errorMessageHidden={true}
          onBlur={props.onBlur}
          onChange={newValue => {
            if (!props.value) return
            props.onChange?.(new DateInputValue({
              ...props.value,
              year: newValue
            }))
          }}
        />
      </InputGroupRow>
      <InlineError error={props.error}/>
    </InputGroup>
  )
}

const DateDayInput = (props: CommonInputProps) => {
  const intl = useIntl()
  const options: IMask.AnyMaskedOptions = {
    mask: "00",
  }

  return <MaskedInput
    {...props}
    type={"text"}
    iMaskOptions={options}
    inputMode={"numeric"}
    autoComplete={"bday-day"}
    capitalization={"none"}
    placeholder={_.capitalize(intl.formatDisplayName("day", {type: "dateTimeField"}))}
  />
}

export const DateMonthInput = (props: CommonInputProps) => {
  const intl = useIntl()

  const monthNames = useMemo(() => Array.from({length: 12}, (_, i) => {
    return intl.formatDateToParts(Date.UTC(2020, i), {month: "long", timeZone: "UTC"}).find(part => part.type === "month")?.value
  }), [intl])

  const options = useMemo(() =>
    monthNames.map((name, i) => ({
        value: i.toString(),
        label: name,
      })
    ), [monthNames])

  return (
    <Picker
      {...props}
      options={options}
      placeholder={_.capitalize(intl.formatDisplayName("month", {type: "dateTimeField"}))}
      value={options.find(option => option.value === props.value) ?? null}
      errored={props.error !== undefined}
      hasIcon={false}
      noSearchIcon={true}
      filterOptions={(option, inputValue) => {
        if (option.data.skeleton) return false

        const input = inputValue.toLowerCase()
        const label = option.label.toLowerCase()
        if (label.includes(input)) return true

        try {
          const inputNum = Number(input)
          if (inputNum === 0) return true
          if (Number.isNaN(inputNum)) return false
          const valueNum = Number(option.value) + 1
          return valueNum.toFixed(0).startsWith(inputNum.toFixed(0))
            || valueNum.toFixed(0).startsWith(input)
        } catch (e) {
          return false
        }
      }}
      onChange={(newValue) => {
        props.onChange?.(newValue?.value || "")
      }}
    />
  )
}

const DateYearInput = (props: CommonInputProps) => {
  const intl = useIntl()
  const options: IMask.AnyMaskedOptions = {
    mask: "0000",
  }

  return <MaskedInput
    {...props}
    type={"text"}
    iMaskOptions={options}
    inputMode={"numeric"}
    autoComplete={"bday-day"}
    capitalization={"none"}
    placeholder={_.capitalize(intl.formatDisplayName("year", {type: "dateTimeField"}))}
  />
}

export default DateInputGroup