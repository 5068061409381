import {useMutation, useQuery} from "react-query";
import {Api, useApi} from "./axios";
import {
    EmailValidationRequest,
    OTPConfirmationRequiredResponse,
    OTPConfirmationResendRequest, PasswordResetRequest,
    PasswordValidationRequest,
    RegisterRequest,
    TokenResponse,
    UserInfoResponse,
    UserTypesListResponse
} from "./whales/Auth";
import {UserType} from "./whales/Common";

export class AuthApi {
    constructor(private api: Api) {
    }

    userTypesList(): Promise<UserType[]> {
        return this.api.get<UserTypesListResponse>("/auth/v1/user-types/list")
            .then((res) => res.payload.types)
    }

    register(userType: UserType, req: RegisterRequest): Promise<TokenResponse> {
        return this.api.post<TokenResponse, RegisterRequest>(
            "/auth/v1/register",
            req,
            {},
            {"type": userType,},
        )
    }

    userInfo(accessToken?: string): Promise<UserInfoResponse> {
        return this.api.get<UserInfoResponse>("/auth/v1/user/info/get", {}, {
            "Authorization": `Bearer ${accessToken}`
        })
    }

    resendOtpCode(req: OTPConfirmationResendRequest): Promise<OTPConfirmationRequiredResponse> {
        return this.api.post("/auth/v1/authorize/otp/resend-code", req)
    }

    emailValidate(req: EmailValidationRequest): Promise<{}> {
        return this.api.post("/auth/v1/email/validate", req)
    }

    passwordValidate(req: PasswordValidationRequest): Promise<{}> {
        return this.api.post("/auth/v1/password/validate", req)
    }

    logout(): Promise<{}> {
        return this.api.post("/auth/v1/logout", undefined)
    }

    resendEmailConfirmation(): Promise<{}> {
        return this.api.post("/auth/v1/email/confirmation/resend", undefined)
    }

    passwordReset(req: PasswordResetRequest): Promise<{}> {
        return this.api.post("/auth/v1/password/reset", req)
    }
}

export function useAuthApi() {
    const api = useApi()
    return new AuthApi(api)
}

const queryKeys = {
    userTypesList: "userTypesList",
    email: {
        validate: "email.validate"
    },
    password: {
        validate: "password.validate"
    }
}

export function useAuthQuery() {
    const authApi = useAuthApi()
    return {
        userTypesList: {
            useQuery: (options?: { onError: (err: any) => void }) => useQuery(queryKeys.userTypesList, () => authApi.userTypesList(), options)
        },
        email: {
            validate: {
                useMutation: () => useMutation(queryKeys.email.validate, (req: EmailValidationRequest) => authApi.emailValidate(req))
            }
        },
        password: {
            validate: {
                useMutation: () => useMutation(queryKeys.password.validate, (req: PasswordValidationRequest) => authApi.passwordValidate(req))
            }
        }
    }
}