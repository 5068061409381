import {Section} from "../../ui/collections/Section";
import {useStrings} from "../../../strings";
import {BankAccountSectionRows} from "../common/BankAccount";
import {Recipient} from "./domain";

const RecipientSection = (props: {recipient?: Recipient}) => {
  const strings = useStrings()
  return (
    <Section title={strings["transfer.review.recipient.title"]}>
      <BankAccountSectionRows bankAccount={props.recipient?.bankAccount} includeCurrency={false}/>
    </Section>
  )
}

export default RecipientSection