import {Money} from "../../../helpers/money";
import {BankAccountSectionRows} from "./BankAccount";
import {PayInDetails} from "../../../models/common/PayInDetails";
import InfoRow from "../../ui/collections/rows/InfoRow";
import FormattedMoney, {useMoneyFormatter} from "../../formatters/money/FormattedMoney";
import InlineButtonCopy from "../../ui/button/InlineButtonCopy";
import {useStrings} from "../../../strings";
import {LearnMoreLink} from "../../ui/LearnMore";
import {Links} from "../../../helpers/Links";
import React from "react";
import Collapsable from "../../ui/Collapsable";
import Icon from "../../ui/icons/Icon";
import {InlineButton} from "../../ui/Buttons";

const ReferenceRow = ({reference}: { reference: string | undefined }) => {
  const strings = useStrings()
  if (reference) {
    return (
      <InfoRow
        caption={<div className={"flex items-center"}>
          {strings["portals.optional_reference"]}
          <InlineButton
            className={"ml-1 flex items-center"}
            type={"link"}
            newTab={true}
            href={Links.helpCenter.articles.portalsOptionalReference}
          >
            <Icon className={"opacity-50"} name={"help-circle"}/>
          </InlineButton>
        </div>}
        content={reference}
        actionButton={<InlineButtonCopy copyString={reference}/>}
      />
    )
  } else {
    return (
      <InfoRow
        caption={strings["transfer.review.reference"]}
        content={
          <span className={"text-primary-50"}>
                  {strings["transfer.timeline.reference_not_needed"]}
                </span>
        }
        actionButton={<LearnMoreLink url={Links.helpCenter.articles.payinReferenceNotRequired}/>}
      />
    )
  }
}

type Props = {
  sendingAmount: Money | undefined
  payInDetails: PayInDetails | undefined
  isLoading: boolean
  onlyImportant?: boolean
}

const PayInDetailsSectionRows = (props: Props) => {
  const strings = useStrings()
  const moneyFormatter = useMoneyFormatter()

  type SectionItem = {
    key: string,
    node: React.ReactNode,
    isImportant: boolean
  }
  const rows: SectionItem[] = []

  if (props.sendingAmount) {
    rows.push({
      key: "sendingAmount",
      node: (
        <InfoRow
          caption={strings["transfer.review.transfer.sending_amount"]}
          content={<FormattedMoney value={props.sendingAmount}/>}
          actionButton={<InlineButtonCopy copyString={moneyFormatter.formatWithoutSymbol(props.sendingAmount)}/>}
        />
      ),
      isImportant: true
    })
  }

  rows.push({
    key: "reference",
    node: (
      <ReferenceRow reference={props.payInDetails?.bankTransfer.reference}/>
    ),
    isImportant: false
  })


  return (
    <>
      <BankAccountSectionRows
        bankAccount={props.payInDetails?.bankTransfer.bankAccount}
        includeCurrency={false}
        withCopy={true}
        onlyImportant={props.onlyImportant}
      />
      {rows.map(item => (
        <Collapsable key={item.key} expanded={!props.onlyImportant || item.isImportant}>
          {item.node}
        </Collapsable>
      ))}
    </>
  )
}

export default PayInDetailsSectionRows;