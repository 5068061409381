import {TitleSubtitleScreen} from "../../ui/screen/TitleSubtitleScreen";
import AtlanticQrCode from "../../ui/AtlanticQrCode";
import ButtonBar from "../../ui/ButtonBar";
import {Button} from "../../ui/Buttons";
import {useCopyToClipboard} from "../../../helpers/hooks";
import {VerificationProps} from "../verification-controller";
import {useStrings} from "../../../strings";
import React from "react";

type Props = VerificationProps

const SelfieIdQrCodeStep = ({onComplete, ...props}: Props) => {
  const strings = useStrings()
  const [_, setCopiedText] = useCopyToClipboard()

  const shareUrl = props.verification.shareUrl

  if (shareUrl === undefined) {
    throw new Error("VerificationSelfieIdStepIntro: shareUrl is undefined")
  }

  return (
    <TitleSubtitleScreen titleAlign={props.titleAlign} title={strings["selfie.intro.title"]}>
      <AtlanticQrCode className={"mx-auto"} size={"big"} url={shareUrl}/>
      <ButtonBar className={"mt-[24px]"} align={"center"}>
        <Button type={"button"}
                title={strings["general.copy_link"]}
                size={"big"}
                color={"secondary"}
                onClickAsync={() => setCopiedText(shareUrl)}
        />
        <Button className={"mt-[4px]"} type={"button"}
                title={strings["verification.finished"]}
                size={"big"}
                color={"primary-black"}
                onClickAsync={onComplete}
        />
      </ButtonBar>
    </TitleSubtitleScreen>
  )
}

export default SelfieIdQrCodeStep