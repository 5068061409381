import {atom, RecoilState, useRecoilState, useRecoilValue} from "recoil";
import {recoilPersist} from "recoil-persist";
import {AccessToken} from "../api/whales/Auth";
import {UserType} from "../api/whales/Common";
import {useUserDefaultsState} from "./user-defaults";
import {useQueryClient} from "react-query";
import {useEstimateInputState} from "./estimate-input";
import * as SentryBrowser from "@sentry/browser"

export class User {
  constructor(
    readonly userId: string,
    readonly email: string,
    readonly region: string,
    readonly countryCode: string,
    readonly userType: UserType,
  ) {
  }
}

export type AuthNotAuthenticatedState = {
  authenticated: false
}

export type AuthAuthenticatedState = {
  authenticated: true;
  user: User;
  token: AccessToken;
}

export type AuthState = AuthNotAuthenticatedState | AuthAuthenticatedState

const {persistAtom} = recoilPersist({key: "atlantic-auth-state"})

export const authStateAtom: RecoilState<AuthState> = atom({
  key: 'authState',
  default: {authenticated: false},
  effects: [persistAtom]
})

export const useAuthValue = () => {
  return useRecoilValue(authStateAtom)
}

export const useUserType = () => {
  const authValue = useAuthValue()
  return authValue.authenticated ? authValue.user.userType : undefined
}

export const useAuthActions = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [, setAuthState] = useRecoilState(authStateAtom)
  const queryClient = useQueryClient()
  const [, setUserDefaults] = useUserDefaultsState()
  const [, setEstimateInputState] = useEstimateInputState()

  return {
    login: (token: AccessToken, user: User) => {
      SentryBrowser.setUser({id: user.userId})
      setAuthState((prev) => ({
        ...prev,
        authenticated: true,
        user: user,
        token: token
      }))
    },
    updateTokens(token: AccessToken) {
      setAuthState((prev) => ({
        ...prev,
        token: token
      }))
    },
    logout: () => {
      SentryBrowser.setUser(null)
      queryClient.clear()
      setUserDefaults({lastViewedEntryMode: "source"})
      setEstimateInputState(undefined)
      setAuthState((_prev) => ({authenticated: false}))
    }
  }
}

export const useAuthorizedAuthValue = (): AuthAuthenticatedState => {
  const authValue = useAuthValue()
  if (!authValue.authenticated) {
    throw new Error("Not authenticated")
  }
  return authValue
}
