import Caption from "../../Caption";
import React, {PropsWithChildren} from "react";
import AbstractRow, {RowModifiersProps} from "./AbstractRow";
import cx from "classnames";
import "./InfoRow.scss"
import {PropsClassName} from "../../../../helpers/props";
import {WithSkeletonFlag} from "../../Skeleton";

export type Props = {
  caption: React.ReactNode
  content: React.ReactNode
  explanation?: React.ReactNode
  actionButton?: React.ReactNode
  isLoading?: boolean
} & PropsWithChildren & RowModifiersProps & PropsClassName

const InfoRow = (props: Props) => {
  const captionContentStack = (
    <div className={"info-row__caption-content-stack"}>
      <Caption size={"3"} color={"primary-50"} text={
        <WithSkeletonFlag minWidth={"150px"}  isLoading={props.isLoading ?? false} children={props.caption}/>
      }/>
      <Caption size={"2"} text={
        <WithSkeletonFlag minWidth={"300px"} isLoading={props.isLoading ?? false} children={props.content}/>
      }/>
    </div>
  )

  const horizontalStack = (
    <div className={"info-row__horizontal-stack"}>
      {captionContentStack}
      {props.actionButton && (
        <div className={"info-row__action-button"}>
          <Caption size={"3-title"} text={
            <WithSkeletonFlag minWidth={"75px"} isLoading={props.isLoading ?? false} children={props.actionButton}/>
          }/>
        </div>
      )}
    </div>
  )

  return (
    <AbstractRow {...props} className={cx(props.className, "info-row")}>
        {horizontalStack}
        {props.explanation &&
            <Caption className={"info-row__explanation"} size={"3"} color={"primary-50"} text={props.explanation}/>}
    </AbstractRow>
  )
}

export default InfoRow;
