import {Money} from "../../../helpers/money";
import FormattedMoney from "../../formatters/money/FormattedMoney";
import {TransferDisplaying} from "../../../models/transfers/TransferDisplaying";

type Props = {
  discounted?: Money
  original: Money
}

export const MoneyWithDiscount = ({discounted, original}: Props) => {
  return (
    <>
      <FormattedMoney value={discounted ?? original}/>
      {discounted && (<>
          {" "}
          <span className={"line-through"}><FormattedMoney value={original}/></span>
        </>
      )}
    </>
  )
}

export const DiscountedFixedFee = ({displaying}: { displaying: TransferDisplaying }) => {
  return <MoneyWithDiscount
    original={displaying.fixedFeeWithoutPromo}
    discounted={displaying.isFixedFeeDiscounted ? displaying.fixedFee : undefined}
  />
}

export const DiscountedTotalFee = ({displaying}: { displaying: TransferDisplaying }) => {
  return <MoneyWithDiscount
    original={displaying.totalFeeWithoutPromo}
    discounted={displaying.isFixedFeeDiscounted ? displaying.totalFee : undefined}
  />
}
