import * as VerificationModels from "../../../api/whales/VerificationCommon";
import {InterfaceTemplate, InterfaceTemplates} from "../interface-template/domain";

export type VerificationStatus = VerificationModels.VerificationStatusV2

export type VerificationType = VerificationModels.VerificationType

export class VerificationTemplate {
  verificationType: VerificationType
  interfaceTemplate?: InterfaceTemplate

  constructor(other: { verificationType: VerificationType, interfaceTemplate?: InterfaceTemplate }) {
    this.verificationType = other.verificationType
    this.interfaceTemplate = other.interfaceTemplate
  }

  static fromApi(other: VerificationModels.VerificationTemplate): VerificationTemplate {
    return new VerificationTemplate({
      verificationType: other.verificationType!,
      interfaceTemplate: other.interfaceTemplate === undefined ? undefined : new InterfaceTemplate(other.interfaceTemplate)
    })

  }
}

export class Verification {
  id: string
  verificationType: VerificationType
  status: VerificationStatus
  template: VerificationTemplate
  shareUrl?: string
  ticket?: string

  constructor(other: {
    id: string,
    verificationType: VerificationType,
    status: VerificationStatus,
    template: VerificationTemplate,
    shareUrl?: string
    ticket?: string
  }) {
    this.id = other.id
    this.verificationType = other.verificationType
    this.status = other.status
    this.template = other.template
    this.shareUrl = other.shareUrl
    this.ticket = other.ticket
  }

  withTicket(ticket: string): Verification {
    return new Verification({
      ...this,
      ticket
    })
  }

  isUserInputRequired(): boolean {
    return this.status === "input-pending" && this.template.interfaceTemplate !== undefined
  }

  isInManualReview(): boolean {
    return this.status === "manual-review"
  }

  documentTemplateIfBusiness(): InterfaceTemplates.Document | undefined {
    const it = this.template.interfaceTemplate
    if (it?.businessInfoTemplate?.document) return it.businessInfoTemplate.document
    if (it?.businessKeyPeopleTemplate?.document) return it.businessKeyPeopleTemplate.document
    return undefined
  }

  static fromApi(other: VerificationModels.Verification): Verification {
    return new Verification({
      id: other.verificationId,
      verificationType: other.verificationType!,
      status: other.statusV2!,
      template: VerificationTemplate.fromApi(other.template),
      shareUrl: other.shareUrl
    })
  }
}

export class VerificationsExt {
  static isUserInputRequired(verifications: Verification[]): boolean {
    return verifications.some((v) => v.isUserInputRequired())
  }

  static isInManualReview(vs: Verification[]): boolean {
    return vs.some((v) => v.isInManualReview())
  }
}
