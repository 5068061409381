import {InputCapitalization} from "../../../ui/input/Inputs";

export function compose<A, B, C>(f: (b: B) => C, g: (a: A) => B): (a: A) => C {
  return (a: A) => f(g(a))
}

export type CommonInterfaceTemplateProps<T, V> = {
  template: T,
  value: V,
  onChange: (value: V) => void
  onValidate?: (value: V) => Promise<any>
  isLoading?: boolean,
  disabled?: boolean,
  capitalization?: InputCapitalization
  error: any
}

