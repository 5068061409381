import React, {CSSProperties, PropsWithChildren, useMemo} from "react";
import cx from "classnames";
import "./Limiter.scss"

const widthStyle = {
    "normal": "580px",
    "form-narrow": "460px",
}

type LimiterWithStyle = keyof typeof widthStyle

type Props = {
    className?: string
    contentMaxWidth?: string
    width?: LimiterWithStyle
    as?: React.ElementType
} & React.PropsWithChildren

const Limiter = (props: Props) => {
    const mw = props.contentMaxWidth ?? widthStyle[props.width ?? "normal"]
    const As = props.as ?? "div"

    const style: CSSProperties = useMemo(() => ({
            "--limiter-mw": mw,
        }),
        [mw]
    )
    return (
        <As className={cx("limiter", props.className, "mx-auto", "flex", "flex-col", "flex-1")} style={style}>
            {props.children}
        </As>
    )
}

export const LimiterOverflow = (props: {mw?: string, overflow: string} & PropsWithChildren) => {
    return (
      <div className={"relative"} style={{left: `-${props.overflow}`, width: `calc(${props.mw ?? "100%"} + 2 * ${props.overflow})`}}>
          {props.children}
      </div>
    )
}

export default Limiter