import * as TransferApiV3 from "../../api/whales/Transfer.v3";
import * as TransferCommon from "../../api/whales/TransferCommon";
import {Currency, Money} from "../../helpers/money";
import Decimal from "decimal.js";
import {StringsObj} from "../../strings";
import React from "react";
import _ from "lodash";
import {useUserDefaultsValue} from "../../atoms/user-defaults";
import {useAuthValue} from "../../atoms/auth";
import {Country} from "../../helpers/countries";
import {Check} from "../../components/domain/common/check";

export type EntryMode = TransferCommon.EntryMode

export type DeliveryOptionKind = "standard" | "expedited"

interface IDeliveryOption {
  readonly kind: DeliveryOptionKind
  readonly deliveryDate: Date
  readonly fee: Money
  readonly disabled: boolean
  readonly rate: Decimal
  readonly isInstant: boolean
}

export class DeliveryOption {
  readonly kind: DeliveryOptionKind
  readonly deliveryDate: Date
  readonly fee: Money
  readonly disabled: boolean
  readonly rate
  readonly isInstant: boolean

  constructor(d: IDeliveryOption) {
    this.kind = d.kind
    this.deliveryDate = d.deliveryDate
    this.fee = d.fee
    this.disabled = d.disabled
    this.rate = d.rate
    this.isInstant = d.isInstant
  }

  static fromApi(d: TransferCommon.DeliveryOption): DeliveryOption {
    return new DeliveryOption({
      kind: d.type === "instant" ? "expedited" : d.type,
      deliveryDate: new Date(d.deliveryDate),
      fee: Money.fromApi(d.fee),
      disabled: d.disabled,
      rate: new Decimal(d.rate ?? 0),
      isInstant: d.isInstant
    })
  }

  toApi(): TransferCommon.DeliveryOption {
    return {
      type: this.kind,
      deliveryDate: this.deliveryDate.toISOString(),
      fee: this.fee.toApi(),
      disabled: this.disabled,
      rate: this.rate.toString(),
      isInstant: this.isInstant,
    }
  }

  get isFree(): boolean {
    return this.fee.amount.eq(0) && this.rate.eq(0)
  }
}

interface IQuoteOption {
  readonly kind: TransferCommon.QuoteOption["type"]
  readonly fee: Money
  readonly disabled: boolean
  readonly disabledExplanation?: string
  readonly rate: Decimal
}

export class QuoteOption {
  readonly kind: TransferCommon.QuoteOption["type"]
  readonly fee: Money
  readonly disabled: boolean
  readonly disabledExplanation?: string
  readonly rate: Decimal

  constructor(q: IQuoteOption) {
    this.kind = q.kind
    this.fee = q.fee
    this.disabled = q.disabled
    this.disabledExplanation = q.disabledExplanation
    this.rate = q.rate
  }

  static fromApi(q: TransferCommon.QuoteOption): QuoteOption {
    return new QuoteOption({
      kind: q.type,
      fee: Money.fromApi(q.fee),
      disabled: q.disabled,
      disabledExplanation: q.disabledExplanation,
      rate: new Decimal(q.rate ?? 0),
    })
  }

  toApi() {
    return {
      type: this.kind,
      fee: this.fee.toApi(),
      disabled: this.disabled,
      disabledExplanation: this.disabledExplanation,
      rate: this.rate.toString(),
    }
  }
}

interface IQuote {
  readonly kind: TransferCommon.Quote["type"]
  readonly rate: Decimal
  readonly expiresAt?: Date
}

export class Quote implements IQuote {
  readonly kind: TransferCommon.Quote["type"]
  readonly rate: Decimal
  readonly expiresAt?: Date

  constructor(q: IQuote) {
    this.kind = q.kind
    this.rate = q.rate
    this.expiresAt = q.expiresAt
  }

  static fromApi(q: TransferCommon.Quote): Quote {
    return new Quote({
      kind: q.type,
      rate: new Decimal(q.rate),
      expiresAt: q.expiresAt ? new Date(q.expiresAt) : undefined,
    })
  }

  toApi(): TransferCommon.Quote {
    return {
      type: this.kind,
      rate: this.rate.toString(),
      expiresAt: this.expiresAt?.toISOString(),
    }
  }
}

export namespace Quote {
  export interface IIsFrozen {
    readonly kind: "not-frozen" | "frozen-with-expiry" | "frozen-because-traded"
    readonly boolean: boolean
  }

  export class NotFrozen implements IIsFrozen {
    readonly kind: "not-frozen" = "not-frozen"

    get boolean(): boolean {
      return false
    }
  }

  export class Frozen implements IIsFrozen {
    readonly kind: "frozen-with-expiry" = "frozen-with-expiry"
    readonly expiry: Date

    constructor(expiry: Date) {
      this.expiry = expiry
    }

    get boolean(): boolean {
      return true
    }
  }

  export class FrozenBecauseTraded implements IIsFrozen {
    readonly kind: "frozen-because-traded" = "frozen-because-traded"

    get boolean(): boolean {
      return true
    }
  }

  export type IsFrozen = NotFrozen | Frozen | FrozenBecauseTraded
}

interface IEstimate {
  readonly entryMode: TransferCommon.EntryMode
  readonly sourceMoney: Money
  readonly destinationMoney: Money
  readonly totalFee: Money
  readonly fixedFee: Money
  readonly fixedFeeWithoutPromo: Money
  readonly quote: Quote
  readonly deliveryOption: DeliveryOption
  readonly quoteOption: QuoteOption
  readonly didCustomerChooseQuoteOption: boolean
  readonly promo?: EstimatePromo
  readonly warnAboutBankLimits: boolean
  readonly checks: Check[]
}

interface IEstimatePromo {
  readonly id?: string;
  readonly fixedFeeReason?: TransferCommon.EstimatePromo["fixedFeeReason"]
}

export class EstimatePromo implements IEstimatePromo {
  readonly id?: string;
  readonly fixedFeeReason?: TransferCommon.EstimatePromo["fixedFeeReason"]

  constructor(other: IEstimatePromo) {
    this.id = other.id
    this.fixedFeeReason = other.fixedFeeReason
  }

  fixedFeeExplanation(strings: StringsObj): React.ReactNode {
    switch (this.fixedFeeReason) {
      case "first-transfer":
        return strings["transfer_promo.first_transfer.explanation"]
      case "referral":
        return strings["transfer_promo.referral.explanation"]
      default:
        return strings["transfer_promo.generic.explanation"]
    }
  }

  static fromApi(e: TransferCommon.EstimatePromo): EstimatePromo {
    return new EstimatePromo({
      id: e.id,
      fixedFeeReason: e.fixedFeeReason,
    })
  }
}

interface ICurrencyAvailability {
  readonly sourceCurrencies: Currency[]
  readonly destinationCurrencies: Currency[]
  readonly sourceEntryCurrencies: Currency[]
  readonly destinationEntryCurrencies: Currency[]
}

export class CurrencyAvailability {
  readonly sourceCurrencies: Currency[]
  readonly destinationCurrencies: Currency[]
  readonly sourceEntryCurrencies: Currency[]
  readonly destinationEntryCurrencies: Currency[]

  constructor(other: ICurrencyAvailability) {
    this.sourceCurrencies = other.sourceCurrencies
    this.destinationCurrencies = other.destinationCurrencies
    this.sourceEntryCurrencies = other.sourceEntryCurrencies
    this.destinationEntryCurrencies = other.destinationEntryCurrencies
  }

  allCurrencies(): Currency[] {
    return _.uniqBy([...this.sourceCurrencies, ...this.destinationCurrencies], (c) => c.code)
  }

  availableEntryModes(source: Currency, destination: Currency): EntryMode[] {
    const result: EntryMode[] = []
    if (this.sourceEntryCurrencies.find(c => c.eq(source))) {
      result.push("source")
    }
    if (this.destinationEntryCurrencies.find(c => c.eq(destination))) {
      result.push("destination")
    }
    return result
  }

  static fromAPI(c: TransferCommon.AvailableCurrencies): CurrencyAvailability {
    return new CurrencyAvailability({
      sourceCurrencies: c.sourceCurrencies.map(Currency.fromApi),
      destinationCurrencies: c.destinationCurrencies.map(Currency.fromApi),
      sourceEntryCurrencies: c.sourceEntryCurrencies.map(Currency.fromApi),
      destinationEntryCurrencies: c.destinationEntryCurrencies.map(Currency.fromApi),
    })
  }

  static useOperations = () => {
    const authValue = useAuthValue()
    const userDefaults = useUserDefaultsValue()

    return (availability: CurrencyAvailability) => {
      const lastViewedSourceCurrencyIfSupported = function () {
        if (userDefaults.lastViewedSourceCurrency === undefined) return undefined
        return availability.sourceCurrencies.find(c =>
          userDefaults.lastViewedSourceCurrency && c.eq(userDefaults.lastViewedSourceCurrency)
        )
      }()

      const nativeSourceCurrencyIfSupported = function () {
        if (!authValue.authenticated) return undefined
        return availability.sourceCurrencies.find(c =>
          new Country(authValue.user?.countryCode).nativeCurrency()?.eq(c)
        )
      }()

      const preferredSourceCurrency = lastViewedSourceCurrencyIfSupported
        ?? nativeSourceCurrencyIfSupported
        ?? availability.sourceCurrencies[0]

      const lastViewedDestinationCurrencyIfSupported = function () {
        if (!userDefaults.lastViewedDestinationCurrency === undefined) return undefined
        return availability.destinationCurrencies.find(c =>
          userDefaults.lastViewedDestinationCurrency && c.eq(userDefaults.lastViewedDestinationCurrency)
        )
      }()

      const preferredDestinationCurrency = lastViewedDestinationCurrencyIfSupported ?? availability.destinationCurrencies[0]

      const preferredEntryMode = (source: Currency, destination: Currency): EntryMode => {
        const availableEntryModes = availability.availableEntryModes(source, destination)
        return availableEntryModes.includes(userDefaults.lastViewedEntryMode)
          ? userDefaults.lastViewedEntryMode
          : availableEntryModes[0]
      }

      return {
        lastViewedSourceCurrencyIfSupported,
        nativeSourceCurrencyIfSupported,
        preferredSourceCurrency,
        lastViewedDestinationCurrencyIfSupported,
        preferredDestinationCurrency,
        preferredEntryMode,
      }
    }
  }
}

export class Estimate implements IEstimate {
  readonly deliveryOption: DeliveryOption;
  readonly destinationMoney: Money;
  readonly didCustomerChooseQuoteOption: boolean;
  readonly entryMode: TransferCommon.EntryMode;
  readonly fixedFee: Money;
  readonly fixedFeeWithoutPromo: Money;
  readonly promo?: EstimatePromo;
  readonly quote: Quote;
  readonly quoteOption: QuoteOption;
  readonly sourceMoney: Money;
  readonly totalFee: Money;
  readonly warnAboutBankLimits: boolean;
  readonly checks: Check[]

  constructor(other: IEstimate) {
    this.entryMode = other.entryMode
    this.sourceMoney = other.sourceMoney
    this.destinationMoney = other.destinationMoney
    this.totalFee = other.totalFee
    this.fixedFee = other.fixedFee
    this.fixedFeeWithoutPromo = other.fixedFeeWithoutPromo
    this.quote = other.quote
    this.deliveryOption = other.deliveryOption
    this.quoteOption = other.quoteOption
    this.didCustomerChooseQuoteOption = other.didCustomerChooseQuoteOption
    this.promo = other.promo
    this.warnAboutBankLimits = other.warnAboutBankLimits
    this.checks = other.checks
  }

  updated(other: Partial<IEstimate>): Estimate {
    return new Estimate({...this, ...other,})
  }

  toApi(): TransferApiV3.SingleTransferEstimate {
    return {
      entryMode: this.entryMode,
      sourceMoney: this.sourceMoney.toApi(),
      destinationMoney: this.destinationMoney.toApi(),
      totalFee: this.totalFee.toApi(),
      fixedFee: this.fixedFee.toApi(),
      fixedFeeWithoutPromo: this.fixedFeeWithoutPromo.toApi(),
      quote: this.quote.toApi(),
      deliveryOption: this.deliveryOption.toApi(),
      quoteOption: this.quoteOption.toApi(),
      didCustomerChooseQuoteOption: this.didCustomerChooseQuoteOption,
      promo: this.promo,
      warnAboutBankLimits: this.warnAboutBankLimits,
    }
  }

  static fromApi(e: TransferApiV3.SingleTransferEstimate): Estimate {
    return new Estimate({
      entryMode: e.entryMode,
      sourceMoney: Money.fromApi(e.sourceMoney),
      destinationMoney: Money.fromApi(e.destinationMoney),
      totalFee: Money.fromApi(e.totalFee),
      fixedFee: Money.fromApi(e.fixedFee),
      fixedFeeWithoutPromo: Money.fromApi(e.fixedFeeWithoutPromo),
      quote: Quote.fromApi(e.quote),
      deliveryOption: DeliveryOption.fromApi(e.deliveryOption),
      quoteOption: QuoteOption.fromApi(e.quoteOption),
      didCustomerChooseQuoteOption: e.didCustomerChooseQuoteOption,
      promo: e.promo && EstimatePromo.fromApi(e.promo),
      warnAboutBankLimits: e.warnAboutBankLimits ?? false,
      checks: (e?.checks ?? []).map(Check.fromApiEstimate),
    })
  }

  get disabled(): boolean {
    return this.deliveryOption.disabled || this.quoteOption.disabled
  }
}
