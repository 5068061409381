import * as CommonModels from "../../../api/whales/Common";
import * as TransferCommon from "../../../api/whales/TransferCommon";
import {Verification} from "../verifications/domain";
import {DisclosureKind} from "../../../api/whales/Common";

export type CheckType = CommonModels.CheckType

export type CheckStatus = CommonModels.CheckStatus

type Meta = {
  kind: DisclosureKind
}

export class Check {
  type: CheckType
  status: CheckStatus
  rejectedExplanation?: string
  verifications?: Verification[]
  meta?: Meta

  constructor(other: {
    type: CheckType
    status: CheckStatus
    rejectedExplanation?: string
    verifications?: Verification[]
    meta?: Meta
  }) {
    this.type = other.type
    this.status = other.status
    this.rejectedExplanation = other.rejectedExplanation
    this.verifications = other.verifications
    this.meta = other.meta
  }

  isUserInputRequired(): boolean {
    switch (this.type) {
      case "transfer_nexus_links_active":
        return false
      case "email_confirmed":
        return this.status !== "accepted"
      case "business_info":
      case "business_key_people":
      case "business_applicant_relationship":
      default:
        return this.verifications?.some(verification => verification.isUserInputRequired()) ?? false
    }
  }

  isInManualReview(): boolean {
    switch (this.type) {
      case "transfer_nexus_links_active":
        return false
      case "email_confirmed":
        return false
      case "business_info":
      case "business_key_people":
      case "business_applicant_relationship":
      default:
        return this.verifications?.some(verification => verification.isInManualReview()) ?? false
    }
  }

  toApiEstimate(): TransferCommon.EstimateCheck {
    return {
      type: this.type,
      status: this.status,
    } as TransferCommon.EstimateCheck
  }

  static fromApiCommon(other: CommonModels.Check): Check {
    return new Check({
      type: other.type,
      status: other.status,
      rejectedExplanation: other.rejectedExplanation,
      verifications: other.verifications?.map(Verification.fromApi)
    })
  }

  static fromApiEstimate(other: TransferCommon.EstimateCheck): Check {
    return new Check({
      type: other.type,
      status: other.status,
      meta: other.meta as Meta
    })
  }
}

export class EntityChecksExt {
  static isUserInputRequired(checks: Check[]):boolean {
    return checks.some((c) => c.isUserInputRequired())
  }
}