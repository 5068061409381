import {withParametricRouter} from "../../routes/parameteric-router";
import Navigate from "../../routes/router-dom-wrapper/Navigate";
import {Links} from "../../helpers/Links";

const DeeplinkVerificationPage = withParametricRouter({
  requiredQueryKeys: ["id"],
  optionalQueryKeys: ["ticket", "public"]
})((props) => {
  return (
    <Navigate
      to={Links.atlantic.verification({verificationId: props.id, ticket: props.ticket})}
      replace={true}
      isBackAllowed={false}/>
  )
})

export default DeeplinkVerificationPage