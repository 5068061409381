import {
  Location,
  NavigateOptions,
  To,
  useLocation as useLocationLib,
  // eslint-disable-next-line no-restricted-imports
  useNavigate as useNavigateLib
} from "react-router-dom";
import {useCallback} from "react";

export type CommonRouteState = {
  previousLocation?: Location
  postAuthRedirectLocation?: Location
  isBackAllowed?: boolean
}

export const useLocation = <T extends any = {}>() => {
  const location = useLocationLib();

  return {...location, state: location.state as (T & CommonRouteState) | undefined}
}

export type NavigateExtraOptions = {
  isBackAllowed?: boolean
  openLocationAfterAuth?: boolean
  state?: any
}

export const useNextCommonRouteState = () => {
  const location = useLocation()
  return useCallback((options?: NavigateExtraOptions): CommonRouteState => {
    return {
      ...location.state,
      ...options?.state,
      previousLocation: location,
      isBackAllowed: options?.isBackAllowed ?? true,
      postAuthRedirectLocation: options?.openLocationAfterAuth
        ? location
        : location.state?.postAuthRedirectLocation
    }
  }, [location])
}

export const useNavigate = (): ((to: To, options?: NavigateOptions & NavigateExtraOptions) => void) => {
  const f = useNavigateLib()
  const nextCommonRouteState = useNextCommonRouteState()
  return useCallback((to, options) => {
    return f(to, {...options, state: nextCommonRouteState(options)})
  }, [f, nextCommonRouteState])
}

export const useRouterNavigateBack = () => {
  const navigate = useNavigateLib();
  const location = useLocation()

  const prevLocation = location.state?.isBackAllowed
    ? location.state.previousLocation
    : undefined

  return prevLocation ? () => navigate(prevLocation) : undefined
}