import React, {ReactPortal, RefCallback, useCallback, useMemo, useRef, useState} from "react";
import ReactDOM from "react-dom"

const measureLayerId = "atlantic-measure-layer";

export const MeasureLayer = () => {
  return <div id={measureLayerId} className={"inline-block absolute -z-10 invisible"}/>
}

type Size = { width: number, height: number }

type State = Record<string, { portal?: ReactPortal }>

type MeasureCtx = ReturnType<typeof useMeasure>

export const useMeasure = () => {
  const measureLayer = useMemo(() => document.getElementById(measureLayerId)!, [])
  const [state, setState] = useState<State>({})
  const sizeCache = useRef(new Map<string, Size>())
  const promiseCache = useRef(new (Map<string, Promise<Size>>)())

  const measure = useCallback((element: (ref: React.RefCallback<HTMLElement>) => React.ReactNode, key: string): Promise<Size> => {
    if (promiseCache.current.has(key)) {
      return promiseCache.current.get(key)!
    }

    const p = new Promise<Size>((resolve, reject) => {
      const cached = sizeCache.current.get(key)
      if (cached) {
        return resolve(cached)
      }

      /*
       * Portals allow us to render an element inside a given DOM node.
       * In this case we're rendering the element we want to measure inside
       * the measureLayer located in the App root.
       */

      const measureContainer = document.createElement("div");
      measureContainer.style.position = "absolute";
      measureContainer.style.display = "inline-block";
      measureLayer.appendChild(measureContainer);

      const measureCb: React.RefCallback<HTMLElement> = (el) => {
        const cached = sizeCache.current.get(key)
        if (cached) {
          return resolve(cached)
        }
        if (!el) {
          return reject(Error("No element"))
        }
        const height = el.clientHeight
        const width = el.clientWidth
        const size = {height, width}

        if (!cached) {
          sizeCache.current.set(key, size)
        }

        const result = cached ?? size
        setState((prev) => ({
          ...prev, [key]: {
            portal: undefined,
          }
        }))
        measureLayer.removeChild(measureContainer);
        resolve(result)
      }

      const portal = ReactDOM.createPortal(element(measureCb), measureContainer, key);
      setState((prev) => ({
        ...prev, [key]: prev[key] ?? {portal}
      }))
    })
    promiseCache.current.set(key, p)
    return p
  }, [measureLayer])

  const portals = Object.values(state).flatMap(v => v.portal ? [v.portal] : [])
  return {measure, portals}
}

export const withMeasure = <T, >(inner: (props: T, measure: MeasureCtx["measure"]) => React.ReactElement): React.FC<T> => {
  return (props: T) => {
    const measureCtx = useMeasure()
    return <>
      {measureCtx.portals}
      {inner(props, measureCtx.measure)}
    </>
  }
}