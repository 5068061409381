import {useEffect, useState} from "react"
import {QueryFunction, QueryKey, useQuery, UseQueryOptions, UseQueryResult} from "react-query";

export const usePromiseValue = <T, >(promise: () => Promise<T>) => {
  const [state, setState] = useState<T | undefined>(undefined)

  useEffect(() => {
    promise().then(value => setState(value))
  }, [state, promise])

  return state
}


function _useQueryWithPromise<TQueryFnData = unknown, TError = unknown, TData = TQueryFnData, TQueryKey extends QueryKey = QueryKey>(queryKey: TQueryKey, queryFn: QueryFunction<TQueryFnData, TQueryKey>, options?: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>): UseQueryResult<TData, TError> & {
  promise: Promise<TData>
} {
  let pResolve: (data: TData) => void, pReject: (err: TError) => void
  const promise = new Promise<TData>((resolve, reject) => {
    pResolve = resolve
    pReject = reject
  })
  const updatedOptions = {
    ...options,
    onSuccess: (data: TData) => {
      pResolve(data)
      options?.onSuccess?.(data)
    },
    onError: (err: TError) => {
      pReject(err)
      options?.onError?.(err)
    }
  }

  const q = useQuery(queryKey, queryFn, updatedOptions)

  return {...q, promise}
}