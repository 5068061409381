import {VerificationProps} from "../verification-controller";
import {InterfaceTemplates} from "../../domain/interface-template/domain";
import {useVerificationApi} from "../../../api/verification";
import {VerificationDocumentStepUploadGeneric} from "../document/VerificationDocumentStepUpload";

const TSOFDocumentUploadStep = (props: VerificationProps & {
  template: InterfaceTemplates.TransferSourceOfFunds
  optionId: string
}) => {
  const verificationApi = useVerificationApi()

  const documentTemplate = props.template.documentsTemplates
    && props.template.documentsTemplates[props.optionId]

  if (documentTemplate === undefined) {
    throw new Error("Document template not found")
  }

  return <VerificationDocumentStepUploadGeneric
    {...props}
    titleAlign={props.titleAlign}
    template={documentTemplate}
    onUpload={(files) => verificationApi.submitTransferSourceOfFunds({
      verificationId: props.verification.id,
      sourceOfFunds: props.optionId,
      documents: files
    })}
  />
}

export default TSOFDocumentUploadStep