import {useStrings} from "../../strings";
import FormattedAddress from "../../components/formatters/FormattedAddress";
import React from "react";
import {Person} from "../../models/client/person";
import InfoRow from "../../components/ui/collections/rows/InfoRow";
import FormattedDate from "../../components/formatters/date/FormattedDate";
import FormattedPhone from "../../components/formatters/FormattedPhone";

type Props = {
  person?: Person
  isLoading?: boolean
  phoneActionButton?: React.ReactNode
}

const PersonInfoRows = ({person, ...props}: Props) => {
  const strings = useStrings()
  const isLoading = props.isLoading ?? (person === undefined ?? false)
  const name = person?.name()
  const email = person?.email
  const phone = person?.phone
  const dob = person?.birthdate
  const address = person?.address
  return (
    <>
      {isLoading || name
        ? <InfoRow isLoading={isLoading} caption={strings["account.account_info.name.title"]} content={name}/>
        : undefined}
      {isLoading || email
        ? <InfoRow isLoading={isLoading} caption={strings["account.account_info.email.title"]} content={email}/>
        : undefined
      }
      {isLoading || phone
        ? <InfoRow
          isLoading={isLoading}
          caption={strings["account.account_info.phone_number.title"]}
          content={phone && <FormattedPhone value={phone}/>}
          actionButton={props.phoneActionButton}
        />
        : undefined
      }
      {isLoading || dob
        ? <InfoRow isLoading={isLoading} caption={strings["account.account_info.dob.title"]}
                   content={dob && <FormattedDate style={"Mdyyyy"} value={dob}/>}/>
        : undefined
      }
      {isLoading || address
        ? <InfoRow isLoading={isLoading} caption={strings["account.account_info.address.title"]}
                   content={address && <FormattedAddress style={"one-line"} value={address}/>}/>
        : undefined}
    </>
  )
}

export default PersonInfoRows