import SideIllustrationLayout from "../../ui/layout/unauthorized/SideIllustrationLayout";
import {useEffect} from "react";
import {useOnboardingQuery} from "../../../api/onboarding";
import OnboardingForm from "./OnboardingForm";
import Disclaimer from "../Disclaimer";
import {useAuthorizedAuthValue} from "../../../atoms/auth";
import {TitleSubtitleCenterScreen} from "../../ui/screen/TitleSubtitleScreen";
import OnboardingScreen from "../OnboardingScreen";
import {NavStackRoot} from "../../navigation/NavStack";
import {useSideIllustration} from "../../../atoms/side-illustration";
import {useNavStack} from "../../../atoms/nav-stack";
import {useStrings} from "../../../strings";
import {Links} from "../../../helpers/Links";
import {useNavigate} from "../../../routes/router-dom-wrapper/hooks";
import Navigate from "../../../routes/router-dom-wrapper/Navigate";
import {TrackingServiceManager} from "../../../services/tracking/TrackingService";

const IndividualDisclaimerScreen = (props: { countryCode: string }) => {
  const strings = useStrings()
  const navigate = useNavigate()
  const authState = useAuthorizedAuthValue()
  const onboardingQuery = useOnboardingQuery()
  const useConfirmMutation = onboardingQuery.application.confirm.useMutation(authState.user.userId)
  useSideIllustration("document")

  return (
    <TitleSubtitleCenterScreen title={strings["signup.disclosure.title"]}>
      <Disclaimer countryCode={props.countryCode} userType={"individual"} onContinue={async () => {
        await useConfirmMutation.mutateAsync()
        TrackingServiceManager.instance.onSignUpComplete()
        navigate(Links.atlantic.onboarding.questioner, {replace: true, isBackAllowed: false})
      }}/>
    </TitleSubtitleCenterScreen>
  )
}

const IndividualOnboardingFormScreen = () => {
  const strings = useStrings()
  const authState = useAuthorizedAuthValue()
  const onboardingQuery = useOnboardingQuery()
  const {data: application} = onboardingQuery.application.get.useQuery(authState.user.userId)
  const navStack = useNavStack()

  useSideIllustration("profile-silhouette")

  return (
    <TitleSubtitleCenterScreen title={strings["signup.form.title"]}>
      <OnboardingForm applicationRes={application} onComplete={() => {
        navStack.push(IndividualDisclaimerScreen, {countryCode: authState.user.countryCode})
      }}/>
    </TitleSubtitleCenterScreen>
  )
}

const IndividualOnboardingPage = () => {
  const authState = useAuthorizedAuthValue()
  const onboardingQuery = useOnboardingQuery()
  const {data: application} = onboardingQuery.application.get.useQuery(authState.user.userId, {enabled: authState.user.userType === "individual"})
  const navigate = useNavigate()

  useEffect(() => {
    switch (application?.payload.status) {
      case "new":
      case "checks-pending":
      case undefined:
        break;
      case "accepted":
        navigate(Links.atlantic.onboarding.download, {replace: true, isBackAllowed: false})
    }
  }, [application, navigate])

  switch (authState.user.userType) {
    case "business":
      return <Navigate to={Links.atlantic.onboarding.business} replace={true} isBackAllowed={false}/>
    case "individual":
      return (
        <SideIllustrationLayout>
          <OnboardingScreen>
            <NavStackRoot root={IndividualOnboardingFormScreen} args={{}} ctx={{}}/>
          </OnboardingScreen>
        </SideIllustrationLayout>
      )
  }
}

export default IndividualOnboardingPage;