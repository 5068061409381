import {InterfaceTemplateValues} from "../domain";
import Crossfade from "../../../ui/Crossfade";
import Icon from "../../../ui/icons/Icon";
import React from "react";
import DefaultRow from "../../../ui/collections/rows/DefaultRow";

type Props = {
  option: InterfaceTemplateValues.SingleSelectOption
  selected: boolean
  disabled: boolean
  submitOnClick: boolean
  rightIcon: "check" | "close" | "chevron-right"
  bottomNode?: React.ReactNode
  onRowClick: (option: InterfaceTemplateValues.SingleSelectOption | null) => void
  onSubmit: (ssv: InterfaceTemplateValues.SingleSelect) => Promise<void>
}

const SelectRow = ({option, onSubmit, ...props}: Props) => {
  return (
    <DefaultRow
      title={option.title}
      trailingIcon={<Crossfade className={"h-6 w-6"}>
          {function () {
            switch (props.rightIcon) {
              case "check":
                return <Icon key="check" name={"row-check"} hidden={!props.selected}/>
              case "close":
                return <Icon key="close" name={"button-close"}/>
              case "chevron-right":
                return <Icon key="default" name={"chevron-right"}/>
            }
          }()}
        </Crossfade>
      }
      ignoreClick={(e) => {
        const el = e.target as HTMLElement
        return !!el.closest(".input")
      }}
      onClickAsync={async () => {
        props.onRowClick(props.selected ? null : option)
        if (props.submitOnClick) {
          await onSubmit({
            kind: "singleSelect",
            singleSelectValue: {
              option: option,
              otherText: undefined
            }
          })
        }
      }}
      disabled={props.disabled}
      bottomNode={props.bottomNode}
    />
  )
}

export default SelectRow
