import {DeliveryOptionKind} from "./transfers/Estimate";
import * as TransferModelsV3 from "../api/whales/Transfer.v3";
import Decimal from "decimal.js";

interface IPortalDeliveryOption {
  readonly type: DeliveryOptionKind;
  readonly isInstant: boolean;
  readonly rate: Decimal;
  readonly deliverySpeed: number;
}


export class PortalDeliveryOption implements IPortalDeliveryOption {
  readonly type: DeliveryOptionKind;
  readonly isInstant: boolean;
  readonly rate: Decimal;
  readonly deliverySpeed: number;

  constructor(other: IPortalDeliveryOption) {
    this.type = other.type
    this.isInstant = other.isInstant
    this.rate = other.rate
    this.deliverySpeed = other.deliverySpeed
  }

  isSameDay(): boolean {
    return this.deliverySpeed === 0
  }

  static fromApi(model: TransferModelsV3.PortalDeliveryOption): PortalDeliveryOption {
    return new PortalDeliveryOption({
      type: model.type,
      isInstant: model.isInstant,
      rate: new Decimal(model.rate),
      deliverySpeed: model.deliverySpeed
    })
  }

  static fallback: PortalDeliveryOption = new PortalDeliveryOption({
    type: "standard",
    isInstant: false,
    rate: new Decimal(0),
    deliverySpeed: 0
  })
}

interface IPortalDeliveryInfo {
  currencyCode: string;
  selectedDeliveryType: DeliveryOptionKind;
  availableDeliveryOptions: PortalDeliveryOption[];
}

export class PortalDeliveryInfo implements IPortalDeliveryInfo {
  currencyCode: string;
  selectedDeliveryType: DeliveryOptionKind;
  availableDeliveryOptions: PortalDeliveryOption[];

  constructor(other: IPortalDeliveryInfo) {
    this.currencyCode = other.currencyCode
    this.selectedDeliveryType = other.selectedDeliveryType
    this.availableDeliveryOptions = other.availableDeliveryOptions
  }

  static fromApi(model: TransferModelsV3.PortalDeliveryOptionInfoResponse["payload"]): PortalDeliveryInfo {
    return new PortalDeliveryInfo({
      currencyCode: model.currencyCode,
      selectedDeliveryType: model.selectedDeliveryType,
      availableDeliveryOptions: model.availableDeliveryOptions.map(PortalDeliveryOption.fromApi)
    })
  }

  static fallback: PortalDeliveryInfo = new PortalDeliveryInfo({
    currencyCode: "USD",
    selectedDeliveryType: "standard",
    availableDeliveryOptions: [PortalDeliveryOption.fallback, PortalDeliveryOption.fallback]
  });
}