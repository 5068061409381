import {PropsClassName} from "../../../helpers/props";
import {ReactComponent as InfoCircleSvg} from "./svg/info-circle.svg"
import cx from "classnames";
import "./IconInfoCircle.scss"

type Props = {
  size?: "medium" | "small"
  color?: "primary-black" | "red"
} & PropsClassName

const IconInfoCircle = ({size = "small", color = "primary-black", ...props}: Props) => {
  const sizePx = function () {
    switch (size) {
      case "medium":
        return 24
      case "small":
        return 16
    }
  }()

  return <InfoCircleSvg width={sizePx} height={sizePx} className={cx("info-circle", props.className, {
    "info-circle--primary-black": color === "primary-black",
    "info-circle--red": color === "red",
  })}/>
}

export default IconInfoCircle