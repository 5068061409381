import * as RecipientsAPI from "../../../api/whales/Recipients";
import {BankAccountElementId} from "../../../api/whales/Common";
import {Currency} from "../../../helpers/money";
import {BankAccountElement} from "./domain/BankAccountElement";
import {InterfaceTemplate} from "../interface-template/domain";
import _ from "lodash";
import {PayInDetails} from "../../../models/common/PayInDetails";
import {BankAccount, BankAccountElementTemplate, BankAccountTemplate} from "../../../models/common/BankAccount";


export type PortalStatus = RecipientsAPI.Portal["status"]

export interface IPortal {
  status: PortalStatus
  payInDetails: PayInDetails
}

export class Portal implements IPortal {
  readonly status: PortalStatus
  readonly payInDetails: PayInDetails

  constructor(other: IPortal) {
    this.status = other.status
    this.payInDetails = other.payInDetails
  }

  isReady(): boolean {
    return this.status === "ready"
  }

  static fromApi(portal: RecipientsAPI.Portal): Portal {
    return new Portal({
      status: portal.status,
      payInDetails: PayInDetails.fromApi(portal.payInDetails)
    })
  }
}

interface IRecipient {
  readonly recipientId: string
  readonly type: RecipientsAPI.RecipientType
  readonly entityType: RecipientsAPI.EntityType
  readonly recipientCode: string
  readonly status: RecipientsAPI.Recipient["status"]
  readonly createdAt: Date
  readonly gradientColors: string[]
  readonly last4?: string
  readonly bankAccount: BankAccount
  readonly checks: RecipientsAPI.Recipient["checks"]
  readonly portals: Portal[]
}

export class Recipient implements IRecipient {
  readonly recipientId: string
  readonly type: RecipientsAPI.RecipientType
  readonly entityType: RecipientsAPI.EntityType
  readonly recipientCode: string
  readonly status: RecipientsAPI.Recipient["status"]
  readonly createdAt: Date
  readonly gradientColors: string[]
  readonly last4?: string
  readonly bankAccount: BankAccount
  readonly checks: RecipientsAPI.Recipient["checks"]
  readonly portals: Portal[]

  constructor(other: IRecipient) {
    this.recipientId = other.recipientId
    this.type = other.type
    this.entityType = other.entityType
    this.recipientCode = other.recipientCode
    this.status = other.status
    this.createdAt = other.createdAt
    this.gradientColors = other.gradientColors
    this.last4 = other.last4
    this.bankAccount = other.bankAccount
    this.checks = other.checks
    this.portals = other.portals
  }

  get holderName(): string | undefined {
    return this.bankAccount.holderName
  }

  get displayName(): string | undefined {
    switch (this.type) {
      case "own":
        return this.bankAccount.bankName ?? this.last4;
      default:
        return this.holderName ?? this.bankAccount.bankName ?? this.last4
    }
  }

  get avatarLabel(): string | undefined {
    switch (this.type) {
      case "own":
        return _.head(this.displayName)?.toUpperCase()
      default:
        const components = this.displayName?.split(" ") || []
        if (components.length === 0) {
          return undefined
        } else if (components.length === 1) {
          return _.head(components[0])?.toUpperCase()
        } else {
          const firstComp = _.head(components)
          const lastComp = _.last(components)

          const firstChar = _.head(firstComp) ?? ""
          const lastChar = _.head(lastComp) ?? ""
          return (firstChar + lastChar).toUpperCase()
        }
    }
  }

  static fromApi(recipient: RecipientsAPI.Recipient): Recipient {
    return new Recipient({
      recipientId: recipient.recipientId,
      type: recipient.type,
      entityType: recipient.entityType,
      recipientCode: recipient.recipientCode,
      status: recipient.status,
      createdAt: new Date(recipient.createdAt),
      gradientColors: recipient.gradientColors,
      last4: recipient.last4,
      bankAccount: BankAccount.fromApi(recipient.bankAccount),
      checks: recipient.checks,
      portals: recipient.portals?.map(p => Portal.fromApi(p)) ?? []
    })
  }

  static fallback = function () {
    const elementIds: BankAccountElementId[] = ["holderName", "accountNumber", "routingNumber", "address"]

    const bankAccountTemplate = new BankAccountTemplate({
      currencyCode: "USD",
      elements: elementIds.map((elementId) => new BankAccountElementTemplate({
        identifier: elementId,
        elementName: elementId,
        interfaceTemplate: InterfaceTemplate.fallbackTextTemplate(),
        isImportant: false,
      }))
    });
    return new Recipient({
      recipientId: "00000000-0000-0000-0000-000000000000",
      type: "own",
      entityType: "individual",
      recipientCode: "fallback",
      status: "verified",
      createdAt: new Date(),
      gradientColors: ["#000000", "#FFFFFF"],
      bankAccount: new BankAccount({
        currency: Currency.default("USD"),
        template: bankAccountTemplate,
        elements: elementIds.map((elementId) => BankAccountElement.fallbackValue(elementId, bankAccountTemplate))
      }),
      checks: [],
      portals: []
    })
  }()
}