import {BankAccount} from "./BankAccount";
import * as CommonModels from "../../api/whales/Common";

type IPayInDetails = {
  bankTransfer: {
    bankAccount: BankAccount;
    /** Filtered by country and features list of actual openbanking options */
    // openBankingInstitutions?: Institution[];
    reference?: string
  };
}

export class PayInDetails implements IPayInDetails {
  bankTransfer: {
    bankAccount: BankAccount;
    reference?: string;
  };

  constructor(other: IPayInDetails) {
    this.bankTransfer = other.bankTransfer;
  }

  static fromApi(other: CommonModels.PayInDetails) {
    return new PayInDetails({
      bankTransfer: {
        bankAccount: BankAccount.fromApi(other.bankTransfer.bankAccount),
        reference: other.bankTransfer.reference,
      },
    });
  }
}
