import React, {useState} from "react";
import {Button} from "../../../ui/Buttons";
import {PropsClassName, PropsOnComplete} from "../../../../helpers/props";
import {InterfaceTemplates, InterfaceTemplateValues} from "../domain";
import {TitleSubtitleScreen} from "../../../ui/screen/TitleSubtitleScreen";
import ButtonBar from "../../../ui/ButtonBar";
import Form, {InputGroup} from "../../../ui/input/Inputs";
import {useAsyncLoading} from "../../../../helpers/hooks";
import {useStrings} from "../../../../strings";
import {InterfaceTemplateAddressInput} from "../inputs/InterfaceTemplateAddressInput";
import {Address} from "../../../../models/address";

type Props = {
  titleAlign: "left" | "center"
  template: InterfaceTemplates.Address,
} & PropsClassName & PropsOnComplete<InterfaceTemplateValues.Address>

const InterfaceTemplateAddressScreen = ({className, template, onComplete, ...props}: Props) => {
  const strings = useStrings()
  const [value, setValue] = useState<InterfaceTemplateValues.Address>({
    kind: "address",
    addressValue: Address.empty()
  })

  const asyncWithLoading = useAsyncLoading(false)

  return (
    <TitleSubtitleScreen
      titleAlign={props.titleAlign}
      className={className}
      title={template.title}
      subtitle={template.subtitle}
    >
      <Form onSubmit={() => asyncWithLoading.asyncWithLoading(() => onComplete(value))}>
        <InputGroup>
          <InterfaceTemplateAddressInput
            suggestedCountryCode={undefined}
            template={template}
            value={value}
            disabled={asyncWithLoading.isLoading}
            onChange={setValue}
            error={undefined}
          />
        </InputGroup>
        <ButtonBar sticky={"bottom"} pushDown={false}>
          <Button
            loading={asyncWithLoading.isLoading}
            title={strings["general.continue"]}
            type={"submit"}
            size={"big"}
            color={"primary-black"}
          />
        </ButtonBar>
      </Form>
    </TitleSubtitleScreen>
  )
}

export default InterfaceTemplateAddressScreen