import {TitleSubtitleLeftScreen} from "./TitleSubtitleScreen";
import {useStrings} from "../../../strings";
import ScrollTextView from "../ScrollTextView";
import {DisclosureKind} from "../../../api/whales/Common";
import {useClientQuery} from "../../../api/client";

type Props = {
  disclosureKind: DisclosureKind
}

const DisclosureScreen = (props: Props) => {
  const strings = useStrings()

  const clientQuery = useClientQuery()
  const {data: disclosure} = clientQuery.info.disclosure.useQuery(props.disclosureKind)

  return (
    <TitleSubtitleLeftScreen className={"pb-10"} title={strings["signup.disclosure.title"]}>
      <ScrollTextView text={disclosure?.text}/>
    </TitleSubtitleLeftScreen>
  )
}

export default DisclosureScreen;