import {useStrings} from "../../../strings"
import {TitleSubtitleLeftScreen} from "../../../components/ui/screen/TitleSubtitleScreen";
import {LimiterOverflow} from "../../../components/ui/Limiter";
import {DeliveryOptionRows} from "../estimate/EstimateDeliveryOptionList";
import {PropsTransferContext, useTransferCreateController} from "./transfer-create-controller";

type Props = {} & PropsTransferContext
const TransferDeliveryOptionStep = ({transferContext}: Props) => {
  const strings = useStrings()
  const transferCreateController = useTransferCreateController()

  return (
    <TitleSubtitleLeftScreen
      title={strings["transfer.delivery_option.title"]}>
      <LimiterOverflow overflow={"16px"}>
        <DeliveryOptionRows transferContext={transferContext} onEstimateSelect={(estimate) => {
          return transferCreateController.onDeliveryOptionComplete(transferContext, estimate.deliveryOption)
        }}/>
      </LimiterOverflow>
    </TitleSubtitleLeftScreen>
  )
}

export default TransferDeliveryOptionStep