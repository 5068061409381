import cx from "classnames";
import React, {useState} from "react";
import {BodyTitle} from "../../../components/ui/Labels";
import TimelineElementContent from "./TimelineElementContent";
import {TimelineElement, TimelineElementLineState} from "../../../models/transfers/Timeline";
import {Transfer} from "../../../models/transfers/Transfer";
import ChevronDown from "../../../components/ui/icons/ChevronDown";
import SmoothCollapse from "react-smooth-collapse";
import TimelineIcon from "../../../components/ui/icons/TimelineIcon";
import {InlineButton} from "../../../components/ui/Buttons";
import {WithSkeleton} from "../../../components/ui/Skeleton";

type Props = {
  state: "open" | "closed"
  element: TimelineElement;
  transfer: Transfer;
} | {
  state: "skeleton"
  isFirst: boolean
  isLast: boolean
}

const TimelineElementView = (props: Props) => {
  const [stateIsOpen, setIsOpen] = useState<boolean>()
  const isOpen = stateIsOpen ?? (props.state === "open")

  const iconComponent = (() => {
    if (props.state === "skeleton") {
      return <TimelineIcon name={"skeleton"}/>
    }

    return (
      <TimelineIcon
        className={cx({"opacity-40": props.element.state === "hidden" || props.element.state === "future"})}
        name={function () {
          switch (props.element.iconConfiguration) {
            case "inProgress":
              return "spinner"
            case "completed":
              return "checkmark"
            case "future":
              return "wait"
            case "userActionRequired":
              return "arrow"
            case "failed":
            default:
              return "failed"
          }
        }()}
        foregroundColor={props.element.iconConfiguration === "userActionRequired" ? "secondary" : "primary"}
        backgroundColor={props.element.iconConfiguration === "userActionRequired" ? "primary" : "secondary"}
      />
    )
  })()

  const onToggleContent = () => {
    setIsOpen(!isOpen)
  }

  return (
    <li className={cx({
      "timeline-element": true,
      ...(props.state !== "skeleton" ? {
        "timeline-element--current": props.element.state === "currentOrPast",
        "timeline-element--future": props.element.state === "future",
        "timeline-element--hidden": props.element.state === "hidden",
      } : undefined),
    })}>
      <Line type={"upper"} state={function () {
        if (props.state === "skeleton") {
          return props.isFirst ? "hidden" : "skeleton"
        }
        return props.element.upperLineState
      }()}/>

      <Line type={"lower"} state={function () {
        if (props.state === "skeleton") {
          return props.isLast ? "hidden" : "skeleton"
        }
        return props.element.lowerLineState
      }()}/>

      <div className={cx({
        "timeline-element__point": true
      })}>{iconComponent}</div>

      <InlineButton
        type={"button"}
        className="timeline-element__header"
        disabled={props.state === "skeleton"}
        onClick={onToggleContent}
      >
        <WithSkeleton
          value={props.state === "skeleton" ? undefined : props.element.title}
          fallback={"Please wait until we load the title"}
          children= {title => <BodyTitle text={title}/>}
        />
        <div className="ml-auto">
          <ChevronDown invertedY={isOpen} hidden={props.state === "skeleton"}/>
        </div>
      </InlineButton>
      <div className={"timeline-element__content"}>
        <SmoothCollapse expanded={isOpen}>
          {props.state === "skeleton"
            ? undefined
            : <TimelineElementContent
              element={props.element}
              transfer={props.transfer}
            />
          }
        </SmoothCollapse>
      </div>
    </li>
  );
};

const Line = ({type, state}: { type: "lower" | "upper", state: TimelineElementLineState | "skeleton" }) => {
  return (
    <div className={cx({
      "timeline-element__line": true,
      [`timeline-element__line--${type}`]: true,

      [`timeline-element__line--hidden`]: state === "hidden",
      [`timeline-element__line--current-or-past`]: state === "currentOrPast",
      [`timeline-element__line--future`]: state === "future",
      [`timeline-element__line--skeleton`]: state === "skeleton",
    })}/>
  )
}

export default TimelineElementView;