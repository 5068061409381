export const defaultAnimDurationMillis = 200

export class Variables {
  static ui = {
    animDuration: {
      default: defaultAnimDurationMillis,
      sidebarOpenDelay: 500,
      sidebarOpenDelayAfterClick: 2 * 1000
    },
    spacing: {
      general: {
        borderWidth: 1,
      },
      contentScreen: {
        leftColumnMaxWidth: 440,
        rightColumnMaxWidth: 360,
      }
    }
  }
  static logic = {
    maxAlertsOnScreen: 3,
  }
}