import Limiter from "../../ui/Limiter";
import Disclaimer from "../Disclaimer";
import {useBusinessOnboardingQuery} from "../../../api/onboarding";
import React from "react";
import {TitleSubtitleCenterScreen} from "../../ui/screen/TitleSubtitleScreen";
import {OnboardingCtx, useOnboardingNavStack} from "./BusinessOnboardingScreen";
import BusinessOnboardingStatus from "./BusinessOnboardingStatus";
import {useSideIllustration} from "../../../atoms/side-illustration";
import {useStrings} from "../../../strings";


type Props = OnboardingCtx

const BusinessDisclaimer = (props: Props) => {
  const strings = useStrings()
  const navStack = useOnboardingNavStack()

  useSideIllustration("document")

  const businessOnboardingQuery = useBusinessOnboardingQuery()
  const confirmApplication = businessOnboardingQuery.application.confirm.useMutation(props.user.userId)

  return (
    <TitleSubtitleCenterScreen title={strings["signup.disclosure.title"]}>
      <Limiter className={"flex flex-col flex-auto"} contentMaxWidth={"580px"}>
        <Disclaimer countryCode={props.user.countryCode} userType={"business"} onContinue={async () => {
          await confirmApplication.mutateAsync()
          navStack.push(BusinessOnboardingStatus, {}, {popAll: true})
        }}/>
      </Limiter>
    </TitleSubtitleCenterScreen>
  )
}

export default BusinessDisclaimer