import {Money} from "../../helpers/money";
import {DeliveryOption, Estimate, Quote, QuoteOption} from "./Estimate";
import {Recipient} from "../../components/domain/recipients/domain";
import {EntryMode} from "../../api/whales/TransferCommon";
import {StringsObj} from "../../strings";
import FormattedMoney from "../../components/formatters/money/FormattedMoney";
import FormattedQuoteRate from "../../components/formatters/FormattedQuoteRate";
import FormattedDate from "../../components/formatters/date/FormattedDate";
import Decimal from "decimal.js";
import {TransferStatus} from "./TransferStatus";
import React from "react";

interface ITransferDisplaying {
  sourceMoney: Money
  destinationMoney: Money
  totalFee: Money
  fixedFee: Money
  fixedFeeWithoutPromo: Money
  quote: Quote
  quoteOption: QuoteOption
  deliveryOption: DeliveryOption
  recipient?: Recipient
  reference?: string
  status: TransferStatus
  createdAt?: Date
  didCustomerChooseQuoteOption: boolean
  entryMode: EntryMode
  shouldWarnAboutBankLimits: boolean
}


// extension transfer displaying in iOS
export class TransferDisplaying {
  readonly sourceMoney: Money;
  readonly destinationMoney: Money;
  readonly totalFee: Money;
  readonly fixedFee: Money;
  readonly fixedFeeWithoutPromo: Money;
  readonly quote: Quote;
  readonly quoteOption: QuoteOption;
  readonly deliveryOption: DeliveryOption;
  readonly recipient?: Recipient;
  readonly reference?: string;
  readonly status: TransferStatus;
  readonly createdAt?: Date;
  readonly didCustomerChooseQuoteOption: boolean;
  readonly entryMode: EntryMode;
  readonly shouldWarnAboutBankLimits: boolean;


  constructor(other: ITransferDisplaying) {
    this.sourceMoney = other.sourceMoney;
    this.destinationMoney = other.destinationMoney;
    this.totalFee = other.totalFee;
    this.fixedFee = other.fixedFee;
    this.fixedFeeWithoutPromo = other.fixedFeeWithoutPromo;
    this.quote = other.quote;
    this.quoteOption = other.quoteOption;
    this.deliveryOption = other.deliveryOption;
    this.recipient = other.recipient;
    this.reference = other.reference;
    this.status = other.status;
    this.createdAt = other.createdAt;
    this.didCustomerChooseQuoteOption = other.didCustomerChooseQuoteOption;
    this.entryMode = other.entryMode;
    this.shouldWarnAboutBankLimits = other.shouldWarnAboutBankLimits
  }

  static fromEstimate(estimate: Estimate): TransferDisplaying {
    return new TransferDisplaying({
      ...estimate,
      status: new TransferStatus("estimate"),
      shouldWarnAboutBankLimits: estimate.warnAboutBankLimits
    })
  }

  get exactAmounts(): EntryMode[] {
    const entryMode = this.entryMode
    const quoteKind = this.quote.kind
    const isQuoteFrozen = this.isQuoteFrozen.boolean
    if (quoteKind === "live" || (entryMode === "source" && quoteKind === "frozen" && !isQuoteFrozen)) {
      return ["source"]
    }
    if (quoteKind === "frozen" && isQuoteFrozen) {
      return ["source", "destination"]
    }
    if (entryMode === "destination" && quoteKind === "frozen" && !isQuoteFrozen) {
      return ["destination"]
    }
    return []
  }

  get shouldDisplaySourceAmountAsExact(): boolean {
    return this.exactAmounts.includes("source")
  }

  get shouldDisplayDestinationAmountAsExact(): boolean {
    return this.exactAmounts.includes("destination")
  }

  sourceAmountDescription(strings: StringsObj): JSX.Element {
    const formattedMoney = <FormattedMoney value={this.sourceMoney}/>
    if (this.shouldDisplaySourceAmountAsExact) {
      return strings["transfer.amount.exact"](formattedMoney)
    }

    return strings["transfer.amount.approximate"](formattedMoney)
  }

  exchangeRateDescription(strings: StringsObj): JSX.Element {
    const formattedRate = <FormattedQuoteRate value={this.quote.rate}/>
    if (this.isQuoteFrozen.boolean) {
      return strings["transfer.amount.exact"](formattedRate)
    } else {
      return strings["transfer.amount.approximate"](formattedRate)
    }
  }

  destinationAmountDescription(strings: StringsObj): JSX.Element {
    const formattedMoney = <FormattedMoney value={this.destinationMoney}/>
    if (this.shouldDisplayDestinationAmountAsExact) {
      return strings["transfer.amount.exact"](formattedMoney)
    }

    return strings["transfer.amount.approximate"](formattedMoney)
  }

  get isFixedFeeDiscounted(): boolean {
    return this.fixedFee.amount < this.fixedFeeWithoutPromo.amount
  }

  get totalFeeWithoutPromo(): Money {
    const discount = this.fixedFee.amount.minus(this.fixedFeeWithoutPromo.amount)

    return this.fixedFee.updated({amount: this.totalFee.amount.minus(discount)})
  }

  get isQuoteFrozen(): Quote.IsFrozen {
    if (this.status.hasTraded) return new Quote.FrozenBecauseTraded()

    if (this.quote.kind !== "frozen") return new Quote.NotFrozen()

    const expiry = this.quote.expiresAt
    if (expiry && expiry > new Date()) {
      return new Quote.Frozen(expiry)
    } else {
      return new Quote.NotFrozen()
    }
  }

  deliverySubtitle(strings: StringsObj): React.ReactNode {
    if (this.deliveryOption.isInstant) {
      const keyInstant: keyof StringsObj = this.isQuoteFrozen ?
        "transfer.delivery_option.instant.exact.description" :
        "transfer.delivery_option.instant.approximate.description"
      return strings[keyInstant](
        this.recipient?.displayName ?? strings["transfer.your_recipient"],
        <FormattedMoney value={this.destinationMoney}/>,
      )
    }

    const key: keyof StringsObj = this.isQuoteFrozen ?
      "transfer.delivery_option.standard.exact.description" :
      "transfer.delivery_option.standard.approximate.description"

    return strings[key](
      this.recipient?.displayName ?? strings["transfer.your_recipient"],
      <FormattedMoney value={this.destinationMoney}/>,
      <FormattedDate style={"EEEEMMMMd"} value={this.deliveryOption.deliveryDate}/>
    )
  }

  isFixedFeeOnly(): boolean {
    return this.deliveryOption.fee.amount.eq(0) &&
      this.quoteOption.fee.amount.eq(0) &&
      this.fixedFee.eq(this.totalFee)
  }

  static fallback: TransferDisplaying = TransferDisplaying.fromEstimate(
    new Estimate({
      didCustomerChooseQuoteOption: false,
      entryMode: "source",
      fixedFee: Money.gbp(3),
      fixedFeeWithoutPromo: Money.gbp(3),
      quote: new Quote({
        kind: "live", rate: new Decimal(1.11111111111)

      }),
      totalFee: Money.gbp(3),
      warnAboutBankLimits: false,
      sourceMoney: Money.gbp(1000),
      destinationMoney: Money.eur(1000),
      deliveryOption: new DeliveryOption({
        kind: "standard",
        deliveryDate: new Date(),
        disabled: false,
        fee: Money.gbp(0),
        rate: new Decimal(1.111111111111),
        isInstant: false,
      }),
      quoteOption: new QuoteOption({
        kind: "exact",
        fee: Money.gbp(0),
        rate: new Decimal(1.111111111111),
        disabled: false,
      }),
      checks: [],
    })
  )
}