import cx from "classnames";
import "./Labels.scss"
import React, {CSSProperties, PropsWithChildren} from "react";

type LabelProps = {
    id?: string,
    className?: string,
    text?: React.ReactNode
    style?: CSSProperties
    mobileStyles?: boolean
    color?: "primary-50"
} & PropsWithChildren

export const Heading1 = React.forwardRef<HTMLHeadingElement, LabelProps>((props: LabelProps, ref) => {
    const mobileStyles = props.mobileStyles ?? true
    return <h1 ref={ref} style={props.style} className={cx("lbl-heading1", {"lbl-heading1--with-mobile": mobileStyles}, props.className)}>{props.text}{props.children}</h1>
})

export const Heading2 = (props: LabelProps) => {
    return <h2 className={cx("lbl-heading2", props.className)}>{props.text}{props.children}</h2>
}

export const Body = (props: LabelProps) => {
    return <span className={cx("lbl-body", props.className)}>{props.text}{props.children}</span>
}

export const BodyTitle = (props: LabelProps) => {
    return <span id={props.id} className={cx("lbl-body-title", props.className)}>{props.text}{props.children}</span>
}

export const BodyCode = (props: LabelProps) => {
    return <span className={cx("lbl-body-code", props.className)}>{props.text}{props.children}</span>
}

export const Small = (props: LabelProps) => {
    return <span className={
        cx("lbl-small", props.className, {
            "lbl--primary-50": props.color === "primary-50"
        })
    }>{props.text}{props.className}</span>
}
