import Formatter from "./Formatter";


class FixedFormatter extends Formatter {
  constructor(public formatStr: string, public transform?: (str: string) => string) {
    super()
  }

  asYouTypePlaceholder() {
    return this.formatStr
      .replaceAll('#', '0')
      .replaceAll('@', 'A')
      .replaceAll('*', '_')
  }

  private escapeFormat = (format: string) => format
    .replace("0", "\\0")
    .replace("a", "\\a")

  iMaskOptions() {
    return {
      mask: this.escapeFormat(this.formatStr),
      eager: true,
      prepare: this.transform,
      definitions: {
        "#": /\d/,
        "@": /[a-zA-Z]/,
        "*": /[a-zA-Z\d]/
      }
    }
  }

  isNumericOnly(): boolean {
    return !this.formatStr.includes('*') && !this.formatStr.includes('@')
  }

  withTransform(transform: (str: string) => string): FixedFormatter {
    return new FixedFormatter(this.formatStr, transform)
  }

  uppercase(): FixedFormatter {
    return this.withTransform((str) => {
      if (this.transform === undefined) {
        return str.toUpperCase()
      }
      return this.transform(str).toUpperCase()
    })
  }

  static pattern(pattern: string): FixedFormatter {
    return new FixedFormatter(pattern)
  }

  static digits(n: number): FixedFormatter {
    return new FixedFormatter("#".repeat(n))
  }

  static letters(n: number): FixedFormatter {
    return new FixedFormatter("@".repeat(n))
  }

  static uppercaseLetters(n: number): FixedFormatter {
    return FixedFormatter.letters(n).uppercase()
  }
}

export default FixedFormatter
