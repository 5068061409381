import {AlertPopUpContext, AlertView} from "../../../components/ui/Errors";
import {useStrings} from "../../../strings";
import {Button} from "../../../components/ui/Buttons";
import {Transfer} from "../../../models/transfers/Transfer";
import FormattedQuoteRate from "../../../components/formatters/FormattedQuoteRate";
import FormattedMoney from "../../../components/formatters/money/FormattedMoney";
import InfoRow from "../../../components/ui/collections/rows/InfoRow";

const TransferSourceAmountIncreasedAlertView = (props: {
  ctx: AlertPopUpContext
  transfer: Transfer
  onCancel: () => Promise<void>
  onContinue: () => Promise<void>
}) => {
  const strings = useStrings()

  const content = (
    <>
      <div>
        {strings["transfer.source_amount_increased.subtitle"]}
        <InfoRow
          caption={strings["transfer.review.transfer.receiving_amount"]}
          content={props.transfer.displaying().destinationAmountDescription(strings)}
        />
        <InfoRow
          caption={strings["transfer.source_amount_increased.new_quote"]}
          content={<FormattedQuoteRate value={props.transfer.displaying().quote.rate}/>}
        />
        <InfoRow
          caption={strings["transfer.source_amount_increased.new_sending_amount"]}
          content={<FormattedMoney value={props.transfer.displaying().sourceMoney}/>}
        />
      </div>
    </>
  )

  return <AlertView
    title={strings["transfer.source_amount_increased.title"]}
    content={content}
    primaryButton={(btnProps) =>
      <Button type={"button"} title={strings["general.continue"]} {...btnProps}/>
    }
    secondaryButton={(btnProps) =>
      <Button type={"button"} title={strings["general.cancel"]} {...btnProps}/>
    }
    {...props.ctx}
  />
}

export default TransferSourceAmountIncreasedAlertView