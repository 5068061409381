import {Form, InputGroup } from "../ui/input/Inputs";
import React, {useCallback, useEffect, useState} from "react";
import Decimal from "decimal.js";
import {Table, Tbody, Td, Th, Thead, Tr} from 'react-super-responsive-table';
import {Currency, Money, MoneyInputValue} from "../../helpers/money";
import {Err, isErr, NewErr} from "../../helpers/errors";
import CompareService, {EstimateRequest, TransferProvider, TransferQuote, useCompareService} from "../../services/CompareService";
import Caption from "../ui/Caption";
import "./CompareBlock.scss"
import cx from "classnames";
import _ from "lodash"
import {useBasicAlertStack} from "../../providers/alert-stack";
import {img} from "../../helpers/public";
import FormattedMoney from "../formatters/money/FormattedMoney";
import FormattedQuoteRate from "../formatters/FormattedQuoteRate";
import {CompareMoneyInput} from "./CompareMoneyInput";

type State = {
    entryMode: "src" | "dst"
    inputs?: {
        [k in "src" | "dst"]: {
            currencyOptions: Currency[]
            value: MoneyInputValue
            error?: unknown
        }
    },
    transferQuotes: { [k in TransferProvider]: TransferQuote | undefined }
}

const otherInput = (entryMode: "src" | "dst") => {
    switch (entryMode) {
        case "src":
            return "dst"
        case "dst":
            return "src"
    }
}

const validateInput = (m: MoneyInputValue): Money | Err => {
    if (m.amount === "empty") {
        return NewErr("Please enter an amount.")
    }
    if (m.amount.greaterThan(1_000_000)) {
        return NewErr("Please enter an amount less than or equal to 1 million.")
    }

    return Money.make(m.amount, m.currency)
}

const CompareBlock = () => {
    const {showNotification} = useBasicAlertStack()
    const emptyTransferQuotes = {
        atlantic: undefined,
        wise: undefined
    }
    const [state, setState] = useState<State>({
        entryMode: "src",
        transferQuotes: emptyTransferQuotes
    })
    const handleError = useCallback((err: any) => {
        console.error(err)
        showNotification(
            "A comparison is currently not possible",
            "Sorry, unable to compare at the moment. Please try again later.")
    }, [showNotification])

    const locale = window.navigator.language
    const compareService = useCompareService()

    const quoteMatchesInput = (state: State, quote: TransferQuote): boolean => {
        const inputMoneyValue = state.entryMode === "src" ?
            state.inputs?.src.value :
            state.inputs?.dst.value
        if (inputMoneyValue === undefined) {
            return false
        }

        const validatedInputMoney = validateInput(inputMoneyValue)
        if (isErr(validatedInputMoney)) {
            return false
        }

        const quoteMoney = state.entryMode === "src" ? quote.sourceMoney : quote.destinationMoney
        return validatedInputMoney.eq(quoteMoney)
    }

    const updateTransferQuote = (provider: TransferProvider, estimateReq: EstimateRequest) => {
        compareService.estimate(provider, estimateReq).then((quote) => {
            setState(prev => {
                if (!prev.inputs) {
                    return prev
                }

                const validated = validateInput(prev.inputs?.src.value)
                if (isErr(validated)) {
                    return prev
                }

                if (!validated.eq(quote.sourceMoney)) {
                    return prev
                }

                return {
                    ...prev,
                    transferQuotes: {
                        ...prev.transferQuotes,
                        [provider]: quote
                    }
                }
            })
        }).catch(handleError)
    }

    const updateOtherQuotes = (estimateReq: EstimateRequest) => {
        const otherProviders = CompareService.availableProviders().filter(provider => provider !== "atlantic")
        otherProviders.forEach(provider => {
            updateTransferQuote(provider, estimateReq)
        })
    }

    const toMoneyInputValue = (m: Money): MoneyInputValue => {
        return {
            amount: m.amount,
            currency: m.currency
        }
    }

    useEffect(() => {
        compareService.bootstrapInfo().then(res => {
            setState((prev) => ({
                ...prev,
                inputs: prev.inputs ? prev.inputs : {
                    src: {
                        currencyOptions: res.availableCurrencies.src.map((cc) => Currency.default(cc)),
                        value: toMoneyInputValue(res.defaultQuote.sourceMoney),
                    },
                    dst: {
                        currencyOptions: res.availableCurrencies.dst.map((cc) => Currency.default(cc)),
                        value: toMoneyInputValue(res.defaultQuote.destinationMoney),
                    }
                },
                transferQuotes: {
                    ...prev.transferQuotes,
                    atlantic: res.defaultQuote
                }
            }))

            return updateOtherQuotes({
                entryMode: "source",
                amount: res.defaultQuote.sourceMoney.amount,
                srcCurrencyCode: res.defaultQuote.sourceMoney.currency.code,
                dstCurrencyCode: res.defaultQuote.destinationMoney.currency.code
            })
        })
        .catch(handleError)
        // eslint-disable-next-line
    }, [])


    const updateEstimate = (state: State) => {
        if (!state.inputs) {
            return
        }

        const inputKind = state.entryMode
        const inputMoney = state.inputs[inputKind].value

        const validatedInputMoney = validateInput(inputMoney)
        if (isErr(validatedInputMoney)) {
            setState(prev => ({
                ...prev,
                inputs: prev.inputs && {
                    ...prev.inputs,
                    [inputKind]: {
                        ...prev.inputs[inputKind],
                        error: validatedInputMoney.error
                    }
                }
            }))
            return;
        }

        const estimateReq: EstimateRequest = {
            entryMode: inputKind === "src" ? "source" : "destination",
            amount: validatedInputMoney.amount,
            srcCurrencyCode: (inputKind === "src" ? inputMoney.currency : state.inputs.src.value.currency).code,
            dstCurrencyCode: (inputKind === "dst" ? inputMoney.currency : state.inputs.dst.value.currency).code
        }

        compareService.estimate("atlantic", estimateReq).then(quote => {
            const other = otherInput(inputKind)
            const otherMoney = other === "src" ? quote.sourceMoney : quote.destinationMoney
            setState((prev) => {
                if (!quoteMatchesInput(prev, quote)) {
                    return prev
                }

                return {
                    ...prev,
                    inputs: prev.inputs && {
                        ...prev.inputs,
                        [other]: {
                            ...prev.inputs[other],
                            value: otherMoney
                        }
                    },
                    transferQuotes: {
                        ...prev.transferQuotes,
                        "atlantic": quote
                    }
                }
            })

            updateOtherQuotes({
                entryMode: "source",
                amount: quote.sourceMoney.amount,
                srcCurrencyCode: quote.sourceMoney.currency.code,
                dstCurrencyCode: quote.destinationMoney.currency.code
            })
        }).catch(handleError)
    }

    const onChangeEstimateInput = (inputKind: "src" | "dst") => (newInput: MoneyInputValue) => {
        const stateUpdater = (prev: State) => {
            const updatedState = {
                ...prev,
                inputs: prev.inputs ? {
                    ...prev.inputs,
                    [inputKind]: {
                        ...prev.inputs[inputKind],
                        value: newInput,
                        error: undefined
                    }
                } : undefined,
                transferQuotes: emptyTransferQuotes
            }

            if (updatedState.inputs && prev.inputs
                && updatedState.inputs.src.value.currency.eq(updatedState.inputs.dst.value.currency)) {
                // swap currencies if src and dst currency codes are same
                const prevCurrency = prev.inputs[inputKind].value.currency
                const otherInputCurrencyOptions = updatedState.inputs[otherInput(inputKind)].currencyOptions
                const newOtherCurrency = (() => {
                    if (otherInputCurrencyOptions.indexOf(prevCurrency) >= 0) {
                        return prevCurrency
                    }

                    return otherInputCurrencyOptions.find(c => !c.eq(newInput.currency))
                        || "" // Something gone terribly wrong if we are returning empty string
                })()
                return {
                    ...updatedState,
                    inputs: {
                        ...updatedState.inputs,
                        [otherInput(inputKind)]: {
                            ...updatedState.inputs[otherInput(inputKind)],
                            value: {
                                ...updatedState.inputs[otherInput(inputKind)].value,
                                currencyCode: newOtherCurrency
                            }
                        }
                    }
                }
            }

            return updatedState
        }

        setState(stateUpdater)

        updateEstimate(stateUpdater(state))
    }

    const onFocusEstimateInput = (inputKind: "src" | "dst") => () => {
        setState(prev => ({...prev, entryMode: inputKind}))
    }

    return (
        <div className={"compare-block"}>
            <Form className={"compare-block__form"} onSubmit={() => {
            }}>
                <InputGroup name={"Send"}>
                    <CompareMoneyInput error={state.inputs?.src.error}
                                currencyOptions={state.inputs?.src.currencyOptions}
                                value={state.inputs?.src.value}
                                onChange={onChangeEstimateInput("src")}
                                onFocus={onFocusEstimateInput("src")}
                    />
                </InputGroup>
                <InputGroup name={"Receive"}>
                    <CompareMoneyInput error={state.inputs?.dst.error}
                                currencyOptions={state.inputs?.dst.currencyOptions}
                                value={state.inputs?.dst.value}
                                onChange={onChangeEstimateInput("dst")}
                                onFocus={onFocusEstimateInput("dst")}
                    />
                </InputGroup>
            </Form>
            <CompareDetails locale={locale} transferQuotes={state.transferQuotes}/>
        </div>
    )
}

type CompareTableRowData = {
    providerId: TransferProvider
    transferFee?: Money
    rate?: Decimal
    destinationMoney?: Money
}

type CompareTableProps = {
    className?: string
    locale: string
    rows: CompareTableRowData[]
}

const ProviderIcon = (props: { provider: TransferProvider }) => {
    const src = {
        "wise": img("wise-logo.svg"),
        "atlantic": img("atlantic-logo.svg")
    }[props.provider]
    return <img src={src} alt={`${CompareService.providerName(props.provider)} logo`}/>
}

const CompareTableRow = (props: { locale: string, data: CompareTableRowData }) => {
    return (
        <Tr className={"compare-table__row"}>
            <Td><ProviderIcon provider={props.data.providerId}/></Td>
            <Td>
                <Caption
                    size={"3"}
                    text={props.data.transferFee && <FormattedMoney value={props.data.transferFee}/>}
                />
            </Td>
            <Td>
                <Caption
                    size={"3"}
                    text={props.data.rate && <FormattedQuoteRate value={props.data.rate}/>}
                />
            </Td>
            <Td>
                <Caption
                    size={"2-title"}
                    text={props.data.destinationMoney && <FormattedMoney value={props.data.destinationMoney}/>}
                />
            </Td>
        </Tr>
    )
}

const CompareTable = (props: CompareTableProps) => {
    return (
        <Table className={"compare-table"}>
            <Thead className={"compare-table__head"}>
                <Tr>
                    <Th><Caption size={"3"} text={"Provider"}/></Th>
                    <Th><Caption size={"3"} text={"Transfer fee"}/></Th>
                    <Th><Caption size={"3"} text={"Exchange rate"}/></Th>
                    <Th><Caption size={"3"} text={"Recipient gets"}/></Th>
                </Tr>
            </Thead>
            <Tbody className={"compare-table__body"}>
                {props.rows.map(data => <CompareTableRow key={data.providerId} locale={props.locale} data={data}/>)}
            </Tbody>
        </Table>
    )
}

type CompareDetailsProps = {
    className?: string
    locale: string
    transferQuotes: { [k in TransferProvider]: TransferQuote | undefined }
}

const CompareDetails = (props: CompareDetailsProps) => {
    const rows: CompareTableRowData[] = CompareService.availableProviders()
        .map((provider) => {
            const quote = props.transferQuotes[provider]
            return {
                providerId: provider,
                transferFee: quote?.transferFee,
                rate: quote?.exchangeRate,
                destinationMoney: quote?.destinationMoney
            }
        })

    const sortedRows = _.sortBy(rows, [
        (row) => row.destinationMoney?.amount.neg().toNumber(),
        (row) => CompareService.availableProviders().indexOf(row.providerId)
    ])
    const allLoaded = rows.findIndex(row => row.destinationMoney === undefined) < 0
    const displayRows = allLoaded ? sortedRows : rows

    const summaryData = (() => {
        const bestQuote = sortedRows[0]
        const secondBest = sortedRows[1]

        if (bestQuote?.destinationMoney === undefined || secondBest?.destinationMoney === undefined) {
            return undefined
        }

        const amountDiff = bestQuote.destinationMoney.amount.minus(secondBest.destinationMoney.amount)
        return {
            providerId: bestQuote.providerId,
            diff: Money.make(amountDiff, bestQuote.destinationMoney.currency)
        }
    })()

    return (
        <div className={cx("compare-details", props.className)}>
            <CompareTable className={"compare-details__table"} locale={props.locale} rows={displayRows}/>
            <div
                className={cx("compare-details__summary", {"compare-details__summary--loading": summaryData === undefined})}>
                {summaryData &&
                    <Caption size={"3"} className={"mt-[16px] text-center"} color={"primary-50"}>
                        {`${CompareService.providerName(summaryData?.providerId)} gets you `}
                        <Caption size={"3-title"} color={"green"}
                                 className={"inline"}><FormattedMoney value={summaryData.diff}/></Caption>
                        {" more."}
                    </Caption>}
            </div>
        </div>
    )
}

export default CompareBlock