import {useMutation, useQuery} from "react-query";
import {Api, queryClient, useApi} from "./axios";
import {Address, UserType} from "./whales/Common";
import {
  ApplicationResendCodeRequest,
  ApplicationResendCodeResponse,
  ApplicationResponse,
  ApplicationTemplateResponse,
  ApplicationUpdateBirthdateRequest,
  ApplicationUpdateNameRequest,
  ApplicationUpdatePhoneRequest,
  ApplicationUpdatePhoneResponse,
  BusinessApplicationAddressUpdateRequest,
  BusinessApplicationChecksConfirmRequest,
  BusinessApplicationResponse,
  BusinessApplicationUpdateHasUbosRequest,
  BusinessApplicationUpdateIdentifierRequest,
  BusinessApplicationUpdateNameRequest,
  BusinessContactBatchUpdateRolesRequest,
  BusinessContactModificationRequest,
  BusinessContactResponse,
  ConfirmPhoneRequest,
  Country,
  CountryList,
  DisclosureResponse,
  JoinWaitlistRequest
} from "./whales/Onboarding";
import {ApplicationTemplate} from "../components/domain/onboarding/ApplicationTemplate";


const fixBusinessApplicationResponse = (res: BusinessApplicationResponse): BusinessApplicationResponse => {
  return {
    payload: {
      ...res.payload,
      contacts: res.payload.contacts ?? [],
    }
  }
}

export class OnboardingApi {
  constructor(private api: Api) {
  }

  countryList(userType: UserType): Promise<Country[]> {
    return this.api.get<CountryList>(`/onboarding/v1/country/list`, {userType: userType})
      .then((res) => res.payload.countries)
  }

  waitlistJoin(req: JoinWaitlistRequest): Promise<{}> {
    return this.api.post("/onboarding/v1/waitlist/join", req)
  }

  applicationTemplateGet(): Promise<ApplicationTemplate> {
    return this.api.get<ApplicationTemplateResponse>("/onboarding/v2/application/template")
      .then((res) => ApplicationTemplate.fromApi(res.payload))
  }

  applicationGet(accessToken?: string): Promise<ApplicationResponse | null> {
    return this.api.getOrNull<ApplicationResponse>("/onboarding/v1/application/get", {}, accessToken && {
      "Authorization": `Bearer ${accessToken}`
    })
  }

  applicationPut(accessToken?: string): Promise<ApplicationResponse> {
    return this.api.post("/onboarding/v1/application/put", undefined, accessToken && {
      "Authorization": `Bearer ${accessToken}`
    })
  }

  applicationGetOrPut(accessToken?: string): Promise<ApplicationResponse> {
    return this.applicationGet(accessToken).then((res) => {
      if (res == null) {
        return this.applicationPut(accessToken)
      } else {
        return res
      }
    })
  }

  applicationNameUpdate(req: ApplicationUpdateNameRequest): Promise<ApplicationResponse> {
    return this.api.post("/onboarding/v1/application/name/update", req)
  }

  applicationBirthdateUpdate(req: ApplicationUpdateBirthdateRequest): Promise<ApplicationResponse> {
    return this.api.post("/onboarding/v1/application/birthdate/update", req)
  }

  applicationPhoneUpdate(req: ApplicationUpdatePhoneRequest): Promise<ApplicationUpdatePhoneResponse> {
    return this.api.post("/onboarding/v1/application/phone/update", req)
  }

  applicationPhoneConfirm(req: ConfirmPhoneRequest): Promise<ApplicationResponse> {
    return this.api.post("/onboarding/v1/application/phone/confirm", req)
  }

  applicationPhoneResendCode(req: ApplicationResendCodeRequest): Promise<ApplicationResendCodeResponse> {
    return this.api.post("/onboarding/v1/application/phone/resend-code", req)
  }

  applicationHomeAddressUpdate(req: Address): Promise<ApplicationResponse> {
    return this.api.post("/onboarding/v1/application/home-address/update", req)
  }

  applicationConfirm(): Promise<ApplicationResponse> {
    return this.api.post("/onboarding/v1/application/confirm", null)
  }

  disclosureGet(countryCode: string, userType: UserType, kind: "signup"): Promise<DisclosureResponse> {
    return this.api.get("/onboarding/v1/disclosure", {countryCode, userType, kind})
  }

  businessApplicationGet(accessToken?: string): Promise<BusinessApplicationResponse | null> {
    return this.api.getOrNull<BusinessApplicationResponse>("/onboarding/v1/business/application/get", {}, accessToken && {
      "Authorization": `Bearer ${accessToken}`
    }).then((res) => res && fixBusinessApplicationResponse(res))
  }

  businessApplicationPut(accessToken?: string): Promise<BusinessApplicationResponse> {
    return this.api.post<BusinessApplicationResponse, unknown>("/onboarding/v1/business/application/put", undefined, accessToken && {
      "Authorization": `Bearer ${accessToken}`
    }).then(fixBusinessApplicationResponse)
  }

  businessApplicationGetOrPut(accessToken?: string): Promise<BusinessApplicationResponse> {
    return this.businessApplicationGet(accessToken).then((res) => {
      if (res == null) {
        return this.businessApplicationPut(accessToken)
      } else {
        return res
      }
    }).then(fixBusinessApplicationResponse)
  }

  businessApplicationNameUpdate(req: BusinessApplicationUpdateNameRequest): Promise<BusinessApplicationResponse> {
    return this.api.post<BusinessApplicationResponse, BusinessApplicationUpdateNameRequest>("/onboarding/v1/business/application/name/update", req).then(fixBusinessApplicationResponse)
  }

  businessApplicationIdentifierUpdate(req: BusinessApplicationUpdateIdentifierRequest): Promise<BusinessApplicationResponse> {
    return this.api.post<BusinessApplicationResponse, typeof req>("/onboarding/v1/business/application/identifier/update", req).then(fixBusinessApplicationResponse)
  }

  businessApplicationAcceptableCountriesList(): Promise<CountryList> {
    return this.api.get("/onboarding/v1/business/address-acceptable-countries/list")
  }

  businessApplicationRegisteredAddressUpdate(req: BusinessApplicationAddressUpdateRequest): Promise<BusinessApplicationResponse> {
    return this.api.post<BusinessApplicationResponse, typeof req>("/onboarding/v1/business/application/registered-address/update", req).then(fixBusinessApplicationResponse)
  }

  businessApplicationTradingAddressUpdate(req: BusinessApplicationAddressUpdateRequest): Promise<BusinessApplicationResponse> {
    return this.api.post<BusinessApplicationResponse, typeof req>("/onboarding/v1/business/application/trading-address/update", req).then(fixBusinessApplicationResponse)
  }

  businessApplicationUpdateRoles(req: BusinessContactBatchUpdateRolesRequest): Promise<BusinessApplicationResponse> {
    return this.api.post<BusinessApplicationResponse, typeof req>("/onboarding/v1/business/contact/roles/batch-update", req).then(fixBusinessApplicationResponse)
  }

  businessApplicationCreateContact(req: BusinessContactModificationRequest): Promise<BusinessContactResponse> {
    return this.api.post("/onboarding/v1/business/contact/create", req)
  }

  businessApplicationHasUBOUpdate(req: BusinessApplicationUpdateHasUbosRequest): Promise<BusinessApplicationResponse> {
    return this.api.post<BusinessApplicationResponse, typeof req>("/onboarding/v1/business/application/has-ubos/update", req).then(fixBusinessApplicationResponse)
  }

  businessApplicationChecksConfirm(req: BusinessApplicationChecksConfirmRequest): Promise<BusinessApplicationResponse> {
    return this.api.post<BusinessApplicationResponse, typeof req>("/onboarding/v1/business/application/checks/confirm", req).then(fixBusinessApplicationResponse)
  }

  businessApplicationConfirm(): Promise<BusinessApplicationResponse> {
    return this.api.post<BusinessApplicationResponse, unknown>("/onboarding/v1/business/application/confirm", undefined).then(fixBusinessApplicationResponse)
  }
}

export function useOnboardingApi() {
  return new OnboardingApi(useApi())
}

const queryKeys = {
  countryList: (userType: UserType) => ["countryList", {userType}],
  disclosure: (countryCode: string, userType: UserType, kind: "signup") => ["disclosure", countryCode, userType, kind],
  application: {
    get: (userId: string) => ["application.get", {userId}],
    updateName: "application.updateName",
    updateBirthdate: "application.updateBirthdate",
    updateAddress: "application.updateAddress",
    updatePhone: "application.updatePhone",
    resendCode: "application.resendCode",
    confirmPhone: "application.confirmPhone",
    confirm: "application.confirm",
  },
  business: {
    application: {
      get: (userId: string) => ["business.application.get", {userId}],
      updateLegalName: "business.application.updateLegalName",
      updateIdentifier: "business.application.updateIdentifier",
      updateRegisteredAddress: "business.application.updateRegisteredAddress",
      updateTradingAddress: "business.application.updateTradingAddress",
      updateRoles: "business.application.updateRoles",
      updateHasUbos: "business.application.updateHasUbos",
      createContact: "business.application.createContact",
      confirm: "business.application.confirm",
      checksConfirm: "business.application.checksConfirm",
    },
    acceptableCountriesList: "business.acceptableCountriesList"
  }
}

export function useBusinessOnboardingQuery() {
  const onboardingApi = useOnboardingApi()

  return {
    acceptableCountriesList: {
      useQuery: (options?: { onError: (err: any) => void }) =>
        useQuery(queryKeys.business.acceptableCountriesList, () => onboardingApi.businessApplicationAcceptableCountriesList(), options)
    },
    application: {
      get: {
        useQuery: (userId: string, options?: {
          enabled?: boolean,
          onSuccess?: (res: BusinessApplicationResponse) => void,
          onError?: (err: any) => void,
          onBeforeRequest?: () => void,
          onAfterRequest?: () => void,
          refetchInterval?: number
        }) =>
          useQuery(queryKeys.business.application.get(userId), async () => {
            options?.onBeforeRequest?.()
            return await onboardingApi.businessApplicationGetOrPut().finally(() => options?.onAfterRequest?.())
          }, {...options}),
        preFetch: (userId: string, accessToken?: string, staleTime?: number) =>
          queryClient.prefetchQuery(queryKeys.business.application.get(userId), () =>
            onboardingApi.businessApplicationGetOrPut(accessToken), {staleTime}
          )
      },
      updateLegalName: {
        useMutation: (userId: string) => useMutation(
          queryKeys.business.application.updateLegalName,
          (req: BusinessApplicationUpdateNameRequest) => onboardingApi.businessApplicationNameUpdate(req),
          {
            onSuccess: data => {
              queryClient.setQueriesData(queryKeys.business.application.get(userId), data)
            }
          }
        )
      },
      updateIdentifier: {
        useMutation: (userId: string) => useMutation(
          queryKeys.business.application.updateIdentifier,
          (req: BusinessApplicationUpdateIdentifierRequest) => onboardingApi.businessApplicationIdentifierUpdate(req),
          {
            onSuccess: data => {
              queryClient.setQueriesData(queryKeys.business.application.get(userId), data)
            }
          }
        )
      },
      updateRegisteredAddress: {
        useMutation: (userId: string) => useMutation(
          queryKeys.business.application.updateRegisteredAddress,
          (req: BusinessApplicationAddressUpdateRequest) => onboardingApi.businessApplicationRegisteredAddressUpdate(req),
          {
            onSuccess: data => {
              queryClient.setQueriesData(queryKeys.business.application.get(userId), data)
            }
          }
        )
      },
      updateTradingAddress: {
        useMutation: (userId: string) => useMutation(
          queryKeys.business.application.updateTradingAddress,
          (req: BusinessApplicationAddressUpdateRequest) => onboardingApi.businessApplicationTradingAddressUpdate(req),
          {
            onSuccess: data => {
              queryClient.setQueriesData(queryKeys.business.application.get(userId), data)
            }
          }
        )
      },
      updateRoles: {
        useMutation: (userId: string) => useMutation(
          queryKeys.business.application.updateRoles,
          (req: BusinessContactBatchUpdateRolesRequest) => onboardingApi.businessApplicationUpdateRoles(req),
          {
            onSuccess: data => {
              queryClient.setQueriesData(queryKeys.business.application.get(userId), data)
            }
          }
        )
      },
      updateHasUbos: {
        useMutation: (userId: string) => useMutation(
          queryKeys.business.application.updateHasUbos,
          (req: BusinessApplicationUpdateHasUbosRequest) => onboardingApi.businessApplicationHasUBOUpdate(req),
          {
            onSuccess: data => {
              queryClient.setQueriesData(queryKeys.business.application.get(userId), data)
            }
          }
        )
      },
      checksConfirm: {
        useMutation: (userId: string) => useMutation(
          queryKeys.business.application.checksConfirm,
          (req: BusinessApplicationChecksConfirmRequest) => onboardingApi.businessApplicationChecksConfirm(req),
          {
            onSuccess: data => {
              queryClient.setQueriesData(queryKeys.business.application.get(userId), data)
            }
          }
        )
      },
      confirm: {
        useMutation: (userId: string) => useMutation(
          queryKeys.business.application.confirm,
          () => onboardingApi.businessApplicationConfirm(),
          {
            onSuccess: data => {
              queryClient.setQueriesData(queryKeys.business.application.get(userId), data)
            }
          }
        )
      },
      contact: {
        create: {
          useMutation: () => useMutation(
            queryKeys.business.application.createContact,
            (req: BusinessContactModificationRequest) => onboardingApi.businessApplicationCreateContact(req),
          )
        }
      }
    }
  }
}

export function useOnboardingQuery() {
  const onboardingApi = useOnboardingApi()
  return {
    countryList: {
      useQuery: (userType: UserType, options?: { onError: (err: any) => void }) =>
        useQuery(queryKeys.countryList(userType), () => onboardingApi.countryList(userType), options)
    },
    disclosure: {
      useQuery: (countryCode: string, userType: UserType, kind: "signup", options?: {
        onError: (err: any) => void
      }) => {
        return useQuery(queryKeys.disclosure(countryCode, userType, kind),
          () => onboardingApi.disclosureGet(countryCode, userType, kind), options
        )
      }
    },
    application: {
      template: {
        useQuery: (options?: { onError: (err: any) => void }) =>
          useQuery("application.template", () => onboardingApi.applicationTemplateGet(), options)
      },
      get: {
        useQuery: (userId: string, options?: {
          enabled?: boolean,
          onBeforeRequest?: () => void,
          onAfterRequest?: () => void,
          onError?: (err: any) => void
        }) => useQuery(queryKeys.application.get(userId), async () => {
          options?.onBeforeRequest?.()
          return await onboardingApi.applicationGetOrPut().finally(() => options?.onAfterRequest?.())
        }, {...options,}),
        preFetch: (userId: string, accessToken?: string) =>
          queryClient.prefetchQuery(queryKeys.application.get(userId), () =>
            onboardingApi.applicationGetOrPut(accessToken)
          )
      },
      updateName: {
        useMutation: (userId: string) => useMutation(
          queryKeys.application.updateName,
          (req: ApplicationUpdateNameRequest) => onboardingApi.applicationNameUpdate(req),
          {
            onSuccess: data => {
              queryClient.setQueriesData(queryKeys.application.get(userId), data)
            }
          }
        )
      },
      updateBirthdate: {
        useMutation: (userId: string) => useMutation(
          queryKeys.application.updateBirthdate,
          (req: ApplicationUpdateBirthdateRequest) => onboardingApi.applicationBirthdateUpdate(req),
          {
            onSuccess: data => {
              queryClient.setQueriesData(queryKeys.application.get(userId), data)
            }
          }
        )
      },
      updateAddress: {
        useMutation: (userId: string) => useMutation(
          queryKeys.application.updateAddress,
          (req: Address) => onboardingApi.applicationHomeAddressUpdate(req),
          {
            onSuccess: data => {
              queryClient.setQueriesData(queryKeys.application.get(userId), data)
            }
          }
        )
      },
      updatePhone: {
        useMutation: () => useMutation(
          queryKeys.application.updatePhone,
          (req: ApplicationUpdatePhoneRequest) => onboardingApi.applicationPhoneUpdate(req),
        )
      },
      resendCode: {
        useMutation: () => useMutation(
          queryKeys.application.resendCode,
          (req: ApplicationResendCodeRequest) => onboardingApi.applicationPhoneResendCode(req),
        )
      },
      confirmPhone: {
        useMutation: (userId: string) => useMutation(
          queryKeys.application.confirmPhone,
          (req: ConfirmPhoneRequest) => onboardingApi.applicationPhoneConfirm(req),
          {
            onSuccess: data => {
              queryClient.setQueriesData(queryKeys.application.get(userId), data)
            }
          }
        )
      },
      confirm: {
        useMutation: (userId: string) => useMutation(
          queryKeys.application.confirm,
          () => onboardingApi.applicationConfirm(),
          {
            onSuccess: data => {
              queryClient.setQueriesData(queryKeys.application.get(userId), data)
            }
          }
        )
      },
    }
  }
}