import NavBar from "../navigation/NavBar";
import React from "react";
import {Link} from "../ui/Link";
import Icon from "../ui/icons/Icon";
import {PropsClassName} from "../../helpers/props";
import cx from "classnames";
import {ErrorBoundary} from "../ui/layout/ErrorBoundaryLayout";
import {useResizeObserver} from "usehooks-ts";

type Props = {
  onBack?: () => void
} & React.PropsWithChildren & PropsClassName

const OnboardingScreen = (props: Props) => {
  const containerRef = React.useRef<HTMLDivElement>(null)
  const containerSize = useResizeObserver({ref: containerRef})
  return (
    <ErrorBoundary>
      <div ref={containerRef} className={cx(props.className, "")}>
        <div className={"fixed top-0 right-0 z-[var(--z-header)] pt-[24px] px-[24px] bg-secondary"}
             style={{width: containerSize.width}}>
          <NavBar onBack={props.onBack} features={["help", "logout"]}/>
        </div>
        <div className={"flex flex-col"}>
          <div className={"pt-[64px] flex justify-center items-center grow-0 mb-[24px]"}>
            <Link href={"https://atlantic.money"} newTab={true}>
              <Icon name={"atlantic-logo-graphic"}/>
            </Link>
          </div>
          <div className={"px-[24px] flex-1"}>
            {props.children}
          </div>
        </div>
      </div>
    </ErrorBoundary>
  )
}

export default OnboardingScreen;