import Tooltip, {TooltipView} from "../ui/Tooltip";
import {useStrings} from "../../strings";
import React from "react";
import {TransferDisplaying} from "../../models/transfers/TransferDisplaying";
import FormattedQuoteRate from "../formatters/FormattedQuoteRate";
import FormattedDueDate from "../formatters/date/FormattedDueDate";
import {Quote} from "../../models/transfers/Estimate";
import {PropsClassName} from "../../helpers/props";

type Props = {
  displaying: TransferDisplaying
} & PropsClassName

const TooltipExchangeRate = ({className, displaying}: Props): JSX.Element | null => {
  const strings = useStrings()

  const body = function (): React.ReactNode {
    if (displaying.status.string === "estimate") {
      const isQuoteFrozen =  displaying.isQuoteFrozen
      switch (isQuoteFrozen.kind) {
        case "frozen-with-expiry":
          const frozenWithExpiry = isQuoteFrozen as Quote.Frozen
          return strings["transfer.details.exchange_rate.frozen_quote"](
            <FormattedQuoteRate value={displaying.quote.rate}/>,
            <FormattedDueDate value={frozenWithExpiry.expiry}/>
          )
        case "not-frozen":
          return strings["transfer.details.exchange_rate.estimate.live_quote"](
            <FormattedQuoteRate value={displaying.quote.rate}/>,
          )
        case "frozen-because-traded":
          return undefined
      }
    } else {
      const isQuoteFrozen =  displaying.isQuoteFrozen
      switch (isQuoteFrozen.kind) {
        case "frozen-with-expiry":
          const frozenWithExpiry = isQuoteFrozen as Quote.Frozen
          return strings["transfer.details.exchange_rate.frozen_quote"](
            <FormattedQuoteRate value={displaying.quote.rate}/>,
            <FormattedDueDate value={frozenWithExpiry.expiry}/>
          )
        case "not-frozen":
          return strings["transfer.details.exchange_rate.not_paid_for.live_quote"](
            <FormattedQuoteRate value={displaying.quote.rate}/>,
          )
        case "frozen-because-traded":
          return undefined
      }
    }
  }()

  if (!body) return null

  return (
    <Tooltip className={className}>
      <TooltipView
        title={strings["transfer.fee_breakdown.transfer_fees"]}
        subtitle={body}
      />
    </Tooltip>
  )
}

export default TooltipExchangeRate