type OptInEntity = {
    optIn: boolean
}

export class OptInStorage {
    static key = "atlantic_opt_in"
    static setGeneralOptIn(optIn: boolean): void {
        localStorage.setItem(this.key, JSON.stringify({optIn}));
    }

    static getGeneralOptIn(): OptInEntity | null {
        const optIn = localStorage.getItem(this.key);
        if (optIn) {
            return JSON.parse(optIn);
        }
        return null;
    }
}