import {VerificationProps} from "./verification-controller";
import {InterfaceTemplates} from "../domain/interface-template/domain";
import {useVerificationApi} from "../../api/verification";
import InterfaceTemplateEmploymentScreen from "../domain/interface-template/screens/InterfaceTemplateEmploymentScreen";

type Props = VerificationProps & {
  template: InterfaceTemplates.Employment
}

const VerificationEmploymentStepSubmit = (props: Props) => {
  const verificationApi = useVerificationApi()
  return (
    <InterfaceTemplateEmploymentScreen
      titleAlign={props.titleAlign}
      template={props.template}
      onComplete={async (v) => {
        await verificationApi.submit({
            verificationId: props.verification.id,
            value: {
              kind: "employment",
              employmentValue: {
                statusValue: {
                  option: v.employmentValue.status.singleSelectValue.option.toApi(),
                  otherText: v.employmentValue.status.singleSelectValue.otherText ? {
                    text: v.employmentValue.status.singleSelectValue.otherText
                  } : undefined
                },
                occupationValue: v.employmentValue.occupation ? {
                  text: v.employmentValue.occupation
                } : undefined,
                employerValue: v.employmentValue.employer ? {
                  text: v.employmentValue.employer
                } : undefined
              }
            }
          }
        )
        await props.onComplete()
      }}
    />
  )
}

export default VerificationEmploymentStepSubmit