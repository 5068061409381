import React, {useCallback, useEffect, useState} from "react";
import {useOnboardingQuery} from "../../../api/onboarding";
import {ApplicationResponse} from "../../../api/whales/Onboarding";
import {useAuthorizedAuthValue} from "../../../atoms/auth";
import {useAsyncLoading} from "../../../helpers/hooks";
import {nonEmptyOrUndefined, notEmpty} from "../../../helpers/strings";
import ButtonBar from "../../ui/ButtonBar";
import {Button} from "../../ui/Buttons";
import Collapsable from "../../ui/Collapsable";
import {InlineError} from "../../ui/Errors";
import {Form, InputGroup, InputGroupRow, OtpInput, TextInput, useOtpFocuser} from "../../ui/input/Inputs";
import AddressInputGroup from "../../ui/input/AddressInputGroup";
import {useStrings} from "../../../strings";
import PhoneInput from "../../ui/input/PhoneInput";
import DateInputGroup, {DateInputValue} from "../../ui/input/DateInputGroup";
import moment from "moment";
import {useDisplayErrorMessage} from "../../../helpers/errors";

type Props = {
  applicationRes?: ApplicationResponse
  onComplete: () => void
}

type InputName =
  "firstName"
  | "lastName"
  | "addressLine1"
  | "addressLine2"
  | "city"
  | "stateOrProvince"
  | "postcode"
  | "phone"
type ErrorName = "name" | "birthdate" | "address" | "phone" | "otp"
type Inputs = { [key in InputName]: string } & { birthdate: DateInputValue }
type State = {
  formState: "fill" | "confirm-phone"
  inputs?: Inputs
  otp: {
    [key: string]: {
      status: "not-sent" | "sent" | "confirmed";
      ticket?: string;
      code: string;
    }
  }
  errors: {
    [key in ErrorName]: string | undefined
  }
}

const sequencePromises = async <T, >(fs: (() => Promise<T>)[]): Promise<T[]> => {
  const errors = Array(fs.length)
  const values = Array(fs.length)
  for (let i = 0; i < fs.length; ++i) {
    await fs[i]().then((val) => {
      values[i] = val
    }, (err) => {
      errors[i] = err
    })
  }

  const firstErr = errors.find(err => err !== undefined)
  if (firstErr !== undefined) {
    return Promise.reject(firstErr)
  }
  return Promise.resolve(values)
}

const OnboardingForm = (props: Props) => {
  const strings = useStrings()
  const displayErrorMessage = useDisplayErrorMessage()

  const application = props.applicationRes?.payload
  const onboardingQuery = useOnboardingQuery()
  const authState = useAuthorizedAuthValue()
  const [state, setState] = useState<State>({
    formState: application?.phoneConfirmed ? "confirm-phone" : "fill",
    otp: {},
    errors: {
      name: undefined,
      birthdate: undefined,
      address: undefined,
      phone: undefined,
      otp: undefined,
    }
  })
  const otpFocuser = useOtpFocuser()
  const {isLoading, asyncWithLoading} = useAsyncLoading(false)

  useEffect(() => {
    if (application == null) {
      return
    }

    setState((prev) => {
      return ({
        ...prev,
        inputs: prev.inputs ? prev.inputs : {
          firstName: application.firstName || "",
          lastName: application.lastName || "",
          birthdate: application.birthdate ? DateInputValue.fromDate(moment(application.birthdate, "yyyy-MM-DD").toDate()) : DateInputValue.empty(),
          addressLine1: application.homeAddress?.addressLine1 || "",
          addressLine2: application.homeAddress?.addressLine2 || "",
          city: application.homeAddress?.city || "",
          stateOrProvince: application.homeAddress?.stateOrProvince || "",
          postcode: application.homeAddress?.postcode || "",
          phone: application.phone || "",
        },
        otp: {
          ...prev.otp,
          [application.phone]: prev.otp[application.phone] || {
            status: application.phoneConfirmed ? "confirmed" : "not-sent",
            code: ""
          }
        }
      })
    })

  }, [application, authState.user.countryCode])

  const setError = useCallback((errName: ErrorName) => (err: any | undefined) => {
    setState((prev) => {
      const newErrMsg = err && displayErrorMessage(err)
      if (prev.errors[errName] === newErrMsg) {
        return prev
      }
      return {...prev, errors: {...prev.errors, [errName]: newErrMsg}}
    })
  }, [displayErrorMessage])

  const unsetError = useCallback((errName: ErrorName) => setError(errName)(undefined), [setError])

  const {mutateAsync: mutateNameAsync} = onboardingQuery.application.updateName.useMutation(authState.user.userId)
  const onUpdateName = useCallback((inputs?: { firstName: string, lastName: string }) => {
    return mutateNameAsync({
      firstName: inputs?.firstName || "",
      lastName: inputs?.lastName || ""
    }, {
      onSuccess: () => unsetError("name"),
      onError: setError("name")
    })
  }, [mutateNameAsync, setError, unsetError])

  const onBlurName = () => {
    if (state.inputs && notEmpty(state.inputs.firstName) && notEmpty(state.inputs.lastName)) {
      return onUpdateName(state.inputs)
    }
  }

  const {mutateAsync: mutateBirthdateAsync} = onboardingQuery.application.updateBirthdate.useMutation(authState.user.userId)
  const onUpdateBirthdate = useCallback((inputs?: { birthdate: DateInputValue }) => {
    return mutateBirthdateAsync({birthdate: inputs?.birthdate.toApiString() || ""}, {
      onSuccess: () => unsetError("birthdate"),
      onError: setError("birthdate")
    })
  }, [mutateBirthdateAsync, setError, unsetError])

  const onBlurBirthdate = () => {
    if (state.inputs?.birthdate.isComplete()) {
      return onUpdateBirthdate(state.inputs)
    }
  }

  const {mutateAsync: mutateAddressAsync} = onboardingQuery.application.updateAddress.useMutation(authState.user.userId)
  const onUpdateAddress = useCallback((inputs?: {
    addressLine1: string
    addressLine2: string
    city: string
    stateOrProvince: string
    postcode: string
  }) => {
    return mutateAddressAsync({
      addressLine1: inputs?.addressLine1 || "",
      addressLine2: nonEmptyOrUndefined(inputs?.addressLine2) ?? null,
      city: inputs?.city || "",
      stateOrProvince: nonEmptyOrUndefined(inputs?.stateOrProvince),
      postcode: inputs?.postcode || "",
      countryCode: authState.user.countryCode
    }, {
      onSuccess: () => unsetError("address"),
      onError: setError("address")
    })
  }, [authState.user.countryCode, mutateAddressAsync, setError, unsetError])

  const onBlurAddress = () => {
    if (state.inputs
      && notEmpty(state.inputs.addressLine1)
      && notEmpty(state.inputs.city)
      && notEmpty(state.inputs.postcode)) {
      return onUpdateAddress(state.inputs)
    }
  }

  const usePhoneMutation = onboardingQuery.application.updatePhone.useMutation()
  const onSendOtpCode = () => asyncWithLoading(async () => {
    const phone = state.inputs?.phone
    const res = await usePhoneMutation.mutateAsync({phone: phone || ""}, {
      onSuccess: () => unsetError("phone"),
      onError: setError("phone")
    })
    if (!phone) {
      return
    }
    setState((prev) => ({
        ...prev,
        otp: {
          ...prev.otp,
          [phone]: {
            code: "",
            status: "sent",
            ticket: res.payload.ticket
          }
        }
      })
    )
  })

  const useResendCodeMutation = onboardingQuery.application.resendCode.useMutation()
  const onResendOtpCode = () => asyncWithLoading(async () => {
    const phone = state.inputs?.phone
    if (!phone) {
      return
    }
    const res = await useResendCodeMutation.mutateAsync({ticket: state.otp[phone].ticket || ""}, {
      onSuccess: () => unsetError("phone"),
      onError: setError("phone")
    })

    setState((prev) => ({
        ...prev,
        otp: {
          ...prev.otp,
          [phone]: {
            code: "",
            status: "sent",
            ticket: res.ticket
          }
        }
      })
    )
    otpFocuser.focus()
  })

  const useConfirmPhoneMutation = onboardingQuery.application.confirmPhone.useMutation(authState.user.userId)
  const onConfirmOtpCode = () => asyncWithLoading(async () => {
    const phone = state.inputs?.phone
    if (!phone) {
      return
    }

    const otpState = state.otp[phone]
    await useConfirmPhoneMutation.mutateAsync({
      ticket: otpState?.ticket || "",
      code: otpState?.code || ""
    }, {
      onSuccess: () => {
        unsetError("otp")
        setState((prev) => ({
            ...prev,
            otp: {
              ...prev.otp,
              [phone]: {
                ...otpState,
                status: "confirmed"
              }
            }
          })
        )
      },
      onError: (err) => {
        setError("otp")(err)
        setState((prev) => ({
          ...prev,
          otp: {
            ...prev.otp,
            [phone]: {
              ...otpState,
              status: "sent",
              code: "",
            }
          }
        }))
      }
    })
  })

  useEffect(() => {
    if (state.errors.name && state.inputs?.firstName !== undefined) {
      void onUpdateName({
        firstName: state.inputs.firstName,
        lastName: state.inputs.lastName
      });
    }
  }, [onUpdateName, state.inputs?.firstName, state.inputs?.lastName, state.errors.name])

  useEffect(() => {
    if (state.errors.birthdate && state.inputs?.birthdate !== undefined) {
      void onUpdateBirthdate({
        birthdate: state.inputs.birthdate
      });
    }
  }, [onUpdateBirthdate, onUpdateName, state.errors.birthdate, state.inputs?.birthdate])

  useEffect(() => {
    if (state.errors.address && state.inputs?.addressLine1 !== undefined) {
      void onUpdateAddress({
        addressLine1: state.inputs.addressLine1,
        addressLine2: state.inputs.addressLine2,
        city: state.inputs.city,
        stateOrProvince: state.inputs.stateOrProvince,
        postcode: state.inputs.postcode
      });
    }
  }, [
    onUpdateAddress, state.errors.address,
    state.inputs?.addressLine1, state.inputs?.addressLine2,
    state.inputs?.city, state.inputs?.stateOrProvince, state.inputs?.postcode
  ])

  useEffect(() => {
    if (state.inputs?.phone) {
      const phone = state.inputs.phone
      setState((prev) => ({
        ...prev,
        otp: {
          ...prev.otp,
          [phone]: prev.otp[phone] ?
            prev.otp[phone] :
            {
              code: "",
              status: "not-sent"
            }
        }
      }))
    }
  }, [state.inputs?.phone])

  const onChangeInput = (name: InputName) => (newValue: string) => {
    setState((prev) => {
      if (!prev.inputs) {
        return prev
      }
      return {
        ...prev, inputs: {...prev.inputs, [name]: newValue}
      }
    })
  }

  const onChangeBirthdate = (newValue: DateInputValue) => {
    setState((prev) => {
      if (!prev.inputs) {
        return prev
      }
      return {
        ...prev, inputs: {...prev.inputs, birthdate: newValue}
      }
    })
  }

  const updateAllFields = async (): Promise<void> => {
    return sequencePromises([
      () => onUpdateName(state.inputs),
      () => onUpdateBirthdate(state.inputs),
      () => onUpdateAddress(state.inputs)
    ]).then(() => {
    })
  }

  const onSubmit = () => asyncWithLoading(async () => {
    if (!state.inputs) {
      return Promise.reject(Error("inputs are undefined"))
    }
    const phone = state.inputs.phone
    switch (state.otp[phone]?.status) {
      case undefined:
      case "not-sent":
        // return Promise.all([updateAllFields(), onSendOtpCode()])
        return sequencePromises([updateAllFields, onSendOtpCode])
      case "sent":
        // await Promise.all([updateAllFields(), onConfirmOtpCode()])
        await sequencePromises([updateAllFields, onConfirmOtpCode])
        return props.onComplete()
      case "confirmed":
        await updateAllFields()
        return props.onComplete()
    }
  })

  const phone = state.inputs?.phone
  const otpState = phone && state.otp[phone]

  return <Form onSubmit={onSubmit}>
    <InputGroup name={strings["personal_info.name.title"]}>
      <InputGroupRow>
        <TextInput className="flex-1"
                   placeholder={strings["signup.name.first_name.placeholder"]}
                   disabled={isLoading}
                   autoComplete={"given-name"}
                   capitalization={"words"}
                   value={state.inputs?.firstName}
                   error={state.errors.name}
                   errorMessageHidden={true}
                   onChange={onChangeInput("firstName")}
                   onBlur={onBlurName}
        />
        <TextInput className={"flex-1"}
                   placeholder={strings["signup.name.last_name.placeholder"]}
                   disabled={isLoading}
                   autoComplete={"family-name"}
                   capitalization={"words"}
                   value={state.inputs?.lastName}
                   error={state.errors.name}
                   errorMessageHidden={true}
                   onChange={onChangeInput("lastName")}
                   onBlur={onBlurName}
        />
      </InputGroupRow>
      <InlineError error={state.errors.name}/>
    </InputGroup>

    <DateInputGroup
      name={strings["signup.dob.title"]}
      disabled={isLoading}
      value={state.inputs?.birthdate}
      error={state.errors.birthdate}
      onChange={onChangeBirthdate}
      onBlur={onBlurBirthdate}
    />

    <AddressInputGroup
      name={strings["signup.address.title"]}
      disabled={isLoading}
      availableCountries={[authState.user.countryCode]}
      value={state.inputs && {
        addressLine1: state.inputs?.addressLine1,
        addressLine2: state.inputs?.addressLine2,
        city: state.inputs?.city,
        stateOrProvince: state.inputs?.stateOrProvince,
        postcode: state.inputs?.postcode,
        countryCode: authState.user.countryCode,
      }}
      error={state.errors.address}
      onChange={(newValue) => {
        setState((prev) => ({
          ...prev,
          inputs: prev.inputs && {
            ...prev.inputs,
            addressLine1: newValue.addressLine1,
            addressLine2: newValue.addressLine2,
            city: newValue.city,
            stateOrProvince: newValue.stateOrProvince,
            postcode: newValue.postcode,
          }
        }))
      }}
      onBlur={onBlurAddress}
    />

    <InputGroup name={strings["signup.phone_number.title"]}>
      <PhoneInput
        ariaLabel={"Phone number"}
        disabled={isLoading}
        suggestCountryCode={authState.user.countryCode}
        value={state.inputs?.phone}
        onChange={(newValue: string) => {
          const phone = newValue
          setState((prev) => ({
            ...prev,
            inputs: prev.inputs && {
              ...prev.inputs,
              phone: newValue,
            },
            otp: {
              ...prev.otp,
              [phone]: prev.otp[phone] ? prev.otp[phone] :
                {
                  code: "",
                  status: "not-sent"
                }
            }
          }))
        }}
        error={state.errors.phone}
        gadget={(() => {
          if (!otpState) {
            return {
              kind: "button",
              hidden: true,
              text: "",
              isLoading: false,
              onClick: () => {
              }
            }
          }

          const text = (() => {
            // WEB-369: Make Verify/Resend crossfade
            switch (otpState.status) {
              case "not-sent":
                return strings["signup.otp.verify"]
              case "sent":
              case "confirmed":
                return strings["signup.otp.resend"]
            }
          })() || ""
          const onClick = () => {
            switch (otpState.status) {
              case "not-sent":
                onSendOtpCode()
                break;
              case "sent":
                onResendOtpCode()
                break;
              case "confirmed":
                break;

            }
          };
          return {
            kind: "button",
            hidden: isLoading || otpState.status === "confirmed",
            isLoading: isLoading,
            text,
            onClick: onClick
          }
        })()}
      />
    </InputGroup>

    <Collapsable expanded={!!otpState && otpState.status === "sent"} onExpanded={() => otpFocuser.focus()}>
      <InputGroup
        name={strings["signup.otp.title"]}>
        <OtpInput
          focuser={otpFocuser}
          disabled={isLoading || (!!otpState && otpState.status !== "sent")}
          value={otpState ? otpState.code : ""}
          error={state.errors.otp}
          onChange={(newValue) => {
            setState((prev) => {
              const phone = prev.inputs?.phone
              if (!phone) return prev
              const otpState = prev.otp[phone]
              return {
                ...prev,
                otp: {...prev.otp, [phone]: {...otpState, code: newValue}},
                errors: {...prev.errors, otp: undefined}
              }
            })
          }}
          onComplete={onSubmit}
        />
      </InputGroup>
    </Collapsable>

    <ButtonBar align={"center"}>
      <Button type={"submit"} title={strings["general.continue"]} size={"max"} color={"primary-black"}
              loading={isLoading}/>
    </ButtonBar>
  </Form>
}

export default OnboardingForm;