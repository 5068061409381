import {useCallback, useState} from "react"

export const useFileState = () => {
  const [files, setFiles] = useState<File[]>([])

  const addFiles = useCallback((newFiles: File[]) => {
    setFiles((prev) => [...prev, ...newFiles])
  }, [])

  const removeFile = useCallback((index: number) => {
    setFiles((prev) => {
      const newFiles = [...prev]
      newFiles.splice(index, 1)
      return newFiles
    })
  }, [])

  return {
    files,
    addFiles,
    removeFile
  }
}