import * as ReactIntl from "react-intl";
import {useStrings} from "../../strings";

type Style = "percent"

const formatStyles  = {
  percent: {
    style: "percent",
    maximumSignificantDigits: 2
  }
}

type Props = {
  value: number
  style: Style
  withFree?: boolean
}

const FormattedNumber = ({value, ...props}: Props) => {
  const strings = useStrings()
  const style = formatStyles[props.style]

  if (props.withFree && value === 0) return strings["general.free"]

  return <ReactIntl.FormattedNumber
    {...style}
    style={"percent"}
    value={value}
  />
}

export default FormattedNumber;