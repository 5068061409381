import {poll, PollOptions, PollResultPromise} from "../helpers/poller";
import {Api, useApi} from "./axios";
import {SubmitRequest, SubmitResponse, VerificationResponse} from "./whales/Verification";
import {Verification} from "../components/domain/verifications/domain";


type SubmitTransferSourceOfFundsRequest = {
  verificationId: string
  sourceOfFunds: string
  documents: File[]
}

export class VerificationApi {
  constructor(private api: Api) {
  }

  get(id: string): Promise<Verification> {
    return this.api.get<VerificationResponse>("/verification/v1/get", {verificationId: id})
      .then((r) => Verification.fromApi(r.payload))
  }

  getPublic(id: string, ticket: string): Promise<Verification> {
    return this.api.get<VerificationResponse>(
      "/verification/public/v1/get",
      {verificationId: id, accessTicket: ticket})
      .then((r) => Verification.fromApi(r.payload))
  }

  getPublicOrAuthorized(id: string, ticket?: string): Promise<Verification> {
    return ticket
      ? this.getPublic(id, ticket)
      : this.get(id)
  }

  pollVerification(v: Verification, options: PollOptions<Verification>): PollResultPromise<Verification> {
    return poll(() => this.getPublicOrAuthorized(v.id, v.ticket), options)
  }

  submitDocument(id: string, files: File[]): Promise<Verification> {
    const formData = new FormData()
    formData.append("verificationId", id)
    return this.api.postFiles<VerificationResponse>("/verification/v1/document/submit", formData, files)
      .then((r) => Verification.fromApi(r.payload))
  }

  submit(req: SubmitRequest): Promise<Verification> {
    return this.api.post<SubmitResponse, SubmitRequest>("/verification/v1/submit", req)
      .then((r) => Verification.fromApi(r.payload))
  }

  submitTransferSourceOfFunds(req: SubmitTransferSourceOfFundsRequest): Promise<Verification> {
    const formData = new FormData()
    formData.append("verificationId", req.verificationId)
    formData.append("sourceOfFunds", req.sourceOfFunds)
    return this.api.postFiles<VerificationResponse>("/verification/v1/transfer-source-of-funds/submit", formData, req.documents)
      .then((r) => Verification.fromApi(r.payload))
  }
}

export function useVerificationApi() {
  const api = useApi()
  return new VerificationApi(api)
}