import React from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import "./ProgressRow.scss"
import Caption from "../../Caption";
import {PropsClassName} from "../../../../helpers/props";
import cx from "classnames";

const ProgressIndicator = (props: { progressFraction: number }) => {
  return (
    <ProgressBar
      completed={props.progressFraction * 100}
      bgColor={"var(--clr-primary-black)"}
      baseBgColor={"var(--clr-primary-20)"}
      isLabelVisible={false}
      height={"10px"}
    />
  )
}

type Props = {
  caption: React.ReactNode
  content: React.ReactNode
  secondary?: React.ReactNode
  progressFraction: number
} & PropsClassName

const ProgressRow = (props: Props) => {
  return (
    <div className={cx("progress-row", props.className)}>
      <div className={"progress-row__caption-content-stack"}>
        <div className={"progress-row__caption"}>
          <Caption size={"3"} text={props.caption}/>
        </div>
        <div className={"progress-row__content"}>
          <Caption size={"2"} text={props.content}/>
        </div>
      </div>
      <div className={"progress-row__indicator"}>
        <ProgressIndicator progressFraction={props.progressFraction}/>
      </div>
      {props.secondary && (
        <div className={"progress-row__secondary-stack"}>
          <div className={"progress-row__secondary"}>
            <Caption size={"2"} color={"primary-50"} text={props.secondary}/>
          </div>
        </div>
      )}
    </div>
  )
}

export default ProgressRow