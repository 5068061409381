import {AlertPopUpContext, AlertView} from "../../../components/ui/Errors";
import {useStrings} from "../../../strings";
import {Button} from "../../../components/ui/Buttons";
import {Transfer} from "../../../models/transfers/Transfer";
import FormattedMoney from "../../../components/formatters/money/FormattedMoney";

const TransferSourceAmountIncreasedAlertView = (props: {
  ctx: AlertPopUpContext
  transfer: Transfer
  onCancel: () => Promise<void>
  onContinue: () => Promise<void>
}) => {
  const strings = useStrings()

  return <AlertView
    title={strings["transfer.frozen_quote_unavailable.title"]}
    content={strings["transfer.frozen_quote_unavailable.subtitle"](
      <FormattedMoney value={props.transfer.destinationMoney}/>
    )}
    primaryButton={(btnProps) =>
      <Button type={"button"} title={strings["general.continue"]} {...btnProps}/>
    }
    secondaryButton={(btnProps) =>
      <Button type={"button"} title={strings["general.cancel"]} {...btnProps}/>
    }
    {...props.ctx}
  />
}

export default TransferSourceAmountIncreasedAlertView