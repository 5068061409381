import {PropsOnComplete} from "../../../helpers/props";
import {Currency} from "../../../helpers/money";
import {Section} from "../../../components/ui/collections/Section";
import CurrencyIcon from "../../../components/ui/icons/CurrencyIcon";
import {BodyTitle} from "../../../components/ui/Labels";
import Icon from "../../../components/ui/icons/Icon";
import {RecipientCreateContext} from "./recipient-create-controller";
import {TitleSubtitleLeftScreen} from "../../../components/ui/screen/TitleSubtitleScreen";
import {useStrings} from "../../../strings";
import {useIntl} from "react-intl";
import {useAsyncLoading} from "../../../helpers/hooks";
import DefaultRow from "../../../components/ui/collections/rows/DefaultRow";
import {useAlertStack} from "../../../providers/alert-stack";

type Props = {
  context: RecipientCreateContext
  availableCurrencies: Currency[]
} & PropsOnComplete<Currency>

export const RecipientCreateCurrencyStep = (props: Props) => {
  const {isLoading, asyncWithLoading} = useAsyncLoading(false)
  const strings = useStrings()
  const intl = useIntl()
  const alertStack = useAlertStack()
  const rows = props.availableCurrencies
    .map((currency) => {
      return (
        <DefaultRow
          key={currency.code}
          title={<BodyTitle children={currency.name(intl)}/>}
          leadingImage={<CurrencyIcon currencyCode={currency.code} className={"mr-[18px]"} size={"big"}/>}
          trailingIcon={<Icon name={"chevron-right"}/>}
          disabled={isLoading}
          onClickAsync={() => asyncWithLoading(() => props.onComplete(currency).catch(e => alertStack.showError(e)))}
        />
      );
    }) || [];

  return (
    <TitleSubtitleLeftScreen
      title={strings["add_recipient.currency.title"]}
      subtitle={strings["add_recipient.currency.subtitle"]}
    >
      <Section>
        {rows}
      </Section>
    </TitleSubtitleLeftScreen>
  )
}