type AdData = {
    gclid?: string
}
export class AdStorage {
    static key: string = "ad_storage"
    static setData(adData: AdData): void {
        localStorage.setItem(this.key, JSON.stringify(adData));
    }

    static getData(): AdData | null {
        const data = localStorage.getItem(this.key);
        if (data) {
            return JSON.parse(data);
        }
        return null;
    }
}