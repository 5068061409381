import {useT} from "@transifex/react";
import React, {useMemo} from "react";
import {UseT} from "./services/transifex";
import {iosStrings} from "./strings-ios";

export type StringsObj = ReturnType<typeof useStrings>
export type StringKey = keyof StringsObj
export const useStrings = () => {
  const t = useT()

  // Keep this sorted alphabetically, IDEA Option + Enter
  const custom = (t: UseT) => ({
    "account.settings": t("Settings", {_key: "account.settings"}),
    "add_recipient.bank_account_details.title": t("Recipient bank account details", {_key: "add_recipient.bank_account_details.title"}),
    "address.country.title": t("Country", {_key: "address.country.title"}),
    "business.id.share_copy": t("Send this link to your UBO or Director. It will guide them to ID upload and selfie capture", {_key: "business.id.share_copy"}),
    "business.id.verify_in_app": t("Verify in app", {_key: "business.id.verify_in_app"}),
    "business.proof_of_authority.row.title": t("Mandate from director", {_key: "business.proof_of_authority.row.title"}),
    "business_signup.address.title": t("Business address", {_key: "business_signup.address.title"}),
    "business_signup.applicant_info_form.title": t("Your profile", {_key: "business_signup.applicant_info.title"}),
    "business_signup.company_information.title": t("Company information", {_key: "business_signup.company_information.title"}),
    "business_signup.identifier.placeholder": t("Enter your business ID", {_key: "business_signup.identifier.placeholder"}),
    "business_signup.identifier.title": t("Business identifier", {_key: "business_signup.identifier.title"}),
    "business_signup.key_people_form.title": t("Key team member info", {_key: "business_signup.key_people_form.title"}),
    "business_signup.legal_name.placeholder": t("Enter your official business name", {_key: "business_signup.legal_name.placeholder"}),
    "contact_us.chat_now": t("Chat now", {_key: "contact_us.chat_now"}),
    "contact_us.options.help_center": t("Help center", {_key: "contact_us.options.help_center"}),
    "contact_us.options.in_app": t("Chat with us on your phone", {_key: "contact_us.options.in_app"}),
    "contact_us.title": t("How can we help?", {_key: "contact_us.title"}),
    "cookie.accept": t("Accept", {_key: "cookie.accept"}),
    "cookie.body": (cookiesLink: React.ReactNode) => t("If you accept, we’ll use {cookies_link} to make your experience better.", {
      _key: "cookie.body",
      cookies_link: cookiesLink
    }),
    "cookie.body.2": (cookiesLink: React.ReactNode) => t("If you accept, we’ll use {cookies_link} to observe how you use our site so we can improve it where necessary.", {
      _key: "cookie.body.2",
      cookies_link: cookiesLink
    }),
    "cookie.decline": t("Decline", {_key: "cookie.decline"}),
    "cookie.link": t("cookies", {
      _key: "cookie.link",
      _comment: "If you accept, we’ll use {cookie.link} to observe how you use our site so we can improve it where necessary."
    }),
    "deeplink.open_in_app.subtitle": t("This action is best experienced in our iOS and Android apps.", {_key: "deeplink.open_in_app.subtitle"}),
    "deeplink.open_in_app.title": t("Continue in the app", {_key: "deeplink.open_in_app.title"}),
    "document.dropzone.rejectedMessage": t("Please upload an image (png, jpg) or a PDF.", {_key: "document.dropzone.rejectedMessage"}),
    "document.dropzone.subtitle": t("PDF, JPEG, or PNG", {_key: "document.dropzone.subtitle"}),
    "document.dropzone.title": t("Upload", {_key: "document.dropzone.title"}),
    "document.dropzone.uploadedFiles": t("Uploaded files", {_key: "document.dropzone.uploadedFiles"}),
    "download_app.subtitle.desktop": t("The full Atlantic Money experience is only available on iOS and Android, though we’re working hard to make it available on web!", {_key: "download_app.subtitle.desktop"}),
    "download_app.subtitle.mobile": t("The full Atlantic Money experience is available in our iOS and Android apps!", {_key: "download_app.subtitle.mobile"}),
    "download_app.title": t("Continue in the app", {_key: "download_app.title"}),
    "error.404.subtitle": t("We can’t find the page that you’re looking for.", {_key: "error.404.subtitle"}),
    "error.404.title": t("Oops!", {_key: "error.404.title"}),
    "error.generic_screen.subtitle": t("We're having trouble displaying the page you requested.", {_key: "error.generic_screen.subtitle"}),
    "error.generic_screen.title": t("Oops! Something Went Wrong", {_key: "error.generic_screen.title"}),
    "general.copy_link": t("Copy link", {_key: "general.copy_link"}),
    "general.get_app": t("Get the app", {_key: "general.get_app"}),
    "general.go_home": t("Go Home", {_key: "general.go_home"}),
    "general.logout": t("Logout", {_key: "general.logout"}),
    "onboarding.disclaimer.agree_checkbox_label": t("I have read and agree to the terms.", {_key: "onboarding.disclaimer.agree_checkbox_label"}),
    "onboarding.disclaimer.agree_request": t("Please agree to the terms in order to continue.", {_key: "onboarding.disclaimer.agree_request"}),
    "personal_info.name.title": t("Name", {_key: "personal_info.name.title"}),
    "selfie.intro.title": t("Use your phone’s camera to scan this QR code", {_key: "selfie.intro.title"}),
    "selfie_id.verification_polling_expired.subtitle": t("We haven’t received any photos of your ID yet. Please make sure you've sent them in – we'll double check on our end too.", {_key: "selfie_id.verification_polling_expired.subtitle"}),
    "selfie_id.verification_polling_expired.title": t("Your ID", {_key: "selfie_id.verification_polling_expired.title"}),
    "sidebar.items.bulk": t("Mass payments", {_key: "sidebar.items.bulk"}),
    "sidebar.items.help": t("Help", {_key: "sidebar.items.help"}),
    "sidebar.items.history": t("Transfer history", {_key: "sidebar.items.history"}),
    "sidebar.items.limits": t("Limits", {_key: "sidebar.items.limits"}),
    "sidebar.items.log_in": t("Log in", {_key: "sidebar.items.log_in"}),
    "sidebar.items.log_out": t("Log out", {_key: "sidebar.items.log_out"}),
    "sidebar.items.recipients": t("Recipients", {_key: "sidebar.items.recipients"}),
    "sidebar.items.referral": t("Get free transfers", {_key: "sidebar.items.referral"}),
    "sidebar.items.send": t("Send money", {_key: "sidebar.items.send"}),
    "sidebar.items.settings": t("Settings", {_key: "sidebar.items.settings"}),
    "signup.business_unavailable.body": t("Opening a business account is currently only possible on our mobile apps - download the app to get started.", {_key: "signup.business_unavailable.body"}),
    "signup.country.placeholder.business": t("Country of business registration", {_key: "signup.country.placeholder.business"}),
    "signup.country.placeholder.individual": t("Your country", {_key: "signup.country.placeholder.individual"}),
    "signup.form.title": t("Let’s finish your profile", {_key: "signup.form.title"}),
    "signup.otp.resend": t("Resend", {_key: "signup.otp.resend"}),
    "signup.otp.title": t("Confirmation code", {_key: "signup.otp.title"}),
    "signup.otp.verify": t("Verify", {_key: "signup.otp.verify"}),
    "signup.password.placeholder.long": t("Password at least 10 characters", {_key: "signup.password.placeholder.long"}),
    "signup.questioner.other_placeholder": t("Tell how did you hear about us", {_key: "signup.questioner.other_placeholder"}),
    "signup.to_log_in": t("Already have an account? Log in", {_key: "signup.to_log_in"}),
    "tier.row.remaining_amount": (amount: React.ReactNode) => t("{amount} remaining", {
      _key: "tier.row.remaining_amount",
      amount
    }),
    "transfer.bulk.table.header.bank_details": t("Bank details", {_key: "transfer.bulk.table.header.bank_details"}),
    "transfer.bulk.table.header.beneficiary_name": t("Beneficiary name", {_key: "transfer.bulk.table.header.beneficiary_name"}),
    "transfer.bulk.table.header.currency": t("Currency", {_key: "transfer.bulk.table.header.currency"}),
    "transfer.bulk.table.header.id": t("ID", {_key: "transfer.bulk.table.header.id"}),
    "transfer.review.agree_to_terms.text": (terms_and_conditions: React.ReactNode) =>
      t("By confirming this transfer, I agree to the {terms_and_conditions}.", {
        _key: "transfer.review.agree_to_terms.text",
        terms_and_conditions
      }),
    "transfer.review.agree_to_terms.underlined": t("terms and conditions", {_key: "transfer.review.agree_to_terms.underlined"}),
    "transfer.estimate.dst.label": t("Receive", {_key: "transfer.estimate.dst.label"}),
    "transfer.estimate.src.label": t("Send", {_key: "transfer.estimate.src.label"}),
    "verification.finished": t("I've finished verifying", {_key: "verification.finished"}),
    "web.launch.sign_up": t("No account? Sign up", {_key: "web.launch.sign_up"}),
    "web.transfer_source_of_funds.email_title": t("Or, you can email your documents to:", {_key: "web.transfer_source_of_funds.email_title"}),
    "web.transfer_source_of_funds.upload_title": t("Upload your documents here", {_key: "web.transfer_source_of_funds.upload_title"}),
    "transfer.history.no_transfers.title": t("No transfers yet", {_key: "transfer.history.no_transfers.title"}),
    "transfer.history.no_transfers.subtitle": t("Your transfer history will appear here once you've made transactions.", {_key: "transfer.history.no_transfers.subtitle"}),
  })

  const plurals = (t: UseT) => ({
    "delivery.business_days": (count: number) => t("{count, plural, one {{count} business day} other {{count} business days}}", {_key: "delivery.business_days", _tags: "ios", count}),
    "portals.delivery_option.basic_description.future": (count: number) => t(
      "{count, plural, one {Your transfer should arrive within {count} business day after we receive a payment.} other {Your transfer should arrive within {count} business days after we receive a payment.}}",
      {_key: "portals.delivery_option.basic_description.future", _tags: "ios", count}),
  })

  type T1 = keyof ReturnType<typeof iosStrings>
  type T2 = keyof ReturnType<typeof custom>
  type T3 = keyof ReturnType<typeof plurals>
  type Overlap = T1 & T2 & T3
  // If you got error here looks like strings-ios.tsx and strings.tsx have overlapping keys
  // Hover over error to see exact keys
  type _ErrorIfOverlapping<_T extends never = Overlap> = void;


  return useMemo(() => Object.assign({}, iosStrings(t), custom(t), plurals(t)), [t])
}