import React, {useRef, useState} from "react";
import {InterfaceTemplates, InterfaceTemplateValues} from "../domain";
import {TitleSubtitleScreen} from "../../../ui/screen/TitleSubtitleScreen";
import {LimiterOverflow} from "../../../ui/Limiter";
import {useAsyncLoading} from "../../../../helpers/hooks";
import {CommonInterfaceTemplateScreenProps} from "./common";
import SelectRow from "./SelectRow";
import {BasicForm} from "../../../ui/input/Inputs";
import {InterfaceTemplateTextInput} from "../inputs/InterfaceTemplateTextInput";
import Collapsable from "../../../ui/Collapsable";
import {Button} from "../../../ui/Buttons";
import {useStrings} from "../../../../strings";


type Props =
  CommonInterfaceTemplateScreenProps<InterfaceTemplates.SingleSelect, InterfaceTemplateValues.SingleSelect>
  & {
  otherValue: InterfaceTemplateValues.Text,
  onChangeOtherValue: (value: InterfaceTemplateValues.Text) => void,
}

const SingleSelectRow = ({option, template, ...props}: {
  template: InterfaceTemplates.SingleSelect,
  option: InterfaceTemplateValues.SingleSelectOption
  otherValue: InterfaceTemplateValues.Text
  onChangeOtherValue: (value: InterfaceTemplateValues.Text) => void
  selected: boolean
  onSelect: (option: InterfaceTemplateValues.SingleSelectOption | null) => void
  isLoading: boolean
  onSubmit: () => Promise<void>
}) => {
  const strings = useStrings()
  const otherInputRef = useRef<HTMLInputElement>(null)
  const otherTemplate = template.otherTextEntry

  const selectedBottomNode = option.isOther && otherTemplate ? (
    <Collapsable expanded={props.selected} onExpanded={() => otherInputRef.current?.focus()}>
      <div className={"pt-3"}>
        <InterfaceTemplateTextInput
          ref={otherInputRef}
          template={otherTemplate}
          disabled={props.isLoading}
          value={props.otherValue}
          onChange={props.onChangeOtherValue}
          error={undefined}
        />
        <Button className={"mt-[32px]"}
                type={"submit"}
                loading={props.isLoading}
                stopPropagation={true}
                title={strings["general.continue"]}
                size={"max"}
                color={"primary-black"}/>
      </div>
    </Collapsable>
  ) : null

  return (
    <SelectRow
      key={option.id}
      selected={props.selected}
      rightIcon={function () {
        if (props.selected && selectedBottomNode != null) {
          return "close"
        }
        return "chevron-right"
      }()}
      option={option}
      disabled={props.isLoading}
      bottomNode={selectedBottomNode}
      submitOnClick={selectedBottomNode === null}
      onRowClick={(newOption) => props.onSelect(newOption)}
      onSubmit={props.onSubmit}
    />
  )
}

export const InterfaceTemplateSingleSelectForm = ({template, otherValue, onChangeOtherValue, onComplete}: Props) => {
  const [selectedId, setSelectedId] = useState<string | null>()
  const asyncWithLoading = useAsyncLoading(false)

  const onSubmit = async (ssv?: InterfaceTemplateValues.SingleSelect) => {
    return asyncWithLoading.asyncWithLoading(() => onComplete(ssv ?? {
      kind: "singleSelect",
      singleSelectValue: {
        option: template.options.find((option) => option.id === selectedId)!,
        otherText: otherValue.textValue
      },
    }))
  }

  return (
    <BasicForm onSubmit={onSubmit}>
      {template.options.map((option) =>
        <SingleSelectRow
          key={option.id}
          template={template}
          option={option}
          otherValue={otherValue}
          onChangeOtherValue={onChangeOtherValue}
          selected={option.id === selectedId}
          onSelect={(newOption) => setSelectedId(newOption?.id ?? null)}
          isLoading={asyncWithLoading.isLoading}
          onSubmit={onSubmit}
        />
      )}
    </BasicForm>
  )
}

const InterfaceTemplateSingleSelectScreen = (props: Props) => {
  return (
    <TitleSubtitleScreen
      title={props.template.title}
      subtitle={props.template.subtitle}
      titleAlign={props.titleAlign}
    >
      <LimiterOverflow overflow={"16px"}>
        <InterfaceTemplateSingleSelectForm {...props}/>
      </LimiterOverflow>
    </TitleSubtitleScreen>
  )
}

export default InterfaceTemplateSingleSelectScreen