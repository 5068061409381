import {BusinessInfo} from "../../models/client/client-info";
import {useStrings} from "../../strings";
import FormattedAddress from "../../components/formatters/FormattedAddress";
import React from "react";
import InfoRow from "../../components/ui/collections/rows/InfoRow";

type Props = {
  businessInfo: BusinessInfo
}

const BusinessInfoRows = ({businessInfo}: Props) => {
  const strings = useStrings()
  return (
    <>
      <InfoRow caption={strings["business.name.title"]} content={businessInfo.legalName}/>
      <InfoRow caption={strings["business.identifier.title"]} content={businessInfo.identifier}/>
      <InfoRow
        caption={strings["business.registered_address.title"]}
        content={
          <FormattedAddress style={"one-line"} value={businessInfo.registeredAddress}/>
        }/>
      <InfoRow
        caption={strings["business.trading_address.title"]}
        content={
          <FormattedAddress style={"one-line"} value={businessInfo.tradingAddress}/>
        }/>
    </>
  )
}

export default BusinessInfoRows