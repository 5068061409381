import Sidebar from "../../sidebar/Sidebar";
import {ErrorStack} from "../Errors";
import './MainLayout.scss'
import {StatefulContentScreen} from "../ContentScreen";
import {useAuthValue} from "../../../atoms/auth";
import {ErrorBoundaryLayout} from "./ErrorBoundaryLayout";

const MainLayout = () => {
  const authValue = useAuthValue()
  return (
    <div className={"flex flex-row"}>
      <Sidebar className={"flex-shrink-0"} isAuthorized={authValue.authenticated}/>

      <div className="main-layout__content">
        <StatefulContentScreen className={"flex-1"}>
          <ErrorBoundaryLayout/>
        </StatefulContentScreen>
        <ErrorStack/>
      </div>
    </div>
  )
};

export default MainLayout;