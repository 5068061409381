import GenericErrorPage from "../../../pages/generic/GenericErrorPage";
import * as Sentry from "@sentry/react";
import {Outlet, useLocation} from "react-router-dom";
import {PropsWithChildren, useEffect, useRef} from "react";


export const ErrorBoundary = ({children}: PropsWithChildren) => {
  const location = useLocation()
  const errorBoundaryRef = useRef<Sentry.ErrorBoundary>(null)

  useEffect(() => {
    if (errorBoundaryRef.current) {
      errorBoundaryRef.current.resetErrorBoundary()
    }
  }, [location])

  return (
    <Sentry.ErrorBoundary ref={errorBoundaryRef} fallback={
      <div className={"min-h-[var(--100vh)] flex flex-col"}>
        <GenericErrorPage/>
      </div>
    }>
      {children}
    </Sentry.ErrorBoundary>
  )
}

export const ErrorBoundaryLayout = () => {
  return (
    <ErrorBoundary>
      <Outlet/>
    </ErrorBoundary>
  )
}