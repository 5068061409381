import {Button} from "../../ui/Buttons";
import {Checkbox, Form} from "../../ui/input/Inputs";
import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  size,
  useDismiss,
  useFloating,
  useInteractions,
  useTransitionStyles
} from "@floating-ui/react";
import ButtonBar from "../../ui/ButtonBar";
import {useEffect, useMemo, useState} from "react";
import {useAsyncLoading} from "../../../helpers/hooks";
import {useAlertStack} from "../../../providers/alert-stack";
import {BusinessContact, BusinessContactRoleUpdate} from "../../../api/whales/Onboarding";
import {Section} from "../../ui/collections/Section";
import Icon from "../../ui/icons/Icon";
import cx from "classnames";
import {useBusinessOnboardingQuery} from "../../../api/onboarding";
import {BusinessContactRole} from "../../../api/whales/Common";
import {BusinessContactExt} from "../../domain/onboarding/business";
import {TitleSubtitleCenterScreen} from "../../ui/screen/TitleSubtitleScreen";
import {OnboardingCtx, useOnboardingNavStack} from "./BusinessOnboardingScreen";
import {AnyBusinessContactForm} from "./BusinessContactForm";
import {useStrings} from "../../../strings";
import {PropsClassName, PropsOnComplete} from "../../../helpers/props";
import BusinessDisclaimer from "./BusinessDisclaimer";
import {useAuthorizedAuthValue} from "../../../atoms/auth";
import {LimiterOverflow} from "../../ui/Limiter";
import {useSideIllustration} from "../../../atoms/side-illustration";
import Collapsable from "../../ui/Collapsable";
import {WithLearnMoreSuffix} from "../../ui/LearnMore";
import {Links} from "../../../helpers/Links";
import DefaultRow from "../../ui/collections/rows/DefaultRow";

type Props = {
  subtitle?: string
} & PropsOnComplete

type State = {
  roleMap?: { [key: string]: BusinessContactRole[] } // contact_id -> roles
  hasNoUBOs: boolean
}

const BusinessKeyPeople = (props: Props) => {
  const strings = useStrings()
  const navStack = useOnboardingNavStack()
  const errorStack = useAlertStack()
  const authState = useAuthorizedAuthValue()
  const {isLoading, asyncWithLoading} = useAsyncLoading(false)

  const businessOnboardingQuery = useBusinessOnboardingQuery()
  const updateRoles = businessOnboardingQuery.application.updateRoles.useMutation(authState.user.userId)
  const updateHasUBOs = businessOnboardingQuery.application.updateHasUbos.useMutation(authState.user.userId)
  const {data: application} = businessOnboardingQuery.application.get.useQuery(authState.user.userId)

  const [state, setState] = useState<State>({
    roleMap: undefined,
    hasNoUBOs: false
  })

  const hasUBOInContacts = useMemo(() => {
    if (state.roleMap === undefined) return false

    for (const roleList of Object.values(state.roleMap)) {
      if (roleList.includes("ubo")) return true
    }
    return false
  }, [state.roleMap])

  useEffect(() => {
    const rolesSnapshot: { [k: string]: BusinessContactRole[] } = {}
    application?.payload.contacts.forEach((contact) => {
      rolesSnapshot[contact.contactId] = contact.roles
    })
    setState(prev => ({...prev, roleMap: prev.roleMap ?? rolesSnapshot}))
  }, [application?.payload.contacts])


  const onUpdateRoles = async () => {
    const allRoles: BusinessContactRole[] = ["ubo", "director"]
    if (application === undefined) return
    const updates: BusinessContactRoleUpdate[] = application?.payload.contacts.flatMap((contact) => {
      if (state.roleMap === undefined || state.roleMap[contact.contactId] === undefined) return []
      const roleList = state.roleMap[contact.contactId]
      return allRoles.map((role) => {
        return {
          contactId: contact.contactId,
          roles: [role],
          operation: roleList.includes(role) ? "add" : "remove",
        }
      })
    })
    await updateRoles.mutateAsync({updates: updates})
    return updateHasUBOs.mutateAsync({hasUBOs: hasUBOInContacts || !state.hasNoUBOs})
  }

  const onFormSubmit = () => {
    return asyncWithLoading(() => onUpdateRoles().then(() => props.onComplete()).catch((e) => errorStack.showError(e)))
  }

  const onAddRole = (contactId: string, role: BusinessContactRole) => setState(prev => ({
    ...prev, roleMap: prev.roleMap && {
      ...prev.roleMap,
      [contactId]: [...(prev.roleMap[contactId] ?? []), role]
    }
  }))

  const onRemoveRole = (contactId: string, role: BusinessContactRole) => setState(prev => ({
    ...prev, roleMap: prev.roleMap && {
      ...prev.roleMap,
      [contactId]: prev.roleMap[contactId]?.filter(v => v !== role)
    }
  }))

  const onAddContact = async (role: BusinessContactRole) => {
    await onUpdateRoles().catch((e) => errorStack.showError(e))
    navStack.push(AnyBusinessContactForm, {
      role,
      onComplete: async () => {
        navStack.pop?.()
      }
    })
  }

  return (
    <TitleSubtitleCenterScreen title={strings["business.key_people.title"]}
                               subtitle={props.subtitle}>
      <Form onSubmit={onFormSubmit}>
        <Section title={strings["business.key_people.ubos.title"]}
                 subtitle={<WithLearnMoreSuffix
                   url={Links.helpCenter.articles.keyPeople}
                   children={strings["business.key_people.ubos.subtitle"]}/>
                 }>
          <LimiterOverflow overflow={"16px"}>
            <KeyPeopleList
              className={"mb-[16px]"}
              role={"ubo"}
              hideAddRow={state.hasNoUBOs}
              contacts={application?.payload.contacts || []} roleMap={state.roleMap ?? {}}
              onAddContact={onAddContact}
              onAddRole={onAddRole}
              onRemoveRole={onRemoveRole}
            />
          </LimiterOverflow>
          {!hasUBOInContacts &&
              <Checkbox isChecked={state.hasNoUBOs} label={strings["business.key_people.no_ubos"]}
                        onChange={checked => setState(prev => ({...prev, hasNoUBOs: checked}))}/>
          }
        </Section>

        <Section className={"mt-[32px]"} title={strings["business.key_people.directors.title"]}>
          <LimiterOverflow overflow={"16px"}>
            <KeyPeopleList
              role={"director"}
              contacts={application?.payload.contacts || []}
              roleMap={state.roleMap ?? {}}
              onAddContact={onAddContact}
              onAddRole={onAddRole}
              onRemoveRole={onRemoveRole}
            />
          </LimiterOverflow>
        </Section>

        <ButtonBar align={"center"} className={"mt-[24px]"}>
          <Button type={"submit"} title={strings["general.continue"]} size={"max"} color={"primary-black"}
                  loading={isLoading}/>
        </ButtonBar>
      </Form>
    </TitleSubtitleCenterScreen>
  )
};

type KeyPeopleSectionProps = {
  role: BusinessContactRole
  contacts: BusinessContact[]
  hideAddRow?: boolean
  roleMap: { [k: string]: BusinessContactRole[] }
  onAddContact: (role: BusinessContactRole) => Promise<void>
  onAddRole: (contactId: string, role: BusinessContactRole) => void
  onRemoveRole: (contactId: string, role: BusinessContactRole) => void
} & PropsClassName

const KeyPeopleList = (props: KeyPeopleSectionProps) => {
  const strings = useStrings()
  const addRowTitle = function (): string | undefined {
    switch (props.role) {
      case "ubo":
        return strings["business.key_people.add_ubo"]
      case "director":
        return strings["business.key_people.add_director"]
    }
  }()

  const [isAddOpen, setIsAddOpen] = useState(false)

  const {refs, floatingStyles, context} = useFloating({
    placement: "bottom-start",
    open: isAddOpen,
    onOpenChange: setIsAddOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset({mainAxis: 8}),
      flip({padding: 10}),
      size({
        apply({rects, elements}) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`
          });
        }
      })
    ]
  });

  const dismiss = useDismiss(context);
  const {isMounted, styles: transitionStyles} = useTransitionStyles(context, {
    duration: 200,
    close: {
      opacity: 0,
    },
    open: {
      opacity: 1,
    }
  });
  const {getReferenceProps, getFloatingProps} = useInteractions([
    dismiss,
  ]);

  const filteredContacts = useMemo(() => {
    return props.contacts.filter((contact) => props.roleMap[contact.contactId]?.includes(props.role))
  }, [props.contacts, props.roleMap, props.role])

  return (
    <Collapsable expanded={!props.hideAddRow}>
      <div className={cx(props.className)}>
        <div ref={refs.setReference} {...getReferenceProps()}>
          <DefaultRow
            title={addRowTitle}
            onClick={() => setIsAddOpen(!isAddOpen)}
            active={isAddOpen}
            bordered={true}
            trailingIcon={<Icon name={"plus"}/>}
          />
        </div>
        {isMounted &&
            <FloatingPortal>
                <div ref={refs.setFloating} style={{...floatingStyles, ...transitionStyles}} {...getFloatingProps()}>
                    <div className={"w-full bg-secondary border border-primary-20 rounded-[8px]"}>
                      {props.contacts.map((contact) => {
                        const checked = props.roleMap[contact.contactId]?.includes(props.role) || false
                        const onChange = (checked: boolean) => {
                          if (checked)
                            props.onAddRole(contact.contactId, props.role)
                          else
                            props.onRemoveRole(contact.contactId, props.role)
                        }
                        return <DefaultRow key={contact.contactId}
                                           title={BusinessContactExt.fullName(contact)}
                                           onClick={() => onChange(!checked)}
                                           bordered={true}
                                           trailingIcon={<Checkbox isChecked={checked}/>}/>
                      })}
                        <DefaultRow
                            title={strings["business.key_people.add_person"]}
                            onClickAsync={() => props.onAddContact(props.role)}
                            trailingIcon={<Icon name={"user-plus-01"}/>}
                            bordered={false}
                        />
                    </div>
                </div>
            </FloatingPortal>}
        {filteredContacts.map((contact) => {
          return <DefaultRow key={contact.contactId} title={BusinessContactExt.fullName(contact)}/>
        })}
      </div>
    </Collapsable>
  )
}

export const BusinessSignUpKeyPeople = (props: OnboardingCtx) => {
  const navStack = useOnboardingNavStack()
  useSideIllustration("profile-2-silhouettes")

  return <BusinessKeyPeople {...props} onComplete={async () => {
    navStack.push(BusinessDisclaimer, {})
  }}/>
}

export const BusinessUpdateKeyPeople = (props: PropsOnComplete) => {
  const strings = useStrings()
  const businessOnboardingQuery = useBusinessOnboardingQuery()
  const authState = useAuthorizedAuthValue()
  const checksConfirm = businessOnboardingQuery.application.checksConfirm.useMutation(authState.user.userId)

  return <BusinessKeyPeople {...props} subtitle={strings["add_recipient.review.subtitle"]} onComplete={async () => {
    await checksConfirm.mutateAsync({checks: ["business_key_people"]})
    await props.onComplete()
  }}/>
}
