import React, {
  HTMLInputTypeAttribute,
  PropsWithChildren,
  RefObject,
  useCallback,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState
} from "react";
import "./Inputs.scss"
import cx from "classnames";
import Caption from "../Caption";
import Skeleton, {SkeletonAdjust} from "../Skeleton";
import {InlineError} from "../Errors";
import {useDelayUnmount} from "../../../helpers/hooks";
import {defaultAnimDurationMillis} from "../../../variables";
import {IMask, useIMask} from "react-imask";
import moment from "moment";
import 'moment/min/locales';
import React18OtpInput from "../React18OtpInput";
import scrollIntoView from "scroll-into-view";
import {InlineButton} from "../Buttons";
import {InlinePicker, PickerOption} from "../Picker";
import Limiter from "../Limiter";
import Icon from "../icons/Icon";
import {useMergeRefs} from "@floating-ui/react";
import {PropsClassName} from "../../../helpers/props";
import Formatter from "../../formatters/Formatter";
import {useDisplayErrorMessage} from "../../../helpers/errors";
import Collapsable from "../Collapsable";

export type TextAutocomplete =
  "address-line-1" | "address-line-2" | "address-level-2" | "address-level-1" | "postal-code" |
  "given-name" | "family-name" | "bday" | "bday-day" | "bday-month" | "bday-year";

export type InputCapitalization = "none" | "characters" | "words" | "sentences"

type ButtonGadget = {
  kind: "button"
  hidden?: boolean;
  text: string;
  isLoading: boolean;
  onClick: () => void;
}

type SelectGadget = {
  kind: "select"
  options?: PickerOption[]
  disabled?: boolean
  value: PickerOption | null
  onChange: (value: PickerOption | null) => void
}

type GadgetType = ButtonGadget | SelectGadget

export interface CommonInputPropsGeneric<T> {
  id?: string;
  className?: string;
  disabled?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  value?: T;
  onChange?: (newValue: T) => void;
  onFocus?: () => void
  onBlur?: () => void
  onValidate?: (s: string) => Promise<any>
  error: any
  hint?: React.ReactNode
  isLoading?: boolean
  errorMessageHidden?: boolean
  ariaLabel?: string
  gadget?: GadgetType
  leadingNode?: () => React.ReactNode
  skeleton?: {
    adjust?: Partial<SkeletonAdjust>
  },
}

export type CommonInputProps = CommonInputPropsGeneric<string>

export type InputComponentProps = {
  id?: string
  innerRef?: React.Ref<HTMLInputElement>
  className?: string
  type: HTMLInputTypeAttribute
  disabled?: boolean
  readOnly?: boolean
  autoComplete?: string
  inputMode?: InputMode
  placeholder?: string
  'aria-label'?: string
  'aria-details'?: string
  'aria-labelledby'?: string
  autoCapitalize: string
  value?: string,
  onClick?: () => void
  onChange?: (s: string) => void
  onBlur?: () => void
  onFocus?: () => void
}

type InputMode = "text" | "decimal" | "numeric" | "tel" | "email"

interface InputProps extends CommonInputProps {
  innerRef?: React.Ref<HTMLInputElement>
  type: "text" | "email" | "password" | "tel";
  inputMode?: InputMode
  asYouTypePlaceholder?: {
    placeholder: string
    filledPrefix: string
  }
  controlled?: boolean
  autoComplete?: "current-password" | "new-password" | "email" | TextAutocomplete | "tel";
  capitalization: InputCapitalization
  components?: {
    Input: React.FC<InputComponentProps>
  }
}

export const Input = ({gadget, components, ...props}: InputProps) => {
  const displayErrorMessage = useDisplayErrorMessage()
  const [validateErrorMessage, setValidateErrorMessage] = useState<string | undefined>(undefined)
  const isLoading = props.isLoading !== undefined ? props.isLoading : props.value === undefined
  const skeletonMount = useDelayUnmount(isLoading, defaultAnimDurationMillis)
  const [isFocused, setFocused] = useState(false)

  const onValidate = (s: string) =>
    props.onValidate && props.onValidate(s).then(
      () => setValidateErrorMessage(undefined),
      (err) => setValidateErrorMessage(displayErrorMessage(err))
    )

  const onChange = (newValue: string) => {
    props.onChange && props.onChange(newValue)
    if (!!validateErrorMessage || !!props.error) {
      onValidate(newValue)
    }
  }

  const errorMessage = (props.error && displayErrorMessage(props.error)) || validateErrorMessage

  const CustomInputComponent = components?.Input
  const InputComponent = useCallback((({innerRef, ...inputProps}: InputComponentProps) => {
    return CustomInputComponent ?
      <CustomInputComponent
        {...inputProps}
        onChange={(s) => inputProps.onChange && inputProps.onChange(s)}
      /> :
      <input
        ref={innerRef}
        {...inputProps}
        onChange={((e) => inputProps.onChange && inputProps.onChange(e.target.value))}
      />
  }), [CustomInputComponent])

  const placeholderId = useId()

  return (
    <div className={cx("input", {
      "input--errored": !!props.error,
      "input--disabled": props.disabled,
      "input--readonly": props.readOnly,
      "input--has-formatter-placeholder": !!props.asYouTypePlaceholder
    }, props.className)}>
      <label className={cx("input__container")}>
        {skeletonMount.shouldRender &&
            <Skeleton className={"z-20"}
                      hidden={!skeletonMount.shouldShow}
                      adjust={props.skeleton?.adjust ?? {left: 1, right: 1, top: 1, bottom: 1}}
                      borderRadius={"8px"}/>}
        {props.asYouTypePlaceholder && props.asYouTypePlaceholder.filledPrefix.length > 0 &&
            <div id={placeholderId}
                 className={"absolute caption2 inset-[16px] text-left pointer-events-none flex items-center"}>
                <div>
                    <span className={"opacity-0"}>{props.asYouTypePlaceholder.filledPrefix}</span>
                    <span className={"text-primary-40"}>
                      {props.asYouTypePlaceholder.placeholder.slice(props.asYouTypePlaceholder.filledPrefix.length)}
                    </span>
                </div>
            </div>
        }
        {props.leadingNode && <div className={"input__leading-node"}>{props.leadingNode()}</div>}
        <InputComponent
          id={props.id}
          innerRef={props.innerRef}
          type={props.type}
          disabled={props.disabled === undefined ? isLoading : props.disabled}
          readOnly={props.readOnly}
          autoComplete={props.autoComplete ?? "off"}
          placeholder={(isFocused || props.placeholder === undefined) && !!props.asYouTypePlaceholder ? props.asYouTypePlaceholder.placeholder : props.placeholder}
          inputMode={props.inputMode}
          aria-label={props.ariaLabel || props.placeholder}
          aria-details={isFocused ? placeholderId : undefined}
          autoCapitalize={props.capitalization}
          value={props.controlled === false ? undefined : (props.value || "")}
          onChange={onChange}
          onFocus={() => {
            setFocused(true)
            props.onFocus && props.onFocus()
          }}
          onBlur={() => {
            setFocused(false)
            props.onBlur && props.onBlur()
            props.value && onValidate(props.value)
          }}
        />
        {gadget?.kind === "button" &&
            <InlineButton type={"button"} className={cx("input__action-btn", {"opacity-0": gadget.hidden})}
                          loading={gadget.isLoading}
                          onClick={gadget.onClick}
                          title={<Caption size={"3-title"} text={gadget.text}/>}
            />
        }
        {gadget?.kind === "select" && <InlinePicker<PickerOption> {...gadget} className={"mr-[11px]"}/>}
      </label>
      <Collapsable expanded={!!props.hint}>
        <div className={"pt-[10px]"}>
          <Caption size={"3"} text={props.hint ?? ""} color={"primary-50"} hidden={props.hint === undefined}/>
        </div>
      </Collapsable>

      {!props.errorMessageHidden && <InlineError message={errorMessage}/>}
    </div>
  )
};

interface MaskedInputProp<IMaskOptions extends IMask.AnyMaskedOptions> extends InputProps {
  iMaskOptions?: IMaskOptions
  asYouTypePlaceholderText?: string
  onChangeMasked?: (value: {
    masked: string,
    unmasked: string
  }) => void
  onComplete?: (value: IMaskOptions["mask"]) => void
}

const emptyIMaskOptions: IMask.AnyMaskedOptions = {mask: ""}

export const MaskedInput = <IMaskOptions extends IMask.AnyMaskedOptions, >(
  {
    onChange,
    onChangeMasked,
    iMaskOptions,
    ...props
  }: MaskedInputProp<IMaskOptions>) => {
  const initialized = useRef(false)
  const [readyToShow, setReadyToShow] = useState(false)

  const options = iMaskOptions ? iMaskOptions : emptyIMaskOptions

  const {setValue, setUnmaskedValue, maskRef, ...iMask} = useIMask(options, {
    onAccept: (value, iMaskRef, event) => {
      // console.log("onAccept", {
      //   value,
      //   propsValue: props.value,
      //   unmaskedValue: iMaskRef.unmaskedValue,
      //   event
      // })

      if (!initialized.current && props.value !== undefined && iMaskRef.unmaskedValue !== "") {
        // console.log("initialized")
        initialized.current = true
        setReadyToShow(true)
        return
      }

      if (initialized.current && iMaskRef.unmaskedValue !== props.value) {
        onChangeMasked && onChangeMasked({masked: value, unmasked: iMaskRef.unmaskedValue})
        onChange && onChange(iMaskRef.unmaskedValue)
      }

      if (initialized.current && event !== undefined) {
        onChangeMasked && onChangeMasked({masked: value, unmasked: iMaskRef.unmaskedValue})
        onChange && onChange(iMaskRef.unmaskedValue)
      }
    },
    onComplete: (_value, _maskRef, _e) => {
    }
  })

  const ref = useMergeRefs([iMask.ref, props.innerRef])

  useEffect(() => {
    // console.log("tryInit effect", {value: props.value, unmaskedValue: props.unmaskedValue})
    if (initialized.current || props.value === undefined) {
      return
    }

    if (maskRef.current?.unmaskedValue === props.value) {
      initialized.current = true
      setReadyToShow(true)
      return
    }

    // Init loop
    const interval = setInterval(() => {
      // console.log("tryInit", {value: props.value, unmaskedValue: props.unmaskedValue})
      if (initialized.current) {
        clearInterval(interval)
        return
      }
      if (props.value) setUnmaskedValue(props.value)
    }, 20)
    return () => {
      clearInterval(interval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value, setUnmaskedValue, setValue])

  useEffect(() => {
    if (!initialized.current) {
      return
    }
    // console.log("set unmasked value", props.unmaskedValue, maskRef.current?.unmaskedValue)
    if (props.value !== undefined && props.value !== maskRef.current?.unmaskedValue) {
      setUnmaskedValue(props.value)
    }
  }, [maskRef, maskRef.current?.unmaskedValue, props.value, setUnmaskedValue])

  const isLoading = (() => {
    if (props.value === undefined) {
      return true
    }
    if (iMaskOptions === undefined) {
      return true
    }
    if (!readyToShow) {
      return true
    }

    return props.isLoading || false
  })()

  return <Input
    {...props}
    innerRef={ref}
    value={undefined}
    onChange={undefined}
    controlled={false}
    isLoading={isLoading}
    asYouTypePlaceholder={
      props.asYouTypePlaceholderText !== undefined ? {
        placeholder: props.asYouTypePlaceholderText,
        filledPrefix: maskRef.current?.value || ""
      } : undefined
    }
  />
}

interface FormattedInputProps extends InputProps {
  formatter: Formatter
}

export const FormattedInput = ({value, formatter, inputMode, ...props}: FormattedInputProps) => {
  const isNumericOnly = formatter.isNumericOnly()
  return <MaskedInput
    {...props}
    asYouTypePlaceholderText={formatter.asYouTypePlaceholder()}
    iMaskOptions={formatter?.iMaskOptions()}
    type={"text"}
    inputMode={inputMode || (isNumericOnly ? "numeric" : "text")}
    value={value}
  />
}

interface TextInputProps extends CommonInputProps {
  innerRef?: React.Ref<HTMLInputElement>
  autoComplete?: TextAutocomplete;
  capitalization: InputCapitalization
  placeholder?: string;
  value?: string;
  onChange: (newValue: string) => void;
}

export const TextInput = (props: TextInputProps) => {
  return <Input {...props} type={"text"} inputMode={"text"}/>
}

type EmailProps = CommonInputProps

export const EmailInput = (props: EmailProps) => {
  return <Input {...props} type={"text"} inputMode={"email"} autoComplete={"email"} capitalization={"none"}/>
};

interface PasswordProps extends CommonInputProps {
  passwordKind: "new" | "current";
}

export const PasswordInput = ({passwordKind, ...props}: PasswordProps) => {
  const autoComplete = passwordKind === "new" ? "new-password" : "current-password"
  return <Input {...props} type={"password"} inputMode={"text"} autoComplete={autoComplete} capitalization={"none"}/>
};

const iMaskOptionsForDateFormat = (format: string): IMask.MaskedPatternOptions => {
  const fmt = format.toUpperCase()
  let dCount = 0, mCount = 0, yCount = 0
  for (let i = 0; i < fmt.length; ++i) {
    switch (fmt.at(i)) {
      case "D":
        dCount++
        break
      case "M":
        mCount++
        break
      case "Y":
        yCount++
    }
  }

  return {
    mask: fmt,
    eager: true,
    blocks: {
      [Array(yCount).fill("Y").join("")]: {
        mask: Array(yCount).fill("0").join(""),
        placeholderChar: 'Y'
      },
      [Array(mCount).fill("M").join("")]: {
        mask: Array(mCount).fill("0").join(""),
        placeholderChar: 'M'
      },
      [Array(dCount).fill("D").join("")]: {
        mask: Array(dCount).fill("0").join(""),
        placeholderChar: 'D'
      }
    }
  }
}

export const preferredDateFormat = () => {
  return moment.localeData(window.navigator.language).longDateFormat("L")
}

const apiDateFormat = "yyyy-MM-DD"

export const unformatApiDate = (apiDateStr: string, dstFormat: string) => {
  const transformedDateFormat = dstFormat.replaceAll("Y", "y")
  return moment(apiDateStr, apiDateFormat).format(transformedDateFormat)
}

export const formatDateForApi = (formattedDateStr: string, srcFormat: string) => {
  const transformedDateFormat = srcFormat.replaceAll("Y", "y")
  try {
    const res = moment(formattedDateStr || "", transformedDateFormat).format(apiDateFormat)
    if (res === "Invalid date") {
      return ""
    }
    return res
  } catch (err) {
    console.log(err)
    return ""
  }
}

type DateInputProps = CommonInputProps & {
  format?: string
}

/** @deprecated use DateInputGroup */
export const DateInput = ({value, onChange, ...props}: DateInputProps) => {
  const localeFormat = useMemo(() => preferredDateFormat(), [])
  const format = props.format || localeFormat
  const iMaskOptions = useMemo(() => iMaskOptionsForDateFormat(format), [format])

  return <MaskedInput
    {...props}
    type={"text"}
    capitalization={"none"}
    inputMode={"numeric"}
    autoComplete={"bday"}
    iMaskOptions={iMaskOptions}
    asYouTypePlaceholderText={format.toUpperCase()}
    value={value?.replaceAll(/\D/g, "")}
    onChangeMasked={(value) => onChange && onChange(value.masked)}
  />
}


type OtpInputProps = {
  focuser?: OtpFocuser
  disabled?: boolean
  value: string
  onChange: (newValue: string) => void
  onComplete?: () => void
  error: any
}

type OtpFocuser = {
  focus: () => void
  otpRef: RefObject<React18OtpInput>
  containerRef: RefObject<HTMLDivElement>
}

export const useOtpFocuser = (): OtpFocuser => {
  const otpRef = useRef<React18OtpInput>(null)
  const containerRef = useRef<HTMLDivElement | null>(null)
  return {
    focus: () => {
      const tryFocus = () => {
        const clone = Object.assign({}, otpRef.current)
        if (clone.focusInput && containerRef.current) {
          // For some reason focus to 0 not working
          clone.focusInput(1)
          scrollIntoView(containerRef.current)
        } else {
          setTimeout(tryFocus, 20)
        }
      }
      tryFocus()
    },
    otpRef, containerRef
  }
}

// WEB-371: Figure what is wrong with OTP disabled style
export const OtpInput = (props: OtpInputProps) => {
  const otpLength = 6
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (props.value.length === otpLength) {
      props.onComplete && props.onComplete()
    }
  }, [props.value])


  return <div ref={props.focuser?.containerRef} className={"input input--otp"}>
    <React18OtpInput
      ref={props.focuser?.otpRef}
      className={"input__container input__container--otp"}
      containerStyle={"flex justify-between"}
      isDisabled={props.disabled}
      value={props.value}
      onChange={props.onChange}
      numInputs={6}
      placeholder={"000000"}
      isInputNum={true}
      inputProps={{
        inputMode: "numeric",
        autoComplete: "one-time-code",
        type: "text",
      }}
    />
    {props.error && <InlineError error={props.error}/>}
  </div>
}


type InputGroupProps = {
  className?: string;
  name?: React.ReactNode;
  children: React.ReactNode
}

type CheckboxProps = {
  className?: string
  isChecked: boolean;
  label?: string
  onChange?: (isChecked: boolean) => void;
  error?: any
}

export const Checkbox = (props: CheckboxProps) => {
  return (
    <div className={cx("checkbox", {"checkbox--error": props.error}, props.className)}>
      <label className="checkbox__label">
        <input type={"checkbox"} checked={props.isChecked} onChange={(e) => props.onChange?.(e.target.checked)}/>
        <div className={"checkbox__indicator"} onClick={(e) => {
          // Fix double-clicking
          e.stopPropagation()
        }}/>
        {props.label && <Caption className={"block text-left ml-[8px]"} size={"3"} text={props.label}/>}
      </label>
      {props.error && <InlineError error={props.error}/>}
    </div>
  )
}

type SearchInputProps = TextInputProps

export const SearchInput = (props: SearchInputProps) => {
  return (
    <Input
      {...props}
      className={cx("input--search", props.className)}
      type={"text"}
      inputMode={"text"}
      leadingNode={() => <Icon name={"search-sm"} className={"mr-[8px]"}/>}
    />
  )
}

export const InputGroup = ({className, name, children}: InputGroupProps) => {
  return <fieldset className={cx("input-group", className)}>
    {name && <legend className={"block text-left opacity-60 mb-[8px]"}><Caption size={"2"} text={name}/></legend>}
    {children}
  </fieldset>
}

export const InputGroupRow = (props: {
  className?: string;
  children: React.ReactNode
}) => {
  return <div className={cx("input-group__row", props.className)}>
    {props.children}
  </div>
}

type FormProps = {
  className?: string;

  onSubmit: () => void;
  children: React.ReactNode;
  mw?: string,
}

export const Form = ({className, children, onSubmit, ...props}: FormProps) => {
  return (
    <Limiter className={cx("form", className, "mx-auto")} contentMaxWidth={props.mw ?? "460px"}>
      <BasicForm onSubmit={onSubmit} className={className}>
        {children}
      </BasicForm>
    </Limiter>
  )
}

export const BasicForm = ({className, onSubmit, children}: PropsClassName & PropsWithChildren & {
  onSubmit: () => void
}) => {
  return (
    <form className={className} onSubmit={(e) => {
      e.preventDefault()
      // WEB-373: Disable all inputs automatically after submit + disable button
      onSubmit()
    }}
    >
      {children}
    </form>
  )
}

export default Form