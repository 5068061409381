import {useNavStack} from "../../atoms/nav-stack";
import SettingsStepEditPhone from "./SettingsStepEditPhone";
import SettingsStepMain from "./SettingsStepMain";
import {PropsOnComplete} from "../../helpers/props";

export const useSettingsController = () => {
  const navStack = useNavStack()

  const onEditPhone = async (opts: {
    userCountryCode: string
  } & PropsOnComplete) => {
    navStack.push(SettingsStepEditPhone, {
      defaultCountryCode: opts.userCountryCode,
      onComplete: async () => {
        await opts.onComplete()
        navStack.popUntil({component: SettingsStepMain, inclusive: false})
      }
    })
  }

  return {onEditPhone}
}