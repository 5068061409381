import React, {useEffect, useMemo} from "react";
import {useTransfersQuery} from "../../../api/transfers";
import {Section} from "../../../components/ui/collections/Section";
import TimelineStepMain from "../timeline/TimelineStepMain";
import Limiter, {LimiterOverflow} from "../../../components/ui/Limiter";
import {SetLeftColumn} from "../../../components/ui/ContentScreen";
import {TitleSubtitleLeftScreen} from "../../../components/ui/screen/TitleSubtitleScreen";
import {NavStackRoot} from "../../../components/navigation/NavStack";
import {PropsOf} from "@emotion/react";
import NavBar, {NavBarSpacer} from "../../../components/navigation/NavBar";
import {NoRequiredPathParams, withParametricRouter} from "../../../routes/parameteric-router";
import {Links} from "../../../helpers/Links";
import {useNavStack} from "../../../atoms/nav-stack";
import {useStrings} from "../../../strings";
import {Transfer} from "../../../models/transfers/Transfer";
import moment from "moment";
import DefaultRow from "../../../components/ui/collections/rows/DefaultRow";
import {useNavigate} from "../../../routes/router-dom-wrapper/hooks";
import {useIntersectionObserver} from "usehooks-ts";
import TransferRow from "../../../components/transfers/TransferRow";

type PathKeys = "id"


type Props = {
  onSelectTransfer?: (transferId: string) => void
}

const TransferHistoryPage = withParametricRouter<Props, NoRequiredPathParams, PathKeys>({
  optionalPathKeys: ["id"],
})(({
      onSelectTransfer: propsOnSelectTransfer,
      ...props
    }) => {
  const strings = useStrings()
  const navigate = useNavigate()
  const navStack = useNavStack()
  const transfersQuery = useTransfersQuery()

  const transferId = props.id
  const pageSize = 20

  const onSelectTransfer = (transferId: string) => {
    propsOnSelectTransfer?.(transferId)
    navigate(Links.atlantic.root.history(transferId))
  }

  const transferListQuery = transfersQuery.list.useInfiniteQuery(pageSize, {refetchInterval: 5000})

  const transferList = useMemo(() =>
      transferListQuery.data?.pages.flatMap((page) => page.results),
    [transferListQuery.data?.pages]
  )

  const {ref: nextPageLoadAnchorRef} = useIntersectionObserver({
    threshold: 0.5,
    onChange: (isIntersecting) => {
      if (isIntersecting) {
        void transferListQuery.fetchNextPage()
      }
    }
  })

  useEffect(() => {
    const firstTransfer = transferList && transferList[0]
    if (transferId === undefined && firstTransfer) {
      propsOnSelectTransfer?.(firstTransfer.transferId)
      navigate(Links.atlantic.root.history(firstTransfer.transferId), {replace: true, isBackAllowed: false})
    }
  }, [navigate, propsOnSelectTransfer, transferId, transferList])

  useEffect(() => {
    if (transferId === undefined) return

    navStack.push(TimelineStepMain, {transferId: transferId}, {popAll: true})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transferId]);


  const makeSkeletonRows = (count: number) => {
    return Array.from({length: count}).map((_, index) => {
      return (
        <DefaultRow
          key={index}
          skeleton
          title={"Bank of Scotland"}
          subtitle={"9 April"}
          secondary={"€100.00"}
          subSecondary={"£85.81"}
        />
      )
    })
  }

  const transfers = useMemo(() => {
    const inProgress: Transfer[] = []
    const recent: Transfer[] = []
    const older: Transfer[] = []

    for (const transfer of transferList || []) {
      if (transfer.status.isInProgress()) {
        inProgress.push(transfer)
      } else if (moment(transfer.createdAt).isAfter(moment().subtract(30, "days"))) {
        recent.push(transfer)
      } else {
        older.push(transfer)
      }
    }
    return {"in_progress": inProgress, "recent": recent, "older": older}
  }, [transferList])

  const nextPageLoadTriggerTransferId = useMemo(() => {
    const flatTransfersOrder = Object.entries(transfers).map(([_, transfers]) => transfers).flat()
    const triggerTransfer = flatTransfersOrder[flatTransfersOrder.length - Math.ceil(pageSize / 4)] ?? flatTransfersOrder[flatTransfersOrder.length - 1]
    return triggerTransfer?.transferId
  }, [transfers])

  const makeRows = (transfers: Transfer[]) => {
    return transfers.map((transfer) => {
      return <TransferRow
        key={transfer.transferId}
        ref={transfer.transferId === nextPageLoadTriggerTransferId ? nextPageLoadAnchorRef : undefined}
        active={transfer.transferId === transferId}
        transfer={transfer}
        onClick={() => onSelectTransfer(transfer.transferId)}
      />
    })
  }


  const sections = function () {
    if (transferList?.length === 0) {
      return (
        <Section
          title={strings["transfer.history.no_transfers.title"]}
          subtitle={strings["transfer.history.no_transfers.subtitle"]}
        />
      )
    }

    if (transferList === undefined) {
      return (
        <Section>
          <LimiterOverflow overflow={"16px"}>
            {makeSkeletonRows(pageSize)}
          </LimiterOverflow>
        </Section>
      )
    }

    const sectionNames = ["in_progress", "recent", "older"] as const

    const isLastNonEmptySection = (sectionIdx: number) => {
      for (let i = sectionIdx + 1; i < sectionNames.length; i++) {
        if (transfers[sectionNames[i]].length > 0) return false
      }
      return true
    }

    return sectionNames.map((sectionName, sectionIdx) => {
      const sectionTransfers = transfers[sectionName]
      if (sectionTransfers.length === 0) return null
      return (
        <Section key={sectionName} className={"mb-[24px]"} title={strings[`transfers.list.${sectionName}`]}>
          <div className={"mt-[8px]"}>
            <LimiterOverflow overflow={"16px"}>
              {makeRows(sectionTransfers)}
              {transferListQuery.isFetchingNextPage && isLastNonEmptySection(sectionIdx) && makeSkeletonRows(pageSize)}
            </LimiterOverflow>
          </div>
        </Section>
      )
    })
  }()

  const leftColumn = function () {
    return (
      <>
        <NavBarSpacer/>
        <TitleSubtitleLeftScreen title={strings["account.transfer_history"]}>
          {sections}
        </TitleSubtitleLeftScreen>
      </>
    )
  }()

  return (
    <>
      <SetLeftColumn key={"transfer-history-list"} children={leftColumn}/>
      <NavStackRoot<PropsOf<typeof TimelineStepMain>, {}>
        root={TimelineStepMain}
        args={{}}
        ctx={{transferId: props.id}}
        wrapper={(navStack, children) => {
          return (
            <Limiter width={"normal"}>
              <NavBar backDisabled={navStack.top.component === TimelineStepMain}/>
              {children}
            </Limiter>
          )
        }}
      />
    </>
  )
})

export default TransferHistoryPage;