import {Form} from "../../../components/ui/input/Inputs";
import {Button} from "../../../components/ui/Buttons";
import React, {useState} from "react";
import {PropsOnComplete} from "../../../helpers/props";
import {RecipientCreateContext} from "./recipient-create-controller";
import {TitleSubtitleLeftScreen} from "../../../components/ui/screen/TitleSubtitleScreen";
import _ from "lodash";
import {useStrings} from "../../../strings";
import ButtonBar from "../../../components/ui/ButtonBar";
import {BankAccountElement} from "../../../components/domain/recipients/domain/BankAccountElement";
import {BankAccountElementId} from "../../../api/whales/Common";
import RecipientTemplateInputRow, {InputState} from "./RecipientTemplateInputRow";
import {useRecipientsApi} from "../../../api/recipients";
import {InterfaceTemplateValue} from "../../../components/domain/interface-template/domain";
import {useAsyncLoading} from "../../../helpers/hooks";
import {useAlertStack} from "../../../providers/alert-stack";
import {useAddRecipientElementsState} from "../../../atoms/recipient/add-recipient-elements";
import {BankAccountTemplate} from "../../../models/common/BankAccount";

type Result = BankAccountElement[]

type Props = {
  context: RecipientCreateContext
  template: BankAccountTemplate
} & PropsOnComplete<Result>;

type State = {
  elements: Partial<Record<BankAccountElementId, InputState>>
};

export const RecipientCreateTemplateStep = (props: Props) => {
  const strings = useStrings()

  const [addRecipientState, setAddRecipientState] = useAddRecipientElementsState()

  const recipientsApi = useRecipientsApi()
  const alertStack = useAlertStack()

  const asyncLoading = useAsyncLoading(false)

  const currencyCode = props.context.currency?.code!

  const [state, setState] = useState<State>({
    elements: Object.fromEntries(props.template.elements.map((elementTemplate) => {
      const element = function () {
        const contextElement = props.context.elements?.find(el => el.identifier === elementTemplate.identifier)
        const globalStateElement = addRecipientState?.currencyCode === currencyCode
          ? addRecipientState?.elements.find(el => el.identifier === elementTemplate.identifier)
          : undefined
        const defaultValue = function () {
          let defValue = BankAccountElement.defaultValue(elementTemplate.identifier, props.template)
          if (props.context.kind === "own" && elementTemplate.identifier === "holderName") {
            defValue = defValue.withValue({kind: "text", textValue: props.context.clientInfo.accountTitle()})
          }
          return defValue
        }

        return contextElement ?? globalStateElement ?? defaultValue()
      }()

      return [element.identifier, {element: element} as InputState]
    }))
  });


  const inputs = _.entries(state.elements).map(([elementIdStr, inputState]) => {
    const elementId = elementIdStr as BankAccountElementId
    return <RecipientTemplateInputRow
      key={elementId}
      context={props.context}
      inputState={inputState}
      suggestedCountryCode={props.context.currency?.countryCode()}
      disabled={asyncLoading.isLoading}
      onChanges={(newValue) => {
        setState((prev: State): State => {
          const inputState = prev.elements[elementId]
          if (!inputState) return prev
          return ({
            ...prev,
            elements: {
              ...prev.elements,
              [elementId]: {
                ...inputState,
                element: inputState.element.withValue(newValue),
                error: undefined,
              } as InputState
            }
          } as State)
        })
      }}/>
  });

  const onSubmit = () => asyncLoading.asyncWithLoading(async () => {
    setAddRecipientState({
      currencyCode: currencyCode,
      elements: _.values(state.elements).map(inputState => inputState.element)
    })
    await Promise.all(_.values(state.elements).map(async inputState => {
      const elementId = inputState.element.identifier
      try {
        await recipientsApi.bankAccountElementValidate({
          recipientType: props.context.kind!,
          currencyCode: currencyCode,
          elementId: elementId,
          element: InterfaceTemplateValue.toApi(inputState.element.value),
        })
      } catch (e) {
        setState((prev: State): State => {
          const inputState = prev.elements[elementId]
          if (!inputState) return prev
          return ({
            ...prev,
            elements: {
              ...prev.elements,
              [elementId]: {
                ...inputState,
                error: e,
              } as InputState
            }
          } as State)
        })
        throw e
      }
    }))

    return props.onComplete(_.values(state.elements).map((inputState) => inputState!.element))
      .catch(alertStack.showError)
  })

  return (
    <TitleSubtitleLeftScreen title={strings["add_recipient.bank_account_details.title"]}>
      <Form className={"flex flex-col flex-1"} onSubmit={onSubmit} mw={"auto"}>
        {inputs}
        <ButtonBar sticky={"bottom"}>
          <Button
            type={"submit"}
            title={strings["general.continue"]}
            size={"big"}
            loading={asyncLoading.isLoading}
            color={"primary-black"}
          />
        </ButtonBar>
      </Form>
    </TitleSubtitleLeftScreen>
  );
}