import cx from "classnames";
import React, {MouseEventHandler, PropsWithChildren, useCallback, useState} from "react";
import {inArray} from "../../helpers/array";
import {useAlertStack} from "../../providers/alert-stack";
import './Buttons.scss';
import Caption from "./Caption";
import Icon, {IconName} from "./icons/Icon";
import {RawLink} from "./Link";
import Spinner from "./icons/Spinner";
import {PropsClassName} from "../../helpers/props";

interface CommonProps extends PropsWithChildren {
  type: "submit" | "button" | "link"
  title?: React.ReactNode
  disabled?: boolean
  stopPropagation?: boolean
}

export interface FormTypeProps extends CommonProps {
  type: "submit"
  loading?: boolean
}

export interface ButtonTypeProps extends CommonProps {
  type: "button",
  onClick?: () => void,
  onClickDOM?: MouseEventHandler<HTMLButtonElement>
  loading?: boolean
  onClickAsync?: () => Promise<any>
  onError?: (err: any) => void
}

export interface LinkTypeProps extends CommonProps {
  type: "link"
  href: string
  newTab?: boolean
}

export type AllButtonTypesProps = (FormTypeProps | ButtonTypeProps | LinkTypeProps)

type AbstractProps = AllButtonTypesProps & {
  className: (isLoading: boolean) => string
  content: (isLoading: boolean) => React.ReactNode
}

const AbstractButton = React.forwardRef<any, AbstractProps>(({content, ...props}: AbstractProps, ref) => {
  const [loading, setLoading] = useState(false)
  const errorStack = useAlertStack()

  const isLoading = (props.type === "button" || props.type === "submit") && props.loading ? props.loading : loading
  const className = props.className(isLoading)

  const onClick: MouseEventHandler<HTMLButtonElement> = useCallback((e) => {
    const showError = errorStack.showError
    try {
      if (props.stopPropagation) {
        e.stopPropagation()
      }
      if (props.type === "button") {
        props.onClick?.()
        props.onClickDOM?.(e)
        if (props.onClickAsync) {
          setLoading(true)
          props.onClickAsync()
            .catch(props.onError || errorStack.showError)
            .finally(() => {
              setLoading(false)
            })
        }
      }
    } catch (e) {
      showError(e)
      throw e
    }
  }, [errorStack.showError, props])

  switch (props.type) {
    case "button":
    case "submit":
    case undefined:
      return (
        <button ref={ref} type={props.type || "button"} className={className} disabled={props.disabled ?? isLoading}
                onClick={onClick}>
          {content(isLoading)}
        </button>
      )
    case "link":
      return (
        <RawLink ref={ref} className={className} href={props.href} newTab={!!props.newTab}>
          {content(isLoading)}
        </RawLink>
      )
  }
})

export type BlockButtonProps = {
  size: "max" | "big" | "fit" | "small",
  color: "primary-10" | "primary-black" | "secondary" | "light",
  iconLeft?: IconName,
  iconRight?: IconName,
} & PropsClassName

export type ButtonProps = AllButtonTypesProps & BlockButtonProps

export const Button = (props: ButtonProps) => {
  const className = useCallback((isLoading: boolean) => cx(
    "button", `button-${props.size}`, `button-${props.color}`,
    {"button--loading": isLoading}, props.className
  ), [props.className, props.color, props.size])

  const iconColor = props.color === "primary-black" ? "light" : "dark"

  const inner = useCallback((isLoading: boolean) => {
      const captionSize = inArray(["max", "big"], props.size) ? "2-title" : "3-title";
      return (
        <div className={cx("relative flex items-center",)}>
          <div className={cx("default-anim transition-opacity flex items-center", {"opacity-0": isLoading})}>
            {props.iconLeft && <Icon name={props.iconLeft} className={"icon icon-left"} colorScheme={iconColor}/>}
            <Caption key={"content"} kind={"text"} text={props.title} size={captionSize}/>
            {props.iconRight && <Icon  name={props.iconRight} className={"icon icon-right"} colorScheme={iconColor}/>}
          </div>
          <Spinner className={"absolute w-6 h-6 left-[calc(50%-12px)]"} hidden={!isLoading} color={iconColor}/>
        </div>
      )
    }
    , [props.size, props.iconLeft, props.title, props.iconRight, iconColor]);

  return <AbstractButton {...props} className={className} content={inner}/>
};

type InlineButtonProps = AllButtonTypesProps & {
  className?: string
  indications?: boolean
}

export const InlineButton = React.forwardRef<any, InlineButtonProps>((props: InlineButtonProps, ref) => {
  const indications = props.indications ?? true
  // WEB-376: add loading indication
  const className = useCallback((isLoading: boolean) => cx(
    "inline-button", {
      "inline-button--loading": isLoading,
      "inline-button--indications": indications,
    }, props.className
  ), [indications, props.className])

  return <AbstractButton {...props} ref={ref} className={className} content={() => props.title || props.children}/>
})

type CloseButtonProps = {
  onClick: () => void
} & PropsClassName

export const CloseButton = (props: CloseButtonProps) => {
  const {className, ...rest} = props

  return (
    <InlineButton
      {...rest}
      onClick={props.onClick}
      type={"button"}
      className={cx("close-button", className)}
      aria-label={"Close"}
    >
      <Icon name={"button-close"}/>
    </InlineButton>
  )
}