import {useStrings} from "../../../strings";
import Limiter from "../../ui/Limiter";
import ButtonBar from "../../ui/ButtonBar";
import {Button} from "../../ui/Buttons";
import {TitleSubtitleCenterScreen} from "../../ui/screen/TitleSubtitleScreen";
import {img} from "../../../helpers/public";

const BusinessApplicationApproved = () => {
  const strings = useStrings()
  return (
    <TitleSubtitleCenterScreen
      title={strings["application.approved.title"]}
      subtitle={strings["application.approved.subtitle"]}>
      <Limiter contentMaxWidth={"375px"}>
        <img className={""} src={img("popper.png")} alt={"Confetti"}/>
      </Limiter>
      <ButtonBar align={"center"}>
        <Button type={"link"} title={strings["general.continue"]} size={"big"} color={"primary-black"} href={"/"}/>
      </ButtonBar>
    </TitleSubtitleCenterScreen>
  )
}

export default BusinessApplicationApproved;