import {useIntl} from "react-intl";
import FormattedDueDate from "../../../components/formatters/date/FormattedDueDate";
import FormattedQuoteRate from "../../../components/formatters/FormattedQuoteRate";
import ButtonBar from "../../../components/ui/ButtonBar";
import {Button} from "../../../components/ui/Buttons";
import {WithLearnMoreSuffix} from "../../../components/ui/LearnMore";
import React, {PropsWithChildren} from "react";
import {Links} from "../../../helpers/Links";
import {Transfer} from "../../../models/transfers/Transfer";
import {Quote} from "../../../models/transfers/Estimate";
import {TimelineElement} from "../../../models/transfers/Timeline";
import {useTransferTimelineController} from "./transfer-timeline-controller";
import {useStrings} from "../../../strings";
import FormattedMoney from "../../../components/formatters/money/FormattedMoney";
import Icon from "../../../components/ui/icons/Icon";
import FormattedDate from "../../../components/formatters/date/FormattedDate";
import InfoRow from "../../../components/ui/collections/rows/InfoRow";
import DeliveryDateArrivesOn from "../../../components/domain/transfers/DeliveryDateArrivesOn";
import {PropsClassName} from "../../../helpers/props";

const SubtitleView = ({className, element, learnMoreLink}:
                        { element: TimelineElement, learnMoreLink?: string } & PropsClassName) => {
  if (element.subtitle === undefined) {
    return null
  }

  if (learnMoreLink) {
    return <WithLearnMoreSuffix url={learnMoreLink}>{element.subtitle}</WithLearnMoreSuffix>
  }

  return (
    <div className={className}>{element.subtitle}</div>
  )
}

type TimelineElementViewProps = {
  element: TimelineElement,
  buttons?: React.ReactNode
  learnMoreUrl?: string
} & PropsWithChildren

const TimelineElementContentView = ({element, ...props}: TimelineElementViewProps) => {
  return (
    <div className={"timeline-element__content-view"}>
      <SubtitleView className={"pb-3"} element={element} learnMoreLink={props.learnMoreUrl}/>
      {props.children}
      {props.buttons ?
        (<ButtonBar className={"mt-4"} align={"start"}>
          {props.buttons}
        </ButtonBar>) : null}
    </div>
  )

}

type PropsElement = {
  transfer: Transfer
  element: TimelineElement
}

const ElementPayInWaiting = ({transfer, element}: PropsElement) => {
  const strings = useStrings()
  const controller = useTransferTimelineController({transferId: transfer.transferId})

  const amountDueDateLabel = function () {
    const quoteFrozen = transfer.displaying().isQuoteFrozen
    if (quoteFrozen.kind === "frozen-with-expiry") {
      return strings["transfer.timeline.element.title.3.due_by_subtitle"](
        <FormattedMoney value={transfer.displaying().sourceMoney}/>,
        <FormattedDueDate value={(quoteFrozen as Quote.Frozen).expiry}/>,
        <FormattedQuoteRate value={transfer.displaying().quote.rate}/>
      )
    } else
      return null
  }
  ()

  return (
    <TimelineElementContentView
      element={element}
      buttons={
        <Button
          type={"button"}
          title={strings["transfer.send_payment"]}
          size={"fit"}
          color={"primary-black"}
          iconRight={"chevron-right"}
          onClickAsync={controller.onPayInWaiting}
        />
      }
      children={amountDueDateLabel ?
        (
          <div className={"grid grid-rows-[auto_auto] grid-cols-[1fr_auto] gap-4"}>
            <div className={"col-span-1"}>{amountDueDateLabel}</div>
            <div className={"row-start-2"}>{transfer.displaying().deliverySubtitle(strings)}</div>
            <div className={"row-start-2 self-center"}><Icon name={"transfer-shield"}/></div>
          </div>
        ) : undefined
      }/>
  )
}

const ElementPayInInitiated = ({transfer, element}: PropsElement) => {
  const strings = useStrings()
  const transferTimelineController = useTransferTimelineController({transferId: transfer.transferId})
  return (
    <TimelineElementContentView
      element={element}
      buttons={
        <Button
          type={"button"}
          size={"fit"}
          color={"primary-10"}
          title={strings["transfer.timeline.havent_paid"]}
          iconRight={"chevron-right"}
          onClickAsync={transferTimelineController.onIHaventPaid}
        />
      }
    />
  )
}

const ElementPayInMoreInfo = ({transfer, element}: PropsElement) => {
  const strings = useStrings()
  const transferTimelineController = useTransferTimelineController({transferId: transfer.transferId})
  return (
    <TimelineElementContentView
      element={element}
      buttons={
        <Button
          type={"button"}
          size={"fit"}
          color={"primary-black"}
          title={strings["transfer.timeline.element.provide_additional_info"]}
          iconRight={"chevron-right"}
          onClickAsync={transferTimelineController.routeForChecks}
        />
      }
    />
  )
}

const ElementTransferInitiationCompletePending = ({transfer, element, userInputRequired}: PropsElement & {
  userInputRequired: boolean
}) => {
  const strings = useStrings()
  const controller = useTransferTimelineController({transferId: transfer.transferId})
  return (
    <TimelineElementContentView
      element={element}
      buttons={
        userInputRequired ?
          <Button
            type={"button"}
            size={"fit"}
            color={"primary-black"}
            title={strings["transfer.timeline.element.provide_additional_info"]}
            iconRight={"chevron-right"}
            onClickAsync={controller.routeForChecks}
          /> : undefined
      }
    >
      {function () {
        switch (transfer.displaying().entryMode) {
          case "source":
            if (transfer.displaying().shouldDisplaySourceAmountAsExact) {
              return <InfoRow
                caption={strings["transfer.review.transfer.sending_amount"]}
                content={strings["transfer.amount.exact"](
                  <FormattedMoney value={transfer.displaying().sourceMoney}/>
                )}
              />
            } else {
              return <InfoRow
                caption={strings["transfer.review.transfer.sending_amount"]}
                content={strings["transfer.amount.approximate"](
                  <FormattedMoney value={transfer.displaying().sourceMoney}/>
                )}
              />
            }
          case "destination":
            if (transfer.displaying().shouldDisplayDestinationAmountAsExact) {
              return <InfoRow
                caption={strings["transfer.review.transfer.receiving_amount"]}
                content={strings["transfer.amount.exact"](
                  <FormattedMoney value={transfer.displaying().destinationMoney}/>
                )}
              />
            } else {
              return <InfoRow
                caption={strings["transfer.review.transfer.receiving_amount"]}
                content={strings["transfer.amount.approximate"](
                  <FormattedMoney value={transfer.displaying().destinationMoney}/>
                )}
              />
            }
        }
      }()}
      <InfoRow
        caption={strings["transfer.timeline.recipient_name"]}
        content={transfer.recipient.displayName}
      />
    </TimelineElementContentView>
  )
}

const ElementPayInChecksFailed = ({element}: PropsElement) => {
  return (
    <TimelineElementContentView element={element} learnMoreUrl={Links.helpCenter.articles.payinRejected}/>
  )
}

const ElementExchangeCurrencyFuture = ({transfer, element}: PropsElement) => {
  const strings = useStrings()

  const receivingAmount = (
    <InfoRow
      caption={strings["transfer.review.transfer.receiving_amount"]}
      content={transfer.displaying().destinationAmountDescription(strings)}
    />
  )

  const rows = function () {
    switch (transfer.displaying().isQuoteFrozen.kind) {
      case "frozen-with-expiry":
        return <>
          <InfoRow
            caption={strings["transfer.locked_exchange_rate"]}
            content={<FormattedQuoteRate value={transfer.displaying().quote.rate}/>}
          />
          <InfoRow
            caption={strings["transfer.locked_until"]}
            content={<FormattedDueDate value={(transfer.displaying().isQuoteFrozen as Quote.Frozen).expiry}/>}
          />
          {receivingAmount}
        </>
      case "not-frozen":
        return <>
          <InfoRow
            caption={strings["transfer.review.transfer.exchange_rate"]}
            content={strings["transfer.amount.approximate"](
              <FormattedQuoteRate value={transfer.displaying().quote.rate}/>
            )}
          />
          {receivingAmount}
        </>
      case "frozen-because-traded":
        return null
    }
  }()

  return (
    <TimelineElementContentView element={element}>
      {rows}
    </TimelineElementContentView>
  )
}

const ElementPayOutFutureCurrentCompletePending = ({transfer, element}: PropsElement) => {
  const strings = useStrings()
  return (
    <TimelineElementContentView element={element}>
      <InfoRow
        caption={strings["transfer.review.transfer.delivery"]}
        content={<DeliveryDateArrivesOn deliveryOption={transfer.displaying().deliveryOption}/>}
      />
      <InfoRow
        caption={strings["transfer.review.transfer.receiving_amount"]}
        content={transfer.displaying().destinationAmountDescription(strings)}
      />
    </TimelineElementContentView>
  )
}

const ElementPayOutFailed = ({element}: PropsElement) => {
  return (
    <TimelineElementContentView element={element} learnMoreUrl={Links.helpCenter.articles.payoutFailed}/>
  )
}

const ElementPayInComplete = ({transfer, element}: PropsElement) => {
  const strings = useStrings()
  return (
    <TimelineElementContentView element={element}>
      <InfoRow
        caption={strings["transfer.timeline.element.title.13.amount_received"]}
        content={<FormattedMoney value={transfer.displaying().sourceMoney}/>}
      />
    </TimelineElementContentView>
  )
}

const ElementTradeComplete = ({transfer, element}: PropsElement) => {
  const strings = useStrings()
  return (
    <TimelineElementContentView element={element}>
      <InfoRow
        caption={strings["transfer.review.transfer.exchange_rate"]}
        content={strings["transfer.quote_option.exact.title"](
          <FormattedQuoteRate value={transfer.displaying().quote.rate}/>
        )}
      />
      <InfoRow
        caption={strings["transfer.converted_amount"]}
        content={transfer.displaying().destinationAmountDescription(strings)}
      />
    </TimelineElementContentView>
  )
}

const ElementTradePending = ({transfer, element}: PropsElement) => {
  const strings = useStrings()

  return (
    <TimelineElementContentView element={element}>
      {function () {
        switch (transfer.displaying().isQuoteFrozen.kind) {
          case "frozen-with-expiry":
          case"frozen-because-traded":
            return <InfoRow
              caption={strings["transfer.locked_exchange_rate"]}
              content={strings["transfer.quote_option.exact.title"](
                <FormattedQuoteRate value={transfer.displaying().quote.rate}/>
              )}
            />
          case "not-frozen":
            return <InfoRow
              caption={strings["transfer.review.transfer.exchange_rate"]}
              content={strings["transfer.amount.approximate"](
                <FormattedQuoteRate value={transfer.displaying().quote.rate}/>
              )}
            />
        }
      }()}
      <InfoRow
        caption={strings["transfer.review.transfer.receiving_amount"]}
        content={transfer.displaying().destinationAmountDescription(strings)}
      />
    </TimelineElementContentView>
  )
}

const ElementTradePlaced = ({transfer, element}: PropsElement) => {
  const intl = useIntl()
  const strings = useStrings()
  return (
    <TimelineElementContentView element={element}>
      <InfoRow
        caption={strings["transfer.review.transfer.exchange_rate"]}
        content={strings["transfer.quote_option.exact.title"](
          <FormattedQuoteRate value={transfer.displaying().quote.rate}/>
        )}
      />
      <InfoRow
        caption={strings["transfer.converted_amount"]}
        content={transfer.displaying().destinationAmountDescription(strings)}
      />
      <InfoRow
        caption={strings["transfer.receive_destination_on"](
          transfer.displaying().destinationMoney.currency.name(intl),
        )}
        content={
          <FormattedDate style={"EEEE"}
                         value={transfer.settleDate ?? transfer.displaying().deliveryOption.deliveryDate}/>
        }
      />
    </TimelineElementContentView>
  )
}


type Props = {
  transfer: Transfer
  element: TimelineElement
}

const TimelineElementContent = ({transfer, element}: Props) => {
  switch (element.identifier) {
    case "transfer-initiation-complete":
    case "transfer-initiation-pending-nexus-links-active":
    case "transfer-initiation-pending-other":
    case "transfer-initiation-pending-manual-review":
      return <ElementTransferInitiationCompletePending
        transfer={transfer}
        element={element}
        userInputRequired={false}
      />
    case "transfer-initiation-pending-user-input-required":
      return <ElementTransferInitiationCompletePending
        transfer={transfer}
        element={element}
        userInputRequired={true}
      />
    case "payin-more-info":
      return <ElementPayInMoreInfo transfer={transfer} element={element}/>
    case "payin-waiting":
      return <ElementPayInWaiting transfer={transfer} element={element}/>
    case "payin-initiated":
      return <ElementPayInInitiated transfer={transfer} element={element}/>
    case "payin-complete":
      return <ElementPayInComplete transfer={transfer} element={element}/>
    case "payin-checks-failed":
      return <ElementPayInChecksFailed element={element} transfer={transfer}/>
    case "exchange-currency-future":
      return <ElementExchangeCurrencyFuture transfer={transfer} element={element}/>
    case "trade-pending":
      return <ElementTradePending transfer={transfer} element={element}/>
    case "trade-placed":
      return <ElementTradePlaced transfer={transfer} element={element}/>
    case "trade-complete":
      return <ElementTradeComplete transfer={transfer} element={element}/>

    case "payout-future":
    case "payout-current":
    case "payout-complete":
    case "payout-pending":
      return <ElementPayOutFutureCurrentCompletePending transfer={transfer} element={element}/>

    case "payout-failed":
      return <ElementPayOutFailed element={element} transfer={transfer}/>

    default:
      return <TimelineElementContentView element={element}/>
  }
};

export default TimelineElementContent;