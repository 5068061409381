// @ts-nocheck
export function fbqScript() {
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  !function(f, b, e, v, n, t, s) {
  if (f.fbq) return;
  n = f.fbq = function () {
    n.callMethod ?
      n.callMethod.apply(n, arguments) : n.queue.push(arguments)
  };
  if (!f._fbq) f._fbq = n;
  n.push = n;
  n.loaded = !0;
  n.version = '2.0';
  n.queue = [];
  t = b.createElement(e);
  t.async = !0;
  t.src = v;
  s = b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t, s)
}(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js')
}

export function gaScript() {
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({
      'gtm.start':
        new Date().getTime(), event: 'gtm.js'
    });
    var f = d.getElementsByTagName(s)[0],
      // eslint-disable-next-line eqeqeq
      j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src =
      'https://www.googletagmanager.com/gtag/js?id=' + i + dl;
    var n = d.querySelector('[nonce]');
    n && j.setAttribute('nonce', n.nonce || n.getAttribute('nonce'));
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', 'G-1MWV6T9N37');

  window.dataLayer = window.dataLayer || [];

  function gtag() {
    dataLayer.push(arguments);
  }

  gtag('js', new Date());

  gtag('consent', 'default', {
    'ad_storage': 'denied',
    'analytics_storage': 'denied',
    'functionality_storage': 'denied',
    'personalization_storage': 'denied',
    'security_storage': 'denied',
  });
  gtag('config', 'G-1MWV6T9N37');
}

export function branchScript() {
  (function (b, r, a, n, c, h, _, s, d, k) {
    if (!b[n] || !b[n]._q) {
      for (; s < _.length;) c(h, _[s++]);
      d = r.createElement(a);
      d.async = 1;
      d.src = "https://cdn.branch.io/branch-latest.min.js";
      k = r.getElementsByTagName(a)[0];
      k.parentNode.insertBefore(d, k);
      b[n] = h
    }
  })(window, document, "script", "branch", function (b, r) {
    b[r] = function () {
      b._q.push([r, arguments])
    }
  }, {
    _q: [],
    _v: 1
  }, "addListener applyCode autoAppIndex banner closeBanner closeJourney creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode trackCommerceEvent logEvent disableTracking qrCode".split(" "), 0);
}