import React from 'react';
import {useIntl} from 'react-intl';
import {Currency} from "../../helpers/money";

interface CurrencyNameProps {
  currency: Currency;
}

const CurrencyName: React.FC<CurrencyNameProps> = ({ currency }) => {
  const intl = useIntl();

  return <>{currency.name(intl)}</>;
};

export default CurrencyName;
