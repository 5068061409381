import {Api, useApi} from "./axios";
import {AuthorizeRequest} from "./whales/Support";

export class SupportApi {
  constructor(private api: Api) {
  }

  authorize(req: AuthorizeRequest): Promise<{}> {
    return this.api.post("/support/v1/authorize", req)
  }
}

export function useSupportApi() {
  return new SupportApi(useApi())
}

// const queryKeys = {};
//
// export function useClientQuery() {
//   const api = useSupportApi();
//   const queryClient = useQueryClient();
//   return {}
// }