import React, {PropsWithChildren} from "react";
import AbstractRow, {RowClickableProps, RowModifiersProps} from "./AbstractRow";
import cx from "classnames";
import "./IconBodyRow.scss"
import Caption from "../../Caption";
import {BodyTitle} from "../../Labels";
import {PropsClassName} from "../../../../helpers/props";
import "./ComplexRow.scss"
import AtlanticQrCode from "../../AtlanticQrCode";

type Props = {
  topImage?: React.ReactNode
  title: React.ReactNode
  subtitle?: React.ReactNode
  secondary?: React.ReactNode
  auxiliaryButton?: React.ReactNode
  trailingIcon?: React.ReactNode
  qrCodeUrl?: string

  bordered?: boolean
} & PropsWithChildren & RowModifiersProps & RowClickableProps & PropsClassName

const ComplexRow = (props: Props) => {
  return (
    <AbstractRow
      {...props}
      ignoreClick={(e) => {
        const el = e.target as HTMLElement
        const ignoreLinksAndBtns =  !!el.closest("a") || !!el.closest("button")
        return props.ignoreClick?.(e) || false || ignoreLinksAndBtns
      }}
      className={cx(props.className, "complex-row", {
      "complex-row--bordered": props.bordered,
    })}>
      {props.topImage && (
        <div className={"complex-row__top-image"}>
          {props.topImage}
        </div>
      )}
      <div className={"complex-row__title"}>
        <BodyTitle text={props.title}/>
      </div>
      {props.subtitle && (
        <div className={"complex-row__subtitle"}>
          <Caption text={props.subtitle} size={"2"}/>
        </div>
      )}
      {props.secondary && (
        <div className={"complex-row__secondary"}>
          <Caption text={props.secondary} size={"3"}/>
        </div>
      )}
      {props.trailingIcon && (
        <div className={"complex-row__trailing-icon"}>
          {props.trailingIcon}
        </div>
      )}
      {props.qrCodeUrl && (
        <div className={"complex-row__qr-code"}>
          {<AtlanticQrCode size={'small'} url={props.qrCodeUrl}/>}
        </div>
      )}
      {props.auxiliaryButton && (
        <div className={"complex-row__auxiliary-button"}>
          <Caption size={"3-title"} text={props.auxiliaryButton}/>
        </div>
      )}
    </AbstractRow>
  )
}

export default ComplexRow;
