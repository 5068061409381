import {atom, useRecoilValue, useSetRecoilState} from "recoil";
import {useEffectOnce} from "../hooks/effect";

export type SideIllustrationFile =
  "app-showcase"
  | "briefcase"
  | "document"
  | "profile-2-silhouettes"
  | "profile-silhouette"

type State = {
  sideIllustration: SideIllustrationFile
}

const sideIllustrationAtom = atom<State>({
  key: "sideIllustration",
  default: {
    sideIllustration: "app-showcase"
  },
})

export const useSideIllustrationValue = () => {
  return useRecoilValue(sideIllustrationAtom)
}

export const useSideIllustration = (illustration?: SideIllustrationFile) => {
  const setState = useSetRecoilState(sideIllustrationAtom)

  const setSideIllustration = (illustration: SideIllustrationFile) => {
    setState((prev) => ({
      ...prev,
      sideIllustration: illustration
    }))
  }

  useEffectOnce(() => {
    illustration && setSideIllustration(illustration)
  })

  return { setSideIllustration }
}
