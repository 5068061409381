import React from "react";
import {svgSprite} from "../../../helpers/public";

type Props = {
  className?: string;
  countryCode: string;
}

const CountryIcon: React.FC<Props> = (props) => {
  return (
    <svg width={18} height={18}>
      <use href={`${svgSprite("flags", "flag-placeholder")}`}/>
      <use href={`${svgSprite("flags", props.countryCode)}`}/>
    </svg>
  )
}

export default CountryIcon;