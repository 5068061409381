import {CountryCode} from "../../helpers/countries";
import FixedFormatter from "./FixedFormatter";
import FormattedString from "./FormattedString";


const countryIbanMasks: Partial<Record<CountryCode, string>> = {
  "AL": "@@## #### #### **** **** **** ****",
  "AD": "@@## #### #### **** **** ****",
  "AT": "@@## #### #### #### ####",
  "AZ": "@@## **** #### #### #### #### ####",
  "BH": "@@## @@@@ **** **** **** **",
  "BY": "@@## **** #### **** **** **** ****",
  "BE": "@@## #### #### ####",
  "BA": "@@## #### #### #### ####",
  "BR": "@@## #### #### #### #### #### ###@ *",
  "BG": "@@## @@@@ #### ##** **** **",
  "CR": "@@## #### #### #### #### ##",
  "HR": "@@## #### #### #### #### #",
  "CY": "@@## #### #### **** **** **** ****",
  "CZ": "@@## #### #### #### #### ####",
  "DK": "@@## #### #### #### ##",
  "DO": "@@## @@@@ #### #### #### #### ####",
  "TL": "@@## #### #### #### #### ###",
  "EG": "@@## #### #### #### #### #### #### #",
  "SV": "@@## @@@@ #### #### #### #### ####",
  "EE": "@@## #### #### #### ####",
  "FO": "@@## #### #### #### ##",
  "FI": "@@## #### #### #### ##",
  "FR": "@@## #### #### ##** **** **** *##",
  "GE": "@@## **## #### #### #### ##",
  "DE": "@@## #### #### #### #### ##",
  "GI": "@@## @@@@ **** **** **** ***",
  "GR": "@@## #### ###* **** **** **** ***",
  "GL": "@@## #### #### #### ##",
  "GT": "@@## **** **** **** **** **** ****",
  "HU": "@@## #### #### #### #### #### ####",
  "IS": "@@## #### #### #### #### #### ##",
  "IQ": "@@## @@@@ #### #### #### ###",
  "IE": "@@## **** #### #### #### ##",
  "IL": "@@## #### #### #### #### ###",
  "IT": "@@## @### #### ###* **** **** ***",
  "JO": "@@## @@@@ #### #### #### #### #### ##",
  "KZ": "@@## ###* **** **** ****",
  "XK": "@@## #### #### #### ####",
  "KW": "@@## @@@@ **** **** **** **** **** **",
  "LV": "@@## @@@@ **** **** **** *",
  "LB": "@@## #### **** **** **** **** ****",
  "LY": "@@## #### #### #### #### #### #",
  "LI": "@@## #### #*** **** **** *",
  "LT": "@@## #### #### #### ####",
  "LU": "@@## ###* **** **** ****",
  "MK": "@@## ###* **** **** *##",
  "MT": "@@## @@@@ #### #*** **** **** **** ***",
  "MR": "@@## #### #### #### #### #### ###",
  "MU": "@@## @@@@ #### #### #### #### ###@ @@",
  "MC": "@@## #### #### ##** **** **** *##",
  "MD": "@@## **** **** **** **** ****",
  "ME": "@@## #### #### #### #### ##",
  "NL": "@@## @@@@ #### #### ##",
  "NO": "@@## #### #### ###",
  "PK": "@@## **** #### #### #### ####",
  "PS": "@@## **** #### #### #### #### #### #",
  "PL": "@@## #### #### #### #### #### ####",
  "PT": "@@## #### #### #### #### #### #",
  "QA": "@@## @@@@ **** **** **** **** **** *",
  "RO": "@@## @@@@ **** **** **** ****",
  "LC": "@@## @@@@ **** **** **** **** **** ****",
  "SM": "@@## @### #### ###* **** **** ***",
  "ST": "@@## #### #### #### #### #### #",
  "SA": "@@## ##** **** **** **** ****",
  "RS": "@@## #### #### #### #### ##",
  "SC": "@@## @@@@ #### #### #### #### #### @@@",
  "SK": "@@## #### #### #### #### ####",
  "SI": "@@## #### #### #### ###",
  "ES": "@@## #### #### #### #### ####",
  "SE": "@@## #### #### #### #### ####",
  "CH": "@@## #### #*** **** **** *",
  "TN": "@@## #### #### #### #### ####",
  "TR": "@@## #### #*** **** **** **** **",
  "UA": "@@## #### ##** **** **** **** **** *",
  "AE": "@@## #### #### #### #### ###",
  "GB": "@@## @@@@ #### #### #### ##",
  "VA": "@@## #### #### #### #### ##",
  "VG": "@@## **** #### #### #### ####",
};

const defaultIbanPattern = "@@** **** **** **** **** **** ****"

export const useIbanFormatter = (value: string): FixedFormatter => {
  const countryCode = value.slice(0, 2);
  const patternStr = countryIbanMasks[countryCode as CountryCode] ?? defaultIbanPattern;

  return FixedFormatter.pattern(patternStr).uppercase()
}

const FormattedIban = ({value}: { value: string }) => {
  const formatter = useIbanFormatter(value);

  return <FormattedString value={value} formatter={formatter}/>
}

export default FormattedIban;