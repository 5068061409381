import {PropsClassName} from "../../../../helpers/props";
import React, {MouseEventHandler, PropsWithChildren} from "react";
import cx from "classnames";
import "./AbstractRow.scss"
import {RawLink} from "../../Link";
import {useAlertStack} from "../../../../providers/alert-stack";

export type RowModifiersProps = {
  inProgress?: boolean
  disabled?: boolean
  clickable?: boolean
  active?: boolean
  skeleton?: boolean
}

export type RowClickableProps = {
  onClick?: () => void
  onClickAsync?: () => Promise<void>
  onClickDOM?: MouseEventHandler<HTMLDivElement>
  ignoreClick?: (e: React.MouseEvent<HTMLDivElement>) => boolean
  href?: string
  newTab?: boolean
}

type Props = {
  id?: string
} & PropsWithChildren & PropsClassName & RowModifiersProps & RowClickableProps

const AbstractRow = React.forwardRef<HTMLDivElement, Props>((props: Props, ref) => {
  const alertStack = useAlertStack()
  const [stateInProgress, setStateInProgress] = React.useState(false)
  const inProgress = props.inProgress ?? stateInProgress

  const clickable = props.clickable ?? (props.onClick !== undefined || props.onClickAsync !== undefined || props.href !== undefined)

  const onClick: MouseEventHandler<HTMLDivElement> | undefined = function () {
    if (props.href !== undefined) return undefined
    return async (e) => {
      try {
        props.onClickDOM?.(e)
        const ignore = props.ignoreClick?.(e)
        if (props.disabled || !clickable || ignore) {
          return
        }

        if (props.onClick !== undefined) {
          props.onClick()
          return
        }

        if (props.onClickAsync !== undefined) {
          setStateInProgress(true)
          await props.onClickAsync().finally(() => setStateInProgress(false))
          return
        }
      } catch (e) {
        alertStack.showError(e)
        throw e
      }
    }
  }()

  const commonProps = {
    id: props.id,
    className: cx("abstract-row", {
      "abstract-row--clickable": clickable,
      "abstract-row--clickable-active": props.active ?? false,
      "abstract-row--disabled": props.disabled ?? false,
      "abstract-row--clickable-in-progress": inProgress,
    }, props.className),
    children: props.children
  }

  if (props.href !== undefined) {
    return <RawLink href={props.href} newTab={props.newTab ?? false} {...commonProps}/>
  }
  return <div ref={ref} onClick={onClick} {...commonProps}/>
})
export default AbstractRow