import cx from "classnames";
import React from "react";
import {img} from "../../../../helpers/public";
import {CookieModal} from "../../CookieModal";
import {ErrorStack} from "../../Errors";
import "./SideIllustrationLayout.scss"
import {SideIllustrationFile, useSideIllustrationValue} from "../../../../atoms/side-illustration";

type Props = {
  sideIllustration?: SideIllustrationFile
  style?: {
    limitMaxHeight?: boolean;
  }
  children?: React.ReactNode
}

const SideIllustrationLayout = (props: Props) => {
  const state = useSideIllustrationValue()

  return (
    <div className="unauthorized-layout">
      <div className={"unauthorized-layout__illustr"}>
        <img src={img(`side-illustrations/${props.sideIllustration ?? state.sideIllustration}.png`)} alt="Illustration"/>
      </div>
      <div
        className={cx("unauthorized-layout__content-wrapper",
          {"unauthorized-layout__content-wrapper--limit-mh": props.style?.limitMaxHeight}
        )}>
        <div className={"unauthorized-layout__content-main"}>
          {props.children}
        </div>
        <div className={"unauthorized-layout__notifications"}>
          <ErrorStack/>
          <CookieModal/>
        </div>
      </div>
    </div>
  )
}

export default SideIllustrationLayout;
