import React, {useMemo} from "react";
import {useRecipientsQuery} from "../../api/recipients";
import {RecipientType} from "../../api/whales/Recipients";
import RecipientTypeValue from "../../components/domain/recipients/RecipientTypeValue";
import {Section} from "../../components/ui/collections/Section";
import {Recipient} from "../../components/domain/recipients/domain";
import {Currency} from "../../helpers/money";
import {PropsOnComplete} from "../../helpers/props";
import {LimiterOverflow} from "../../components/ui/Limiter";
import Icon from "../../components/ui/icons/Icon";
import DefaultRow from "../../components/ui/collections/rows/DefaultRow";
import RecipientAvatar from "../../components/domain/recipients/RecipientAvatar";
import _ from "lodash";

type Props = {
  recipientCurrency?: Currency;
} & PropsOnComplete<Recipient>

const RecipientList = (props: Props) => {
  const recipientsQuery = useRecipientsQuery();

  const recipientsListResponse = recipientsQuery.recipient.list.useQuery()

  const recipients = recipientsListResponse.data

  const filteredRecipients = useMemo(() => {
    if (props.recipientCurrency === undefined) return recipients
    return recipients?.filter((recipient) => {
      return recipient.bankAccount.currency.code === props.recipientCurrency?.code
    })
  }, [recipients, props.recipientCurrency])

  const recipientListForRender = useMemo(() => {
    if (filteredRecipients === undefined) return undefined
    return _.sortBy(filteredRecipients, (recipient) => recipient.createdAt)
  }, [filteredRecipients])

  const onRecipientClick = async (recipient: Recipient) => {
    return props.onComplete(recipient)
  }

  const renderList = (type: RecipientType, recipients: Recipient[] | undefined) => {
    const rows = recipients?.map((recipient, idx) => {
      const title = recipient.displayName

      return (
        <DefaultRow
          key={idx}
          leadingImage={<RecipientAvatar size={"regular"} recipient={recipient}/>}
          title={title ? title : "Unknown"}
          subtitle={`${recipient.bankAccount.currency.code} • ${recipient.last4}`}
          trailingIcon={<Icon name={"chevron-right"}/>}
          onClickAsync={() => onRecipientClick(recipient)}
        />
      );
    }) ?? Array.from({length: 2}).map((_, index) => {
      return (
        <DefaultRow
          key={index}
          skeleton={true}
          leadingImage={<RecipientAvatar size={"regular"} recipient={undefined}/>}
          title={"Loading"}
          subtitle={"Loading"}
          trailingIcon={undefined}
        />
      );
    })

    return (
      <>
        <Section title={<RecipientTypeValue value={type}/>}>
          <LimiterOverflow overflow={"16px"} children={rows}/>
        </Section>
      </>
    )
  }


  const renderLists = function () {
    const typedRecipients = new Map<RecipientType, Recipient[]>()
    for (const recipient of recipientListForRender || []) {
      if (recipient.status === "deleted") {
        continue
      }

      if (!typedRecipients.has(recipient.type)) {
        typedRecipients.set(recipient.type, [])
      }

      typedRecipients.get(recipient.type)?.push(recipient)
    }

    return Array.from(
      ["own", "person", "business"] as const
    ).map((type) => {
      const recipientsOfType = typedRecipients?.get(type)

      if (recipientListForRender !== undefined && recipientsOfType === undefined) {
        return null
      }

      return (
        <React.Fragment key={type}>
          {renderList(type, recipientListForRender !== undefined ? (recipientsOfType ?? []) : undefined)}
        </React.Fragment>
      )
    })
  }

  return (
    <div>
      {renderLists()}
    </div>
  )
};

export default RecipientList;