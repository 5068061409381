import React from "react";
import SideIllustrationLayout from "../../../components/ui/layout/unauthorized/SideIllustrationLayout";
import Limiter from "../../../components/ui/Limiter";
import SignUpForm from "./SignUpForm";
import SignUpSwitcher from "./SignUpSwitcher";
import "./SignUpSwitcher.scss"
import {TitleSubtitleCenterScreen} from "../../../components/ui/screen/TitleSubtitleScreen";
import OnboardingScreen from "../../../components/onboarding/OnboardingScreen";
import {useStrings} from "../../../strings";

type Props = {
  userType: "business" | "individual"
};

const SignUpPage = (props: Props) => {
  const strings = useStrings()
  return (
    <SideIllustrationLayout sideIllustration={"app-showcase"}>
      <OnboardingScreen>
        <TitleSubtitleCenterScreen title={strings["signup.lets_get_started"]}>
          <Limiter className={"mx-auto"} contentMaxWidth={"460px"}>
            <SignUpSwitcher userType={props.userType} className={"mb-[20px]"}/>
            <SignUpForm userType={props.userType}/>
          </Limiter>
        </TitleSubtitleCenterScreen>
      </OnboardingScreen>
    </SideIllustrationLayout>
  )
}

export default SignUpPage;