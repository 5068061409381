import {VerificationProps} from "./verification-controller";
import {InterfaceTemplates} from "../domain/interface-template/domain";
import {useVerificationApi} from "../../api/verification";
import InterfaceTemplateMultiSelectScreen
  from "../domain/interface-template/screens/InterfaceTemplateMultiSelectScreen";

type Props = VerificationProps & {
  template: InterfaceTemplates.MultiSelect
}

const VerificationMultiSelectStepSubmit = (props: Props) => {
  const verificationApi = useVerificationApi()
  return (
    <InterfaceTemplateMultiSelectScreen
      titleAlign={props.titleAlign}
      template={props.template}
      onComplete={async (value) => {
        await verificationApi.submit({
          verificationId: props.verification.id,
          value: {
            kind: "multiSelect",
            multiSelectValue: {
              options: value.multiSelectValue.options.map(option => option.toApi()),
              otherText: value.multiSelectValue.otherText !== undefined ? {
                text: value.multiSelectValue.otherText
              } : undefined
            }
          }
        })
        await props.onComplete()
      }}
    />
  )
}

export default VerificationMultiSelectStepSubmit