import cx from "classnames";
import React, {PropsWithChildren, useEffect, useRef} from "react";
import "./ContentScreen.scss"
import {useContentScreenState, useLeftColumnValue, useRightColumnValue} from "../../atoms/content-screen";
import {PropsClassName} from "../../helpers/props";
import {useDelayUnmount} from "../../helpers/hooks";
import {defaultAnimDurationMillis, Variables} from "../../variables";
import {useEffectOnce} from "../../hooks/effect";

type Props = {
  children: React.ReactNode;
  inner?: boolean;
  full?: boolean;
  leftColumn?: React.ReactNode;
  rightColumn?: React.ReactNode;
}

type ColumnProps = {
  l: boolean;
  r: boolean;
  center: boolean;
} & PropsWithChildren

const Column = (props: ColumnProps) => {
  const maxW = function () {
    if (props.l) return Variables.ui.spacing.contentScreen.leftColumnMaxWidth
    if (props.r) return Variables.ui.spacing.contentScreen.rightColumnMaxWidth
    return undefined
  }()

  const classes = cx(
    "content-screen__column",
    "scrollbar-thin scrollbar-w-[6px] scrollbar-thumb-rounded scrollbar-thumb-primary-20 scrollbar-thumb-rounded-full scrollbar-track-rounded-full",
    {
      "content-screen__column--main": props.children && props.center,
      "content-screen__column--side": props.children && !props.center,
      "content-screen__column--hidden": !props.children,
      "content-screen__column--l": props.l,
      "content-screen__column--r": props.r,
    }
  )

  const lastContent = useRef<React.ReactNode>(props.children)
  if (props.children) lastContent.current = (
    <div className={cx("content-screen__column-content")}
      // style={{flexBasis: maxW && `${maxW - 2 * Variables.ui.spacing.borderWidth}px`}}
    >
      {props.children}
    </div>
  )

  const {shouldRender} = useDelayUnmount(props.children !== undefined, defaultAnimDurationMillis)

  return (
    <div
      className={classes}
      style={{maxWidth: maxW && `${maxW}px`}}
    >
      {props.center && lastContent.current}
      {(props.l || props.r) && shouldRender && lastContent.current}
    </div>
  )
}

/**
 * @deprecated: Use StatefulContentScreen
 */
export const ContentScreen = (props: Props) => {
  return (
    <div className="content-screen">
      <Column l={true} r={false} center={false} children={props.leftColumn}/>
      <Column l={false} r={false} center={true} children={props.children}/>
      <Column l={false} r={true} center={false} children={props.rightColumn}/>
    </div>
  );
};

const LeftStatefulColumn = (props: {}) => {
  const content = useLeftColumnValue()

  return <Column {...props} l={true} r={false} center={false} children={content}/>
}

const RightStatefulColumn = (props: {}) => {
  const content = useRightColumnValue()

  return <Column {...props} l={false} r={true} center={false} children={content}/>
}

type StatefulProps = {} & PropsWithChildren & PropsClassName
export const StatefulContentScreen = (props: StatefulProps) => {
  return (
    <div className={cx("content-screen", props.className)}>
      <LeftStatefulColumn/>
      <Column l={false} r={false} center={true} children={props.children}/>
      <RightStatefulColumn/>
    </div>
  )
}

const SetColumn = ({key, setter, children}: {
  key: string,
  setter: (key: string, content?: React.ReactNode) => void
} & PropsWithChildren) => {
  useEffect(() => {
    setter(key, children)
  }, [setter, children, key])

  useEffectOnce(() => {
    return () => {
      setter(key, undefined)
    }
  })

  return null
}

export const SetLeftColumn = (props: { key: string } & PropsWithChildren) => {
  const contentScreenState = useContentScreenState();
  return <SetColumn setter={contentScreenState.setLeft} {...props}/>
}

export const SetRightColumn = (props: { key: string } & PropsWithChildren) => {
  const contentScreenState = useContentScreenState();
  return <SetColumn setter={contentScreenState.setRight} {...props}/>
}