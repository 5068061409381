import React, {useCallback, useEffect} from "react";
import {atom, useRecoilState, useRecoilValue} from "recoil";

export type ComponentNavStackEntry<T, Ctx> = {
  component: React.FC<T & Ctx>
  args: T
}

export type NavStackEntry<T, Ctx> = ComponentNavStackEntry<T, Ctx>

export type NavStackState = {
  stackEntries: NavStackEntry<any, any>[]
}

const stackAtom = atom<NavStackState>({
  key: "navStack",
  default: {
    stackEntries: []
  }
})

const displayStack = (stack: NavStackState["stackEntries"]) => {
  const result = []
  for (const entry of stack) {
    result.push({name: entry.component.name, args: entry.args})
  }
  return result
}

export const useNavStackValue = () => {
  const state = useRecoilValue(stackAtom)

  useEffect(() => {
    debugNavStack("displayNavStack", displayStack(state.stackEntries))
  }, [state.stackEntries]);

  return state
}

export const debugNavStack = function (name: string, ...args: any[]) {
  const DEBUG = true
  if (process.env.NODE_ENV === "development" && DEBUG) {
    console.debug("NavStack:", name, ...args)
  }
}

type PopUntilOpts = {
  component: React.FC<any>,
  inclusive?: boolean
}

const popUntilImpl = (stack: NavStackState["stackEntries"], opts: PopUntilOpts): NavStackState["stackEntries"] => {
  const newStack = [...stack]
  while (newStack.length > 0) {
    const last = newStack[newStack.length - 1]
    if (last.component.name === opts.component.name) {
      if (opts.inclusive === true) {
        newStack.pop()
      }
      break
    }
    newStack.pop()
  }
  return newStack
}


export type NavStack<Ctx, Common> = ReturnType<typeof useNavStack<Ctx, Common>>

export type NavStackPush<Ctx, Common> = NavStack<Ctx, Common>["push"]

export type PushOptions = {
  replace?: boolean,
  popUntil?: PopUntilOpts
  popAll?: boolean
}

export const useNavStack = <Ctx, Common>() => {
  const [state, setState] = useRecoilState(stackAtom)

  const popUnsafe = useCallback(() => {
    setState((prev) => {
      const newStack = [...prev.stackEntries]
      newStack.pop()
      return {...prev, stackEntries: newStack}
    })
  }, [setState])
  return {
    top: state.stackEntries[state.stackEntries.length - 1],
    push: useCallback(<T extends Common, Ctx2 extends Ctx>(
      component: React.FC<T & Ctx2>,
      args: T,
      options?: PushOptions
    ) => {
      debugNavStack("push", component.name, args, options)
      setState((prev) => {
        let newStack = [...prev.stackEntries]
        if (options?.replace === true) {
          newStack.pop()
        }
        if (options?.popUntil !== undefined) {
          newStack = popUntilImpl(newStack, options.popUntil)
        }
        if (options?.popAll === true) {
          newStack = []
        }
        newStack.push({component: component, args: args})
        return {
          ...prev,
          stackEntries: newStack
        }
      })
    }, [setState]),
    popUnsafe: popUnsafe,
    pop: state.stackEntries.length > 1 ? popUnsafe : undefined,
    popUntil: (opts: PopUntilOpts) => {
      setState((prev) => {
        return {...prev, stackEntries: popUntilImpl(prev.stackEntries, opts)}
      })
    },
    clear: () => {
      setState((prev) => ({...prev, stackEntries: []}))
    },
  }
}
