import {Currency} from "../../../helpers/money";
import {useIntl} from "react-intl";

// WEB-372: write test for different locales
export const useIMaskOptionsForMoney = (currency?: Currency): IMask.AnyMaskedOptions | undefined => {
  const intl = useIntl()

  if (!currency) {
    return undefined
  }
  const parts = intl.formatNumberToParts(10e9 + 0.50, {style: "currency", currency: currency.code})
  const groupSymbol = parts.find((part) => part.type === "group")?.value
  const decimalSymbol = parts.find((part) => part.type === "decimal")?.value
  const filteredParts = parts.filter((part) => part.type === "integer" || part.type === "literal" || part.type === "currency")
  let mask = ""
  let prevType: string = ""
  for (const part of filteredParts) {
    switch (part.type) {
      case "literal":
      case "currency":
        mask += part.value
        break
      case "integer":
        if (prevType !== "integer") {
          mask += "num"
        }
    }

    prevType = part.type
  }

  return {
    mask: mask,
    lazy: false,
    blocks: {
      num: {
        // nested masks are available!
        mask: Number,
        signed: false,
        thousandsSeparator: groupSymbol || " ",
        radix: decimalSymbol || ".",
        min: 0,
        scale: currency.maximumFractionDigits()
      }
    },
  }
}
