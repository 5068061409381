import {Api} from "./axios";

type QuotesReq = {
    sourceCurrency: string
    targetCurrency: string
    sourceAmount?: number
    targetAmount?: number
}

type PaymentOption = {
    fee: {
        total: number
    }
    sourceAmount: number
    sourceCurrency: string
    targetAmount: number
    targetCurrency: string
}

type QuotesRes = {
    rate: number
    paymentOptions: PaymentOption[]
}

export class WiseApi {
    constructor(private api: Api) {
    }

    quotesV2(req: QuotesReq): Promise<QuotesRes> {
        return this.api.post("/wise-affiliate/v2/quotes", req)
    }
}

export const useWiseApi = () => {
    return new WiseApi(new Api({authenticated: false}, () => { }, () => { }))
}