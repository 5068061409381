import * as RecipientsAPI from "../../../../api/whales/Recipients";
import FormattedAddress, {useAddressFormatter} from "../../../formatters/FormattedAddress";
import {InterfaceTemplateValue, InterfaceTemplateValues} from "../../interface-template/domain";
import FormattedIban from "../../../formatters/FormattedIban";
import FormattedString from "../../../formatters/FormattedString";
import FixedFormatter from "../../../formatters/FixedFormatter";
import {BankAccountElementTemplate, BankAccountTemplate} from "../../../../models/common/BankAccount";
import {BankAccountElementId} from "../../../../api/whales/Common";


export abstract class BankAccountElement {
  readonly identifier: BankAccountElementId
  readonly template?: BankAccountElementTemplate
  readonly value: InterfaceTemplateValues.Value

  protected constructor(other: {
    identifier: BankAccountElementId,
    template?: BankAccountElementTemplate,
    value: InterfaceTemplateValues.Value
  }) {
    this.identifier = other.identifier
    this.template = other.template
    this.value = other.value
  }

  abstract withValue(value: InterfaceTemplateValues.Value): BankAccountElement

  static fromValue(
    elementId: BankAccountElementId,
    value: InterfaceTemplateValues.Value,
    bankAccountTemplate: BankAccountTemplate,
  ): BankAccountElement {
    const common = {
      identifier: elementId,
      template: bankAccountTemplate.elements.find(element => element.identifier === elementId)
    }

    switch (value.kind) {
      case "text":
        return new BankAccountElementText({
          ...common,
          value: value
        })
      case "picker":
        return new BankAccountElementPicker({
          ...common,
          value: value
        })
      case "address":
        return new BankAccountElementAddress({
          ...common,
          value: value
        })
      case "singleSelect":
        return new BankAccountElementSingleSelect({
          ...common,
          value: value
        })
      default:
        throw new Error(`Unknown element type: ${value}`)
    }
  }

  static fromApi(
    apiValue: RecipientsAPI.Recipient["bankAccount"]["elements"][0],
    bankAccountTemplate: BankAccountTemplate,
  ): BankAccountElement {
    const value = InterfaceTemplateValue.fromApi(apiValue.value)

    return BankAccountElement.fromValue(apiValue.elementId, value, bankAccountTemplate)
  }

  static defaultValue(elementId: BankAccountElementId, template: BankAccountTemplate) {
    const templateElement = template.elements.find((element) => element.identifier === elementId)!
    return BankAccountElement.fromValue(
      elementId,
      InterfaceTemplateValue.defaultForTemplate(templateElement.interfaceTemplate),
      template
    )
  }

  static fallbackValue(elementId: BankAccountElementId, template: BankAccountTemplate) {
    const templateElement = template.elements.find((element) => element.identifier === elementId)!
    return BankAccountElement.fromValue(
      elementId,
      InterfaceTemplateValue.fallbackForTemplate(templateElement.interfaceTemplate),
      template
    )
  }
}

export class BankAccountElementText extends BankAccountElement {
  readonly value: InterfaceTemplateValues.Text

  constructor(other: {
    identifier: BankAccountElementId,
    value: InterfaceTemplateValues.Text,
    template?: BankAccountElementTemplate
  }) {
    super(other)
    this.value = other.value
  }

  withValue(value: InterfaceTemplateValues.Value): BankAccountElement {
    return new BankAccountElementText({
      ...this,
      value: value as InterfaceTemplateValues.Text
    })
  }

}

export class BankAccountElementPicker extends BankAccountElement {
  readonly value: InterfaceTemplateValues.Picker

  constructor(other: {
    identifier: BankAccountElementId,
    value: InterfaceTemplateValues.Picker,
    template?: BankAccountElementTemplate
  }) {
    super(other)
    this.value = other.value
  }

  withValue(value: InterfaceTemplateValues.Value): BankAccountElement {
    return new BankAccountElementPicker({
      ...this,
      value: value as InterfaceTemplateValues.Picker
    })
  }
}

export class BankAccountElementAddress extends BankAccountElement {
  readonly value: InterfaceTemplateValues.Address

  constructor(other: {
    identifier: BankAccountElementId,
    value: InterfaceTemplateValues.Address,
    template?: BankAccountElementTemplate
  }) {
    super(other)
    this.value = other.value
  }

  withValue(value: InterfaceTemplateValues.Value): BankAccountElement {
    return new BankAccountElementAddress({
      ...this,
      value: value as InterfaceTemplateValues.Address
    })

  }
}

export class BankAccountElementSingleSelect extends BankAccountElement {
  readonly value: InterfaceTemplateValues.SingleSelect

  constructor(other: {
    identifier: BankAccountElementId,
    value: InterfaceTemplateValues.SingleSelect,
    template?: BankAccountElementTemplate
  }) {
    super(other)
    this.value = other.value
  }

  withValue(value: InterfaceTemplateValues.Value): BankAccountElement {
    return new BankAccountElementSingleSelect({
      ...this,
      value: value as InterfaceTemplateValues.SingleSelect
    })
  }
}

const BankAccountElementTextView = ({element}: { element: BankAccountElementText }): JSX.Element => {
  const template = element.template?.interfaceTemplate.typed
  if (element.identifier === "iban") {
    return <FormattedIban value={element.value.textValue}/>
  } else if (template?.kind === "text" && template.text.format) {
    return <FormattedString value={element.value.textValue} formatter={FixedFormatter.pattern(template.text.format)}/>
  } else {
    return <>{element.value.textValue}</>
  }
}

export const useBankAccountElementFormatter = () => {
  const addressFormatter = useAddressFormatter()
  return (element: BankAccountElement): string => {
    if (element instanceof BankAccountElementText) {
      return element.value.textValue
    }

    if (element instanceof BankAccountElementPicker) {
      return element.value.pickerValue.title
    }

    if (element instanceof BankAccountElementAddress) {
      return addressFormatter.formatToString(element.value.addressValue, "one-line")
    }

    if (element instanceof BankAccountElementSingleSelect) {
      return element.value.singleSelectValue.otherText ?? element.value.singleSelectValue.option.title
    }

    console.error("Unknown element type", element)
    throw new Error(`Unknown element type: ${element}`)
  }
}


export const BankAccountElementView = ({element}: { element: BankAccountElement }): JSX.Element => {
  const bankAccountElementFormatter = useBankAccountElementFormatter()

  if (element instanceof BankAccountElementText) {
    return <BankAccountElementTextView element={element}/>
  }

  if (element instanceof BankAccountElementAddress) {
    return <FormattedAddress style={"one-line"} value={element.value.addressValue}/>
  }


  return <>{bankAccountElementFormatter(element)}</>
}