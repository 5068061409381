import {RelativeColloquialType, RelativeColloquialTypeClass} from "../../../helpers/relative-colloquial";
import FormattedDate from "./FormattedDate";
import React from "react";

type Props = {
  value: Date
  past?: (date: JSX.Element) => string,
  today: string,
  tomorrow: string,
  other: (date: JSX.Element) => string

}

const FormattedColloquialDate = (
  {
    value,
    past,
    today,
    tomorrow,
    other
  }: Props) => {
  const str = function () {
    switch (RelativeColloquialTypeClass.from(value)) {
      case RelativeColloquialType.TODAY:
        return today
      case RelativeColloquialType.TOMORROW:
        return tomorrow
      case RelativeColloquialType.WITHIN_A_WEEK:
        return other(
          <FormattedDate value={value} style={"EEEE"}/>
        )
      case RelativeColloquialType.FUTURE:
        return other(
          <FormattedDate value={value} style={"MMMMd"}/>
        )
      case RelativeColloquialType.PAST:
        return past?.(
          <FormattedDate value={value} style={"MMMMd"}/>
        ) ?? ""
    }
  }()

  return <>{str}</>
}

export default FormattedColloquialDate;