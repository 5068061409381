import {useStrings} from "../../strings";
import {Tier} from "../../models/tiers/Tier";
import {WithLearnMoreSuffix} from "../../components/ui/LearnMore";
import {Links} from "../../helpers/Links";
import InformationScreen from "../../components/ui/screen/InformationScreen";
import {Button} from "../../components/ui/Buttons";
import {PropsOnComplete} from "../../helpers/props";
import {img} from "../../helpers/public";

type Props = {
  requestedTier: Tier
} & PropsOnComplete

const LimitsStepInReview = ({requestedTier, onComplete}: Props) => {
  const strings = useStrings()
  return (
    <InformationScreen
      title={strings["tier.upgrade_in_review.title"](requestedTier.name)}
      subtitle={<WithLearnMoreSuffix url={Links.helpCenter.articles.tierUpgradeInReview}
                                     children={strings["tier.upgrade_in_review.subtitle"]}/>}
      media={<img className={"max-w-[375px]"} src={img("gov-id-need-more-time.png")} alt={"Illustration"}/>}
      primaryButton={buttonProps =>
        <Button
          {...buttonProps}
          title={strings["general.done"]}
          type={"button"}
          onClickAsync={onComplete}
        />
      }
    />
  )
}

export default LimitsStepInReview