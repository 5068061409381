import React, {useEffect, useRef} from "react";

import {PropsClassName} from "../../../helpers/props";
import {MaskedInput} from "../../../components/ui/input/Inputs";
import Decimal from "decimal.js";
import cx from "classnames";
import "./EstimateAmountInput.scss";
import {MoneyInputValue} from "../../../helpers/money";
import {useIMaskOptionsForMoney} from "../../../components/formatters/money/imask-money-formatter";

type Props = {
  innerRef?: React.Ref<HTMLInputElement>
  autoFocus?: boolean;
  value?: MoneyInputValue
  onChange: (value: Decimal | "empty") => void;
  onFocus?: () => void;
  disabled?: boolean;
  isLoading?: boolean;
  id?: string;
} & PropsClassName;

const EstimateAmountInput = ({value, onChange, ...props}: Props) => {
  const iMaskOptions = useIMaskOptionsForMoney(value?.currency)
  const [isFocused, setIsFocused] = React.useState(false)

  const ref = React.useRef<HTMLInputElement>(null)
  const autoFocusTriggered = useRef(false)

  useEffect(() => {
    if (ref.current && value !== undefined && props.autoFocus && !autoFocusTriggered.current) {
      // Not working for some reason :(
      autoFocusTriggered.current = true
      ref.current.focus()
    }
  }, [props.autoFocus, value]);

  return <MaskedInput
    {...props}
    innerRef={ref}
    className={cx("estimate-amount-input", props.className)}
    skeleton={{adjust: {left: 2, right: 2, bottom: 2.5}}}
    iMaskOptions={iMaskOptions}
    type={"text"}
    capitalization={"none"}
    inputMode={"decimal"}
    value={value?.amount === "empty" ? (isFocused ? "" : "0") : value?.amount.toFixed()}
    onChangeMasked={(newValue) => {
      const newAmount = newValue.unmasked === "" ? "empty" : new Decimal(newValue.unmasked);
      onChange?.(newAmount)
    }}
    onFocus={() => {
      setIsFocused(true)
      props.onFocus?.()
    }}
    onBlur={() => {
      setIsFocused(false)
    }}
    error={undefined}
  />
}

export default EstimateAmountInput;