import {FormattedInput, InputCapitalization, InputGroup, InputGroupRow, TextAutocomplete, TextInput} from "./Inputs";
import {CountryCode} from "../../../helpers/countries";
import React, {useCallback} from "react";
import {InlineError} from "../Errors";
import CountryPicker from "../CountryPicker";
import Collapsable from "../Collapsable";
import cx from "classnames";
import * as Common from "../../../api/whales/Common";
import {StringsObj, useStrings} from "../../../strings";
import FixedFormatter from "../../formatters/FixedFormatter";
import {useAddressPartsFormatter} from "../../formatters/FormattedAddress";
import Formatter from "../../formatters/Formatter";

type AddressFieldKind = "city" | "stateOrProvince" | "postcode"

type AddressField = {
  kind: AddressFieldKind
  placeholder: string
  capitalization: InputCapitalization,
  formatter: Formatter | null,
  autoComplete: TextAutocomplete
}

type AddressFieldOpt = {
  kind: AddressFieldKind
  placeholder?: string
  capitalization?: InputCapitalization,
  formatter?: FixedFormatter
}

const postcodeCity: AddressFieldOpt[] = [{kind: "postcode"}, {kind: "city"}]

const customFields = (strings: StringsObj): Record<string, AddressFieldOpt[]> => {
  const genericStatePlaceholder = strings["address.generic.state.placeholder"]
  const genericProvincePlaceholder = strings["address.generic.province.placeholder"]

  return {
    [CountryCode.Australia]: [
      {kind: "city", placeholder: strings["address.au.suburb.placeholder"]},
      {kind: "stateOrProvince", placeholder: genericStatePlaceholder, capitalization: "characters"},
      {kind: "postcode"}
    ],
    [CountryCode.Belgium]: [{kind: "city"}, {kind: "postcode"}],
    [CountryCode.Canada]: [
      {kind: "city"},
      {kind: "stateOrProvince", placeholder: genericProvincePlaceholder, capitalization: "characters"},
      {kind: "postcode"}
    ],
    [CountryCode.CzechRepublic]: postcodeCity,
    [CountryCode.Germany]: postcodeCity,
    [CountryCode.Denmark]: postcodeCity,
    [CountryCode.Spain]: postcodeCity,
    [CountryCode.France]: postcodeCity,
    [CountryCode.UnitedKingdom]: [{kind: "city"}, {
      kind: "postcode",
      placeholder: strings["address.gb.postcode.placeholder"]
    }],
    [CountryCode.Italy]: [
      {kind: "city"},
      {kind: "postcode"},
      {
        kind: "stateOrProvince",
        placeholder: genericProvincePlaceholder,
        capitalization: "characters"
      }
    ],
    [CountryCode.Norway]: postcodeCity,
    [CountryCode.Poland]: postcodeCity,
    [CountryCode.Sweden]: postcodeCity,
    [CountryCode.UnitedStates]: [
      {kind: "city"},
      {kind: "stateOrProvince", placeholder: genericStatePlaceholder, capitalization: "characters"},
      {kind: "postcode", placeholder: strings["address.us.postal_code.placeholder"]},
    ]
  }
}

const genericCityField = (strings: StringsObj): AddressField => ({
  kind: "city",
  placeholder: strings["address.generic.city.placeholder"],
  capitalization: "words",
  formatter: null,
  autoComplete: "address-level-2"
})

const genericStateOrProvinceField = (strings: StringsObj): AddressField => ({
  kind: "stateOrProvince",
  placeholder: strings["address.generic.region.placeholder"],
  capitalization: "words",
  formatter: null,
  autoComplete: "address-level-1"
})

const genericPostcodeField = (strings: StringsObj): AddressField => ({
  kind: "postcode",
  placeholder: strings["address.generic.postcode.placeholder"],
  capitalization: "characters",
  formatter: null,
  autoComplete: "postal-code"
})

const useAddressRemainderConfig = (countryCode: string): AddressField[] => {
  const strings = useStrings()
  const addressPartsFormatter = useAddressPartsFormatter()
  const custom = customFields(strings)[countryCode]
  if (!custom) {
    return [genericCityField(strings), genericStateOrProvinceField(strings), genericPostcodeField(strings)]
  }
  return custom.map(({kind, ...rest}) => {
    return (() => {
      switch (kind) {
        case "city":
          return {...genericCityField(strings), ...rest}
        case "stateOrProvince":
          return {
            ...genericStateOrProvinceField(strings),
            formatter: addressPartsFormatter(countryCode).stateOrProvince,
            ...rest
          }
        case "postcode":
          return {
            ...genericPostcodeField(strings),
            formatter: addressPartsFormatter(countryCode).postcode,
            ...rest
          }
      }
    })()
  })
}

type AddressRemainderLineProps = {
  disabled: boolean
  error: any
  onBlur?: () => void
  inputProps: {
    [k in AddressFieldKind]: {
      value: string | undefined
      onChange: (newValue: string) => void,
    }
  }
  fields: AddressField[]
}

const AddressRemainderLine = (props: AddressRemainderLineProps) => {
  return (
    <InputGroupRow>
      {props.fields.map((field) => {
        const commonProps = {
          ...props,
          key: field.kind,
          className: "flex-1",
          placeholder: field.placeholder,
          autoComplete: field.autoComplete,
          capitalization: field.capitalization,
          errorMessageHidden: true,
          value: props.inputProps[field.kind]?.value,
          onChange: props.inputProps[field.kind]?.onChange,
        }

        if (field.formatter) {
          return <FormattedInput type={"text"} {...commonProps} formatter={field.formatter.withNoPlaceholder()}/>
        } else {
          return <TextInput {...commonProps} />
        }
      })
      }
    </InputGroupRow>
  )
}

type AddressRemainderProps = {
  countryCode: string
  inputProps: {
    [k in AddressFieldKind]: {
      value: string | undefined
      onChange: (newValue: string) => void,
    }
  }
  disabled: boolean
  error: any,
  onBlur?: () => void
}

const AddressRemainder: React.FC<AddressRemainderProps> = (props): React.ReactElement => {
  const remainderFields = useAddressRemainderConfig(props.countryCode)
  if (remainderFields.length < 2 || remainderFields.length > 3) {
    throw Error(`invalid remainder fields: ${remainderFields}`)
  }
  const [field1, ...rest] = remainderFields

  const fieldLayoutForLen: { [k in string]: React.ReactElement } = {
    "2": (
      <AddressRemainderLine {...props} fields={remainderFields}/>
    ),
    "3": (
      <>
        <AddressRemainderLine {...props} fields={[field1]}/>
        <AddressRemainderLine {...props} fields={rest}/>
      </>
    )
  }

  const layout = fieldLayoutForLen[`${remainderFields.length}`]
  if (!layout) {
    throw Error(`failed to choose layout for ${remainderFields}`)
  }
  return layout
}

type Props = {
  availableCountries?: string[]
  suggestedCountryCode?: string
  disabled: boolean
  value?: AddressInputValue
  onChange: (newValue: AddressInputValue) => void
  onBlur?: () => void
  error?: any
}

export type AddressInputValue = {
  addressLine1: string
  addressLine2: string
  city: string
  stateOrProvince: string
  postcode: string
  countryCode: string | null
}

export const fromApiAddress = (modelAddress?: Common.Address): AddressInputValue => ({
  addressLine1: modelAddress?.addressLine1 ?? "",
  addressLine2: modelAddress?.addressLine2 ?? "",
  city: modelAddress?.city ?? "",
  stateOrProvince: modelAddress?.stateOrProvince ?? "",
  postcode: modelAddress?.postcode ?? "",
  countryCode: modelAddress?.countryCode ?? "",
})

export const toApiAddressModel = (value?: AddressInputValue): Common.Address => ({
  countryCode: value?.countryCode ?? "",
  addressLine1: value?.addressLine1 ?? "",
  addressLine2: value?.addressLine2 ?? null,
  city: value?.city ?? "",
  stateOrProvince: value?.stateOrProvince,
  postcode: value?.postcode ?? ""
})

export const AddressInputs = ({value, onBlur, onChange, ...props}: Props) => {
  const strings = useStrings()

  const onChangeInput = useCallback((field: keyof AddressInputValue) => (newValue: string) => {
    if (value === undefined) return
    onChange({...value, [field]: newValue})
  }, [onChange, value])

  const needCountyPicker = props.availableCountries === undefined || props.availableCountries.length > 1
  let selectedCountry = value?.countryCode ?? undefined
  if (!needCountyPicker && props.availableCountries) {
    if (value) value.countryCode = props.availableCountries[0]
    selectedCountry = props.availableCountries[0]
  }

  return (
    <>
      {needCountyPicker && <CountryPicker
          placeholder={strings["address.country.title"]}
          countryCodes={props.availableCountries}
          suggestCountryCode={props.suggestedCountryCode}
          disabled={props.disabled}
          value={value?.countryCode || null}
          error={props.error}
          onChange={(newValue: string | null) => {
            value && onChange({...value, countryCode: newValue})
          }}
      />}
      <Collapsable expanded={selectedCountry !== undefined && selectedCountry !== ""}>
        <TextInput
          className={cx({"mt-[20px]": needCountyPicker})}
          placeholder={strings["address.line_one.placeholder"]}
          disabled={props.disabled}
          autoComplete={"address-line-1"}
          capitalization={"words"}
          value={value?.addressLine1}
          error={props.error}
          errorMessageHidden={true}
          onChange={onChangeInput("addressLine1")}
          onBlur={onBlur}
        />
        <TextInput placeholder={strings["address.line_two.placeholder"]}
                   disabled={props.disabled}
                   autoComplete={"address-line-2"}
                   capitalization={"words"}
                   value={value?.addressLine2}
                   error={props.error}
                   errorMessageHidden={true}
                   onChange={onChangeInput("addressLine2")}
                   onBlur={onBlur}
        />
        <AddressRemainder
          countryCode={selectedCountry || ""}
          inputProps={{
            "city": {
              value: value?.city,
              onChange: onChangeInput("city")
            },
            "stateOrProvince": {
              value: value?.stateOrProvince,
              onChange: onChangeInput("stateOrProvince")
            },
            "postcode": {
              value: value?.postcode,
              onChange: onChangeInput("postcode")
            }
          }}
          error={props.error}
          disabled={props.disabled}
          onBlur={onBlur}
        />
      </Collapsable>
      <InlineError error={props.error}/>
    </>
  )
}

const AddressInputGroup = (props: Props & {
  name: string
  classNames?: {
    inputGroup: string
  }
}) => {
  return (
    <InputGroup name={props.name} className={props.classNames?.inputGroup}>
      <AddressInputs {...props}/>
    </InputGroup>
  )
}

export default AddressInputGroup