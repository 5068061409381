import {IMask} from "react-imask";

abstract class Formatter {
  abstract asYouTypePlaceholder(): string | undefined
  abstract iMaskOptions(): IMask.AnyMaskedOptions
  abstract isNumericOnly(): boolean

  format(str: string): string {
    const mask = IMask.createMask(this.iMaskOptions())
    return mask.resolve(str)
  }

  withNoPlaceholder(): Formatter {
    const that = this
    return new class extends Formatter {
      asYouTypePlaceholder(): string | undefined {
        return undefined;
      }

      iMaskOptions(): IMask.AnyMaskedOptions {
        return that.iMaskOptions()
      }

      isNumericOnly(): boolean {
        return that.isNumericOnly();
      }
    }()
  }
}

export default Formatter