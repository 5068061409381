import * as TransferCommon from "../../api/whales/TransferCommon";

export class TransferStatus {
  readonly string: TransferCommon.TransferStatus | "estimate"

  constructor(s: TransferStatus["string"]) {
    this.string = s
  }

  get excludeEstimate(): TransferCommon.TransferStatus | undefined {
    if (this.string === "estimate") return undefined
    return this.string
  }

  get hasTraded(): boolean {
    const self = this
    const result: boolean = function () {
      switch (self.string) {
        case 'estimate':
        case 'created':
        case 'checks_sent':
        case 'checks_pending':
        case 'checks_approved':
        case 'checks_failed':
        case 'payin_waiting':
        case 'payin_initiated':
        case 'canceled':
        case 'payin_received':
        case 'payin_checks_approved':
        case 'fee_debited':
        case 'trade_pending':
        case 'payin_checks_sent':
        case 'payin_checks_pending':
        case 'payin_checks_failed':
        case 'refund_failed':
        case 'refund_pending':
        case 'refund_completed':
          return false;

        case 'trade_placed':
        case 'trade_settled':
        case 'payout_pending':
        case 'payout_completed':
        case 'payout_failed':
        case 'payout_checks_pending':
        case 'payout_checks_approved':
        case 'payout_checks_failed':
          return true;      }
    }()

    return result ?? false
  }

  isInitiating(): boolean {
    const self = this
    const result: boolean = function () {
      switch (self.string) {
        case "estimate":
        case "created":
        case "checks_sent":
        case "checks_pending":
        case "checks_approved":
          return true

        case 'payin_waiting':
        case 'payin_initiated':
        case 'payin_checks_failed':
        case 'refund_pending':
        case 'payin_checks_approved':
        case 'fee_debited':
        case 'trade_pending':
        case 'trade_placed':
        case 'trade_settled':
        case 'payout_pending':
        case 'payout_completed':
        case 'canceled':
        case 'refund_completed':
        case 'checks_failed':
        case 'payout_failed':
        case 'refund_failed':
        case 'payin_checks_sent':
        case 'payin_checks_pending':
        case 'payin_received':
        case 'payout_checks_pending':
        case 'payout_checks_approved':
        case 'payout_checks_failed':
          return false
      }
    }()

    return result ?? false
  }

    isInProgress(): boolean {
      const self = this
      const result: boolean = function () {
        switch (self.string) {
          case "estimate":
          case "created":
          case "checks_sent":
          case "checks_pending":
          case "checks_approved":
          case 'payin_waiting':
          case 'payin_initiated':
          case 'payin_checks_failed':
          case 'refund_pending':
          case 'payin_checks_approved':
          case 'fee_debited':
          case 'trade_pending':
          case 'trade_placed':
          case 'trade_settled':
          case 'payout_pending':
          case 'payout_failed':
          case 'refund_failed':
          case 'payin_checks_sent':
          case 'payin_checks_pending':
          case 'payin_received':
          case 'payout_checks_pending':
          case 'payout_checks_approved':
          case 'payout_checks_failed':
            return true
          case 'payout_completed':
          case 'refund_completed':
          case 'canceled':
          case 'checks_failed':
            return false
        }
      }()

      return result ?? false
  }
}
