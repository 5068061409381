import cx from "classnames";
import {CloseButton} from "../Buttons";
import Caption from "../Caption";
import Icon, {IconName} from "../icons/Icon";

type FilePreviewProps = {
  file: File
  onRemove: () => void
  className?: string
}

const FilePreview = (props: FilePreviewProps) => {
  return (
    <div className={cx("p-4 flex gap-2 border-primary-20 border rounded-[8px] items-center", props.className)}>
      <Icon className={"shrink-0"} name={function (): IconName {
        if (props.file.type.startsWith("image")) {
          return "image-01"
        } else {
          return "file-04"
        }
      }()}/>
      <Caption className={"truncate grow"} size={"3"} text={props.file.name}/>
      <CloseButton className={"shrink-0"} onClick={props.onRemove}/>
    </div>
  )
}

type FileListPreviewProps = {
  files: File[]
  onRemove: (index: number) => void
}

export const FileListPreview = (props: FileListPreviewProps) => {
  return (
    <div className="flex flex-col gap-4">
      {props.files.map((file, index) => (
        <FilePreview key={index} file={file} onRemove={() => props.onRemove(index)}/>
      ))}
    </div>
  )
}

export default FilePreview