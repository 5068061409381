import {svgSprite} from "../../../helpers/public";
import {PropsClassName} from "../../../helpers/props";
import cx from "classnames";
import {CSSProperties} from "react";

export type IconName =
  | "archive-filled"
  | "archive-transparent"
  | "arrow-narrow-right"
  | "arrow-narrow-down"
  | "arrows-right"
  | "atlantic-logo-graphic"
  | "button-close"
  | "check-small"
  | "check-circle-solid"
  | "chevron-up"
  | "chevron-down"
  | "chevron-right"
  | "dot"
  | "file-04"
  | "gift-transparent"
  | "gift-solid"
  | "help-transparent"
  | "help-solid"
  | "help-circle"
  | "image-01"
  | "mark"
  | "plus"
  | "row-check"
  | "search-sm"
  | "send-filled"
  | "send-transparent"
  | "settings-filled"
  | "settings-transparent"
  | "speedometer-filled"
  | "speedometer-transparent"
  | "user-filled"
  | "user-transparent"
  | "users-filled"
  | "users-transparent"
  | "user-plus-01"
  | "transfer-shield"
  | "transfer-local"
  | "transfer-one-transfer"
  | "tilda-circle-01"
  | "logo-small"
  | "logo-text"
  | "log-out"
  | "log-in"
  | "circle-atlantic-logo"
  | "circle-help-center"
  | "circle-imessage-logo"
  | "circle-whatsapp-logo"
  | "estimate-triangle"


interface Props<T extends IconName = IconName> extends PropsClassName {
  name: T;
  hidden?: boolean;
  colorScheme?: "dark" | "light"
  style?: CSSProperties
}

type Size = { w: number, h: number }

const sizes: Partial<Record<IconName, Size>> = {
  "atlantic-logo-graphic": {w: 56, h: 32},
  "dot": {w: 5, h: 24},
  "mark": {w: 22, h: 22},
  "transfer-local": {w: 20, h: 20},
  "transfer-one-transfer": {w: 20, h: 20},
  "logo-small": {w: 32, h: 19},
  "logo-text": {w: 128, h: 19},
  "arrow-narrow-down": {w: 13, h: 13},
  "circle-atlantic-logo": {w: 40, h: 40},
  "circle-help-center": {w: 40, h: 40},
  "circle-imessage-logo": {w: 40, h: 40},
  "circle-whatsapp-logo": {w: 40, h: 40},
  "estimate-triangle": {w: 12, h: 12},
  "help-circle": {w: 12, h: 12},
}

const pickSize = (name: IconName): Size => {
  return sizes[name] || {w: 24, h: 24}
}

const Icon = (props: Props) => {
  const hidden = props.hidden ?? false;
  const {name, ..._rest} = props;
  const size = pickSize(name);

  const modifier = props.colorScheme ?? "dark";
  return (
    <svg
      className={cx(props.className, "transition-opacity default-anim", {"opacity-0": hidden})}
      width={size.w}
      height={size.h}
      style={props.style}
    >
      <use href={`${svgSprite("icons", name)}`}/>
      <use href={`${svgSprite("icons", name, modifier)}`}/>
    </svg>
  )
};

export default Icon;