import cx from "classnames";
import {PropsClassName} from "../../../helpers/props";
import React from "react";
import {TitleSubtitleCenter} from "./TitleSubtitleScreen";
import ButtonBar from "../ButtonBar";
import {BlockButtonProps} from "../Buttons";

type Btn = (props: Pick<BlockButtonProps, "size" | "color">) => React.ReactNode

type Props = {
  title: React.ReactNode
  subtitle?: React.ReactNode
  media: React.ReactNode
  primaryButton: Btn
  secondaryButton?: Btn
} & PropsClassName

const InformationScreen = (props: Props) => {
  return (
    <div className={cx(props.className, "flex-1 flex flex-col items-center justify-center")}>
      {props.media}
      <TitleSubtitleCenter title={props.title} subtitle={props.subtitle}/>
      <ButtonBar className={"mt-8"} align={"center"}>
        {props.primaryButton({size: "big", color: "primary-black"})}
        {props.secondaryButton?.({size: "big", color: "secondary"})}
      </ButtonBar>
    </div>
  )
}

export default InformationScreen