import Icon, {IconName} from "../ui/icons/Icon";
import Caption from "../ui/Caption";
import cx from "classnames";
import {NavLink} from "react-router-dom";
import {useAlertStack} from "../../providers/alert-stack";

type Props = {
  activeIcon?: IconName,
  inactiveIcon: IconName,
  title: string,
  url?: string,
  newTab?: boolean,
  onClick?: () => void,
}

const SidebarMenuItem = (props: Props) => {
  const alertStack = useAlertStack()
  const params = props;

  const active = <Icon name={params.activeIcon ?? params.inactiveIcon}/>
  const inactive = <Icon name={params.inactiveIcon}/>

  const inner = (isActive: boolean) => {
    return (
      <div className={cx("sidebar__menu-item", {"sidebar__menu-item--selected": isActive})}>
        <div className={cx("sidebar__menu-item-content")}>
          {isActive ? active : inactive}
          <Caption size={"3-medium"} className="sidebar__menu-item-title" text={params.title}/>
        </div>
      </div>
    )
  }

  if (params.onClick) {
    const onClick = () => {
      try {
        params.onClick?.()
      } catch (e) {
        alertStack.showError(e)
        throw e
      }
    }
    return (
      <div onClick={onClick}>
        {inner(false)}
      </div>
    )
  }

  if (params.url) {
    return (
      <NavLink to={params.url} {...props.newTab ? {target: "_blank", rel: "noreferrer"} : {}}>
        {(navProps) => inner(navProps.isActive)}
      </NavLink>
    )
  }

  return null
};

export default SidebarMenuItem;
