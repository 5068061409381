import React, {PropsWithChildren, useCallback, useMemo} from "react";
import {v4 as uuidv4} from 'uuid';
import {useDisplayErrorMessage} from "../helpers/errors";
import {AlertPopUpContext} from "../components/ui/Errors";
import {useStrings} from "../strings";
import {Variables} from "../variables";

type AlertStackAction = {
  type: "SHOW"
  payload: {
    id: string
    title: string
    errorMessage: string
  }
} | {
  type: "CLOSE";
  payload: {
    id: string;
  }
} | {
  type: "CLOSE_POPUP"
} | {
  type: "SHOW_POPUP"
  payload: {
    node: (ctx: AlertPopUpContext) => React.ReactNode
  }
}

type AlertStackState = {
  popUpAlert: {
    open: boolean
    content?: (ctx: AlertPopUpContext) => React.ReactNode
  }
  errorOrder: string[]
  visibleErrors: string[]
  errors: {
    [id: string]: {
      closed: boolean
      title: string
      message: string
    }
  }
}

const ErrorStackStateContext = React.createContext<AlertStackState>({
  popUpAlert: {
    open: false,
    content: undefined
  },
  visibleErrors: [],
  errorOrder: [],
  errors: {}
});
export const useAlertState = () => {
  const context = React.useContext(ErrorStackStateContext);
  if (context === undefined) {
    throw new Error("useErrorState must be used within a ErrorProvider");
  }
  return context;
}

const AlertStackDispatchContext = React.createContext<React.Dispatch<AlertStackAction> | null>(null);
const useAlertStackDispatch = () => {
  const context = React.useContext(AlertStackDispatchContext);
  if (context === null) {
    throw new Error("useErrorDispatch must be used within a ErrorProvider");
  }
  return context;
}

const fadeTimeoutMillis = 15 * 1000
export const useBasicAlertStack = (onShowError?: (err: any) => void) => {
  const strings = useStrings()
  const dispatch = useAlertStackDispatch()
  const state = useAlertState()
  const displayErrorMessage = useDisplayErrorMessage()

  const closeError = useCallback((id: string) => {
    dispatch({type: "CLOSE", payload: {id}})
  }, [dispatch])

  const closePopUp = useCallback(() => {
    dispatch({type: "CLOSE_POPUP"})
  }, [dispatch])

  const showPopUp = useCallback((node: (ctx: AlertPopUpContext) => React.ReactNode) => {
    dispatch({type: "SHOW_POPUP", payload: {node}})
  }, [dispatch])

  const showNotification = useCallback((title: string, message: string) => {
    const id = uuidv4()
    setTimeout(() => {
      closeError(id)
    }, fadeTimeoutMillis)
    dispatch({type: "SHOW", payload: {id, title: title, errorMessage: message}})
  }, [closeError, dispatch])

  const showErrorMessage = useCallback((errorMessage: string) => {
    showNotification(strings["generic.error"], errorMessage)
  }, [strings, showNotification])

  const showError = useCallback((err: any) => {
    console.error("showError:", err)
    const msg = displayErrorMessage(err)
    onShowError && onShowError(err)
    showErrorMessage(msg)
  }, [displayErrorMessage, onShowError, showErrorMessage])

  return useMemo(() => ({
    state,
    showNotification,
    showErrorMessage,
    showError,
    showPopUp,
    closeError,
    closePopUp
  }), [state, showNotification, showErrorMessage, showError, showPopUp, closeError, closePopUp])
}
export const useAlertStack = () => {
  return useBasicAlertStack(useCallback((_err: any) => {
  }, []))
}

const alertStackReducer = (state: AlertStackState, action: AlertStackAction): AlertStackState => {
  switch (action.type) {
    case "SHOW":
      let newErrors = {
        ...state.errors,
        [action.payload.id]: {
          title: action.payload.title,
          message: action.payload.errorMessage,
          closed: false
        }
      }
      let newVisibleErrors = [...state.visibleErrors, action.payload.id]

      if (newVisibleErrors.length > Variables.logic.maxAlertsOnScreen) {
        const id = newVisibleErrors.shift()
        if (id) {
          newErrors = {
            ...newErrors,
            [id]: {...newErrors[id], closed: true}
          }
        }
      }

      return {
        ...state,
        errorOrder: [...state.errorOrder, action.payload.id],
        visibleErrors: newVisibleErrors,
        errors: newErrors
      }
    case "CLOSE":
      return {
        ...state,
        visibleErrors: state.visibleErrors.filter(id => id !== action.payload.id),
        errors: {
          ...state.errors,
          [action.payload.id]: {...state.errors[action.payload.id], closed: true}
        }
      }
    case "CLOSE_POPUP":
      return {
        ...state,
        popUpAlert: {
          ...state.popUpAlert,
          open: false,
        },
      }
    case "SHOW_POPUP":
      return {
        ...state,
        popUpAlert: {
          open: true,
          content: action.payload.node
        }
      }
  }
}

export const AlertProvider = (props: PropsWithChildren) => {
  const [state, dispatch] = React.useReducer(alertStackReducer, {}, () => ({
    popUpAlert: {
      open: false,
      content: undefined
    },
    visibleErrors: [],
    errorOrder: [],
    errors: {}
  }));

  return (
    <ErrorStackStateContext.Provider value={state}>
      <AlertStackDispatchContext.Provider value={dispatch}>
        {props.children}
      </AlertStackDispatchContext.Provider>
    </ErrorStackStateContext.Provider>
  )
}
