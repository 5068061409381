import {InterfaceTemplates, InterfaceTemplateValues} from "../../domain/interface-template/domain";
import {PropsOnComplete} from "../../../helpers/props";
import InterfaceTemplateSingleSelectScreen
  from "../../domain/interface-template/screens/InterfaceTemplateSingleSelectScreen";
import {useTSOFVerificationState} from "../../../atoms/verification/tsof";

const TSOFSelectStep = (props: {
  titleAlign: "left" | "center",
  template: InterfaceTemplates.TransferSourceOfFunds
} & PropsOnComplete<InterfaceTemplateValues.SingleSelect>) => {
  const [state, setState] = useTSOFVerificationState()
  return <InterfaceTemplateSingleSelectScreen
    titleAlign={props.titleAlign}
    template={props.template.sourceOfFundsSelect}
    onComplete={props.onComplete}
    otherValue={{kind: "text", textValue: state.selectOther}}
    onChangeOtherValue={(value) => setState(prev =>
      ({...prev, selectOther: value.textValue})
    )}
  />
}

export default TSOFSelectStep
