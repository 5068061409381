import {CommonInputPropsGeneric, FormattedInput} from "./Inputs";
import {useIbanFormatter} from "../../formatters/FormattedIban";

type Props = CommonInputPropsGeneric<string>

const IbanInput = (props: Props) => {
  const ibanMask = useIbanFormatter(props.value ?? "")
  return <FormattedInput
    {...props}
    type={"text"}
    capitalization={"characters"}
    formatter={ibanMask}
  />
}

export default IbanInput