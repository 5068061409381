import {NavStackRoot} from "../../components/navigation/NavStack";
import LimitsStepMain from "./LimitsStepMain";
import Limiter from "../../components/ui/Limiter";
import NavBar from "../../components/navigation/NavBar";

const LimitsPage = () => {
  return (
    <NavStackRoot
      wrapper={(navStack, children) => {
        return (
          <Limiter>
            <NavBar/>
            {children}
          </Limiter>
        )
      }}
      root={LimitsStepMain} args={{}} ctx={{}}/>
  )
}

export default LimitsPage