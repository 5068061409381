import {RecipientType} from "../../../api/whales/Recipients";
import {useStrings} from "../../../strings";
import {useAuthorizedAuthValue} from "../../../atoms/auth";
import {img} from "../../../helpers/public";
import {PropsOnComplete} from "../../../helpers/props";
import {LimiterOverflow} from "../../../components/ui/Limiter";
import {TitleSubtitleLeftScreen} from "../../../components/ui/screen/TitleSubtitleScreen";
import {useAsyncLoading} from "../../../helpers/hooks";
import ComplexRow from "../../../components/ui/collections/rows/ComplexRow";
import {Section} from "../../../components/ui/collections/Section";
import {useAlertStack} from "../../../providers/alert-stack";
import Icon from "../../../components/ui/icons/Icon";

export const RecipientCreateTypeStep = (prop: PropsOnComplete<RecipientType>) => {
  const authorizedAuthValue = useAuthorizedAuthValue()
  const types: RecipientType[] = ["own", "person", "business"]
  const strings = useStrings()
  const {isLoading, asyncWithLoading} = useAsyncLoading(false)
  const alertStack = useAlertStack()

  const isBusiness = authorizedAuthValue.user.userType === "business"

  const businessPart = isBusiness ? ".business" : ""

  const renderIcon = (imgName: string) => {
    return (
      <div className={"w-[60px] h-[60px] mr-[20px] bg-primary-10 rounded-full flex items-center justify-center"}>
        <img width={60} height={60} src={img(`recipient/${imgName}.png`)} alt={"recipient type icon"}/>
      </div>
    )
  }

  const renderRecipientTypeRow = (type: RecipientType) => {
    const config: {
      [key in RecipientType]: {
        title: string,
        subtitle: string,
        img: string,
      }
    } = {
      "own": {
        title: strings[`add_recipient${businessPart}.self.title`],
        subtitle: strings[`add_recipient${businessPart}.self.subtitle`],
        img: isBusiness ? "business-own" : "own",
      },
      "person": {
        title: strings[`add_recipient${businessPart}.person.title`],
        subtitle: strings[`add_recipient${businessPart}.person.subtitle`],
        img: "person",
      },
      "business": {
        title: strings[`add_recipient${businessPart}.business.title`],
        subtitle: strings[`add_recipient${businessPart}.business.subtitle`],
        img: "business",
      }
    }

    const typedConfig = config[type]

    return (
      <ComplexRow
        key={type}
        topImage={renderIcon(typedConfig.img)}
        title={typedConfig.title}
        subtitle={typedConfig.subtitle}
        disabled={isLoading}
        trailingIcon={<Icon name={"chevron-right"}/>}
        onClickAsync={() => asyncWithLoading(() => prop.onComplete(type).catch(e => alertStack.showError(e)))}
      />)

  }

  return (
    <TitleSubtitleLeftScreen title={strings["transfer.recipient.add_a_recipient"]}>
      <Section>
        <LimiterOverflow overflow={"16px"}>
          {types.map((type) => renderRecipientTypeRow(type))}
        </LimiterOverflow>
      </Section>
    </TitleSubtitleLeftScreen>
  );
}