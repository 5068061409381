import {
  BankAccountElement,
  BankAccountElementView,
  useBankAccountElementFormatter
} from "../recipients/domain/BankAccountElement";
import Join from "../../ui/collections/Join";
import {useStrings} from "../../../strings";
import {useIntl} from "react-intl";
import React from "react";
import InfoRow from "../../ui/collections/rows/InfoRow";
import InlineButtonCopy from "../../ui/button/InlineButtonCopy";
import Collapsable from "../../ui/Collapsable";
import {BankAccount} from "../../../models/common/BankAccount";
import {Recipient} from "../recipients/domain";

export const BankAccountLine = ({bankAccount}: { bankAccount: BankAccount }) => {
  const elements = bankAccount.displayElements()

  return <Join
    separator={<span className={"mx-[10px]"}>•</span>}
    elements={elements.map((element) => <BankAccountElementView element={element}/>)}
    keys={elements.map((element) => element.identifier)}
  />
}

export const BankAccountElementRow = ({element, withCopy, isLoading}: {
  element: BankAccountElement,
  withCopy: boolean,
  isLoading: boolean
}) => {
  const bankAccountElementFormatter = useBankAccountElementFormatter()

  return (
    <InfoRow
      isLoading={isLoading}
      caption={element.template?.elementName || "Element title unknown"}
      content={<BankAccountElementView element={element}/>}
      actionButton={withCopy ? <InlineButtonCopy copyString={bankAccountElementFormatter(element)}/> : undefined}
    />
  )
}

export const BankAccountSectionRows = ({includeCurrency, ...props}: {
  bankAccount: BankAccount | undefined,
  includeCurrency: boolean,
  onlyImportant?: boolean
  withCopy?: boolean
}) => {
  const strings = useStrings()
  const intl = useIntl()

  const isLoading = props.bankAccount === undefined
  const bankAccount = props.bankAccount ?? Recipient.fallback.bankAccount
  const elements = bankAccount.displayElements()

  const rows = elements
    .flatMap((element) => {
      type ResultItem = {
        key: string,
        node: React.ReactNode,
        isImportant: boolean
      }
      const result: ResultItem[] = []

      result.push({
          key: element.identifier,
          node: (
            <BankAccountElementRow
              isLoading={isLoading}
              element={element}
              withCopy={props.withCopy ?? false}
            />
          ),
          isImportant: element.template?.isImportant ?? false
        }
      )

      if (element.identifier === "holderName") {
        if (bankAccount.bankName) {
          result.push({
              key: "bankName",
              node: (
                <InfoRow
                  isLoading={isLoading}
                  caption={strings["bank_account.bank_name"]}
                  content={bankAccount.bankName}
                  actionButton={props.withCopy ? <InlineButtonCopy copyString={bankAccount.bankName}/> : undefined}
                />
              ),
              isImportant: false
            }
          )
        }

        if (includeCurrency) {
          result.push({
              key: "currency",
              node: (
                <InfoRow
                  isLoading={isLoading}
                  caption={strings["bank_account.currency.title"]}
                  content={strings["bank_account.currency.value"](
                    bankAccount.currency.name(intl),
                    bankAccount.currency.code
                  )}
                  actionButton={props.withCopy ? <InlineButtonCopy copyString={bankAccount.currency.code}/> : undefined}
                />
              ),
              isImportant: false
            }
          )
        }
      }

      return result
    })


  return <>{rows.map(item => (
    <Collapsable key={item.key} expanded={!props.onlyImportant || item.isImportant}>
      {item.node}
    </Collapsable>
  ))}</>
}
