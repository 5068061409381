import cx from "classnames";
import React from "react";
import Caption from "../Caption";
import {BodyTitle} from "../Labels";
import "./Section.scss"
import {PropsClassName} from "../../../helpers/props";
import {WithSkeletonFlag} from "../Skeleton";

export type SectionProps = {
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  actionButton?: React.ReactNode;
  isLoading?: boolean;
} & React.PropsWithChildren & PropsClassName

export const Section = (props: SectionProps) => {
  const isLoading = props.isLoading ?? false
  return (
    <div className={cx("section", "text-left", props.className)}>
      <div className={"mb-2"}>
        {props.title && (
          <div className={"flex items-center"}>
            <div>
              <WithSkeletonFlag minWidth={"350px"} isLoading={isLoading}>
                <BodyTitle text={props.title}/>
              </WithSkeletonFlag>
            </div>
            {props.actionButton && <div className={"pl-2 ml-auto"}>
                <Caption size={"3-title"} children={props.actionButton}/>
            </div>}
          </div>
        )}
        {props.subtitle && <div className={"mt-[4px]"}>
            <WithSkeletonFlag isLoading={isLoading}>
                <Caption size={"3"} text={props.subtitle}/>
            </WithSkeletonFlag>
        </div>}
      </div>
      {props.children}
    </div>
  );
};