import React from "react";
import {useT} from "@transifex/react";
export const iosStrings = (t: typeof useT) => ({
  "account.account_info.address.title": t("Home address", {_key: "account.account_info.address.title", _tags: "ios", }),
  "account.account_info.dob.title": t("Date of birth", {_key: "account.account_info.dob.title", _tags: "ios", }),
  "account.account_info.email.title": t("Email", {_key: "account.account_info.email.title", _tags: "ios", }),
  "account.account_info.name.title": t("Name", {_key: "account.account_info.name.title", _tags: "ios", }),
  "account.account_info.phone_number.title": t("Phone number", {_key: "account.account_info.phone_number.title", _tags: "ios", }),
  "account.account_info.title": t("Personal info", {_key: "account.account_info.title", _tags: "ios", }),
  "account.business_info": t("Business info", {_key: "account.business_info", _tags: "ios", }),
  "account.email_notifications": t("Marketing emails", {_key: "account.email_notifications", _tags: "ios", }),
  "account.enable_push_notifications": t("Enable push notifications", {_key: "account.enable_push_notifications", _tags: "ios", }),
  "account.help": t("Help", {_key: "account.help", _tags: "ios", }),
  "account.limits": t("Limits", {_key: "account.limits", _tags: "ios", }),
  "account.more.title": t("More", {_key: "account.more.title", _tags: "ios", }),
  "account.pending_transfers.title": t("Pending transfers", {_key: "account.pending_transfers.title", _tags: "ios", }),
  "account.personal_info": t("Personal info", {_key: "account.personal_info", _tags: "ios", }),
  "account.privacy_policy": t("Privacy policy", {_key: "account.privacy_policy", _tags: "ios", }),
  "account.push_notifications": t("Push notifications", {_key: "account.push_notifications", _tags: "ios", }),
  "account.refer_friends": t("Get free transfers", {_key: "account.refer_friends", _tags: "ios", }),
  "account.title": (string1: React.ReactNode) => t("Hello, {string1}.", {_key: "account.title", _tags: "ios", string1}),
  "account.transfer_history": t("Transfer history", {_key: "account.transfer_history", _tags: "ios", }),
  "account.transfers.title": t("Your transfers", {_key: "account.transfers.title", _tags: "ios", }),
  "account.user_agreement": t("User agreement", {_key: "account.user_agreement", _tags: "ios", }),
  "account.your_account.title": t("Your account", {_key: "account.your_account.title", _tags: "ios", }),
  "add_recipient.add_recipient.title": t("Add recipient", {_key: "add_recipient.add_recipient.title", _tags: "ios", }),
  "add_recipient.business.business.subtitle": t("An account held by another business.", {_key: "add_recipient.business.business.subtitle", _tags: "ios", }),
  "add_recipient.business.business.title": t("Another business", {_key: "add_recipient.business.business.title", _tags: "ios", }),
  "add_recipient.business.person.subtitle": t("An account held by an individual.", {_key: "add_recipient.business.person.subtitle", _tags: "ios", }),
  "add_recipient.business.person.title": t("An individual", {_key: "add_recipient.business.person.title", _tags: "ios", }),
  "add_recipient.business.self.subtitle": t("An account held in the name of my company.", {_key: "add_recipient.business.self.subtitle", _tags: "ios", }),
  "add_recipient.business.self.title": t("My company account", {_key: "add_recipient.business.self.title", _tags: "ios", }),
  "add_recipient.business.subtitle": t("An account held by a business.", {_key: "add_recipient.business.subtitle", _tags: "ios", }),
  "add_recipient.business.title": t("A business", {_key: "add_recipient.business.title", _tags: "ios", }),
  "add_recipient.currency.subtitle": t("This is the currency of your recipient bank account.", {_key: "add_recipient.currency.subtitle", _tags: "ios", }),
  "add_recipient.currency.title": t("Select a currency", {_key: "add_recipient.currency.title", _tags: "ios", }),
  "add_recipient.holder_name.business.title": t("Business name", {_key: "add_recipient.holder_name.business.title", _tags: "ios", }),
  "add_recipient.holder_name.my_account.title": t("Account holder name", {_key: "add_recipient.holder_name.my_account.title", _tags: "ios", }),
  "add_recipient.holder_name.person.title": t("Recipient name", {_key: "add_recipient.holder_name.person.title", _tags: "ios", }),
  "add_recipient.holder_name.subtitle": t("This should be the name on the bank account that will receive your funds.", {_key: "add_recipient.holder_name.subtitle", _tags: "ios", }),
  "add_recipient.person.subtitle": t("An account held by someone else that I know.", {_key: "add_recipient.person.subtitle", _tags: "ios", }),
  "add_recipient.person.title": t("Someone else", {_key: "add_recipient.person.title", _tags: "ios", }),
  "add_recipient.requirements.share": (string1: React.ReactNode) => t("Hey there, I’ll need your {string1} bank info:", {_key: "add_recipient.requirements.share", _tags: "ios", string1}),
  "add_recipient.requirements.title": (string1: React.ReactNode) => t("Heads up! Please have your recipient’s {string1} bank info ready:", {_key: "add_recipient.requirements.title", _tags: "ios", string1}),
  "add_recipient.review.button_title": t("Confirm recipient", {_key: "add_recipient.review.button_title", _tags: "ios", }),
  "add_recipient.review.subtitle": t("Let’s make sure we’ve got all the details right.", {_key: "add_recipient.review.subtitle", _tags: "ios", }),
  "add_recipient.review.title": t("Review your recipient", {_key: "add_recipient.review.title", _tags: "ios", }),
  "add_recipient.self.subtitle": t("An account that I own myself or jointly.", {_key: "add_recipient.self.subtitle", _tags: "ios", }),
  "add_recipient.self.title": t("Myself", {_key: "add_recipient.self.title", _tags: "ios", }),
  "address.au.suburb.placeholder": t("Suburb", {_key: "address.au.suburb.placeholder", _tags: "ios", }),
  "address.gb.city.placeholder": t("City", {_key: "address.gb.city.placeholder", _tags: "ios", }),
  "address.gb.house_number.dont_see": t("I don’t see my address", {_key: "address.gb.house_number.dont_see", _tags: "ios", }),
  "address.gb.house_number.placeholder": t("House number", {_key: "address.gb.house_number.placeholder", _tags: "ios", }),
  "address.gb.no_suggestions_alert.body": (string1: React.ReactNode) => t("We didn’t find any suggestions for your postcode {string1}. You may enter your address manually, or try another postcode.", {_key: "address.gb.no_suggestions_alert.body", _tags: "ios", string1}),
  "address.gb.no_suggestions_alert.title": t("No suggestions", {_key: "address.gb.no_suggestions_alert.title", _tags: "ios", }),
  "address.gb.no_suggestions.enter_address_manually": t("Enter address manually", {_key: "address.gb.no_suggestions.enter_address_manually", _tags: "ios", }),
  "address.gb.no_suggestions.try_again": t("Try again", {_key: "address.gb.no_suggestions.try_again", _tags: "ios", }),
  "address.gb.postcode.placeholder": t("Postcode", {_key: "address.gb.postcode.placeholder", _tags: "ios", }),
  "address.generic.city.placeholder": t("City", {_key: "address.generic.city.placeholder", _tags: "ios", }),
  "address.generic.postcode.placeholder": t("Postcode", {_key: "address.generic.postcode.placeholder", _tags: "ios", }),
  "address.generic.province.placeholder": t("Province", {_key: "address.generic.province.placeholder", _tags: "ios", }),
  "address.generic.region.placeholder": t("State or province", {_key: "address.generic.region.placeholder", _tags: "ios", }),
  "address.generic.state.placeholder": t("State", {_key: "address.generic.state.placeholder", _tags: "ios", }),
  "address.line_one.placeholder": t("Address line one", {_key: "address.line_one.placeholder", _tags: "ios", }),
  "address.line_two.placeholder": t("Address line two", {_key: "address.line_two.placeholder", _tags: "ios", }),
  "address.us.postal_code.placeholder": t("ZIP code", {_key: "address.us.postal_code.placeholder", _tags: "ios", }),
  "application_rejected.subtitle": t("Unfortunately we were not able to approve your account. Thank you for your interest in using Atlantic.", {_key: "application_rejected.subtitle", _tags: "ios", }),
  "application_rejected.title": t("Your account was not approved.", {_key: "application_rejected.title", _tags: "ios", }),
  "application.approved.subtitle": t("We’re so excited to have you. We hope you enjoy using our service.", {_key: "application.approved.subtitle", _tags: "ios", }),
  "application.approved.title": t("Welcome to Atlantic Money", {_key: "application.approved.title", _tags: "ios", }),
  "application.in_review.subtitle": t("We’re reviewing your details as fast as we can. We’ll get back to you as soon as possible. Thanks for sticking with us.", {_key: "application.in_review.subtitle", _tags: "ios", }),
  "application.in_review.title": t("Your application is in review", {_key: "application.in_review.title", _tags: "ios", }),
  "bank_account.bank_name": t("Bank", {_key: "bank_account.bank_name", _tags: "ios", }),
  "bank_account.currency.title": t("Currency", {_key: "bank_account.currency.title", _tags: "ios", }),
  "bank_account.currency.value": (string1: React.ReactNode, string2: React.ReactNode) => t("{string1} ({string2})", {_key: "bank_account.currency.value", _tags: "ios", string1, string2}),
  "bulk_transfer.title": t("Bulk transfer", {_key: "bulk_transfer.title", _tags: "ios", }),
  "bulk_transfer.transfers": t("%i transfers", {_key: "bulk_transfer.transfers", _tags: "ios", }),
  "business_contact.applicant": t("Applicant", {_key: "business_contact.applicant", _tags: "ios", }),
  "business_contact.delete": t("Remove person", {_key: "business_contact.delete", _tags: "ios", }),
  "business_contact.delete.alert.body": t("Are you sure you would like to remove this person?", {_key: "business_contact.delete.alert.body", _tags: "ios", }),
  "business_contact.delete.alert.title": t("Confirm removal", {_key: "business_contact.delete.alert.title", _tags: "ios", }),
  "business_contact.deleted": t("Deleted", {_key: "business_contact.deleted", _tags: "ios", }),
  "business_contact.director": t("Director", {_key: "business_contact.director", _tags: "ios", }),
  "business_contact.ubo": t("UBO", {_key: "business_contact.ubo", _tags: "ios", }),
  "business_signup.applicant_info_intro.title.2": t("Next, we need some info about you.", {_key: "business_signup.applicant_info_intro.title.2", _tags: "ios", }),
  "business_signup.country_of_registration.subtitle": t("In which country is your business registered?", {_key: "business_signup.country_of_registration.subtitle", _tags: "ios", }),
  "business_signup.country_of_registration.title": t("Business country of registration", {_key: "business_signup.country_of_registration.title", _tags: "ios", }),
  "business_signup.email.placeholder": t("Work email", {_key: "business_signup.email.placeholder", _tags: "ios", }),
  "business_signup.identifier.subtitle.2": t("Please provide your business number, maybe also known as your business identifier.", {_key: "business_signup.identifier.subtitle.2", _tags: "ios", }),
  "business_signup.identifier.title.2": t("Business number", {_key: "business_signup.identifier.title.2", _tags: "ios", }),
  "business_signup.key_people_intro.title.2": t("Finally, we need info about key members of the business.", {_key: "business_signup.key_people_intro.title.2", _tags: "ios", }),
  "business_signup.legal_name.subtitle": t("Please provide the full legal name of your business.", {_key: "business_signup.legal_name.subtitle", _tags: "ios", }),
  "business_signup.legal_name.title": t("Legal business name", {_key: "business_signup.legal_name.title", _tags: "ios", }),
  "business_signup.registered_address.confirm.title": t("Confirm registered address", {_key: "business_signup.registered_address.confirm.title", _tags: "ios", }),
  "business_signup.registered_address.subtitle": t("Your registered address is the one you used when registering your company.", {_key: "business_signup.registered_address.subtitle", _tags: "ios", }),
  "business_signup.registered_address.title": t("Business registered address", {_key: "business_signup.registered_address.title", _tags: "ios", }),
  "business_signup.trading_address_same.confirm.title": t("Is this also your trading address?", {_key: "business_signup.trading_address_same.confirm.title", _tags: "ios", }),
  "business_signup.trading_address_same.no": t("No", {_key: "business_signup.trading_address_same.no", _tags: "ios", }),
  "business_signup.trading_address_same.yes": t("Yes", {_key: "business_signup.trading_address_same.yes", _tags: "ios", }),
  "business_signup.trading_address.confirm.title": t("Confirm trading address", {_key: "business_signup.trading_address.confirm.title", _tags: "ios", }),
  "business_signup.trading_address.subtitle": t("Your trading address is where you primarily conduct business.", {_key: "business_signup.trading_address.subtitle", _tags: "ios", }),
  "business_signup.trading_address.title": t("Trading address", {_key: "business_signup.trading_address.title", _tags: "ios", }),
  "business.application_status.in_review.subtitle.2": t("Thanks for sharing all your info. We’re currently reviewing and will reach out if we need anything else within 2 business days.", {_key: "business.application_status.in_review.subtitle.2", _tags: "ios", }),
  "business.application_status.in_review.title": t("We’re reviewing your business", {_key: "business.application_status.in_review.title", _tags: "ios", }),
  "business.application_status.input_required.subtitle": t("We’ll be able to approve your application once you’ve completed the steps below.", {_key: "business.application_status.input_required.subtitle", _tags: "ios", }),
  "business.application_status.input_required.title": t("We need more info", {_key: "business.application_status.input_required.title", _tags: "ios", }),
  "business.check.approved": t("Verified", {_key: "business.check.approved", _tags: "ios", }),
  "business.check.not_approved": t("Rejected", {_key: "business.check.not_approved", _tags: "ios", }),
  "business.contact.name.title": t("Legal name", {_key: "business.contact.name.title", _tags: "ios", }),
  "business.contact.roles": t("Roles", {_key: "business.contact.roles", _tags: "ios", }),
  "business.contact.roles.none": t("None", {_key: "business.contact.roles.none", _tags: "ios", }),
  "business.contacts.title": t("Company directory", {_key: "business.contacts.title", _tags: "ios", }),
  "business.email_confirmed.confirmed": t("Verified", {_key: "business.email_confirmed.confirmed", _tags: "ios", }),
  "business.email_confirmed.input_pending": t("Resend email", {_key: "business.email_confirmed.input_pending", _tags: "ios", }),
  "business.email_confirmed.pending.subtitle": t("Please tap a link in your inbox to verify your email address.", {_key: "business.email_confirmed.pending.subtitle", _tags: "ios", }),
  "business.email_confirmed.title": t("Email verification", {_key: "business.email_confirmed.title", _tags: "ios", }),
  "business.id.complete_now": t("Verify", {_key: "business.id.complete_now", _tags: "ios", }),
  "business.id.share_link": t("Share link", {_key: "business.id.share_link", _tags: "ios", }),
  "business.identifier.title": t("Identifier", {_key: "business.identifier.title", _tags: "ios", }),
  "business.info.approved": t("We successfully verified the business info you provided.", {_key: "business.info.approved", _tags: "ios", }),
  "business.info.in_review": t("We’re evaluating the business info you provided.", {_key: "business.info.in_review", _tags: "ios", }),
  "business.info.row.update": t("Submit docs", {_key: "business.info.row.update", _tags: "ios", }),
  "business.info.row.verification_pending": t("Verification in progress", {_key: "business.info.row.verification_pending", _tags: "ios", }),
  "business.info.row.verified": t("Info verified", {_key: "business.info.row.verified", _tags: "ios", }),
  "business.info.title": t("Business info", {_key: "business.info.title", _tags: "ios", }),
  "business.info.verification_pending": t("We weren’t able to verify the business info you provided. Please submit docs confirming the info you provided is correct.", {_key: "business.info.verification_pending", _tags: "ios", }),
  "business.key_people_tasks": t("Pending tasks", {_key: "business.key_people_tasks", _tags: "ios", }),
  "business.key_people.add_director": t("Add director", {_key: "business.key_people.add_director", _tags: "ios", }),
  "business.key_people.add_person": t("Add person", {_key: "business.key_people.add_person", _tags: "ios", }),
  "business.key_people.add_ubo": t("Add UBO", {_key: "business.key_people.add_ubo", _tags: "ios", }),
  "business.key_people.approved": t("We were able to successfully verify the key people associated with your business.", {_key: "business.key_people.approved", _tags: "ios", }),
  "business.key_people.complete_other_verification": (string1: React.ReactNode) => t("Complete ID verification for {string1}", {_key: "business.key_people.complete_other_verification", _tags: "ios", string1}),
  "business.key_people.complete_your_verification": t("Complete your ID verification", {_key: "business.key_people.complete_your_verification", _tags: "ios", }),
  "business.key_people.directors.subtitle": t("A director is a member of your company’s Board of Directors.", {_key: "business.key_people.directors.subtitle", _tags: "ios", }),
  "business.key_people.directors.title": t("Directors", {_key: "business.key_people.directors.title", _tags: "ios", }),
  "business.key_people.docs_in_review": t("We’re reviewing your key people docs", {_key: "business.key_people.docs_in_review", _tags: "ios", }),
  "business.key_people.list_verified_identity_pending": <div className="whitespace-pre-line">{t("We’ve confirmed the list of key people you provided.\n\nNext, please verify your identity and key people associated with your business below.", {_key: "business.key_people.list_verified_identity_pending", _tags: "ios", })}</div>,
  "business.key_people.no_ubos": t("I have no UBOs", {_key: "business.key_people.no_ubos", _tags: "ios", }),
  "business.key_people.title": t("Key people", {_key: "business.key_people.title", _tags: "ios", }),
  "business.key_people.ubos.subtitle": t("An ultimate beneficial owner (“UBO”) owns 25% or more of the business.", {_key: "business.key_people.ubos.subtitle", _tags: "ios", }),
  "business.key_people.ubos.title": t("Ultimate beneficial owners", {_key: "business.key_people.ubos.title", _tags: "ios", }),
  "business.key_people.verification_pending": t("We weren’t able to verify the key people you provided. Please submit docs confirming the info you provided is correct.", {_key: "business.key_people.verification_pending", _tags: "ios", }),
  "business.key_people.verify_identity": t("We’re required to verify your identity and key people associated with your business.", {_key: "business.key_people.verify_identity", _tags: "ios", }),
  "business.name.title": t("Legal name", {_key: "business.name.title", _tags: "ios", }),
  "business.proof_of_authority.accepted": (string1: React.ReactNode) => t("We’ve confirmed you have the authority to open this account for {string1}.", {_key: "business.proof_of_authority.accepted", _tags: "ios", string1}),
  "business.proof_of_authority.input_required": (string1: React.ReactNode) => t("Since you’re not a director or UBO, we need a document showing that a director of {string1} has authorized you to open this account.", {_key: "business.proof_of_authority.input_required", _tags: "ios", string1}),
  "business.proof_of_authority.title": t("Proof of authority", {_key: "business.proof_of_authority.title", _tags: "ios", }),
  "business.proof_of_authority.upload_authorization.confirmed": t("Authorization confirmed", {_key: "business.proof_of_authority.upload_authorization.confirmed", _tags: "ios", }),
  "business.proof_of_authority.upload_authorization.in_review": t("Authorization in review", {_key: "business.proof_of_authority.upload_authorization.in_review", _tags: "ios", }),
  "business.proof_of_authority.upload_authorization.input_pending": t("Upload doc", {_key: "business.proof_of_authority.upload_authorization.input_pending", _tags: "ios", }),
  "business.registered_address.title": t("Registered address", {_key: "business.registered_address.title", _tags: "ios", }),
  "business.trading_address.title": t("Trading address", {_key: "business.trading_address.title", _tags: "ios", }),
  "business.verification_share_link_copy": (string1: React.ReactNode) => <div className="whitespace-pre-line">{t("Hi there! Please verify your identity with this link as part of the Atlantic Money account opening process. Thank you!\n\n{string1}", {_key: "business.verification_share_link_copy", _tags: "ios", string1})}</div>,
  "currency_exchange_rate": (string1: React.ReactNode) => t("{string1} exchange rate", {_key: "currency_exchange_rate", _tags: "ios", string1}),
  "date_format.due_date.later": (string1: React.ReactNode, string2: React.ReactNode) => t("{string1} on {string2}", {_key: "date_format.due_date.later", _tags: "ios", string1, string2}),
  "date_format.due_date.today": (string1: React.ReactNode) => t("{string1} today", {_key: "date_format.due_date.today", _tags: "ios", string1}),
  "date_format.due_date.tomorrow": (string1: React.ReactNode) => t("{string1} tomorrow", {_key: "date_format.due_date.tomorrow", _tags: "ios", string1}),
  "delivery_option.expedited": t("Express", {_key: "delivery_option.expedited", _tags: "ios", }),
  "delivery_option.standard": t("Standard", {_key: "delivery_option.standard", _tags: "ios", }),
  "delivery_option.unavailable.body.expedited": t("We apologize, Express delivery is temporarily unavailable due to high volume.", {_key: "delivery_option.unavailable.body.expedited", _tags: "ios", }),
  "delivery_option.unavailable.body.standard": t("We apologize, Standard delivery is temporarily unavailable due to high volume.", {_key: "delivery_option.unavailable.body.standard", _tags: "ios", }),
  "delivery_option.unavailable.title": t("Temporarily unavailable", {_key: "delivery_option.unavailable.title", _tags: "ios", }),
  "delivery.instant": t("Instant", {_key: "delivery.instant", _tags: "ios", }),
  "delivery.same_day": t("Same day", {_key: "delivery.same_day", _tags: "ios", }),
  "disclosure.button.accept": t("Accept", {_key: "disclosure.button.accept", _tags: "ios", }),
  "disclosure.button.review": t("Review", {_key: "disclosure.button.review", _tags: "ios", }),
  "documents.submitted.subtitle": t("We’ll review your documents within the next few days. You’ll let you know once we’ve completed our review.", {_key: "documents.submitted.subtitle", _tags: "ios", }),
  "documents.submitted.title": t("Thanks for submitting your documents.", {_key: "documents.submitted.title", _tags: "ios", }),
  "enable_notifications_in_settings.body": t("Please visit the Settings app to enable notifications for Atlantic.", {_key: "enable_notifications_in_settings.body", _tags: "ios", }),
  "enable_notifications_in_settings.open_settings": t("Open settings", {_key: "enable_notifications_in_settings.open_settings", _tags: "ios", }),
  "enable_notifications_in_settings.title": t("Enable in Settings", {_key: "enable_notifications_in_settings.title", _tags: "ios", }),
  "general.add": t("Add", {_key: "general.add", _tags: "ios", }),
  "general.back": t("Back", {_key: "general.back", _tags: "ios", }),
  "general.cancel": t("Cancel", {_key: "general.cancel", _tags: "ios", }),
  "general.continue": t("Continue", {_key: "general.continue", _tags: "ios", }),
  "general.copied": t("Copied!", {_key: "general.copied", _tags: "ios", }),
  "general.copy": t("Copy", {_key: "general.copy", _tags: "ios", }),
  "general.dismiss": t("Dismiss", {_key: "general.dismiss", _tags: "ios", }),
  "general.done": t("Done", {_key: "general.done", _tags: "ios", }),
  "general.edit": t("Edit", {_key: "general.edit", _tags: "ios", }),
  "general.free": t("Free", {_key: "general.free", _tags: "ios", }),
  "general.learn_more": t("Learn more", {_key: "general.learn_more", _tags: "ios", }),
  "general.no": t("No", {_key: "general.no", _tags: "ios", }),
  "general.other": t("Other", {_key: "general.other", _tags: "ios", }),
  "general.paste_from_clipboard": t("Paste from clipboard", {_key: "general.paste_from_clipboard", _tags: "ios", }),
  "general.save": t("Save", {_key: "general.save", _tags: "ios", }),
  "general.search": t("Search", {_key: "general.search", _tags: "ios", }),
  "general.see_all": t("See all", {_key: "general.see_all", _tags: "ios", }),
  "general.share": t("Share", {_key: "general.share", _tags: "ios", }),
  "general.skip": t("Skip", {_key: "general.skip", _tags: "ios", }),
  "general.submit": t("Submit", {_key: "general.submit", _tags: "ios", }),
  "general.yes": t("Yes", {_key: "general.yes", _tags: "ios", }),
  "generic.error": t("Error", {_key: "generic.error", _tags: "ios", }),
  "generic.error.description": t("Something went wrong, please try again.", {_key: "generic.error.description", _tags: "ios", }),
  "generic.log_out": t("Log out", {_key: "generic.log_out", _tags: "ios", }),
  "generic.try_again": t("Try again", {_key: "generic.try_again", _tags: "ios", }),
  "government_id.intro.subtitle": t("As part of our normal verification process, we need you to upload a photo of your ID and a few selfies.", {_key: "government_id.intro.subtitle", _tags: "ios", }),
  "government_id.intro.title": t("Please verify your identity", {_key: "government_id.intro.title", _tags: "ios", }),
  "government_id.scan.confirm_photo": t("Confirm photo", {_key: "government_id.scan.confirm_photo", _tags: "ios", }),
  "government_id.scan.confirm.title": t("Please confirm the text in this photo is readable", {_key: "government_id.scan.confirm.title", _tags: "ios", }),
  "government_id.scan.open_scanner": t("Open scanner", {_key: "government_id.scan.open_scanner", _tags: "ios", }),
  "government_id.scan.retake_photo": t("Retake photo", {_key: "government_id.scan.retake_photo", _tags: "ios", }),
  "government_id.scan.subtitle": t("We recommend placing your document flat against a table or other surface.", {_key: "government_id.scan.subtitle", _tags: "ios", }),
  "government_id.select_country.title": t("Select the country of your ID", {_key: "government_id.select_country.title", _tags: "ios", }),
  "government_id.select_type.subtitle": t("We can accept the following types of documents.", {_key: "government_id.select_type.subtitle", _tags: "ios", }),
  "government_id.select_type.title": t("Select your identity document", {_key: "government_id.select_type.title", _tags: "ios", }),
  "government_id.verification_failed.subtitle": t("We weren’t able to verify your info. Please make sure your ID is clearly readable and without glare.", {_key: "government_id.verification_failed.subtitle", _tags: "ios", }),
  "government_id.verification_failed.title": t("We need you to try again.", {_key: "government_id.verification_failed.title", _tags: "ios", }),
  "government_id.verification_pending.subtitle": t("We’re still reviewing your info. We’ll let you know when we’ve completed our review. Thanks for sticking with us.", {_key: "government_id.verification_pending.subtitle", _tags: "ios", }),
  "government_id.verification_pending.title": t("We need a little more time.", {_key: "government_id.verification_pending.title", _tags: "ios", }),
  "government_id.verification_success.subtitle": t("Thanks for sharing your documents, we’ve successfully verified your identity.", {_key: "government_id.verification_success.subtitle", _tags: "ios", }),
  "government_id.verification_success.title": t("We’ve verified your info.", {_key: "government_id.verification_success.title", _tags: "ios", }),
  "government_id.verifying.subtitle": t("This could take a few minutes, stick with us.", {_key: "government_id.verifying.subtitle", _tags: "ios", }),
  "government_id.verifying.title": t("Thanks for that info. We’re verifying it now.", {_key: "government_id.verifying.title", _tags: "ios", }),
  "help_center.categories.title": t("Help center", {_key: "help_center.categories.title", _tags: "ios", }),
  "help_center.chat_with_us": t("Chat with us", {_key: "help_center.chat_with_us", _tags: "ios", }),
  "help_center.chat.imessage": t("iMessage", {_key: "help_center.chat.imessage", _tags: "ios", }),
  "help_center.chat.whatsapp": t("WhatsApp", {_key: "help_center.chat.whatsapp", _tags: "ios", }),
  "help_center.search.title": <div className="whitespace-pre-line">{t("Hello!\nHow can we help?", {_key: "help_center.search.title", _tags: "ios", })}</div>,
  "inr.exchange_rate_alert.subtitle.3": t("This is the live institutional rate that we receive passed directly to you. We don’t mark up this rate.", {_key: "inr.exchange_rate_alert.subtitle.3", _tags: "ios", }),
  "launch.1.subtitle": t("With a flat £3 fee, always the live exchange rate, and an app designed just for you, moving money across borders has never been so easy.", {_key: "launch.1.subtitle", _tags: "ios", }),
  "launch.1.subtitle.eu": t("With a flat €3 fee, always the live exchange rate, and an app designed just for you, moving money across borders has never been so easy.", {_key: "launch.1.subtitle.eu", _tags: "ios", }),
  "launch.1.title": t("Sending money abroad, finally done right.", {_key: "launch.1.title", _tags: "ios", }),
  "launch.2.subtitle": t("We’ve built direct connections with the world’s interbank exchanges, so you’ll always get the best exchange rate.", {_key: "launch.2.subtitle", _tags: "ios", }),
  "launch.2.title": t("We always convert your money at a live rate, just like Google.", {_key: "launch.2.title", _tags: "ios", }),
  "launch.3.subtitle": t("You could save 90% or more compared to any other money transfer company.", {_key: "launch.3.subtitle", _tags: "ios", }),
  "launch.3.title": t("All you pay is a £3 flat fee – no matter how much you send.", {_key: "launch.3.title", _tags: "ios", }),
  "launch.3.title.eu": t("All you pay is a €3 flat fee – no matter how much you send.", {_key: "launch.3.title.eu", _tags: "ios", }),
  "launch.4.subtitle": t("We’ll deliver your funds directly to your recipient’s bank account.", {_key: "launch.4.subtitle", _tags: "ios", }),
  "launch.4.title": t("Transfer between ten currencies today, like US Dollar and Euro.", {_key: "launch.4.title", _tags: "ios", }),
  "launch.4.title.eu": t("Transfer between ten currencies today, like US Dollar and British Pound.", {_key: "launch.4.title.eu", _tags: "ios", }),
  "launch.5.subtitle": t("Standard delivery takes just a few days. And we’ve got instant delivery when you need it.", {_key: "launch.5.subtitle", _tags: "ios", }),
  "launch.5.title": t("Your money moves at the speed of life.", {_key: "launch.5.title", _tags: "ios", }),
  "launch.6.subtitle": t("And we take our responsibility to protect your funds seriously by operating safeguarding accounts with licensed institutions.", {_key: "launch.6.subtitle", _tags: "ios", }),
  "launch.6.title": t("We’re authorised as a payment institution by the FCA.", {_key: "launch.6.title", _tags: "ios", }),
  "launch.6.title.eu": t("We’re authorised as a payment institution by the National Bank of Belgium.", {_key: "launch.6.title.eu", _tags: "ios", }),
  "launch.error.body": t("Unfortunately something went wrong as we were loading the app. Please try again.", {_key: "launch.error.body", _tags: "ios", }),
  "launch.log_in": t("Log in", {_key: "launch.log_in", _tags: "ios", }),
  "launch.sign_up": t("Sign up", {_key: "launch.sign_up", _tags: "ios", }),
  "limits.custom_limit.re_request": t("Request new limit", {_key: "limits.custom_limit.re_request", _tags: "ios", }),
  "log_in.email": t("Email", {_key: "log_in.email", _tags: "ios", }),
  "log_in.forgot_password": t("Forgot password", {_key: "log_in.forgot_password", _tags: "ios", }),
  "log_in.password": t("Password", {_key: "log_in.password", _tags: "ios", }),
  "log_in.welcome_back": t("Welcome back.", {_key: "log_in.welcome_back", _tags: "ios", }),
  "login.confirm_otp.subtitle.email": (string1: React.ReactNode) => t("For your security, we sent an email to {string1}. Please enter your code here.", {_key: "login.confirm_otp.subtitle.email", _tags: "ios", string1}),
  "login.confirm_otp.subtitle.phone": (string1: React.ReactNode) => t("For your security, we sent a text message to your phone number ending in {string1}. Please enter your code here.", {_key: "login.confirm_otp.subtitle.phone", _tags: "ios", string1}),
  "login.confirm_otp.title": t("Verify your login", {_key: "login.confirm_otp.title", _tags: "ios", }),
  "marketing_emails.opt_in": t("Opt in", {_key: "marketing_emails.opt_in", _tags: "ios", }),
  "marketing_emails.opt_in_success.subtitle": t("You’ve successfully opted in. We’ll keep you up to date with the latest offers and updates about Atlantic. You can opt out any time.", {_key: "marketing_emails.opt_in_success.subtitle", _tags: "ios", }),
  "marketing_emails.opt_in_success.title": t("Opt in successful", {_key: "marketing_emails.opt_in_success.title", _tags: "ios", }),
  "marketing_emails.opt_out": t("Opt out", {_key: "marketing_emails.opt_out", _tags: "ios", }),
  "marketing_emails.opt_out_success.subtitle": t("You’ve successfully opted out. We won’t notify you about offers or updates about Atlantic. You’ll still receive notifications related to your account.", {_key: "marketing_emails.opt_out_success.subtitle", _tags: "ios", }),
  "marketing_emails.opt_out_success.title": t("Opt out successful", {_key: "marketing_emails.opt_out_success.title", _tags: "ios", }),
  "marketing_emails.subtitle": t("If you opt in, we’ll keep you up to date with the latest offers and updates about Atlantic. You can opt out any time.", {_key: "marketing_emails.subtitle", _tags: "ios", }),
  "marketing_emails.title": t("Marketing emails", {_key: "marketing_emails.title", _tags: "ios", }),
  "notification_permission_screen.allow": t("Enable notifications", {_key: "notification_permission_screen.allow", _tags: "ios", }),
  "notification_permission_screen.deny": t("Not now", {_key: "notification_permission_screen.deny", _tags: "ios", }),
  "notification_permission_screen.subtitle": t("We’ll keep you up to date about the status of your account. We won’t send you spam.", {_key: "notification_permission_screen.subtitle", _tags: "ios", }),
  "notification_permission_screen.title": t("Enable notifications", {_key: "notification_permission_screen.title", _tags: "ios", }),
  "notifications_enabled.body": t("We’ll keep you up to date about important things relating to your account. We won’t send you spam.", {_key: "notifications_enabled.body", _tags: "ios", }),
  "notifications_enabled.title": t("Notifications enabled", {_key: "notifications_enabled.title", _tags: "ios", }),
  "open_banking.manual_transfer.action": t("I’ve sent this payment", {_key: "open_banking.manual_transfer.action", _tags: "ios", }),
  "open_banking.manual_transfer.subtitle_addition": t("Please send a bank transfer to the details below.", {_key: "open_banking.manual_transfer.subtitle_addition", _tags: "ios", }),
  "open_banking.manual_transfer.title": t("Please pay for your transfer", {_key: "open_banking.manual_transfer.title", _tags: "ios", }),
  "pay_transfer.bank_transfer.cancel.alert.body": t("Are you sure you would like to cancel this transfer?", {_key: "pay_transfer.bank_transfer.cancel.alert.body", _tags: "ios", }),
  "pay_transfer.bank_transfer.cancel.alert.title": t("Confirm cancellation", {_key: "pay_transfer.bank_transfer.cancel.alert.title", _tags: "ios", }),
  "pay_transfer.bank_transfer.exchange_rate_detail.approximate.body": (string1: React.ReactNode) => <div className="whitespace-pre-line">{t("We will convert your money at the live exchange rate at the time we receive your payment. This may be higher or lower than {string1}.\n\nIf you would like a fixed exchange rate, we recommend canceling this transfer and creating a new one.", {_key: "pay_transfer.bank_transfer.exchange_rate_detail.approximate.body", _tags: "ios", string1})}</div>,
  "pay_transfer.bank_transfer.exchange_rate_detail.exact.body": (string1: React.ReactNode, string2: React.ReactNode) => t("Your exchange rate is locked at {string1} until {string2}. If we receive your payment after that time, we’ll use the live exchange rate instead.", {_key: "pay_transfer.bank_transfer.exchange_rate_detail.exact.body", _tags: "ios", string1, string2}),
  "pay_transfer.bank_transfer.refund.alert.body": (string1: React.ReactNode, string2: React.ReactNode) => <div className="whitespace-pre-line">{t("We will cancel and refund this transfer.\n\nYou should receive {string1} to your bank account ending in {string2} within one business day.\n\nAre you sure you would like to proceed?", {_key: "pay_transfer.bank_transfer.refund.alert.body", _tags: "ios", string1, string2})}</div>,
  "pay_transfer.bank_transfer.refund.alert.title": t("Confirm refund", {_key: "pay_transfer.bank_transfer.refund.alert.title", _tags: "ios", }),
  "personal_info.update_phone_number.title": t("Update phone number", {_key: "personal_info.update_phone_number.title", _tags: "ios", }),
  "portal.delivery_option.description": (string1: React.ReactNode, string2: React.ReactNode, string3: React.ReactNode) => t("{string1}: {string2}, {string3}", {_key: "portal.delivery_option.description", _tags: "ios", string1, string2, string3}),
  "portal.loading": t("Creating bank details", {_key: "portal.loading", _tags: "ios", }),
  "portals.delivery_option_selection_description": t("We’ll use this delivery option for all Portals, for all recipients.", {_key: "portals.delivery_option_selection_description", _tags: "ios", }),
  "portals.delivery_option.basic_description.instant": t("Your transfer should arrive instantly once we receive a payment.", {_key: "portals.delivery_option.basic_description.instant", _tags: "ios", }),
  "portals.delivery_option.basic_description.same_day": t("Your transfer should arrive on the same business day that we receive a payment.", {_key: "portals.delivery_option.basic_description.same_day", _tags: "ios", }),
  "portals.delivery_option.description.express": t("We’ll use Express delivery whenever it’s available. Otherwise, we’ll use Standard and won’t charge you for Express.", {_key: "portals.delivery_option.description.express", _tags: "ios", }),
  "portals.delivery_option.title": (string1: React.ReactNode, string2: React.ReactNode) => t("{string1}: {string2}", {_key: "portals.delivery_option.title", _tags: "ios", string1, string2}),
  "portals.optional_reference": t("Reference (optional)", {_key: "portals.optional_reference", _tags: "ios", }),
  "portals.show_less": t("Show less", {_key: "portals.show_less", _tags: "ios", }),
  "portals.show_more": t("Show more", {_key: "portals.show_more", _tags: "ios", }),
  "portals.subtitle": (string1: React.ReactNode, string2: React.ReactNode) => t("When you send money to a portal, we’ll automatically convert it to {string1} and send it to {string2}.", {_key: "portals.subtitle", _tags: "ios", string1, string2}),
  "portals.title": t("Portals", {_key: "portals.title", _tags: "ios", }),
  "quote.approximate_warning.subtitle": (string1: React.ReactNode) => <div className="whitespace-pre-line">{t("Please note that you selected the approximate option, so your recipient will likely not receive exactly {string1}.\n\nInstead, they’ll receive about {string1} based on the rate when markets open on Monday.\n\nIf you’d like them to receive exactly {string1}, we’d recommend choosing the exact exchange rate.", {_key: "quote.approximate_warning.subtitle", _tags: "ios", string1})}</div>,
  "quote.approximate_warning.title": t("Approximate exchange rate", {_key: "quote.approximate_warning.title", _tags: "ios", }),
  "recipient_detail.delete_alert.body": (string1: React.ReactNode) => t("Please confirm you would like to remove the recipient {string1}.", {_key: "recipient_detail.delete_alert.body", _tags: "ios", string1}),
  "recipient_detail.delete_alert.primary_action": t("Confirm removal", {_key: "recipient_detail.delete_alert.primary_action", _tags: "ios", }),
  "recipient_detail.delete.title": t("Remove recipient", {_key: "recipient_detail.delete.title", _tags: "ios", }),
  "recipient_detail.recipient_info": t("Recipient info", {_key: "recipient_detail.recipient_info", _tags: "ios", }),
  "recipient_detail.send_money.title": t("Send money", {_key: "recipient_detail.send_money.title", _tags: "ios", }),
  "referral.reward.free_transfer.title": t("Free transfer", {_key: "referral.reward.free_transfer.title", _tags: "ios", }),
  "referral.status.accepted.title": t("Completed", {_key: "referral.status.accepted.title", _tags: "ios", }),
  "referral.status.checks_pending.title": t("In review", {_key: "referral.status.checks_pending.title", _tags: "ios", }),
  "referral.status.created_transfer.title": t("Created a transfer", {_key: "referral.status.created_transfer.title", _tags: "ios", }),
  "referral.status.paid_transfer.title": t("Completed a transfer", {_key: "referral.status.paid_transfer.title", _tags: "ios", }),
  "referral.status.payout_failed.title": t("Payment failed", {_key: "referral.status.payout_failed.title", _tags: "ios", }),
  "referral.status.payout_pending_recipient.title": t("Choose your payment method", {_key: "referral.status.payout_pending_recipient.title", _tags: "ios", }),
  "referral.status.payout_pending.title": t("Payment in progress", {_key: "referral.status.payout_pending.title", _tags: "ios", }),
  "referral.status.rejected.title": t("Rejected", {_key: "referral.status.rejected.title", _tags: "ios", }),
  "referral.status.started_signup.title": t("Signed up", {_key: "referral.status.started_signup.title", _tags: "ios", }),
  "referrals.confirm_payout_recipient": t("Use this account", {_key: "referrals.confirm_payout_recipient", _tags: "ios", }),
  "referrals.list.completed": t("Completed", {_key: "referrals.list.completed", _tags: "ios", }),
  "referrals.list.in_progress": t("In progress", {_key: "referrals.list.in_progress", _tags: "ios", }),
  "referrals.list.title": t("Your referral history", {_key: "referrals.list.title", _tags: "ios", }),
  "referrals.more": t("More", {_key: "referrals.more", _tags: "ios", }),
  "referrals.payment_method.add": t("Add account", {_key: "referrals.payment_method.add", _tags: "ios", }),
  "referrals.payment_method.subtitle": t("This is where we’ll send your referral reward payments. You’ll receive each payment as soon as the referral is confirmed.", {_key: "referrals.payment_method.subtitle", _tags: "ios", }),
  "referrals.payment_method.suggested_title": (string1: React.ReactNode) => t("Suggested: {string1}", {_key: "referrals.payment_method.suggested_title", _tags: "ios", string1}),
  "referrals.payment_method.title": t("Payment method", {_key: "referrals.payment_method.title", _tags: "ios", }),
  "referrals.recipients.title": t("Choose an account", {_key: "referrals.recipients.title", _tags: "ios", }),
  "referrals.refer_cash.subtitle": (string1: React.ReactNode) => t("You’ll earn {string1} for every friend that transfers with Atlantic. Plus, their first transfer is free!", {_key: "referrals.refer_cash.subtitle", _tags: "ios", string1}),
  "referrals.refer_cash.title": (string1: React.ReactNode) => t("Invite a friend, get {string1}", {_key: "referrals.refer_cash.title", _tags: "ios", string1}),
  "referrals.refer.copy_link": t("Copy link", {_key: "referrals.refer.copy_link", _tags: "ios", }),
  "referrals.refer.share": t("Share", {_key: "referrals.refer.share", _tags: "ios", }),
  "referrals.refer.subtitle": t("You’ll get a free transfer for every friend that joins with your link and completes a transfer. Their first transfer is free too!", {_key: "referrals.refer.subtitle", _tags: "ios", }),
  "referrals.refer.title": t("Invite friends, get free transfers", {_key: "referrals.refer.title", _tags: "ios", }),
  "referrals.share_text": (string1: React.ReactNode) => t("Hey there! Use my link to sign up for Atlantic and your first transfer will be completely free! {string1}", {_key: "referrals.share_text", _tags: "ios", string1}),
  "referrals.summary.complete": t("Complete", {_key: "referrals.summary.complete", _tags: "ios", }),
  "referrals.summary.earned": t("Total earned", {_key: "referrals.summary.earned", _tags: "ios", }),
  "referrals.summary.pending": t("In progress", {_key: "referrals.summary.pending", _tags: "ios", }),
  "request_country.other": t("I don’t see my country", {_key: "request_country.other", _tags: "ios", }),
  "request_country.other.placeholder": t("Country name", {_key: "request_country.other.placeholder", _tags: "ios", }),
  "request_country.subtitle": t("Let us know what country you live in, and we’ll try to support you as soon as possible.", {_key: "request_country.subtitle", _tags: "ios", }),
  "request_country.success.subtitle": t("We’ll try to launch our services in your country as soon as possible.", {_key: "request_country.success.subtitle", _tags: "ios", }),
  "request_country.success.title": t("Thanks for letting us know", {_key: "request_country.success.title", _tags: "ios", }),
  "request_country.title": t("Request a country", {_key: "request_country.title", _tags: "ios", }),
  "request_currency.other": t("I don’t see my currency", {_key: "request_currency.other", _tags: "ios", }),
  "request_currency.subtitle": t("We’ll let you know when this currency is available on Atlantic.", {_key: "request_currency.subtitle", _tags: "ios", }),
  "request_currency.success.title": t("We’ve received your request", {_key: "request_currency.success.title", _tags: "ios", }),
  "request_currency.title": t("Request a currency", {_key: "request_currency.title", _tags: "ios", }),
  "reset_password.subtitle": t("We’ll email you a link where you can set a new password.", {_key: "reset_password.subtitle", _tags: "ios", }),
  "reset_password.success.subtitle": t("Please check your email, we’ve emailed you a link where you can set a new password.", {_key: "reset_password.success.subtitle", _tags: "ios", }),
  "reset_password.success.title": t("We’ve emailed you a link", {_key: "reset_password.success.title", _tags: "ios", }),
  "reset_password.title": t("Reset your password", {_key: "reset_password.title", _tags: "ios", }),
  "root.recipient.title": t("Recipients", {_key: "root.recipient.title", _tags: "ios", }),
  "scan_id.generic.back.title": (string1: React.ReactNode) => t("Please scan the back of your {string1}.", {_key: "scan_id.generic.back.title", _tags: "ios", string1}),
  "scan_id.generic.front.title": (string1: React.ReactNode) => t("Please scan the front of your {string1}.", {_key: "scan_id.generic.front.title", _tags: "ios", string1}),
  "scan_id.passport.front.title": t("Please scan the photo page of your passport.", {_key: "scan_id.passport.front.title", _tags: "ios", }),
  "selfie_intro.subtitle": t("These selfies will be matched against your ID. This is how we confirm you are you.", {_key: "selfie_intro.subtitle", _tags: "ios", }),
  "selfie_intro.title": t("We’ll also need you to take a few selfies.", {_key: "selfie_intro.title", _tags: "ios", }),
  "selfie.face_count_check.many_faces.error_message": t("Please make sure only your face is in view.", {_key: "selfie.face_count_check.many_faces.error_message", _tags: "ios", }),
  "selfie.face_count_check.no_faces.error_message": t("Please make sure your face is fully in view.", {_key: "selfie.face_count_check.no_faces.error_message", _tags: "ios", }),
  "selfie.face_size_check.too_big.error_message": t("Move just a little further away...", {_key: "selfie.face_size_check.too_big.error_message", _tags: "ios", }),
  "selfie.face_size_check.too_small.error_message": t("Move just a little closer...", {_key: "selfie.face_size_check.too_small.error_message", _tags: "ios", }),
  "selfie.left.title": t("Perfect. Now, look left", {_key: "selfie.left.title", _tags: "ios", }),
  "selfie.right.title": t("Great. Finally, look right", {_key: "selfie.right.title", _tags: "ios", }),
  "selfie.straight.title": t("First, look directly at the camera", {_key: "selfie.straight.title", _tags: "ios", }),
  "send_to_recipient_currency_unavailable.body": t("Unfortunately we’re not currently able to make transfers to this recipient’s currency.", {_key: "send_to_recipient_currency_unavailable.body", _tags: "ios", }),
  "send_to_recipient_currency_unavailable.title": t("Currency unavailable", {_key: "send_to_recipient_currency_unavailable.title", _tags: "ios", }),
  "signup.address.confirm.subtitle": t("Let’s make sure we’ve got the right address.", {_key: "signup.address.confirm.subtitle", _tags: "ios", }),
  "signup.address.confirm.title": t("Confirm home address", {_key: "signup.address.confirm.title", _tags: "ios", }),
  "signup.address.title": t("Home address", {_key: "signup.address.title", _tags: "ios", }),
  "signup.business.subtitle.2": t("Open an account for your company.", {_key: "signup.business.subtitle.2", _tags: "ios", }),
  "signup.business.title": t("Business", {_key: "signup.business.title", _tags: "ios", }),
  "signup.country_of_residence.subtitle": t("What’s your country of residence?", {_key: "signup.country_of_residence.subtitle", _tags: "ios", }),
  "signup.credentials.subtitle": t("Please make sure your password is at least 10 characters.", {_key: "signup.credentials.subtitle", _tags: "ios", }),
  "signup.credentials.title": t("Create your login", {_key: "signup.credentials.title", _tags: "ios", }),
  "signup.disclosure.title": t("Please review our terms", {_key: "signup.disclosure.title", _tags: "ios", }),
  "signup.dob.title": t("Date of birth", {_key: "signup.dob.title", _tags: "ios", }),
  "signup.email.placeholder": t("Email address", {_key: "signup.email.placeholder", _tags: "ios", }),
  "signup.hear_about_us.friend": t("Friend or family", {_key: "signup.hear_about_us.friend", _tags: "ios", }),
  "signup.hear_about_us.google": t("Google search", {_key: "signup.hear_about_us.google", _tags: "ios", }),
  "signup.hear_about_us.news": t("News article", {_key: "signup.hear_about_us.news", _tags: "ios", }),
  "signup.hear_about_us.price_comparison": t("Price comparison site", {_key: "signup.hear_about_us.price_comparison", _tags: "ios", }),
  "signup.hear_about_us.social_media": t("Social media", {_key: "signup.hear_about_us.social_media", _tags: "ios", }),
  "signup.hear_about_us.title": t("How did you hear about us?", {_key: "signup.hear_about_us.title", _tags: "ios", }),
  "signup.individual.subtitle": t("Open an account for your own personal use.", {_key: "signup.individual.subtitle", _tags: "ios", }),
  "signup.individual.title": t("Personal", {_key: "signup.individual.title", _tags: "ios", }),
  "signup.lets_get_started": t("Let’s get started.", {_key: "signup.lets_get_started", _tags: "ios", }),
  "signup.name.first_name.placeholder": t("First name", {_key: "signup.name.first_name.placeholder", _tags: "ios", }),
  "signup.name.hint": t("No nicknames please!", {_key: "signup.name.hint", _tags: "ios", }),
  "signup.name.last_name.placeholder": t("Last name", {_key: "signup.name.last_name.placeholder", _tags: "ios", }),
  "signup.name.subtitle": t("Use your full legal name, exactly like your government ID. This helps us open your account more quickly.", {_key: "signup.name.subtitle", _tags: "ios", }),
  "signup.name.title": t("Your legal name", {_key: "signup.name.title", _tags: "ios", }),
  "signup.password.placeholder": t("Password", {_key: "signup.password.placeholder", _tags: "ios", }),
  "signup.phone_number.title": t("Phone number", {_key: "signup.phone_number.title", _tags: "ios", }),
  "signup.primary_currency.subtitle": t("We’ll use this to personalize your experience.", {_key: "signup.primary_currency.subtitle", _tags: "ios", }),
  "signup.primary_currency.title": t("Which currency do you primarily transfer to?", {_key: "signup.primary_currency.title", _tags: "ios", }),
  "signup.primary_source_currency.subtitle": t("We’ll use this to personalize your experience.", {_key: "signup.primary_source_currency.subtitle", _tags: "ios", }),
  "signup.primary_source_currency.title": t("Which currency do you primarily transfer from?", {_key: "signup.primary_source_currency.title", _tags: "ios", }),
  "signup.required_by_law.subtitle": t("We’re required by law to collect this information to help verify your identity.", {_key: "signup.required_by_law.subtitle", _tags: "ios", }),
  "signup.verify_phone_number.resend_title": t("Resend code", {_key: "signup.verify_phone_number.resend_title", _tags: "ios", }),
  "signup.verify_phone_number.resent_code": t("Code resent!", {_key: "signup.verify_phone_number.resent_code", _tags: "ios", }),
  "signup.verify_phone_number.subtitle": (string1: React.ReactNode) => t("We sent a text message to {string1}. Please enter your code here.", {_key: "signup.verify_phone_number.subtitle", _tags: "ios", string1}),
  "signup.verify_phone_number.title": t("Verify your phone number", {_key: "signup.verify_phone_number.title", _tags: "ios", }),
  "source_of_funds.remove_document": t("Remove document", {_key: "source_of_funds.remove_document", _tags: "ios", }),
  "source_of_funds.verification_polling_expired.subtitle": t("We haven’t received any of your documents yet. Please make sure you’ve sent them in – we’ll double check on our end too.", {_key: "source_of_funds.verification_polling_expired.subtitle", _tags: "ios", }),
  "source_of_funds.verification_polling_expired.title": t("Your documents", {_key: "source_of_funds.verification_polling_expired.title", _tags: "ios", }),
  "source_of_funds.view_document": t("View document", {_key: "source_of_funds.view_document", _tags: "ios", }),
  "support.auth.imessage": t("Auth with iMessage", {_key: "support.auth.imessage", _tags: "ios", }),
  "support.auth.initial_message": (string1: React.ReactNode, string2: React.ReactNode) => t("Send this message to authenticate yourself.\n\nHi there, I’m {string1} and my email is {string2}.", {_key: "support.auth.initial_message", _tags: "ios", string1, string2}),
  "support.auth.initial_message.business": (string1: React.ReactNode, string2: React.ReactNode, string3: React.ReactNode) => t("Send this message to authenticate yourself.\n\nHi there, I’m {string1} from {string2} and my email is {string3}.", {_key: "support.auth.initial_message.business", _tags: "ios", string1, string2, string3}),
  "support.auth.subtitle": t("Please choose your channel and we’ll securely authenticate you in the background.", {_key: "support.auth.subtitle", _tags: "ios", }),
  "support.auth.title": t("Authenticate with our support team", {_key: "support.auth.title", _tags: "ios", }),
  "support.auth.whatsapp": t("Auth with WhatsApp", {_key: "support.auth.whatsapp", _tags: "ios", }),
  "support.chat_with_us.initial_message": (string1: React.ReactNode, string2: React.ReactNode) => t("Send this message to get started.\n\nHi there, I’m {string1} and my email is {string2}.", {_key: "support.chat_with_us.initial_message", _tags: "ios", string1, string2}),
  "support.chat_with_us.initial_message_detail_appendage": (string1: React.ReactNode) => t("I was looking at “{string1}” and I need some help.", {_key: "support.chat_with_us.initial_message_detail_appendage", _tags: "ios", string1}),
  "support.chat_with_us.initial_message.business": (string1: React.ReactNode, string2: React.ReactNode, string3: React.ReactNode) => t("Send this message to get started.\n\nHi there, I’m {string1} from {string2} and my email is {string3}.", {_key: "support.chat_with_us.initial_message.business", _tags: "ios", string1, string2, string3}),
  "support.chat_with_us.initial_message.unauthed": t("Send this message to get started.\n\nHi there, I’m a new customer!", {_key: "support.chat_with_us.initial_message.unauthed", _tags: "ios", }),
  "support.chat_with_us.subtitle": t("We’re available from 9am-6pm (London time), Monday to Friday.", {_key: "support.chat_with_us.subtitle", _tags: "ios", }),
  "support.chat_with_us.title": t("Chat with us", {_key: "support.chat_with_us.title", _tags: "ios", }),
  "tier.day_limit": (number1: React.ReactNode) => t("{number1}-day limit", {_key: "tier.day_limit", _tags: "ios", number1}),
  "tier.upgrade_in_review.subtitle": t("Thank you for submitting your documents. We’re reviewing your information and we’ll get back to you as soon as possible.", {_key: "tier.upgrade_in_review.subtitle", _tags: "ios", }),
  "tier.upgrade_in_review.title": (string1: React.ReactNode) => t("Your upgrade to {string1} is in review", {_key: "tier.upgrade_in_review.title", _tags: "ios", string1}),
  "tier.upgrade_successful.subtitle": (string1: React.ReactNode) => t("You’ve been upgraded to {string1}, and your limits have been increased.", {_key: "tier.upgrade_successful.subtitle", _tags: "ios", string1}),
  "tier.upgrade_successful.title": t("Congratulations, your upgrade was successful", {_key: "tier.upgrade_successful.title", _tags: "ios", }),
  "tier.upgrade.in_review": t("In review", {_key: "tier.upgrade.in_review", _tags: "ios", }),
  "tiers.custom.days_left": (string1: React.ReactNode) => t("{string1} days left", {_key: "tiers.custom.days_left", _tags: "ios", string1}),
  "tiers.subtitle": t("The more we’re able to verify your identity, the higher sending limit we’ll be able to give you.", {_key: "tiers.subtitle", _tags: "ios", }),
  "tiers.title": t("Limits", {_key: "tiers.title", _tags: "ios", }),
  "tiers.upgrade": t("Verify", {_key: "tiers.upgrade", _tags: "ios", }),
  "transfer_detail.title.approximate": (string1: React.ReactNode, string2: React.ReactNode) => t("Approx. {string1} to {string2}", {_key: "transfer_detail.title.approximate", _tags: "ios", string1, string2}),
  "transfer_detail.title.exact": (string1: React.ReactNode, string2: React.ReactNode) => t("{string1} to {string2}", {_key: "transfer_detail.title.exact", _tags: "ios", string1, string2}),
  "transfer_promo.explanation.title": t("Your transfer discount", {_key: "transfer_promo.explanation.title", _tags: "ios", }),
  "transfer_promo.first_transfer.explanation": t("Welcome to Atlantic! To help you get used to our app, we’re happy to waive the fixed fee on your first transfer. Thanks so much for trying us out.", {_key: "transfer_promo.first_transfer.explanation", _tags: "ios", }),
  "transfer_promo.generic.explanation": t("Please enjoy this discount on your transfer.", {_key: "transfer_promo.generic.explanation", _tags: "ios", }),
  "transfer_promo.referral.explanation": t("Thanks so much for referring your friend! This free transfer is your referral bonus.", {_key: "transfer_promo.referral.explanation", _tags: "ios", }),
  "transfer_row.title": (string1: React.ReactNode) => t("To {string1}", {_key: "transfer_row.title", _tags: "ios", string1}),
  "transfer_source_of_funds_select_documents.add_document_type.scan_document": t("Scan document", {_key: "transfer_source_of_funds_select_documents.add_document_type.scan_document", _tags: "ios", }),
  "transfer_source_of_funds_select_documents.add_document_type.select_file": t("Select file", {_key: "transfer_source_of_funds_select_documents.add_document_type.select_file", _tags: "ios", }),
  "transfer_source_of_funds_select_documents.add_document_type.select_photo": t("Select photo", {_key: "transfer_source_of_funds_select_documents.add_document_type.select_photo", _tags: "ios", }),
  "transfer_source_of_funds_select_documents.select_first_document": t("Select your first document", {_key: "transfer_source_of_funds_select_documents.select_first_document", _tags: "ios", }),
  "transfer_source_of_funds.copy_email": t("Copy email", {_key: "transfer_source_of_funds.copy_email", _tags: "ios", }),
  "transfer_source_of_funds.details_title": t("Please send us documents showing the following details", {_key: "transfer_source_of_funds.details_title", _tags: "ios", }),
  "transfer_source_of_funds.email_subtitle": t("This is your unique email address. You can send us one or more emails with your documents.", {_key: "transfer_source_of_funds.email_subtitle", _tags: "ios", }),
  "transfer_source_of_funds.email_title": t("You can email your documents to:", {_key: "transfer_source_of_funds.email_title", _tags: "ios", }),
  "transfer_source_of_funds.emailed_all_docs": t("I’ve sent all my docs", {_key: "transfer_source_of_funds.emailed_all_docs", _tags: "ios", }),
  "transfer_source_of_funds.submitted.subtitle.2": t("We’ll review your documents within the next few days. You’ll receive a higher limit on your account once we’ve completed our review.", {_key: "transfer_source_of_funds.submitted.subtitle.2", _tags: "ios", }),
  "transfer_source_of_funds.submitted.title": t("Thanks for submitting your documents.", {_key: "transfer_source_of_funds.submitted.title", _tags: "ios", }),
  "transfer_source_of_funds.upload_title": t("Or, you can upload your documents here", {_key: "transfer_source_of_funds.upload_title", _tags: "ios", }),
  "transfer_source_of_funds.upload.subtitle": t("We’ll grant you the highest transfer limit we can based on the documents you submit.", {_key: "transfer_source_of_funds.upload.subtitle", _tags: "ios", }),
  "transfer.amount_entry.express.future_date": (string1: React.ReactNode) => t("Express delivery arrives {string1}", {_key: "transfer.amount_entry.express.future_date", _tags: "ios", string1}),
  "transfer.amount_entry.express.instant": t("Express delivery arrives instantly", {_key: "transfer.amount_entry.express.instant", _tags: "ios", }),
  "transfer.amount_entry.express.today": t("Express delivery arrives today", {_key: "transfer.amount_entry.express.today", _tags: "ios", }),
  "transfer.amount_entry.express.tomorrow": t("Express delivery arrives tomorrow", {_key: "transfer.amount_entry.express.tomorrow", _tags: "ios", }),
  "transfer.amount_entry.generic.future_date": (string1: React.ReactNode) => t("Arrives on {string1}", {_key: "transfer.amount_entry.generic.future_date", _tags: "ios", string1}),
  "transfer.amount_entry.generic.instant": t("Arrives instantly", {_key: "transfer.amount_entry.generic.instant", _tags: "ios", }),
  "transfer.amount_entry.generic.today": t("Arrives today", {_key: "transfer.amount_entry.generic.today", _tags: "ios", }),
  "transfer.amount_entry.generic.tomorrow": t("Arrives tomorrow", {_key: "transfer.amount_entry.generic.tomorrow", _tags: "ios", }),
  "transfer.amount_entry.standard.future_date": (string1: React.ReactNode) => t("Standard delivery arrives {string1}", {_key: "transfer.amount_entry.standard.future_date", _tags: "ios", string1}),
  "transfer.amount_entry.standard.today": t("Standard delivery arrives today", {_key: "transfer.amount_entry.standard.today", _tags: "ios", }),
  "transfer.amount_entry.standard.tomorrow": t("Standard delivery arrives tomorrow", {_key: "transfer.amount_entry.standard.tomorrow", _tags: "ios", }),
  "transfer.amount.approximate": (string1: React.ReactNode) => t("About {string1}", {_key: "transfer.amount.approximate", _tags: "ios", string1}),
  "transfer.amount.exact": (string1: React.ReactNode) => t("Exactly {string1}", {_key: "transfer.amount.exact", _tags: "ios", string1}),
  "transfer.cancel": t("Cancel transfer", {_key: "transfer.cancel", _tags: "ios", }),
  "transfer.cancel_and_refund": t("Cancel and refund", {_key: "transfer.cancel_and_refund", _tags: "ios", }),
  "transfer.confirm.confirm_transfer": t("Confirm transfer", {_key: "transfer.confirm.confirm_transfer", _tags: "ios", }),
  "transfer.converted_amount": t("Converted amount", {_key: "transfer.converted_amount", _tags: "ios", }),
  "transfer.delivery_arrives_on.future_date.2": (string1: React.ReactNode) => t("Should arrive on {string1}", {_key: "transfer.delivery_arrives_on.future_date.2", _tags: "ios", string1}),
  "transfer.delivery_arrives_on.instant": t("Should arrive instantly", {_key: "transfer.delivery_arrives_on.instant", _tags: "ios", }),
  "transfer.delivery_arrives_on.past.2": (string1: React.ReactNode) => t("Should have arrived on {string1}", {_key: "transfer.delivery_arrives_on.past.2", _tags: "ios", string1}),
  "transfer.delivery_arrives_on.today.2": t("Should arrive today", {_key: "transfer.delivery_arrives_on.today.2", _tags: "ios", }),
  "transfer.delivery_arrives_on.tomorrow.2": t("Should arrive tomorrow", {_key: "transfer.delivery_arrives_on.tomorrow.2", _tags: "ios", }),
  "transfer.delivery_option.expedited.title": (string1: React.ReactNode) => t("Express: {string1}", {_key: "transfer.delivery_option.expedited.title", _tags: "ios", string1}),
  "transfer.delivery_option.expedited.title.today": t("Express: Today", {_key: "transfer.delivery_option.expedited.title.today", _tags: "ios", }),
  "transfer.delivery_option.expedited.title.tomorrow": t("Express: Tomorrow", {_key: "transfer.delivery_option.expedited.title.tomorrow", _tags: "ios", }),
  "transfer.delivery_option.instant.approximate.description": (string1: React.ReactNode, string2: React.ReactNode) => t("{string1} should receive about {string2} instantly once we have your payment.", {_key: "transfer.delivery_option.instant.approximate.description", _tags: "ios", string1, string2}),
  "transfer.delivery_option.instant.exact.description": (string1: React.ReactNode, string2: React.ReactNode) => t("{string1} should receive exactly {string2} instantly once we have your payment.", {_key: "transfer.delivery_option.instant.exact.description", _tags: "ios", string1, string2}),
  "transfer.delivery_option.instant.title": t("Express: Instant", {_key: "transfer.delivery_option.instant.title", _tags: "ios", }),
  "transfer.delivery_option.standard.approximate.description": (string1: React.ReactNode, string2: React.ReactNode, string3: React.ReactNode) => t("{string1} should receive about {string2} on {string3}.", {_key: "transfer.delivery_option.standard.approximate.description", _tags: "ios", string1, string2, string3}),
  "transfer.delivery_option.standard.exact.description": (string1: React.ReactNode, string2: React.ReactNode, string3: React.ReactNode) => t("{string1} should receive exactly {string2} on {string3}.", {_key: "transfer.delivery_option.standard.exact.description", _tags: "ios", string1, string2, string3}),
  "transfer.delivery_option.standard.title": (string1: React.ReactNode) => t("Standard: {string1}", {_key: "transfer.delivery_option.standard.title", _tags: "ios", string1}),
  "transfer.delivery_option.standard.title.today": t("Standard: Today", {_key: "transfer.delivery_option.standard.title.today", _tags: "ios", }),
  "transfer.delivery_option.standard.title.tomorrow": t("Standard: Tomorrow", {_key: "transfer.delivery_option.standard.title.tomorrow", _tags: "ios", }),
  "transfer.delivery_option.title": t("Choose your delivery speed", {_key: "transfer.delivery_option.title", _tags: "ios", }),
  "transfer.details.exchange_rate.estimate.live_quote": (string1: React.ReactNode) => <div className="whitespace-pre-line">{t("We will convert your money at the live exchange rate at the time we receive your payment. This may be higher or lower than {string1}.\n\nIf you would like an exact exchange rate, you may edit your exchange rate option.", {_key: "transfer.details.exchange_rate.estimate.live_quote", _tags: "ios", string1})}</div>,
  "transfer.details.exchange_rate.frozen_quote": (string1: React.ReactNode, string2: React.ReactNode) => <div className="whitespace-pre-line">{t("Your exchange rate is locked at {string1} until {string2}.\n\nIf we receive your payment after that time, we’ll use the live exchange rate instead.", {_key: "transfer.details.exchange_rate.frozen_quote", _tags: "ios", string1, string2})}</div>,
  "transfer.details.exchange_rate.not_paid_for.live_quote": (string1: React.ReactNode) => <div className="whitespace-pre-line">{t("We will convert your money at the live exchange rate at the time we receive your payment. This may be higher or lower than {string1}.\n\nIf you would like an exact exchange rate, you may cancel this transfer and create a new one.", {_key: "transfer.details.exchange_rate.not_paid_for.live_quote", _tags: "ios", string1})}</div>,
  "transfer.entry_mode_availability.destination_only.body": t("Transfers between these currencies must be specified by the destination amount.", {_key: "transfer.entry_mode_availability.destination_only.body", _tags: "ios", }),
  "transfer.entry_mode_availability.destination_only.title": t("Destination amounts only", {_key: "transfer.entry_mode_availability.destination_only.title", _tags: "ios", }),
  "transfer.entry_mode_availability.source_only.body": t("Transfers between these currencies must be specified by the source amount.", {_key: "transfer.entry_mode_availability.source_only.body", _tags: "ios", }),
  "transfer.entry_mode_availability.source_only.title": t("Source amounts only", {_key: "transfer.entry_mode_availability.source_only.title", _tags: "ios", }),
  "transfer.fee": t("fee", {_key: "transfer.fee", _tags: "ios", }),
  "transfer.fee_breakdown.delivery.approximate": t("Live quote fee", {_key: "transfer.fee_breakdown.delivery.approximate", _tags: "ios", }),
  "transfer.fee_breakdown.delivery.exact": t("Exact weekend quote fee", {_key: "transfer.fee_breakdown.delivery.exact", _tags: "ios", }),
  "transfer.fee_breakdown.delivery.expedited": t("Express delivery fee", {_key: "transfer.fee_breakdown.delivery.expedited", _tags: "ios", }),
  "transfer.fee_breakdown.delivery.instant": t("Instant delivery fee", {_key: "transfer.fee_breakdown.delivery.instant", _tags: "ios", }),
  "transfer.fee_breakdown.delivery.standard": t("Standard delivery fee", {_key: "transfer.fee_breakdown.delivery.standard", _tags: "ios", }),
  "transfer.fee_breakdown.fixed_fee": t("Fixed fee", {_key: "transfer.fee_breakdown.fixed_fee", _tags: "ios", }),
  "transfer.fee_breakdown.transfer_fees": t("Transfer fees", {_key: "transfer.fee_breakdown.transfer_fees", _tags: "ios", }),
  "transfer.fixed_fee": t("fixed fee", {_key: "transfer.fixed_fee", _tags: "ios", }),
  "transfer.frozen_quote_unavailable.subtitle": (string1: React.ReactNode) => <div className="whitespace-pre-line">{t("Because your original quote has expired and markets are currently closed, we can’t guarantee your recipient will receive exactly {string1}.\n\nThey will receive approximately {string1}.\n\nIf you want your recipient to receive exactly {string1}, we recommend canceling and reinitating this transfer when currency markets are open.", {_key: "transfer.frozen_quote_unavailable.subtitle", _tags: "ios", string1})}</div>,
  "transfer.frozen_quote_unavailable.title": t("We can’t guarantee your receiving amount", {_key: "transfer.frozen_quote_unavailable.title", _tags: "ios", }),
  "transfer.live_rate": t("live rate", {_key: "transfer.live_rate", _tags: "ios", }),
  "transfer.locked_exchange_rate": t("Locked exchange rate", {_key: "transfer.locked_exchange_rate", _tags: "ios", }),
  "transfer.locked_until": t("Locked until", {_key: "transfer.locked_until", _tags: "ios", }),
  "transfer.pay.things_to_remember.one_transfer": t("Please send us your payment with exactly one bank transfer.", {_key: "transfer.pay.things_to_remember.one_transfer", _tags: "ios", }),
  "transfer.pay.things_to_remember.title": t("Things to remember for your payment", {_key: "transfer.pay.things_to_remember.title", _tags: "ios", }),
  "transfer.pay.things_to_remember.transfer_type": t("Please do not send us transfers via SWIFT, wire,  etc. - we can only accept local bank transfers.", {_key: "transfer.pay.things_to_remember.transfer_type", _tags: "ios", }),
  "transfer.pay.things_to_remember.transfer_type_eur": t("Please pay us by European transfer (SEPA), not SWIFT.", {_key: "transfer.pay.things_to_remember.transfer_type_eur", _tags: "ios", }),
  "transfer.payment_received.view_transfer_details": t("View transfer details", {_key: "transfer.payment_received.view_transfer_details", _tags: "ios", }),
  "transfer.quote_option.approximate.subtitle": (string1: React.ReactNode) => t("We’ll use the live rate when markets open on Monday. This rate may be higher or lower than {string1}.", {_key: "transfer.quote_option.approximate.subtitle", _tags: "ios", string1}),
  "transfer.quote_option.approximate.title": (string1: React.ReactNode) => t("About {string1}", {_key: "transfer.quote_option.approximate.title", _tags: "ios", string1}),
  "transfer.quote_option.disabled": t("We apologize, this option is not currently available.", {_key: "transfer.quote_option.disabled", _tags: "ios", }),
  "transfer.quote_option.exact.subtitle": t("This was the rate when markets closed on Friday.", {_key: "transfer.quote_option.exact.subtitle", _tags: "ios", }),
  "transfer.quote_option.exact.title": (string1: React.ReactNode) => t("Exactly {string1}", {_key: "transfer.quote_option.exact.title", _tags: "ios", string1}),
  "transfer.quote_option.subtitle": t("Because global currency markets are closed on the weekend, you have a choice of exchange rates.", {_key: "transfer.quote_option.subtitle", _tags: "ios", }),
  "transfer.quote_option.title": t("Choose your weekend exchange rate", {_key: "transfer.quote_option.title", _tags: "ios", }),
  "transfer.receive_destination_on": (string1: React.ReactNode) => t("We’ll have your {string1} on", {_key: "transfer.receive_destination_on", _tags: "ios", string1}),
  "transfer.recipient.add_a_recipient": t("Add a recipient", {_key: "transfer.recipient.add_a_recipient", _tags: "ios", }),
  "transfer.recipient.section_title.businesses": t("Businesses", {_key: "transfer.recipient.section_title.businesses", _tags: "ios", }),
  "transfer.recipient.section_title.my_accounts": t("My accounts", {_key: "transfer.recipient.section_title.my_accounts", _tags: "ios", }),
  "transfer.recipient.section_title.people": t("People", {_key: "transfer.recipient.section_title.people", _tags: "ios", }),
  "transfer.recipient.select_a_recipient": t("Select a recipient", {_key: "transfer.recipient.select_a_recipient", _tags: "ios", }),
  "transfer.reference.clear_reference": t("Clear reference", {_key: "transfer.reference.clear_reference", _tags: "ios", }),
  "transfer.review.add_reference": t("Add a transfer reference", {_key: "transfer.review.add_reference", _tags: "ios", }),
  "transfer.review.add_reference_optional": t("Add reference (optional)", {_key: "transfer.review.add_reference_optional", _tags: "ios", }),
  "transfer.review.agree_to_terms": t("By confirming this transfer, I agree to the [terms and conditions].", {_key: "transfer.review.agree_to_terms", _tags: "ios", }),
  "transfer.review.bank_limits_warning": t("We recommend double checking your bank’s limits – some banks won’t let you pay us this much. If your bank’s limits are too low, you can split this into multiple transfers. Please remember we can’t accept wires.", {_key: "transfer.review.bank_limits_warning", _tags: "ios", }),
  "transfer.review.recipient.title": t("Recipient details", {_key: "transfer.review.recipient.title", _tags: "ios", }),
  "transfer.review.reference": t("Reference", {_key: "transfer.review.reference", _tags: "ios", }),
  "transfer.review.title": t("Review your transfer", {_key: "transfer.review.title", _tags: "ios", }),
  "transfer.review.transfer_amount.approximate.description": <div className="whitespace-pre-line">{t("This amount is approximate because your exchange rate is not fixed.\n\nIf you would like your recipient to receive an exact amount, we recommend canceling this transfer and creating a new one.", {_key: "transfer.review.transfer_amount.approximate.description", _tags: "ios", })}</div>,
  "transfer.review.transfer.delivery": t("Delivery", {_key: "transfer.review.transfer.delivery", _tags: "ios", }),
  "transfer.review.transfer.exchange_rate": t("Exchange rate", {_key: "transfer.review.transfer.exchange_rate", _tags: "ios", }),
  "transfer.review.transfer.quote": t("Weekend exchange rate", {_key: "transfer.review.transfer.quote", _tags: "ios", }),
  "transfer.review.transfer.quote.approximate": t("Approximate", {_key: "transfer.review.transfer.quote.approximate", _tags: "ios", }),
  "transfer.review.transfer.quote.exact": t("Exact", {_key: "transfer.review.transfer.quote.exact", _tags: "ios", }),
  "transfer.review.transfer.receiving_amount": t("Receiving amount", {_key: "transfer.review.transfer.receiving_amount", _tags: "ios", }),
  "transfer.review.transfer.sending_amount": t("Sending amount", {_key: "transfer.review.transfer.sending_amount", _tags: "ios", }),
  "transfer.review.transfer.submitted_at": t("Submitted at", {_key: "transfer.review.transfer.submitted_at", _tags: "ios", }),
  "transfer.review.transfer.title": t("Transfer details", {_key: "transfer.review.transfer.title", _tags: "ios", }),
  "transfer.review.transfer.total_fees": t("Total fees", {_key: "transfer.review.transfer.total_fees", _tags: "ios", }),
  "transfer.send_payment": t("Send your payment", {_key: "transfer.send_payment", _tags: "ios", }),
  "transfer.source_amount_increased.new_quote": t("New exchange rate", {_key: "transfer.source_amount_increased.new_quote", _tags: "ios", }),
  "transfer.source_amount_increased.new_sending_amount": t("New sending amount", {_key: "transfer.source_amount_increased.new_sending_amount", _tags: "ios", }),
  "transfer.source_amount_increased.subtitle": t("Your original quote has expired, so we’ve updated your quote to the current live rate.", {_key: "transfer.source_amount_increased.subtitle", _tags: "ios", }),
  "transfer.source_amount_increased.title": t("We’ve had to update your exchange rate", {_key: "transfer.source_amount_increased.title", _tags: "ios", }),
  "transfer.status": t("Status", {_key: "transfer.status", _tags: "ios", }),
  "transfer.status.canceled": t("Canceled", {_key: "transfer.status.canceled", _tags: "ios", }),
  "transfer.status.checks_approved": t("In review", {_key: "transfer.status.checks_approved", _tags: "ios", }),
  "transfer.status.checks_failed": t("In review", {_key: "transfer.status.checks_failed", _tags: "ios", }),
  "transfer.status.checks_pending": t("In review", {_key: "transfer.status.checks_pending", _tags: "ios", }),
  "transfer.status.checks_sent": t("In review", {_key: "transfer.status.checks_sent", _tags: "ios", }),
  "transfer.status.created": t("New", {_key: "transfer.status.created", _tags: "ios", }),
  "transfer.status.estimate": t("Estimate", {_key: "transfer.status.estimate", _tags: "ios", }),
  "transfer.status.fee_debited": t("In progress", {_key: "transfer.status.fee_debited", _tags: "ios", }),
  "transfer.status.payin_checks_approved": t("Payment in review", {_key: "transfer.status.payin_checks_approved", _tags: "ios", }),
  "transfer.status.payin_checks_failed": t("Refund in progress", {_key: "transfer.status.payin_checks_failed", _tags: "ios", }),
  "transfer.status.payin_checks_pending": t("Payment in review", {_key: "transfer.status.payin_checks_pending", _tags: "ios", }),
  "transfer.status.payin_checks_sent": t("Payment in review", {_key: "transfer.status.payin_checks_sent", _tags: "ios", }),
  "transfer.status.payin_initiated": t("Awaiting payment", {_key: "transfer.status.payin_initiated", _tags: "ios", }),
  "transfer.status.payin_received": t("Pending payment", {_key: "transfer.status.payin_received", _tags: "ios", }),
  "transfer.status.payin_waiting": t("Pending payment", {_key: "transfer.status.payin_waiting", _tags: "ios", }),
  "transfer.status.payout_completed": t("Funds sent", {_key: "transfer.status.payout_completed", _tags: "ios", }),
  "transfer.status.payout_failed": t("In progress", {_key: "transfer.status.payout_failed", _tags: "ios", }),
  "transfer.status.payout_pending": t("In progress", {_key: "transfer.status.payout_pending", _tags: "ios", }),
  "transfer.status.refund_completed": t("Refunded", {_key: "transfer.status.refund_completed", _tags: "ios", }),
  "transfer.status.refund_failed": t("Refund in progress", {_key: "transfer.status.refund_failed", _tags: "ios", }),
  "transfer.status.refund_pending": t("Refund in progress", {_key: "transfer.status.refund_pending", _tags: "ios", }),
  "transfer.status.trade_pending": t("In progress", {_key: "transfer.status.trade_pending", _tags: "ios", }),
  "transfer.status.trade_placed": t("In progress", {_key: "transfer.status.trade_placed", _tags: "ios", }),
  "transfer.status.trade_settled": t("In progress", {_key: "transfer.status.trade_settled", _tags: "ios", }),
  "transfer.timeline.element.provide_additional_info": t("Provide additional info", {_key: "transfer.timeline.element.provide_additional_info", _tags: "ios", }),
  "transfer.timeline.element.title.3.due_by_subtitle": (string1: React.ReactNode, string2: React.ReactNode, string3: React.ReactNode) => t("Your payment of {string1} is due by {string2} to receive your exchange rate of {string3}.", {_key: "transfer.timeline.element.title.3.due_by_subtitle", _tags: "ios", string1, string2, string3}),
  "transfer.timeline.element.title.13.amount_received": t("Amount received", {_key: "transfer.timeline.element.title.13.amount_received", _tags: "ios", }),
  "transfer.timeline.havent_paid": t("I haven’t paid", {_key: "transfer.timeline.havent_paid", _tags: "ios", }),
  "transfer.timeline.recipient_name": t("Recipient name", {_key: "transfer.timeline.recipient_name", _tags: "ios", }),
  "transfer.timeline.reference_not_needed": t("None required", {_key: "transfer.timeline.reference_not_needed", _tags: "ios", }),
  "transfer.transfer_reference": t("Transfer reference", {_key: "transfer.transfer_reference", _tags: "ios", }),
  "transfer.your_recipient": t("Your recipient", {_key: "transfer.your_recipient", _tags: "ios", }),
  "transfers.list.in_progress": t("In progress", {_key: "transfers.list.in_progress", _tags: "ios", }),
  "transfers.list.older": t("Older", {_key: "transfers.list.older", _tags: "ios", }),
  "transfers.list.recent": t("Recent", {_key: "transfers.list.recent", _tags: "ios", }),
  "transfers.list.title": t("Transfers", {_key: "transfers.list.title", _tags: "ios", }),
  "transfers.list.title_recipient": (string1: React.ReactNode) => t("Transfers to {string1}", {_key: "transfers.list.title_recipient", _tags: "ios", string1}),
  "verification.generic_accepted.subtitle": t("Thanks for sharing your documents, we’ve successfully verified this information.", {_key: "verification.generic_accepted.subtitle", _tags: "ios", }),
  "verification.generic_accepted.title": t("We've accepted this submission!", {_key: "verification.generic_accepted.title", _tags: "ios", }),
  "verification.generic_rejected.subtitle": t("We couldn't accept this submission, but it should be possible for you to try again - please check your email. If you need help, please contact our support team.", {_key: "verification.generic_rejected.subtitle", _tags: "ios", }),
  "verification.generic_rejected.title": t("Please try submitting again", {_key: "verification.generic_rejected.title", _tags: "ios", }),
  "verification.generic_under_review.subtitle": t("Thanks so much for submitting your information. It’s currently in review, and we’ll update you once our review is complete.", {_key: "verification.generic_under_review.subtitle", _tags: "ios", }),
  "verification.generic_under_review.title": t("We’re reviewing your info", {_key: "verification.generic_under_review.title", _tags: "ios", }),
  "verification.unknown.open_app_store": t("Open App Store", {_key: "verification.unknown.open_app_store", _tags: "ios", }),
  "verification.unknown.subtitle": t("We’ve encountered something we don’t recognize. This most likely means there’s a new version of the app waiting for you.", {_key: "verification.unknown.subtitle", _tags: "ios", }),
  "verification.unknown.title": t("Please update Atlantic", {_key: "verification.unknown.title", _tags: "ios", }),
  "verify_email.resent.body": (string1: React.ReactNode) => t("We’ve resent your email verification link to {string1}.", {_key: "verify_email.resent.body", _tags: "ios", string1}),
  "verify_email.resent.title": t("Email verification", {_key: "verify_email.resent.title", _tags: "ios", }),
})