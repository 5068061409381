import React from "react";
import TimelineStepMain from "./TimelineStepMain";
import Limiter from "../../../components/ui/Limiter";
import {withParametricRouter} from "../../../routes/parameteric-router";
import {NavStackRoot} from "../../../components/navigation/NavStack";
import {PropsType} from "../../../helpers/props";
import NavBar from "../../../components/navigation/NavBar";
import {useLocation} from "../../../routes/router-dom-wrapper/hooks";
import {useChecksController} from "../../../components/verification/verification-controller";
import {useNavStack} from "../../../atoms/nav-stack";

type Props = {}

export type TransferTimelineLocationState = {
  shouldOpenVerifications?: boolean
}

type PathKeys = "id"

const TimelinePage = withParametricRouter<Props, PathKeys>({
  requiredPathKeys: ["id"],
})(
  (props) => {
    const transferLoadedRef = React.useRef(false)
    const location = useLocation<TransferTimelineLocationState>()
    const navStack = useNavStack<{}, {}>()
    const checksController = useChecksController()
    return (
      <NavStackRoot<{}, PropsType<typeof TimelineStepMain>>
        root={TimelineStepMain}
        args={{
          transferId: props.id,
          onTimelineUpdated: location.state?.shouldOpenVerifications ? (timeline) => {
            if (transferLoadedRef.current) return
            transferLoadedRef.current = true
            void checksController(timeline.transfer.checks, {
              titleAlign: "center",
              sourceStackEntry: TimelineStepMain,
              onFinished: async () => {
                navStack.popUntil({component: TimelineStepMain, inclusive: false})
              }
            })
          } : undefined,
        }}
        wrapper={(navStack, children) => {
          return (
            <Limiter className={"flex-1"}>
              <NavBar/>
              {children}
            </Limiter>
          )
        }}
        ctx={{}}
      />
    )
  })

export default TimelinePage;