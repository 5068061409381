import React, {useState} from "react";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {useAuthApi, useAuthQuery} from "../../../api/auth";
import {useApi} from "../../../api/axios";
import {useBusinessOnboardingQuery, useOnboardingQuery} from "../../../api/onboarding";
import {Referral, UserInfoResponse} from "../../../api/whales/Auth";
import {useAuthActions, User} from "../../../atoms/auth";
import ButtonBar from "../../../components/ui/ButtonBar";
import {Button, InlineButton} from "../../../components/ui/Buttons";
import {EmailInput, Form, InputGroup, PasswordInput} from "../../../components/ui/input/Inputs";
import {useAsyncLoading} from "../../../helpers/hooks";
import {useAlertStack} from "../../../providers/alert-stack";
import {AdService} from "../../../services/ad-service";
import CountryPicker from "../../../components/ui/CountryPicker";
import Caption from "../../../components/ui/Caption";
import {useStrings} from "../../../strings";
import {TrackingServiceManager} from "../../../services/tracking/TrackingService";

type Props = {
  userType: "business" | "individual"
}

type SignUpFormState = {
  countryCode: string | null,
  email: string,
  password: string
}

const SignUpForm = (props: Props) => {
  const strings = useStrings()
  const authActions = useAuthActions();
  const onboardingQuery = useOnboardingQuery()
  const businessOnboardingQuery = useBusinessOnboardingQuery()
  const api = useApi()
  const authApi = useAuthApi()
  const authQuery = useAuthQuery()
  const errorStack = useAlertStack()

  const [state, setState] = useState<SignUpFormState>({
    countryCode: null,
    email: "",
    password: ""
  })

  const {isLoading, asyncWithLoading} = useAsyncLoading(false)

  const {data: countryList} = onboardingQuery.countryList.useQuery(props.userType, {
    onError: errorStack.showError
  })

  const useEmailValidate = authQuery.email.validate.useMutation()
  const onValidateEmail = (email: string) => {
    return useEmailValidate.mutateAsync({email})
  }

  const usePasswordValidate = authQuery.password.validate.useMutation()
  const onValidatePassword = (password: string) => {
    return usePasswordValidate.mutateAsync({password})
  }

  const {executeRecaptcha} = useGoogleReCaptcha();
  const onRegister = () => asyncWithLoading((() => {
    const fetch = async () => {
      // await sleep(10000000)
      try {
        await Promise.all([
          onValidateEmail(state.email),
          onValidatePassword(state.password)
        ])
      } catch (_) {
        return
      }

      if (!executeRecaptcha) {
        throw Error("reCaptcha is not available")
      }
      const referral = async (): Promise<Referral | undefined> => {
        const branchReferral = await (async (): Promise<Referral | undefined> => {
          const branchData = await TrackingServiceManager.instance.branch.data()
          if (!branchData || !branchData.data_parsed) {
            return undefined
          }

          const code = branchData.data_parsed["code"] as string | undefined
          if (!code) {
            return undefined
          }

          const metadata = Object.fromEntries(
            Object.entries(branchData?.data_parsed || {})
              .filter((([k, _]) => k.startsWith("am")))
          )

          return {code, metadata}
        })()

        if (branchReferral) {
          return branchReferral
        }

        const adReferrer = AdService.referralData()
        if (adReferrer) {
          return adReferrer
        }

        return undefined
      }

      const res = await authApi.register(props.userType, {
        country: state.countryCode || "",
        email: state.email,
        password: state.password,
        challenge: await executeRecaptcha("SIGNUP"),
        referral: await referral()
      })

      if (!res.payload.tokens) {
        throw Error("tokens are empty")
      }
      const token = res.payload.tokens

      const userRes = await api.get<UserInfoResponse>("/auth/v1/user/info/get", {}, {
        "Authorization": `Bearer ${token.accessToken}`
      })

      if (userRes.payload.userType === "business") {
        await businessOnboardingQuery.application.get.preFetch(userRes.payload.userId, token.accessToken)
      } else {
        await onboardingQuery.application.get.preFetch(userRes.payload.userId, token.accessToken)
      }

      authActions.login(token, new User(userRes.payload.userId, userRes.payload.email, userRes.payload.region, userRes.payload.countryCode, userRes.payload.userType))
    }

    return () => fetch().catch(errorStack.showError)
  })())

  return (
    <Form onSubmit={onRegister}>
      <InputGroup>
        <CountryPicker
          countryCodes={countryList?.map(country => country.countryCode)}
          disabled={isLoading}
          placeholder={props.userType === "individual" ?
            strings["signup.country.placeholder.individual"] :
            strings["signup.country.placeholder.business"]
          }
          value={state.countryCode}
          error={undefined}
          onChange={(newValue: string | null) => {
            setState((old) => ({...old, countryCode: newValue}))
          }}
        />
        <EmailInput
          placeholder={strings["signup.email.placeholder"]}
          disabled={isLoading}
          value={state.email}
          onValidate={onValidateEmail}
          error={useEmailValidate.error}
          onChange={(newValue) => {
            setState((old) => ({...old, email: newValue}))
          }}
        />
        <PasswordInput
          passwordKind="new"
          placeholder={strings["signup.password.placeholder.long"]}
          disabled={isLoading}
          value={state.password}
          onValidate={onValidatePassword}
          error={usePasswordValidate.error}
          onChange={(newValue) => {
            setState((old) => ({...old, password: newValue}))
          }}
        />
      </InputGroup>
      <ButtonBar className={"gap-5"} align={"center"}>
        <Button type="submit" title={strings["general.continue"]} size={"max"} color={"primary-black"}
                loading={isLoading}/>
        <InlineButton className={"mt-[20px]"} type={"link"} href="/sign-in"
                      title={<Caption size={"3-title"} text={strings["signup.to_log_in"]}/>}
        />
      </ButtonBar>
    </Form>
  )
}

export default SignUpForm;