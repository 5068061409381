import {useMutation, useQuery, useQueryClient} from "react-query";
import {Api, useApi} from "./axios";
import * as ClientModels from "./whales/Client";
import {
  ClientInfoResponse,
  ClientPhoneConfirmRequest, ClientPhoneResendCodeRequest, ClientPhoneResendCodeResponse,
  ClientPhoneUpdateRequest,
  ClientPhoneUpdateResponse, DisclosureResponse,
  V2ClientInfoResponse
} from "./whales/Client";
import {SingleSelectValue} from "./whales/Interface-template";
import {ClientInfo} from "../models/client/client-info";
import {Disclosure, DisclosureKind} from "./whales/Common";

export class ClientApi {
  constructor(private api: Api) {
  }

  questionnaireAnswer(id: string, req: { value: SingleSelectValue }): Promise<{}> {
    return this.api.post(`/client/v1/questionnaire/answer?id=${id}`, req)
  }

  clientInfo(): Promise<ClientInfo> {
    return this.api.get<V2ClientInfoResponse>(`/client/v2/info/get`).then((response) => {
      return ClientInfo.fromApi(response.payload)
    })
  }

  phoneUpdate(phone: string): Promise<ClientPhoneUpdateResponse['payload']> {
    const req: ClientPhoneUpdateRequest = {
      phone: phone
    }
    return this.api.post<ClientPhoneUpdateResponse, ClientPhoneUpdateRequest>(`/client/v1/info/phone/update`, req)
      .then(r => r.payload)
  }

  phoneConfirm(req: ClientPhoneConfirmRequest): Promise<ClientModels.ClientInfo> {
    return this.api.post<ClientInfoResponse, ClientPhoneConfirmRequest>(`/client/v1/info/phone/confirm`, req)
      .then(r => r.payload)
  }

  phoneResendCode(ticket: string): Promise<ClientPhoneResendCodeResponse> {
    return this.api.post<ClientPhoneResendCodeResponse, ClientPhoneResendCodeRequest>(`/client/v1/info/phone/resend-code`, {ticket})
  }

  getDisclosure(kind: DisclosureKind): Promise<Disclosure> {
    return this.api.get<DisclosureResponse>(`/client/v1/disclosure`, {kind})
      .then(r => r.payload)
  }
}

export function useClientApi() {
  return new ClientApi(useApi())
}

const queryKeys = {
  client: {
    disclosure: (kind: DisclosureKind) => ["client.disclosure", kind],
    info: {
      get: "client.info.get",
      phone: {
        update: "client.info.phone.update",
        confirm: "client.info.phone.confirm",
        resendCode: "client.info.phone.resendCode",
      }
    },
    v2: {
      info: {
        get: "client.v2.info.get",
      }
    }
  }
};

export function useClientQuery() {
  const api = useClientApi();
  const queryClient = useQueryClient();
  return {
    info: {
      get: {
        useQuery: () => useQuery(queryKeys.client.info.get, () => api.clientInfo()),
        preFetch: () => queryClient.prefetchQuery(queryKeys.client.info.get, () => api.clientInfo()),
      },
      disclosure: {
        useQuery: (kind: DisclosureKind) => useQuery(queryKeys.client.disclosure(kind), () => api.getDisclosure(kind)),
      },
      phone: {
        update: {
          useMutation: () => useMutation(
            queryKeys.client.info.phone.update,
            (phone: string) => api.phoneUpdate(phone)
          )
        },
        confirm: {
          useMutation: () => useMutation(
            queryKeys.client.info.phone.confirm,
            (req: ClientPhoneConfirmRequest) => api.phoneConfirm(req),
            {
              onSettled: async () => {
                await queryClient.invalidateQueries([queryKeys.client.info.get, queryKeys.client.v2.info.get])
              },
            }
          )
        },
        resendCode: {
          useMutation: () => useMutation(
            queryKeys.client.info.phone.resendCode,
            (req: {ticket: string}) => api.phoneResendCode(req.ticket)
          )
        },
      }
    },
    v2: {
      info: {
        useQuery: () => useQuery(queryKeys.client.v2.info.get, () => api.clientInfo()),
      },
    }
  }
}