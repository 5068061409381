import {FbqService} from "./FbqService";
import {GtagService} from "./GtagService";
import {BranchService} from "./BranchService";
import {BuildConfig} from "build-config";

export interface TrackingService {
  init(): void;
  grantConsent(): void;
}

export class TrackingServiceManager {
  fbq?: FbqService
  gtag?: GtagService
  branch: BranchService
  private services: TrackingService[]
  constructor() {
    // disable fba and gtag for now
    // this.fbq = new FbqService()
    // this.gtag = new GtagService()
    this.branch = new BranchService(BuildConfig.branchKey)
    this.services = []
    if (this.fbq) {
      this.services.push(this.fbq)
    }
    if (this.gtag) {
      this.services.push(this.gtag)
    }
    if (this.branch) {
      this.services.push(this.branch)
    }
  }

  init() {
    this.services.forEach(service => service.init());
  }

  grantConsent() {
    this.services.forEach(service => service.grantConsent());
  }

  onSignUpComplete() {
    this.fbq?.track("CompleteRegistration")
    this.gtag?.event("sign_up")
  }

  static instance = new TrackingServiceManager()
}