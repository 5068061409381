import React from "react";
import {atom, useRecoilValue, useSetRecoilState} from "recoil";

type State = {
  key?: string
  content?: React.ReactNode
}

const contentScreenLeft = atom<State>({
  key: "contextScreen.left",
  default: {
    key: undefined,
    content: undefined
  },
})

const contentScreenRight = atom<State>({
  key: "contextScreen.right",
  default: {
    key: undefined,
    content: undefined
  },
})

export const useLeftColumnValue = () => {
  return useRecoilValue(contentScreenLeft).content
}

export const useRightColumnValue = () => {
  return useRecoilValue(contentScreenRight).content
}

export const useContentScreenState = () => {
  const left = useSetRecoilState(contentScreenLeft)
  const right = useSetRecoilState(contentScreenRight)

  const f = (setter: typeof left) => (key: string, newValue?: React.ReactNode) => {
    setter((prev) => {
      if (prev.key !== undefined && prev.key !== key) {
        console.error("ContentScreen: key mismatch", prev.key, key)
      }
      return {
        ...prev,
        key: newValue ? key : undefined,
        content: newValue
      }
    })
  }

  return {
    setLeft: f(left),
    setRight: f(right)
  }
}
