import {ReactComponent as CheckmarkSvg} from "./svg/transfer-timeline-icon-checkmark.svg"
import {ReactComponent as ArrowSvg} from "./svg/transfer-timeline-icon-arrow.svg"
import {ReactComponent as FailedSvg} from "./svg/transfer-timeline-icon-failed.svg"
import {ReactComponent as WaitSvg} from "./svg/transfer-timeline-icon-wait.svg"
import cx from "classnames";
import {PropsClassName} from "../../../helpers/props";
import "./TimelineIcon.scss"
import {SpinnerIOS} from "./Spinner";
import React from "react";
import Skeleton from "../Skeleton";


type Color = "primary" | "secondary"

type Props = ({
  name: "arrow" | "checkmark" | "failed" | "wait" | "spinner"
  foregroundColor: Color
  backgroundColor: Color
} & PropsClassName) | ({ name: "skeleton" } & PropsClassName)

const TimelineIcon = (props: Props) => {
  const name = props.name
  const foregroundColor = props.name === "skeleton" ? undefined : props.foregroundColor
  const backgroundColor = props.name === "skeleton" ? undefined : props.backgroundColor
  const className = cx({
      "timeline-icon": true,
      [`timeline-icon--${foregroundColor}`]: foregroundColor,
      [`timeline-icon--bg-${backgroundColor}`]: backgroundColor,
      "timeline-icon--spinner": name === "spinner",
    },
    props.className
  )
  switch (name) {
    case "skeleton":
      return (
        <div className={"relative w-8 h-8"}>
          <Skeleton circle={true}/>
        </div>
      )
    case "checkmark":
      return <CheckmarkSvg className={className}/>
    case "arrow":
      return <ArrowSvg className={className}/>
    case "failed":
      return <FailedSvg className={className}/>
    case "wait":
      return <WaitSvg className={className}/>
    case "spinner":
      return (
        <div className={cx(className, "relative w-8 h-8 rounded-full border-2")}>
          <SpinnerIOS className={"absolute left-[calc(50%-8px)] top-[calc(50%-8px)]"} size={16}/>
        </div>
      )
  }
}

export default TimelineIcon