import {useStrings} from "../../strings";
import {useClientQuery} from "../../api/client";
import {useNotificationsQuery} from "../../api/notifications";
import {Section} from "../../components/ui/collections/Section";
import BusinessInfoRows from "./BuisnessInfoRows";
import PersonInfoRows from "./PersonInfoRows";
import Toggle from "../../components/ui/Toggle";
import {TitleSubtitleLeftScreen} from "../../components/ui/screen/TitleSubtitleScreen";
import {WithLearnMoreSuffix} from "../../components/ui/LearnMore";
import {Links} from "../../helpers/Links";
import {InlineButton} from "../../components/ui/Buttons";
import {useSettingsController} from "./controller";
import {useAuthorizedAuthValue} from "../../atoms/auth";

const SettingsStepMain = () => {
  const strings = useStrings()
  const controller = useSettingsController()
  const authorizedAuthValue = useAuthorizedAuthValue()

  const clientQuery = useClientQuery()
  const notificationsQuery = useNotificationsQuery()

  const {data: clientInfo, refetch} = clientQuery.v2.info.useQuery()
  const typed = clientInfo?.typed

  const {data: subscriptionList} = notificationsQuery.subscriptions.list.useQuery()
  const updateSubscription = notificationsQuery.subscriptions.marketing.update.useMutation()


  const isLoading = clientInfo === undefined || subscriptionList === undefined

  const businessSection = typed?.kind === "business" && (
    <Section title={strings["account.business_info"]}>
      <BusinessInfoRows businessInfo={typed.business.info}/>
    </Section>
  )

  const personalSection = function () {
    const personalInfo = clientInfo?.personalInfo()

    return (
      <Section
        isLoading={isLoading}
        title={strings["account.personal_info"]}
      >
        <PersonInfoRows
          isLoading={isLoading}
          person={personalInfo}
          phoneActionButton={function () {
            return (
              <InlineButton
                type={"button"}
                title={strings["general.edit"]}
                onClickAsync={() => controller.onEditPhone({
                  userCountryCode: authorizedAuthValue.user.countryCode,
                  onComplete: async () => {
                    await refetch()
                  }
                })}
              />
            )
          }()}
        />
      </Section>
    )
  }()

  const marketingEmailsSection = (
    <Section
      isLoading={isLoading}
      title={strings["marketing_emails.title"]}
      subtitle={
        <WithLearnMoreSuffix
          url={Links.helpCenter.articles.marketingEmails}
          children={strings["marketing_emails.subtitle"]}
        />
      }
      actionButton={
        <Toggle
          checked={function () {
            const mutationChecked = updateSubscription.variables
            if (updateSubscription.isLoading && mutationChecked !== undefined) {
              return mutationChecked
            }

            if (!subscriptionList) {
              return undefined
            }

            return subscriptionList.find(sub => sub.channel === "email" && sub.type === "marketing")?.status === "active"
          }()
          }
          onChange={async (checked) => {
            return updateSubscription.mutateAsync(checked)
          }}
          isLoading={isLoading}
        />
      }
    />
  )

  return (
    <TitleSubtitleLeftScreen
      title={strings["sidebar.items.settings"]}
    >
      {businessSection}
      {personalSection}
      {marketingEmailsSection}
    </TitleSubtitleLeftScreen>
  )
}

export default SettingsStepMain;