import React, {ReactNode, useRef, useState} from "react";
import {Button} from "../../../ui/Buttons";
import {PropsClassName, PropsOnComplete} from "../../../../helpers/props";
import {InterfaceTemplates, InterfaceTemplateValues} from "../domain";
import {TitleSubtitleScreen} from "../../../ui/screen/TitleSubtitleScreen";
import {BasicForm, InputGroup} from "../../../ui/input/Inputs";
import {useAsyncLoading} from "../../../../helpers/hooks";
import {useStrings} from "../../../../strings";
import Collapsable from "../../../ui/Collapsable";
import {InterfaceTemplateTextInput} from "../inputs/InterfaceTemplateTextInput";
import SelectRow from "./SelectRow";
import {LimiterOverflow} from "../../../ui/Limiter";

const EmploymentRow = ({option, template, ...props}: {
  template: InterfaceTemplates.Employment,
  option: InterfaceTemplateValues.SingleSelectOption
  selected: boolean
  onSelect: (option: InterfaceTemplateValues.SingleSelectOption | null) => void

  occupationValue: InterfaceTemplateValues.Text
  setOccupationValue: (value: InterfaceTemplateValues.Text) => void

  employerValue: InterfaceTemplateValues.Text
  setEmployerValue: (value: InterfaceTemplateValues.Text) => void

  otherValue: InterfaceTemplateValues.Text
  setOtherValue: (value: InterfaceTemplateValues.Text) => void

  isLoading: boolean
  onSubmit: (option: InterfaceTemplateValues.SingleSelectOption) => Promise<void>
}) => {
  const strings = useStrings()
  const firstInputRef = useRef<HTMLInputElement>(null)
  const withRef = (f: (ref?: React.MutableRefObject<HTMLInputElement | null>) => ReactNode) => (isFirst?: boolean) => {
    return f(isFirst ? firstInputRef : undefined)
  }

  const occupationTemplate = template.occupationTemplate
  const employerTemplate = template.employerTemplate
  const otherTemplate = template.statusTemplate.otherTextEntry

  const bottomNodeContent = function () {
    const occupationNode = (option.isSelfEmployed || option.isEmployed) && withRef(ref =>
      <InputGroup key={"occupation"} name={occupationTemplate.subtitle}>
        <InterfaceTemplateTextInput
          ref={ref}
          capitalization={"sentences"}
          template={occupationTemplate}
          value={props.occupationValue}
          onChange={props.setOccupationValue}
          disabled={props.isLoading}
          error={undefined}
        />
      </InputGroup>
    )
    const employerNode = option.isEmployed && withRef(ref =>
      <InputGroup key={"employer"} name={employerTemplate.subtitle}>
        <InterfaceTemplateTextInput
          ref={ref}
          capitalization={"words"}
          template={employerTemplate}
          value={props.employerValue}
          onChange={props.setEmployerValue}
          disabled={props.isLoading}
          error={undefined}
        />
      </InputGroup>
    )

    const otherNode = option.isOther && otherTemplate && withRef(ref =>
      <InterfaceTemplateTextInput
        key={"other"}
        capitalization={"words"}
        ref={ref}
        template={otherTemplate}
        disabled={props.isLoading}
        value={props.otherValue}
        onChange={props.setOtherValue}
        error={undefined}
      />
    )

    if (!occupationNode && !employerNode && !otherNode) {
      return null
    }

    const list = [
      occupationNode,
      employerNode,
      otherNode,
    ].flatMap((e, index) => {
      if (!e) {
        return []
      }

      return [e(index === 0)]
    })

    return (
      <div className={"pt-3"}>
        {list}

        <Button
          className={"mt-[32px] mx-auto"}
          type={"submit"}
          loading={props.isLoading}
          stopPropagation={true}
          title={strings["general.continue"]}
          size={"big"}
          color={"primary-black"}
        />
      </div>
    )
  }()

  const selectedBottomNode = bottomNodeContent && (
    <Collapsable expanded={props.selected} onExpanded={() => firstInputRef.current?.focus()}>
      <div className={"pt-3"}>
        {bottomNodeContent}
      </div>
    </Collapsable>
  )

  return (
    <SelectRow
      key={option.id}
      selected={props.selected}
      rightIcon={function () {
        if (props.selected && bottomNodeContent != null) {
          return "close"
        }
        return "chevron-right"
      }()}
      option={option}
      disabled={props.isLoading}
      bottomNode={selectedBottomNode}
      submitOnClick={selectedBottomNode === null}
      onRowClick={(newOption) => props.onSelect(newOption)}
      onSubmit={() => props.onSubmit(option)}
    />
  )
}

type Props = {
  titleAlign: "left" | "center"
  template: InterfaceTemplates.Employment,
} & PropsClassName & PropsOnComplete<InterfaceTemplateValues.Employment>

const InterfaceTemplateEmploymentScreen = ({className, template, onComplete, ...props}: Props) => {
  const [selectedId, setSelectedId] = useState<string | null>()
  const asyncWithLoading = useAsyncLoading(false)

  const [occupationValue, setOccupationValue] = useState<InterfaceTemplateValues.Text>({
    kind: "text",
    textValue: "",
  })

  const [employerValue, setEmployerValue] = useState<InterfaceTemplateValues.Text>({
    kind: "text",
    textValue: "",
  })

  const [otherValue, setOtherValue] = useState<InterfaceTemplateValues.Text>({
    kind: "text",
    textValue: "",
  })


  const onSubmit = async (passedOption?: InterfaceTemplateValues.SingleSelectOption) => {
    const selectedOption = passedOption ?? template.statusTemplate.options.find((option) => option.id === selectedId)
    if (selectedOption === undefined) {
      throw Error("No selected option (not sure how possible)")
    }

    return asyncWithLoading.asyncWithLoading(() => onComplete({
      kind: "employment",
      employmentValue: {
        status: {
          kind: "singleSelect",
          singleSelectValue: {
            option: selectedOption,
            otherText: selectedOption.isOther ? otherValue.textValue : undefined
          }
        },
        occupation: occupationValue.textValue,
        employer: selectedOption.isSelfEmployed ? "Self" : employerValue.textValue,
      },
    }))
  }

  return (
    <TitleSubtitleScreen
      titleAlign={props.titleAlign}
      className={className}
      title={template.statusTemplate.title}
      subtitle={template.statusTemplate.subtitle}
    >
      <LimiterOverflow overflow={"16px"}>
        <BasicForm onSubmit={() => onSubmit()}>
          {template.statusTemplate.options.map((option) =>
            <EmploymentRow
              key={option.id}
              template={template}
              option={option}
              setOtherValue={setOtherValue}
              selected={option.id === selectedId}
              onSelect={(newOption) => setSelectedId(newOption?.id ?? null)}
              isLoading={asyncWithLoading.isLoading}
              onSubmit={onSubmit}

              occupationValue={occupationValue}
              setOccupationValue={setOccupationValue}
              employerValue={employerValue}
              setEmployerValue={setEmployerValue}
              otherValue={otherValue}
            />
          )}
        </BasicForm>
      </LimiterOverflow>
    </TitleSubtitleScreen>
  )
}

export default InterfaceTemplateEmploymentScreen