import {Address} from "../address";

export interface IPerson {
  firstName: string;
  lastName: string;
  birthdate?: Date;
  address?: Address;
  phone?: string;
  email?: string;
}

export abstract class Person implements IPerson {
  firstName: string;
  lastName: string;
  birthdate?: Date;
  address?: Address

  phone?: string;
  email?: string;

  protected constructor(other: IPerson) {
    this.firstName = other.firstName;
    this.lastName = other.lastName;
    this.birthdate = other.birthdate;
    this.address = other.address;
    this.phone = other.phone;
    this.email = other.email;
  }

  name(): string {
    return `${this.firstName} ${this.lastName}`;
  }
}
