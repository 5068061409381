import Decimal from "decimal.js";
import {Money} from "../helpers/money";
import _ from "lodash";
import {TransfersApi, useTransfersApi} from "../api/transfers";
import {useWiseApi, WiseApi} from "../api/wise";

type BootstrapInfo = {
    availableCurrencies: {
        src: string[]
        dst: string[]
    }
    defaultQuote: TransferQuote
}

export type TransferProvider = "atlantic" | "wise"

export type TransferQuote = {
    transferFee: Money
    exchangeRate: Decimal
    sourceMoney: Money
    destinationMoney: Money
}

export type EstimateRequest = {
    entryMode: "source" | "destination"
    amount: Decimal
    srcCurrencyCode: string
    dstCurrencyCode: string
}

class CompareService {
    constructor(private transfersApi: TransfersApi, private wiseApi: WiseApi) {
    }

    static availableProviders(): TransferProvider[] {
        return ["atlantic", "wise"]
    }

    static providerName(provider: TransferProvider): string {
        return {
            "atlantic": "Atlantic Money",
            "wise": "Wise"
        }[provider]
    }

    bootstrapInfo(): Promise<BootstrapInfo> {
        return this.transfersApi.publicEstimateBootstrapInfo().then(res => {
            if (!res.payload) {
                throw Error("payload is undefined")
            }

            if (!res.payload.availableCurrencies) {
                throw Error("payload.availableCurrencies is undefined")
            }

            if (!res.payload.defaultEstimate) {
                throw Error("payload.defaultEstimate is undefined")
            }

            return {
                availableCurrencies: {
                    src: res.payload.availableCurrencies.sourceCurrencies.map((v) => v.code),
                    dst: res.payload.availableCurrencies.destinationCurrencies.map((v) => v.code)
                },
                defaultQuote: {
                    sourceMoney: Money.fromApi(res.payload.defaultEstimate.sourceMoney),
                    destinationMoney: Money.fromApi(res.payload.defaultEstimate.destinationMoney),
                    exchangeRate: new Decimal(res.payload.defaultEstimate.quote.rate),
                    transferFee: Money.fromApi(res.payload.defaultEstimate.totalFee)
                }
            }
        })
    }

    estimate(provider: TransferProvider, req: EstimateRequest): Promise<TransferQuote> {
        switch (provider) {
            case "atlantic":
                return this.atlanticQuote(req)
            case "wise":
                return this.wiseQuote(req)
        }
    }

    private atlanticQuote(req: EstimateRequest): Promise<TransferQuote> {
        return this.transfersApi.publicEstimate({
            amount: req.amount.toFixed(),
            entryMode: req.entryMode,
            sourceCurrencyCode: req.srcCurrencyCode,
            destinationCurrencyCode: req.dstCurrencyCode
        }).then(res => {
            if (!res.payload) {
                throw Error("payload is undefined")
            }
            return {
                transferFee: Money.fromApi(res.payload.totalFee),
                exchangeRate: new Decimal(res.payload.quote.rate),
                sourceMoney: Money.fromApi(res.payload.sourceMoney),
                destinationMoney: Money.fromApi(res.payload.destinationMoney)
            }
        })
    }

    private async wiseQuote(req: EstimateRequest): Promise<TransferQuote> {
        const res = await this.wiseApi.quotesV2({
            sourceAmount: req.amount.toNumber(),
            sourceCurrency: req.srcCurrencyCode,
            targetCurrency: req.dstCurrencyCode
        })

        const bestOption = _.maxBy(res.paymentOptions, option => option.targetAmount)
        if (bestOption === undefined) {
            throw new Error("wise option is undefined")
        }

        return {
            exchangeRate: new Decimal(res.rate),
            transferFee: Money.default(new Decimal(bestOption.fee.total), req.srcCurrencyCode),
            sourceMoney: Money.default(new Decimal(bestOption.sourceAmount), bestOption.sourceCurrency),
            destinationMoney: Money.default(new Decimal(bestOption.targetAmount), bestOption.targetCurrency)
        }
    }
}

export const useCompareService = () => {
    const transfersApi = useTransfersApi()
    const wiseApi = useWiseApi()

    return new CompareService(transfersApi, wiseApi)
}

export default CompareService