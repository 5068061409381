import React, {useRef, useState} from "react";
import {Button} from "../../../ui/Buttons";
import {useStrings} from "../../../../strings";
import {PropsClassName, PropsOnComplete} from "../../../../helpers/props";
import {InterfaceTemplates, InterfaceTemplateValues} from "../domain";
import {TitleSubtitleScreen} from "../../../ui/screen/TitleSubtitleScreen";
import {LimiterOverflow} from "../../../ui/Limiter";
import {BasicForm} from "../../../ui/input/Inputs";
import {useAsyncLoading} from "../../../../helpers/hooks";
import _ from "lodash";
import ButtonBar from "../../../ui/ButtonBar";
import SelectRow from "./SelectRow";
import Collapsable from "../../../ui/Collapsable";
import {InterfaceTemplateTextInput} from "../inputs/InterfaceTemplateTextInput";
import SingleSelectOption = InterfaceTemplateValues.SingleSelectOption;


const MultiSelectRow = ({option, template, ...props}: {
  template: InterfaceTemplates.SingleSelect,
  option: InterfaceTemplateValues.SingleSelectOption
  otherValue: InterfaceTemplateValues.Text
  onChangeOtherValue: (value: InterfaceTemplateValues.Text) => void
  selected: boolean
  onSelect: (option: InterfaceTemplateValues.SingleSelectOption | null) => void
  isLoading: boolean
  onSubmit: () => Promise<void>
}) => {
  const otherInputRef = useRef<HTMLInputElement>(null)
  const otherTemplate = template.otherTextEntry

  const selectedBottomNode = option.isOther && otherTemplate && (
    <Collapsable expanded={props.selected} onExpanded={() => otherInputRef.current?.focus()}>
      <div className={"pt-3"}>
        <InterfaceTemplateTextInput
          ref={otherInputRef}
          template={otherTemplate}
          disabled={props.isLoading}
          value={props.otherValue}
          onChange={props.onChangeOtherValue}
          error={undefined}
        />
      </div>
    </Collapsable>
  )

  return (
    <SelectRow
      key={option.id}
      selected={props.selected}
      option={option}
      rightIcon={"check"}
      disabled={props.isLoading}
      bottomNode={selectedBottomNode}
      submitOnClick={false}
      onRowClick={(newOption) => props.onSelect(newOption)}
      onSubmit={props.onSubmit}
    />
  )
}

type Props = {
  titleAlign: "left" | "center"
  template: InterfaceTemplates.MultiSelect,
} & PropsClassName & PropsOnComplete<InterfaceTemplateValues.MultiSelect>

const InterfaceTemplateMultiSelectScreen = ({className, template, onComplete, ...props}: Props) => {
  const strings = useStrings()
  const [selectedOptions, setSelectedOptions] = useState<SingleSelectOption[]>([])
  const [otherText, setOtherText] = useState<InterfaceTemplateValues.Text>({kind: "text", textValue: ""})
  const asyncWithLoading = useAsyncLoading(false)

  const onSubmit = async () => {
    return asyncWithLoading.asyncWithLoading(() => onComplete({
      kind: "multiSelect",
      multiSelectValue: {
        options: selectedOptions,
        otherText: !!_.find(selectedOptions, o => o.isOther)
          ? otherText.textValue : undefined,
      }
    }))
  }

  return (
    <TitleSubtitleScreen
      titleAlign={props.titleAlign}
      className={className}
      title={template.title}
      subtitle={template.subtitle}
    >
      <BasicForm
        onSubmit={onSubmit}>
        <LimiterOverflow overflow={"16px"}>
          {template.options.map((option) =>
            <MultiSelectRow
              key={option.id}
              template={template}
              option={option}
              otherValue={otherText}
              onChangeOtherValue={setOtherText}
              selected={!!selectedOptions.find(o => o.id === option.id)}
              onSelect={() => setSelectedOptions(prev => {
                const exists = !!prev.find(o => o.id === option.id)
                if (exists) {
                  return prev.filter(o => o.id !== option.id)
                } else {
                  return [...prev, option]
                }
              })}
              isLoading={asyncWithLoading.isLoading}
              onSubmit={onSubmit}
            />
          )}
        </LimiterOverflow>
        <ButtonBar sticky={"bottom"}>
          <Button className={"mt-[32px]"}
                  type={"submit"}
                  loading={asyncWithLoading.isLoading}
                  title={strings["general.continue"]}
                  size={"big"}
                  color={"primary-black"}
          />
        </ButtonBar>
      </BasicForm>
    </TitleSubtitleScreen>
  )
}

export default InterfaceTemplateMultiSelectScreen