import cx from "classnames";
import React from "react";
import {PropsClassName} from "../../helpers/props";
import {Small} from "./Labels";
import "./ButtonBar.scss"

type Props = {
  children: React.ReactNode,
  align?: "start" | "center" | "end",
  sticky?: "top" | "bottom"
  pushDown?: boolean
  buttonCompanion?: React.ReactNode
} & PropsClassName

const ButtonBar = ({sticky, align = "center", ...props}: Props) => {
  return (
    <div className={cx("button-bar", props.className, `items-${align}`,
      {
        "sticky pb-[40px] pt-[24px] bg-secondary": sticky,
        "mt-auto": props.pushDown ?? false,
        "top-0": sticky === "top",
        "bottom-0": sticky === "bottom"
      }
    )}>
      {props.buttonCompanion && (
        <div className={"pb-3"}>
          <Small color={"primary-50"} text={props.buttonCompanion}/>
        </div>
      )}
      {props.children}
    </div>
  );
}

export default ButtonBar;