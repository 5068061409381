import {RecipientListStep} from "../../recipients/RecipientListPage";
import {PropsTransferContext, useTransferCreateController} from "./transfer-create-controller";
import {useUserDefaultsValue} from "../../../atoms/user-defaults";

const TransferRecipientStep = (
  props: PropsTransferContext
) => {
  const transferCreateController = useTransferCreateController()
  const userDefaults = useUserDefaultsValue()

  return (
    <RecipientListStep
      currency={props.transferContext.transferEstimate(userDefaults).destinationMoney.currency}
      onComplete={(recipient) => transferCreateController.onRecipientComplete(props.transferContext, recipient)}
    />
  )
}
export default TransferRecipientStep