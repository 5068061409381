import InformationScreen from "../../components/ui/screen/InformationScreen";
import Limiter from "../../components/ui/Limiter";
import {Button} from "../../components/ui/Buttons";
import {useStrings} from "../../strings";
import {Links} from "../../helpers/Links";
import {withParametricRouter} from "../../routes/parameteric-router";
import AtlanticQrCode from "../../components/ui/AtlanticQrCode";

const DeeplinkOpenInAppPage = withParametricRouter({requiredQueryKeys: ["original-url"]})((props) => {
  const strings = useStrings()

  return (
    <Limiter className={"min-h-[var(--100vh)]"}>
      <InformationScreen
        title={strings["deeplink.open_in_app.title"]}
        subtitle={strings["deeplink.open_in_app.subtitle"]}
        media={<AtlanticQrCode className={"mb-4"} size={"big"} url={props["original-url"]}/>}
        primaryButton={(innerProps) =>
          <Button
            {...innerProps}
            type={"link"}
            title={strings["general.go_home"]}
            href={Links.atlantic.home}
          />
        }
      />
    </Limiter>
  )
})

export default DeeplinkOpenInAppPage