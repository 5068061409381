import {TransferContext} from "../../../models/transfers/TransferContext";
import TransferRecipientStep from "./TransferRecipientStep";
import {useNavStack} from "../../../atoms/nav-stack";
import {useRecipientsApi} from "../../../api/recipients";
import TransferQuoteOptionStep from "./TransferQuoteOptionStep";
import TransferReviewStep from "./TransferReviewStep";
import {useTransfersApi, useTransfersQuery} from "../../../api/transfers";
import {Links} from "../../../helpers/Links";
import {DeliveryOption, QuoteOption} from "../../../models/transfers/Estimate";
import TransferDeliveryOptionStep from "./TransferDeliveryOptionStep";
import {Recipient} from "../../../components/domain/recipients/domain";
import {useUserDefaultsValue} from "../../../atoms/user-defaults";
import {useEstimateInputState} from "../../../atoms/estimate-input";
import {Transfer} from "../../../models/transfers/Transfer";
import {Check, EntityChecksExt} from "../../../components/domain/common/check";
import {useNavigate} from "../../../routes/router-dom-wrapper/hooks";
import {TransferTimelineLocationState} from "../timeline/TimelinePage";

export type PropsTransferContext = {
  transferContext: TransferContext
}

export const useTransferNavStack = useNavStack<{}, {}>

export const useTransferCreateController = () => {
  const navigate = useNavigate()
  const navStack = useTransferNavStack()
  const [_, setEstimateInput] = useEstimateInputState()

  const recipientsApi = useRecipientsApi()
  const transfersApi = useTransfersApi()
  const transferQuery = useTransfersQuery()
  const create = transferQuery.create.useMutation()

  const userDefaults = useUserDefaultsValue()

  const onConfirm = async (transferContext: TransferContext, acceptedChecks: Check[], reference?: string) => {
    transferContext = transferContext.with({reference: reference})
    const recipientId = transferContext.recipient?.recipientId
    if (recipientId === undefined) {
      return Promise.reject(new Error("onConfirm: Recipient is undefined"))
    }

    const estimate = transferContext.transferEstimate(userDefaults).toApi()
    estimate.checks = acceptedChecks.map(c => {
      console.log(c)
      return c.toApiEstimate()
    })
    const result = await create.mutateAsync({
      recipientId: recipientId,
      estimate: estimate,
      customerReference: transferContext.reference,
    })

    const transferId = result.payload?.singleTransferId
    if (transferId === undefined) {
      return Promise.reject(new Error("onConfirm: TransferId is undefined"))
    }

    setEstimateInput(prev => {
      return prev && {
        ...prev,
        enteredMoney: {...prev?.enteredMoney, amount: "empty"}
      }
    })

    const handleCreatedTransfer = (transferId: string): Promise<void> => {
      return transfersApi.pollTransfer(transferId, {
        intervalMs: 1000,
        maxDurationMs: 5000,
        pollCondition: (transfer: Transfer) => {
          if (!transfer.status.isInitiating()) return true

          return EntityChecksExt.isUserInputRequired(transfer.checks)
        },
      }).pollThen({
        onSatisfied: (transfer) => {
          navigate(Links.atlantic.transfer.timeline(transfer.transferId), {
            state: {
              shouldOpenVerifications: true
            } as TransferTimelineLocationState
          })
        },
        onExpire: (transfer) => {
          if (transfer === undefined) return handleCreatedTransfer(transferId)
          navigate(Links.atlantic.transfer.timeline(transfer.transferId))
        },
      })
    }

    await handleCreatedTransfer(transferId)
  }

  const onQuoteOptionComplete = async (transferContext: TransferContext, quoteOption?: QuoteOption) => {
    transferContext = transferContext.with({
      selectedQuoteOption: quoteOption,
      observeLastSelectedQuoteOptionKindPreferences: true
    })

    navStack.push(TransferReviewStep, {transferContext})
  }

  const onDeliveryOptionComplete = async (
    transferContext: TransferContext,
    deliveryOption?: DeliveryOption
  ) => {
    transferContext = transferContext.with({selectedDeliveryOption: deliveryOption})

    if (transferContext.quoteCandidateTransferEstimates.length === 1) {
      return onQuoteOptionComplete(transferContext, undefined)
    } else {
      navStack.push(TransferQuoteOptionStep, {transferContext})
      return
    }
  }

  const onRecipientComplete = async (transferContext: TransferContext, recipient: Recipient) => {
    transferContext = transferContext.with({recipient: recipient})

    if (transferContext.selectedDeliveryOption !== undefined) {
      return onDeliveryOptionComplete(transferContext, undefined)
    } else {
      navStack.push(TransferDeliveryOptionStep, {transferContext})
    }
  }

  const onEstimateComplete = async (transferContext: TransferContext) => {
    await transfersApi.estimateValidate(transferContext.transferEstimate(userDefaults))

    const recipients = await recipientsApi.recipientList()
    const filteredRecipients = recipients.filter(r =>
      r.bankAccount.currency.code === transferContext.displaying(userDefaults).destinationMoney.currency.code
    )
    navStack.push(TransferRecipientStep, {transferContext, recipients: filteredRecipients})
  }


  return {
    onEstimateComplete,
    onRecipientComplete,
    onDeliveryOptionComplete,
    onQuoteOptionComplete,
    onConfirm
  }
}