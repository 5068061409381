import Caption from "../../../components/ui/Caption";
import cx from "classnames";
import {Link} from "../../../components/ui/Link";
import React from "react";
import {useStrings} from "../../../strings";
import {Links} from "../../../helpers/Links";

type Props = {
  className?: string,
  userType: "business" | "individual"
}

const SwitcherElement = (props: { title: React.ReactNode, selected: boolean, navigateTo: string }) => {
  const {title, selected, navigateTo} = props

  return (
    <Link className={cx("sign-up-switcher__element", {
      "sign-up-switcher__element--selected": selected,
      "sign-up-switcher__element--unselected": !selected,
    })} href={navigateTo} newTab={false} fixOpacity={true}>
      <Caption size={"2-title"} text={title}/>
    </Link>
  )
}

const SignUpSwitcher = ({className, userType}: Props) => {
  const strings = useStrings()
  return (<div className={cx("sign-up-switcher", className)}>
    <SwitcherElement title={strings["signup.individual.title"]}
                     selected={userType === "individual"} navigateTo={Links.atlantic.auth.signUp.individual}/>
    <SwitcherElement title={strings["signup.business.title"]}
                     selected={userType === "business"} navigateTo={Links.atlantic.auth.signUp.business}/>
  </div>)
}

export default SignUpSwitcher;