import {DeliveryOption, EntryMode, Quote, QuoteOption} from "./Estimate";
import {Money} from "../../helpers/money";
import * as TransferApi from "../../api/whales/Transfer.v3";
import {Recipient} from "../../components/domain/recipients/domain";
import {TransferDisplaying} from "./TransferDisplaying";
import {TransferStatus} from "./TransferStatus";
import {Check} from "../../components/domain/common/check";
import {PayInDetails} from "../common/PayInDetails";

type RefundReason = TransferApi.SingleTransfer["refundReason"];

interface ITransfer {
  transferId: string;
  status: TransferStatus;

  destinationMoney: Money;
  sourceMoney: Money;
  fixedFee: Money;
  totalFee: Money;
  fixedFeeWithoutPromo: Money;

  quote: Quote;
  deliveryOption: DeliveryOption;
  quoteOption: QuoteOption;
  entryMode: EntryMode;
  recipient: Recipient;
  checks: Check[];
  payInDetails?: PayInDetails;
  /** Returns only for transfers in refund statuses */
  refundReason?: RefundReason
  /**
   * Available only after trade order had been filled
   * @format date
   */
  settleDate?: Date;
  reference: string;

  didCustomerChooseQuoteOption: boolean;
  isCancellable: boolean;
  isBlocking: boolean;
  isUserRefundable: boolean;

  createdAt: Date;
  updatedAt?: Date;
}


export class Transfer implements ITransfer {
  transferId: string;
  status: TransferStatus;

  destinationMoney: Money;
  sourceMoney: Money;
  fixedFee: Money;
  totalFee: Money;
  fixedFeeWithoutPromo: Money;

  quote: Quote;
  deliveryOption: DeliveryOption;
  quoteOption: QuoteOption;
  entryMode: EntryMode;
  recipient: Recipient;
  checks: Check[];
  payInDetails?: PayInDetails;
  refundReason?: RefundReason

  settleDate?: Date;
  reference: string;

  didCustomerChooseQuoteOption: boolean;
  isCancellable: boolean;
  isBlocking: boolean;
  isUserRefundable: boolean;

  createdAt: Date;
  updatedAt?: Date;

  constructor(other: ITransfer) {
    this.transferId = other.transferId;
    this.status = other.status;
    this.destinationMoney = other.destinationMoney;
    this.sourceMoney = other.sourceMoney;
    this.fixedFee = other.fixedFee;
    this.totalFee = other.totalFee;
    this.fixedFeeWithoutPromo = other.fixedFeeWithoutPromo;
    this.quote = other.quote;
    this.deliveryOption = other.deliveryOption;
    this.quoteOption = other.quoteOption;
    this.entryMode = other.entryMode;
    this.recipient = other.recipient;
    this.checks = other.checks;
    this.payInDetails = other.payInDetails;
    this.refundReason = other.refundReason;
    this.settleDate = other.settleDate;
    this.reference = other.reference;
    this.didCustomerChooseQuoteOption = other.didCustomerChooseQuoteOption;
    this.isCancellable = other.isCancellable;
    this.isBlocking = other.isBlocking;
    this.isUserRefundable = other.isUserRefundable;
    this.createdAt = other.createdAt;
    this.updatedAt = other.updatedAt;
  }

  static fromApi(other: TransferApi.SingleTransfer) {
    return new Transfer({
      transferId: other.singleTransferId,
      status: new TransferStatus(other.transferStatus),
      destinationMoney: Money.fromApi(other.destinationMoney),
      sourceMoney: Money.fromApi(other.sourceMoney),
      fixedFee: Money.fromApi(other.fixedFee),
      totalFee: Money.fromApi(other.totalFee),
      fixedFeeWithoutPromo: Money.fromApi(other.fixedFeeWithoutPromo),
      quote: Quote.fromApi(other.quote),
      deliveryOption: DeliveryOption.fromApi(other.deliveryOption),
      quoteOption: QuoteOption.fromApi(other.quoteOption),
      entryMode: other.entryMode,
      recipient: Recipient.fromApi(other.recipient),
      checks: other.checks?.map(c => Check.fromApiCommon(c)) ?? [],
      payInDetails: other.payInDetails && PayInDetails.fromApi(other.payInDetails),
      refundReason: other.refundReason,
      settleDate: other.settleDate ? new Date(other.settleDate) : undefined,
      reference: other.customerReference,
      didCustomerChooseQuoteOption: other.didCustomerChooseQuoteOption,
      isCancellable: other.isCancellable,
      isBlocking: other.isBlocking,
      isUserRefundable: other.isUserRefundable ?? false,
      createdAt: new Date(other.createdAt),
      updatedAt: new Date(other.updatedAt),
    });
  }

  static fromApiSummary(other: TransferApi.SingleTransferSummary) {
    return new Transfer({
      transferId: other.singleTransferId,
      status: new TransferStatus(other.transferStatus),
      destinationMoney: Money.fromApi(other.destinationMoney),
      sourceMoney: Money.fromApi(other.sourceMoney),
      fixedFee: Money.fromApi(other.fixedFee),
      totalFee: Money.fromApi(other.totalFee),
      fixedFeeWithoutPromo: Money.fromApi(other.fixedFeeWithoutPromo),
      quote: Quote.fromApi(other.quote),
      deliveryOption: DeliveryOption.fromApi(other.deliveryOption),
      quoteOption: QuoteOption.fromApi(other.quoteOption),
      entryMode: other.entryMode,
      recipient: Recipient.fromApi(other.recipient),
      checks: [],
      payInDetails: undefined,
      refundReason: undefined,
      settleDate: undefined,
      reference: other.customerReference,
      didCustomerChooseQuoteOption: other.didCustomerChooseQuoteOption,
      isCancellable: other.isCancellable,
      isBlocking: other.isBlocking,
      isUserRefundable: other.isUserRefundable ?? false,
      createdAt: new Date(other.createdAt),
      updatedAt: undefined,
    });
  }

  displaying(): TransferDisplaying {
    return new TransferDisplaying({
      status: this.status,
      destinationMoney: this.destinationMoney,
      sourceMoney: this.sourceMoney,
      fixedFee: this.fixedFee,
      totalFee: this.totalFee,
      fixedFeeWithoutPromo: this.fixedFeeWithoutPromo,
      quote: this.quote,
      deliveryOption: this.deliveryOption,
      quoteOption: this.quoteOption,
      entryMode: this.entryMode,
      recipient: this.recipient,
      reference: this.reference,
      didCustomerChooseQuoteOption: this.didCustomerChooseQuoteOption,
      createdAt: this.createdAt,
      shouldWarnAboutBankLimits: false,
    })
  }

  static fallback = Transfer.fallbackWithStatus("checks_pending")

  static fallbackWithStatus(status: TransferStatus["string"]) {
    const displayingFallback = TransferDisplaying.fallback
    return new Transfer({
      ...displayingFallback,
      transferId: "00000000-0000-0000-0000-000000000000",
      status: new TransferStatus(status),
      isCancellable: false,
      isBlocking: false,
      isUserRefundable: false,
      recipient: Recipient.fallback,
      reference: "",
      checks: [],
      updatedAt: new Date(),
      createdAt: new Date(),
    })
  }
}