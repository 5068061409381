import SideIllustrationLayout from "../../../components/ui/layout/unauthorized/SideIllustrationLayout";
import OnboardingScreen from "../../../components/onboarding/OnboardingScreen";
import {NavStackRoot} from "../../../components/navigation/NavStack";
import PasswordResetStepForm from "./PasswordResetStepForm";

const PasswordResetPage = () => {
  return (
    <SideIllustrationLayout sideIllustration={"app-showcase"}>
      <OnboardingScreen>
        <NavStackRoot root={PasswordResetStepForm} args={{}} ctx={{}}/>
      </OnboardingScreen>
    </SideIllustrationLayout>
  );
}

export default PasswordResetPage;