import Decimal from "decimal.js";
import * as Common from "../api/whales/Common";
import {IntlShape} from "react-intl";
import {CountryCode} from "./countries";

export interface ICurrency {
  readonly code: string
  readonly minIncrement: Decimal
}

const defaultMinIncrement = new Decimal(0.01)
const defaultMinIncrementMap: Record<string, Decimal> = {
  "GBP": defaultMinIncrement,
  "EUR": defaultMinIncrement,
}

export class Currency implements ICurrency {
  readonly code: string
  readonly minIncrement: Decimal

  constructor(other: ICurrency) {
    this.code = other.code
    this.minIncrement = other.minIncrement
  }

  countryCode(): string | undefined {
    switch (this.code) {
      case Currency.gbp.code:
        return CountryCode.UnitedKingdom
      case Currency.eur.code:
        return undefined
      case Currency.nok.code:
        return CountryCode.Norway
      case Currency.inr.code:
        return CountryCode.India
      case Currency.cad.code:
        return CountryCode.Canada
      case Currency.aud.code:
        return CountryCode.Australia
      case Currency.usd.code:
        return CountryCode.UnitedStates
      case Currency.dkk.code:
        return CountryCode.Denmark
      case Currency.sek.code:
        return CountryCode.Sweden
      case Currency.pln.code:
        return CountryCode.Poland
      default:
        return undefined
    }
  }

  toApi(): Common.Currency {
    return {code: this.code, minIncrement: this.minIncrement.toString()}
  }

  eq(b: Currency) {
    return this.code === b.code
  }

  maximumFractionDigits(): number {
    return Decimal.max(0, Decimal.log10(this.minIncrement).neg()).toNumber()
  }

  name(intl: IntlShape): string {
    return intl.formatDisplayName(this.code, { type: 'currency' }) || this.code
  }

  static fromApi(c: Common.Currency) {
    return new Currency({code: c.code, minIncrement: new Decimal(c.minIncrement)})
  }

  static default(currencyCode: string) {
    const minIncrement = defaultMinIncrementMap[currencyCode] ?? defaultMinIncrement
    return new Currency({code: currencyCode, minIncrement: new Decimal(minIncrement)})
  }

  static readonly gbp = Currency.default("GBP")
  static readonly eur = Currency.default("EUR")
  static readonly nok = Currency.default("NOK")
  static readonly inr = Currency.default("INR")
  static readonly cad = Currency.default("CAD")
  static readonly aud = Currency.default("AUD")
  static readonly usd = Currency.default("USD")
  static readonly dkk = Currency.default("DKK")
  static readonly sek = Currency.default("SEK")
  static readonly pln = Currency.default("PLN")
}

interface IMoney {
  readonly amount: Decimal
  readonly currency: Currency
}

export class Money implements IMoney {
  readonly amount: Decimal
  readonly currency: Currency

  constructor(other: IMoney) {
    this.amount = other.amount
    this.currency = other.currency
  }

  updated(other: Partial<IMoney>) {
    return new Money({...this, ...other})
  }

  toApi(): Common.Money {
    return {amount: this.amount.toString(), currency: this.currency.toApi()}
  }

  eq(b: Money) {
    return this.amount.eq(b.amount) && this.currency.eq(b.currency)
  }

  static make(amount: Decimal.Value, currency: Currency) {
    return new Money({amount: new Decimal(amount), currency: currency})
  }

  static fromApi(m: Common.Money) {
    return new Money({amount: new Decimal(m.amount), currency: Currency.fromApi(m.currency)})
  }

  static default(amount: Decimal.Value, currencyCode: string) {
    return Money.make(amount, Currency.default(currencyCode))
  }


  static gbp(amount: Decimal.Value) {
    return Money.make(amount, Currency.gbp)
  }

  static eur(amount: Decimal.Value) {
    return Money.make(amount, Currency.eur)
  }
  static nok(amount: Decimal.Value) {
    return Money.make(amount, Currency.nok)
  }

  static cad(amount: Decimal.Value) {
    return Money.make(amount, Currency.cad)
  }

  static aud(amount: Decimal.Value) {
    return Money.make(amount, Currency.aud)
  }

  static usd(amount: Decimal.Value) {
    return Money.make(amount, Currency.usd)
  }
}

export type MoneyInputValue = {
  amount: Decimal | "empty"
  currency: Currency
}
