import React from "react";
import TransferStatusView from "../../components/domain/transfers/TransferStatusView";
import {Section} from "../../components/ui/collections/Section";
import {useStrings} from "../../strings";
import RecipientSection from "../../components/domain/recipients/RecipientView";
import FormattedDate from "../../components/formatters/date/FormattedDate";
import {DiscountedTotalFee} from "../../components/domain/transfers/DiscountedFee";
import Join from "../../components/ui/collections/Join";
import Tooltip, {TooltipView} from "../../components/ui/Tooltip";
import RowInput from "../../components/ui/input/RowInput";
import {useTransfersApi} from "../../api/transfers";
import {TransferDisplaying} from "../../models/transfers/TransferDisplaying";
import {Recipient} from "../../components/domain/recipients/domain";
import {WithSkeleton} from "../../components/ui/Skeleton";
import moment from "moment";
import TooltipViewFeeBreakdown from "../../components/transfers/TooltipViewFeeBreakdown";
import TooltipExchangeRate from "../../components/transfers/TooltipExchangeRate";
import InfoRow from "../../components/ui/collections/rows/InfoRow";
import {DeliveryOptionTitle} from "../../components/domain/transfers/FormattedDeliveryOption";


const ReferenceRow = (
  {reference, isLoading, setReference}: {
    isLoading: boolean,
    reference: string,
    setReference?: (reference: string) => void
  }) => {
  const strings = useStrings()
  const transfersApi = useTransfersApi()

  return (
    <WithSkeleton value={!isLoading ? reference : undefined} fallback={"Example reference"} children={
      reference =>
        <InfoRow
          caption={strings["transfer.review.reference"]}
          content={<RowInput
            placeholder={strings["transfer.review.add_reference_optional"]}
            value={reference}
            readOnly={!setReference}
            onChange={setReference}
            error={undefined}
            onValidate={(s: string) => s === ""
              ? Promise.resolve()
              : transfersApi.referenceValidate(s)}
          />}
        />}
    />)
}

type EditTransferProps = {
  reference?: string,
  setReference?: (reference: string) => void
}

type TransferDetailsSectionProps = { transferDisplaying?: TransferDisplaying } & EditTransferProps

export const TransferDetailsSection = (
  {
    transferDisplaying,
    ...props
  }
    :
    TransferDetailsSectionProps
) => {
  // WEB-384: Edit buttons
  const strings = useStrings()

  const rowProps = {transferDisplaying}

  const isLoading = transferDisplaying === undefined
  return (
    <Section title={strings["transfer.review.transfer.title"]}>
      {transferDisplaying?.status.excludeEstimate && <TransferInfoRow
        {...rowProps}
        caption={strings["transfer.status"]}
        content={displaying => <TransferStatusView status={ displaying.status}/>}
      />}

      {transferDisplaying?.createdAt && <TransferInfoRow
        {...rowProps}
        caption={strings["transfer.review.transfer.submitted_at"]}
        content={displaying => {
          const createAt = displaying.createdAt
          return createAt && <FormattedDate style={"MMMMdyyyyjmm"} value={createAt}/>
        }}
      />}

      <TransferInfoRow
        {...rowProps}
        caption={strings["transfer.review.transfer.sending_amount"]}
        content={transferDisplaying =>
          transferDisplaying.sourceAmountDescription(strings)
        }
        explanation={transferDisplaying =>
          transferDisplaying.shouldWarnAboutBankLimits ? strings["transfer.review.bank_limits_warning"] : undefined
        }
      />

      <TransferInfoRow
        {...rowProps}
        caption={strings["transfer.review.transfer.total_fees"]}
        content={transferDisplaying =>
          <Join separator={" "} elements={[
            <DiscountedTotalFee displaying={transferDisplaying}/>,
            <Tooltip children={<TooltipViewFeeBreakdown displaying={transferDisplaying}/>}/>
          ]}/>}
      />

      <TransferInfoRow
        {...rowProps}
        caption={strings["transfer.review.transfer.exchange_rate"]}
        content={transferDisplaying =>
          <>
            {transferDisplaying.exchangeRateDescription(strings)}
            <TooltipExchangeRate className={"ml-[4px]"} displaying={transferDisplaying}/>
          </>
        }/>

      <TransferInfoRow
        {...rowProps}
        caption={strings["transfer.review.transfer.receiving_amount"]}
        content={transferDisplaying => transferDisplaying.destinationAmountDescription(strings)}
      />

      <TransferInfoRow
        {...rowProps}
        caption={strings["transfer.review.transfer.delivery"]}
        content={transferDisplaying =>
          <>
            <DeliveryOptionTitle deliveryOption={transferDisplaying.deliveryOption}/>
            {function () {
              if (transferDisplaying.status.string === "canceled") return null
              if (moment().diff(transferDisplaying.deliveryOption.deliveryDate, "days") <= -1) {
                return (
                  <Tooltip className={"ml-[4px]"}>
                    <TooltipView
                      title={strings["transfer.review.transfer.delivery"]}
                      subtitle={transferDisplaying.deliverySubtitle(strings)}
                    />
                  </Tooltip>
                )
              }
              return null
            }()}
          </>
        }
      />


      <ReferenceRow
        isLoading={isLoading}
        reference={props.reference ?? transferDisplaying?.reference ?? ""}
        setReference={props.setReference}
      />
    </Section>
  )
}

const TransferInfoRow = (
  {caption, content, explanation, transferDisplaying}: {
    caption: string,
    content: (displaying: TransferDisplaying) => React.ReactNode,
    explanation?: (displaying: TransferDisplaying) => React.ReactNode,
    transferDisplaying?: TransferDisplaying,
  }) => {
  return (
    <WithSkeleton
      value={transferDisplaying}
      fallback={TransferDisplaying.fallback}
      children={transferDisplaying =>
        <InfoRow
          caption={caption}
          content={content(transferDisplaying)}
          explanation={explanation?.(transferDisplaying)}
        />}
    />
  )
}


type TransferViewProps = {
  transferDisplaying?: TransferDisplaying
  recipient?: Recipient
  displayRecipient: boolean
} & EditTransferProps
const TransferView = ({transferDisplaying, recipient, displayRecipient = true, ...props}: TransferViewProps) => {
  return (
    <>
      <TransferDetailsSection {...props} transferDisplaying={transferDisplaying}/>
      {displayRecipient && <RecipientSection recipient={recipient}/>}
    </>
  )
};

export default TransferView;
