import cx from "classnames";
import {GetLuminance} from "../../helpers/color";
import './Avatar.scss';
import {WithSkeleton} from "./Skeleton";

type Props = {
  title?: string,
  size: "big" | "regular",
  colors?: string[]
  className?: string
};

const Avatar = (props: Props) => {
  const defaultBackground = "#E9E0D9"

  const size = props.size === "big" ? 64 : 40;

  const getForegroundClass = () => {
    if (props.colors === undefined || props.colors.length === 0) {
      return 'avatar-font-dark'
    }

    const color = props.colors[Math.floor(props.colors.length / 2)]
    const luminance = GetLuminance(color)

    return luminance > 0.73 ? 'avatar-font-dark' : 'avatar-font-light';
  }

  const getBackgroundColor = () => {
    if (props.colors === undefined) {
      return defaultBackground
    }

    if (props.colors.length >= 2) {
      return `linear-gradient(140deg, ${props.colors[0]} 0%, ${props.colors[1]} 100%)`
    } else if (props.colors.length === 1) {
      return props.colors[0]
    } else {
      return defaultBackground
    }
  };

  const foregroundClass = getForegroundClass();
  const backgroundColor = getBackgroundColor();

  const sizePx = `${size}px`;

  return (

    <div
      className={cx(
        "avatar uppercase relative", `avatar-${props.size}`,
        foregroundClass, props.className
      )}
      style={{background: backgroundColor, width: sizePx, height: sizePx}}
      children={
        <WithSkeleton
          wrap={false}
          circle={true}
          adjust={{left: 1, top: 1, right: 1, bottom: 1}}
          value={props.title}
          fallback={"??"}
          children={title => title}
        />
      }
    />
  )
}

export default Avatar;