import {Money} from "../../helpers/money";
import * as TransferModels from "../../api/whales/TransferCommon";

export class TierCustomLimit {
  limit: Money
  expiryDate: Date
  consumedMoney: Money

  constructor(other: {
    limit: Money,
    expiryDate: Date,
    consumedMoney: Money
  }) {
    this.limit = other.limit
    this.expiryDate = other.expiryDate
    this.consumedMoney = other.consumedMoney
  }

  static fromApi(api: TransferModels.CustomLimit): TierCustomLimit {
    return new TierCustomLimit({
      limit: Money.fromApi(api.limit),
      expiryDate: new Date(api.expiryDate),
      consumedMoney: Money.fromApi(api.consumedMoney)
    })
  }
}