export function inArray<T>(array: T[], value: T): boolean {
  return array.indexOf(value) !== -1;
}

export class Arrays {
  static groupBy<K, V>(array: V[], keyGetter: (value: V) => K): Map<K, V[]> {
    const map = new Map<K, V[]>();
    array.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  static toMap<K, V>(array: V[], keyGetter: (value: V) => K): Map<K, V> {
    const map = new Map<K, V>();
    array.forEach((item) => {
      const key = keyGetter(item);
      map.set(key, item);
    });
    return map;
  }

  static iteratorToArray<T>(iterator: IterableIterator<T>): T[] {
    const array: T[] = [];
    let next = iterator.next();
    while (!next.done) {
      array.push(next.value);
      next = iterator.next();
    }
    return array;
  }

  // This is `Arrays.set` extension function in iOS
  static uniqueWith<T>(self: T[], options: {
    areEqual: (t1: T, t2: T) => boolean,
    tiebreaker?: (t1: T, t2: T) => boolean
  }): T[] {
    const elements: T[] = []
    for (let element of self) {
      const firstIndex = elements.findIndex(e => options.areEqual(e, element));
      if (firstIndex >= 0) {
        if (options.tiebreaker?.(element, elements[firstIndex]) === true) {
          elements[firstIndex] = element;
        } else {
          continue
        }
      } else {
        elements.push(element);
      }
    }
    return elements
  }
}