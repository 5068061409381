import {NavStackPush, useNavStack} from "../../../atoms/nav-stack";
import {useVerificationApi} from "../../../api/verification";
import {Verification} from "../../domain/verifications/domain";
import {InterfaceTemplates, InterfaceTemplateValues} from "../../domain/interface-template/domain";
import {PropsOnComplete} from "../../../helpers/props";
import TSOFDocumentUploadStep from "./TSOFDocumentUploadStep";
import {PropsOf} from "@emotion/react";
import TSOFSelectStep from "./TSOFSelectStep";
import {useTSOFVerificationState} from "../../../atoms/verification/tsof";

export const useTransferSourceOfFundsController = () => {
  const [_1, _2, resetState] = useTSOFVerificationState()
  const navStack = useNavStack()
  const verificationApi = useVerificationApi()

  const routeFirst = async (opts: {
    verification: Verification,
    titleAlign: "left" | "center",
    template: InterfaceTemplates.TransferSourceOfFunds
    pushFirst?: NavStackPush<{}, {}>
  } & PropsOnComplete) => {
    const push = opts.pushFirst || navStack.push
    if (opts.template.sourceOfFundsSelect.options.length === 1) {
      return onCompleteSourceOfFundsSelect({
        ...opts,
        option: {
          kind: "singleSelect",
          singleSelectValue: {
            option: opts.template.sourceOfFundsSelect.options[0],
            otherText: undefined
          }
        }
      })
    }

    push<PropsOf<typeof TSOFSelectStep>, {}>(
      TSOFSelectStep, {
        titleAlign: opts.titleAlign,
        template: opts.template,
        onComplete: (v) => onCompleteSourceOfFundsSelect({...opts, option: v})
      }
    )
  }

  const onCompleteSourceOfFundsSelect = async (opts: {
    verification: Verification,
    template: InterfaceTemplates.TransferSourceOfFunds,
    option: InterfaceTemplateValues.SingleSelect,
  } & PropsOnComplete) => {
    await verificationApi.submitTransferSourceOfFunds({
      verificationId: opts.verification.id,
      sourceOfFunds: opts.option.singleSelectValue.option.id,
      documents: []
    })

    const onComplete = async () => {
      await opts.onComplete()
      resetState()
    }

    navStack.push<PropsOf<typeof TSOFDocumentUploadStep>, {}>(
      TSOFDocumentUploadStep, {
        titleAlign: "left",
        verification: opts.verification,
        optionId: opts.option.singleSelectValue.option.id,
        template: opts.template,
        onComplete: onComplete
      }
    )
  }

  return {routeFirst}
}
