import InformationScreen from "../../components/ui/screen/InformationScreen";
import {useStrings} from "../../strings";
import {WithLearnMoreSuffix} from "../../components/ui/LearnMore";
import {Tier} from "../../models/tiers/Tier";
import {PropsOnComplete} from "../../helpers/props";
import {Links} from "../../helpers/Links";
import {Button} from "../../components/ui/Buttons";
import {img} from "../../helpers/public";

type Props = {
  requestedTier: Tier
} & PropsOnComplete

const LimitsStepUpgradeSuccess = ({requestedTier, onComplete}: Props) => {
  const strings = useStrings()
  return (
    <InformationScreen
      title={strings["tier.upgrade_successful.title"]}
      subtitle={<WithLearnMoreSuffix url={Links.helpCenter.articles.tierUpgradeComplete}
                                     children={strings["tier.upgrade_successful.subtitle"](requestedTier.name)}/>}
      media={<img className={"max-w-[375px]"} src={img("popper.png")} alt={"Popper"}/>}
      primaryButton={buttonProps =>
        <Button {...buttonProps} title={strings["general.done"]} type={"button"} onClickAsync={onComplete}/>
      }
    />
  )
}

export default LimitsStepUpgradeSuccess