import {Button} from "../../ui/Buttons";
import {VerificationProps} from "../verification-controller";
import {useStrings} from "../../../strings";
import React from "react";
import InformationScreen from "../../ui/screen/InformationScreen";
import {img} from "../../../helpers/public";

type Props = VerificationProps & {
  onContinue: () => Promise<void>
}

const SelfieIdIntroStep = (props: Props) => {
  const strings = useStrings()

  return (
    <InformationScreen
      title={strings["government_id.intro.title"]}
      subtitle={strings["government_id.intro.subtitle"]}
      media={<img className={"max-w-[375px]"} src={img("gov-id-verify.png")} alt={"Illustration"}/>}
      primaryButton={(common) => {
        return <Button {...common} type={"button"} onClickAsync={props.onContinue} title={strings["general.continue"]}/>
      }}
    />
  )
}

export default SelfieIdIntroStep