import TinyCrossfade from "react-tiny-crossfade";
import "./Crossfade.scss"
import {defaultAnimDurationMillis} from "../../variables";
import {PropsClassName} from "../../helpers/props";
import cx from "classnames";

type Props = {children: JSX.Element} & PropsClassName

const Crossfade = ({children, ...props}: Props) => {
  return (
    <TinyCrossfade
      className={cx("crossfade-component-wrapper", props.className)}
      disableInitialAnimation={true}
      duration={defaultAnimDurationMillis / 2}
      children={children}
    />
  )
}

export default Crossfade