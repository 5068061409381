import {TitleSubtitleLeftScreen} from "../../components/ui/screen/TitleSubtitleScreen";
import {useStrings} from "../../strings";
import {BasicForm, InputGroup, OtpInput, useOtpFocuser} from "../../components/ui/input/Inputs";
import PhoneInput from "../../components/ui/input/PhoneInput";
import {useState} from "react";
import Collapsable from "../../components/ui/Collapsable";
import {useAsyncLoading} from "../../helpers/hooks";
import {useClientQuery} from "../../api/client";
import ButtonBar from "../../components/ui/ButtonBar";
import {Button} from "../../components/ui/Buttons";
import {PropsOnComplete} from "../../helpers/props";
import {useDisplayErrorMessage} from "../../helpers/errors";

type Props = {
  defaultCountryCode: string
} & PropsOnComplete


type InputName = "phone"
type ErrorName = "phone" | "otp"
type Inputs = { [key in InputName]: string }
type State = {
  formState: "fill" | "confirm-phone"
  inputs: Inputs
  otp: {
    [key: string]: {
      status: "not-sent" | "sent" | "confirmed";
      ticket?: string;
      code: string;
    }
  }
  errors: {
    [key in ErrorName]: string | undefined
  }
}

const SettingsStepEditPhone = (props: Props) => {
  const strings = useStrings()
  const displayErrorMessage = useDisplayErrorMessage()
  const clientQuery = useClientQuery()
  const phoneMutation = clientQuery.info.phone.update.useMutation()

  const [state, setState] = useState<State>({
    formState: "fill",
    inputs: {
      phone: "",
    },
    otp: {},
    errors: {
      phone: undefined,
      otp: undefined,
    }
  })
  const otpFocuser = useOtpFocuser()
  const asyncWithLoading = useAsyncLoading(false)

  const setError = (errName: ErrorName) => (err: any | undefined) => {
    setState((prev) => {
      const newErrMsg = err && displayErrorMessage(err)
      if (prev.errors[errName] === newErrMsg) {
        return prev
      }
      return {...prev, errors: {...prev.errors, [errName]: newErrMsg}}
    })
  }
  const unsetError = (errName: ErrorName) => setError(errName)(undefined)

  const onSendOtpCode = () => asyncWithLoading.asyncWithLoading(async () => {
    const phone = state.inputs?.phone
    const res = await phoneMutation.mutateAsync(phone, {
      onSuccess: () => unsetError("phone"),
      onError: setError("phone")
    })
    if (!phone) {
      return
    }
    setState((prev) => ({
        ...prev,
        otp: {
          ...prev.otp,
          [phone]: {
            code: "",
            status: "sent",
            ticket: res.ticket
          }
        }
      })
    )
  })

  const resendCodeMutation = clientQuery.info.phone.resendCode.useMutation()
  const onResendOtpCode = () => asyncWithLoading.asyncWithLoading(async () => {
    const phone = state.inputs?.phone
    if (!phone) {
      return
    }
    const res = await resendCodeMutation.mutateAsync({ticket: state.otp[phone].ticket || ""}, {
      onSuccess: () => unsetError("phone"),
      onError: setError("phone")
    })

    setState((prev) => ({
        ...prev,
        otp: {
          ...prev.otp,
          [phone]: {
            code: "",
            status: "sent",
            ticket: res.ticket
          }
        }
      })
    )
    otpFocuser.focus()
  })

  const confirmPhoneMutation = clientQuery.info.phone.confirm.useMutation()
  const onConfirmOtpCode = () => asyncWithLoading.asyncWithLoading(async () => {
    const phone = state.inputs?.phone
    if (!phone) {
      return
    }

    const otpState = state.otp[phone]
    await confirmPhoneMutation.mutateAsync({
      ticket: otpState?.ticket || "",
      code: otpState?.code || ""
    }, {
      onSuccess: () => {
        unsetError("otp")
        setState((prev) => ({
            ...prev,
            otp: {
              ...prev.otp,
              [phone]: {
                ...otpState,
                status: "confirmed"
              }
            }
          })
        )
      },
      onError: (err) => {
        setError("otp")(err)
        setState((prev) => ({
          ...prev,
          otp: {
            ...prev.otp,
            [phone]: {
              ...otpState,
              status: "sent",
              code: "",
            }
          }
        }))
      }
    })
  })

  const onSubmit = () => asyncWithLoading.asyncWithLoading(async () => {
    if (!state.inputs) {
      return Promise.reject(Error("inputs are undefined"))
    }
    const phone = state.inputs.phone
    switch (state.otp[phone]?.status) {
      case undefined:
      case "not-sent":
        return onSendOtpCode()
      case "sent":
        await onConfirmOtpCode()
        return props.onComplete()
      case "confirmed":
        return props.onComplete()
    }
  })

  const isLoading = asyncWithLoading.isLoading
  const phone = state.inputs?.phone
  const otpState = state.otp[phone]

  return (
    <TitleSubtitleLeftScreen
      title={strings["personal_info.update_phone_number.title"]}
    >
      <BasicForm onSubmit={onSubmit}>
        <InputGroup name={strings["signup.phone_number.title"]}>
          <PhoneInput
            ariaLabel={strings["signup.phone_number.title"]}
            disabled={isLoading}
            suggestCountryCode={props.defaultCountryCode}
            value={state.inputs?.phone}
            onChange={(newValue: string) => {
              const phone = newValue
              setState((prev) => ({
                ...prev,
                inputs: prev.inputs && {
                  ...prev.inputs,
                  phone: newValue,
                },
                otp: {
                  ...prev.otp,
                  [phone]: prev.otp[phone] ? prev.otp[phone] :
                    {
                      code: "",
                      status: "not-sent"
                    }
                }
              }))
            }}
            error={state.errors.phone}
            gadget={(() => {
              if (!otpState) {
                return {
                  kind: "button",
                  hidden: true,
                  text: "",
                  isLoading: false,
                  onClick: () => {
                  }
                }
              }

              const text = (() => {
                // WEB-369: Make Verify/Resend crossfade
                switch (otpState.status) {
                  case "not-sent":
                    return strings["signup.otp.verify"]
                  case "sent":
                  case "confirmed":
                    return strings["signup.otp.resend"]
                }
              })() || ""
              const onClick = () => {
                switch (otpState.status) {
                  case "not-sent":
                    onSendOtpCode()
                    break;
                  case "sent":
                    onResendOtpCode()
                    break;
                  case "confirmed":
                    break;

                }
              };
              return {
                kind: "button",
                hidden: isLoading || otpState.status === "confirmed",
                isLoading: isLoading,
                text,
                onClick: onClick
              }
            })()}
          />
        </InputGroup>

        <Collapsable
          expanded={!!otpState && (otpState.status === "sent" || otpState.status === "confirmed")}
          onExpanded={() => otpFocuser.focus()}
        >
          <InputGroup
            name={strings["signup.otp.title"]}>
            <OtpInput
              focuser={otpFocuser}
              disabled={isLoading || (!!otpState && otpState.status !== "sent")}
              value={otpState ? otpState.code : ""}
              error={state.errors.otp}
              onChange={(newValue) => {
                setState((prev) => {
                  const phone = prev.inputs?.phone
                  if (!phone) return prev
                  const otpState = prev.otp[phone]
                  return {
                    ...prev,
                    otp: {...prev.otp, [phone]: {...otpState, code: newValue}},
                    errors: {...prev.errors, otp: undefined}
                  }
                })
              }}
              onComplete={onSubmit}
            />
          </InputGroup>
        </Collapsable>

        <ButtonBar align={"center"}>
          <Button type={"submit"} title={strings["general.continue"]} size={"big"} color={"primary-black"}
                  loading={isLoading}/>
        </ButtonBar>
      </BasicForm>
    </TitleSubtitleLeftScreen>
  );
}

export default SettingsStepEditPhone;