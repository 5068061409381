import * as OnboardingModels from "../../../api/whales/Onboarding";
import {InterfaceTemplate} from "../interface-template/domain";

type ApplicationTemplateId = OnboardingModels.ApplicationTemplateElement["elementId"]

export class ApplicationElementTemplate {
  readonly identifier: ApplicationTemplateId
  readonly elementName: string
  readonly interfaceTemplate: InterfaceTemplate

  constructor(other: {
    identifier: ApplicationTemplateId
    elementName: string
    interfaceTemplate: InterfaceTemplate
  }) {
    this.identifier = other.identifier
    this.elementName = other.elementName
    this.interfaceTemplate = other.interfaceTemplate
  }

  static fromApi(template: OnboardingModels.ApplicationTemplateElement): ApplicationElementTemplate {
    return new ApplicationElementTemplate({
      identifier: template.elementId,
      elementName: template.elementName,
      interfaceTemplate: new InterfaceTemplate(template.interfaceTemplate)
    })
  }
}

export class ApplicationTemplate {
  readonly elements: ApplicationElementTemplate[]

  constructor(other: {
    elements: ApplicationElementTemplate[]
  }) {
    this.elements = other.elements
  }

  interfaceTemplateFor(identifier: ApplicationTemplateId): InterfaceTemplate{
    const element = this.elements.find((element) => element.identifier === identifier)
    if (element === undefined) {
      throw new Error(`Element with identifier ${identifier} not found in template`)
    }
    return element?.interfaceTemplate
  }

  static fromApi(template: OnboardingModels.ApplicationTemplate): ApplicationTemplate {
    return new ApplicationTemplate({
      elements: template.elements.map((element) => {
        return ApplicationElementTemplate.fromApi(element)
      })
    })
  }
}