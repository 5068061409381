import {Heading1} from "../../components/ui/Labels";
import Limiter from "../../components/ui/Limiter";
import {Form, Input, InputGroup} from "../../components/ui/input/Inputs";
import {useState} from "react";
import {Picker, PickerOption} from "../../components/ui/Picker";
import {FileExtension} from "qr-code-styling";
import AtlanticQrCode from "../../components/ui/AtlanticQrCode";
import ButtonBar from "../../components/ui/ButtonBar";
import {Button} from "../../components/ui/Buttons";
import {useQrCode} from "../../helpers/qrcode";

type FormState = {
  url: string,
  size: string,
  format: FormatOption | null,
}

interface FormatOption extends PickerOption {
  value: FileExtension
}

const formatOptions: FormatOption[] = [
  {
    value: "png",
    label: "PNG",
  },
  {
    value: "svg",
    label: "SVG",
  }
]

const QrCodeGeneratorPage = () => {
  const [state, setState] = useState<FormState>({
    url: "atlantic.money",
    size: "300",
    format: null,
  })

  const qrCodeCtx = useQrCode({
    url: state.url,
    size: Number.parseFloat(state.size),
  })

  const onSubmit = () => {
    qrCodeCtx.download({
      name: "qr-code",
      extension: state.format?.value,
    })
  }

  return (
    <div>
      <Limiter contentMaxWidth={"720px"} className={"mx-auto"}>
        <Heading1 text={"Welcome to Atlantic QR code generator!"} className={"text-center mb-[32px]"}/>
        <div className={"flex justify-between items-center "}>
          <Form className={"flex-1 mr-[72px]"} onSubmit={onSubmit}>
            <InputGroup name={"URL"}>
              <Input type={"text"}
                     capitalization={"none"}
                     error={undefined}
                     placeholder={"atlantic.money"}
                     value={state.url}
                     onChange={(newValue) => setState(prev => ({...prev, url: newValue}))}
              />
            </InputGroup>
            <InputGroup name={"Size in px"}>
              <Input
                type={"text"}
                capitalization={"none"}
                error={undefined}
                placeholder={"Size"}
                value={state.size}
                onChange={(newValue) => setState(prev => ({...prev, size: newValue}))}
              />
            </InputGroup>
            <InputGroup name={"Format"}>
              <Picker<FormatOption>
                placeholder={"Format"}
                value={state.format}
                onChange={(newValue) =>
                  setState(prev => ({...prev, format: newValue}))
                }
                options={formatOptions}/>
            </InputGroup>
            <ButtonBar align={"center"}>
              <Button type={"submit"} size={"max"} title={"Download"} color={"primary-black"}/>
            </ButtonBar>
          </Form>
          <AtlanticQrCode className={"grow-0 mb-[86px]"} size={"big"} url={state.url}/>
        </div>
      </Limiter>
    </div>
  )
}

export default QrCodeGeneratorPage