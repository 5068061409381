export const capitialize = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

export function notEmpty(str: string | undefined): boolean {
    return (str && str.length > 0) || false
}

export function nonEmptyOrUndefined(str: string | undefined): string | undefined {
    return str && str.length > 0 ? str : undefined
}