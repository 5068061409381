import Caption from "../../Caption";
import React from "react";
import cx from "classnames";
import {PropsClassName} from "../../../../helpers/props";
import AbstractRow, {RowClickableProps, RowModifiersProps} from "./AbstractRow";
import "./DefaultRow.scss"
import {WithSkeletonFlag} from "../../Skeleton";

type Props = {
  id?: string
  leadingImage?: React.ReactNode
  title: React.ReactNode
  subtitle?: React.ReactNode
  secondary?: React.ReactNode
  subSecondary?: React.ReactNode
  trailingIcon?: React.ReactNode

  bottomNode?: React.ReactNode

  bordered?: boolean
} & PropsClassName & RowModifiersProps & RowClickableProps

const DefaultRow = React.forwardRef<HTMLDivElement, Props>((props: Props, ref) => {
  const primaryStack = (
    <div className={"default-row__primary-stack"}>
      <div className={"default-row__title"}>
        <WithSkeletonFlag isLoading={!!props.skeleton}>
          <Caption text={props.title} size={"2"}/>
        </WithSkeletonFlag>
      </div>
      {props.subtitle && (
        <div className={"default-row__subtitle"}>
          <WithSkeletonFlag isLoading={!!props.skeleton}>
            <Caption text={props.subtitle} size={"3"} color={"primary-50"}/>
          </WithSkeletonFlag>
        </div>
      )}
    </div>
  )

  const secondaryStack = (
    <div className={"default-row__secondary-stack"}>
      {props.secondary && (
        <div className={"default-row__secondary"}>
          <WithSkeletonFlag isLoading={!!props.skeleton}>
            <Caption text={props.secondary} size={"2"}/>
          </WithSkeletonFlag>
        </div>
      )}
      {props.subSecondary && (
        <div className={"default-row__sub-secondary"}>
          <WithSkeletonFlag isLoading={!!props.skeleton}>
            <Caption text={props.subSecondary} size={"3"} color={"primary-50"}/>
          </WithSkeletonFlag>
        </div>
      )}
    </div>
  )

  return (
    <AbstractRow
      {...props}
      ref={ref}
      className={cx(props.className, "default-row", {
        "default-row--bordered": props.bordered ?? true,
      })}>
      <div className={"default-row__horizontal-stack"}>
        {props.leadingImage && <div className={"mr-4 flex-grow-0 flex-shrink-0"}>{props.leadingImage}</div>}
        {primaryStack}
        {secondaryStack}
        {props.trailingIcon && <div className={"ml-1 flex-grow-0 flex-shrink-0"}>{props.trailingIcon}</div>}
      </div>
      <div className={"default-row__bottom-node"}>
        {props.bottomNode}
      </div>
    </AbstractRow>
  )
})

export default DefaultRow;
