import createCache from '@emotion/cache'
import {CacheProvider} from '@emotion/react'
import React from 'react';
import ReactDOM from 'react-dom/client';
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import {QueryClientProvider} from "react-query";
import {ReactQueryDevtools} from 'react-query/devtools'
import {RecoilRoot} from "recoil";
import {queryClient} from "./api/axios";
import App from './App';
import CompareBlock from "./components/compare/CompareBlock";
import {ErrorStack} from "./components/ui/Errors";
import './index.css';
import {AlertProvider} from "./providers/alert-stack";
import reportWebVitals from './reportWebVitals';
import {AdService} from "./services/ad-service";
import {Transifex} from "./services/transifex";
import {IntlProvider} from "react-intl";
import {MeasureLayer} from "./components/ui/MeasureLayer";
import {TrackingServiceManager} from "./services/tracking/TrackingService";
import * as SentryBrowser from "@sentry/browser"
import * as Sentry from "@sentry/react";
import {useLocation} from "./routes/router-dom-wrapper/hooks";
import {createRoutesFromChildren, matchRoutes, useNavigationType} from "react-router-dom";
import {BuildConfig} from "build-config";
import {DynamicConfig} from "./storage/dynamic-сonfig";

// @ts-expect-error
window.atlantc = {
// @ts-expect-error
  ...window.atlantc,
  gitInfo: {
    branch: __GIT_BRANCH_NAME__,
    commit: __GIT_COMMIT_HASH__,
  }
}

function isFilteredRequestError(event: SentryBrowser.ErrorEvent) {
  // In case there's a chain, we take the last entries
  const mainAndMaybeCauseErrors = event.exception?.values?.slice(-2) ?? [];

  for (const error of mainAndMaybeCauseErrors) {
    if (error.type === "AxiosError") {
      return true
    }
    const is404 = error.type === 'NotFoundError' && !!error.value?.match('(GET|POST|PUT|DELETE) .* 404');
    const is429 = error.type === 'TooManyRequestsError' && !!error.value?.match('(GET|POST|PUT|DELETE) .* 429');
    if (is404 || is429) {
      return true;
    }
  }

  return false;
}

Sentry.init({
  enabled: process.env.NODE_ENV === "production",
  debug: process.env.NODE_ENV === "development",
  dsn: "https://a8ca1c735d0200ba2649028a8cce7707@o1334883.ingest.us.sentry.io/4507137089470464",
  environment: BuildConfig.sentryEnv,
  release: __GIT_BRANCH_NAME__,
  ignoreErrors: [
    /**
     * Thrown when firefox prevents an add-on from refrencing a DOM element that has been removed.
     * This can also be filtered by enabling the browser extension inbound filter
     */
    "TypeError: can't access dead object",
    /**
     * React internal error thrown when something outside react modifies the DOM
     * This is usually because of a browser extension or Chrome's built-in translate
     */
    "NotFoundError: Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.",
    "NotFoundError: Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.",
  ],
  beforeSend(event: SentryBrowser.ErrorEvent, _hint: SentryBrowser.EventHint) {
    if (isFilteredRequestError(event)) {
      // Return null to stop the error from being sent to Sentry
      return null;
    }
    return event;
  },
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  // tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

SentryBrowser.setExtra("deviceId", DynamicConfig.getDeviceID())


export const myCache = createCache({
  key: "my-prefix-key",
  nonce: "aHFqIGRocWRicWhkIHFqd2RxZA=="
})

const initWebApp = (rootEl: HTMLElement) => {
  const root = ReactDOM.createRoot(rootEl);

  root.render(
    <React.StrictMode>
      <CacheProvider value={myCache}>
        <IntlProvider locale={navigator.language}>
          <GoogleReCaptchaProvider
            reCaptchaKey={"6LdcqAgkAAAAAPavN7CCrAzEkQBJs6L6RL-4Np_E"}
            useEnterprise={true}>
            <QueryClientProvider client={queryClient}>
              <ReactQueryDevtools initialIsOpen={false}/>
              <RecoilRoot>
                <AlertProvider>
                  <App/>
                  <MeasureLayer/>
                </AlertProvider>
              </RecoilRoot>
            </QueryClientProvider>
          </GoogleReCaptchaProvider>
        </IntlProvider>
      </CacheProvider>
    </React.StrictMode>
  );
}

const initCompareBlock = (rootEl: HTMLElement) => {
  const root = ReactDOM.createRoot(rootEl);

  root.render(
    <React.StrictMode>
      <AlertProvider>
        <CompareBlock/>
        <ErrorStack/>
      </AlertProvider>
    </React.StrictMode>
  );
}

const webAppRoot = document.getElementById('web-app-root') as HTMLElement | undefined
if (webAppRoot) {
  initWebApp(webAppRoot)
}

const compareRoot = document.getElementById('compare-block-root') as HTMLElement | undefined
if (compareRoot) {
  initCompareBlock(compareRoot)
}

Transifex.init();
AdService.init()
TrackingServiceManager.instance.init()

// If you want to start measuring performance in your specs, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

