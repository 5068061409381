import {Currency, Money} from "../../helpers/money";
import {CurrencyAvailability, DeliveryOption, Estimate, QuoteOption} from "./Estimate";
import {Recipient} from "../../components/domain/recipients/domain";
import {EntryMode} from "../../api/whales/TransferCommon";
import _ from "lodash";
import {Arrays} from "../../helpers/array";
import {useTransfersApi} from "../../api/transfers";
import {TransferDisplaying} from "./TransferDisplaying";
import {TransferStatus} from "./TransferStatus";
import {UserDefaults, useUserDefaultsValue} from "../../atoms/user-defaults";

export interface ITransferContext {
  transferEstimates: Estimate[]
  currencyAvailability: CurrencyAvailability
  recipient?: Recipient
  reference?: string

  entryMode: EntryMode
  isRecipientPresent?: boolean

  observeLastSelectedQuoteOptionKindPreferences?: boolean
  selectedDeliveryOption?: DeliveryOption
  selectedQuoteOption?: QuoteOption
}

type UpdateParams = {
  entryMode: EntryMode
  enteredMoney: Money
  calculatedCurrency: Currency
}

const cheapest = (estimates: Estimate[]): Estimate | undefined => {
  return _.minBy(estimates, (e) => e.totalFee.amount.toNumber())!
}

export const useTransferContextOps = () => {
  const transfersApi = useTransfersApi()
  const userDefaults = useUserDefaultsValue()

  const make = async (params: UpdateParams & {
    currencyAvailability?: CurrencyAvailability
  }): Promise<TransferContext> => {
    const estimates = await function () {
      if (params.entryMode === "source") {
        return transfersApi.estimateBySource({
          srcMoney: params.enteredMoney,
          dstCurrencyCode: params.calculatedCurrency.code
        })
      }

      return transfersApi.estimateByDestination({
        dstMoney: params.enteredMoney,
        srcCurrencyCode: params.calculatedCurrency.code
      })
    }()

    const currencyAvailability = params.currencyAvailability ?? await transfersApi.availableCurrencies()

    return new TransferContext(
      {
        transferEstimates: estimates,
        currencyAvailability: currencyAvailability,
        entryMode: params.entryMode
      })
  }

  const update = async (context: TransferContext, params: UpdateParams): Promise<TransferContext> => {
    const estimates = await function () {
      if (params.entryMode === "source") {
        return transfersApi.estimateBySource({
          srcMoney: params.enteredMoney,
          dstCurrencyCode: params.calculatedCurrency.code
        })
      }

      return transfersApi.estimateByDestination({
        dstMoney: params.enteredMoney,
        srcCurrencyCode: params.calculatedCurrency.code
      })
    }()

    return context.with({transferEstimates: estimates})
  }

  const refresh = async (context: TransferContext): Promise<TransferContext> => {
    const displaying = context.displaying(userDefaults)
    return update(context, {
      entryMode: context.entryMode,
      enteredMoney: displaying[`${context.entryMode}Money`],
      calculatedCurrency: displaying[`${context.entryMode === "source" ? "destination" : "source"}Money`].currency
    })
  }

  return {make, update, refresh}
}

export class TransferContext {
  readonly transferEstimates: Estimate[];
  readonly currencyAvailability: CurrencyAvailability;
  readonly recipient?: Recipient;
  readonly reference?: string;

  readonly isRecipientPresent: boolean = false;

  readonly observeLastSelectedQuoteOptionKindPreferences: boolean = false;
  readonly selectedDeliveryOption?: DeliveryOption;
  readonly selectedQuoteOption?: QuoteOption;

  constructor(
    {
      transferEstimates,
      currencyAvailability,
      recipient,
      reference,
      isRecipientPresent = false,
      observeLastSelectedQuoteOptionKindPreferences = false,
      selectedDeliveryOption,
      selectedQuoteOption
    }: ITransferContext) {
    if (transferEstimates.length === 0 ) {
      throw new Error("Transfer estimates must not be empty")
    }
    this.transferEstimates = transferEstimates;
    this.currencyAvailability = currencyAvailability;
    this.recipient = recipient;
    this.reference = reference;
    this.isRecipientPresent = isRecipientPresent;
    this.observeLastSelectedQuoteOptionKindPreferences = observeLastSelectedQuoteOptionKindPreferences;
    this.selectedDeliveryOption = selectedDeliveryOption;
    this.selectedQuoteOption = selectedQuoteOption;
  }

  get entryMode() {
    // Be careful, but I don't think we would ever have different entry mode of estimates in the same context.
    return this.transferEstimates[0].entryMode
  }

  get deliveryCandidateTransferEstimates(): Estimate[] {
    return Arrays.uniqueWith(this.transferEstimates, {
      areEqual: (a, b) => a.deliveryOption.kind === b.deliveryOption.kind,
      tiebreaker: (a, _) => !a.disabled
    })
  }

  get quoteCandidateTransferEstimates(): Estimate[] {
    let candidateTransferEstimates = this.transferEstimates
    if (this.selectedDeliveryOption) {
      candidateTransferEstimates = candidateTransferEstimates.filter((estimate) => estimate.deliveryOption.kind === this.selectedDeliveryOption?.kind)
    }

    return Arrays.uniqueWith(candidateTransferEstimates, {
      areEqual: (a, b) => a.quoteOption.kind === b.quoteOption.kind,
      tiebreaker: (a, _) => !a.disabled
    })
  }

  get isDeliveryChoiceAvailable(): boolean {
    return this.deliveryCandidateTransferEstimates.length > 1
  }

  transferEstimate(userDefaults: UserDefaults): Estimate {
    const self = this
    const deliveryMatchingEstimates: Estimate[] = function () {
      const lastSelectedDeliveryOptionKindMatchingEstimates: Estimate[] = function () {
        const lastSelectedDeliveryOptionKind = userDefaults.lastSelectedDeliveryOptionKind
        if (lastSelectedDeliveryOptionKind === undefined) return []

        return self.transferEstimates.filter((estimate) => {
          if (estimate.disabled) return false
          return lastSelectedDeliveryOptionKind === estimate.deliveryOption.kind
            && !estimate.deliveryOption.isInstant
        })
      }()

      if (self.selectedDeliveryOption) {
        return self.transferEstimates.filter((estimate) => estimate.deliveryOption.kind === self.selectedDeliveryOption?.kind)
      } else if (lastSelectedDeliveryOptionKindMatchingEstimates.length > 0) {
        return lastSelectedDeliveryOptionKindMatchingEstimates
      } else {
        return self.transferEstimates.filter((estimate) => estimate.deliveryOption.fee.amount.eq(0))
      }
    }()

    const quoteMatchingEstimates: Estimate[] = function () {
      const lastSelectedQuoteOptionKindMatchingEstimates = deliveryMatchingEstimates.filter(
        (estimate) => estimate.quoteOption.kind === userDefaults.lastSelectedQuoteOptionKind
      )

      if (self.selectedQuoteOption) {
        return deliveryMatchingEstimates.filter((estimate) => estimate.quoteOption.kind === self.selectedQuoteOption?.kind)
      } else if (self.observeLastSelectedQuoteOptionKindPreferences && lastSelectedQuoteOptionKindMatchingEstimates.length > 0) {
        return lastSelectedQuoteOptionKindMatchingEstimates
      } else {
        return deliveryMatchingEstimates.filter((estimate) => estimate.quoteOption.fee.amount.eq(0))
      }
    }()

    return _.first(quoteMatchingEstimates) ?? cheapest(self.transferEstimates)!
  }

  with(options: {
    transferEstimates?: Estimate[],
    recipient?: Recipient,
    observeLastSelectedQuoteOptionKindPreferences?: boolean,
    selectedDeliveryOption?: DeliveryOption,
    selectedQuoteOption?: QuoteOption,
    reference?: string
  }): TransferContext {
    const transferEstimates = options.transferEstimates ?? this.transferEstimates
    const recipient = options.recipient ?? this.recipient
    const observeLastSelectedQuoteOptionKindPreferences = options.observeLastSelectedQuoteOptionKindPreferences ?? this.selectedDeliveryOption

    let selectedDeliveryOption = options.selectedDeliveryOption ?? this.selectedDeliveryOption
    let selectedQuoteOption = options.selectedQuoteOption ?? this.selectedQuoteOption

    const matchingTransferEstimate = cheapest(transferEstimates.filter((estimate) => {
      if (estimate.disabled) return false

      const matchesDeliveryOption = function () {
        if (!selectedDeliveryOption) return true

        return estimate.deliveryOption.kind === selectedDeliveryOption.kind
      }()

      const matchesQuoteOption = function () {
        if (!selectedQuoteOption) return true

        return estimate.quoteOption.kind === selectedQuoteOption.kind
      }()

      return matchesDeliveryOption && matchesQuoteOption
    }))

    let newSelectedDeliveryOption: DeliveryOption | undefined
    if (selectedDeliveryOption) {
      newSelectedDeliveryOption = matchingTransferEstimate?.deliveryOption
    }

    let newSelectedQuoteOption: QuoteOption | undefined
    if (selectedQuoteOption) {
      newSelectedQuoteOption = matchingTransferEstimate?.quoteOption
    }

    if (selectedDeliveryOption) {
      // Delivery option can change the available quote options, so customers may have to reselect.
      newSelectedQuoteOption = undefined
    }

    return new TransferContext({
      ...this,
      transferEstimates,
      recipient,
      observeLastSelectedQuoteOptionKindPreferences,
      selectedDeliveryOption: newSelectedDeliveryOption,
      selectedQuoteOption: newSelectedQuoteOption,
      reference: options.reference ?? this.reference
    })
  }

  displaying(userDefaults: UserDefaults): TransferDisplaying {
    const transferEstimate = this.transferEstimate(userDefaults)
    return new TransferDisplaying({
      sourceMoney: transferEstimate.sourceMoney,
      destinationMoney: transferEstimate.destinationMoney,
      totalFee: transferEstimate.totalFee,
      fixedFee: transferEstimate.fixedFee,
      fixedFeeWithoutPromo: transferEstimate.fixedFeeWithoutPromo,
      quote: transferEstimate.quote,
      quoteOption: transferEstimate.quoteOption,
      deliveryOption: transferEstimate.deliveryOption,
      recipient: this.recipient,
      reference: this.reference,
      status: new TransferStatus("estimate"),
      didCustomerChooseQuoteOption: transferEstimate.didCustomerChooseQuoteOption,
      entryMode: this.entryMode,
      shouldWarnAboutBankLimits: transferEstimate.warnAboutBankLimits
    })
  }
}