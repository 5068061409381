import cx from "classnames";
import React from "react";
import "./Link.scss"
import {useNavigate} from "../../routes/router-dom-wrapper/hooks";

type Props = {
  className?: string
  href: string
  newTab: boolean
  fixOpacity?: boolean
  style?: React.CSSProperties
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void
} & React.PropsWithChildren

// Unstyled link
export const RawLink = React.forwardRef<HTMLAnchorElement, Props>((props: Props, ref) => {
  const navigate = useNavigate()
  const url = new URL(props.href, window.location.href)

  return (
    <a className={props.className}
       ref={ref}
       href={props.href}
       onClick={(e) => {
         if (!props.newTab && url.host === window.location.host) {
           e.preventDefault()
           navigate(props.href)
         }
       }}
       style={props.style}
       {...props.newTab ? {target: "_blank", rel: "noreferrer"} : {}}
    >{props.children}</a>
  )
})

// Styled link
export const Link = (props: Props) => {
  return <RawLink {...props} className={cx("link", {"link--fix-opacity": props.fixOpacity}, props.className)}/>
}
