import {NavigateExtraOptions, useNextCommonRouteState} from "./hooks";
import {NavigateProps, Navigate as NavigateLib} from "react-router-dom";

type Props = NavigateProps & NavigateExtraOptions

const Navigate = (props: Props) => {
  const commonRouteState = useNextCommonRouteState()

  return <NavigateLib {...props} state={commonRouteState(props)}/>
}

export default Navigate