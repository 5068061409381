export class Links {
  static atlantic = {
    app: {
      contactUs: "https://atlantic.money/app/contact-us",
    },
    auth: {
      signIn: "/sign-in",
      signUp: {
        individual: "/sign-up",
        business: "/business/sign-up"
      },
      forgotPassword: "/forgot-password",
    },
    onboarding: {
      signIn: "/sign-in",
      signUp: "/sign-up",
      questioner: "/questioner",
      download: "/download",
      individual: "/onboarding",
      business: "/business/onboarding",
    },
    verification: (params: { verificationId: string, ticket?: string }) =>
      `/verifications/${params.verificationId}${params.ticket ? `?ticket=${params.ticket}` : ""}`,
    home: "/",
    root: {
      send: "/send",
      recipients: "/recipients",
      history: (selectedTransferId?: string) => {
        if (selectedTransferId) {
          return `/history/${selectedTransferId}`
        }

        return "/history"
      },
      limits: "/limits",
      referral: "/referral",
      contactUs: "/contact-us",
      settings: "/settings",
    },
    transfer: {
      view: (transferId: string) => `/transfer/${transferId}`,
      timeline: (transferId: string) => `/transfer/${transferId}/timeline`,
      bulk: {
        create: "/transfers/bulk/create",
        review: (jobId: string) => `/transfers/bulk/review/${jobId}`,
        view: (jobId: string) => `/transfers/bulk/view/${jobId}`,
        checks: (jobId: string) => `/transfers/bulk/verifications/${jobId}`,
        payInWaiting: (jobId: string) => `/transfers/bulk/pay-in-waiting/${jobId}`,
        summary: (jobId: string) => `/transfers/bulk/summary/${jobId}`
      },
    },
    recipient: {
      details: (recipientId: string) => `/recipients/${recipientId}`,
    }
  }

  static helpCenter = {
    home: "https://support.atlantic.money",
    contactUs: {
      whatsApp: "https://wa.me/448081751167",
      iMessage: "sms://open?service=iMessage&recipient=urn:biz:5e30973b-363d-4788-b71a-effb88e6385e"
    },
    articles: {
      deliveryOptions: "https://support.atlantic.money/support/solutions/articles/101000360767",
      inr: "https://support.atlantic.money/a/solutions/articles/101000473867",
      keyPeople: "https://support.atlantic.money/a/solutions/articles/101000495643",
      localBankTransferOnly: "https://support.atlantic.money/en/support/solutions/articles/101000360805",
      marketingEmails: "https://support.atlantic.money/a/solutions/articles/101000407138",
      oneBankTransferPayment: "https://support.atlantic.money/en/support/solutions/articles/101000449029",
      payinReferenceNotRequired: "https://support.atlantic.money/support/solutions/articles/101000360806",
      payinRejected: "https://support.atlantic.money/a/solutions/articles/101000407740",
      payoutFailed: "https://support.atlantic.money/a/solutions/articles/101000362275",
      quoteOptions: "https://support.atlantic.money/support/solutions/articles/101000407684",
      tierUpgradeComplete: "https://support.atlantic.money/support/solutions/articles/101000360843",
      tierUpgradeInReview: "https://support.atlantic.money/support/solutions/articles/101000407152",
      tiers: "https://support.atlantic.money/support/solutions/articles/101000360843",
      portals: "https://support.atlantic.money/a/solutions/articles/101000528725",
      portalsOptionalReference: "https://support.atlantic.money/a/solutions/articles/101000528727",
      portalsDeliveryOptions: "https://support.atlantic.money/a/solutions/articles/101000528726"
    }
  }
}