import {V2ClientInfoResponse} from "../../api/whales/Client";
import {IPerson, Person} from "./person";
import {Address} from "../address";

export class ClientInfoIndividual extends Person {
  address: Address
  email: string
  phone: string

  constructor(other: Omit<IPerson, "address" | "email" | "phoneNumber"> & {
    address: Address
    email: string
    phone: string
  }) {
    super(other)
    this.address = new Address(other.address)
    this.email = other.email
    this.phone = other.phone
  }

  static fromApiFull(apiModel: NonNullable<V2ClientInfoResponse["payload"]["individual"]>): ClientInfoIndividual {
    if (!apiModel.homeAddress) throw new Error("Address is required")
    return new ClientInfoIndividual({
      firstName: apiModel.firstName,
      lastName: apiModel.lastName,
      address: Address.fromApi(apiModel.homeAddress),
      birthdate: (apiModel.birthdate && new Date(apiModel.birthdate)) || undefined,
      email: apiModel.email,
      phone: apiModel.phone
    })
  }
}

export type BusinessInfo = {
  legalName: string
  identifier: string
  registeredAddress: Address
  tradingAddress: Address
}

type BusinessContactRole = NonNullable<V2ClientInfoResponse["payload"]["business"]>["contacts"][0]["roles"][0]

export class BusinessContact extends Person {
  roles: BusinessContactRole[]

  constructor(other: IPerson & {roles: BusinessContactRole[]}) {
    super(other);
    this.roles = other.roles
  }

  static fromApi(apiModel: NonNullable<V2ClientInfoResponse["payload"]["business"]>["contacts"][0]): BusinessContact {
    return new BusinessContact({
      firstName: apiModel.firstName,
      lastName: apiModel.lastName,
      birthdate: new Date(apiModel.birthdate),
      address: Address.fromApi(apiModel.address),
      email: apiModel.email ?? undefined,
      phone: apiModel.phone ?? undefined,
      roles: apiModel.roles
    })
  }
}

export class ClientInfoBusiness {
  info: BusinessInfo
  contacts: BusinessContact[]

  constructor(other: {
    info: BusinessInfo
    contacts: BusinessContact[]
  }) {
    this.info = other.info
    this.contacts = other.contacts
  }

  applicant(): BusinessContact | undefined {
    return this.contacts.find((c) => c.roles.includes("applicant"))
  }

  static fromApi(apiModel: NonNullable<V2ClientInfoResponse["payload"]["business"]>): ClientInfoBusiness {
    return new ClientInfoBusiness({
      info: {
        legalName: apiModel.info.legalName,
        identifier: apiModel.info.identifier,
        registeredAddress: Address.fromApi(apiModel.info.registeredAddress),
        tradingAddress: Address.fromApi(apiModel.info.tradingAddress)
      },
      contacts: apiModel.contacts.map((c) => BusinessContact.fromApi(c))
    })
  }
}

type ClientInfoTyped = {
  kind: "individual"
  individual: ClientInfoIndividual
} | {
  kind: "business"
  business: ClientInfoBusiness
}

export class ClientInfo {
  readonly userType: ClientInfoTyped["kind"]
  readonly typed: ClientInfoTyped

  constructor(other: {
    typed: ClientInfoTyped
  }) {
    this.userType = other.typed.kind
    this.typed = other.typed
  }

  accountTitle(): string {
    switch (this.typed.kind) {
      case "individual":
        return this.typed.individual.name()
      case "business":
        return this.typed.business.info.legalName
    }
  }

  personalInfo(): Person | undefined {
    switch (this.typed.kind) {
      case "individual":
        return this.typed.individual
      case "business":
        return this.typed.business.applicant()
    }
  }

  static fromApi(apiModel: V2ClientInfoResponse["payload"]): ClientInfo {
    return new ClientInfo(function () {
      if (apiModel.userType === "individual" && apiModel.individual !== undefined) {
        return {
          typed: {
            kind: "individual",
            individual: ClientInfoIndividual.fromApiFull(apiModel.individual)
          }
        }
      }

      if (apiModel.userType === "business" && apiModel.business !== undefined) {
        return {
          typed: {
            kind: "business",
            business: ClientInfoBusiness.fromApi(apiModel.business)
          }
        }
      }

      throw new Error(`Unknown user type: ${apiModel.userType} or info is undefined`)
    }())
  }
}