import {BranchStorage} from "../../storage/branch";
import {branchScript} from "./raw-scripts";
import {TrackingService} from "./TrackingService";

export class BranchService implements TrackingService {
  constructor(private readonly key: string) {
    this.key = key
  }

  init(): void {
    branchScript()
    this.withBranch((branch => {
      branch.init(this.key, {no_journeys: true, tracking_disabled: true}, (err, data) => {
        if (err) {
          console.error("error from branch.io", err)
          return
        }
        this.setData(data)
      })
    }))
  }

  grantConsent(): void {
    this.disableTracking(false)
  }

  private withBranch(f: (_branch: NonNullable<typeof branch>) => void) {
    if (typeof branch === 'undefined') {
      setTimeout(() => this.withBranch(f), 200)
      return
    }
    f(branch)
  }

  private setData(data: BranchData) {
    if (data.data_parsed && data.data_parsed["+clicked_branch_link"]) {
      BranchStorage.setData(data)
    }
  }

  disableTracking(flag: boolean) {
    this.withBranch((branch) => {
      branch.disableTracking(flag)
    })
  }

  async data(): Promise<BranchData | undefined> {
    const storageData = BranchStorage.getData()
    if (storageData) {
      return storageData
    }

    return new Promise<BranchData>((resolve, reject) => {
      let timeoutPassed = false
      setTimeout(() => {
        timeoutPassed = true
        reject(Error("branch request timed out"))
      }, 2000)
      const getBranchData = () => {
        if (timeoutPassed) {
          return;
        }
        if (branch === undefined) {
          setTimeout(() => {
            getBranchData()
          }, 200)
          return;
        }
        branch.data((err, data) => {
          if (timeoutPassed) return
          if (err) {
            reject(err)
          } else {
            this.setData(data)
            resolve(data)
          }
        })
      }
      getBranchData()
    }).catch((err) => {
      console.error("branch data err", err);
      return undefined
    })
  }
}
