import React, {useId} from "react";
import "./SegmentedControl.scss";
import cx from "classnames";
import Caption from "../Caption";

type SegmentedControlItem = {
  value: string
  label: React.ReactNode
}

type Props = {
  name: string
  segments: SegmentedControlItem[]
  value: string
  onChange: (value: string) => void
}

const Segment = ({name, item, ...props}: {
  name: string,
  item: SegmentedControlItem,
  active: boolean,
  onChange: (value: string) => void
}) => {
  const id = useId()
  return (
    <div
      key={item.value}
      className={cx("segmented-control__segment", {"segmented-control__segment--active": props.active})}
    >
      <input
        type="radio"
        value={item.value}
        id={id}
        name={name}
        onChange={() => props.onChange(item.value)}
        checked={props.active}
      />

      <label htmlFor={id}>
        <Caption size={"3-title"}>{item.label}</Caption>
      </label>
    </div>
  )
}

const SegmentedControl = ({name, segments, value, onChange}: Props) => {
  return (
    <div className="segmented-control">
      <div className={`segmented-control__controls`}>
        {segments?.map((item) => (
          <Segment
            key={item.value}
            name={name}
            item={item}
            active={value === item.value}
            onChange={onChange}
          />
        ))}
      </div>
    </div>
  )
}

export default SegmentedControl