import {VerificationProps} from "./verification-controller";
import {InterfaceTemplates, InterfaceTemplateValues} from "../domain/interface-template/domain";
import {useVerificationApi} from "../../api/verification";
import InterfaceTemplateSingleSelectScreen
  from "../domain/interface-template/screens/InterfaceTemplateSingleSelectScreen";
import { useState } from "react";

type Props = VerificationProps & {
  template: InterfaceTemplates.SingleSelect
}

const VerificationSingleSelectStepSubmit = (props: Props) => {
  const verificationApi = useVerificationApi()
  const [otherValue, setOtherValue] = useState<InterfaceTemplateValues.Text>({
    kind: "text",
    textValue: ""
  })
  return (
    <InterfaceTemplateSingleSelectScreen
      titleAlign={props.titleAlign}
      template={props.template}
      otherValue={otherValue}
      onChangeOtherValue={setOtherValue}
      onComplete={async (value) => {
        await verificationApi.submit({
          verificationId: props.verification.id,
          value: {
            kind: "singleSelect",
            singleSelectValue: {
              option:  value.singleSelectValue.option.toApi(),
              otherText: value.singleSelectValue.otherText !== undefined ? {
                text: value.singleSelectValue.otherText
              } : undefined
            }
          }
        })
        await props.onComplete()
      }}
    />
  )
}

export default VerificationSingleSelectStepSubmit