import {withParametricRouter} from "../../routes/parameteric-router";
import {Links} from "../../helpers/Links";
import Navigate from "../../routes/router-dom-wrapper/Navigate";

const DeeplinkTransferPage = withParametricRouter({
  requiredQueryKeys: ["id"]
})((props) => {
  return <Navigate to={Links.atlantic.transfer.timeline(props.id)} replace={true} isBackAllowed={false}/>
})

export default DeeplinkTransferPage