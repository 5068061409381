import React, {useEffect} from "react"
import {ComponentNavStackEntry, NavStack, useNavStack, useNavStackValue} from "../../atoms/nav-stack";
import Limiter from "../ui/Limiter";
import NavBar from "./NavBar";
import {useEffectOnce} from "../../hooks/effect";

type NavStackRootProps<T, Ctx> = {
  root: ComponentNavStackEntry<T, Ctx>["component"]
  args: ComponentNavStackEntry<T, Ctx>["args"]
  ctx: Ctx
  wrapper?: (navStack: NavStack<T, Ctx>, children: React.ReactNode) => JSX.Element
}

export const NavStackDefaultWrapper = <T, Ctx>(navStack: NavStack<T, Ctx>, children: React.ReactNode) => {
  return (
    <Limiter className={"flex-1"}>
      <NavBar className={"px-0"}/>
      {children}
    </Limiter>
  )
}

export const NavStackRoot = <Ctx, T>(props: NavStackRootProps<T, Ctx>): JSX.Element | null => {
  const navStack = useNavStack()
  const state = useNavStackValue()
  const wrapper = props.wrapper

  useEffectOnce(() => {
    props.root && navStack.push<T, Ctx>(props.root, {...props.ctx, ...(props.args)})
    return () => {
      navStack.clear()
    }
  })

  const lastEntry = state.stackEntries[state.stackEntries.length - 1]

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [lastEntry]);

  if (!lastEntry) {
    // WEB-340: loading animation https://atlantic-money.atlassian.net/browse/WEB-340
    return null
  }

  const inner = <lastEntry.component {...lastEntry.args} {...props.ctx} />
  return wrapper ? wrapper(navStack, inner) : inner
}