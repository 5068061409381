import {Money} from "../../helpers/money";
import {SingleRefundInfoResponse} from "../../api/whales/Transfer.v3";

interface ISingleRefundInfo {
  singleTransferId: string;
  refundAmount: Money;
  refundBankAccountLast4: string;
}

export class SingleRefundInfo implements ISingleRefundInfo {
  singleTransferId: string;
  refundAmount: Money;
  refundBankAccountLast4: string;

  constructor(other: ISingleRefundInfo) {
    this.singleTransferId = other.singleTransferId;
    this.refundAmount = other.refundAmount;
    this.refundBankAccountLast4 = other.refundBankAccountLast4;
  }

  static fromApi(model: SingleRefundInfoResponse["payload"]): SingleRefundInfo {
    return new SingleRefundInfo({
      singleTransferId: model.singleTransferId,
      refundAmount: Money.fromApi(model.refundAmount),
      refundBankAccountLast4: model.refundBankAccountLast4
    });
  }
}