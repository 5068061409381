import Formatter from "./Formatter";

type Props = {
  value: string
  formatter: Formatter
}

const FormattedString = ({ value, formatter }: Props) => {
  return <>{formatter.format(value)}</>
}

export default FormattedString