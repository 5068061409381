import SmoothCollapse from "react-smooth-collapse";
import React from "react";

type Props = {
    className?: string;
    expanded: boolean;

    overflowHidden?: boolean;
    onExpanded?: () => void
    onCollapsed?: () => void

    children?: React.ReactNode
}

export const Collapsable = (props: Props) => {
    return <SmoothCollapse
        className={props.className}
        expanded={props.expanded}
        allowOverflowWhenOpen={!props.overflowHidden}
        onChangeEnd={() =>
            props.expanded ?
                props.onExpanded && props.onExpanded() :
                props.onCollapsed && props.onCollapsed()
        }>
        {props.children}
    </SmoothCollapse>
}

export default Collapsable;