import * as TransferV3Models from "../../api/whales/Transfer.v3";
import * as TransferCommon from "../../api/whales/TransferCommon";
import {Transfer} from "./Transfer";

export type TimelineElementId = TransferCommon.TimelineElementIdentifier
export type TimelineElementState = TransferCommon.TimelineLineState
export type TimelineElementLineState = TransferCommon.TimelineLineState
export type TimelineElementIconConfiguration = TransferCommon.TimelineIconConfiguration

export class TimelineElement {
  identifier: TimelineElementId
  order: number
  title: string
  subtitle?: string
  state: TimelineElementState
  upperLineState: TimelineElementLineState
  lowerLineState: TimelineElementLineState
  iconConfiguration: TimelineElementIconConfiguration

  constructor(other: {
    identifier: TimelineElementId,
    order: number,
    title: string,
    subtitle?: string,
    state: TimelineElementState,
    upperLineState: TimelineElementLineState,
    lowerLineState: TimelineElementLineState,
    iconConfiguration: TimelineElementIconConfiguration
  }) {
    this.identifier = other.identifier
    this.order = other.order
    this.title = other.title
    this.subtitle = other.subtitle
    this.state = other.state
    this.upperLineState = other.upperLineState
    this.lowerLineState = other.lowerLineState
    this.iconConfiguration = other.iconConfiguration
  }

  static fromApi(model: TransferCommon.TimelineElement): TimelineElement {
    return new TimelineElement({
      identifier: model.identifier,
      order: model.order,
      title: model.title,
      subtitle: model.subtitle,
      state: model.state,
      upperLineState: model.upperLineState,
      lowerLineState: model.lowerLineState,
      iconConfiguration: model.iconConfiguration
    })
  }
}

export class Timeline {
  transfer: Transfer
  elements: TimelineElement[]

  constructor(other: { transfer: Transfer, elements: TimelineElement[] }) {
    this.transfer = other.transfer
    this.elements = other.elements
  }

  static fromApi(model: TransferV3Models.SingleTransferTimelineResponse): Timeline {
    return new Timeline({
      transfer: Transfer.fromApi(model.payload.singleTransfer),
      elements: model.payload.elements.map(e => TimelineElement.fromApi(e))
    })
  }
}