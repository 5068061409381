import {Outlet, Route, Routes} from "react-router-dom";
import DeeplinkTransferPage from "../pages/deeplink/DeeplinkTransferPage";
import DeeplinkVerificationPage from "../pages/deeplink/DeeplinkVerificationPage";
import DeeplinkOpenInAppPage from "../pages/deeplink/DeeplinkOpenInAppPage";
import DeeplinkTiersPage from "../pages/deeplink/DeeplinkTiersPage";
import {BuildConfig} from "build-config";
import CompareBlock from "../components/compare/CompareBlock";
import QrCodeGeneratorPage from "../pages/development/QrCodeGeneratorPage";
import NotFoundPage from "../pages/generic/NotFoundPage";
import PasswordResetPage from "../pages/auth/password-reset/PasswordResetPage";
import SignInPage from "../pages/auth/sign-in/SignInPage";
import SignUpPage from "../pages/auth/sign-up/SignUpPage";
import {Links} from "../helpers/Links";
import {useAuthValue} from "../atoms/auth";
import BusinessOnboardingScreen from "../components/onboarding/business/BusinessOnboardingScreen";
import IndividualOnboardingPage from "../components/onboarding/individual/OnboardingScreen";
import DownloadScreen from "../components/onboarding/DownloadScreen";
import QuestionerScreen from "../components/onboarding/QuestionerScreen";
import MainLayout from "../components/ui/layout/MainLayout";
import RootTransferPage from "../pages/transfer/create/RootTransferPage";
import {RootRecipientsPage} from "../pages/recipients/RecipientListPage";
import {RecipientDetailsPage} from "../pages/recipients/RecipientDetailsPage";
import ViewTransferPage from "../pages/transfer/ViewTransferPage";
import TimelinePage from "../pages/transfer/timeline/TimelinePage";
import TransferHistoryPage from "../pages/transfer/history/TransferHistoryPage";
import LimitsPage from "../pages/limits/LimitsPage";
import RootSettingsPage from "../pages/settings/RootSettingsPage";
import {ErrorBoundaryLayout} from "../components/ui/layout/ErrorBoundaryLayout";
import {useLocation} from "./router-dom-wrapper/hooks";
import Navigate from "./router-dom-wrapper/Navigate";
import {useSessionRouter} from "./hooks";
import VerificationsPage from "../pages/verification/VerificationsPage";
import ContactUsPage from "../pages/support/ContactUsPage";
import DeeplinkContactUsPage from "../pages/deeplink/DeeplinkContactUsPage";
import {isAndroid, isIOS} from "react-device-detect";
import {useConfig} from "../hooks/config";
import * as Sentry from "@sentry/react";

const NonAuthorizedRoute = () => {
  const config = useConfig()
  const authValue = useAuthValue()
  const location = useLocation()

  const defaultPostAuthRedirect = config.enableFullWebAppExperience ? Links.atlantic.root.send : Links.atlantic.onboarding.download


  if (authValue.authenticated) {
    return <Navigate to={location.state?.postAuthRedirectLocation ?? defaultPostAuthRedirect} replace={true}/>
  }

  return <Outlet/>
}

const _AuthorizedRoute = () => {
  const authValue = useAuthValue()

  if (!authValue.authenticated) {
    return <Navigate to={Links.atlantic.auth.signIn} replace={true} isBackAllowed={false} openLocationAfterAuth={true}/>
  }

  return <Outlet/>
}

const OnboardedRoute = () => {
  const authValue = useAuthValue()
  const sessionRouterData = useSessionRouter()

  if (!authValue.authenticated) {
    return (
      <Navigate
        to={Links.atlantic.auth.signIn}
        replace={true}
        openLocationAfterAuth={true}
      />
    )
  }

  if (sessionRouterData.error) {
    throw sessionRouterData.error
  }

  if (sessionRouterData.isOnboarded === undefined) {
    return null
  }

  if (sessionRouterData.isOnboarded) {
    return <Outlet/>
  }
  return <Navigate to={Links.atlantic.onboarding.individual} replace={true} isBackAllowed={false}/>
}

const NonOnboardedRoute = () => {
  const config = useConfig()
  const authValue = useAuthValue()
  const onboardingData = useSessionRouter()

  if (!authValue.authenticated) {
    return (
      <Navigate
        to={Links.atlantic.auth.signIn}
        replace={true}
        openLocationAfterAuth={true}
      />
    )
  }

  if (onboardingData.error) {
    throw onboardingData.error
  }

  if (onboardingData.isOnboarded === undefined) {
    return null
  }

  if (!onboardingData.isOnboarded) {
    return <Outlet/>
  }

  return <Navigate
    to={config.enableFullWebAppExperience ? Links.atlantic.root.send : Links.atlantic.onboarding.download}
    replace={true}/>
}

const DevRoute = () => {
  if (!BuildConfig.devRoutes) return null

  return <Outlet/>
}

const DesktopOnlyRoute = () => {
  const isMobile = isAndroid || isIOS

  if (isMobile) {
    return <DownloadScreen/>
  }

  return <Outlet/>
}

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const AppRouter = () => {
  const config = useConfig()

  return (
    <SentryRoutes>
      <Route element={<ErrorBoundaryLayout/>}>
        <Route path={"deeplink"}>
          <Route path={"transfers"} element={<DeeplinkTransferPage/>}/>
          <Route path={"tiers"} element={<DeeplinkTiersPage/>}/>
          <Route path={"contact-us"} element={<DeeplinkContactUsPage/>}/>
          <Route path={"verifications"} element={<DeeplinkVerificationPage/>}/>
          <Route path={"customer-support-auth"} element={<DeeplinkOpenInAppPage/>}/>
          <Route path={"review"} element={<DeeplinkOpenInAppPage/>}/>
          {/*TODO: Change this after referral page is ready*/}
          <Route path={"referrals-link-payout-account"} element={<DeeplinkOpenInAppPage/>}/>
        </Route>

        {/* Same page for auth and non auth users*/}
        <Route path="/" element={<MainLayout/>}>
          <Route path={"/contact-us"} element={<ContactUsPage/>}/>
          <Route element={<DesktopOnlyRoute/>}>
            <Route path={"/verifications/:id"} element={<VerificationsPage/>}/>
          </Route>
        </Route>

        <Route element={<NonAuthorizedRoute/>}>
          <Route index element={<Navigate replace={true} to={Links.atlantic.auth.signIn} isBackAllowed={false}/>}/>
          <Route path={"/forgot-password"} element={<PasswordResetPage/>}/>
          <Route path={"/sign-in"} element={<SignInPage/>}/>
          <Route path={"/sign-up"} element={<SignUpPage userType={"individual"}/>}/>
          <Route path={"/business/sign-up"} element={<SignUpPage userType={"business"}/>}/>
        </Route>

        <Route element={<NonOnboardedRoute/>}>
          <Route index
                 element={<Navigate to={Links.atlantic.onboarding.individual} replace={true}
                                    isBackAllowed={false}/>}/>
          <Route path="/onboarding" element={<IndividualOnboardingPage/>}/>
          <Route path="/business/onboarding" element={<BusinessOnboardingScreen/>}/>
        </Route>
        <Route element={<OnboardedRoute/>}>
          <Route path="/download" element={<DownloadScreen/>}/>
          <Route path="/questioner" element={<QuestionerScreen/>}/>
          {config.enableFullWebAppExperience && (
            <Route element={<DesktopOnlyRoute/>}>
              <Route path="/" element={<MainLayout/>}>
                <Route index element={<Navigate to="/send" replace={true} isBackAllowed={false}/>}/>
                <Route path="/send" element={<RootTransferPage/>}/>
                <Route path="/recipients">
                  <Route index path={""} element={<RootRecipientsPage/>}/>
                  <Route path={":id"} element={<RecipientDetailsPage/>}/>
                </Route>
                <Route path="/transfer">
                  <Route path=":id" element={<ViewTransferPage/>}/>
                  <Route path=":id/timeline" element={<TimelinePage/>}/>
                </Route>
                <Route path={"/history"} element={<TransferHistoryPage onSelectTransfer={undefined}/>}/>
                <Route path={"/history/:id"} element={<TransferHistoryPage onSelectTransfer={undefined}/>}/>
                <Route path={"/limits"} element={<LimitsPage/>}/>
                <Route path={"/settings"} element={<RootSettingsPage/>}/>
              </Route>
            </Route>
          )}
        </Route>

        <Route element={<DevRoute/>}>
          <Route path={"/compare"} element={
            <div className={"min-h-[var(--100vh)] flex flex-col justify-start items-center"}>
              <div className={"max-w-[580px] w-full px-[24px]"}>
                <CompareBlock/>
              </div>
            </div>
          }/>
          <Route path={"/qrcode"} element={<QrCodeGeneratorPage/>}/>
        </Route>

        <Route path="/*" element={
          <div className={"min-h-screen flex flex-col"}>
            <NotFoundPage/>
          </div>
        }/>
      </Route>
    </SentryRoutes>
  )
}
export default AppRouter