import Icon, {IconName} from "../../icons/Icon";
import Caption from "../../Caption";
import {PropsWithChildren} from "react";
import AbstractRow from "./AbstractRow";
import cx from "classnames";
import "./IconBodyRow.scss"
import {PropsClassName} from "../../../../helpers/props";

export type Props = {
  iconName: IconName
} & PropsWithChildren & PropsClassName

const IconBodyRow = (props: Props) => {
  return (
    <AbstractRow {...props} className={cx(props.className, "icon-body-row")}>
      <div className={"mr-2 self-start"}><Icon name={props.iconName}/></div>
      <div><Caption text={props.children} size={"3"}/></div>
    </AbstractRow>
  )
}

export default IconBodyRow;