import {DeliveryOptionKind} from "../../models/transfers/Estimate";
import {useStrings} from "../../strings";

type Props = {
  kind: DeliveryOptionKind
}

const DeliveryOptionKindView = ({kind}: Props) => {
  const strings = useStrings()

  return strings[`delivery_option.${kind}`]
}

export default DeliveryOptionKindView;