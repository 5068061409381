import {InputGroup, InputGroupRow} from "../../../components/ui/input/Inputs";
import {InterfaceTemplateTitle} from "../../../components/domain/interface-template/InterfaceTemplateTitle";
import {InterfaceTemplateInput} from "../../../components/domain/interface-template/InterfaceTemplateInput";
import {
  InterfaceTemplate,
  InterfaceTemplateValue,
  InterfaceTemplateValues
} from "../../../components/domain/interface-template/domain";
import {useRecipientsApi, useRecipientsQuery} from "../../../api/recipients";
import {BankAccountElement} from "../../../components/domain/recipients/domain/BankAccountElement";
import {RecipientCreateContext} from "./recipient-create-controller";
import React from "react";
import {useStrings} from "../../../strings";
import IbanInput from "../../../components/ui/input/IbanInput";
import {VerboseError} from "../../../api/axios";


export type InputState = {
  element: BankAccountElement
  error: any
}

type Props = {
  context: RecipientCreateContext
  inputState: InputState
  disabled?: boolean
  suggestedCountryCode: string | undefined
  onChanges: (value: InterfaceTemplateValues.Value) => void
}

const RecipientTemplateInputRow = ({inputState, ...props}: Props) => {
  const strings = useStrings()
  const recipientsApi = useRecipientsApi();
  const recipientQuery = useRecipientsQuery()
  const elementId = inputState.element.identifier

  const [hint, setHint] = React.useState<string | undefined>(undefined)

  recipientQuery.bankAccount.element.validate.useQuery({
    recipientType: props.context.kind!,
    currencyCode: props.context.currency?.code!,
    elementId: elementId,
    element: InterfaceTemplateValue.toApi(inputState.element.value),
  }, {
    onSettled: (data, error) => setHint(() => {
      const respHint =  data?.payload.hint
      if (respHint) return respHint

      if (error === undefined) return undefined

      return VerboseError.fromError(error)?.errorDetails?.["hint"]
    })
  })

  const error = inputState.error

  const interfaceTemplate = inputState.element.template?.interfaceTemplate
  if (!interfaceTemplate) throw new Error("interface template is undefined")


  const defaultTitle = interfaceTemplate
    ? <InterfaceTemplateTitle template={interfaceTemplate}/>
    : undefined
  const defaultComponent = (
    opts: {
      template: InterfaceTemplate,
      value: InterfaceTemplateValues.Value
      isLoading?: boolean
    }) => (
    <InterfaceTemplateInput
      capitalization={"words"}
      suggestedCountryCode={props.suggestedCountryCode}
      template={opts.template}
      value={opts.value}
      error={error}
      disabled={props.disabled}
      isLoading={opts.isLoading ?? false}
      onChange={props.onChanges}
      onValidate={(value) => {
        return recipientsApi.bankAccountElementValidate({
          recipientType: props.context.kind!,
          currencyCode: props.context.currency?.code!,
          elementId: elementId,
          element: InterfaceTemplateValue.toApi(value),
        })
      }}
    />
  )

  const commonInputOpts = {
    template: interfaceTemplate,
    value: inputState.element.value,
    isLoading: false
  }

  const row = function (): {
    title: React.ReactNode
    component: React.ReactNode
  } {
    if (inputState.element.template?.interfaceTemplate.rawKind === "text") {
      if (inputState.element.template?.identifier === "iban") {
        const value = inputState.element.value.kind === "text" ? inputState.element.value.textValue : ""
        return {
          title: defaultTitle,
          component: <IbanInput
            value={value}
            hint={hint}
            error={error}
            disabled={props.disabled}
            isLoading={commonInputOpts.isLoading ?? false}
            onChange={(newValue) => props.onChanges({kind: "text", textValue: newValue})}
            onValidate={(value) => {
              return recipientsApi.bankAccountElementValidate({
                recipientType: props.context.kind!,
                currencyCode: props.context.currency?.code!,
                elementId: elementId,
                element: InterfaceTemplateValue.toApi({kind: "text", textValue: value}),
              })
            }}
          />
        }
      }
      if (inputState.element.template?.identifier === "holderName") {
        switch (props.context.kind) {
          case "own":
            return {
              title: strings["add_recipient.holder_name.my_account.title"],
              component: defaultComponent(commonInputOpts)
            }
          case "person":
            return {
              title: strings["add_recipient.holder_name.person.title"],
              component: defaultComponent(commonInputOpts)
            }
          case "business":
            return {
              title: strings["add_recipient.holder_name.business.title"],
              component: defaultComponent(commonInputOpts)
            }
        }
      }
    }

    return {
      title: defaultTitle,
      component: defaultComponent(commonInputOpts)
    }
  }();

  return (
    <InputGroupRow key={inputState.element.identifier}>
      <InputGroup name={row.title}>
        {row.component}
      </InputGroup>
    </InputGroupRow>
  )
}

export default RecipientTemplateInputRow;