import React from "react";
import {RecipientType} from "../../../api/whales/Recipients";
import {useStrings} from "../../../strings";

const RecipientTypeValue = ({ value }: { value: RecipientType }) => {
  const strings = useStrings();
  switch (value) {
    case "own":
      return <>{strings["transfer.recipient.section_title.my_accounts"]}</>
    case "person":
      return <>{strings["transfer.recipient.section_title.people"]}</>
    case "business":
      return <>{strings["transfer.recipient.section_title.businesses"]}</>
  }
}

export default RecipientTypeValue;