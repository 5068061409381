import {useTransfersQuery} from "../../../api/transfers";
import {useAlertStack} from "../../../providers/alert-stack";
import TransferSourceAmountIncreasedAlertView from "./TransferSourceAmountIncreasedAlertView";
import {Transfer} from "../../../models/transfers/Transfer";
import TransferFrozenQuoteUnavailableAlertView from "./TransferFrozenQuoteUnavailableAlertView";
import {Timeline} from "../../../models/transfers/Timeline";
import {useNavStack} from "../../../atoms/nav-stack";
import TimelineStepMain from "./TimelineStepMain";
import TimelineStepSendPayment from "./TimelineStepSendPayment";
import {useChecksController} from "../../../components/verification/verification-controller";
import {poll} from "../../../helpers/poller";
import {EntityChecksExt} from "../../../components/domain/common/check";

const timelinePollingInterval = 1_000

type Options = {
  transferId: string | undefined
  onSuccess?: (t: Timeline) => void
  onError?: (e: any) => void
}

export const useTransferTimelineController = ({transferId, ...options}: Options) => {
  const navStack = useNavStack()
  const errorStack = useAlertStack()

  const checksController = useChecksController()

  const transfersQuery = useTransfersQuery()
  const confirmQuote = transfersQuery.confirmQuote.useMutation()
  const markPayInInitiated = transfersQuery.markPayInInitiated.useMutation()
  const markPayInWaiting = transfersQuery.markPayInWaiting.useMutation()
  const {
    data: timeline,
    refetch: refetchTimeline
  } = transfersQuery.timeline.useQuery(transferId, {
    interval: timelinePollingInterval,
    onSuccess: options.onSuccess,
    onError: options.onError
  })

  const onPaymentConfirmation = timeline && (async () => {
    try {
      await markPayInInitiated.mutateAsync(timeline.transfer.transferId)
      await refetchTimeline()
      navStack.popUntil({
        component: TimelineStepMain,
        inclusive: false,
      })
    } catch (e) {
      errorStack.showError(e)
    }
  })

  const onIHaventPaid = timeline && (async () => {
    try {
      await markPayInWaiting.mutateAsync(timeline.transfer.transferId)
      await refetchTimeline()
      navStack.push(TimelineStepSendPayment, {transferId: timeline.transfer.transferId})
    } catch (e) {
      errorStack.showError(e)
    }
  })

  const onPayInWaiting = timeline && (async () => {
    const success = async (t: Transfer) => {
      navStack.push(TimelineStepSendPayment, {transferId: t.transferId})
    }
    try {
      const confirmResult = await confirmQuote.mutateAsync(timeline.transfer.transferId)
      const transfer = Transfer.fromApi(confirmResult.payload.singleTransfer!)
      switch (confirmResult.payload.action) {
        case "source-amount-increased":
          errorStack.showPopUp(ctx => (
            <TransferSourceAmountIncreasedAlertView
              ctx={ctx}
              transfer={transfer}
              onCancel={ctx.onDismiss}
              onContinue={() => success(Transfer.fromApi(confirmResult.payload.singleTransfer!))}
            />
          ))
          break;
        case "frozen-quote-unavailable":
          errorStack.showPopUp(ctx => (
            <TransferFrozenQuoteUnavailableAlertView
              ctx={ctx}
              transfer={transfer}
              onCancel={ctx.onDismiss}
              onContinue={() => success(Transfer.fromApi(confirmResult.payload.singleTransfer!))}
            />
          ))
          break;
        default:
          await success(transfer)

      }
    } catch (e) {
      errorStack.showError(e)
    }
  })

  const routeForChecks = async (timeline: Timeline) => {
    const onComplete = async () => {
      await poll(() => refetchTimeline(), {
        maxDurationMs: 2 * 1000,
        intervalMs: 500,
        pollCondition: (t) =>
          !t.data ? false : EntityChecksExt.isUserInputRequired(t.data.transfer.checks),
      }).pollThen({
        onSatisfied: (t) => {
          t.data && routeForChecks(t.data)
        },
        onExpire: () => {
          navStack.popUntil({component: TimelineStepMain, inclusive: false})
        }
      })
    }

    return checksController(timeline.transfer.checks, {
      titleAlign: "left",
      sourceStackEntry: TimelineStepMain,
      onFinished: onComplete
    })
  }

  return {
    timeline,
    onPaymentConfirmation,
    onIHaventPaid,
    onPayInWaiting,
    routeForChecks: timeline && (() => routeForChecks(timeline))
  }
}