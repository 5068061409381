import {InterfaceTemplates, InterfaceTemplateValues} from "../domain";
import {CommonInterfaceTemplateProps} from "./common";
import {Picker, PickerOption} from "../../../ui/Picker";
import Collapsable from "../../../ui/Collapsable";
import {InterfaceTemplateTextInput} from "./InterfaceTemplateTextInput";
import SingleSelectOption = InterfaceTemplateValues.SingleSelectOption;

export const InterfaceTemplateMultiSelectInput = (
  {template, value, ...props}:
    CommonInterfaceTemplateProps<InterfaceTemplates.MultiSelect, InterfaceTemplateValues.MultiSelect>
) => {
  const options: PickerOption[] = template.options.map((option) => ({
    value: option.id,
    label: option.title,
  }))

  return (
    <>
      <Picker
        placeholder={template.title ?? ""}
        value={options.find((option) => option.value === value.multiSelectValue.options[0]?.id) ?? null}
        options={options}
        errored={props.error}
        onChange={
          (newValue) => {
            props.onChange({
              kind: "multiSelect",
              multiSelectValue: {
                options: [new SingleSelectOption({
                  id: newValue?.value ?? "",
                  title: newValue?.value ?? "",
                })],
              }
            })
          }
        }
      />
      {template.otherTextEntry && (
        <div className={"pt-[24px]"}>
          <Collapsable expanded={value.multiSelectValue.options.findIndex(o => o.isOther) >= 0}>
            <InterfaceTemplateTextInput
              capitalization={"sentences"}
              template={template.otherTextEntry}
              value={{kind: "text", textValue: value.multiSelectValue.otherText ?? ""}}
              error={undefined}
              onChange={(newValue) => {
                props.onChange({
                  kind: "multiSelect",
                  multiSelectValue: {
                    options: value.multiSelectValue.options,
                    otherText: newValue.textValue ?? "",
                  }
                })
              }}
            />
          </Collapsable>
        </div>
      )
      }
    </>
  )
}
